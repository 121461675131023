// General
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // TOR variables
  torDescription: null,

  // Profile Preview Photo variables
  profilePreviewPhotoInfo: null,

  // Profile Badge variables
  profileBadgeInfo: null,

  // Profile Completion variables
  profileCompletionPercentage: null,
  profileRequirementPopup: 50,
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    // General Functions
    updateTorDescription: (state, action) => {
      state.torDescription = action.payload;
    },

    // Profile Preview Photo Functions
    updateProfilePreviewPhotoInfo: (state, action) => {
      state.profilePreviewPhotoInfo = action.payload;
    },

    // Profile Badge Functions
    updateProfileBadgeInfo: (state, action) => {
      state.profileBadgeInfo = action.payload;
    },

    // Profile Completion Functions
    updateProfileCompletionPercentage: (state, action) => {
      state.profileCompletionPercentage = action.payload;
    },
    updateProfileRequirementPopup: (state, action) => {
      state.profileRequirementPopup = action.payload;
    },

    // Utility Functions
    resetProfileStore: () => {
      return { ...initialState };
    },
  },
});

export const {
  // General Functions
  updateTorDescription,

  // Profile Preview Photo Functions
  updateProfilePreviewPhotoInfo,

  // Profile Badge Functions
  updateProfileBadgeInfo,

  // Profile Completion Functions
  updateProfileCompletionPercentage,
  updateProfileRequirementPopup,

  // Utility Functions
  resetProfileStore,
} = profileSlice.actions;

export default profileSlice.reducer;
