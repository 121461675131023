// General
import "./profile-info.scss";
import { useEffect } from "react";
// Services
import { useLazyGetProfileCompletionQuery } from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
import userConst from "../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
// i18next
import { useTranslation } from "react-i18next";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { CircularProgress, Skeleton } from "@mui/material";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import useGetMembershipBadge from "../../../utility/custom-hooks/useGetMembershipBadge-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../../../shared/elements/custom-avatar/custom-avatar";
import VerifiedProfileBadge from "../../../shared/elements/verified-profile-badge/verified-profile-badge";

const ProfileInfo = () => {
  // API variables
  const [
    getProfileCompletion,
    {
      data: getProfileCompletionData,
      error: getProfileCompletionErrorData,
      isFetching: getProfileCompletionFetching,
      isLoading: getProfileCompletionLoading,
      isSuccess: getProfileCompletionSuccess,
      isError: getProfileCompletionError,
    },
  ] = useLazyGetProfileCompletionQuery();

  // Redux variables
  const profileCompletionPercentage = useSelector(
    (state) => state.profile.profileCompletionPercentage
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getMembershipBadge = useGetMembershipBadge();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfileCompletion(null, false);
  }, []);

  // Event Handlers | Button
  const onProfileComplete = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-11-11.4",
      },
    });

    onNavigate(routeConst.profile.completion.path);
  };
  const onNewTab = (url) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-11-11.5",
      },
    });

    if (!url) return;

    window.open(url, "_blank");
  };

  if (getProfileCompletionSuccess || getProfileCompletionData) {
    return (
      <div id="overview-profile-info-subcomponent">
        <div className="profile-photo-container">
          {profileCompletionPercentage < 100 && (
            <CircularProgress
              className="default-circular-progress-bar"
              variant="determinate"
              value={100}
            />
          )}
          {profileCompletionPercentage < 100 && (
            <CircularProgress
              className="circular-progress-bar"
              variant="determinate"
              value={profileCompletionPercentage || 0}
            />
          )}

          <CustomAvatar
            className={`profile-photo ${
              profileCompletionPercentage < 100 ? "profile-photo-absolute" : ""
            }`}
            src={
              getProfileCompletionData?.data?.profile_photo?.photo_status !==
              "REJECTED"
                ? getProfileCompletionData?.data?.profile_photo?.original_photo
                : null
            }
            role={getProfileCompletionData?.data?.role}
            profileBorder={
              getProfileCompletionData?.data?.profile_decorations
                ?.profile_border_url
            }
          />

          {profileCompletionPercentage < 100 && (
            <div
              className="profile-completed-percentage-container"
              onClick={onProfileComplete}
            >
              {profileCompletionPercentage || 0}%
            </div>
          )}
        </div>

        <div className="username-container">
          <div className="name">
            {getProfileCompletionData?.data?.username || t("common.new_user")}
          </div>

          {/* Verified Profile */}
          <div className="verified-profile-badge-container">
            <VerifiedProfileBadge
              verifiedProfile={
                getProfileCompletionData?.data?.is_verified_profile
              }
              className={"verified-profile-badge"}
            />
          </div>

          {/* Premium Mini Badge */}
          {(getProfileCompletionData?.data?.membership_type ===
            userConst.membershipType.premium ||
            getProfileCompletionData?.data?.membership_type ===
              userConst.membershipType.freePremium) &&
            getIcon("premiumMiniBadge", "premium-mini-badge")}
        </div>

        <div className="badge-container">
          {/* Premium Badge */}
          {(getProfileCompletionData?.data?.membership_type ===
            userConst.membershipType.premium ||
            getProfileCompletionData?.data?.membership_type ===
              userConst.membershipType.freePremium) && (
            <div className="premium-badge-container">
              <img
                className="premium-badge"
                src={getMembershipBadge(
                  getProfileCompletionData?.data?.membership_type
                )}
              />
            </div>
          )}

          {/* Levelling */}
          {getProfileCompletionData?.data?.levelling_profile?.badge && (
            <div className="levelling-badge-container">
              <img
                className="levelling-badge"
                src={
                  getProfileCompletionErrorData?.data?.levelling_profile?.badge
                }
              />
            </div>
          )}

          {/* Special Badges */}
          {getProfileCompletionData?.data?.special_badges?.length > 0 && (
            <div className="special-badges-container">
              {getProfileCompletionData?.data?.special_badges?.map(
                (badge, index) => (
                  <img
                    className="special-badges"
                    src={badge?.asset}
                    alt={"special"}
                    onClick={() => onNewTab(badge?.url)}
                    key={index}
                  />
                )
              )}
            </div>
          )}
        </div>
      </div>
    );
  } else if (getProfileCompletionFetching || getProfileCompletionLoading) {
    return (
      <div id="overview-profile-info-subcomponent">
        <div className="profile-photo-container">
          <Skeleton className="profile-photo" variant="circular" />
        </div>

        <div className="username-container">
          <Skeleton className="username-skeleton" variant="rounded" />
        </div>

        <div className="badge-container">
          <Skeleton className="badge-skeleton" variant="rounded" />
        </div>
      </div>
    );
  } else if (getProfileCompletionError) {
    if (getProfileCompletionErrorData?.status === 401) {
      onNavigate(routeConst.logout.path);
    }
  }
};

export default ProfileInfo;
