// General
import "./overall-tab.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetUserAioQuery,
  useLazyGetStreamerCenterQuery,
} from "../../../../../services/data.service";
// Static Data
import routeConst from "../../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateDiamondsWithdrawalInfoDialog } from "../../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";
import useCompactNumbers from "../../../../utility/custom-hooks/useCompactNumbers-hook";
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../../../shared/elements/spinner/spinner";
import ProfileStats from "../../../overview/profile-stats/profile-stats";
import StreamerStats from "../more-info-tab/streamer-stats/streamer-stats";
import DiamondsWithdrawalInfoDialog from "../../../../shared/dialog-content/diamonds-withdrawal-info-dialog/diamonds-withdrawal-info-dialog";

const OverallTab = () => {
  // API variables
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();
  const [
    getStreamerCenter,
    {
      data: getStreamerCenterData,
      error: getStreamerCenterErrorData,
      isFetching: getStreamerCenterFetching,
      isLoading: getStreamerCenterLoading,
      isSuccess: getStreamerCenterSuccess,
      isError: getStreamerCenterError,
    },
  ] = useLazyGetStreamerCenterQuery();

  // Redux variables
  const isLoggedIn = useSelector((state) => state.public.isLoggedIn);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getCompactNumbers = useCompactNumbers();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!isLoggedIn) return;

    getUserAio(null, true);
    getStreamerCenter(null, true);
  }, [isLoggedIn]);

  // Event Handlers | Button
  const onLastStreamSummary = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-29-29.3-29.3.1-Button",
      },
    });

    onNavigate(routeConst.profile.streamerCenter.lastStreamSummary.path);
  };
  const onCashOut = (claimable) => {
    if (!claimable) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-29-29.3-29.3.2-Button",
      },
    });

    dispatch(updateDiamondsWithdrawalInfoDialog(true));
    // https://sugarbook.zendesk.com/hc/en-us/articles/4403662305561-I-want-to-cash-out-my-diamonds
  };
  const onOpenStreamerPolicy = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-29-29.3-29.3.3-Button",
      },
    });

    window.open(routeConst.zendesk.streamerPolicy.path, "_blank");
  };

  if (
    getStreamerCenterFetching ||
    getStreamerCenterLoading ||
    getUserAioFetching ||
    getUserAioLoading
  ) {
    return <Spinner />;
  } else if (getStreamerCenterSuccess && getUserAioSuccess) {
    return (
      <div id="streamer-center-overall-tab-subcomponent">
        <div className="padding-container">
          <div className="diamond-img-container">
            {getIcon("sbDiamondIcon", "diamond-img")}
          </div>

          <div className="diamond-stats-container">
            <div className="diamond-stats-header">
              {t("settings.current_balance")}:
            </div>
            <div className="diamond-stats-text">
              {getCompactNumbers(
                getStreamerCenterData?.data?.diamonds?.balance
              ) || 0}
            </div>
          </div>

          <div className="diamond-stats-2nd-row-container">
            {getUserAioData?.data?.modules?.live_streaming_enabled ? (
              <div className="diamond-stats-container">
                <div className="diamond-stats-header">
                  {t("settings.live_streaming_earnings")}:
                </div>
                <div className="diamond-stats-text">
                  {getCompactNumbers(
                    getStreamerCenterData?.data?.diamonds?.total
                  ) || 0}
                </div>
              </div>
            ) : (
              <div className="coming-soon-container">
                {t("common.coming_soon")}
              </div>
            )}

            <div className="diamond-stats-container">
              <div className="diamond-stats-header">
                {t("settings.video_call_earnings")}:
              </div>
              <div className="diamond-stats-text">
                {getCompactNumbers(
                  getStreamerCenterData?.data?.diamonds?.private_calls_total
                ) || 0}
              </div>
            </div>
          </div>

          <div className="progress-bar-container">
            <div className="diamond-count-labels-container">
              <div className="start-diamond-label">
                {getStreamerCenterData?.data?.diamonds?.cashout_progress?.minimum?.toLocaleString() ||
                  0}{" "}
                {t("common.diamonds")}
              </div>

              <div className="end-diamond-label">
                {getStreamerCenterData?.data?.diamonds?.cashout_progress?.maximum?.toLocaleString() ||
                  "N/A"}{" "}
                {t("common.diamonds")}
              </div>
            </div>

            <div className="progress-bar">
              <div
                className="progress-bar-load"
                style={{
                  width: `calc(${
                    (getStreamerCenterData?.data?.diamonds?.balance /
                      getStreamerCenterData?.data?.diamonds?.cashout_progress
                        ?.maximum) *
                    100
                  }%)`,
                }}
              ></div>
              <div className="progress-bar-value">
                {getStreamerCenterData?.data?.diamonds?.balance?.toLocaleString() ||
                  0}{" "}
                /{" "}
                {getStreamerCenterData?.data?.diamonds?.cashout_progress?.maximum?.toLocaleString() ||
                  0}
              </div>
            </div>

            <div className="your-progress-label">
              {t("settings.your_progress")}
            </div>
          </div>
        </div>

        {getUserAioData?.data?.modules?.live_streaming_enabled && (
          <div className="profile-stats-container">
            <ProfileStats />
          </div>
        )}

        <StreamerStats />

        {getUserAioData?.data?.modules?.live_streaming_enabled && (
          <div className="button-container" onClick={onLastStreamSummary}>
            <div className="button">{t("settings.last_stream_summary")}</div>
          </div>
        )}

        <div className="padding-container">
          <div className="header">{t("settings.cash_rewards")}</div>
          <div className="text">
            {t("settings.turn_your_diamonds_into_real_cash")}
          </div>

          <div className="diamonds-list-container">
            {getStreamerCenterData?.data?.diamonds?.cashout_tiers?.map(
              (cashReward, index) => (
                <div className="diamond-container" key={index}>
                  <div className="buy-diamond-img-container">
                    {getIcon("sbDiamondIcon", "diamond-img")}
                  </div>

                  <div className="diamond-price-container">
                    <div className="price">
                      {cashReward?.currency} {cashReward?.amount}
                    </div>
                    <div className="diamond-description">
                      {t("settings.claim_cash_using_n_diamonds", {
                        n: cashReward?.diamonds?.toLocaleString(),
                      })}
                    </div>
                  </div>

                  <div
                    className={`claim-button ${
                      cashReward?.claimable ? "claimable" : "claimed"
                    }`}
                    onClick={() => onCashOut(cashReward?.claimable)}
                  >
                    {t("profile.claim")}
                  </div>
                </div>
              )
            )}
          </div>

          <div className="bottom-description-container">
            <div className="claiming-text">
              {t("settings.claim_tnc")}{" "}
              <div className="policy-text" onClick={onOpenStreamerPolicy}>
                {t("settings.streaming_policy")}
              </div>
            </div>
          </div>
        </div>

        <DiamondsWithdrawalInfoDialog />
      </div>
    );
  } else if (getStreamerCenterError || getUserAioError) {
    if (getStreamerCenterErrorData?.status === 401) {
      onNavigate(routeConst.logout.path);
    }
  }
};

export default OverallTab;
