// General
import "./dating-style.scss";
// Static Data
import editProfileConst from "../../../../../const/editProfileConst";
import utilityConst from "../../../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateUpdateProfileDialogView } from "../../../../../redux/store/editProfileStore";
import {
  updateRestartRegistrationDialog,
  updateUpdateProfileFullscreenDialog,
} from "../../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation } from "react-i18next";
// Material UI
import { Divider } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";

const DatingStyle = (props) => {
  const { mode = utilityConst.editProfileMode.mobile } = props;

  // Redux variables
  const datingStyles = useSelector((state) => state.editProfile.datingStyles);
  const profileCompletionPercentage = useSelector(
    (state) => state.profile.profileCompletionPercentage
  );
  const profileRequirementPopup = useSelector(
    (state) => state.profile.profileRequirementPopup
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Event Handlers | Button
  const onSelectDatingStyle = () => {
    if (profileCompletionPercentage < profileRequirementPopup) {
      dispatch(updateRestartRegistrationDialog(true));
    } else {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-25-25.7",
        },
      });

      dispatch(
        updateUpdateProfileDialogView(
          editProfileConst.profileDialogView.datingStyle
        )
      );
      dispatch(updateUpdateProfileFullscreenDialog(true));
    }
  };

  if (mode === utilityConst.editProfileMode.mobile) {
    return (
      <div id="mobile-edit-profile-dating-style-subcomponent">
        <div className="dating-style-label-container">
          <div className="dating-style-label">{t("profile.dating_style")}</div>
        </div>

        <div className="tor-list-container" onClick={onSelectDatingStyle}>
          <div className="left-container">
            {datingStyles?.length > 0 ? (
              datingStyles?.map((dating, index) => (
                <div className="dating-style-item-container" key={index}>
                  <div className="dating-style-emoji-container">
                    {dating?.emoji}
                  </div>

                  <div className="dating-style-label">{dating?.locale}</div>
                </div>
              ))
            ) : (
              <div className="to-be-completed-text-container">
                <AddRoundedIcon className="add-icon" />
                <div className="to-be-completed-text">
                  {t("profile.add_dating_style")}
                </div>
              </div>
            )}
          </div>

          <div className="right-container">
            <ChevronRightIcon className="right-icon" />
          </div>
        </div>
      </div>
    );
  } else if (mode === utilityConst.editProfileMode.desktop) {
    return (
      <div id="desktop-edit-profile-dating-style-subcomponent">
        <div className="dating-style-static-label-container">
          <div className="dating-style-label">{t("profile.dating_style")}</div>
          <Divider className="short-divider" />
        </div>

        <div className="tor-list-container" onClick={onSelectDatingStyle}>
          {datingStyles?.length > 0 ? (
            datingStyles?.map((dating, index) => (
              <div className="dating-style-item-container" key={index}>
                <div className="dating-style-emoji-container">
                  {dating?.emoji}
                </div>

                <div className="dating-style-label">{dating?.locale}</div>
              </div>
            ))
          ) : (
            <div className="to-be-completed-text-container">
              <AddRoundedIcon className="add-icon" />
              <div className="to-be-completed-text">
                {t("profile.add_dating_style")}
              </div>
            </div>
          )}

          {/* {datingStyles?.length >= 5 ? (
            <div className="tor-edit">
              <EditIcon className="edit-icon" />
            </div>
          ) : (
            <div className="tor-add">
              <AddIcon className="add-icon" />
            </div>
          )} */}
        </div>
      </div>
    );
  }
};

export default DatingStyle;
