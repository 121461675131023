// General
import { useEffect } from "react";
// Services
import { useUpdateUserLocationMutation } from "../../../../services/data.service";
import { sessionService } from "../../../../services/session.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateGeolocationPermissionPromptDialog,
  updateGeolocationPermissionDeniedDialog,
} from "../../../../redux/store/dialogStore";
// Material UI
import { useMediaQuery } from "@mui/material";
// moment
import moment from "moment";

const GeolocationPermissionMounted = () => {
  // API variables
  const [
    updateUserLocation,
    {
      data: updateUserLocationData,
      error: updateUserLocationErrorData,
      isLoading: updateUserLocationLoading,
      isSuccess: updateUserLocationSuccess,
      isError: updateUserLocationError,
    },
  ] = useUpdateUserLocationMutation();

  // Redux variables
  const isLoggedIn = useSelector((state) => state.public.isLoggedIn);
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // Lifecycle | Mounted
  useEffect(() => {
    if (isMobile) {
      const locationData = sessionService.getLocationData();

      if (
        !locationData?.lat ||
        !locationData?.lng ||
        !locationData?.lastRequested
      ) {
        checkGpsPermission();
      } else if (
        moment().diff(moment(locationData?.lastRequested).toDate(), "days") > 1
      ) {
        sessionService.deleteLocationData();
        checkGpsPermission();
      }
    }
  }, []);

  // Lifecycle | Check for update | isLoggedIn
  useEffect(() => {
    if (!isLoggedIn) return;

    const locationData = sessionService.getLocationData();
    if (locationData?.lat && locationData?.lng && locationData?.location_data) {
      const locationObj = {
        lat: locationData?.lat,
        lng: locationData?.lng,
        location_data: locationData?.location_data,
        type: "device_gps",
      };
      updateUserLocation(locationObj);
    }
  }, [isLoggedIn]);

  // Utility Functions
  const checkGpsPermission = async () => {
    try {
      const status = await navigator.permissions.query({
        name: "geolocation",
      });

      switch (status.state) {
        case "granted":
          getGpsLocation();
          break;
        case "denied":
          dispatch(updateGeolocationPermissionDeniedDialog(true));
          break;
        case "undetermined":
          break;
        case "prompt":
          dispatch(updateGeolocationPermissionPromptDialog(true));
          break;
        default:
          break;
      }

      // Listen to changes to the permission | Not needed yet
      status.onchange = () => {
        switch (status.state) {
          case "granted":
            break;
          case "denied":
            dispatch(updateGeolocationPermissionPromptDialog(false));
            dispatch(updateGeolocationPermissionDeniedDialog(true));
            break;
          case "undetermined":
            break;
          case "prompt":
            getGpsLocation();
            break;
          default:
            break;
        }
      };
    } catch (error) {}
  };
  const getGpsLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const apiKey = process.env["REACT_APP_GOOGLE_MAPS_API_KEY"];
          const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${apiKey}`;

          fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
              const place = data.results[0];
              let location_data = {
                formatted_address: place.formatted_address,
              };

              if (place?.address_components) {
                for (let i = 0; i < place.address_components.length; i++) {
                  switch (place.address_components[i].types[0]) {
                    case "locality":
                      location_data.city =
                        place.address_components[i].long_name;
                      break;
                    case "neighborhood":
                      if (location_data.city !== null) {
                        location_data.neighborhood =
                          place.address_components[i].long_name;
                      }
                      break;
                    case "administrative_area_level_1":
                      location_data.state =
                        place.address_components[i].short_name;
                      break;
                    case "country":
                      location_data.country =
                        place.address_components[i].long_name;
                      location_data.country_iso =
                        place.address_components[i].short_name;
                      break;
                    default:
                      break;
                  }
                }
              }

              const locationDataObj = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
                location_data,
                lastRequested: moment().toDate(),
              };
              sessionService.setLocationData(locationDataObj);

              if (isLoggedIn) {
                const locationObj = {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                  location_data: location_data,
                  type: "device_gps",
                };
                updateUserLocation(locationObj);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              break;
            case error.POSITION_UNAVAILABLE:
              break;
            case error.TIMEOUT:
              break;
            case error.UNKNOWN_ERROR:
              break;
            default:
              break;
          }
        }
      );
    } else {
    }
  };

  return <div id="geolocation-permission-mounted-shadow-component"></div>;
};

export default GeolocationPermissionMounted;
