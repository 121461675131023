const paymentConst = {
  paymentState: {
    initial: "initial",
    pendingToken: "pending_token",
    pendingSecure3d: "pending_3ds",
    pendingBackendSubmission: "pending_backend_submission",
    decline: "decline",
    success: "success",
    addingCard: "adding_card",
    addCardSaved: "card_saved",
    addCardFailed: "card_failed",
  },
  xenopayPaymentStatus: {
    successful: "successful",
  }
};

export default paymentConst;
