// General
import "./bottom-user-container.scss";
// Static Data
import profileConst from "../../../../const/profileConst";
// Material UI
import { useMediaQuery } from "@mui/material";
// Components
import IndepthInfo from "../../../shared/elements/profile/indepth-info/indepth-info";
import UserProfileTab from "./user-profile-tab/user-profile-tab";

const BottomUserContainer = (props) => {
  const { userProfileType } = props;

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  return (
    <div id="user-profile-bottom-user-container-subcomponent">
      <IndepthInfo
        userProfileType={
          isMobile ? userProfileType : profileConst.userProfileType.page
        }
      />

      <UserProfileTab userProfileType={userProfileType} />
    </div>
  );
};

export default BottomUserContainer;
