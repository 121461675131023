// General
import { createSlice } from "@reduxjs/toolkit";
// Services
import { sessionService } from "../../services/session.service";
import { API_CONFIG_HEADERS } from "../../const/apiConst";
// Static Data
import utilityConst from "../../const/utilityConst";

const initialState = {
  // Language variables
  manualLanguage: false,
  language: utilityConst.language.en,

  // Google Maps variables
  googleMapsLoaded: false,
};

export const noResetSlice = createSlice({
  name: "noReset",
  initialState,
  reducers: {
    // Language Functions
    updateManualLanguage: (state, action) => {
      state.manualLanguage = action.payload;
      sessionService.setManualLanguage(action.payload ?? false);
    },
    updateLanguage: (state, action) => {
      state.language = action.payload;
      sessionService.setLanguage(action.payload ?? utilityConst.language.en);

      API_CONFIG_HEADERS.SB_API_HEADERS["device-language"] = state.language;
      API_CONFIG_HEADERS.COM_HEADERS["device-language"] = state.language;
      API_CONFIG_HEADERS.SPI_HEADERS["SB-Language"] = state.language;
    },

    // Google Maps Functions
    updateGoogleMapsLoaded: (state, action) => {
      state.googleMapsLoaded = action.payload;
    },

    // Utility Functions
    resetNoResetStore: () => {
      return { ...initialState };
    },
  },
});

export const {
  // Language Functions
  updateManualLanguage,
  updateLanguage,

  // Google Maps Functions
  updateGoogleMapsLoaded,

  // Utility Functions
  resetNoResetStore,
} = noResetSlice.actions;

export default noResetSlice.reducer;
