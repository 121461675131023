// General
import "./pre-request-video-call.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetProfileQuery,
  useLazyGetPrivateCallRequestQuery,
} from "../../../../../services/data.service";
// Static Data
import routeConst from "../../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updatePrivateCallCallingDialog,
  updateStreamerCenterLearnMoreDialog,
} from "../../../../../redux/store/dialogStore";
import {
  updateCalleeUsername,
  updateCallerUsername,
  // Interval Functions
  updateRequestId,
} from "../../../../../redux/store/privateCallStore";
// Material UI
import { Divider } from "@mui/material";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../../../shared/elements/spinner/spinner";
import CustomAvatar from "../../../../shared/elements/custom-avatar/custom-avatar";
import OneOnOneVideoCall from "../../../../shared/dialog-content/one-on-one-video-call-dialog/one-on-one-video-call-dialog";

const PreRequestVideoCall = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    getPrivateCallRequest,
    {
      data: getPrivateCallRequestData,
      error: getPrivateCallRequestErrorData,
      isFetching: getPrivateCallRequestFetching,
      isLoading: getPrivateCallRequestLoading,
      isSuccess: getPrivateCallRequestSuccess,
      isError: getPrivateCallRequestError,
    },
  ] = useLazyGetPrivateCallRequestQuery();

  // Redux variables
  const conversationUser = useSelector((state) => state.inbox.conversationUser);
  const callerCoins = useSelector((state) => state.privateCall.callerCoins);
  const calleeDiamonds = useSelector(
    (state) => state.privateCall.calleeDiamonds
  );
  const calleeRatePerMinute = useSelector(
    (state) => state.privateCall.calleeRatePerMinute
  );
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  // Lifecycle | Check for update | Private Call Request API Response
  useEffect(() => {
    if (getPrivateCallRequestFetching || getPrivateCallRequestLoading) {
    } else if (getPrivateCallRequestSuccess) {
      if (getPrivateCallRequestData?.status === 0) {
        dispatch(
          updateRequestId(
            getPrivateCallRequestData?.data?.call_request?.call_request_id
          )
        );
        dispatch(
          updateCallerUsername(
            getPrivateCallRequestData?.data?.call_request?.caller?.username
          )
        );
        dispatch(
          updateCalleeUsername(
            getPrivateCallRequestData?.data?.call_request?.callee?.username
          )
        );

        dispatch(updatePrivateCallCallingDialog(true));
      }
    } else if (getPrivateCallRequestError) {
    }
  }, [
    getPrivateCallRequestFetching,
    getPrivateCallRequestLoading,
    getPrivateCallRequestSuccess,
    getPrivateCallRequestError,
  ]);

  // Event Handlers | Button
  const onCallNow = () => {
    const obj = {
      callee_id: conversationUser?.userId,
    };
    getPrivateCallRequest(obj);
  };
  const onOpenVideoCallInfoDialog = () => {
    dispatch(updateStreamerCenterLearnMoreDialog(true));
  };

  if (getProfileFetching || getProfileLoading) {
    return <Spinner />;
  } else if (getProfileSuccess) {
    return (
      <div id="conversation-list-pre-request-video-call-subcomponent">
        <div className="pre-request-video-call-layout">
          <div className="top-container">
            <div className="profile-photos-container">
              <CustomAvatar
                className="caller-picture"
                src={getProfileData?.data?.profile_photo?.original_photo}
                role={getProfileData?.data?.role}
                profileBorder={
                  getProfileData?.data?.profile_decorations?.profile_border_url
                }
              />

              <CustomAvatar
                className="callee-picture"
                src={conversationUser?.profilePhoto}
                role={conversationUser?.role}
                profileBorder={
                  conversationUser?.profileDecorations?.profile_border_url
                }
              />
            </div>

            <div className="username-calling-label">
              <Trans
                i18nKey={"inbox.chat_request_call"}
                values={{ user: conversationUser?.username }}
                components={{
                  br: <br />,
                }}
              />
            </div>

            <div className="call-request-rate-description">
              {isDaddy ? (
                <Trans
                  i18nKey={"inbox.chat_request_charging"}
                  values={{ coins: callerCoins }}
                  components={{
                    coinicon: getIcon("sbCoinIcon", "coin-icon"),
                    br: <br />,
                    coin: <span className="coin-text" />,
                  }}
                />
              ) : (
                <Trans
                  i18nKey={"inbox.chat_request_earning"}
                  values={{
                    diamond: `+${calleeDiamonds}`,
                  }}
                  components={{
                    diamondicon: getIcon("sbDiamondIcon", "diamond-icon"),
                    br: <br />,
                    amount: <span className="amount-text" />,
                    diamond: <span className="diamond-text" />,
                  }}
                />
              )}
            </div>
          </div>

          <Divider />

          <div className="bottom-container">
            <div className="tips-header">
              {t("inbox.chat_request_tips_title")}
            </div>
            <ul className="tips-unordered-list">
              <li>
                {t(
                  isDaddy
                    ? "inbox.chat_request_sd_tip_1"
                    : "inbox.chat_request_sb_tip_1"
                )}
              </li>
              <li
                className="highlighted-text"
                onClick={() => onNavigate(routeConst.settings.videoCall.path)}
              >
                {t(
                  isDaddy
                    ? "inbox.chat_request_sd_tip_2"
                    : "inbox.chat_request_sb_tip_2"
                )}
              </li>
              <li>
                <div className="text-with-icon">
                  {!isDaddy && (
                    <div className="diamond-icon-container">
                      {getIcon("sbDiamondIcon", "diamond-icon")}
                    </div>
                  )}
                  <div>
                    {t(
                      isDaddy
                        ? "inbox.chat_request_sd_tip_3"
                        : "inbox.chat_request_sb_tip_3"
                    )}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="call-now-button" onClick={onCallNow}>
          {getPrivateCallRequestFetching ? (
            <Spinner size={20} isPadding={false} color="white-spinner" />
          ) : (
            t("inbox.chat_request_call_now")
          )}
        </div>

        <div className="buttons-container">
          <div
            className="settings-button"
            onClick={() => onNavigate(routeConst.settings.videoCall.path)}
          >
            <div className="settings-icon-container">
              {getIcon("redSettingsIcon", "settings-icon")}
            </div>

            <div className="settings-label">
              {t("inbox.chat_request_settings")}
            </div>
          </div>

          <div
            className="learn-more-button"
            onClick={onOpenVideoCallInfoDialog}
          >
            <div className="learn-more-icon-container">
              {getIcon("redInfoIcon", "learn-more-icon")}
            </div>

            <div className="learn-more-label">
              {t("inbox.chat_request_learn_more")}
            </div>
          </div>
        </div>

        <OneOnOneVideoCall />
      </div>
    );
  } else if (getProfileError) {
  }
};

export default PreRequestVideoCall;
