// General
import "./edit-photos.scss";
import { useEffect } from "react";
// Services
import { useUpdateEditUserProfileMutation } from "../../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateUsername } from "../../../../../redux/store/editProfileStore";
import { updateUpdateProfileFullscreenDialog } from "../../../../../redux/store/dialogStore";
// i18next
import { useTranslation } from "react-i18next";
// Components
import Spinner from "../../../elements/spinner/spinner";
import Photos from "../../../../profile/edit/photos/photos";

const EditPhotos = () => {
  // API variables
  const [
    updateProfile,
    {
      data: updateProfileData,
      error: updateProfileErrorData,
      isLoading: updateProfileLoading,
      isSuccess: updateProfileSuccess,
      isError: updateProfileError,
    },
  ] = useUpdateEditUserProfileMutation();

  // Redux variables
  const username = useSelector((state) => state.editProfile.username);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Check for update | updateProfile API Response
  useEffect(() => {
    if (updateProfileLoading) {
    } else if (updateProfileSuccess) {
      dispatch(updateUsername(updateProfileData?.data?.user?.username));
      dispatch(updateUpdateProfileFullscreenDialog(false));
    } else if (updateProfileError) {
    }
  }, [updateProfileLoading, updateProfileSuccess, updateProfileError]);

  // Event Handlers | Button
  const onSavePhotos = () => {
    dispatch(updateUpdateProfileFullscreenDialog(false));
  };

  // Utility Functions
  const isFormValid = () => {
    return username;
  };

  return (
    <div id="photos-subcomponent">
      <div className="padding-container">
        <div className="top-container">
          <div className="photos-container">
            <Photos />
          </div>
        </div>
      </div>

      <div className="bottom-container">
        <div
          className={`save-button ${isFormValid() ? "" : "disabled-button"}`}
          onClick={onSavePhotos}
        >
          {updateProfileLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("common.save")
          )}
        </div>
      </div>
    </div>
  );
};

export default EditPhotos;
