// General
import "./race.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetJourneyQuestionsQuery,
  useUpdateJourneyMutation,
} from "../../../../services/data.service";
// Static Data
import utilityConst from "../../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateRace,
  updateJourneyNavigationPassthrough,
  updateJourneyDataPassthrough,
  updateRegistrationNavigationData,
} from "../../../../redux/store/registrationStore";
import { updateErrorToast } from "../../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { useMediaQuery } from "@mui/material";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Components
import Spinner from "../../../shared/elements/spinner/spinner";

const RaceDetails = () => {
  // API variables
  const [
    getJourneyQuestions,
    {
      data: getJourneyQuestionsData,
      error: getJourneyQuestionsErrorData,
      isFetching: getJourneyQuestionsFetching,
      isLoading: getJourneyQuestionsLoading,
      isSuccess: getJourneyQuestionsSuccess,
      isError: getJourneyQuestionsError,
    },
  ] = useLazyGetJourneyQuestionsQuery();
  const [
    updateJourney,
    {
      data: updateJourneyData,
      error: updateJourneyErrorData,
      isLoading: updateJourneyLoading,
      isSuccess: updateJourneySuccess,
      isError: updateJourneyError,
    },
  ] = useUpdateJourneyMutation();
  const [
    skipJourney,
    {
      data: skipJourneyData,
      error: skipJourneyErrorData,
      isLoading: skipJourneyLoading,
      isSuccess: skipJourneySuccess,
      isError: skipJourneyError,
    },
  ] = useUpdateJourneyMutation();

  // Redux variables
  const ethnicity = useSelector((state) => state.registration.ethnicity);
  const race = useSelector((state) => state.registration.race);
  const skippableJourney = useSelector(
    (state) => state.registration.skippableJourney
  );
  const registrationNavigationData = useSelector(
    (state) => state.registration.registrationNavigationData
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    getJourneyQuestions(null, true);
  }, []);

  // Lifecycle | Check for update | updateJourney API Response
  useEffect(() => {
    if (updateJourneyLoading) {
    } else if (updateJourneySuccess) {
      dispatch(updateJourneyDataPassthrough(updateJourneyData));
      dispatch(updateJourneyNavigationPassthrough({}));
    } else if (updateJourneyError) {
      const warningToast = {
        message: updateJourneyErrorData?.data?.message,
        autoClose: 3000,
      };
      dispatch(updateErrorToast(warningToast));
    }
  }, [updateJourneyLoading, updateJourneySuccess, updateJourneyError]);

  // Lifecycle | Check for update | skipJourney API Response
  useEffect(() => {
    if (skipJourneyLoading) {
    } else if (skipJourneySuccess) {
      const updatedItems = registrationNavigationData?.map((item, i) => ({
        ...item,
        skipped: i === 8 ? true : i > 8 ? false : item.skipped,
      }));
      dispatch(updateRegistrationNavigationData(updatedItems));

      dispatch(updateJourneyDataPassthrough(updateJourneyData));
      dispatch(updateJourneyNavigationPassthrough({}));
    } else if (skipJourneyError) {
    }
  }, [skipJourneyLoading, skipJourneySuccess, skipJourneyError]);

  // Event Handlers | Button
  const onSelectRace = (race) => {
    dispatch(updateRace(race));
  };
  const onNextPage = () => {
    if (!isFormValid()) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-62-62.9-62.9.1",
      },
    });

    const obj = {
      journey_step: "9",
      journey_data: {
        profile: {
          race: race?.id,
        },
      },
    };
    updateJourney(obj);
  };
  const onSkip = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-62-62.9-62.9.2",
      },
    });

    const obj = {
      journey_step: "9",
      journey_data: {
        looking_for: {},
      },
    };
    skipJourney(obj);
  };

  // Utility Functions
  const isFormValid = () => {
    return race && !updateJourneyLoading;
  };
  const getRaceData = () => {
    const raceData = getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "race"
    )?.selections;

    const filteredRaceData = raceData?.filter(
      (item) => item?.sub_category === ethnicity?.tag
    );
    return filteredRaceData;
  };

  if (ethnicity) {
    return (
      <div id="details-signup-race-details-subcomponent">
        <div className="padding-container">
          <div className="top-container">
            <div className="race-details-label">
              {t("registration.race.title")}
            </div>

            {getJourneyQuestionsFetching || getJourneyQuestionsLoading ? (
              <Spinner />
            ) : (
              <div
                className={`race-body-container ${
                  getRaceData()?.length >
                  utilityConst.registrationItemFlexAmount
                    ? "flex-start"
                    : "justify-center"
                }`}
              >
                {getRaceData()?.map((item, index) => (
                  <div
                    className={`race-item ${
                      getRaceData()?.length <=
                      utilityConst.registrationItemFlexAmount
                        ? "partial-width"
                        : getRaceData()?.length >
                          utilityConst.registrationItemFlexAmount
                        ? "fit-content"
                        : "fix-width"
                    } ${item?.id === race?.id ? "selected" : ""}`}
                    onClick={() => onSelectRace(item)}
                    key={index}
                  >
                    {item?.display_locale}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="bottom-container">
          <div
            className={`continue-button ${
              isMobile ? "full-width" : "fit-content"
            } ${isFormValid() ? "" : "disabled-button"}`}
            onClick={onNextPage}
          >
            {updateJourneyLoading ? (
              <Spinner size={19} isPadding={false} color={"white-spinner"} />
            ) : (
              t("common.continue")
            )}
          </div>

          <div className="skip-button" onClick={onSkip}>
            {skipJourneyLoading ? (
              <Spinner size={19} isPadding={false} color={"white-spinner"} />
            ) : (
              t("common.skip")
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div id="details-signup-race-details-subcomponent">
        <div className="please-select-ethnicity-to-continue">
          {t("registration.race.missing")}
        </div>
      </div>
    );
  }
};

export default RaceDetails;
