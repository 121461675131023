// General
import "./about-me-tab.scss";
// i18next
import { useTranslation } from "react-i18next";
// Components
import ProfileDatingStyle from "../../../../../shared/elements/profile/profile-dating-styles/profile-dating-styles";
import ProfileFinancialInformation from "../../../../../shared/elements/profile/profile-financial-information/profile-financial-information";
import ProfileAboutMe from "../../../../../shared/elements/profile/profile-about-me/profile-about-me";
import ProfileLookingFor from "../../../../../shared/elements/profile/profile-looking-for/profile-looking-for";
import ProfileMoreAboutMe from "../../../../../shared/elements/profile/profile-more-about-me/profile-more-about-me";

const AboutMeTab = () => {
  // i18next variables
  const { t } = useTranslation();

  return (
    <div id="user-profile-tab-about-me-tab-subcomponent">
      <ProfileDatingStyle />
      <ProfileFinancialInformation />
      <ProfileAboutMe />
      <ProfileLookingFor />
      <ProfileMoreAboutMe />
    </div>
  );
};

export default AboutMeTab;
