// General
import "./profile-more-about-me.scss";
import { useEffect } from "react";
// Services
import { useLazyGetProfileQuery } from "../../../../../services/data.service";
// Static Data
import editProfileConst from "../../../../../const/editProfileConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateProfileCompletionPercentage } from "../../../../../redux/store/profileStore";
import {
  updateAboutMe,
  updateUpdateProfileDialogView,
} from "../../../../../redux/store/editProfileStore";
import {
  updateUpdateProfileFullscreenDialog,
  updateRestartRegistrationDialog,
} from "../../../../../redux/store/dialogStore";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";

const ProfileMoreAboutMe = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // Redux variables
  const aboutMe = useSelector((state) => state.userCard.aboutMe);
  const aboutMeList = useSelector((state) => state.editProfile.aboutMeList);
  const profileCompletionPercentage = useSelector(
    (state) => state.profile.profileCompletionPercentage
  );
  const profileRequirementPopup = useSelector(
    (state) => state.profile.profileRequirementPopup
  );
  const personalityType = useSelector(
    (state) => state.userCard.personalityType
  );
  const hobbies = useSelector((state) => state.userCard.hobbies);
  const fitnessLevel = useSelector((state) => state.userCard.fitnessLevel);
  const starSign = useSelector((state) => state.userCard.starSign);
  const dominantOrSubmissive = useSelector(
    (state) => state.userCard.dominantOrSubmissive
  );
  const personalityTraits = useSelector(
    (state) => state.userCard.personalityTraits
  );
  const isSelf = useSelector((state) => state.userCard.isSelf);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  // Lifecycle | Check for update | getProfileData
  useEffect(() => {
    if (getProfileFetching || getProfileLoading) {
    } else if (getProfileSuccess) {
      // dispatch(
      //   updateProfileCompletionPercentage(
      //     getProfileData?.data?.profile_progress?.summary?.progress
      //   )
      // );
    } else if (getProfileError) {
    }
  }, [
    getProfileFetching,
    getProfileLoading,
    getProfileSuccess,
    getProfileError,
  ]);

  // Event Handlers | Button
  const onEditSection = (section) => {
    if (isSelf) return;

    if (profileCompletionPercentage < profileRequirementPopup) {
      dispatch(updateRestartRegistrationDialog(true));
    } else {
      return;

      const obj = {
        name: section,
        data: aboutMeList?.find((item) => item?.name === section)?.data,
      };
      dispatch(updateAboutMe(obj));

      dispatch(
        updateUpdateProfileDialogView(
          editProfileConst.profileDialogView.aboutMe
        )
      );
      dispatch(updateUpdateProfileFullscreenDialog(true));
    }
  };

  // Check if obj data exists or not
  // Utility Functions
  const checkData = () => {
    return (
      (personalityType && personalityType?.length > 0) ||
      (hobbies && hobbies?.length > 0) ||
      fitnessLevel ||
      starSign ||
      dominantOrSubmissive ||
      personalityTraits
    );
  };

  if (checkData()) {
    return (
      <div id="profile-more-about-me-subcomponent">
        <div className="padding-container">
          <div className="more-about-me-label">
            {t("profile.more_about_me")}
          </div>

          <div className="more-about-me-container">
            {/* Personality Type */}
            {personalityType &&
              personalityType?.length > 0 &&
              personalityType?.map((item, index) => (
                <div
                  className={`more-about-me-item-container ${
                    isSelf ? "non-clickable" : ""
                  }`}
                  onClick={() => onEditSection("personality_type")}
                  key={index}
                >
                  <div className="selected-question-icon-container">
                    {getIcon("personalityTypeIcon", "selected-question-icon")}
                  </div>
                  {item?.locale}
                </div>
              ))}

            {/* Hobbies */}
            {hobbies &&
              hobbies?.length > 0 &&
              hobbies?.map((item, index) => (
                <div
                  className={`more-about-me-item-container ${
                    isSelf ? "non-clickable" : ""
                  }`}
                  onClick={() => onEditSection("hobbies")}
                  key={index}
                >
                  <div className="selected-question-icon-container">
                    {getIcon("hobbiesIcon", "selected-question-icon")}
                  </div>
                  {item?.locale}
                </div>
              ))}

            {/* Fitness Level */}
            {fitnessLevel && (
              <div
                className={`more-about-me-item-container ${
                  isSelf ? "non-clickable" : ""
                }`}
                onClick={() => onEditSection("fitness_level")}
              >
                <div className="selected-question-icon-container">
                  {getIcon("fitnessIcon", "selected-question-icon")}
                </div>
                {fitnessLevel?.locale}
              </div>
            )}

            {/* Star Sign */}
            {starSign && (
              <div
                className={`more-about-me-item-container ${
                  isSelf ? "non-clickable" : ""
                }`}
                onClick={() => onEditSection("star_sign")}
              >
                <div className="selected-question-icon-container">
                  {getIcon("starSignIcon", "selected-question-icon")}
                </div>
                {starSign?.locale}
              </div>
            )}

            {/* Dominant or Submissive */}
            {dominantOrSubmissive && (
              <div
                className={`more-about-me-item-container ${
                  isSelf ? "non-clickable" : ""
                }`}
                onClick={() => onEditSection("dominant_or_submissive")}
              >
                <div className="selected-question-icon-container">
                  {getIcon("dominantSubmissiveIcon", "selected-question-icon")}
                </div>
                {dominantOrSubmissive?.locale}
              </div>
            )}

            {/* Personality Traits */}
            {personalityTraits && (
              <div
                className={`more-about-me-item-container ${
                  isSelf ? "non-clickable" : ""
                }`}
                onClick={() => onEditSection("personality_traits")}
              >
                <div className="selected-question-icon-container">
                  {getIcon("personalityTraitsIcon", "selected-question-icon")}
                </div>
                {personalityTraits?.locale}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default ProfileMoreAboutMe;
