// General
import "./top-fans-tab.scss";
import { useState, useEffect } from "react";
// react-gtm-module
import TagManager from "react-gtm-module";
// embla-carousel-react
import useEmblaCarousel from "embla-carousel-react";
// Material UI
import { Tabs, Tab, Box } from "@mui/material";
import PropTypes from "prop-types";
// i18next
import { useTranslation } from "react-i18next";
// Components
import DailyTab from "./daily-tab/daily-tab";
import WeeklyTab from "./weekly-tab/weekly-tab";
import MonthlyTab from "./monthly-tab/monthly-tab";
import OverallTab from "./overall-tab/overall-tab";

const TopFansTab = () => {
  // MUI variables
  const [value, setValue] = useState(0);

  // Embla variables
  const [emblaRef, emblaApi] = useEmblaCarousel({
    duration: 15,
    watchDrag: false,
  });

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted | emblaApi
  useEffect(() => {
    if (!emblaApi) return;
    emblaApi?.scrollTo(value);

    emblaApi?.on("select", () => {
      setValue(emblaApi?.selectedScrollSnap());
    });
  }, [emblaApi]);

  // Lifecycle | Check for update | value
  useEffect(() => {
    emblaApi?.scrollTo(value);
  }, [value]);

  // Event Handlers | MUI Tabs
  const onTabChange = (event, newValue) => {
    TagManager.dataLayer({
      dataLayer: {
        event: `PWA-D6-6.7-6.7.1-Button-${
          newValue === 0
            ? "DailyTab"
            : newValue === 1
            ? "WeeklyTab"
            : newValue === 2
            ? "MonthlyTab"
            : "OverallTab"
        }`,
      },
    });

    setValue(newValue);
  };

  return (
    <div id="viewer-info-top-fans-tab-subcomponent">
      <div className="tab-container">
        <Tabs
          className="custom-tabs"
          value={value}
          onChange={onTabChange}
          TabIndicatorProps={{ style: { backgroundColor: "#710d0d" } }}
          variant="fullWidth"
        >
          <Tab label={t("top-fan.tabs.daily")} {...a11yProps(0)} />
          <Tab label={t("top-fan.tabs.weekly")} {...a11yProps(1)} />
          <Tab label={t("top-fan.tabs.monthly")} {...a11yProps(2)} />
          <Tab label={t("top-fan.tabs.overall")} {...a11yProps(3)} />
        </Tabs>

        <div ref={emblaRef} className="embla">
          <div className="embla__container">
            <div className="embla__slide">{value === 0 && <DailyTab />}</div>

            <div className="embla__slide">{value === 1 && <WeeklyTab />}</div>

            <div className="embla__slide">{value === 2 && <MonthlyTab />}</div>

            <div className="embla__slide">{value === 3 && <OverallTab />}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopFansTab;

// Material UI | Tab Functions
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }} className="tab-box-container">
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
