// General
import "./vpn-block-dialog.scss";
import { useEffect, forwardRef } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateVpnBlockDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// Custom Hooks
import AssetManager from "../../../utility/manager/asset-manager/asset-manager";

const VpnBlockDialog = () => {
  // Redux variables
  const vpnBlockDialog = useSelector((state) => state.dialog.vpnBlockDialog);
  const vpnBlockInfo = useSelector((state) => state.public.vpnBlockInfo);
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 500px)");

  // Custom Hooks Functions
  const getAsset = AssetManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!vpnBlockDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-VPNBlock-Dialog",
      },
    });
  }, [vpnBlockDialog]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = (event, reason) => {
    if (
      reason === "backdropClick" ||
      reason === "clickaway" ||
      reason === "escapeKeyDown"
    )
      return;

    dispatch(updateVpnBlockDialog(false));
  };

  return (
    <Dialog
      fullScreen
      open={vpnBlockDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      <div id="vpn-block-dialog">
        {/* Close Button | For debug purposes */}
        {false && (
          <div className="dialog-header">
            <div className="close-button-container">
              <CloseIcon className="close-button" onClick={onCloseDialog} />
            </div>
          </div>
        )}

        <div className="vpn-body-container">
          {!isMobile && (
            <div className="vpn-desktop-background-container">
              <img
                className="vpn-desktop-background"
                src={getAsset("vpnBlockDesktopBackground")}
                alt="VPN Block"
              />
            </div>
          )}

          <div className="padding-container">
            <div
              className="title"
              dangerouslySetInnerHTML={{ __html: vpnBlockInfo?.title }}
            ></div>

            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: vpnBlockInfo?.description }}
            ></div>

            {!isMobile && (
              <div className="no-vpn-fake-button">SAY NO TO VPN</div>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default VpnBlockDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
