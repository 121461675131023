// General
import "./interactable-overlay.scss";
// Components
import Topbar from "../../livestream/interactable-overlay/topbar/topbar";
import ChatSection from "../../../shared/elements/chat-section/chat-section";
import PinChat from "../../livestream/interactable-overlay/pin-chat/pin-chat";
import ChatInput from "../../livestream/interactable-overlay/chat-input/chat-input";

const InteractableOverlay = () => {
  return (
    <div id="livestreamer-interactable-overlay">
      <div className="top-section">
        <div className="padding-container">
          <Topbar
            state={"livestreamer"}
            showProfile={true}
            showVolume={true}
            showReport={false}
            showBack={true}
          />
        </div>
      </div>

      <div className="bottom-section">
        <ChatSection state={"livestreamer"} />
        <PinChat state={"livestreamer"} />
        <ChatInput state={"livestreamer"} />
      </div>
    </div>
  );
};

export default InteractableOverlay;
