// General
import "./age-range.scss";
import { useEffect } from "react";
// Services
import { useLazyGetProfileQuery } from "../../../../../services/data.service";
// Static Data
import searchConst from "../../../../../const/searchConst";
import userConst from "../../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateMinAge,
  updateMaxAge,
} from "../../../../../redux/store/searchFilterStore";
// Material UI
import { Slider } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Components
import PremiumLock from "../../../elements/premium-lock/premium-lock";

const AgeRange = (props) => {
  const { type = searchConst.searchFilterType.dialog } = props;

  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // Redux variables
  const minAge = useSelector((state) => state.searchFilter.minAge);
  const maxAge = useSelector((state) => state.searchFilter.maxAge);
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  // Event Handlers | MUI Slider
  const onAgeRangeChange = (event, newValue) => {
    dispatch(updateMinAge(newValue[0]));
    dispatch(updateMaxAge(newValue[1]));
  };

  // Utility Functions
  const shouldShowPremiumLock = () => {
    if (
      isDaddy &&
      getProfileData?.data?.membership_type === userConst.membershipType.free
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div id="search-filter-age-range-subcomponent">
      <div
        className={`filter-padding-container ${
          type === searchConst.searchFilterType.full ? "no-gap" : ""
        }`}
      >
        <div className="subheader-container">
          <div className="subheader">{t("filter.age_range")}</div>

          {shouldShowPremiumLock() && <PremiumLock />}
        </div>
        <div
          className={`slider-container ${
            shouldShowPremiumLock() ? "locked" : ""
          }`}
        >
          <div className="min-label">{minAge}</div>
          <Slider
            className={`slider ${
              shouldShowPremiumLock() ? "" : "custom-slider"
            }`}
            size="small"
            value={[minAge, maxAge]}
            onChange={onAgeRangeChange}
            disabled={shouldShowPremiumLock()}
            min={searchConst.filters.ageRange.min}
            max={searchConst.filters.ageRange.max}
          />
          <div className="max-label">{maxAge}</div>
        </div>
      </div>
    </div>
  );
};

export default AgeRange;
