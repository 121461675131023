// General
import "./upgrade-perks.scss";
// Static Data
import upgradeConst from "../../../../const/upgradeConst";
import utilityConst from "../../../../const/utilityConst";
// Redux
import { useSelector } from "react-redux";
// Material UI
import { useMediaQuery } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";

const UpgradePerks = () => {
  // Constant variables
  const daddyUpgradePerks = upgradeConst.upgradePerks.daddy;
  const babyUpgradePerks = upgradeConst.upgradePerks.baby;

  // Render variables
  let carouselView = <div></div>;

  // Redux variables
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const sugarbookTheme = useSelector((state) => state.app.sugarbookTheme);

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Utility Functions
  const returnBackground = () => {
    if (isMobile) {
      switch (sugarbookTheme) {
        case utilityConst.sugarbookTheme.christmas:
          return "christmas-mobile-background";
        case utilityConst.sugarbookTheme.anniversary:
          return "anniversary-mobile-background";
        case utilityConst.sugarbookTheme.valentine:
          return "valentine-mobile-background";
        case utilityConst.sugarbookTheme.raya:
          return "raya-mobile-background";
        default:
          return "mobile-background-color";
      }
    }
  };

  if (isDaddy) {
    carouselView = daddyUpgradePerks.map((perk, index) => {
      return (
        <swiper-slide class="daddy-perks" key={index}>
          <img className="perk-image" src={perk.image} alt={perk.title} />
          <div className="text-container">
            <div className="title">{t(perk.title)}</div>
            <div className="subtitle">{t(perk.subtitle)}</div>
          </div>
        </swiper-slide>
      );
    });
  } else {
    carouselView = babyUpgradePerks.map((perk, index) => {
      return (
        <swiper-slide class="baby-perks" key={index}>
          <img className="perk-image" src={perk.image} alt={perk.title} />
          <div className="text-container">
            <div className="title">{t(perk.title)}</div>
            <div className="subtitle">{t(perk.subtitle)}</div>
          </div>
        </swiper-slide>
      );
    });
  }

  return (
    <div id="upgrade-upgrade-perks-subcomponent" className={returnBackground()}>
      <div className="carousel-container">
        <swiper-container
          class="swiper-container"
          navigation="false"
          pagination="true"
          scrollbar="false"
          loop="true"
          speed="700"
          autoplay-delay="2500"
        >
          {carouselView}
        </swiper-container>
      </div>
    </div>
  );
};

export default UpgradePerks;
