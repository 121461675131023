// General
import "./desktop-left-section.scss";
import { useState, useEffect } from "react";
// Redux
import { useDispatch } from "react-redux";
import { updatePurchaseDialogTab } from "../../../../redux/store/productsStore";
// Material UI
import { Divider, useMediaQuery } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// Components
import UserInfo from "../../../shared/dialog-content/livestream-profile-view-dialog/user-info/user-info";
import ViewerInfo from "../../../shared/dialog-content/livestream-profile-view-dialog/viewer-info/viewer-info";
import ProductPurchase from "../../../shared/shop/product-purchase/product-purchase";

const DesktopLeftSection = () => {
  // General variables
  const [isLobbyExpanded, setIsLobbyExpanded] = useState(false);
  const [isGiftsExpanded, setIsGiftsExpanded] = useState(true);

  // Redux variables
  const dispatch = useDispatch();

  // MUI variables
  const isDesktop = useMediaQuery("(min-width: 1305px)");

  // Lifecycle | Mounted
  useEffect(() => {
    dispatch(updatePurchaseDialogTab(1));
  }, []);

  // Event Handlers | Button
  const onTogglePanelHeight = () => {
    setIsLobbyExpanded(!isLobbyExpanded);
    setIsGiftsExpanded(!isGiftsExpanded);
  };

  if (isDesktop) {
    return (
      <div id="livestream-desktop-left-section-subcomponent">
        <div
          className={`lobby-container ${
            isLobbyExpanded ? "lobby-maximize-height" : "lobby-minimize-height"
          }`}
        >
          <div className="lobby-header-container" onClick={onTogglePanelHeight}>
            <div className="lobby-label">Lobby</div>

            <div className="lobby-icon-container">
              {isLobbyExpanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            </div>
          </div>

          <Divider />

          <UserInfo />

          <Divider />

          <ViewerInfo state={"standalone"} />
        </div>

        <div
          className={`product-container ${
            isGiftsExpanded ? "gifts-maximize-height" : "gifts-minimize-height"
          }`}
        >
          <div className="gifts-header-container" onClick={onTogglePanelHeight}>
            <div className="gifts-label">Gifts</div>

            <div className="gifts-icon-container">
              {isGiftsExpanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            </div>
          </div>

          <Divider />

          <ProductPurchase state={"standalone"} />
        </div>
      </div>
    );
  }
};

export default DesktopLeftSection;
