// General
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
// Redux
import { useSelector, useDispatch } from "react-redux";

const SearchParamMounted = () => {
  // Redux variables
  const dialogSearchParam = useSelector(
    (state) => state.dialog.dialogSearchParam
  );

  // Router variables
  const [searchParams, setSearchParams] = useSearchParams();

  // Lifecycle | Check for update | dialogSearchParam
  useEffect(() => {
    // setSearchParams(dialogSearchParam.join("&"));
  }, [dialogSearchParam]);

  return <div id="search-param-mounted-shadow-component"></div>;
};

export default SearchParamMounted;
