// General
import "./initializing-stream-overlay.scss";
// Redux
import { useSelector } from "react-redux";
// i18next
import { useTranslation } from "react-i18next";

const InitializingStreamOverlay = () => {
  // Redux variables
  const showVideoCallStreamInitializingOverlay = useSelector(
    (state) => state.privateCall.showVideoCallStreamInitializingOverlay
  );

  // i18next variables
  const { t } = useTranslation();

  if (showVideoCallStreamInitializingOverlay) {
    return (
      <div id="initializing-stream-overlay-subcomponent">
        <div className="subheader">{t("1on1.initializing_stream")}</div>
      </div>
    );
  }
};

export default InitializingStreamOverlay;
