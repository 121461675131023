// Assets
import UnlimitedMessages from "../assets/icon/static/upgrade/unlimited_messages.svg";
import FeaturedProfile from "../assets/icon/static/upgrade/featured_profile.svg";
import ReadReceipts from "../assets/icon/static/upgrade/read_receipts.svg";
import AdvancedPrivacy from "../assets/icon/static/upgrade/advanced_privacy.svg";
import Unlock from "../assets/icon/static/upgrade/unlock.svg";
import ViewAdmirers from "../assets/icon/static/upgrade/view_admirers.svg";
import InstantApprovals from "../assets/icon/static/upgrade/instant_approvals.svg";
import ViewPremiumMembers from "../assets/icon/static/upgrade/view_premium_members.svg";
import PersonalizedAboutMeAlt from "../assets/icon/static/upgrade/personalized-about-me-alt.svg";

const upgradeConst = {
  upgradePerks: {
    daddy: [
      {
        title: "upgrade.benefits.unlimited_messages",
        subtitle: "upgrade.benefits.unlimited_messages_desc",
        image: UnlimitedMessages,
      },
      {
        title: "upgrade.benefits.featured_profile",
        subtitle: "upgrade.benefits.featured_profile_desc",
        image: FeaturedProfile,
      },
      {
        title: "upgrade.benefits.read_receipts",
        subtitle: "upgrade.benefits.read_receipts_desc",
        image: ReadReceipts,
      },
      {
        title: "upgrade.benefits.advanced_privacy_features",
        subtitle: "upgrade.benefits.advanced_privacy_features_desc",
        image: AdvancedPrivacy,
      },
      {
        title: "upgrade.benefits.unlock_viewers",
        subtitle: "upgrade.benefits.unlock_viewers_desc",
        image: Unlock,
      },
      {
        title: "upgrade.benefits.view_admirers",
        subtitle: "upgrade.benefits.view_admirers_desc",
        image: ViewAdmirers,
      },
      {
        title: "upgrade.benefits.instant_profile_approvals",
        subtitle: "upgrade.benefits.instant_profile_approvals_desc",
        image: InstantApprovals,
      },
      {
        title: "upgrade.benefits.personalize_about_me",
        subtitle: "upgrade.benefits.personalize_about_me_desc",
        image: PersonalizedAboutMeAlt,
      },
    ],
    baby: [
      {
        title: "upgrade.benefits.featured_profile",
        subtitle: "upgrade.benefits.featured_profile_desc",
        image: FeaturedProfile,
      },
      {
        title: "upgrade.benefits.advanced_privacy_features",
        subtitle: "upgrade.benefits.advanced_privacy_features_desc",
        image: AdvancedPrivacy,
      },
      {
        title: "upgrade.benefits.instant_profile_approvals",
        subtitle: "upgrade.benefits.instant_profile_approvals_desc",
        image: InstantApprovals,
      },
      {
        title: "upgrade.benefits.view_premium_members",
        subtitle: "upgrade.benefits.view_premium_members_desc",
        image: ViewPremiumMembers,
      },
    ],
  },
};

export default upgradeConst;
