// General
import "./achievement-tab.scss";
// Components
import ProfileAchievements from "../../../../../shared/elements/profile/achievements-info/achievements-info";

const AchievementTab = () => {
  return (
    <div id="user-profile-tab-achievement-tab-subcomponent">
      <ProfileAchievements mode={"page"} />
    </div>
  );
};

export default AchievementTab;
