// General
import "./basic-filters.scss";
import { useEffect } from "react";
// Services
import { useLazyGetProfileQuery } from "../../../../../services/data.service";
// Static Data
import searchConst from "../../../../../const/searchConst";
import userConst from "../../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updatePremiumOnly,
  updatePhotos,
  updateViewed,
  updateUnviewed,
  updateFavorited,
  updateFavoritedMe,
} from "../../../../../redux/store/searchFilterStore";
// Material UI
import { Switch } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Components
import PremiumLock from "../../../elements/premium-lock/premium-lock";

const BasicFilters = (props) => {
  const { type = searchConst.searchFilterType.dialog } = props;

  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // Redux variables
  const premiumOnly = useSelector((state) => state.searchFilter.premiumOnly);
  const photos = useSelector((state) => state.searchFilter.photos);
  const viewed = useSelector((state) => state.searchFilter.viewed);
  const unviewed = useSelector((state) => state.searchFilter.unviewed);
  const favorited = useSelector((state) => state.searchFilter.favorited);
  const favoritedMe = useSelector((state) => state.searchFilter.favoritedMe);
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  // Event Handlers | MUI Switches
  const onSwitchChange = (state) => (event) => {
    switch (state) {
      case "premium":
        dispatch(updatePremiumOnly(event.target.checked));
        break;
      case "photos":
        dispatch(updatePhotos(event.target.checked));
        break;
      case "viewed":
        dispatch(updateViewed(event.target.checked));
        break;
      case "unviewed":
        dispatch(updateUnviewed(event.target.checked));
        break;
      case "favorited":
        dispatch(updateFavorited(event.target.checked));
        break;
      case "favorited-me":
        dispatch(updateFavoritedMe(event.target.checked));
        break;
      default:
        break;
    }
  };

  // Utility Functions
  const shouldShowPremiumLock = (state) => {
    switch (state) {
      case "premium":
        if (
          getProfileData?.data?.membership_type ===
          userConst.membershipType.free
        ) {
          return true;
        } else {
          return false;
        }
        break;
      default:
        if (
          isDaddy &&
          getProfileData?.data?.membership_type ===
            userConst.membershipType.free
        ) {
          return true;
        } else {
          return false;
        }
        break;
    }
  };

  return (
    <div id="search-filter-basic-filters-subcomponent">
      <div
        className={`filter-padding-container ${
          type === searchConst.searchFilterType.full ? "no-gap" : ""
        }`}
      >
        <div className="subheader-container">
          <div className="subheader">{t("filter.basic_filters")}</div>
        </div>

        {/* Premium */}
        <div className="switch-container">
          <div
            className={`label ${
              type === searchConst.searchFilterType.dialog
                ? ""
                : "desktop-label"
            }`}
          >
            {t("filter.premium")}

            {shouldShowPremiumLock("premium") && (
              <span className="premium-lock-container">
                <PremiumLock />
              </span>
            )}
          </div>

          <Switch
            className="custom-switch"
            checked={premiumOnly}
            disabled={shouldShowPremiumLock("premium")}
            onChange={onSwitchChange("premium")}
          />
        </div>

        {/* Photos | Hide due to business logic */}
        {false && (
          <div className="switch-container">
            <div
              className={`label ${
                type === searchConst.searchFilterType.dialog
                  ? ""
                  : "desktop-label"
              }`}
            >
              {t("filter.has_photos")}
            </div>

            <Switch
              className="custom-switch"
              checked={photos}
              disabled={shouldShowPremiumLock()}
              onChange={onSwitchChange("photos")}
            />
          </div>
        )}

        {/* Viewed | Hide due to business logic */}
        {false && (
          <div className="switch-container">
            <div
              className={`label ${
                type === searchConst.searchFilterType.dialog
                  ? ""
                  : "desktop-label"
              }`}
            >
              {t("filter.viewed")}
            </div>

            <Switch
              className="custom-switch"
              checked={viewed}
              disabled={shouldShowPremiumLock()}
              onChange={onSwitchChange("viewed")}
            />
          </div>
        )}

        {/* Unviewed | Hide due to business logic */}
        {false && (
          <div className="switch-container">
            <div
              className={`label ${
                type === searchConst.searchFilterType.dialog
                  ? ""
                  : "desktop-label"
              }`}
            >
              {t("filter.unviewed")}
            </div>

            <Switch
              className="custom-switch"
              checked={unviewed}
              disabled={shouldShowPremiumLock()}
              onChange={onSwitchChange("unviewed")}
            />
          </div>
        )}

        {/* Favorited */}
        <div className="switch-container">
          <div
            className={`label ${
              type === searchConst.searchFilterType.dialog
                ? ""
                : "desktop-label"
            }`}
          >
            {t("filter.favorited")}

            {shouldShowPremiumLock() && (
              <span className="premium-lock-container">
                <PremiumLock />
              </span>
            )}
          </div>

          <Switch
            className="custom-switch"
            checked={favorited}
            disabled={shouldShowPremiumLock()}
            onChange={onSwitchChange("favorited")}
          />
        </div>

        {/* Favorited Me */}
        <div className="switch-container">
          <div
            className={`label ${
              type === searchConst.searchFilterType.dialog
                ? ""
                : "desktop-label"
            }`}
          >
            {t("filter.favorited_me")}

            {shouldShowPremiumLock() && (
              <span className="premium-lock-container">
                <PremiumLock />
              </span>
            )}
          </div>

          <Switch
            className="custom-switch"
            checked={favoritedMe}
            disabled={shouldShowPremiumLock()}
            onChange={onSwitchChange("favorited-me")}
          />
        </div>
      </div>
    </div>
  );
};

export default BasicFilters;
