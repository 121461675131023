// General
import "./blocked-members.scss";
import { useState, useEffect } from "react";
// Services
import { useLazyGetBlockedUsersListQuery } from "../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateBlockedUsersList,
  resetBlockedUsersList,
  updateBlockedUsersPagination,
} from "../../../redux/store/userStore";
// React Infinite Scroller
import InfiniteScroll from "react-infinite-scroller";
// react-simple-pull-to-refresh
import PullToRefresh from "react-simple-pull-to-refresh";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { useMediaQuery } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import BlockedList from "./blocked-list/blocked-list";
import EmptyList from "./empty-list/empty-list";
import Spinner from "../../shared/elements/spinner/spinner";
import UtilityDesktopNavbar from "../../shared/elements/utility-desktop-navbar/utility-desktop-navbar";

const BlockedMembers = () => {
  // General variables
  const [firstLoad, setFirstLoad] = useState(true);

  // API variables
  const [
    getBlockedUsersList,
    {
      data: getBlockedUsersListData,
      error: getBlockedUsersListErrorData,
      isFetching: getBlockedUsersListFetching,
      isLoading: getBlockedUsersListLoading,
      isSuccess: getBlockedUsersListSuccess,
      isError: getBlockedUsersListError,
    },
  ] = useLazyGetBlockedUsersListQuery();

  // Render variables
  let blockedMembersListView;

  // Redux variables
  const blockedUsersList = useSelector((state) => state.user.blockedUsersList);
  const blockedUsersPagination = useSelector(
    (state) => state.user.blockedUsersPagination
  );
  const isLoggedIn = useSelector((state) => state.public.isLoggedIn);
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!isLoggedIn) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-34",
      },
    });

    if (blockedUsersList?.length <= 0) {
      getBlockedUsersList(null, false);
    }
  }, [isLoggedIn]);

  // Lifecycle | Check for update | Blocked Users List API Response
  useEffect(() => {
    if (getBlockedUsersListFetching || getBlockedUsersListLoading) {
    } else if (getBlockedUsersListSuccess) {
      switch (getBlockedUsersListData?.status) {
        case 0:
          dispatch(
            updateBlockedUsersPagination(
              getBlockedUsersListData?.data?.pagination?.next_cursor
            )
          );

          if (getBlockedUsersListData?.data?.results?.length > 0) {
            dispatch(
              updateBlockedUsersList(getBlockedUsersListData?.data?.results)
            );
          }
          break;
        default:
          break;
      }
    } else if (getBlockedUsersListError) {
    }
  }, [
    getBlockedUsersListFetching,
    getBlockedUsersListLoading,
    getBlockedUsersListSuccess,
    getBlockedUsersListError,
  ]);

  // Infinite Scroller Functions
  const infiniteScrollFunc = () => {
    if (!blockedUsersPagination) return;

    // Delay to allow User Meta to have a chance to load
    setTimeout(() => {
      getBlockedUsersList(`?pagination_token=${blockedUsersPagination}`, true);
    }, 1000);
  };

  // Pull To Refresh Functions
  const pullToRefreshFunc = () => {
    dispatch(resetBlockedUsersList());
    dispatch(updateBlockedUsersPagination(null));
    setFirstLoad(true);

    return new Promise((resolve) => {
      resolve(getBlockedUsersList(null, false));
    });
  };

  // Render Functions | Blocked Users List
  if (blockedUsersList?.length > 0) {
    blockedMembersListView = (
      <div className="infinite-scroller-container">
        {blockedUsersList?.map((user, index) => (
          <BlockedList
            id={user?.user?.id}
            idInt={user?.user?.id_int}
            isBlocked={user?.user?.interaction?.is_blocked}
            profilePhoto={user?.user?.profile_photo?.original_photo}
            username={user?.user?.username}
            role={user?.user?.role}
            key={index}
          />
        ))}
      </div>
    );
  } else if (
    firstLoad &&
    (getBlockedUsersListFetching || getBlockedUsersListLoading)
  ) {
    blockedMembersListView = <Spinner />;
  } else {
    blockedMembersListView = <EmptyList />;
  }

  return (
    <div id="blocked-members-page">
      <div className="max-width-container">
        <UtilityDesktopNavbar leftButton={"back"} />

        <PullToRefresh
          className={`pull-to-refresh-container ${
            isMobile ? "full-height" : "desktop-height"
          }`}
          onRefresh={pullToRefreshFunc}
          refreshingContent={t("common.release")}
          pullingContent={t("common.pull_to_refresh")}
          isPullable={!getBlockedUsersListFetching}
        >
          <div className="blocked-users-list-container">
            <InfiniteScroll
              className="infinite-scroller-container"
              pageStart={0}
              loadMore={infiniteScrollFunc}
              hasMore={blockedUsersPagination ? true : false}
              loader={<Spinner key={0} />}
              useWindow={false}
            >
              {blockedMembersListView}
            </InfiniteScroll>
          </div>
        </PullToRefresh>
      </div>
    </div>
  );
};

export default BlockedMembers;
