// General
import "./login-page-troubleshoot-dialog.scss";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateLoginPageTroubleshootDialog } from "../../../../redux/store/dialogStore";
// Material UI
import { Dialog } from "@mui/material";
// Components
import AppSettings from "../../../settings/settings/app-settings/app-settings";

const LoginPageTroubleshootDialog = () => {
  // Redux variables
  const loginPageTroubleshootDialog = useSelector(
    (state) => state.dialog.loginPageTroubleshootDialog
  );
  const dispatch = useDispatch();

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateLoginPageTroubleshootDialog(false));
  };

  return (
    <Dialog
      className="custom-radius10-dialog"
      open={loginPageTroubleshootDialog}
      onClose={onCloseDialog}
    >
      <AppSettings />
    </Dialog>
  );
};

export default LoginPageTroubleshootDialog;
