// General
import "./pk-info-dialog.scss";
import { useEffect, forwardRef } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updatePKInfoDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide, Button } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";

const PkInfoDialog = () => {
  // Redux variables
  const pkInfoDialog = useSelector((state) => state.dialog.pkInfoDialog);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!pkInfoDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-PKInfo-Dialog",
      },
    });
  }, [pkInfoDialog]);

  // Event Handlers | Button
  const onCloseDialog = () => {
    dispatch(updatePKInfoDialog(false));
  };

  return (
    <Dialog
      className="custom-bottom-sheet-dialog"
      fullScreen
      open={pkInfoDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      <div id="pk-info-dialog">
        <div className="padding-container">
          <div className="header">What is PK</div>

          <div className="text">
            A multi-tiered event offers a level playing field for every
            streamer. Now, you can compete with other streamers in the same
            category, with a better chance for you to win prizes.
          </div>

          <Button
            className="close-button"
            variant="text"
            onClick={onCloseDialog}
          >
            {t("common.close")}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default PkInfoDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
