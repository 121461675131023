// General
import "./private-call-callee-current-session-summary-dialog.scss";
import { useEffect } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateCalleeCurrentSessionSummaryDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const PrivateCallCalleeCurrentSessionSummaryDialog = () => {
  // Redux variables
  const calleeCurrentSessionSummaryDialog = useSelector(
    (state) => state.dialog.calleeCurrentSessionSummaryDialog
  );
  const callerUsername = useSelector(
    (state) => state.privateCall.callerUsername
  );
  const calleeCurrentVideoCallSummary = useSelector(
    (state) => state.privateCall.calleeCurrentVideoCallSummary
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!calleeCurrentSessionSummaryDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-D13",
      },
    });
  }, [calleeCurrentSessionSummaryDialog]);

  // Event Handlers | Button
  const onCloseDialog = () => {
    dispatch(updateCalleeCurrentSessionSummaryDialog(false));
  };

  // Utility Functions
  const getFormattedTime = (seconds) => {
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds / 60) % 60);
    let secs = Math.floor(seconds % 60);

    let formattedHours = hours < 10 ? "0" + hours : hours;
    let formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    let formattedSeconds = secs < 10 ? "0" + secs : secs;

    return formattedHours + ":" + formattedMinutes + ":" + formattedSeconds;
  };

  return (
    <Dialog
      className="custom-radius35-dialog"
      open={calleeCurrentSessionSummaryDialog}
      onClose={onCloseDialog}
    >
      <div id="private-call-callee-current-session-summary-dialog">
        <div className="padding-container">
          <div className="header">
            {t("1on1.video_call_has_ended_with", { user: callerUsername })}
          </div>

          <div className="top-stats-container">
            <div className="duration-container">
              <div className="duration">
                {getFormattedTime(calleeCurrentVideoCallSummary?.duration)}
              </div>
              <div className="duration-label">{t("1on1.duration")}</div>
            </div>

            <div className="total-diamonds-earned-container">
              <div className="total-diamonds-earned">
                {calleeCurrentVideoCallSummary?.totalDiamondsEarned}
              </div>

              <div className="total-diamonds-earned-label">
                {t("1on1.total_diamonds_earned")}
              </div>
            </div>
          </div>

          <div className="bottom-stats-container">
            <div className="diamonds-earned-text">
              {t("1on1.diamonds_earned")}:
            </div>

            <div className="diamonds-earned-category-container">
              <div className="diamonds-earned-category-label">
                <div className="category-label">{t("1on1.video_call")}</div>
                <div className="category-label">{t("1on1.tips")}</div>
                <div className="category-label">{t("1on1.gifts")}</div>
                <div className="category-label">
                  {t("1on1.charged_messages")}
                </div>
                <div className="category-label">
                  {t("1on1.actions_completed")}
                </div>
                <div className="category-label">
                  {t("1on1.requests_from_daddy")}
                </div>
              </div>

              <div className="diamonds-earned-category-output">
                <div className="diamonds-earned">
                  <div className="diamond-icon-container">
                    {getIcon("sbDiamondIcon", "diamond-icon")}
                  </div>
                  +{calleeCurrentVideoCallSummary?.videoCallDiamondsEarned}
                </div>
                <div className="diamonds-earned">
                  <div className="diamond-icon-container">
                    {getIcon("sbDiamondIcon", "diamond-icon")}
                  </div>
                  +{calleeCurrentVideoCallSummary?.tipsDiamondsEarned}
                </div>
                <div className="diamonds-earned">
                  <div className="diamond-icon-container">
                    {getIcon("sbDiamondIcon", "diamond-icon")}
                  </div>
                  +{calleeCurrentVideoCallSummary?.giftsDiamondsEarned}
                </div>
                <div className="diamonds-earned">
                  <div className="diamond-icon-container">
                    {getIcon("sbDiamondIcon", "diamond-icon")}
                  </div>
                  +
                  {calleeCurrentVideoCallSummary?.chargedMessagesDiamondsEarned}
                </div>
                <div className="diamonds-earned">
                  {calleeCurrentVideoCallSummary?.actionsCompleted}
                </div>
                <div className="diamonds-earned">
                  {calleeCurrentVideoCallSummary?.requestsFromDaddy}
                </div>
              </div>
            </div>
          </div>

          <div className="close-button" onClick={onCloseDialog}>
            {t("common.close")}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default PrivateCallCalleeCurrentSessionSummaryDialog;
