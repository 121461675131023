// General
import "./success-notification.scss";
// Material UI
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

const SuccessNotification = (props) => {
  const { message } = props;

  return (
    <div id="success-nofitication-element">
      <div className="padding-container">
        <div className="center-container">
          <div className="success-icon-container">
            <div className="success-inner-ring">
              <DoneIcon className="success-icon" />
            </div>
          </div>
        </div>

        <div className="message-body-container">
          <div className="message-body">{message}</div>
        </div>

        {false && <div className="close-icon-container">
          <CloseIcon className="close-icon" />
        </div>}
      </div>
    </div>
  );
};

export default SuccessNotification;
