// General
import "./email-verification.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetProfileQuery,
  useInitiateEmailVerificationMutation,
  useVerifyEmailVerificationMutation,
} from "../../../services/data.service";
import { sessionService } from "../../../services/session.service";
// Static Data
import routeConst from "../../../const/routeConst";
import verificationConst from "../../../const/verificationConst";
import utilityConst from "../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  // Email Verification Functions
  setEmailVerificationTimer,
  updateEmailVerificationTimer,
  updateEmailVerificationInterval,
  clearEmailVerificationInterval,

  // Mobile Verification Functions
  updateMobileVerificationRequired,
} from "../../../redux/store/verificationStore";
import {
  updateVerificationDataPassthrough,
  updateVerificationNavigatePassthrough,
} from "../../../redux/store/registrationStore";
import { updateErrorToast } from "../../../redux/store/toastStore";
import { updateVerificationStatusDialog } from "../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// lottie-react
import Lottie from "lottie-react";
// Moment
import moment from "moment";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
import AssetManager from "../../utility/manager/asset-manager/asset-manager";
import IconManager from "../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../shared/elements/spinner/spinner";

const EmailVerification = (props) => {
  const { type = utilityConst.fillingDetails.signup.type } = props;

  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    initiateEmailVerification,
    {
      data: initiateEmailVerificationData,
      error: initiateEmailVerificationErrorData,
      isLoading: initiateEmailVerificationLoading,
      isSuccess: initiateEmailVerificationSuccess,
      isError: initiateEmailVerificationError,
    },
  ] = useInitiateEmailVerificationMutation();
  const [
    verifyEmailVerification,
    {
      data: verifyEmailVerificationData,
      error: verifyEmailVerificationErrorData,
      isLoading: verifyEmailVerificationLoading,
      isSuccess: verifyEmailVerificationSuccess,
      isError: verifyEmailVerificationError,
    },
  ] = useVerifyEmailVerificationMutation();

  // General variables
  const [otp, setOtp] = useState("");
  const [invalidCode, setInvalidCode] = useState(false);
  const [isAnimationPlaying, setIsAnimationPlaying] = useState(false);

  // Redux variables
  const email = useSelector((state) => state.signup.email);
  const signinEmail = useSelector((state) => state.signin.email);
  const emailVerificationTimer = useSelector(
    (state) => state.verification.emailVerificationTimer
  );
  const emailVerificationRequired = useSelector(
    (state) => state.verification.emailVerificationRequired
  );
  const mobileVerificationDisplay = useSelector(
    (state) => state.verification.mobileVerificationDisplay
  );
  const isMissingDetails = useSelector((state) => state.user.isMissingDetails);
  const isLoggedIn = useSelector((state) => state.public.isLoggedIn);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getAsset = AssetManager();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
    onEmailVerificationTimer();

    return () => {
      dispatch(updateEmailVerificationTimer(0));
      dispatch(clearEmailVerificationInterval());
    };
  }, []);

  // Lifecycle | Check for update | Initiate Email Verification API Response
  useEffect(() => {
    if (initiateEmailVerificationLoading) {
    } else if (initiateEmailVerificationSuccess) {
      switch (initiateEmailVerificationData?.status) {
        case 1:
          const createdAt =
            initiateEmailVerificationData?.data?.email_verification?.created_at;
          const expiredAt =
            initiateEmailVerificationData?.data?.email_verification?.expired_at;
          const diffInSeconds = moment(expiredAt).diff(createdAt, "seconds");

          dispatch(setEmailVerificationTimer(60));

          setTimeout(() => {
            onEmailVerificationTimer();
          }, 1000);
          break;
        case -1:
          // Need to wait 3 minutes
          const errorToast = {
            message: initiateEmailVerificationData?.message,
            autoClose: 3000,
          };
          dispatch(updateErrorToast(errorToast));
          break;
        default:
          // Not sure
          break;
      }
    } else if (initiateEmailVerificationError) {
      const errorToast = {
        message: initiateEmailVerificationErrorData?.data?.message,
        autoClose: 3000,
      };
      dispatch(updateErrorToast(errorToast));
    }
  }, [
    initiateEmailVerificationLoading,
    initiateEmailVerificationSuccess,
    initiateEmailVerificationError,
  ]);

  // Lifecycle | Check for update | Verify Email Verification API Response
  useEffect(() => {
    if (verifyEmailVerificationLoading) {
    } else if (verifyEmailVerificationSuccess) {
      switch (verifyEmailVerificationData?.status) {
        case 0:
        // Not sure yet
        case 1:
          if (
            !getProfileData?.data?.verifications?.mobile?.verified &&
            getProfileData?.data?.verifications?.mobile?.verification_display
          ) {
            // Save the state of email and mobile verification required
            // Used for checking if email and mobile verification is skippable
            dispatch(
              updateMobileVerificationRequired(
                getProfileData?.data?.user?.verification?.mobile
                  ?.verification_required
              )
            );
          }

          dispatch(updateVerificationStatusDialog(true));

          const verificationData = {
            email: {
              verified: true,
              verification_display: false,
              verification_required: true,
            },
            mobile: {
              verified: getProfileData?.data?.verifications?.mobile?.verified,
              verification_display:
                getProfileData?.data?.verifications?.mobile
                  ?.verification_display,
              verification_required: true,
            },
          };
          dispatch(updateVerificationDataPassthrough(verificationData));

          // Animation is 2.5 seconds long
          setTimeout(() => {
            dispatch(updateVerificationStatusDialog(false));
            dispatch(updateVerificationNavigatePassthrough());
          }, 2500);
          break;
        case -1:
          // Mismatched Codes
          setInvalidCode(true);
          setOtp("");

          const errorToast = {
            message: verifyEmailVerificationData?.message,
            autoClose: 3000,
          };
          dispatch(updateErrorToast(errorToast));
          break;
        default:
          break;
      }
    } else if (verifyEmailVerificationError) {
      const errorToast = {
        message: verifyEmailVerificationErrorData?.data?.message,
        autoClose: 3000,
      };
      dispatch(updateErrorToast(errorToast));
    }
  }, [
    verifyEmailVerificationLoading,
    verifyEmailVerificationSuccess,
    verifyEmailVerificationError,
  ]);

  // Lifecycle | Check for update | emailVerificationTimer
  useEffect(() => {
    if (emailVerificationTimer && emailVerificationTimer <= 0) {
      dispatch(clearEmailVerificationInterval());
    }
  }, [emailVerificationTimer]);

  // Event Handlers | Button
  const onVerifyEmail = () => {
    if (otp?.length !== verificationConst.emailOtpMaxLength) return;

    // Backend has implemented hard code to bypass
    // if (otp === "987654") {
    //   const verificationObj = {
    //     email: {
    //       verified: true,
    //       verification_display: false,
    //       verification_required: true,
    //     },
    //     mobile: {
    //       verified: false,
    //       verification_display: true,
    //       verification_required: false,
    //     },
    //   }
    //   dispatch(updateVerificationDataPassthrough(verificationObj));
    //   dispatch(updateJourneyNavigationPassthrough({}));
    //   return;
    // } else if (otp === "123456") {
    //   const verificationObj = {
    //     email: {
    //       verified: true,
    //       verification_display: false,
    //       verification_required: true,
    //     },
    //     mobile: {
    //       verified: false,
    //       verification_display: false,
    //       verification_required: false,
    //     },
    //   }
    //   dispatch(updateVerificationDataPassthrough(verificationObj));
    //   dispatch(updateJourneyNavigationPassthrough({}));
    //   return;
    // }

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-58-58.1-button",
      },
    });

    const obj = {
      code: otp,
    };
    verifyEmailVerification(obj);
  };
  const onResendCode = () => {
    if (emailVerificationTimer > 0) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-58-58.2-button",
      },
    });

    initiateEmailVerification();
  };
  const onUpdateEmail = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-58-58.3-button",
      },
    });

    if (isLoggedIn) {
      onNavigate(routeConst.logout.path);
    } else {
      onNavigate(routeConst.accountSignup.path);
    }
  };
  const onNextPage = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-58-58.4-button",
      },
    });

    if (mobileVerificationDisplay) {
      if (type === utilityConst.fillingDetails.signup.type) {
        onNavigate(routeConst.phoneAuthentication.path);
      } else if (type === utilityConst.fillingDetails.missingDetails.type) {
        onNavigate(routeConst.phoneAuthentication.altPath);
      }
    } else if (isMissingDetails) {
      if (type === utilityConst.fillingDetails.signup.type) {
        // Will never reach here
        onNavigate(routeConst.infoSignup.path);
      } else if (type === utilityConst.fillingDetails.missingDetails.type) {
        onNavigate(routeConst.missingDetails.path);
      }
    } else {
      if (type === utilityConst.fillingDetails.signup.type) {
        onNavigate(routeConst.infoSignup.path);
      } else if (type === utilityConst.fillingDetails.missingDetails.type) {
        onNavigate(routeConst.search.path);
      }
    }
  };

  // Utility Functions
  const onEmailVerificationTimer = () => {
    dispatch(
      updateEmailVerificationInterval(
        setInterval(() => {
          dispatch(updateEmailVerificationTimer());
        }, 1000)
      )
    );
  };
  const handleVerificationCodeChange = (event) => {
    const input = event.target.value;
    const numericInput = input.replace(/\D/g, "");
    setOtp(numericInput);
  };
  const getEmail = () => {
    if (type === utilityConst.fillingDetails.signup.type) {
      return email || "-";
    } else if (type === utilityConst.fillingDetails.missingDetails.type) {
      return signinEmail || sessionService.getEmail() || "-";
    }
  };

  return (
    <div id="email-verification-page">
      <div className="max-width-container">
        <div className="padding-container">
          <div className="navbar-container">
            {type === utilityConst.fillingDetails.signup.type &&
              !isLoggedIn && (
                <div className="back-button" onClick={() => onNavigate(-1)}>
                  {getIcon("signupBackIcon", "signup-back-icon")}
                </div>
              )}
          </div>

          <div className="top-container">
            <div className="email-verification-top-container">
              <div className="enter-verification-label">
                <Trans
                  i18nKey={"verification.great_check_your_email"}
                  components={{ br: <br /> }}
                />
              </div>
              <div className="code-sent-label">
                <Trans
                  i18nKey={"verification.your_code_has_been_sent_to_email"}
                  values={{ email: getEmail() }}
                  components={{
                    span: <span className="email-label" />,
                  }}
                />
              </div>
            </div>

            <div className="email-verification-middle-container">
              <div className="code-input-container">
                <input
                  className="email-code-input"
                  maxLength={verificationConst.emailOtpMaxLength}
                  value={otp}
                  onChange={handleVerificationCodeChange}
                />
              </div>

              {invalidCode && (
                <div className="invalid-code-label">
                  {t("verification.this_code_is_invalid")}
                </div>
              )}

              <div className="resend-code-button-container">
                <div className="resend-code-description">
                  {t("verification.did_not_receive_the_verification_code")}
                </div>
                {initiateEmailVerificationLoading ? (
                  <Spinner size={20} isPadding={false} />
                ) : emailVerificationTimer > 0 ? (
                  <div
                    className="resend-code-label-container"
                    onClick={onResendCode}
                  >
                    <Trans
                      className={`resend-code-label ${
                        emailVerificationTimer > 0 ? "disable-button" : ""
                      }`}
                      i18nKey={"verification.resend_code_in_n_sec"}
                      values={{ n: emailVerificationTimer }}
                      components={{
                        span: <span className="resend-countdown-label" />,
                        div: (
                          <div
                            className={`resend-code-label ${
                              emailVerificationTimer > 0 ? "disable-button" : ""
                            }`}
                          />
                        ),
                      }}
                    />
                  </div>
                ) : (
                  <div
                    className="resend-code-label-container"
                    onClick={onResendCode}
                  >
                    <div className="resend-code-label">
                      {t("verification.resend_code")}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="bottom-container">
            <div className="email-verification-bottom-container">
              <div
                className={`submit-button ${
                  otp?.length !== verificationConst.emailOtpMaxLength
                    ? "disable-submit-button"
                    : ""
                }`}
                onClick={onVerifyEmail}
              >
                {verifyEmailVerificationLoading ? (
                  <Spinner
                    size={21}
                    isPadding={false}
                    color={"white-spinner"}
                  />
                ) : (
                  t("common.submit")
                )}
              </div>

              {emailVerificationRequired === false && (
                <div className="skip-button" onClick={onNextPage}>
                  {t("signup.skip_for_now")}
                </div>
              )}

              <div className="email-verification-utility-container">
                <div className="update-email-button-container">
                  <div className="update-email-description">
                    {t("verification.dont_have_access_to_this_email")}
                  </div>
                  <div className="update-email-label" onClick={onUpdateEmail}>
                    {t("verification.restart_registration")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isAnimationPlaying && (
          <div className="lottie-overlay-container">
            <Lottie
              className="general-success-lottie"
              animationData={getAsset("generalSuccessLottie")}
              autoPlay={true}
              loop={false}
            />

            <div className="verification-status-label">
              <Trans
                i18nKey={"verification.verification_successful"}
                components={{ br: <br /> }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmailVerification;
