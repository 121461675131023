// General
import "./result-end-card.scss";
// Static Data
import searchConst from "../../../../const/searchConst";
// i18next
import { useTranslation, Trans } from "react-i18next";

const ResultEndCard = (props) => {
  const { type = searchConst.endCardType.generic } = props;

  // i18next variables
  const { t } = useTranslation();

  return (
    <div id="result-end-card-shared-component">
      <div className="end-card-title">
        {type === searchConst.endCardType.videoCallSb ? (
          <Trans
            i18nKey={"search.eor.video_call_sb_title"}
            components={{ br: <br /> }}
          />
        ) : type === searchConst.endCardType.filtered ? (
          <Trans
            i18nKey={"search.eor.filtered_title"}
            components={{ br: <br /> }}
          />
        ) : (
          <Trans i18nKey={"search.eor.title"} components={{ br: <br /> }} />
        )}
      </div>

      <div className="end-card-description">
        {type === searchConst.endCardType.videoCallSb ? (
          <Trans
            i18nKey={"search.eor.video_call_sb_desc"}
            components={{ br: <br /> }}
          />
        ) : type === searchConst.endCardType.filtered ? (
          <Trans
            i18nKey={"search.eor.filtered_desc"}
            components={{ br: <br /> }}
          />
        ) : (
          <Trans i18nKey={"search.eor.desc"} components={{ br: <br /> }} />
        )}
      </div>
    </div>
  );
};

export default ResultEndCard;
