// General
import "./bottom-navbar.scss";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// Services
import {
  useLazyGetUserAioQuery,
  useLazyGetBadgeCountQuery,
} from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
import utilityConst from "../../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateSearchScrollToTopPassthrough } from "../../../../redux/store/searchStore";
import { updateInboxScrollToTopPassthrough } from "../../../../redux/store/inboxStore";
import { updateVideoCallScrollToTopPassthrough } from "../../../../redux/store/privateCallStore";
import { updateLivestreamScrollToTopPassthrough } from "../../../../redux/store/livestreamingStore";
import { updateInterestScrollToTopPassthrough } from "../../../../redux/store/interestStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import DefaultBottomNavbar from "./default-navbar/default-bottom-navbar";
import OctopusNavbar from "./octopus-navbar/octopus-navbar";

const BottomNavbar = () => {
  // API variables
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();
  const [
    getBadgeCount,
    {
      data: getBadgeCountData,
      error: getBadgeCountErrorData,
      isFetching: getBadgeCountFetching,
      isLoading: getBadgeCountLoading,
      isSuccess: getBadgeCountSuccess,
      isError: getBadgeCountError,
    },
  ] = useLazyGetBadgeCountQuery();

  // General variables
  let [showNavbar, setShowNavbar] = useState(true);
  const [currentRoute, setCurrentRoute] = useState("");

  // Redux variables
  const showNotificationBadge = useSelector(
    (state) => state.notification.showNotificationBadge
  );
  const mobileNavbarTheme = useSelector((state) => state.app.mobileNavbarTheme);
  const isLoggedIn = useSelector((state) => state.public.isLoggedIn);
  const releaseChannel = useSelector((state) => state.app.releaseChannel);
  const showVideoCall = useSelector((state) => state.app.showVideoCall);
  const dispatch = useDispatch();

  // Router variables
  const location = useLocation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!isLoggedIn) return;

    getUserAio(null, true);
    getBadgeCount(null, false);
  }, [isLoggedIn]);

  // Lifecycle | Check for update | Change navbar icon based on route
  useEffect(() => {
    if (!isLoggedIn) return;

    // Change Bottom Navbar Icon
    switch (location.pathname) {
      case routeConst.search.path:
        setCurrentRoute(routeConst.search.path);
        break;
      case routeConst.inbox.path:
      case routeConst.inbox.messaging.path:
      case routeConst.inbox.messaging.chat.path:
        setCurrentRoute(routeConst.inbox.path);
        break;
      case routeConst.videoCall.path:
        setCurrentRoute(routeConst.videoCall.path);
        break;
      case routeConst.live.path:
        setCurrentRoute(routeConst.live.path);
        break;
      case routeConst.interest.path:
        setCurrentRoute(routeConst.interest.path);
        break;
      case routeConst.profile.overview.path:
        setCurrentRoute(routeConst.profile.overview.path);
        break;
      default:
        break;
    }

    // Show / Hide Bottom Navbar
    switch (location.pathname) {
      case routeConst.search.path:
      case routeConst.inbox.path:
      case routeConst.live.path:
      case routeConst.interest.path:
      case routeConst.profile.overview.path:
      case routeConst.videoCall.path:
        setShowNavbar(true);
        break;
      default:
        setShowNavbar(false);
        break;
    }
  }, [isLoggedIn, location]);

  // Event Handlers | Button
  const onNavigateClick = (path) => {
    if (location.pathname === path) {
      // If same path, scroll to top
      switch (path) {
        case routeConst.search.path:
          dispatch(updateSearchScrollToTopPassthrough({}));
          break;
        case routeConst.inbox.path:
          dispatch(updateInboxScrollToTopPassthrough({}));
          break;
        case routeConst.videoCall.path:
          dispatch(updateVideoCallScrollToTopPassthrough({}));
          break;
        case routeConst.live.path:
          dispatch(updateLivestreamScrollToTopPassthrough({}));
          break;
        case routeConst.interest.path:
          dispatch(updateInterestScrollToTopPassthrough({}));
          break;
        case routeConst.profile.overview.path:
          break;
        default:
          break;
      }

      dispatch(updateSearchScrollToTopPassthrough({}));
    } else {
      getBadgeCount();

      let eventType = "";

      switch (path) {
        case routeConst.search.path:
          eventType = "Search";
          break;
        case routeConst.inbox.path:
          eventType = "Messages";
          break;
        case routeConst.videoCall.path:
          eventType = "Video";
          break;
        case routeConst.live.path:
          eventType = "Live";
          break;
        case routeConst.interest.path:
          eventType = "Interests";
          break;
        case routeConst.profile.overview.path:
          eventType = "ProfileOverview";
          break;
        default:
          break;
      }

      TagManager.dataLayer({
        dataLayer: {
          event: `PWA-45-${eventType}`,
        },
      });

      onNavigate(path);
    }
  };

  if (isLoggedIn && showNavbar) {
    if (mobileNavbarTheme === utilityConst.mobileNavbarTheme.default) {
      return (
        <DefaultBottomNavbar
          // Show/Hide Icon
          showSearch={true}
          showInbox={getUserAioData?.data?.modules?.messaging_enabled}
          showVideoCall={
            showVideoCall &&
            getUserAioData?.data?.modules?.private_calls_enabled
          }
          showLive={
            getUserAioData?.data?.modules?.live_streaming_enabled ||
            getUserAioData?.data?.modules?.private_calls_enabled
          }
          showInterest={true}
          showProfile={true}
          // Badge Count
          inboxBadgeCount={getBadgeCountData?.data?.counts?.new_message ? 1 : 0}
          interestBadgeCount={showNotificationBadge ? 1 : 0}
          // Selected Icon
          currentRoute={currentRoute}
          // Callback Functions
          onNavigateClickCallback={onNavigateClick}
        />
      );
    } else if (mobileNavbarTheme === utilityConst.mobileNavbarTheme.octopus) {
      return (
        <OctopusNavbar
          showSearch={true}
          showInbox={getUserAioData?.data?.modules?.messaging_enabled}
          showVideoCall={
            showVideoCall &&
            getUserAioData?.data?.modules?.private_calls_enabled
          }
          showLive={
            getUserAioData?.data?.modules?.live_streaming_enabled ||
            getUserAioData?.data?.modules?.private_calls_enabled
          }
          showInterest={true}
          showProfile={true}
          // Badge Count
          inboxBadgeCount={getBadgeCountData?.data?.counts?.new_message}
          interestBadgeCount={showNotificationBadge ? 1 : 0}
          // Selected Icon
          currentRoute={currentRoute}
          // Callback Functions
          onNavigateClickCallback={onNavigateClick}
        />
      );
    } else {
      return (
        <DefaultBottomNavbar
          showSearch={true}
          showInbox={getUserAioData?.data?.modules?.messaging_enabled}
          showVideoCall={
            showVideoCall &&
            getUserAioData?.data?.modules?.private_calls_enabled
          }
          showLive={
            getUserAioData?.data?.modules?.live_streaming_enabled ||
            getUserAioData?.data?.modules?.private_calls_enabled
          }
          showInterest={true}
          showProfile={true}
          // Badge Count
          inboxBadgeCount={getBadgeCountData?.data?.counts?.new_message ? 1 : 0}
          interestBadgeCount={showNotificationBadge ? 1 : 0}
          // Selected Icon
          currentRoute={currentRoute}
          // Callback Functions
          onNavigateClickCallback={onNavigateClick}
        />
      );
    }
  }
};

export default BottomNavbar;
