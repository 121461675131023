// General
import "./photos-tab.scss";
import { useState, useEffect } from "react";
// Services
import { useLazyGetProfileQuery } from "../../../../../../services/data.service";
// Static Data
import userConst from "../../../../../../const/userConst";
import profileConst from "../../../../../../const/profileConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateProfilePreviewPhotoInfo } from "../../../../../../redux/store/profileStore";
import { updatePaymentTag } from "../../../../../../redux/store/paymentStore";
import {
  updateRequestPrivatePhotoDialog,
  updatePreviewPhotoDialog,
  updateUserProfileDialog,
  updateOnboardingSugarbookVerifiedDialog,
} from "../../../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../../../utility/manager/icon-manager/icon-manager";
import routeConst from "../../../../../../const/routeConst";

const PhotosTab = (props) => {
  const { userProfileType } = props;

  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // General variables
  const [isBlur, setIsBlur] = useState(true);
  let organizedAllPhotos = [];

  // Render variables
  let publicPhotoView = <div></div>;
  let privatePhotoView = <div></div>;

  // Redux variables
  const idInt = useSelector((state) => state.userCard.idInt);
  const publicPhotos = useSelector((state) => state.userCard.publicPhotos);
  const privatePhotos = useSelector((state) => state.userCard.privatePhotos);
  const profileStatus = useSelector((state) => state.userCard.profileStatus);
  const privatePhotoRequested = useSelector(
    (state) => state.userCard.privatePhotoRequested
  );
  const privatePhotoApproval = useSelector(
    (state) => state.userCard.privatePhotoApproval
  );
  const ownMembershipType = useSelector((state) => state.user.membershipType);
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  // Event Handlers | Button
  const onHandlePrivatePhoto = (allPhotos, currentPhoto) => {
    if (
      ownMembershipType === userConst.membershipType.admin ||
      (privatePhotoRequested && privatePhotoApproval)
    ) {
      if (!isBlur) {
        onPreviewPhoto(allPhotos, currentPhoto);
      }
    } else {
      onRequestPrivatePhoto();
    }
  };
  const onRequestPrivatePhoto = () => {
    if (
      ownMembershipType === userConst.membershipType.admin ||
      privatePhotoRequested ||
      privatePhotoApproval
    )
      return;

    if (isDaddy) {
      if (ownMembershipType === userConst.membershipType.free) {
        TagManager.dataLayer({
          dataLayer: {
            event: "PWA-30-30.3-30.3.1-Button",
          },
        });

        dispatch(updatePaymentTag("pwa-user-profile-request-private-photo"));
        dispatch(updateUserProfileDialog(false));
        onNavigate(routeConst.upgrade.path);
      } else {
        TagManager.dataLayer({
          dataLayer: {
            event: "PWA-UserProfile-Page-PhotosTab-RequestPrivatePhoto-Button",
          },
        });

        dispatch(updateRequestPrivatePhotoDialog(true));
      }
    } else {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-UserProfile-Page-PhotosTab-RequestPrivatePhoto-Button",
        },
      });

      // BUSINESS LOGIC
      // dispatch(updateOnboardingSugarbookVerifiedDialog(true));
      dispatch(updateRequestPrivatePhotoDialog(true));
    }
  };
  const onPreviewPhoto = (allPhotos, currentPhoto) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-30-30.3-30.3.2-Button",
      },
    });

    const newIndex = allPhotos.findIndex(
      (photo) => photo?.photoUrl === currentPhoto?.photoUrl
    );

    const obj = {
      id: null,
      photoUrl: currentPhoto?.photoUrl,
      allPhotos,
      index: newIndex,
    };
    dispatch(updateProfilePreviewPhotoInfo(obj));
    dispatch(updatePreviewPhotoDialog(true));
  };
  const onUnblurPhoto = () => {
    setIsBlur(false);
  };

  // Render Functions
  if (getProfileData?.data?.id_int === idInt) {
    const organizedPublicPhotos =
      publicPhotos?.filter((photo) => photo.status !== "REJECTED") || [];
    const organizedPrivatePhotos =
      privatePhotos?.filter((photo) => photo.status !== "REJECTED") || [];

    organizedAllPhotos = [...organizedPublicPhotos, ...organizedPrivatePhotos];

    if (organizedPublicPhotos?.length > 0) {
      publicPhotoView = (
        <div className="public-photo-main-container">
          <div className="public-photo-label">{t("profile.public_photos")}</div>

          {organizedPublicPhotos?.length > 0 && (
            <div className="public-photo-container">
              <div className="public-photo-image-container">
                {organizedPublicPhotos?.map((photo, index) => (
                  <div
                    className="public-image-container"
                    onClick={() => onPreviewPhoto(organizedAllPhotos, photo)}
                    key={index}
                  >
                    <img
                      className="public-image"
                      src={photo?.photoUrl}
                      alt="public"
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      );
    }

    if (organizedPrivatePhotos?.length > 0) {
      privatePhotoView = (
        <div className="private-photo-main-container">
          <div className="private-photo-label-container">
            <div className="private-photo-label">
              {t("profile.private_photos")}
            </div>
            {privatePhotoRequested && !privatePhotoApproval && (
              <div className="requested-label">
                {t("profile.private_requested")}
              </div>
            )}
          </div>

          {organizedPrivatePhotos?.length > 0 && (
            <div className="private-photo-container">
              <div className="private-photo-image-container">
                {organizedPrivatePhotos?.map((photo, index) => (
                  <div
                    className="private-image-container"
                    onClick={() => onHandlePrivatePhoto(photo)}
                    key={index}
                  >
                    <img
                      className={`private-image ${
                        !privatePhotoApproval ? "" : ""
                      }`}
                      src={photo?.photoUrl}
                      alt="private"
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      );
    }
  } else {
    let organizedPublicPhotos = [];
    let organizedPrivatePhotos = [];

    // Public Photo render
    if (publicPhotos?.length > 0) {
      organizedPublicPhotos = publicPhotos;

      publicPhotoView = (
        <div className="public-photo-main-container">
          <div className="public-photo-label">{t("profile.public_photos")}</div>

          {publicPhotos?.length > 0 && (
            <div className="public-photo-container">
              <div className="public-photo-image-container">
                {publicPhotos?.map((photo, index) => (
                  <div
                    className="public-image-container"
                    onClick={() => onPreviewPhoto(organizedAllPhotos, photo)}
                    key={index}
                  >
                    <img
                      className="public-image"
                      src={photo?.photoUrl}
                      alt="public"
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      );
    }

    // Private Photo render
    if (privatePhotos?.length > 0) {
      // Organize private photo based on privatePhotoApproval
      if (privatePhotoApproval) {
        organizedPrivatePhotos = privatePhotos;
      }

      privatePhotoView = (
        <div className="private-photo-main-container">
          <div className="private-photo-label-container">
            <div className="private-photo-label">
              {t("profile.private_photos")}
            </div>
            {privatePhotoRequested && !privatePhotoApproval && (
              <div className="requested-label">
                {t("profile.private_requested")}
              </div>
            )}
          </div>

          {privatePhotos?.length > 0 && (
            <div className="private-photo-container">
              <div className="private-photo-image-container">
                {privatePhotos?.map((photo, index) => (
                  <div
                    className="private-image-container"
                    onClick={() =>
                      onHandlePrivatePhoto(organizedAllPhotos, photo)
                    }
                    key={index}
                  >
                    <img
                      className={`private-image ${
                        !privatePhotoApproval ? "" : ""
                      }`}
                      src={
                        photo?.photoUrl
                          ? photo?.photoUrl
                          : `data:image/png;base64,${photo?.thumbnailData}`
                      }
                      alt="private"
                    />

                    {getProfileData?.data?.id_int !== idInt &&
                      ownMembershipType !== userConst.membershipType.admin &&
                      !privatePhotoApproval && (
                        <div className="private-photo-overlay-container">
                          <div
                            className={
                              userProfileType ===
                              profileConst.userProfileType.page
                                ? "blur-filter"
                                : "background-filter"
                            }
                          ></div>

                          <div className="lock-container">
                            <div className="lock-icon-container">
                              {getIcon("privatePhotoLockIcon", "lock-icon")}
                            </div>
                            <div className="private-photo-label">
                              {t("profile.private_photos")}
                            </div>
                          </div>
                        </div>
                      )}

                    {getProfileData?.data?.id_int !== idInt &&
                      ownMembershipType !== userConst.membershipType.admin &&
                      privatePhotoApproval &&
                      photo?.isSensitive &&
                      isBlur && (
                        <div className="blur-filter-container">
                          <div className="sensitive-icon-container">
                            {getIcon("sensitiveIcon", "sensitive-icon")}
                          </div>

                          <div className="blur-filter-title">
                            {t("profile.sensitive_content")}
                          </div>

                          <div className="blur-filter-description">
                            <Trans
                              i18nKey={"profile.sensitive_content_desc"}
                              components={{ br: <br /> }}
                            />
                          </div>

                          <div
                            className="blur-filter-cta"
                            onClick={onUnblurPhoto}
                          >
                            {t("profile.sensitive_content_cta")}
                          </div>
                        </div>
                      )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      );
    }

    organizedAllPhotos = [...organizedPublicPhotos, ...organizedPrivatePhotos];
  }

  return (
    <div id="user-profile-tab-photos-tab-subcomponent">
      {publicPhotoView}
      {privatePhotoView}
    </div>
  );
};

export default PhotosTab;
