// General
import "./registration-profile-almost-complete-dialog.scss";
import { useEffect, forwardRef } from "react";
// Services
import { useLazyGetProfileCompletionQuery } from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateProfileCompletionPercentage,
  updateProfileRequirementPopup,
} from "../../../../redux/store/profileStore";
import { updateRegistrationProfileAlmostCompleteDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide, CircularProgress, useMediaQuery } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import CustomAvatar from "../../elements/custom-avatar/custom-avatar";

const RegistrationProfileAlmostCompleteDialog = () => {
  // API variables
  const [
    getProfileCompletion,
    {
      data: getProfileCompletionData,
      error: getProfileCompletionErrorData,
      isFetching: getProfileCompletionFetching,
      isLoading: getProfileCompletionLoading,
      isSuccess: getProfileCompletionSuccess,
      isError: getProfileCompletionError,
    },
  ] = useLazyGetProfileCompletionQuery();

  // Redux variables
  const registrationProfileAlmostCompleteDialog = useSelector(
    (state) => state.dialog.registrationProfileAlmostCompleteDialog
  );
  const profileCompletionPercentage = useSelector(
    (state) => state.profile.profileCompletionPercentage
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    if (registrationProfileAlmostCompleteDialog) {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-D39",
        },
      });

      getProfileCompletion(null, false);
    }
  }, [registrationProfileAlmostCompleteDialog]);

  // Lifecycle | Check for update | getProfileCompletion API Response
  useEffect(() => {
    if (getProfileCompletionFetching || getProfileCompletionLoading) {
    } else if (getProfileCompletionSuccess) {
      if (getProfileCompletionData?.status === 0) {
        dispatch(
          updateProfileCompletionPercentage(
            getProfileCompletionData?.data?.profile_progress?.summary?.progress
          )
        );
        dispatch(
          updateProfileRequirementPopup(
            getProfileCompletionData?.data?.profile_progress?.summary
              ?.requirement
          )
        );
      }
    } else if (getProfileCompletionError) {
    }
  }, [
    getProfileCompletionFetching,
    getProfileCompletionLoading,
    getProfileCompletionSuccess,
    getProfileCompletionError,
  ]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-D39-39.1",
      },
    });

    dispatch(updateRegistrationProfileAlmostCompleteDialog(false));
  };

  return (
    <Dialog
      className={
        isMobile
          ? "custom-bottom-sheet-no-radius-fullscreen-dialog"
          : "custom-radius10-dialog"
      }
      fullScreen={isMobile ? true : false}
      // scroll={"body"}
      open={registrationProfileAlmostCompleteDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      <div
        id="registration-profile-almost-complete-dialog"
        className={isMobile ? "" : "add-max-width"}
      >
        {/* <div className="dialog-header">
          <div className="left-container" onClick={onCloseDialog}>
            <ChevronLeftIcon className="back-icon" />
          </div>
          <div className="middle-container"></div>
          <div className="right-container"></div>
        </div> */}

        <div className="restart-journey-container">
          <div className="profile-photo-container">
            {profileCompletionPercentage < 100 && (
              <CircularProgress
                className="default-circular-progress-bar"
                variant="determinate"
                value={100}
              />
            )}
            {profileCompletionPercentage < 100 && (
              <CircularProgress
                className="circular-progress-bar"
                variant="determinate"
                value={profileCompletionPercentage || 0}
              />
            )}

            <CustomAvatar
              className={`profile-photo ${
                profileCompletionPercentage < 100
                  ? "profile-photo-absolute"
                  : ""
              }`}
              src={
                getProfileCompletionData?.data?.profile_photo?.photo_status !==
                "REJECTED"
                  ? getProfileCompletionErrorData?.data?.profile_photo
                      ?.original_photo
                  : null
              }
              role={getProfileCompletionData?.data?.role}
              profileBorder={
                getProfileCompletionData?.data?.profile_decorations
                  ?.profile_border_url
              }
            />

            {profileCompletionPercentage < 100 && (
              <div
                className="profile-completed-percentage-container"
                onClick={() => onNavigate(routeConst.profile.completion.path)}
              >
                {profileCompletionPercentage || 0}%
              </div>
            )}
          </div>

          <div className="restart-journey-title">
            {t("registration.profile_almost_complete.title")}
          </div>

          <div className="restart-journey-description">
            {t("registration.profile_almost_complete.description")}
          </div>

          <div className="restart-journey-body-container">
            <div className="option-1-container">
              <div className="option-icon-container">
                {getIcon("whiteGiftIcon", "option-icon")}
              </div>

              <div className="option-label-container">
                <div className="option-title">
                  {t("registration.profile_almost_complete.option_1_title")}
                </div>
                <div className="option-description">
                  {t(
                    "registration.profile_almost_complete.option_1_description"
                  )}
                </div>
              </div>
            </div>

            <div className="option-2-container">
              <div className="option-icon-container">
                {getIcon("checkSquareIcon", "option-icon")}
              </div>

              <div className="option-label-container">
                <div className="option-title">
                  {t("registration.profile_almost_complete.option_2_title")}
                </div>
                <div className="option-description">
                  {t(
                    "registration.profile_almost_complete.option_2_description"
                  )}
                </div>
              </div>
            </div>

            <div className="option-3-container">
              <div className="option-icon-container">
                {getIcon("whiteLoaderIcon", "option-icon")}
              </div>

              <div className="option-label-container">
                <div className="option-title">
                  {t("registration.profile_almost_complete.option_3_title")}
                </div>
                <div className="option-description">
                  {t(
                    "registration.profile_almost_complete.option_3_description"
                  )}
                </div>
              </div>
            </div>
          </div>

          {false && (
            <div className="restart-journey-footer">
              {t("registration.profile_almost_complete.footer")}
            </div>
          )}
        </div>

        <div className="bottom-container">
          <div className="continue-button" onClick={onCloseDialog}>
            {t("registration.profile_almost_complete.back_to_sugarbook")}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default RegistrationProfileAlmostCompleteDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
