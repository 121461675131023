// General
import "./details-signup.scss";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// Services
import {
  useLazyGetJourneyQuestionsQuery,
  useLazyGetJourneyQuery,
} from "../../../services/data.service";
// Static Data
import user from "../../../enums/user";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateUsername,
  updateBirthdate,
  updateRegistrationActiveTab,
  updateAnnualIncomeIndex,
  updateAnnualIncome,
  updateNetworthIndex,
  updateNetworth,
  updateLifestyle,
  updateDatingBudget,
  updateRelationshipStatus,
  updatePosition,
  updateIndustry,
  updateDatingStyles,
  updateEthnicity,
  updateRace,
  updatePublicFantasies,
  updatePrivateFantasies,
  updateSmoking,
  updateDrinking,
  updateAlcoholPreference,
  updateAboutMeQuestions,
  updateAboutMeState,
  updateAboutMeDisableState,
  updateJoinLivestreamCommunity,
  updateLookingForAgeRangeMax,
  updateLookingForAgeRangeMin,
  updateLookingForLocation,
  updateLookingForEthnicity,
  updateRegistrationNavigationData,
  updateAboutMeList,
  updateAboutMeLength,
  updateSelectedAboutMe,

  // Utility Functions
  updateIsInJourney,
} from "../../../redux/store/registrationStore";
import { updateGender, updateWantToBe } from "../../../redux/store/signupStore";
// embla-carousel-react
import useEmblaCarousel from "embla-carousel-react";
// Material UI
import { useMediaQuery } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Components
import RegistrationNavigationBar from "./registration-navigation-bar/registration-navigation-bar";
import TermsOfRelationshipDetails from "./terms-of-relationship/terms-of-relationship";
import AnnualIncomeDetails from "./annual-income/annual-income";
import NetWorthDetails from "./net-worth/net-worth";
import LifestyleBudgetDetails from "./lifestyle-budget/lifestyle-budget";
import RelationshipStatusDetails from "./relationship-status/relationship-status";
import OccupationDetails from "./occupation/occupation";
import DatingStylesDetails from "./dating-styles/dating-styles";
import BedroomFantasiesDetails from "./bedroom-fantasies/bedroom-fantasies";
import EthnicityDetails from "./ethnicity/ethnicity";
import RaceDetails from "./race/race";
import SmokingDetails from "./smoking/smoking";
import DrinkingDetails from "./drinking/drinking";
import AlcoholPreferenceDetails from "./alcohol-preference/alcohol-preference";
import AboutMeDetails from "./about-me/about-me";
import LookingForDetails from "./looking-for/looking-for";
import LivestreamingDetails from "./livestreaming/livestreaming";
import PhotosDetails from "./photos/photos";

const DetailsSignup = () => {
  // API variables
  const [
    getJourneyQuestions,
    {
      data: getJourneyQuestionsData,
      error: getJourneyQuestionsErrorData,
      isFetching: getJourneyQuestionsFetching,
      isLoading: getJourneyQuestionsLoading,
      isSuccess: getJourneyQuestionsSuccess,
      isError: getJourneyQuestionsError,
    },
  ] = useLazyGetJourneyQuestionsQuery();
  const [
    getJourney,
    {
      data: getJourneyData,
      error: getJourneyErrorData,
      isFetching: getJourneyFetching,
      isLoading: getJourneyLoading,
      isSuccess: getJourneySuccess,
      isError: getJourneyError,
    },
  ] = useLazyGetJourneyQuery();

  // Redux variables
  const registrationNavigationData = useSelector(
    (state) => state.registration.registrationNavigationData
  );
  const registrationActiveTab = useSelector(
    (state) => state.registration.registrationActiveTab
  );
  const detailsSignupScroll = useSelector(
    (state) => state.registration.detailsSignupScroll
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // Embla variables
  const [emblaRef, emblaApi] = useEmblaCarousel({
    duration: 15,
    watchDrag: false,
  });

  // Router variables
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const active = queryParams?.get("active");

  // i18next variables
  const { i18n } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    getJourneyQuestions(null, true);
    dispatch(updateIsInJourney(true));
  }, []);

  // Lifecycle | Check for update | Journey Questions API Response
  useEffect(() => {
    if (getJourneyQuestionsFetching || getJourneyQuestionsLoading) {
    } else if (getJourneyQuestionsSuccess) {
      getJourney(null, false);
    } else if (getJourneyQuestionsError) {
    }
  }, [
    getJourneyQuestionsFetching,
    getJourneyQuestionsLoading,
    getJourneyQuestionsSuccess,
    getJourneyQuestionsError,
  ]);

  // Lifecycle | Check for update | Journey API Response
  useEffect(() => {
    if (getJourneyFetching || getJourneyLoading) {
    } else if (getJourneySuccess) {
      if (getJourneyData?.user_journey) {
        let annualIncomeExist = false;
        let networthExist = false;
        let lifestyleExist = false;
        let relationshipStatusExist = false;
        let positionExist = false;
        let industryExist = false;
        let datingStylesExist = false;
        let bedroomFantasiesExist = false;
        let ethnicityExist = false;
        let raceExist = false;
        let smokingExist = false;
        let drinkingExist = false;
        let alcoholPreferenceExist = false;
        let aboutMeExist = false;
        let lookingForExist = false;
        let livestreamExist = false;

        // Annual Income
        if (
          getJourneyData?.user_journey?.journey_data?.profile?.annual_income
        ) {
          annualIncomeExist = true;

          const getAnnualIncomeData = getJourneyQuestionsData?.selections?.find(
            (item) => item?.category === "annual_income"
          )?.selections;
          const annualIncomeId =
            getJourneyData?.user_journey?.journey_data?.profile?.annual_income;
          const annualIncome = getAnnualIncomeData?.find(
            (item) => item?.id === annualIncomeId
          );
          dispatch(updateAnnualIncome(annualIncome?.id));
          const index = getAnnualIncomeData?.findIndex(
            (item) => item?.id === annualIncomeId
          );
          if (index !== -1) {
            dispatch(updateAnnualIncomeIndex(index + 2));
          }
        }

        // Networth
        if (getJourneyData?.user_journey?.journey_data?.profile?.networth) {
          networthExist = true;

          const getNetworthData = getJourneyQuestionsData?.selections?.find(
            (item) => item?.category === "networth"
          )?.selections;
          const networthId =
            getJourneyData?.user_journey?.journey_data?.profile?.networth;
          const networth = getNetworthData?.find(
            (item) => item?.id === networthId
          );
          dispatch(updateNetworth(networth?.id));
          const index = getNetworthData?.findIndex(
            (item) => item?.id === networthId
          );
          if (index !== -1) {
            dispatch(updateNetworthIndex(index + 2));
          }
        }

        // Lifestyle
        if (getJourneyData?.user_journey?.journey_data?.profile?.lifestyle) {
          lifestyleExist = true;

          const getLifestyleData = getJourneyQuestionsData?.selections?.find(
            (item) => item?.category === "lifestyle"
          )?.selections;
          const lifestyleId =
            getJourneyData?.user_journey?.journey_data?.profile?.lifestyle;
          const lifestyle = getLifestyleData?.find(
            (item) => item?.id === lifestyleId
          );
          dispatch(updateLifestyle(lifestyle));
        }

        // Dating Budget
        if (
          getJourneyData?.user_journey?.journey_data?.profile?.dating_budget
        ) {
          lifestyleExist = true;

          const getDatingBudgetData = getJourneyQuestionsData?.selections?.find(
            (item) => item?.category === "dating_budget"
          )?.selections;
          const datingBudgetId =
            getJourneyData?.user_journey?.journey_data?.profile?.dating_budget;
          const datingBudget = getDatingBudgetData?.find(
            (item) => item?.id === datingBudgetId
          );
          dispatch(updateDatingBudget(datingBudget));
        }

        // Relationship Status
        if (getJourneyData?.user_journey?.journey_data?.profile?.relationship) {
          relationshipStatusExist = true;

          const getRelationshipStatusData =
            getJourneyQuestionsData?.selections?.find(
              (item) => item?.category === "relationship"
            )?.selections;

          const relatiionshipStatusId =
            getJourneyData?.user_journey?.journey_data?.profile?.relationship;
          const relationshipStatus = getRelationshipStatusData?.find(
            (item) => item?.id === relatiionshipStatusId
          );
          dispatch(updateRelationshipStatus(relationshipStatus));
        }

        // Position
        if (getJourneyData?.user_journey?.journey_data?.profile?.position) {
          positionExist = true;

          const getPositionData = getJourneyQuestionsData?.selections?.find(
            (item) => item?.category === "position"
          )?.selections;

          const positionId =
            getJourneyData?.user_journey?.journey_data?.profile?.position;
          const position = getPositionData?.find(
            (item) => item?.id === positionId
          );
          dispatch(updatePosition(position));
        }

        // Industry
        if (getJourneyData?.user_journey?.journey_data?.profile?.industry) {
          industryExist = true;

          const getIndustryData = getJourneyQuestionsData?.selections?.find(
            (item) => item?.category === "industry"
          )?.selections;

          const industryId =
            getJourneyData?.user_journey?.journey_data?.profile?.industry;
          const industry = getIndustryData?.find(
            (item) => item?.id === industryId
          );
          dispatch(updateIndustry(industry));
        }

        // Dating Styles
        if (getJourneyData?.user_journey?.journey_data?.profile?.dating_style) {
          datingStylesExist = true;

          const getDatingStylesData = getJourneyQuestionsData?.selections?.find(
            (item) => item?.category === "dating_style"
          )?.selections;

          const datingStyleIds =
            getJourneyData?.user_journey?.journey_data?.profile?.dating_style;
          const datingStyles = getDatingStylesData?.filter((item) =>
            datingStyleIds?.includes(item?.id)
          );
          dispatch(updateDatingStyles(datingStyles));
        }

        // Bedroom Fantasies
        if (getJourneyData?.user_journey?.journey_data?.profile?.br_fantasy) {
          bedroomFantasiesExist = true;

          const getBedroomFantasiesData =
            getJourneyQuestionsData?.selections?.find(
              (item) => item?.category === "br_fantasy"
            )?.selections;

          const bedroomFantasiesIds =
            getJourneyData?.user_journey?.journey_data?.profile?.br_fantasy;
          const bedroomFantasiesPrivateIds =
            getJourneyData?.user_journey?.journey_data?.profile
              ?.br_fantasy_private;
          const bedroomFantasiesPublic = getBedroomFantasiesData?.filter(
            (item) => bedroomFantasiesIds?.includes(item?.id)
          );
          const bedroomFantasiesPrivate = getBedroomFantasiesData?.filter(
            (item) => bedroomFantasiesPrivateIds?.includes(item?.id)
          );
          dispatch(updatePublicFantasies(bedroomFantasiesPublic));
          dispatch(updatePrivateFantasies(bedroomFantasiesPrivate));
        }

        // Ethnicity
        if (getJourneyData?.user_journey?.journey_data?.profile?.ethnicity) {
          ethnicityExist = true;

          const getEthnicityData = getJourneyQuestionsData?.selections?.find(
            (item) => item?.category === "ethnicity"
          )?.selections;

          const ethnicityId =
            getJourneyData?.user_journey?.journey_data?.profile?.ethnicity;
          const ethnicity = getEthnicityData?.find(
            (item) => item?.id === ethnicityId
          );
          dispatch(updateEthnicity(ethnicity));
        }

        // Race
        if (getJourneyData?.user_journey?.journey_data?.profile?.race) {
          raceExist = true;

          const getRaceData = getJourneyQuestionsData?.selections?.find(
            (item) => item?.category === "race"
          )?.selections;

          const raceId =
            getJourneyData?.user_journey?.journey_data?.profile?.race;
          const race = getRaceData?.find((item) => item?.id === raceId);
          dispatch(updateRace(race));
        }

        // Smoking
        if (getJourneyData?.user_journey?.journey_data?.profile?.smoking) {
          smokingExist = true;

          const getSmokingData = getJourneyQuestionsData?.selections?.find(
            (item) => item?.category === "smoking"
          )?.selections;

          const smokingId =
            getJourneyData?.user_journey?.journey_data?.profile?.smoking;
          const smoking = getSmokingData?.find(
            (item) => item?.id === smokingId
          );
          dispatch(updateSmoking(smoking));
        }

        // Drinking
        if (getJourneyData?.user_journey?.journey_data?.profile?.drinking) {
          drinkingExist = true;

          const getDrinkingData = getJourneyQuestionsData?.selections?.find(
            (item) => item?.category === "drinking"
          )?.selections;

          const drinkingId =
            getJourneyData?.user_journey?.journey_data?.profile?.drinking;
          const drinking = getDrinkingData?.find(
            (item) => item?.id === drinkingId
          );
          dispatch(updateDrinking(drinking));
        }

        // Alcohol Preference
        if (getJourneyData?.user_journey?.journey_data?.profile?.alcohol) {
          alcoholPreferenceExist = true;

          const getAlcoholPreferenceData =
            getJourneyQuestionsData?.selections?.find(
              (item) => item?.category === "alcohol"
            )?.selections;

          const alcoholPreferenceIds =
            getJourneyData?.user_journey?.journey_data?.profile?.alcohol;
          const alcoholPreference = getAlcoholPreferenceData?.filter((item) =>
            alcoholPreferenceIds?.includes(item?.id)
          );
          dispatch(updateAlcoholPreference(alcoholPreference));
        }

        // About Me
        const personalityType =
          getJourneyData?.user_journey?.journey_data?.profile?.personality_type
            ?.length > 0
            ? getJourneyData?.user_journey?.journey_data?.profile
                ?.personality_type
            : null;
        const hobbies =
          getJourneyData?.user_journey?.journey_data?.profile?.hobbies?.length >
          0
            ? getJourneyData?.user_journey?.journey_data?.profile?.hobbies
            : null;
        const fitnessLevel =
          getJourneyData?.user_journey?.journey_data?.profile?.fitness_level;
        const starSign =
          getJourneyData?.user_journey?.journey_data?.profile?.star_sign;
        const dominantOrSubmissive =
          getJourneyData?.user_journey?.journey_data?.profile
            ?.dominant_or_submissive;
        const personalityTraits =
          getJourneyData?.user_journey?.journey_data?.profile
            ?.personality_traits;

        let tempAboutMeList = [];
        let tempAboutMeLength = 0;
        let tempSelectedAboutMe = [];

        const pushAboutMe = (name, data) => {
          tempAboutMeList?.push({ name, data });
          if (data) {
            tempAboutMeLength++;
            tempSelectedAboutMe?.push({ name, data });
          }
        };

        pushAboutMe(
          "personality_type",
          personalityType?.map((item) =>
            getAboutMeRemap("personality_type", item)
          )
        );
        pushAboutMe(
          "hobbies",
          hobbies?.map((item) => getAboutMeRemap("hobbies", item))
        );
        pushAboutMe("star_sign", getAboutMeRemap("star_sign", starSign));
        pushAboutMe(
          "fitness_level",
          getAboutMeRemap("fitness_level", fitnessLevel)
        );
        pushAboutMe(
          "dominant_or_submissive",
          getAboutMeRemap("dominant_or_submissive", dominantOrSubmissive)
        );
        pushAboutMe(
          "personality_traits",
          getAboutMeRemap("personality_traits", personalityTraits)
        );

        // If tempSelectedAboutMe has less than 3 items, add empty items
        if (tempSelectedAboutMe?.length < 3) {
          const emptyItems = 3 - tempSelectedAboutMe?.length;
          for (let i = 0; i < emptyItems; i++) {
            tempSelectedAboutMe.push({ name: null, data: null });
          }
        }

        dispatch(updateAboutMeList(tempAboutMeList));
        dispatch(updateAboutMeLength(tempAboutMeLength));
        dispatch(updateSelectedAboutMe(tempSelectedAboutMe));

        if (tempAboutMeLength > 0) {
          aboutMeExist = true;
        }

        let lookingForStateCounter = 0;
        // Looking For | Age Range
        if (getJourneyData?.user_journey?.journey_data?.looking_for?.age_max) {
          lookingForStateCounter++;

          dispatch(
            updateLookingForAgeRangeMax(
              getJourneyData?.user_journey?.journey_data?.looking_for?.age_max
            )
          );
          dispatch(
            updateLookingForAgeRangeMin(
              getJourneyData?.user_journey?.journey_data?.looking_for?.age_min
            )
          );
        }
        // Looking For | Locations
        if (
          getJourneyData?.user_journey?.journey_data?.looking_for?.locations
            ?.length > 0
        ) {
          lookingForStateCounter++;

          dispatch(
            updateLookingForLocation(
              getJourneyData?.user_journey?.journey_data?.looking_for?.locations
            )
          );
        }
        // Looking For | Ethnicities
        if (
          getJourneyData?.user_journey?.journey_data?.looking_for?.ethnicities
            ?.length > 0
        ) {
          lookingForStateCounter++;

          const getEthnicityData = getJourneyQuestionsData?.selections?.find(
            (item) => item?.category === "ethnicity"
          )?.selections;
          const getRaceData = getJourneyQuestionsData?.selections?.find(
            (item) => item?.category === "race"
          )?.selections;

          const organizedData =
            getJourneyData?.user_journey?.journey_data?.looking_for?.ethnicities?.map(
              (ethnicityRace) => {
                const ethnicityId = ethnicityRace?.ethnicity;
                const raceId = ethnicityRace?.race;

                const ethnicity = getEthnicityData?.find(
                  (item) => item?.id === ethnicityId
                );
                const race = getRaceData?.find((item) => item?.id === raceId);

                return { ethnicity, race };
              }
            );

          dispatch(updateLookingForEthnicity(organizedData));
        }

        if (lookingForStateCounter === 3) {
          lookingForExist = true;
        }

        // Livestream
        dispatch(
          updateGender(getJourneyData?.user_journey?.journey_data?.gender)
        );
        dispatch(
          updateWantToBe(getJourneyData?.user_journey?.journey_data?.wanttobe)
        );
        if (
          getJourneyData?.user_journey?.journey_data?.gender === user.gender.MAN
        ) {
          if (
            getJourneyData?.user_journey?.journey_data?.livestream
              ?.date_streamer
          ) {
            livestreamExist = true;

            const getDateStreamerData =
              getJourneyQuestionsData?.selections?.find(
                (item) => item?.category === "date_streamer"
              )?.selections;
            const joinLivestreamCommunityIds =
              getJourneyData?.user_journey?.journey_data?.livestream
                ?.date_streamer;
            const joinLivestreamCommunity = getDateStreamerData?.find(
              (item) => item?.id === joinLivestreamCommunityIds
            );
            dispatch(updateJoinLivestreamCommunity(joinLivestreamCommunity));
          }
        } else if (
          getJourneyData?.user_journey?.journey_data?.gender ===
          user.gender.WOMAN
        ) {
          if (
            getJourneyData?.user_journey?.journey_data?.livestream
              ?.join_livestream
          ) {
            livestreamExist = true;

            const getJoinLivestreamData =
              getJourneyQuestionsData?.selections?.find(
                (item) => item?.category === "join_livestream"
              )?.selections;
            const joinLivestreamCommunityIds =
              getJourneyData?.user_journey?.journey_data?.livestream
                ?.join_livestream;
            const joinLivestreamCommunity = getJoinLivestreamData?.find(
              (item) => item?.id === joinLivestreamCommunityIds
            );
            dispatch(updateJoinLivestreamCommunity(joinLivestreamCommunity));
          }
        }

        // Update Registration Navigation Data
        let temp = registrationNavigationData.map((item) => {
          if (item?.id === 1 && annualIncomeExist) {
            return {
              ...item,
              isVisible: true,
              isClickable: true,
            };
          } else if (item?.id === 2 && networthExist) {
            return {
              ...item,
              isVisible: true,
              isClickable: true,
            };
          } else if (item?.id === 3 && lifestyleExist) {
            return {
              ...item,
              isVisible: true,
              isClickable: true,
            };
          } else if (item?.id === 4 && relationshipStatusExist) {
            return {
              ...item,
              isVisible: true,
              isClickable: true,
            };
          } else if (item?.id === 5 && datingStylesExist) {
            return {
              ...item,
              isVisible: true,
              isClickable: true,
            };
          } else if (
            item?.id === 6 &&
            getJourneyData?.user_journey?.journey_data?.gender ===
              user.gender.MAN &&
            positionExist &&
            industryExist
          ) {
            return {
              ...item,
              isVisible: true,
              isClickable: true,
            };
          } else if (
            item?.id === 6 &&
            getJourneyData?.user_journey?.journey_data?.gender ===
              user.gender.WOMAN &&
            positionExist
          ) {
            return {
              ...item,
              isVisible: true,
              isClickable: true,
            };
          } else if (item?.id === 7 && ethnicityExist) {
            return {
              ...item,
              isVisible: true,
              isClickable: true,
            };
          } else if (item?.id === 8 && raceExist) {
            return {
              ...item,
              isVisible: true,
              isClickable: true,
            };
          } else if (item?.id === 9 && smokingExist) {
            return {
              ...item,
              isVisible: true,
              isClickable: true,
            };
          } else if (item?.id === 10 && drinkingExist) {
            return {
              ...item,
              isVisible: true,
              isClickable: true,
            };
          } else if (item?.id === 11 && alcoholPreferenceExist) {
            return {
              ...item,
              isVisible: true,
              isClickable: true,
            };
          } else if (item?.id === 12 && aboutMeExist) {
            return {
              ...item,
              isVisible: true,
              isClickable: true,
            };
          } else if (item?.id === 13 && lookingForExist) {
            return {
              ...item,
              isVisible: true,
              isClickable: true,
            };
          } else if (item?.id === 14 && livestreamExist) {
            return {
              ...item,
              isVisible: true,
              isClickable: true,
            };
          } else if (
            item?.id === 14 &&
            i18n.language.toLowerCase().includes("zh-tw")
          ) {
            return {
              ...item,
              isVisible: false,
              isClickable: false,
            };
          } else {
            return item;
          }
        });
        dispatch(updateRegistrationNavigationData(temp));
      }
    } else if (getJourneyError) {
    }
  }, [
    getJourneyFetching,
    getJourneyLoading,
    getJourneySuccess,
    getJourneyError,
  ]);

  // Lifecycle | Check for update | queryParams
  useEffect(() => {
    if (active) {
      dispatch(updateRegistrationActiveTab(parseInt(active)));

      if (emblaApi) {
        emblaApi?.scrollTo(parseInt(active));
      }
    }
  }, [active, emblaApi]);

  // Lifecycle | Mounted | emblaApi
  useEffect(() => {
    if (!emblaApi) return;
    emblaApi?.scrollTo(registrationActiveTab);

    emblaApi?.on("select", () => {
      dispatch(
        updateRegistrationActiveTab(parseInt(emblaApi?.selectedScrollSnap()))
      );
    });
  }, [emblaApi]);

  // Utility Functions
  const getAboutMeRemap = (category, aboutMeId) => {
    const cat = getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === category
    )?.selections;

    const obj = cat?.find((data) => data?.id == aboutMeId);
    return obj;
  };

  return (
    <div id="details-signup-page">
      <RegistrationNavigationBar />

      <div
        className={`details-signup-body-container ${
          isMobile
            ? "details-signup-body-mobile-height"
            : "details-signup-body-desktop-height"
        }`}
      >
        <div ref={emblaRef} className="embla">
          <div className="embla__container">
            <div className="embla__slide">
              {registrationActiveTab === 0 && <TermsOfRelationshipDetails />}
            </div>

            <div className="embla__slide">
              {registrationActiveTab === 1 && <AnnualIncomeDetails />}
            </div>

            <div className="embla__slide">
              {registrationActiveTab === 2 && <NetWorthDetails />}
            </div>

            <div className="embla__slide">
              {registrationActiveTab === 3 && <LifestyleBudgetDetails />}
            </div>

            <div className="embla__slide">
              {registrationActiveTab === 4 && <RelationshipStatusDetails />}
            </div>

            <div className="embla__slide">
              {registrationActiveTab === 5 && <DatingStylesDetails />}
            </div>

            <div className="embla__slide">
              {registrationActiveTab === 6 && <OccupationDetails />}
            </div>

            <div className="embla__slide">
              {registrationActiveTab === 7 && <EthnicityDetails />}
            </div>

            <div className="embla__slide">
              {registrationActiveTab === 8 && <RaceDetails />}
            </div>

            <div className="embla__slide">
              {registrationActiveTab === 9 && <SmokingDetails />}
            </div>

            <div className="embla__slide">
              {registrationActiveTab === 10 && <DrinkingDetails />}
            </div>

            <div className="embla__slide">
              {registrationActiveTab === 11 && <AlcoholPreferenceDetails />}
            </div>

            <div className="embla__slide">
              {registrationActiveTab === 12 && <AboutMeDetails />}
            </div>

            <div className="embla__slide">
              {registrationActiveTab === 13 && <LookingForDetails />}
            </div>

            <div className="embla__slide">
              {registrationActiveTab === 14 && <LivestreamingDetails />}
            </div>

            <div className="embla__slide">
              {registrationActiveTab === 15 && <PhotosDetails />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsSignup;
