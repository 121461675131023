// General
import "./go-live-info-dialog.scss";
import { useEffect } from "react";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateGoLiveInfoDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// react-device-detect
import { isAndroid, isIOS } from "react-device-detect";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const GoLiveInfoDialog = () => {
  // Redux variables
  const goLiveInfoDialog = useSelector(
    (state) => state.dialog.goLiveInfoDialog
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!goLiveInfoDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-GoLiveInfo-Dialog",
      },
    });
  }, [goLiveInfoDialog]);

  // Event Handlers | Button
  const onRedirectToDownloadPage = () => {
    if (isAndroid) {
      window.open(routeConst.misc.sugarbookAndroidApk.path, "_blank");
      return;
    } else if (isIOS) {
      window.open(routeConst.misc.sugarbookIosApp.path, "_blank");
      return;
    }
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateGoLiveInfoDialog(false));
  };

  return (
    <Dialog
      className="custom-radius25-dialog"
      maxWidth="xs"
      open={goLiveInfoDialog}
      onClose={onCloseDialog}
    >
      <div id="join-private-android-warning-dialog">
        <div className="padding-container">
          <div className="popup-action-icon-container">
            {getIcon("actionAlertIcon", "action-icon")}
          </div>

          <div className="heads-up-title">
            {t("profile.room_android_title")}
          </div>

          <div className="join-private-android-warning-description">
            Please download our Android App or iOS App to Go Live.
          </div>

          <div className="buttons-container">
            <div className="download-button" onClick={onRedirectToDownloadPage}>
              {t("upgrade.download_now")}
            </div>

            <div className="close-button" onClick={onCloseDialog}>
              {t("common.close")}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default GoLiveInfoDialog;
