// General
import "./missing-terms-of-relationship-dialog.scss";
import { useState, useEffect, forwardRef } from "react";
// Services
import {
  useLazyGetTorListQuery,
  useSaveTorListMutation,
} from "../../../../services/data.service";
// Static Data
import profileConst from "../../../../const/profileConst";
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateCompleteTorDialog,
  updateMissingTorDialog,
} from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide, useMediaQuery } from "@mui/material";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import AssetManager from "../../../utility/manager/asset-manager/asset-manager";
// Components
import Spinner from "../../elements/spinner/spinner";
import TorTag from "../../elements/tor-tag/tor-tag";

const MissingTermsOfRelationshipDialog = () => {
  // API variables
  const [
    getTorList,
    {
      data: getTorListData,
      error: getTorListErrorData,
      isFetching: getTorListFetching,
      isLoading: getTorListLoading,
      isSuccess: getTorListSuccess,
      isError: getTorListError,
    },
  ] = useLazyGetTorListQuery();
  const [
    saveTorList,
    {
      data: saveTorListData,
      error: saveTorListErrorData,
      isLoading: saveTorListLoading,
      isSuccess: saveTorListSuccess,
      isError: saveTorListError,
    },
  ] = useSaveTorListMutation();

  // Constant variables
  const tags = profileConst.onboarding.torTags;

  // General variables
  const [view, setView] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);

  // Render variables
  const [selectTorView, setSelectTorView] = useState(null);
  const [tagDetails, setTagDetails] = useState(null);

  // Redux variables
  const missingTorDialog = useSelector(
    (state) => state.dialog.missingTorDialog
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t, i18n } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getAsset = AssetManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!missingTorDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-MissingTor-Dialog",
      },
    });

    getTorList();
  }, [missingTorDialog]);

  // Lifecycle | Check for update | Tor List API Response
  useEffect(() => {
    if (getTorListFetching || getTorListLoading) {
      setSelectTorView(<Spinner />);
    } else if (getTorListSuccess) {
    } else if (getTorListError) {
      if (getTorListErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getTorListFetching,
    getTorListLoading,
    getTorListSuccess,
    getTorListError,
  ]);

  // Lifecycle | Check for update | Save Tor List API Response
  useEffect(() => {
    if (saveTorListLoading) {
    } else if (saveTorListSuccess) {
      switch (saveTorListData?.status) {
        case 0:
          dispatch(updateMissingTorDialog(false));
          dispatch(updateCompleteTorDialog(true));
          break;
        default:
          break;
      }
    } else if (saveTorListError) {
    }
  }, [saveTorListLoading, saveTorListSuccess, saveTorListError]);

  // Event Handlers | Button
  const onNext = () => {
    setView(true);
  };
  const onSelectTag = (tag) => {
    setTagDetails(tag.description);

    if (selectedTags.includes(tag.id)) {
      setSelectedTags((prev) => prev.filter((item) => item !== tag.id));
    } else {
      if (selectedTags.length < 5) {
        setSelectedTags((prev) => [...prev, tag.id]);
      }
    }
  };
  const onSave = () => {
    if (selectedTags?.length <= 0) return;

    const obj = {
      tags: selectedTags,
    };
    saveTorList(obj);
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateMissingTorDialog(false));
  };

  return (
    <Dialog
      className={isMobile ? "" : "custom-radius25-dialog"}
      fullScreen={isMobile ? true : false}
      open={missingTorDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={onCloseDialog}
    >
      <div
        id="missing-tor-dialog"
        className={isMobile ? "" : "max-width-tor-container"}
      >
        {view === false && (
          <div className="main-view">
            <div className="tor-background-image-container">
              <img
                className="tor-background-image"
                src={getAsset("torIntroBackground")}
              />
            </div>

            <div className="padding-container">
              <div className="title">
                <Trans
                  i18nKey="profile.tor_dialog_title"
                  components={{ br: <br />, sup: <sup /> }}
                />
              </div>

              <div className="description">
                <Trans
                  i18nKey="profile.tor_dialog_desc"
                  components={{ b: <b /> }}
                />
              </div>

              <div className="tags-container">
                {tags.map((tag, index) => (
                  <div className="tag-container" key={index}>
                    {t(tag?.title)}
                  </div>
                ))}
              </div>

              <div className="next-button" onClick={onNext}>
                {t("common.next")}
              </div>
            </div>
          </div>
        )}

        {view && (
          <div className="select-view">
            <div className="padding-container">
              <div className="title">
                <Trans
                  i18nKey="profile.tor_dialog_select_title"
                  components={{ sup: <sup className="smaller-font" /> }}
                />
              </div>

              <div
                className={`selected-label ${
                  selectedTags.length === 5 ? "max-tags" : ""
                }`}
              >
                {t("profile.tor_selected", { n: selectedTags?.length, max: 5 })}
              </div>

              <div className="tags-container">
                {getTorListData?.data?.results?.map((tag, index) => (
                  <div onClick={() => onSelectTag(tag)} key={index}>
                    <TorTag
                      torName={tag?.locale}
                      description={tag?.description}
                      blur={false}
                      match={selectedTags?.includes(tag?.id) ? true : false}
                      interactable={false}
                      readOnly={true}
                      theme={tag?.theme}
                    />
                  </div>
                ))}
              </div>

              {tagDetails && (
                <div className="tag-details">
                  <Trans i18nKey={tagDetails} components={{ b: <b /> }} />
                </div>
              )}

              <div
                className={`save-button ${
                  selectedTags?.length <= 0 ? "disable-button" : ""
                }`}
                onClick={onSave}
              >
                {saveTorListLoading ? (
                  <Spinner
                    size={20}
                    color={"white-spinner"}
                    isPadding={false}
                  />
                ) : (
                  t("common.save")
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default MissingTermsOfRelationshipDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
