// General
// Static Data
import routeConst from "../../../const/routeConst";
// Redux
import { useDispatch } from "react-redux";
// Custom Hooks
import useCustomNavigate from "./useCustomNavigate-hook";

const useEndPrivateCall = () => {
  // Redux variables
  const dispatch = useDispatch();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Main Function
  const onEndCall = () => {
    onNavigate(routeConst.videoCall.summary.path);
  };

  return onEndCall;
};

export default useEndPrivateCall;
