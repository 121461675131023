// General
import "./top-banner.scss";
import { useState, useEffect } from "react";
// Services
import { useLazyGetUserAioQuery } from "../../../../../services/data.service";
// Redux
import { useSelector } from "react-redux";
// i18next
import { Trans } from "react-i18next";

const TopBanner = () => {
  // API variables
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();

  // General variables
  const [freeMinutes, setFreeMinutes] = useState(0);

  // Render variables
  const [adviceView, setAdviceView] = useState(null);
  const [freeMinutesTimerView, setFreeMinutesTimerView] = useState(null);
  const [timerView, setTimerView] = useState(null);

  // Redux variables
  const isPrivateStandbyMode = useSelector(
    (state) => state.privateCall.isPrivateStandbyMode
  );
  const isCaller = useSelector((state) => state.privateCall.isCaller);
  const calleeCoinsPerMinute = useSelector(
    (state) => state.privateCall.calleeCoinsPerMinute
  );
  const callDurationRemaining = useSelector(
    (state) => state.privateCall.callDurationRemaining
  );
  const freeSecondsRemaining = useSelector(
    (state) => state.privateCall.freeSecondsRemaining
  );
  const isDaddy = useSelector((state) => state.user.isDaddy);

  // Lifecycle | Mounted
  useEffect(() => {
    getUserAio(null, true);
  }, []);

  // Lifecycle | Check for update | Get User AIO API Response
  useEffect(() => {
    if (getUserAioFetching || getUserAioLoading) {
    } else if (getUserAioSuccess) {
      if (getUserAioData?.status === 1) {
        setAdviceView(
          <div
            className="top-banner-padding-container"
            dangerouslySetInnerHTML={{
              __html:
                getUserAioData?.data?.private_calls?.top_notice_bar_content,
            }}
          ></div>
        );

        setFreeMinutes(
          getUserAioData?.data?.private_call_campaign?.free_minutes
        );
      }
    } else if (getUserAioError) {
    }
  }, [
    getUserAioFetching,
    getUserAioLoading,
    getUserAioSuccess,
    getUserAioError,
  ]);

  // Lifecycle | Check for update | callDurationRemaining
  useEffect(() => {
    if (!callDurationRemaining) {
      setTimerView(null);
      return;
    }

    if (isPrivateStandbyMode) {
      if (isCaller) {
        if (callDurationRemaining < 300) {
          setTimerView(
            <div className="callee-timer-container">
              <Trans
                i18nKey="1on1.alert_ending_soon"
                components={{
                  countdown: getFormattedTime(callDurationRemaining),
                  span: <span className="real-money-text" />,
                }}
              />
            </div>
          );
        }
      } else {
        setTimerView(
          <div className="callee-timer-container">
            <Trans
              i18nKey="1on1.alert_ending_payee"
              values={{ countdown: getFormattedTime(callDurationRemaining) }}
              components={{
                span: <span className="real-money-text" />,
              }}
            />
          </div>
        );
      }
    } else {
      if (isDaddy) {
        if (callDurationRemaining < 300) {
          setTimerView(
            <div className="callee-timer-container">
              <Trans
                i18nKey="1on1.alert_ending_soon"
                components={{
                  countdown: getFormattedTime(callDurationRemaining),
                  span: <span className="real-money-text" />,
                }}
              />
            </div>
          );
        } else {
          setTimerView(null);
        }
      } else {
        setTimerView(
          <div className="callee-timer-container">
            <Trans
              i18nKey="1on1.alert_ending_payee"
              values={{ countdown: getFormattedTime(callDurationRemaining) }}
              components={{
                span: <span className="real-money-text" />,
              }}
            />
          </div>
        );
      }
    }
  }, [callDurationRemaining]);

  // Lifecycle | Check for update | freeSecondsRemaining
  useEffect(() => {
    if (!freeSecondsRemaining) {
      setFreeMinutesTimerView(null);
      return;
    }

    if (isCaller) {
      if (freeSecondsRemaining < 600) {
        setFreeMinutesTimerView(
          <div className="free-minutes-timer-container">
            <Trans
              i18nKey={"1on1.alert_free_minutes_ending"}
              values={{
                free_minutes: freeMinutes,
                minutes: getFormattedTime(freeSecondsRemaining),
                coin: calleeCoinsPerMinute,
              }}
              components={{ span: <span className="coins-label" /> }}
            />
          </div>
        );
      }
    }
  }, [freeSecondsRemaining]);

  // Utility Functions
  const getFormattedTime = (seconds) => {
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds / 60) % 60);
    let secs = Math.floor(seconds % 60);

    let formattedHours = hours < 10 ? "0" + hours : hours;
    let formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    let formattedSeconds = secs < 10 ? "0" + secs : secs;

    if (hours > 0) {
      return formattedHours + ":" + formattedMinutes + ":" + formattedSeconds;
    } else {
      return formattedMinutes + ":" + formattedSeconds;
    }
  };

  return (
    <div id="interactable-overlay-top-banner-subcomponent">
      {adviceView}
      {freeMinutesTimerView}
      {freeSecondsRemaining <= 0 && timerView}
    </div>
  );
};

export default TopBanner;
