// General
import "./coins-package-dialog.scss";
import { useEffect, forwardRef } from "react";
// Services
import { useLazyGetVirtualCurrencyCoinBalanceQuery } from "../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateCoinsPackageDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import CoinsList from "../../coins-list/coins-list";

const CoinsPackageDialog = () => {
  // API variables
  const [
    getCoins,
    {
      data: getCoinsData,
      error: getCoinsErrorData,
      isFetching: getCoinsFetching,
      isLoading: getCoinsLoading,
      isSuccess: getCoinsSuccess,
      isError: getCoinsError,
    },
  ] = useLazyGetVirtualCurrencyCoinBalanceQuery();

  // Redux variables
  const coinsPackageDialog = useSelector(
    (state) => state.dialog.coinsPackageDialog
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateCoinsPackageDialog(false));
  };

  // Lifecycle | Mounted
  useEffect(() => {
    if (!coinsPackageDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-CoinsPackage-Dialog",
      },
    });

    getCoins();
  }, [coinsPackageDialog]);

  // Utility Functions
  const getFormattedCoins = (coins) => {
    if (coins >= 100000) {
      return `${(coins / 1000).toFixed(1)}K`;
    } else {
      return coins?.toLocaleString();
    }
  };

  return (
    <Dialog
      className="custom-bottom-sheet-dialog"
      fullScreen
      open={coinsPackageDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={onCloseDialog}
    >
      <div id="coins-package-dialog">
        <div className="padding-container">
          <div className="header-container">
            <div className="left-container"></div>
            <div className="middle-container">
              {t("1on1.choose_coin_package")}
            </div>
            <div className="right-container">
              <div className="close-icon-container" onClick={onCloseDialog}>
                <CloseIcon className="close-icon" />
              </div>
            </div>
          </div>

          <div className="coins-package-body">
            <CoinsList />
          </div>

          <div className="bottom-container">
            <div className="balance-container">
              <div className="add-icon-container">
                {getIcon("liveAddCoinIcon", "add-icon")}
              </div>

              <div className="balance-label">{t("common.balance")}:</div>

              <div className="coin-icon-container">
                {getIcon("sbCoinIcon", "coin-icon")}
              </div>

              <div className="coin-value">
                {getFormattedCoins(getCoinsData?.data?.balance)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default CoinsPackageDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
