// General
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // General variables
  id: null,
  idInt: null,
  username: null,
  age: null,
  gender: null,
  location: null,
  membershipType: null,
  verifiedProfile: null,
  verifiedCaller: null,
  levellingBadge: null,
  livestreamingAchievementBadge: null,
  specialBadges: null,
  profilePhoto: null,
  profileDecorations: null,
  publicPhotos: [],
  privatePhotos: [],
  torTags: [],
  datingStyles: [],
  profileAchievementBadge: null,
  aboutMe: [],
  aboutMeManualText: null,
  aboutMeReadStatus: null,
  lookingFor: null,
  tagline: null,
  annualIncome: null,
  networth: null,
  occupation: null, // Deprecated
  position: null,
  industry: null,
  lifestyle: null,
  datingBudget: null,
  education: null,
  relationship: null,
  smoking: null,
  drinking: null,
  height: null,
  bodyType: null,
  ethnicity: null,
  eyeColor: null,
  hairColor: null,
  children: null,
  showLastActive: null,
  lastActive: null,
  showMemberSince: null,
  memberSince: null,
  withinDistanceInKm: null,

  // About Me
  personalityType: null,
  hobbies: null,
  fitnessLevel: null,
  starSign: null,
  dominantOrSubmissive: null,
  personalityTraits: null,

  // Looking For
  lookingForAgeRange: {
    min: null,
    max: null,
  },
  lookingForLocation: [],
  lookingForEthnicityRace: [],

  // Utility variables
  shouldCallApi: null,
  profileStatus: null,
  privatePhotoAbleToRequest: false,
  privatePhotoRequested: false,
  privatePhotoApproval: false,
  requestedMyPrivatePhoto: false,
  approvedMyPrivatePhoto: false,
  isOnline: null,
  isBlocked: null,
  isSilentInteraction: null,
  isFavorited: null,
  isCallEnabled: null,
  isSugarbaby: null,
  role: null,
  isSelf: false,
};

export const userCardSlice = createSlice({
  name: "userCard",
  initialState,
  reducers: {
    // General Functions
    updateId: (state, action) => {
      state.id = action.payload;
    },
    updateIdInt: (state, action) => {
      state.idInt = action.payload;
    },
    updateUsername: (state, action) => {
      state.username = action.payload;
    },
    updateAge: (state, action) => {
      state.age = action.payload;
    },
    updateGender: (state, action) => {
      state.gender = action.payload;
    },
    updateLocation: (state, action) => {
      state.location = action.payload;
    },
    updateMembershipType: (state, action) => {
      state.membershipType = action.payload;
    },
    updateVerifiedProfile: (state, action) => {
      state.verifiedProfile = action.payload;
    },
    updateVerifiedCaller: (state, action) => {
      state.verifiedCaller = action.payload;
    },
    updateLevellingBadge: (state, action) => {
      state.levellingBadge = action.payload;
    },
    updateLivestreamingAchievementBadge: (state, action) => {
      state.livestreamingAchievementBadge = action.payload;
    },
    updateSpecialBadges: (state, action) => {
      state.specialBadges = action.payload;
    },
    updateProfilePhoto: (state, action) => {
      state.profilePhoto = action.payload;
    },
    updateProfileDecorations: (state, action) => {
      state.profileDecorations = action.payload;
    },
    updatePublicPhotos: (state, action) => {
      state.publicPhotos = action.payload;
    },
    updatePrivatePhotos: (state, action) => {
      state.privatePhotos = action.payload;
    },
    updateTorTags: (state, action) => {
      state.torTags = action.payload;
    },
    updateDatingStyles: (state, action) => {
      state.datingStyles = action.payload;
    },
    updateProfileAchievementBadge: (state, action) => {
      state.profileAchievementBadge = action.payload;
    },
    updateAboutMe: (state, action) => {
      state.aboutMe = action.payload;
    },
    updateAboutMeManualText: (state, action) => {
      state.aboutMeManualText = action.payload;
    },
    updateAboutMeReadStatus: (state, action) => {
      state.aboutMeReadStatus = action.payload;
    },
    updateLookingFor: (state, action) => {
      state.lookingFor = action.payload;
    },
    updateTagline: (state, action) => {
      state.tagline = action.payload;
    },
    updateAnnualIncome: (state, action) => {
      state.annualIncome = action.payload;
    },
    updateNetworth: (state, action) => {
      state.networth = action.payload;
    },
    updateOccupation: (state, action) => {
      // Deprecated
      state.occupation = action.payload;
    },
    updatePosition: (state, action) => {
      state.position = action.payload;
    },
    updateIndustry: (state, action) => {
      state.industry = action.payload;
    },
    updateLifestyle: (state, action) => {
      state.lifestyle = action.payload;
    },
    updateDatingBudget: (state, action) => {
      state.datingBudget = action.payload;
    },
    updateEducation: (state, action) => {
      state.education = action.payload;
    },
    updateRelationship: (state, action) => {
      state.relationship = action.payload;
    },
    updateSmoking: (state, action) => {
      state.smoking = action.payload;
    },
    updateDrinking: (state, action) => {
      state.drinking = action.payload;
    },
    updateHeight: (state, action) => {
      state.height = action.payload;
    },
    updateBodyType: (state, action) => {
      state.bodyType = action.payload;
    },
    updateEthnicity: (state, action) => {
      state.ethnicity = action.payload;
    },
    updateEyeColor: (state, action) => {
      state.eyeColor = action.payload;
    },
    updateHairColor: (state, action) => {
      state.hairColor = action.payload;
    },
    updateChildren: (state, action) => {
      state.children = action.payload;
    },
    updateShowLastActive: (state, action) => {
      state.showLastActive = action.payload;
    },
    updateLastActive: (state, action) => {
      state.lastActive = action.payload;
    },
    updateShowMemberSince: (state, action) => {
      state.showMemberSince = action.payload;
    },
    updateMemberSince: (state, action) => {
      state.memberSince = action.payload;
    },
    updateWithinDistanceInKm: (state, action) => {
      state.withinDistanceInKm = action.payload;
    },

    // About Me Functions
    updatePersonalityType: (state, action) => {
      state.personalityType = action.payload;
    },
    updateHobbies: (state, action) => {
      state.hobbies = action.payload;
    },
    updateFitnessLevel: (state, action) => {
      state.fitnessLevel = action.payload;
    },
    updateStarSign: (state, action) => {
      state.starSign = action.payload;
    },
    updateDominantOrSubmissive: (state, action) => {
      state.dominantOrSubmissive = action.payload;
    },
    updatePersonalityTraits: (state, action) => {
      state.personalityTraits = action.payload;
    },

    // Looking for Functions
    updateLookingForAgeRange: (state, action) => {
      state.lookingForAgeRange = action.payload;
    },
    updateLookingForLocation: (state, action) => {
      state.lookingForLocation = action.payload;
    },
    updateLookingForEthnicityRace: (state, action) => {
      state.lookingForEthnicityRace = action.payload;
    },

    // User Utility Functions
    updateShouldCallApi: (state, action) => {
      state.shouldCallApi = action.payload;
    },
    updateProfileStatus: (state, action) => {
      state.profileStatus = action.payload;
    },
    updatePrivatePhotoAbleToRequest: (state, action) => {
      state.privatePhotoAbleToRequest = action.payload;
    },
    updatePrivatePhotoRequested: (state, action) => {
      state.privatePhotoRequested = action.payload;
    },
    updatePrivatePhotoApproval: (state, action) => {
      state.privatePhotoApproval = action.payload;
    },
    updateRequestedMyPrivatePhoto: (state, action) => {
      state.requestedMyPrivatePhoto = action.payload;
    },
    updateApprovedMyPrivatePhoto: (state, action) => {
      state.approvedMyPrivatePhoto = action.payload;
    },
    updateIsOnline: (state, action) => {
      state.isOnline = action.payload;
    },
    updateIsBlocked: (state, action) => {
      state.isBlocked = action.payload;
    },
    updateIsSilentInteraction: (state, action) => {
      state.isSilentInteraction = action.payload;
    },
    updateIsFavorited: (state, action) => {
      state.isFavorited = action.payload;
    },
    updateIsCallEnabled: (state, action) => {
      state.isCallEnabled = action.payload;
    },
    updateIsSugarbaby: (state, action) => {
      state.isSugarbaby = action.payload;
    },
    updateRole: (state, action) => {
      state.role = action.payload;
    },
    updateIsSelf: (state, action) => {
      state.isSelf = action.payload;
    },

    // Utility Functions
    resetUserCardStore: () => {
      return { ...initialState };
    },
  },
});

export const {
  // General Functions
  updateId,
  updateIdInt,
  updateUsername,
  updateAge,
  updateGender,
  updateLocation,
  updateMembershipType,
  updateVerifiedProfile,
  updateVerifiedCaller,
  updateLevellingBadge,
  updateLivestreamingAchievementBadge,
  updateSpecialBadges,
  updateProfilePhoto,
  updateProfileDecorations,
  updatePublicPhotos,
  updatePrivatePhotos,
  updateTorTags,
  updateDatingStyles,
  updateProfileAchievementBadge,
  updateAboutMe,
  updateAboutMeManualText,
  updateAboutMeReadStatus,
  updateLookingFor,
  updateTagline,
  updateAnnualIncome,
  updateNetworth,
  updateOccupation,
  updatePosition,
  updateIndustry,
  updateLifestyle,
  updateDatingBudget,
  updateEducation,
  updateRelationship,
  updateSmoking,
  updateDrinking,
  updateHeight,
  updateBodyType,
  updateEthnicity,
  updateEyeColor,
  updateHairColor,
  updateChildren,
  updateShowLastActive,
  updateLastActive,
  updateShowMemberSince,
  updateMemberSince,
  updateWithinDistanceInKm,

  // About Me Functions
  updatePersonalityType,
  updateHobbies,
  updateFitnessLevel,
  updateStarSign,
  updateDominantOrSubmissive,
  updatePersonalityTraits,

  // Looking For Functions
  updateLookingForAgeRange,
  updateLookingForLocation,
  updateLookingForEthnicityRace,

  // User Utility Functions
  updateShouldCallApi,
  updateProfileStatus,
  updatePrivatePhotoAbleToRequest,
  updatePrivatePhotoRequested,
  updatePrivatePhotoApproval,
  updateRequestedMyPrivatePhoto,
  updateApprovedMyPrivatePhoto,
  updateIsOnline,
  updateIsBlocked,
  updateIsSilentInteraction,
  updateIsFavorited,
  updateIsCallEnabled,
  updateIsSugarbaby,
  updateRole,
  updateIsSelf,

  // Utility Functions
  resetUserCardStore,
} = userCardSlice.actions;

export default userCardSlice.reducer;
