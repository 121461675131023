// General
import "./payment-loading-dialog.scss";
import { useEffect } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updatePaymentProcessDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Components
import Spinner from "../../elements/spinner/spinner";

const PaymentLoadingDialog = () => {
  // Redux variables
  const paymentProcessDialog = useSelector(
    (state) => state.dialog.paymentProcessDialog
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!paymentProcessDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-PaymentLoading-Dialog",
      },
    });
  }, [paymentProcessDialog]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updatePaymentProcessDialog(false));
  };

  return (
    <Dialog
      className="custom-radius35-dialog"
      open={paymentProcessDialog}
      onClose={onCloseDialog}
    >
      <div id="payment-loading-dialog">
        <Spinner type={"alt"} isPadding={false} />

        <div className="progress-label">
          {t("purchase.payment_on_progress")}
        </div>
      </div>
    </Dialog>
  );
};

export default PaymentLoadingDialog;
