// General
import "./last-stream-diamonds-progress.scss";
import { useEffect } from "react";
// Services
import { useLazyGetLastStreamResultQuery } from "../../../../../services/data.service";
// i18next
import { useTranslation } from "react-i18next";
// Components
import Spinner from "../../../../shared/elements/spinner/spinner";

const LastStreamDiamondsProgress = () => {
  // API variables
  const [
    getLastStreamSummary,
    {
      data: getLastStreamSummaryData,
      error: getLastStreamSummaryErrorData,
      isFetching: getLastStreamSummaryFetching,
      isLoading: getLastStreamSummaryLoading,
      isSuccess: getLastStreamSummarySuccess,
      isError: getLastStreamSummaryError,
    },
  ] = useLazyGetLastStreamResultQuery();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    getLastStreamSummary(null, true);
  }, []);

  if (getLastStreamSummaryFetching || getLastStreamSummaryLoading) {
    return <Spinner />;
  } else if (getLastStreamSummarySuccess) {
    return (
      <div id="last-stream-summary-last-stream-diamonds-progress-subcomponent">
        <div className="diamond-count-labels-container">
          <div className="start-diamond-label">
            {getLastStreamSummaryData?.data?.diamonds?.cashout_progress
              ?.minimum || 0}{" "}
            {t("live.diamonds")}
          </div>

          <div className="end-diamond-label">
            {getLastStreamSummaryData?.data?.diamonds?.cashout_progress?.maximum?.toLocaleString() ||
              0}{" "}
            {t("live.diamonds")}
          </div>
        </div>

        <div className="progress-bar">
          <div
            className="progress-bar-load"
            style={{
              width: `calc(${
                (getLastStreamSummaryData?.data?.diamonds?.cashout_progress
                  ?.balance /
                  getLastStreamSummaryData?.data?.diamonds?.cashout_progress
                    ?.maximum) *
                100
              }%)`,
            }}
          ></div>
          <div className="progress-bar-value">
            {getLastStreamSummaryData?.data?.diamonds?.cashout_progress?.balance?.toLocaleString() ||
              0}{" "}
            /{" "}
            {getLastStreamSummaryData?.data?.diamonds?.cashout_progress?.maximum?.toLocaleString() ||
              0}
          </div>
        </div>

        <div className="your-progress-label">{t("settings.your_progress")}</div>
      </div>
    );
  } else if (getLastStreamSummaryError) {
  }
};

export default LastStreamDiamondsProgress;
