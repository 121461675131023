export const emailRegex = new RegExp(/.+@.+\.+.{2,}/);
export const noNumbersOrSpecialChars = new RegExp(/^[A-Za-z\s]+$/);

export const dashToUnderscore = new RegExp(/-/g);

export const removeHTMLEscape = new RegExp(/<[^>]*>/g);
export const removeHTMLEscape2 = new RegExp(/<.*>/);

// Credit Card
export const cardExpiryMonth = new RegExp(/^(0[1-9]|1[0-2])$/);
