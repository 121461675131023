// General
import "./info-signup.scss";
import { useState, useEffect } from "react";
// Services
import { sessionService } from "../../../services/session.service";
import {
  useUpdateUserLocationMutation,
  useUpdateUserProfileMutation,
  useUploadUserPhotoMutation,
} from "../../../services/data.service";
import { removeHTMLEscape } from "../../../services/regex.service";
// Static Data
import userConst from "../../../const/userConst";
import routeConst from "../../../const/routeConst";
import utilityConst from "../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateName,
  updateDob,
  updateLatitude,
  updateLongitude,
} from "../../../redux/store/signupStore";
import { updateLocation } from "../../../redux/store/editProfileStore";
import { updateErrorToast } from "../../../redux/store/toastStore";
// browser-image-compression
import imageCompression from "browser-image-compression";
// react-gtm-module
import TagManager from "react-gtm-module";
// Sentry
import * as Sentry from "@sentry/react";
// react-date-picker
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
// Material UI
import { TextField, Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
// React Google Maps/api
import { Autocomplete } from "@react-google-maps/api";
// i18next
import { useTranslation } from "react-i18next";
// Moment
import moment from "moment";
import "moment/locale/zh-tw";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../shared/elements/spinner/spinner";
import SignupStepper from "../../shared/elements/signup-stepper/signup-stepper";

const InfoSignup = (props) => {
  const { type = utilityConst.fillingDetails.signup.type } = props;

  // API variables
  const [
    updateUserLocation,
    {
      data: updateUserLocationData,
      error: updateUserLocationErrorData,
      isLoading: updateUserLocationLoading,
      isSuccess: updateUserLocationSuccess,
      isError: updateUserLocationError,
    },
  ] = useUpdateUserLocationMutation();
  const [
    updateUserProfile,
    {
      data: updateUserProfileData,
      error: updateUserProfileErrorData,
      isLoading: updateUserProfileLoading,
      isSuccess: updateUserProfileSuccess,
      isError: updateUserProfileError,
    },
  ] = useUpdateUserProfileMutation();
  const [
    uploadUserPhoto,
    {
      data: uploadUserPhotoData,
      error: uploadUserPhotoErrorData,
      isLoading: uploadUserPhotoLoading,
      isSuccess: uploadUserPhotoSuccess,
      isError: uploadUserPhotoError,
    },
  ] = useUploadUserPhotoMutation();

  // General variables
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [nameFocus, setNameFocus] = useState(false);
  const [nameValid, setNameValid] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [dobFocus, setDobFocus] = useState(false);
  const [dobValid, setDobValid] = useState(false);
  const [dobError, setDobError] = useState(false);
  const [locationFocus, setLocationFocus] = useState(false);
  const [locationValid, setLocationValid] = useState(false);
  const [locationError, setLocationError] = useState(false);

  const minDate = moment().subtract(userConst.userAgeRange.min, "years");
  const maxDate = moment().subtract(userConst.userAgeRange.max, "years");
  const [imageData, setImageData] = useState(null);
  const [searchResult, setSearchResult] = useState();

  // Redux variables
  const email = useSelector((state) => state.signup.email);
  const role = useSelector((state) => state.signup.role);
  const isPremium = useSelector((state) => state.signup.is_premium);
  const signupDate = useSelector((state) => state.signup.signup_date);

  const profilePictureLink = useSelector(
    (state) => state.signup.profilePictureLink
  );
  const name = useSelector((state) => state.signup.name);
  const dob = useSelector((state) => state.signup.dob);
  const latitude = useSelector((state) => state.signup.latitude);
  const longitude = useSelector((state) => state.signup.longitude);
  const editLocation = useSelector((state) => state.editProfile.location);
  const googleMapsLoaded = useSelector(
    (state) => state.noReset.googleMapsLoaded
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t, i18n } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-18-18.4",
      },
    });

    const locationData = sessionService.getLocationData();
    if (
      !locationData?.lat ||
      !locationData?.lng ||
      !locationData?.location_data
    ) {
      updateUserLocation({});
    } else {
      const locationObj = {
        lat: locationData?.lat,
        lng: locationData?.lng,
        location_data: locationData?.location_data,
        type: "device_gps",
      };
      updateUserLocation(locationObj);
    }

    // From Facebook Login
    if (profilePictureLink) {
      urlToBase64(profilePictureLink)
        .then((response) => {
          console.log(response);

          setImageData((prevState) => ({
            ...prevState,
            image: response,
            // file: file,
          }));
        })
        .catch((error) => {
          console.log("Error converting URL to Base64:", error);
        });
    }

    // From Facebook Login
    if (name) {
      setNameFocus(true);
    }
  }, []);

  // Lifecycle | Check for update | Update User Profile API Responses
  useEffect(() => {
    if (updateUserProfileLoading) {
    } else if (updateUserProfileSuccess) {
      const obj = {
        photos: [
          {
            is_private: false,
            base64: imageData.image,
          },
        ],
      };
      uploadUserPhoto(obj);
    } else if (updateUserProfileError) {
      switch (updateUserProfileErrorData?.data?.status) {
        case -1:
          const errorToast = {
            message: updateUserProfileErrorData?.data?.message,
            autoClose: 3000,
          };
          dispatch(updateErrorToast(errorToast));
          break;
        default:
          break;
      }
    }
  }, [
    updateUserProfileLoading,
    updateUserProfileSuccess,
    updateUserProfileError,
  ]);

  // Lifecycle | Check for update | Upload User Photo API Response
  useEffect(() => {
    if (uploadUserPhotoLoading) {
    } else if (uploadUserPhotoSuccess) {
      switch (uploadUserPhotoData?.status) {
        case 0:
        // Not sure
        case 1:
          switch (type) {
            case utilityConst.fillingDetails.signup.type:
              let userData = {
                email,
                username: name,
                role,
                is_premium: isPremium,
                signup_date: signupDate,
              };
              window.dataLayer.push(userData);

              onNavigate(routeConst.torSignup.path);
              break;
            case utilityConst.fillingDetails.missingDetails.type:
              TagManager.dataLayer({
                dataLayer: {
                  event: "PWA-Missing-Details-Form-Submit",
                },
              });

              onNavigate(routeConst.search.path);
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    } else if (uploadUserPhotoLoading) {
    }
  }, [uploadUserPhotoLoading, uploadUserPhotoSuccess, uploadUserPhotoError]);

  // Event Handlers | Button
  const onSaveDetails = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-18-18.4-18.4.1-Button",
      },
    });

    const profileObj = {
      username: name,
      birth_date: dob,
    };
    updateUserProfile(profileObj);
  };
  const onFilePicker = async (event) => {
    const file = event.target.files[0];

    const options = {
      maxSizeMB: 1,
    };

    if (file !== undefined) {
      try {
        const compressedFile = await imageCompression(file, options);
        const imageData = await readFile(compressedFile);
        setImageData((prevState) => ({
          ...prevState,
          image: imageData,
          file: file,
        }));
      } catch (error) {
        console.log(error);
      }
    }
  };

  // Event Handlers | MUI Date Picker
  const onDateChange = (event) => {
    if (!event) return;

    dispatch(updateDob(moment(event).format("YYYY-MM-DD")));
  };

  // Event Handlers | MUI Text Field
  const onNameChange = (event) => {
    // TODO: Add Min & Max length
    dispatch(updateName(event.target.value));
  };
  const onNameFocus = (event) => {
    setNameFocus(true);
  };
  const onDobFocus = (event) => {
    setDobFocus(true);
  };
  const onLocationFocus = (event) => {
    setLocationFocus(true);
  };

  // Event Handlers | Google Maps Autocomplete
  const onPlaceChanged = () => {
    if (searchResult != null) {
      const place = searchResult.getPlace();
      let location_data = {
        formatted_address: place?.formatted_address,
      };

      if (place?.address_components) {
        for (let i = 0; i < place?.address_components?.length; i++) {
          if (place?.address_components[i]?.types?.length > 0) {
            switch (place?.address_components[i]?.types[0]) {
              case "locality":
                location_data.city = place?.address_components[i]?.long_name;
                break;
              case "neighborhood":
                if (location_data.city !== null) {
                  location_data.neighborhood =
                    place?.address_components[i]?.long_name;
                }
                break;
              case "administrative_area_level_1":
                location_data.state = place?.address_components[i]?.short_name;
                break;
              case "country":
                location_data.country = place?.address_components[i]?.long_name;
                location_data.country_iso =
                  place?.address_components[i]?.short_name;
                break;
              default:
                break;
            }
          }
        }
      }

      const locationObj = {
        lat: place?.geometry?.location?.lat(),
        lng: place?.geometry?.location?.lng(),
        location_data,
        type: "user_defined",
      };

      dispatch(updateLocation(locationObj));
    }
  };
  const onLoad = (autocomplete) => {
    setSearchResult(autocomplete);
  };

  // Utility Functions
  const isFormValid = () => {
    return imageData?.image && nameValid && dobValid;
  };
  const nameValidation = () => {
    let valid = true;
    let error = false;

    if (!nameFocus) {
      valid = false;
      error = false;
    } else if (nameFocus && name === "") {
      setNameErrorMessage(t("signup.display_name_is_required"));
      valid = false;
      error = true;
    } else if (removeHTMLEscape.test(name)) {
      valid = false;
      error = true;
      dispatch(updateName(""));

      // Send to Sentry
      Sentry.captureMessage("PWA-18-18.4-Username-Input-HTML-Escape-Detected");

      // Send to GTM
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-18-18.4-Username-Input-HTML-Escape-Detected",
        },
      });
    } else {
      setNameErrorMessage("");
      valid = true;
      error = false;
    }

    setNameValid(valid);
    setNameError(error);
  };
  const dobValidation = () => {
    let valid = true;
    let error = false;

    if (dob === "") {
      valid = false;
      error = true;
    } else {
      valid = true;
      error = false;
    }

    setDobValid(valid);
    setDobError(error);
  };
  const onCalendarOpen = () => {
    if (dob) return;

    dispatch(updateDob(maxDate.format("YYYY-MM-DD")));
  };
  const locationValidation = () => {
    let valid = true;
    let error = false;

    if (!locationFocus) {
      valid = false;
      error = false;
    } else if (locationFocus && (!editLocation?.lat || !editLocation?.lng)) {
      valid = false;
      error = true;
    } else {
      valid = true;
      error = false;
    }

    setLocationValid(valid);
    setLocationError(error);
  };
  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };
  const urlToBase64 = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result.split(",")[1]);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Error converting URL to Base64:", error);
      throw error;
    }
  };

  // Check for name validation
  useEffect(() => {
    nameValidation();
    dobValidation();
    locationValidation();
  }, [
    name,
    nameFocus,
    nameErrorMessage,
    dob,
    dobFocus,
    latitude,
    longitude,
    locationFocus,
  ]);

  // Only works outside of useEffect
  if (i18n.language.toLowerCase().includes("zh-tw")) {
    moment.locale("zh-tw");
  } else {
    moment.locale("en");
  }

  return (
    <div id="info-signup-page">
      <div className="max-width-container">
        <div className="navbar-container"></div>

        <div className="overflow-container">
          {type === utilityConst.fillingDetails.signup.type && (
            <div className="stepper-container">
              <div className="stepper-max-width-container">
                <div className="stepper-max-width-inner-container">
                  <SignupStepper current={1} total={5} />
                </div>
              </div>
            </div>
          )}

          <div className="started-label">{t("signup.lets_get_started")}</div>

          <div className="upload-profile-photo-container">
            <Button
              className="profile-img-button"
              disableRipple
              component="label"
            >
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={onFilePicker}
              />
              {imageData?.image ? (
                <img
                  className="profile-img"
                  src={imageData?.image}
                  alt="default"
                />
              ) : (
                getIcon("defaultProfilePhotoIcon", "profile-img")
              )}

              <div className="add-icon-container">
                <EditIcon className="add-icon" />
              </div>
            </Button>
          </div>

          <div className="info-signup-input-container">
            <div className="name-form-field-container">
              <div className="display-name-label">
                {t("signup.display_name")}
              </div>

              <TextField
                className="name-form-field custom-text-field custom-text-field-max-radius custom-text-field-outline-default custom-text-field-outline-background-color"
                required
                value={name}
                error={nameError}
                onChange={onNameChange}
                placeholder={t("signup.your_name")}
                helperText={nameErrorMessage}
                variant="outlined"
                onFocus={onNameFocus}
              />
            </div>

            <div className="dob-form-field-container">
              <div className="birthday-label">{t("profile.birthday")}</div>

              <DatePicker
                name="dob"
                onChange={onDateChange}
                onCalendarOpen={onCalendarOpen}
                value={dob}
                minDate={minDate.toDate()}
                maxDate={maxDate.toDate()}
                clearIcon={null}
                format={"dd/MM/yyyy"}
              />
            </div>
          </div>

          {false && googleMapsLoaded && (
            <div className="location-form-field-container">
              <Autocomplete
                className="autocomplete-wrapper"
                onPlaceChanged={onPlaceChanged}
                onLoad={onLoad}
                options={{ types: ["geocode"] }}
              >
                <TextField
                  id="searchTextField"
                  className="form-field custom-text-field"
                  // error={locationError}
                  label={t("signup.location")}
                  placeholder={t("signup.location")}
                  variant="standard"
                  onFocus={onLocationFocus}
                ></TextField>
              </Autocomplete>
            </div>
          )}
        </div>

        <div className="bottom-container">
          <Button
            className={`continue-button ${
              isFormValid() ? "" : "disabled-button"
            }`}
            variant="text"
            onClick={onSaveDetails}
            disabled={!isFormValid()}
          >
            {updateUserProfileLoading || uploadUserPhotoLoading ? (
              <Spinner size={21} isPadding={false} color={"white-spinner"} />
            ) : (
              t("common.continue")
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InfoSignup;
