// General
import "./ending-call-overlay.scss";
// Redux
import { useSelector } from "react-redux";
// i18next
import { useTranslation } from "react-i18next";

const EndingCallOverlay = () => {
  // Redux variables
  const showVideoCallEndingOverlay = useSelector(
    (state) => state.privateCall.showVideoCallEndingOverlay
  );

  // i18next variables
  const { t } = useTranslation();

  if (showVideoCallEndingOverlay) {
    return (
      <div id="private-standby-ending-call-overlay-subcomponent">
        <div className="subheader">{t("1on1.ending_call")}</div>
      </div>
    );
  }
};

export default EndingCallOverlay;
