// General
import "./livestreamer.scss";
import { useState, useEffect } from "react";
// Services
import { useCreateLivestreamMutation } from "../../../services/data.service";
// Components
import VideoPlayer from "./video-player/video-player";
import InteractableOverlay from "./interactable-overlay/interactable-overlay";

const Livestreamer = () => {
  // API variables
  const [
    createLivestream,
    {
      data: createLivestreamData,
      error: createLivestreamErrorData,
      isLoading: createLivestreamLoading,
      isSuccess: createLivestreamSuccess,
      isError: createLivestreamError,
    },
  ] = useCreateLivestreamMutation();

  // Lifecycle | Check for update | Create Livestream
  useEffect(() => {
    if (createLivestreamLoading) {
    } else if (createLivestreamSuccess) {
    } else if (createLivestreamError) {
    }
  }, [createLivestreamLoading, createLivestreamSuccess, createLivestreamError]);

  // Utility Functions
  const onPush = () => {
    createLivestream();
    // livePusher?.startPush(
    //   "webrtc://push.sgrbk.xyz/live/test222?txSecret=bedcbe73d0f7a11c6e0eb7b9ca0c119c&txTime=65853B64"
    // );
  };

  return (
    <div id="livestreamer-page">
      <VideoPlayer />
      <InteractableOverlay />
      {/* <div onClick={onPush}>Push</div> */}
    </div>
  );
};

export default Livestreamer;
