// General
import "./go-private-permission-tutorial-dialog.scss";
import { useEffect, forwardRef } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateGoPrivatePermissionTutorialDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide } from "@mui/material";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import AssetManager from "../../../utility/manager/asset-manager/asset-manager";

const GoPrivatePermissionTutorial = () => {
  // Redux variables
  const goPrivatePermissionTutorialDialog = useSelector(
    (state) => state.dialog.goPrivatePermissionTutorialDialog
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getAsset = AssetManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!goPrivatePermissionTutorialDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-GoPrivatePermissionTutorial-Dialog",
      },
    });
  }, [goPrivatePermissionTutorialDialog]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateGoPrivatePermissionTutorialDialog(false));
  };

  return (
    <Dialog
      fullScreen
      open={goPrivatePermissionTutorialDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={onCloseDialog}
    >
      <div id="go-private-permission-tutorial-dialog">
        <div className="padding-container">
          <div className="title">{t("tutorial.camera_permission_title")}</div>

          <div className="first-container">
            <div className="instruction">
              <Trans
                i18nKey={"tutorial.camera_permission_point_1"}
                components={{ span: <span /> }}
              />
            </div>

            <div className="background-image-container">
              <img
                className="background-image"
                src={getAsset("cameraPermissionTutorial1")}
              />
            </div>
          </div>

          <div className="second-container">
            <div className="instruction">
              <Trans
                i18nKey={"tutorial.camera_permission_point_2"}
                components={{
                  span: <span />,
                  one: <span className="one" />,
                  two: <span className="two" />,
                }}
              />
            </div>

            <div className="background-image-container">
              <img
                className="background-image"
                src={getAsset("cameraPermissionTutorial2")}
              />
            </div>
          </div>

          <div className="third-container">
            <div className="instruction">
              <Trans
                i18nKey={"tutorial.camera_permission_point_3"}
                components={{ span: <span /> }}
              />
            </div>

            <div className="background-image-container">
              <img
                className="background-image"
                src={getAsset("cameraPermissionTutorial3")}
              />
            </div>
          </div>

          <div className="got-it-button" onClick={onCloseDialog}>
            {t("common.got_it")}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default GoPrivatePermissionTutorial;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
