// General
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // General variables
  email: "",
  country: "",
  phone: "",
  password: "",
  rememberMe: false,
};

export const signinSlice = createSlice({
  name: "signin",
  initialState,
  reducers: {
    // General Functions
    updateEmail: (state, action) => {
      state.email = action.payload;
    },
    updateCountry: (state, action) => {
      state.country = action.payload;
    },
    updatePhone: (state, action) => {
      state.phone = action.payload;
    },
    updatePassword: (state, action) => {
      state.password = action.payload;
    },
    updateRememberMe: (state, action) => {
      state.rememberMe = action.payload;
    },

    // Utility Functions
    resetSigninStore: () => {
      return { ...initialState };
    },
  },
});

export const {
  // General Functions
  updateEmail,
  updateCountry,
  updatePhone,
  updatePassword,
  updateRememberMe,

  // Utility Functions
  resetSigninStore,
} = signinSlice.actions;

export default signinSlice.reducer;
