// General
import "./permissions-status.scss";
// Redux
import { useSelector } from "react-redux";
// react-device-detect
import { isMobileSafari, isIOS, isFirefox } from "react-device-detect";
// Material UI
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";

const PermissionsStatus = () => {
  // Redux variables
  const cameraPermissions = useSelector(
    (state) => state.privateCall.cameraPermissions
  );
  const microphonePermissions = useSelector(
    (state) => state.privateCall.microphonePermissions
  );

  // These browser does not support navigator.mediaDevices.getUserMedia API
  if (isMobileSafari || isIOS || isFirefox) {
    return null;
  } else {
    return (
      <div id="topbar-permissions-status-subcomponent">
        <div className="camera-icon-container">
          {cameraPermissions ? (
            <VideocamIcon className="video-on" />
          ) : (
            <VideocamOffIcon className="video-off" />
          )}
        </div>

        <div className="microphone-icon-container">
          {microphonePermissions ? (
            <MicIcon className="microphone-on" />
          ) : (
            <MicOffIcon className="microphone-off" />
          )}
        </div>
      </div>
    );
  }
};

export default PermissionsStatus;
