// i18next
import { useTranslation } from "react-i18next";
// Moment
import moment from "moment";

const MomentjsMounted = () => {
  // i18next variables
  const { i18n } = useTranslation();

  // Set Language for Momentjs Globally
  // Only works outside of useEffect
  if (i18n.language.toLowerCase().includes("zh-tw")) {
    moment.locale("zh-tw");
  } else {
    moment.locale("en");
  }

  return <div id="momentjs-mounted-shadow-component"></div>;
};

export default MomentjsMounted;
