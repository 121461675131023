// General
import "./coins-tab.scss";
// Components
import CoinsList from "../../../coins-list/coins-list";

const CoinsTab = (props) => {
  const { state } = props;

  return (
    <div
      id="product-purchase-dialog-coins-tab-subcomponent"
      className={state === "dialog" ? "screen-height" : "fix-height"}
    >
      <CoinsList />
    </div>
  );
};

export default CoinsTab;
