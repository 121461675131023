// General
import "./followers-tab.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetProfileQuery,
  useLazyGetFollowersListQuery,
} from "../../../../../services/data.service";
// Static Data
import routeConst from "../../../../../const/routeConst";
import userConst from "../../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateFollowersList,
  resetFollowersList,
} from "../../../../../redux/store/userStore";
// React Infinite Scroller
import InfiniteScroll from "react-infinite-scroller";
// Custom Hooks
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import Spinner from "../../../../shared/elements/spinner/spinner";
import EmptyList from "../empty-list/empty-list";
import FollowerUserList from "../../../../shared/profile/follower-user-list/follower-user-list";

const FollowersTab = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    getFollowersList,
    {
      data: getFollowersListData,
      error: getFollowersListErrorData,
      isFetching: getFollowersListFetching,
      isLoading: getFollowersListLoading,
      isSuccess: getFollowersListSuccess,
      isError: getFollowersListError,
    },
  ] = useLazyGetFollowersListQuery();

  // General variables
  const [firstLoad, setFirstLoad] = useState(true);

  // Render variables
  let followersListView = <div></div>;

  // Redux variables
  const followersList = useSelector((state) => state.user.followersList);
  const dispatch = useDispatch();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    dispatch(resetFollowersList());
    getProfile(null, true);
  }, []);

  // Lifecycle | Check for update | Get Profile API Response
  useEffect(() => {
    if (getProfileFetching || getProfileLoading) {
    } else if (getProfileSuccess) {
      getFollowersList(`?user_id=${getProfileData?.data?.id}`, true);
    } else if (getProfileError) {
      if (getProfileErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getProfileFetching,
    getProfileLoading,
    getProfileSuccess,
    getProfileError,
  ]);

  // Lifecycle | Check for update | Get Followers List API Response
  useEffect(() => {
    if (getFollowersListFetching || getFollowersListLoading) {
    } else if (getFollowersListSuccess) {
      if (getFollowersListData?.status === 1) {
        setFirstLoad(false);

        if (getFollowersListData?.data?.users.length > 0) {
          dispatch(updateFollowersList(getFollowersListData?.data?.users));
        }
      }
    } else if (getFollowersListError) {
      if (getFollowersListErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getFollowersListFetching,
    getFollowersListLoading,
    getFollowersListSuccess,
    getFollowersListError,
  ]);

  // Infinite Scroller Functions
  const infiniteScrollFunc = () => {
    if (getFollowersListData?.data?.pagination?.next_cursor) {
      setTimeout(() => {
        getFollowersList(
          `?user_id=${getProfileData?.data?.id}&pagination_token=${getFollowersListData?.data?.pagination?.next_cursor}`
        );
      }, 1000);
    }
  };

  // Render Functions | Followers List Data
  if (followersList?.length > 0) {
    followersListView = (
      <div className="infinite-scroller-container">
        {followersList?.map((follower, index) => (
          <FollowerUserList
            // User View
            id={follower?.id}
            idInt={follower?.id_int}
            username={follower?.username}
            age={follower?.appearance?.age}
            gender={follower?.gender}
            location={
              follower?.location?.display_address ||
              follower?.location?.city ||
              follower?.location?.state
            }
            membershipType={follower?.membership_type}
            verifiedProfile={follower?.is_verified_profile}
            verifiedCaller={follower?.verified_caller}
            levellingBadge={follower?.levelling_profile?.badge}
            livestreamingAchievementBadge={
              follower?.live_streaming_achievements?.chat_badges_set
            }
            specialBadges={follower?.special_badges}
            profilePhoto={follower?.profile_photo?.original_photo}
            publicPhotos={follower?.public_photos?.map((photo) => {
              return {
                photoUrl: photo?.original_photo,
              };
            })}
            privatePhotos={follower?.private_photos?.map((photo) => {
              return {
                photoUrl: photo?.original_photo,
                thumbnailData: photo?.thumbnail_data,
                isSensitive: photo?.is_sensitive,
              };
            })}
            torTags={follower?.tor_tags}
            profileAchievementBadge={
              follower?.live_streaming_achievements?.profile_badges
            }
            aboutMe={follower?.aboutme}
            aboutMeReadStatus={follower?.aboutme_read_status}
            lookingFor={follower?.interest}
            tagline={follower?.tagline}
            occupation={follower?.occupation?.occupation}
            lifestyle={follower?.occupation?.lifestyle_budget}
            datingBudget={follower?.relationship?.dating_budget}
            education={follower?.occupation?.education}
            relationship={follower?.relationship?.relationship_status}
            smoking={follower?.habit?.smoking}
            drinking={follower?.habit?.drinking}
            height={follower?.appearance?.height}
            bodyType={follower?.appearance?.body_type}
            ethnicity={follower?.appearance?.ethnicity}
            eyeColor={follower?.appearance?.eye_color}
            hairColor={follower?.appearance?.hair_color}
            children={follower?.relationship?.children}
            // User Utility
            shouldCallApi={true}
            privatePhotoAbleToRequest={
              follower?.interaction?.private_photo_requested === false
                ? true
                : false
            }
            privatePhotoRequested={
              follower?.interaction?.private_photo_approval
            }
            privatePhotoApproval={follower?.interaction?.private_photo_approval}
            requestedMyPrivatePhoto={
              follower?.interaction?.requested_my_private_photo
            }
            approvedMyPrivatePhoto={
              follower?.interaction?.approved_my_private_photo
            }
            isBlocked={follower?.interaction?.is_blocked}
            isSilentInteraction={
              follower?.interaction?.should_silent_interactions
            }
            isFavorited={follower?.interaction?.is_favorited}
            isCallEnabled={
              follower?.private_call_settings?.enable_calls &&
              follower?.private_call_settings?.system_private_calls_enabled
            }
            isSugarbaby={
              follower?.role === userConst.userRole.sugarbaby ||
              follower?.role === userConst.userRole.maleSugarbaby
            }
            role={follower?.role}
            // Livestream
            channelId={follower?.live_stream?.channel_id}
            livestreamerId={follower?.id}
            followerCount={follower?.live_streaming_meta?.follower_count}
            viewerCount={follower?.live_stream?.viewer_count}
            diamondCount={
              follower?.live_streaming_meta?.lifetime_diamonds_earned
            }
            isFollowing={follower?.live_streaming_meta?.is_following}
            type={"followers"}
            key={index}
          />
        ))}
      </div>
    );
  } else if (
    firstLoad &&
    (getFollowersListFetching || getFollowersListLoading)
  ) {
    followersListView = <Spinner />;
  } else {
    return <EmptyList />;
  }

  return (
    <div id="follows-followers-tab-subcomponent">
      <div className="followers-list-container">
        <InfiniteScroll
          pageStart={0}
          loadMore={infiniteScrollFunc}
          hasMore={
            getFollowersListData?.data?.pagination?.next_cursor ? true : false
          }
          loader={<Spinner key={0} />}
          useWindow={false}
        >
          {followersListView}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default FollowersTab;
