// General
import "./support-settings.scss";
// Static Data
import routeConst from "../../../../const/routeConst";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";

const SupportSettings = () => {
  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Event Handlers | Button
  const onRedirect = (route, type) => {
    let routeName = "";

    switch (route) {
      case routeConst.zendesk.path:
        routeName = "FrequentlyAskedQuestions";
        break;
      case routeConst.support.path:
        routeName = "ContactSupport";
        break;
      default:
        break;
    }

    TagManager.dataLayer({
      dataLayer: {
        event: `PWA-12-${routeName}-Button`,
      },
    });

    if (type === "external") {
      window.open(route, "_blank");
    } else {
      onNavigate(route);
    }
  };

  return (
    <div id="settings-support-settings-subcomponent">
      <div className="padding-container">
        <div className="account-label">{t("settings.support")}</div>

        <div className="buttons-list-container">
          <div
            className="button-container"
            onClick={() => onRedirect(routeConst.zendesk.path, "external")}
          >
            <div className="button-label">
              {t("settings.frequently_asked_questions")}
            </div>
            <div className="suffix">
              <NavigateNextIcon />
            </div>
          </div>

          <div
            className="button-container"
            onClick={() => onRedirect(routeConst.support.path, "internal")}
          >
            <div className="button-label">{t("settings.contact_support")}</div>
            <div className="suffix">
              <NavigateNextIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportSettings;
