// General
import "./casino-games.scss";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// Services
import {
  useLazyGetGamingListQuery,
  useLazyInitiateGameQuery,
} from "../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateGameTitle,
  resetGameTitle,
  updateSelectedGameUrl,
  resetSelectedGameUrl,
  updateIsGameOpen,
} from "../../../redux/store/gameStore";
import { updateCasinoGameDialog } from "../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Divider, useMediaQuery } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
// Custom Hooks
import IconManager from "../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../shared/elements/spinner/spinner";

const CasinoGames = (props) => {
  const {
    showHeader = false,
    headerType = "dialog",
    showGameMenu = true,
    showRightArrow = false,
    showCloseButton = true,
    isDialog = false,
    applyRadius = false,
  } = props;

  // API variables
  const [
    getGamingList,
    {
      data: getGamingListData,
      error: getGamingListErrorData,
      isFetching: getGamingListFetching,
      isLoading: getGamingListLoading,
      isSuccess: getGamingListSuccess,
      isError: getGamingListError,
    },
  ] = useLazyGetGamingListQuery();
  const [
    initiateGame,
    {
      data: initiateGameData,
      error: initiateGameErrorData,
      isFetching: initiateGameFetching,
      isLoading: initiateGameLoading,
      isSuccess: initiateGameSuccess,
      isError: initiateGameError,
    },
  ] = useLazyInitiateGameQuery();

  // Redux variables
  const gameTitle = useSelector((state) => state.game.gameTitle);
  const selectedGameUrl = useSelector((state) => state.game.selectedGameUrl);
  const gameListPassthrough = useSelector(
    (state) => state.game.gameListPassthrough
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");
  const isLivestreamMobile = useMediaQuery("(max-width: 900px)");
  const isLivestreamTablet = useMediaQuery("(min-width: 900px)");
  const isLivestreamDesktop = useMediaQuery("(min-width: 1305px)");

  // Router variables
  const location = useLocation();
  const navigate = useNavigate();
  const queryString = new URLSearchParams(location.search);

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getGamingList(null, true);

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-16",
      },
    });

    return () => {
      dispatch(resetSelectedGameUrl());
    };
  }, []);

  // Lifecycle | Check for update | Gaming List API Response
  useEffect(() => {
    if (getGamingListFetching || getGamingListLoading) {
    } else if (getGamingListSuccess) {
      const gameId = parseInt(queryString.get("game_id"));
      const gameTitle = queryString.get("game_title");
      if (gameId) {
        for (let i = 0; i < getGamingListData?.data?.games?.length; i++) {
          if (getGamingListData?.data?.games[i]?.game_id === gameId) {
            dispatch(
              updateSelectedGameUrl(
                getGamingListData?.data?.games[i]?.initiate_endpoint
              )
            );
            initiateGame(
              getGamingListData?.data?.games[i]?.initiate_endpoint,
              false
            );

            dispatch(updateGameTitle(gameTitle));

            // Remove game_id from URL
            if (queryString.has("game_id")) {
              queryString.delete("game_id");
            }
            if (queryString.has("game_title")) {
              queryString.delete("game_title");
            }
            navigate({ search: queryString?.toString() });
          }
        }
      }
    } else if (getGamingListError) {
    }
  }, [
    getGamingListFetching,
    getGamingListLoading,
    getGamingListSuccess,
    getGamingListError,
  ]);

  // Lifecycle | Check for update | initiate Game API Response
  useEffect(() => {
    if (initiateGameFetching || initiateGameLoading) {
    } else if (initiateGameSuccess) {
    } else if (initiateGameError) {
    }
  }, [
    initiateGameFetching,
    initiateGameLoading,
    initiateGameSuccess,
    initiateGameError,
  ]);

  // Lifecycle | Check for update | gameListPassthrough
  useEffect(() => {
    if (gameListPassthrough) {
      onBackToGameSelect();
    }
  }, [gameListPassthrough]);

  // Event Handlers | Button
  const onBackToGameSelect = () => {
    dispatch(resetSelectedGameUrl());
    getGamingList(null, false);

    dispatch(resetGameTitle());

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-16-BackTo-16.1-Button",
      },
    });
  };
  const onGameSelect = (game) => {
    dispatch(updateSelectedGameUrl(game?.initiate_endpoint));
    initiateGame(game?.initiate_endpoint, false);

    dispatch(updateGameTitle(game?.title));

    TagManager.dataLayer({
      dataLayer: {
        event: `PWA-16-16.1-Button-${game?.game_id}`,
      },
    });
  };
  const onOpenGameLauncher = () => {
    dispatch(updateIsGameOpen());
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = (event, reason) => {
    if (reason === "backdropClick") return;

    dispatch(updateCasinoGameDialog(false));
  };

  if (getGamingListFetching || getGamingListLoading) {
    return <Spinner />;
  } else if (getGamingListSuccess) {
    return (
      <div id="casino-games-page">
        <div className="max-width-container">
          {showHeader && headerType === "dialog" && (
            <div className="dialog-header">
              <div
                className="game-selection-button-container"
                onClick={onBackToGameSelect}
              >
                {showGameMenu &&
                  selectedGameUrl &&
                  getIcon("threeAceIcon", "game-selection-button")}
              </div>

              <div className="game-title-container">{gameTitle}</div>

              <div className="close-button-container">
                {showCloseButton && (
                  <CloseIcon className="close-button" onClick={onCloseDialog} />
                )}
              </div>
            </div>
          )}

          {headerType === "desktop" && !isMobile && (
            <div className="game-label-container">
              <div className="game-label">{gameTitle}</div>

              <div
                className="game-selection-button-container"
                onClick={onBackToGameSelect}
              >
                {showGameMenu &&
                  selectedGameUrl &&
                  getIcon("threeAceIcon", "game-selection-button")}

                {showRightArrow && (
                  <ChevronRightIcon onClick={onOpenGameLauncher} />
                )}
              </div>
            </div>
          )}

          {showHeader && headerType === "desktop" && <Divider />}

          {!selectedGameUrl && (
            <div className="game-list-container">
              {getGamingListData?.data?.games?.map((game, index) => (
                <div
                  className="game-container"
                  key={index}
                  onClick={() => onGameSelect(game)}
                >
                  <img className="game-logo" src={game?.logo_large} />
                </div>
              ))}
            </div>
          )}

          {selectedGameUrl && initiateGameSuccess && (
            <div
              className={`${
                isMobile
                  ? "casino-game-mobile-container"
                  : "casino-game-desktop-container"
              } ${isDialog ? "casino-fix-height" : ""} ${
                applyRadius ? "casino-apply-radius" : ""
              } ${!isMobile && isLivestreamMobile ? "casino-fix-width" : ""} ${
                headerType !== "desktop" &&
                !isLivestreamDesktop &&
                !isMobile &&
                isLivestreamTablet
                  ? "casino-fix-width"
                  : ""
              }`}
            >
              <div
                className={`casino-game-inner-container ${
                  isDialog ? "casino-max-width" : ""
                }`}
              >
                <iframe
                  className={`casino-game-iframe ${
                    applyRadius ? "casino-apply-radius" : ""
                  }`}
                  src={initiateGameData?.data?.url}
                ></iframe>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  } else if (getGamingListError) {
  }
};

export default CasinoGames;
