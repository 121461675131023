// General
import "./user-profile-tab.scss";
import { useState, useEffect } from "react";
// react-gtm-module
import TagManager from "react-gtm-module";
// embla-carousel-react
import useEmblaCarousel from "embla-carousel-react";
// Material UI
import { Tabs, Tab, Box } from "@mui/material";
import PropTypes from "prop-types";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import PhotosTab from "./photos-tab/photos-tab";
import AboutMeTab from "./about-me-tab/about-me-tab";
import AchievementTab from "./achievement-tab/achievement-tab";
import RequestPrivatePhotoDialog from "../../../../shared/dialog-content/request-private-photo-dialog/request-private-photo-dialog";

const UserProfileTab = (props) => {
  const { userProfileType } = props;

  // MUI variables
  const [value, setValue] = useState(0);

  // Embla variables
  const [emblaRef, emblaApi] = useEmblaCarousel({
    duration: 15,
  });

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted | emblaApi
  useEffect(() => {
    if (!emblaApi) return;
    emblaApi?.scrollTo(value);

    emblaApi?.on("select", () => {
      setValue(emblaApi?.selectedScrollSnap());
    });
  }, [emblaApi]);

  // Lifecycle | Check for update | interestTab
  useEffect(() => {
    emblaApi?.scrollTo(value);
  }, [value]);

  // Event Handlers | MUI Tabs
  const onTabChange = (event, newValue) => {
    TagManager.dataLayer({
      dataLayer: {
        event: `PWA-30-30.1-Button-${
          newValue === 0
            ? "PhotosTab"
            : newValue === 1
            ? "AboutTab"
            : newValue === 2
            ? "AchievementTab"
            : "ReviewsTab"
        }`,
      },
    });

    setValue(newValue);
  };

  return (
    <div id="bottom-user-container-user-profile-tab-subcomponent">
      <Tabs
        className="custom-tabs"
        value={value}
        onChange={onTabChange}
        TabIndicatorProps={{ style: { backgroundColor: "#710d0d" } }}
        variant="fullWidth"
      >
        <Tab
          className="user-profile-tab-label"
          icon={getIcon("profilePhotoIcon", "tab-photo-icon")}
          iconPosition="start"
          label={t("profile.edit_photos")}
          {...a11yProps(0)}
        />
        <Tab
          className="user-profile-tab-label"
          icon={getIcon("profileAboutMeIcon", "tab-about-me-icon")}
          iconPosition="start"
          label={t("settings.about")}
          {...a11yProps(1)}
        />
        <Tab
          className="user-profile-tab-label"
          icon={getIcon("profileAchievementIcon", "tab-achievement-icon")}
          iconPosition="start"
          label={t("profile.achievements")}
          {...a11yProps(2)}
        />
        {/* <Tab
          className="user-profile-tab-label"
          icon={<LockIcon />}
          iconPosition="start"
          label={t("profile.review")}
          disabled
          {...a11yProps(3)}
        /> */}
      </Tabs>

      <div ref={emblaRef} className="embla">
        <div className="embla__container">
          <div className="embla__slide">
            {value === 0 && <PhotosTab userProfileType={userProfileType} />}
          </div>

          <div className="embla__slide">{value === 1 && <AboutMeTab />}</div>

          <div className="embla__slide">
            {value === 2 && <AchievementTab />}
          </div>
        </div>
      </div>

      <RequestPrivatePhotoDialog />
    </div>
  );
};

export default UserProfileTab;

// Material UI | Tab Functions
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      // hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }} className="tab-box-container">
          {children}
          {/* <Typography component={"div"}>{children}</Typography> */}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
