// General
import "./relationship-status-filter.scss";
import { useEffect } from "react";
// Services
import { useLazyGetJourneyQuestionsQuery } from "../../../../../services/data.service";
// Static Data
import searchConst from "../../../../../const/searchConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateRelationshipStatusData,
  updateRelationshipStatusSelected,
} from "../../../../../redux/store/searchFilterStore";
import { updateRelationshipStatusFilterDialog } from "../../../../../redux/store/dialogStore";
// Material UI
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation } from "react-i18next";
// Components
import Spinner from "../../spinner/spinner";

const RelationshipStatusFilter = (props) => {
  const { type, enableTitle = true } = props;

  // API variables
  const [
    getJourneyQuestions,
    {
      data: getJourneyQuestionsData,
      error: getJourneyQuestionsErrorData,
      isFetching: getJourneyQuestionsFetching,
      isLoading: getJourneyQuestionsLoading,
      isSuccess: getJourneyQuestionsSuccess,
      isError: getJourneyQuestionsError,
    },
  ] = useLazyGetJourneyQuestionsQuery();

  // Redux variables
  const relationshipStatusData = useSelector(
    (state) => state.searchFilter.relationshipStatusData
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    getJourneyQuestions(null, true);
  }, []);

  // Lifecycle | Check for update | getJourneyQuestions API Response
  useEffect(() => {
    if (getJourneyQuestionsFetching || getJourneyQuestionsLoading) {
    } else if (getJourneyQuestionsSuccess) {
      if (relationshipStatusData?.length > 0) return;

      let tempRelationshipStatusData = getRelationshipData()?.map((item) => ({
        ...item,
        isSelected: false,
      }));
      dispatch(updateRelationshipStatusData(tempRelationshipStatusData));
    } else if (getJourneyQuestionsError) {
    }
  }, [
    getJourneyQuestionsFetching,
    getJourneyQuestionsLoading,
    getJourneyQuestionsSuccess,
    getJourneyQuestionsError,
  ]);

  // Lifecycle | Check for update | relationshipStatusData
  useEffect(() => {
    dispatch(updateRelationshipStatusSelected(getSelectedRelationshipStatus()));
  }, [relationshipStatusData]);

  // Event Handlers | MUI Checkbox
  const onCheckboxChange = (id) => (event) => {
    const updatedRelationshipStatusData = relationshipStatusData?.map((item) =>
      item.id === id ? { ...item, isSelected: event.target.checked } : item
    );
    dispatch(updateRelationshipStatusData(updatedRelationshipStatusData));
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateRelationshipStatusFilterDialog(false));
  };

  // Utility Functions
  const getRelationshipData = () => {
    return getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "relationship"
    )?.selections;
  };
  const getSelectedRelationshipStatus = () => {
    return relationshipStatusData?.filter((item) => item.isSelected);
  };

  return (
    <div id="relationship-status-filter-shared-component">
      {enableTitle && (
        <div className="dialog-header">
          <div className="title">{t("filter.relationship_status")}</div>

          <div className="close-button-container">
            <CloseIcon className="close-button" onClick={onCloseDialog} />
          </div>
        </div>
      )}

      <div className="padding-container">
        {relationshipStatusData?.length > 0 ? (
          <div
            className={`checkbox-container ${
              type === searchConst.searchFilterType.full ? "flexwrap" : ""
            }`}
          >
            {relationshipStatusData?.map((relationship, index) => (
              <FormGroup className="checkbox" key={index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      className={`custom-checkbox ${
                        type === searchConst.searchFilterType.full
                          ? "custom-checkbox-no-padding"
                          : ""
                      }`}
                      size={
                        type === searchConst.searchFilterType.full
                          ? "small"
                          : "medium"
                      }
                      checked={relationship?.isSelected}
                      onChange={onCheckboxChange(relationship?.id)}
                    />
                  }
                  label={
                    <span
                      className={
                        type === searchConst.searchFilterType.full
                          ? "checkbox-font"
                          : ""
                      }
                    >
                      {relationship?.display_locale}
                    </span>
                  }
                />
              </FormGroup>
            ))}
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

export default RelationshipStatusFilter;
