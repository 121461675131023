// General
import "./empty-list.scss";
// Redux
import { useSelector } from "react-redux";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import AssetManager from "../../../../utility/manager/asset-manager/asset-manager";

const EmptyList = () => {
  // Render variables
  let titleView;
  let descriptionView;

  // Redux variables
  const followersTabValue = useSelector(
    (state) => state.user.followersTabValue
  );

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getAsset = AssetManager();

  // Render Functions
  switch (followersTabValue) {
    case 0:
      titleView = t("profile.empty_followers");
      descriptionView = t("profile.empty_followers_desc");
      break;
    case 1:
      titleView = t("profile.empty_following");
      descriptionView = t("profile.empty_following_desc");
      break;
    case 2:
      titleView = t("profile.empty_gifters");
      descriptionView = t("profile.empty_gifters_desc");
      break;
    default:
      break;
  }
  return (
    <div id="follows-empty-list-subcomponent">
      <div className="empty-live-list-image-container">
        <img
          className="empty-live-list-image"
          src={getAsset("emptyLiveListAlt")}
          alt="empty"
        />
      </div>

      <div className="title">{titleView}</div>
      <div className="description">{descriptionView}</div>
    </div>
  );
};

export default EmptyList;
