// General
import "./signup-success.scss";
import { useEffect } from "react";
// Static Data
import routeConst from "../../../const/routeConst";
// Redux
import { useDispatch } from "react-redux";
import { updateIsLoggedIn } from "../../../redux/store/publicStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
import AssetManager from "../../utility/manager/asset-manager/asset-manager";

const SignupSuccess = () => {
  // Redux variables
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getAsset = AssetManager();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-SignupSuccess-Page",
      },
    });
  }, []);

  // Event Handlers | Button
  const onRedirectToApp = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-EmailVerification-Page-RedirectToSearch-Button",
      },
    });

    dispatch(updateIsLoggedIn(true));
    onNavigate(routeConst.search.path);
  };

  return (
    <div
      id="signup-success-page"
      style={{
        backgroundImage: `url(${getAsset("emailVerificationBackground")})`,
      }}
    >
      <div className="padding-container">
        <div className="signup-success-body">
          <div className="check-gif-container">
            <img
              className="check-gif"
              src={getAsset("checkRedGif")}
              loop={false}
            />
          </div>

          <div className="signup-success-body-top-container">
            <div className="thank-you-label">
              {t("signup.signup_success_title")}
            </div>
          </div>

          <div className="registered-sugarbook-description">
            <Trans
              i18nKey={t("signup.signup_success_desc")}
              components={{ br: <br /> }}
            />
          </div>
        </div>

        <div className="done-button" onClick={onRedirectToApp}>
          {t("common.done")}
        </div>
      </div>
    </div>
  );
};

export default SignupSuccess;
