// General
import "./live-card.scss";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useDispatch } from "react-redux";
import {
  updateChannelId,
  updateLivestreamerId,
  updateLivestreamerIdInt,
  updateLivestreamerProfilePhoto,
  updateLivestreamerAchievements,
  updateLivestreamerUsername,
  updateLivestreamerLevellingBadge,
  updateLivestreamerFollowers,
  updateLivestreamerViewers,
  updateLivestreamerDiamonds,
  updateLivestreamerIsFollowing,
} from "../../../../redux/store/livestreamingStore";
// Material UI
import VisibilityIcon from "@mui/icons-material/Visibility";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

// TODO: DEPRECATED, Moved to UserCard, Remove when necessary
const LiveCard = (props) => {
  const {
    channelId,
    livestreamerId,
    livestreamerIdInt,
    profilePhoto,
    livestreamerAchievements,
    username,
    age,
    location,
    levellingBadge,
    followerCount,
    viewerCount,
    diamondCount,
    isFollowing,
  } = props;

  // Redux variables
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Event Handlers | Button
  const onEnterLivestream = () => {
    dispatch(updateChannelId(channelId));
    dispatch(updateLivestreamerId(livestreamerId));
    dispatch(updateLivestreamerIdInt(livestreamerIdInt));
    dispatch(updateLivestreamerProfilePhoto(profilePhoto));
    dispatch(updateLivestreamerAchievements(livestreamerAchievements));
    dispatch(updateLivestreamerUsername(username));
    dispatch(updateLivestreamerLevellingBadge(levellingBadge));
    dispatch(updateLivestreamerFollowers(followerCount));
    dispatch(updateLivestreamerViewers(viewerCount));
    dispatch(updateLivestreamerDiamonds(diamondCount));
    dispatch(updateLivestreamerIsFollowing(isFollowing));
    onNavigate(`${routeConst.live.liveId.clearPath}${channelId}`);
  };

  return (
    <div
      id="live-live-card-subcomponent"
      className="live-card"
      onClick={onEnterLivestream}
    >
      <div className="live-card-container">
        <div className="profile-image-container">
          {profilePhoto ? (
            <img className="profile-image" src={profilePhoto} alt={"profile"} />
          ) : (
            getIcon("defaultProfilePhotoIcon", "profile-image")
          )}
        </div>

        <div className="description-container">
          <div className="top-description-container">
            <div className="live-status">{t("live.live")}</div>
            <div className="viewer-count-container">
              <div className="eye-logo-container">
                <VisibilityIcon className="eye-logo" />
              </div>
              <div className="viewer-count">{viewerCount || 0}</div>
            </div>
          </div>

          <div className="bottom-description-container">
            {livestreamerAchievements?.length > 0 && (
              <div className="livestreaming-achievements-badge-container">
                {livestreamerAchievements?.map((achievement, index) => (
                  <img
                    className="livestreaming-achievements-badge"
                    src={achievement?.url}
                    key={index}
                  />
                ))}
              </div>
            )}

            <div className="name-age-status-container">
              <div className="name">{username || "-"},</div>
              <div className="age">{age || "-"}</div>
              <div className="status"></div>
            </div>

            <div className="location-description">
              <div className="location">{location || ""}</div>
              {levellingBadge && (
                <div className="badge-container">
                  <img className="badge" src={levellingBadge} alt="badge" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveCard;
