// General
import "./top-leaderboard-list.scss";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
// Services
import {
  useLazyGetLivestreamingLeaderboardQuery,
  useLazyGetPrivateCallsLeaderboardQuery,
  useLazyGetPrivateCallContestLeaderboardQuery,
  useLazyGetLivestreamingContestLeaderboardQuery,
  useLazyGetLivestreamingPkLeaderboardQuery,
  useLazyGetGamingLeaderboardQuery,
} from "../../../services/data.service";
// Static Data
import leaderboardConst from "../../../const/leaderboardConst";
import routeConst from "../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateLeaderboardLists,
  resetLeaderboardLists,
  updateLeaderboardTitle,
  updateLeaderboardPagination,
} from "../../../redux/store/leaderboardStore";
// React Infinite Scroller
import InfiniteScroll from "react-infinite-scroller";
// react-simple-pull-to-refresh
import PullToRefresh from "react-simple-pull-to-refresh";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import Spinner from "../../shared/elements/spinner/spinner";
import UserList from "../../shared/leaderboard/leaderboard-section/user-list/user-list";
import EmptyList from "../../shared/leaderboard/leaderboard-section/empty-list/empty-list";
import UnfollowUserDialog from "../../shared/dialog-content/unfollow-user-dialog/unfollow-user-dialog";

const TopLeaderboardList = (props) => {
  const { state = "queryString" } = props;

  // API variables
  const [
    getLivestreamingLeaderboardTopStreamers,
    {
      data: getLivestreamingLeaderboardTopStreamersData,
      error: getLivestreamingLeaderboardTopStreamersErrorData,
      isFetching: getLivestreamingLeaderboardTopStreamersFetching,
      isLoading: getLivestreamingLeaderboardTopStreamersLoading,
      isSuccess: getLivestreamingLeaderboardTopStreamersSuccess,
      isError: getLivestreamingLeaderboardTopStreamersError,
    },
  ] = useLazyGetLivestreamingLeaderboardQuery();
  const [
    getLivestreamingLeaderboardTopGifters,
    {
      data: getLivestreamingLeaderboardTopGiftersData,
      error: getLivestreamingLeaderboardTopGiftersErrorData,
      isFetching: getLivestreamingLeaderboardTopGiftersFetching,
      isLoading: getLivestreamingLeaderboardTopGiftersLoading,
      isSuccess: getLivestreamingLeaderboardTopGiftersSuccess,
      isError: getLivestreamingLeaderboardTopGiftersError,
    },
  ] = useLazyGetLivestreamingLeaderboardQuery();
  const [
    getPrivateCallLeaderboardTopStreamers,
    {
      data: getPrivateCallLeaderboardTopStreamersData,
      error: getPrivateCallLeaderboardTopStreamersErrorData,
      isFetching: getPrivateCallLeaderboardTopStreamersFetching,
      isLoading: getPrivateCallLeaderboardTopStreamersLoading,
      isSuccess: getPrivateCallLeaderboardTopStreamersSuccess,
      isError: getPrivateCallLeaderboardTopStreamersError,
    },
  ] = useLazyGetPrivateCallsLeaderboardQuery();
  const [
    getPrivateCallLeaderboardTopGifters,
    {
      data: getPrivateCallLeaderboardTopGiftersData,
      error: getPrivateCallLeaderboardTopGiftersErrorData,
      isFetching: getPrivateCallLeaderboardTopGiftersFetching,
      isLoading: getPrivateCallLeaderboardTopGiftersLoading,
      isSuccess: getPrivateCallLeaderboardTopGiftersSuccess,
      isError: getPrivateCallLeaderboardTopGiftersError,
    },
  ] = useLazyGetPrivateCallsLeaderboardQuery();
  const [
    getPrivateCallContestLeaderboardTopStreamers,
    {
      data: getPrivateCallContestLeaderboardTopStreamersData,
      error: getPrivateCallContestLeaderboardTopStreamersErrorData,
      isFetching: getPrivateCallContestLeaderboardTopStreamersFetching,
      isLoading: getPrivateCallContestLeaderboardTopStreamersLoading,
      isSuccess: getPrivateCallContestLeaderboardTopStreamersSuccess,
      isError: getPrivateCallContestLeaderboardTopStreamersError,
    },
  ] = useLazyGetPrivateCallContestLeaderboardQuery();
  const [
    getPrivateCallContestLeaderboardTopGifters,
    {
      data: getPrivateCallContestLeaderboardTopGiftersData,
      error: getPrivateCallContestLeaderboardTopGiftersErrorData,
      isFetching: getPrivateCallContestLeaderboardTopGiftersFetching,
      isLoading: getPrivateCallContestLeaderboardTopGiftersLoading,
      isSuccess: getPrivateCallContestLeaderboardTopGiftersSuccess,
      isError: getPrivateCallContestLeaderboardTopGiftersError,
    },
  ] = useLazyGetPrivateCallContestLeaderboardQuery();
  const [
    getLivestreamingContestLeaderboardTopStreamers,
    {
      data: getLivestreamingContestLeaderboardTopStreamersData,
      error: getLivestreamingContestLeaderboardTopStreamersErrorData,
      isFetching: getLivestreamingContestLeaderboardTopStreamersFetching,
      isLoading: getLivestreamingContestLeaderboardTopStreamersLoading,
      isSuccess: getLivestreamingContestLeaderboardTopStreamersSuccess,
      isError: getLivestreamingContestLeaderboardTopStreamersError,
    },
  ] = useLazyGetLivestreamingContestLeaderboardQuery();
  const [
    getLivestreamingContestLeaderboardTopGifters,
    {
      data: getLivestreamingContestLeaderboardTopGiftersData,
      error: getLivestreamingContestLeaderboardTopGiftersErrorData,
      isFetching: getLivestreamingContestLeaderboardTopGiftersFetching,
      isLoading: getLivestreamingContestLeaderboardTopGiftersLoading,
      isSuccess: getLivestreamingContestLeaderboardTopGiftersSuccess,
      isError: getLivestreamingContestLeaderboardTopGiftersError,
    },
  ] = useLazyGetLivestreamingContestLeaderboardQuery();
  const [
    getLivestreamingPkLeaderboardTopStreamers,
    {
      data: getLivestreamingPkLeaderboardTopStreamersData,
      error: getLivestreamingPkLeaderboardTopStreamersErrorData,
      isFetching: getLivestreamingPkLeaderboardTopStreamersFetching,
      isLoading: getLivestreamingPkLeaderboardTopStreamersLoading,
      isSuccess: getLivestreamingPkLeaderboardTopStreamersSuccess,
      isError: getLivestreamingPkLeaderboardTopStreamersError,
    },
  ] = useLazyGetLivestreamingPkLeaderboardQuery();
  const [
    getLivestreamingPkLeaderboardTopGifters,
    {
      data: getLivestreamingPkLeaderboardTopGiftersData,
      error: getLivestreamingPkLeaderboardTopGiftersErrorData,
      isFetching: getLivestreamingPkLeaderboardTopGiftersFetching,
      isLoading: getLivestreamingPkLeaderboardTopGiftersLoading,
      isSuccess: getLivestreamingPkLeaderboardTopGiftersSuccess,
      isError: getLivestreamingPkLeaderboardTopGiftersError,
    },
  ] = useLazyGetLivestreamingPkLeaderboardQuery();
  const [
    getGamingTopPlayerLeaderboard,
    {
      data: getGamingTopPlayerLeaderboardData,
      error: getGamingTopPlayerLeaderboardErrorData,
      isFetching: getGamingTopPlayerLeaderboardFetching,
      isLoading: getGamingTopPlayerLeaderboardLoading,
      isSuccess: getGamingTopPlayerLeaderboardSuccess,
      isError: getGamingTopPlayerLeaderboardError,
    },
  ] = useLazyGetGamingLeaderboardQuery();
  const [
    getGamingTopWagerLeaderboard,
    {
      data: getGamingTopWagerLeaderboardData,
      error: getGamingTopWagerLeaderboardErrorData,
      isFetching: getGamingTopWagerLeaderboardFetching,
      isLoading: getGamingTopWagerLeaderboardLoading,
      isSuccess: getGamingTopWagerLeaderboardSuccess,
      isError: getGamingTopWagerLeaderboardError,
    },
  ] = useLazyGetGamingLeaderboardQuery();

  // General variables
  const [firstLoad, setFirstLoad] = useState(true);
  const [isApiLoading, setIsApiLoading] = useState(false);

  // Render variables
  let topLeaderboardView = <div></div>;

  // Redux variables
  const leaderboardLists = useSelector(
    (state) => state.leaderboard.leaderboardLists
  );
  const leaderboardPagination = useSelector(
    (state) => state.leaderboard.leaderboardPagination
  );
  const livestreamingContestId = useSelector(
    (state) => state.leaderboard.livestreamingContestId
  );
  const privateCallContestId = useSelector(
    (state) => state.leaderboard.privateCallContestId
  );
  const topLeaderboardListInfo = useSelector(
    (state) => state.leaderboard.topLeaderboardListInfo
  );
  const livestreamingContestPkId = useSelector(
    (state) => state.leaderboard.livestreamingContestPkId
  );
  const dispatch = useDispatch();

  // Router variables
  const [searchParams] = useSearchParams();

  // i18next variables
  const { t } = useTranslation();

  // Utility variables
  const type =
    state === "queryString"
      ? searchParams.get("type")
      : topLeaderboardListInfo?.type;
  const recurrence =
    state === "queryString"
      ? searchParams.get("recurrence")
      : topLeaderboardListInfo?.recurrence;
  const userType =
    state === "queryString"
      ? searchParams.get("userType")
      : topLeaderboardListInfo?.userType;
  const gameId =
    state === "queryString"
      ? searchParams.get("gameId")
      : topLeaderboardListInfo?.gameId;

  const topStreamersParam = {
    board_type: "top_streamers",
    board_period_type: leaderboardConst.tabs.monthly,
  };
  const topGiftersParam = {
    board_type: "top_gifters",
    board_period_type: leaderboardConst.tabs.monthly,
  };
  const topStreamersPrivateCallEventParam = {
    board_type: "top_streamers",
    contest_id: privateCallContestId,
  };
  const topGiftersPrivateCallEventParam = {
    board_type: "top_gifters",
    contest_id: privateCallContestId,
  };
  const topStreamersLivestreamEventParam = {
    category: "live_contest",
    board_type: "top_streamers",
    tier: 1,
    contest_id: livestreamingContestId,
  };
  const topGiftersLivestreamEventParam = {
    category: "live_contest",
    board_type: "top_gifters",
    tier: 1,
    contest_id: livestreamingContestId,
  };
  const topStreamersLivestreamEventPkParam = {
    board_type: "top_streamers",
    contest_id: livestreamingContestPkId,
  };
  const topGiftersLivestreamEventPkParam = {
    board_type: "top_gifters",
    contest_id: livestreamingContestPkId,
  };
  const casinoTopPlayerParam = {
    board_period_type: leaderboardConst.tabs.daily,
    board_type: "top_players",
    game_id: gameId,
    pagination_token: "",
  };
  const casinoTopWagerParam = {
    board_period_type: leaderboardConst.tabs.daily,
    board_type: "top_wagers",
    game_id: gameId,
    pagination_token: "",
  };

  // Custom Hooks
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!livestreamingContestId || !privateCallContestId) return;
    // Backup for when the user refreshes the page

    setTopbarTitle();

    dispatch(resetLeaderboardLists());

    onCallApi();
  }, [livestreamingContestId, privateCallContestId]);

  // Lifecycle | Check for update | Livestreaming Leaderboard Top Streamers
  useEffect(() => {
    if (
      getLivestreamingLeaderboardTopStreamersFetching ||
      getLivestreamingLeaderboardTopStreamersLoading
    ) {
    } else if (getLivestreamingLeaderboardTopStreamersSuccess) {
      if (getLivestreamingLeaderboardTopStreamersData?.status === 1) {
        setFirstLoad(false);
        setIsApiLoading(false);

        dispatch(
          updateLeaderboardPagination(
            getLivestreamingLeaderboardTopStreamersData?.data?.top_streamers
              ?.pagination?.next_cursor
          )
        );
        if (
          getLivestreamingLeaderboardTopStreamersData?.data?.top_streamers
            ?.results?.length > 0
        ) {
          dispatch(
            updateLeaderboardLists(
              getLivestreamingLeaderboardTopStreamersData?.data?.top_streamers
                ?.results
            )
          );
        }
      }
    } else if (getLivestreamingLeaderboardTopStreamersError) {
      if (getLivestreamingLeaderboardTopStreamersErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getLivestreamingLeaderboardTopStreamersFetching,
    getLivestreamingLeaderboardTopStreamersLoading,
    getLivestreamingLeaderboardTopStreamersSuccess,
    getLivestreamingLeaderboardTopStreamersError,
  ]);

  // Lifecycle | Check for update | Livestreaming Leaderboard Top Gifters
  useEffect(() => {
    if (
      getLivestreamingLeaderboardTopGiftersFetching ||
      getLivestreamingLeaderboardTopGiftersLoading
    ) {
    } else if (getLivestreamingLeaderboardTopGiftersSuccess) {
      if (getLivestreamingLeaderboardTopGiftersData?.status === 1) {
        setFirstLoad(false);
        setIsApiLoading(false);

        dispatch(
          updateLeaderboardPagination(
            getLivestreamingLeaderboardTopGiftersData?.data?.top_gifters
              ?.pagination?.next_cursor
          )
        );

        if (
          getLivestreamingLeaderboardTopGiftersData?.data?.top_gifters?.results
            ?.length > 0
        ) {
          dispatch(
            updateLeaderboardLists(
              getLivestreamingLeaderboardTopGiftersData?.data?.top_gifters
                ?.results
            )
          );
        }
      }
    } else if (getLivestreamingLeaderboardTopGiftersError) {
      if (getLivestreamingLeaderboardTopGiftersErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getLivestreamingLeaderboardTopGiftersFetching,
    getLivestreamingLeaderboardTopGiftersLoading,
    getLivestreamingLeaderboardTopGiftersSuccess,
    getLivestreamingLeaderboardTopGiftersError,
  ]);

  // Lifecycle | Check for update | Private Call Leaderboard Top Streamers
  useEffect(() => {
    if (
      getPrivateCallLeaderboardTopStreamersFetching ||
      getPrivateCallLeaderboardTopStreamersLoading
    ) {
    } else if (getPrivateCallLeaderboardTopStreamersSuccess) {
      if (getPrivateCallLeaderboardTopStreamersData?.status === 1) {
        setFirstLoad(false);
        setIsApiLoading(false);

        dispatch(
          updateLeaderboardPagination(
            getPrivateCallLeaderboardTopStreamersData?.data?.top_streamers
              ?.pagination?.next_cursor
          )
        );

        if (
          getPrivateCallLeaderboardTopStreamersData?.data?.top_streamers
            ?.results?.length > 0
        ) {
          dispatch(
            updateLeaderboardLists(
              getPrivateCallLeaderboardTopStreamersData?.data?.top_streamers
                ?.results
            )
          );
        }
      }
    } else if (getPrivateCallLeaderboardTopStreamersError) {
      if (getPrivateCallLeaderboardTopStreamersErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getPrivateCallLeaderboardTopStreamersFetching,
    getPrivateCallLeaderboardTopStreamersLoading,
    getPrivateCallLeaderboardTopStreamersSuccess,
    getPrivateCallLeaderboardTopStreamersError,
  ]);

  // Lifecycle | Check for update | Private Call Leaderboard Top Gifters
  useEffect(() => {
    if (
      getPrivateCallLeaderboardTopGiftersFetching ||
      getPrivateCallLeaderboardTopGiftersLoading
    ) {
    } else if (getPrivateCallLeaderboardTopGiftersSuccess) {
      if (getPrivateCallLeaderboardTopGiftersData?.status === 1) {
        setFirstLoad(false);
        setIsApiLoading(false);

        dispatch(
          updateLeaderboardPagination(
            getPrivateCallLeaderboardTopGiftersData?.data?.top_gifters
              ?.pagination?.next_cursor
          )
        );

        if (
          getPrivateCallLeaderboardTopGiftersData?.data?.top_gifters?.results
            ?.length > 0
        ) {
          dispatch(
            updateLeaderboardLists(
              getPrivateCallLeaderboardTopGiftersData?.data?.top_gifters
                ?.results
            )
          );
        }
      }
    } else if (getPrivateCallLeaderboardTopGiftersError) {
      if (getPrivateCallLeaderboardTopGiftersErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getPrivateCallLeaderboardTopGiftersFetching,
    getPrivateCallLeaderboardTopGiftersLoading,
    getPrivateCallLeaderboardTopGiftersSuccess,
    getPrivateCallLeaderboardTopGiftersError,
  ]);

  // Lifecycle | Check for update | Private Call Contest Leaderboard Top Streamers
  useEffect(() => {
    if (
      getPrivateCallContestLeaderboardTopStreamersFetching ||
      getPrivateCallContestLeaderboardTopStreamersLoading
    ) {
    } else if (getPrivateCallContestLeaderboardTopStreamersSuccess) {
      if (getPrivateCallContestLeaderboardTopStreamersData?.status === 1) {
        setFirstLoad(false);
        setIsApiLoading(false);

        dispatch(
          updateLeaderboardPagination(
            getPrivateCallContestLeaderboardTopStreamersData?.data
              ?.top_streamers?.pagination?.next_cursor
          )
        );

        if (
          getPrivateCallContestLeaderboardTopStreamersData?.data?.top_streamers
            ?.results?.length > 0
        ) {
          dispatch(
            updateLeaderboardLists(
              getPrivateCallContestLeaderboardTopStreamersData?.data
                ?.top_streamers?.results
            )
          );
        }
      }
    } else if (getPrivateCallContestLeaderboardTopStreamersError) {
      if (
        getPrivateCallContestLeaderboardTopStreamersErrorData?.status === 401
      ) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getPrivateCallContestLeaderboardTopStreamersFetching,
    getPrivateCallContestLeaderboardTopStreamersLoading,
    getPrivateCallContestLeaderboardTopStreamersSuccess,
    getPrivateCallContestLeaderboardTopStreamersError,
  ]);

  // Lifecycle | Check for update | Private Call Contest Leaderboard Top Gifters
  useEffect(() => {
    if (
      getPrivateCallContestLeaderboardTopGiftersFetching ||
      getPrivateCallContestLeaderboardTopGiftersLoading
    ) {
    } else if (getPrivateCallContestLeaderboardTopGiftersSuccess) {
      if (getPrivateCallContestLeaderboardTopGiftersData?.status === 1) {
        setFirstLoad(false);
        setIsApiLoading(false);

        dispatch(
          updateLeaderboardPagination(
            getPrivateCallContestLeaderboardTopGiftersData?.data?.top_gifters
              ?.pagination?.next_cursor
          )
        );

        if (
          getPrivateCallContestLeaderboardTopGiftersData?.data?.top_gifters
            ?.results?.length > 0
        ) {
          dispatch(
            updateLeaderboardLists(
              getPrivateCallContestLeaderboardTopGiftersData?.data?.top_gifters
                ?.results
            )
          );
        }
      }
    } else if (getPrivateCallContestLeaderboardTopGiftersError) {
      if (getPrivateCallContestLeaderboardTopGiftersErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getPrivateCallContestLeaderboardTopGiftersFetching,
    getPrivateCallContestLeaderboardTopGiftersLoading,
    getPrivateCallContestLeaderboardTopGiftersSuccess,
    getPrivateCallContestLeaderboardTopGiftersError,
  ]);

  // Lifecycle | Check for update | Livestreaming Contest Leaderboard Top Streamers
  useEffect(() => {
    if (
      getLivestreamingContestLeaderboardTopStreamersFetching ||
      getLivestreamingContestLeaderboardTopStreamersLoading
    ) {
    } else if (getLivestreamingContestLeaderboardTopStreamersSuccess) {
      if (getLivestreamingContestLeaderboardTopStreamersData?.status === 1) {
        setFirstLoad(false);
        setIsApiLoading(false);

        dispatch(
          updateLeaderboardPagination(
            getLivestreamingContestLeaderboardTopStreamersData?.data
              ?.top_streamers?.pagination?.next_cursor
          )
        );

        if (
          getLivestreamingContestLeaderboardTopStreamersData?.data
            ?.top_streamers?.results?.length > 0
        ) {
          dispatch(
            updateLeaderboardLists(
              getLivestreamingContestLeaderboardTopStreamersData?.data
                ?.top_streamers?.results
            )
          );
        }
      }
    } else if (getLivestreamingContestLeaderboardTopStreamersError) {
      if (
        getLivestreamingContestLeaderboardTopStreamersErrorData?.status === 401
      ) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getLivestreamingContestLeaderboardTopStreamersFetching,
    getLivestreamingContestLeaderboardTopStreamersLoading,
    getLivestreamingContestLeaderboardTopStreamersSuccess,
    getLivestreamingContestLeaderboardTopStreamersError,
  ]);

  // Lifecycle | Check for update | Livestreaming Contest Leaderboard Top Gifters
  useEffect(() => {
    if (
      getLivestreamingContestLeaderboardTopGiftersFetching ||
      getLivestreamingContestLeaderboardTopGiftersLoading
    ) {
    } else if (getLivestreamingContestLeaderboardTopGiftersSuccess) {
      if (getLivestreamingContestLeaderboardTopGiftersData?.status === 1) {
        setFirstLoad(false);
        setIsApiLoading(false);

        dispatch(
          updateLeaderboardPagination(
            getLivestreamingContestLeaderboardTopGiftersData?.data?.top_gifters
              ?.pagination?.next_cursor
          )
        );

        if (
          getLivestreamingContestLeaderboardTopGiftersData?.data?.top_gifters
            ?.results?.length > 0
        ) {
          dispatch(
            updateLeaderboardLists(
              getLivestreamingContestLeaderboardTopGiftersData?.data
                ?.top_gifters?.results
            )
          );
        }
      }
    } else if (getLivestreamingContestLeaderboardTopGiftersError) {
      if (
        getLivestreamingContestLeaderboardTopGiftersErrorData?.status === 401
      ) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getLivestreamingContestLeaderboardTopGiftersFetching,
    getLivestreamingContestLeaderboardTopGiftersLoading,
    getLivestreamingContestLeaderboardTopGiftersSuccess,
    getLivestreamingContestLeaderboardTopGiftersError,
  ]);

  // Lifecycle | Check for update | Livestreaming PK Leaderboard Top Streamers
  useEffect(() => {
    if (
      getLivestreamingPkLeaderboardTopStreamersFetching ||
      getLivestreamingPkLeaderboardTopStreamersLoading
    ) {
    } else if (getLivestreamingPkLeaderboardTopStreamersSuccess) {
      if (getLivestreamingPkLeaderboardTopStreamersData?.status === 1) {
        setFirstLoad(false);
        setIsApiLoading(false);

        dispatch(
          updateLeaderboardPagination(
            getLivestreamingPkLeaderboardTopStreamersData?.data?.top_streamers
              ?.pagination?.next_cursor
          )
        );

        if (
          getLivestreamingPkLeaderboardTopStreamersData?.data?.top_streamers
            ?.results?.length > 0
        ) {
          dispatch(
            updateLeaderboardLists(
              getLivestreamingPkLeaderboardTopStreamersData?.data?.top_streamers
                ?.results
            )
          );
        }
      }
    } else if (getLivestreamingPkLeaderboardTopStreamersError) {
      if (getLivestreamingPkLeaderboardTopStreamersErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getLivestreamingPkLeaderboardTopStreamersFetching,
    getLivestreamingPkLeaderboardTopStreamersLoading,
    getLivestreamingPkLeaderboardTopStreamersSuccess,
    getLivestreamingPkLeaderboardTopStreamersLoading,
  ]);

  // Lifecycle | Check for update | Livestreaming PK Leaderboard Top Gifters
  useEffect(() => {
    if (
      getLivestreamingPkLeaderboardTopGiftersFetching ||
      getLivestreamingPkLeaderboardTopGiftersLoading
    ) {
    } else if (getLivestreamingPkLeaderboardTopGiftersSuccess) {
      if (getLivestreamingPkLeaderboardTopGiftersData?.status === 1) {
        setFirstLoad(false);
        setIsApiLoading(false);

        dispatch(
          updateLeaderboardPagination(
            getLivestreamingPkLeaderboardTopGiftersData?.data?.top_gifters
              ?.pagination?.next_cursor
          )
        );

        if (
          getLivestreamingPkLeaderboardTopGiftersData?.data?.top_gifters
            ?.results?.length > 0
        ) {
          dispatch(
            updateLeaderboardLists(
              getLivestreamingPkLeaderboardTopGiftersData?.data?.top_gifters
                ?.results
            )
          );
        }
      }
    } else if (getLivestreamingPkLeaderboardTopGiftersError) {
      if (getLivestreamingPkLeaderboardTopGiftersErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getLivestreamingPkLeaderboardTopGiftersFetching,
    getLivestreamingPkLeaderboardTopGiftersLoading,
    getLivestreamingPkLeaderboardTopGiftersSuccess,
    getLivestreamingPkLeaderboardTopGiftersLoading,
  ]);

  // Lifecycle | Check for update | Gaming Top Player Leaderboard API Response
  useEffect(() => {
    if (
      getGamingTopPlayerLeaderboardFetching ||
      getGamingTopPlayerLeaderboardLoading
    ) {
    } else if (getGamingTopPlayerLeaderboardSuccess) {
      if (getGamingTopPlayerLeaderboardData?.status === 1) {
        setFirstLoad(false);
        setIsApiLoading(false);

        dispatch(
          updateLeaderboardPagination(
            getGamingTopPlayerLeaderboardData?.data?.pagination?.next_cursor
          )
        );

        if (getGamingTopPlayerLeaderboardData?.data?.results?.length > 0) {
          dispatch(
            updateLeaderboardLists(
              getGamingTopPlayerLeaderboardData?.data?.results
            )
          );
        }
      }
    } else if (getGamingTopPlayerLeaderboardError) {
    }
  }, [
    getGamingTopPlayerLeaderboardFetching,
    getGamingTopPlayerLeaderboardLoading,
    getGamingTopPlayerLeaderboardSuccess,
    getGamingTopPlayerLeaderboardError,
  ]);

  // Lifecycle | Check for update | Gaming Top Wager Leaderboard API Response
  useEffect(() => {
    if (
      getGamingTopWagerLeaderboardFetching ||
      getGamingTopWagerLeaderboardLoading
    ) {
    } else if (getGamingTopWagerLeaderboardSuccess) {
      if (getGamingTopWagerLeaderboardData?.status === 1) {
        setFirstLoad(false);
        setIsApiLoading(false);

        dispatch(
          updateLeaderboardPagination(
            getGamingTopWagerLeaderboardData?.data?.pagination?.next_cursor
          )
        );

        if (getGamingTopWagerLeaderboardData?.data?.results?.length > 0) {
          dispatch(
            updateLeaderboardLists(
              getGamingTopWagerLeaderboardData?.data?.results
            )
          );
        }
      }
    } else if (getGamingTopWagerLeaderboardError) {
    }
  }, [
    getGamingTopWagerLeaderboardFetching,
    getGamingTopWagerLeaderboardLoading,
    getGamingTopWagerLeaderboardSuccess,
    getGamingTopWagerLeaderboardError,
  ]);

  // Infinite Scroller Functions
  const infiniteScrollFunc = () => {
    if (!leaderboardPagination || isApiLoading) return;

    // Reason for this is because infiniteScrollFunc is getting called twice, so we need to make sure that it only gets called once
    setIsApiLoading(true);
    onCallApi(leaderboardPagination);
  };

  // Pull To Refresh Functions
  const pullToRefreshFunc = () => {
    dispatch(resetLeaderboardLists());
    dispatch(updateLeaderboardPagination(null));
    setFirstLoad(true);

    return new Promise((resolve) => {
      resolve(onCallApi());
    });
  };

  // Utility Functions
  const onCallApi = (paginationToken) => {
    if (type && recurrence && userType) {
      if (type === leaderboardConst.type.livestreaming) {
        switch (recurrence) {
          case leaderboardConst.tabs.events.events:
            getLivestreamingContestLeaderboardTopGifters(
              `?cat=${topGiftersLivestreamEventParam.category}&board_type=${
                topGiftersLivestreamEventParam.board_type
              }&contest_id=${topStreamersLivestreamEventParam.contest_id}${
                paginationToken ? `&pagination_token=${paginationToken}` : ""
              }`,
              false
            );
            break;
          case leaderboardConst.tabs.events.tier1:
            switch (userType) {
              case "streamer":
                getLivestreamingContestLeaderboardTopStreamers(
                  `?cat=${
                    topStreamersLivestreamEventParam.category
                  }&board_type=${
                    topStreamersLivestreamEventParam.board_type
                  }&tier=1&contest_id=${
                    topStreamersLivestreamEventParam.contest_id
                  }${
                    paginationToken
                      ? `&pagination_token=${paginationToken}`
                      : ""
                  }`,
                  false
                );
                break;
              default:
                break;
            }
            break;
          case leaderboardConst.tabs.events.tier2:
            switch (userType) {
              case "streamer":
                getLivestreamingContestLeaderboardTopStreamers(
                  `?cat=${
                    topStreamersLivestreamEventParam.category
                  }&board_type=${
                    topStreamersLivestreamEventParam.board_type
                  }&tier=2&contest_id=${
                    topStreamersLivestreamEventParam.contest_id
                  }${
                    paginationToken
                      ? `&pagination_token=${paginationToken}`
                      : ""
                  }`,
                  false
                );
                break;
              default:
                break;
            }
            break;
          case leaderboardConst.tabs.events.tier3:
            switch (userType) {
              case "streamer":
                getLivestreamingContestLeaderboardTopStreamers(
                  `?cat=${
                    topStreamersLivestreamEventParam.category
                  }&board_type=${
                    topStreamersLivestreamEventParam.board_type
                  }&tier=3&contest_id=${
                    topStreamersLivestreamEventParam.contest_id
                  }${
                    paginationToken
                      ? `&pagination_token=${paginationToken}`
                      : ""
                  }`,
                  false
                );
                break;
              default:
                break;
            }
            break;
          case leaderboardConst.tabs.events.pk:
            switch (userType) {
              case "streamer":
                if (topStreamersLivestreamEventPkParam?.contest_id) {
                  getLivestreamingPkLeaderboardTopStreamers(
                    `?board_type=${
                      topStreamersLivestreamEventPkParam.board_type
                    }&contest_id=${
                      topStreamersLivestreamEventPkParam.contest_id
                    }${
                      paginationToken
                        ? `&pagination_token=${paginationToken}`
                        : ""
                    }`,
                    false
                  );
                }

                break;
              case "gifter":
                if (topGiftersLivestreamEventPkParam?.contest_id) {
                  getLivestreamingPkLeaderboardTopGifters(
                    `?board_type=${
                      topGiftersLivestreamEventPkParam.board_type
                    }&contest_id=${
                      topGiftersLivestreamEventPkParam.contest_id
                    }${
                      paginationToken
                        ? `&pagination_token=${paginationToken}`
                        : ""
                    }`,
                    false
                  );
                }

                break;
              default:
                break;
            }
            break;
          case leaderboardConst.tabs.daily:
            switch (userType) {
              case "streamer":
                getLivestreamingLeaderboardTopStreamers(
                  `?board_type=${
                    topStreamersParam.board_type
                  }&board_period_type=${leaderboardConst.tabs.daily}${
                    paginationToken
                      ? `&pagination_token=${paginationToken}`
                      : ""
                  }`,
                  false
                );
                break;
              case "gifter":
                getLivestreamingLeaderboardTopGifters(
                  `?board_type=${
                    topGiftersParam.board_type
                  }&board_period_type=${leaderboardConst.tabs.daily}${
                    paginationToken
                      ? `&pagination_token=${paginationToken}`
                      : ""
                  }`,
                  false
                );
                break;
              default:
                break;
            }
            break;
          case leaderboardConst.tabs.weekly:
            switch (userType) {
              case "streamer":
                getLivestreamingLeaderboardTopStreamers(
                  `?board_type=${
                    topStreamersParam.board_type
                  }&board_period_type=${leaderboardConst.tabs.weekly}${
                    paginationToken
                      ? `&pagination_token=${paginationToken}`
                      : ""
                  }`,
                  false
                );
                break;
              case "gifter":
                getLivestreamingLeaderboardTopGifters(
                  `?board_type=${
                    topGiftersParam.board_type
                  }&board_period_type=${leaderboardConst.tabs.weekly}${
                    paginationToken
                      ? `&pagination_token=${paginationToken}`
                      : ""
                  }`,
                  false
                );
                break;
              default:
                break;
            }
            break;
          case leaderboardConst.tabs.monthly:
            switch (userType) {
              case "streamer":
                getLivestreamingLeaderboardTopStreamers(
                  `?board_type=${
                    topStreamersParam.board_type
                  }&board_period_type=${leaderboardConst.tabs.monthly}${
                    paginationToken
                      ? `&pagination_token=${paginationToken}`
                      : ""
                  }`,
                  false
                );
                break;
              case "gifter":
                getLivestreamingLeaderboardTopGifters(
                  `?board_type=${
                    topGiftersParam.board_type
                  }&board_period_type=${leaderboardConst.tabs.monthly}${
                    paginationToken
                      ? `&pagination_token=${paginationToken}`
                      : ""
                  }`,
                  false
                );
                break;
              default:
                break;
            }
            break;
          default:
            break;
        }
      } else if (type === leaderboardConst.type.privateCall) {
        switch (recurrence) {
          case leaderboardConst.tabs.events.events:
            switch (userType) {
              case "streamer":
                getPrivateCallContestLeaderboardTopStreamers(
                  `?board_type=${topStreamersParam.board_type}&contest_id=${
                    topStreamersPrivateCallEventParam.contest_id
                  }${
                    paginationToken
                      ? `&pagination_token=${paginationToken}`
                      : ""
                  }`,
                  false
                );
                break;
              case "gifter":
                getPrivateCallContestLeaderboardTopGifters(
                  `?board_type=${topGiftersParam.board_type}&contest_id=${
                    topGiftersPrivateCallEventParam.contest_id
                  }${
                    paginationToken
                      ? `&pagination_token=${paginationToken}`
                      : ""
                  }`,
                  false
                );
                break;
              default:
                break;
            }
            break;
          case leaderboardConst.tabs.weekly:
            switch (userType) {
              case "streamer":
                getPrivateCallLeaderboardTopStreamers(
                  `?board_type=${
                    topStreamersParam.board_type
                  }&board_period_type=${leaderboardConst.tabs.weekly}${
                    paginationToken
                      ? `&pagination_token=${paginationToken}`
                      : ""
                  }`,
                  false
                );
                break;
              case "gifter":
                getPrivateCallLeaderboardTopGifters(
                  `?board_type=${
                    topGiftersParam.board_type
                  }&board_period_type=${leaderboardConst.tabs.weekly}${
                    paginationToken
                      ? `&pagination_token=${paginationToken}`
                      : ""
                  }`,
                  false
                );
                break;
              default:
                break;
            }
            break;
          case leaderboardConst.tabs.monthly:
            switch (userType) {
              case "streamer":
                getPrivateCallLeaderboardTopStreamers(
                  `?board_type=${
                    topStreamersParam.board_type
                  }&board_period_type=${leaderboardConst.tabs.monthly}${
                    paginationToken
                      ? `&pagination_token=${paginationToken}`
                      : ""
                  }`,
                  false
                );
                break;
              case "gifter":
                getPrivateCallLeaderboardTopGifters(
                  `?board_type=${
                    topGiftersParam.board_type
                  }&board_period_type=${leaderboardConst.tabs.monthly}${
                    paginationToken
                      ? `&pagination_token=${paginationToken}`
                      : ""
                  }`,
                  false
                );
                break;
              default:
                break;
            }
            break;
          default:
            break;
        }
      } else if (type === leaderboardConst.type.casino) {
        switch (recurrence) {
          case leaderboardConst.boardPeriodType.daily:
            switch (userType) {
              case leaderboardConst.userType.player:
                getGamingTopPlayerLeaderboard(
                  `?board_type=${
                    casinoTopPlayerParam.board_type
                  }&board_period_type=${
                    leaderboardConst.boardPeriodType.daily
                  }&game_id=${casinoTopPlayerParam.game_id}${
                    paginationToken
                      ? `&pagination_token=${paginationToken}`
                      : ""
                  }`,
                  false
                );
                break;
              case leaderboardConst.userType.wager:
                getGamingTopWagerLeaderboard(
                  `?board_type=${
                    casinoTopWagerParam.board_type
                  }&board_period_type=${
                    leaderboardConst.boardPeriodType.daily
                  }&game_id=${casinoTopWagerParam.game_id}${
                    paginationToken
                      ? `&pagination_token=${paginationToken}`
                      : ""
                  }`,
                  false
                );
                break;
              default:
                break;
            }
            break;
          case leaderboardConst.boardPeriodType.weekly:
            switch (userType) {
              case leaderboardConst.userType.player:
                getGamingTopPlayerLeaderboard(
                  `?board_type=${
                    casinoTopPlayerParam.board_type
                  }&board_period_type=${
                    leaderboardConst.boardPeriodType.weekly
                  }&game_id=${casinoTopPlayerParam.game_id}${
                    paginationToken
                      ? `&pagination_token=${paginationToken}`
                      : ""
                  }`,
                  false
                );
                break;
              case leaderboardConst.userType.wager:
                getGamingTopWagerLeaderboard(
                  `?board_type=${
                    casinoTopWagerParam.board_type
                  }&board_period_type=${
                    leaderboardConst.boardPeriodType.weekly
                  }&game_id=${casinoTopWagerParam.game_id}${
                    paginationToken
                      ? `&pagination_token=${paginationToken}`
                      : ""
                  }`,
                  false
                );
                break;
              default:
                break;
            }
            break;
          case leaderboardConst.boardPeriodType.monthly:
            switch (userType) {
              case leaderboardConst.userType.player:
                getGamingTopPlayerLeaderboard(
                  `?board_type=${
                    casinoTopPlayerParam.board_type
                  }&board_period_type=${
                    leaderboardConst.boardPeriodType.monthly
                  }&game_id=${casinoTopPlayerParam.game_id}${
                    paginationToken
                      ? `&pagination_token=${paginationToken}`
                      : ""
                  }`,
                  false
                );
                break;
              case leaderboardConst.userType.wager:
                getGamingTopWagerLeaderboard(
                  `?board_type=${
                    casinoTopWagerParam.board_type
                  }&board_period_type=${
                    leaderboardConst.boardPeriodType.monthly
                  }&game_id=${casinoTopWagerParam.game_id}${
                    paginationToken
                      ? `&pagination_token=${paginationToken}`
                      : ""
                  }`,
                  false
                );
                break;
              default:
                break;
            }
            break;
          default:
            break;
        }
      }
    } else {
      onNavigate(routeConst.leaderboard.path);
    }
  };
  const capitalizeFirstLetter = (string) => {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
      return;
    }
  };
  const setTopbarTitle = () => {
    // Set Top Bar title
    const title = `Top ${
      recurrence === "events" ? capitalizeFirstLetter(recurrence) : ""
    } ${capitalizeFirstLetter(userType)}`;

    switch (title) {
      case "Top Streamer":
        dispatch(updateLeaderboardTitle(t("leaderboard.top_streamers")));
        break;
      case "Top Gifter":
        dispatch(updateLeaderboardTitle(t("leaderboard.top_gifters")));
        break;
      case "Top Events Gifter":
        dispatch(updateLeaderboardTitle(t("leaderboard.top_event_gifters")));
        break;
      default:
        break;
    }
  };
  const getBodyTitle = () => {
    if (recurrence === "tier1") {
      if (userType === leaderboardConst.userType.streamer) {
        return `${t("leaderboard.top_streamers")} - ${t("leaderboard.tier_n", {
          n: 1,
        })}`;
      }
    } else if (recurrence === "tier2") {
      if (userType === leaderboardConst.userType.streamer) {
        return `${t("leaderboard.top_streamers")} - ${t("leaderboard.tier_n", {
          n: 2,
        })}`;
      }
    } else if (recurrence === "tier3") {
      if (userType === leaderboardConst.userType.streamer) {
        return `${t("leaderboard.top_streamers")} - ${t("leaderboard.tier_n", {
          n: 3,
        })}`;
      }
    } else if (recurrence === "pk") {
      if (userType === leaderboardConst.userType.streamer) {
        return `${t("leaderboard.top_streamers")} - PK`;
      } else if (userType === leaderboardConst.userType.gifter) {
        return `${t("leaderboard.top_gifters")} - PK`;
      }
    } else {
      return t(`leaderboard.tabs.${recurrence?.toLowerCase()}`);
    }
  };

  if (leaderboardLists.length > 0) {
    topLeaderboardView = (
      <UserList type={type} userList={leaderboardLists} userType={userType} />
    );
  } else if (
    firstLoad &&
    (getLivestreamingLeaderboardTopStreamersFetching ||
      getLivestreamingLeaderboardTopStreamersLoading ||
      getLivestreamingLeaderboardTopGiftersFetching ||
      getLivestreamingLeaderboardTopGiftersLoading ||
      getPrivateCallLeaderboardTopStreamersFetching ||
      getPrivateCallLeaderboardTopStreamersLoading ||
      getPrivateCallLeaderboardTopGiftersFetching ||
      getPrivateCallLeaderboardTopGiftersLoading ||
      getPrivateCallContestLeaderboardTopStreamersFetching ||
      getPrivateCallContestLeaderboardTopStreamersLoading ||
      getPrivateCallContestLeaderboardTopGiftersFetching ||
      getPrivateCallContestLeaderboardTopGiftersLoading ||
      getLivestreamingContestLeaderboardTopStreamersFetching ||
      getLivestreamingContestLeaderboardTopStreamersLoading ||
      getLivestreamingContestLeaderboardTopGiftersFetching ||
      getLivestreamingContestLeaderboardTopGiftersLoading ||
      getLivestreamingPkLeaderboardTopStreamersFetching ||
      getLivestreamingPkLeaderboardTopStreamersLoading ||
      getLivestreamingPkLeaderboardTopGiftersFetching ||
      getLivestreamingPkLeaderboardTopGiftersLoading ||
      getGamingTopPlayerLeaderboardFetching ||
      getGamingTopPlayerLeaderboardLoading ||
      getGamingTopWagerLeaderboardFetching ||
      getGamingTopWagerLeaderboardLoading)
  ) {
    topLeaderboardView = <Spinner />;
  } else {
    topLeaderboardView = <EmptyList />;
  }

  return (
    <div id="top-leaderboard-list-page">
      <div className="max-width-container">
        <PullToRefresh
          className="pull-to-refresh-container"
          onRefresh={pullToRefreshFunc}
          refreshingContent={t("common.release")}
          pullingContent={t("common.pull_to_refresh")}
          isPullable={
            !getLivestreamingLeaderboardTopStreamersFetching &&
            !getLivestreamingLeaderboardTopStreamersLoading &&
            !getLivestreamingLeaderboardTopGiftersFetching &&
            !getLivestreamingLeaderboardTopGiftersLoading &&
            !getPrivateCallLeaderboardTopStreamersFetching &&
            !getPrivateCallLeaderboardTopStreamersLoading &&
            !getPrivateCallLeaderboardTopGiftersFetching &&
            !getPrivateCallLeaderboardTopGiftersLoading &&
            !getPrivateCallContestLeaderboardTopStreamersFetching &&
            !getPrivateCallContestLeaderboardTopStreamersLoading &&
            !getPrivateCallContestLeaderboardTopGiftersFetching &&
            !getPrivateCallContestLeaderboardTopGiftersLoading &&
            !getLivestreamingContestLeaderboardTopStreamersFetching &&
            !getLivestreamingContestLeaderboardTopStreamersLoading &&
            !getLivestreamingContestLeaderboardTopGiftersFetching &&
            !getLivestreamingContestLeaderboardTopGiftersLoading &&
            !getLivestreamingPkLeaderboardTopStreamersFetching &&
            !getLivestreamingPkLeaderboardTopStreamersLoading &&
            !getLivestreamingPkLeaderboardTopGiftersFetching &&
            !getLivestreamingPkLeaderboardTopGiftersLoading &&
            !getGamingTopPlayerLeaderboardFetching &&
            !getGamingTopPlayerLeaderboardLoading &&
            !getGamingTopWagerLeaderboardFetching &&
            !getGamingTopWagerLeaderboardLoading
          }
        >
          <div className="padding-container">
            <div className="recurrence-label">{getBodyTitle()}</div>

            <InfiniteScroll
              className="infinite-scroll-container"
              pageStart={0}
              loadMore={infiniteScrollFunc}
              hasMore={leaderboardPagination ? true : false}
              loader={<Spinner key={0} />}
              useWindow={false}
              getScrollParent={() =>
                document.getElementById("top-leaderboard-list-page")
              }
              threshold={100}
            >
              {topLeaderboardView}
            </InfiniteScroll>
          </div>
        </PullToRefresh>
      </div>

      <UnfollowUserDialog />
    </div>
  );
};

export default TopLeaderboardList;
