// General
import "./terms-of-relationship.scss";
// Static Data
import editProfileConst from "../../../../../const/editProfileConst";
import utilityConst from "../../../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateUpdateProfileDialogView } from "../../../../../redux/store/editProfileStore";
import {
  updateUpdateTorDialog,
  updateUpdateProfileFullscreenDialog,
} from "../../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Material UI
import { Divider } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import TorTag from "../../../../shared/elements/tor-tag/tor-tag";

const TermsOfRelationship = (props) => {
  const { mode = utilityConst.editProfileMode.mobile } = props;

  // Redux variables
  const tor = useSelector((state) => state.editProfile.tor);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Event Handlers | Button
  const onSelectTor = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-25-25.6",
      },
    });

    // dispatch(updateUpdateTorDialog(true));
    dispatch(
      updateUpdateProfileDialogView(editProfileConst.profileDialogView.tor)
    );
    dispatch(updateUpdateProfileFullscreenDialog(true));
  };

  if (mode === utilityConst.editProfileMode.mobile) {
    return (
      <div id="mobile-edit-profile-terms-of-relationship-subcomponent">
        <div className="terms-of-relationship-label-container">
          <div className="tor-label">
            <Trans
              i18nKey={"profile.terms_of_relationship"}
              components={{ sup: <sup className="trade-mark-label" /> }}
            />
          </div>
        </div>

        <div className="tor-list-container" onClick={onSelectTor}>
          <div className="left-container">
            {tor ? (
              tor?.map((tor, index) => (
                <TorTag
                  torName={tor?.locale}
                  description={tor?.description}
                  blur={false}
                  interactable={false}
                  readOnly={true}
                  theme={tor?.theme}
                  unselectedBackgroundColor="#f0ecec"
                  selectedBackgroundColor="#f0ecec"
                  unselectedTextColor="black"
                  selectedTextColor="black"
                  unselectedBorderColor="#d8d4d4"
                  selectedBorderColor="#d8d4d4"
                  key={index}
                />
              ))
            ) : (
              <div className="to-be-completed-text">
                {t("profile.to_be_completed")}
              </div>
            )}
          </div>

          <div className="right-container">
            <ChevronRightIcon className="right-icon" />
          </div>
        </div>
      </div>
    );
  } else if (mode === utilityConst.editProfileMode.desktop) {
    return (
      <div id="desktop-edit-profile-terms-of-relationship-subcomponent">
        <div className="terms-of-relationship-static-label-container">
          <div className="terms-of-relationship-label">
            <Trans
              i18nKey={"profile.terms_of_relationship"}
              components={{ sup: <sup className="trade-mark-label" /> }}
            />
          </div>
          <Divider className="short-divider" />
        </div>

        <div className="tor-list-container" onClick={onSelectTor}>
          {tor ? (
            tor?.map((tor, index) => (
              <TorTag
                torName={tor?.locale}
                description={tor?.description}
                blur={false}
                interactable={false}
                readOnly={true}
                unselectedBackgroundColor={"#f0ecec"}
                selectedBackgroundColor={"#f0ecec"}
                unselectedTextColor={"black"}
                selectedTextColor={"black"}
                unselectedBorderColor={"#d8d4d4"}
                selectedBorderColor={"#d8d4d4"}
                key={index}
              />
            ))
          ) : (
            <div className="to-be-completed-text">
              {t("profile.to_be_completed")}
            </div>
          )}

          {tor?.length >= 5 ? (
            <div className="tor-edit">
              <EditIcon className="edit-icon" />
            </div>
          ) : (
            <div className="tor-add">
              <AddIcon className="add-icon" />
            </div>
          )}
        </div>
      </div>
    );
  }
};

export default TermsOfRelationship;
