// General
import "./topbar.scss";
import { useEffect } from "react";
// Services
import {
  usePostFollowUserMutation,
  usePostUnfollowUserMutation,
} from "../../../../../services/data.service";
// Static Data
import routeConst from "../../../../../const/routeConst";
import userConst from "../../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateLivestreamProfileViewDialog,
  updateLivestreamReportDialog,
} from "../../../../../redux/store/dialogStore";
import {
  updateLivestreamerViewers,
  updateLivestreamerIsFollowing,
  toggleVideoPlayerMute,
  updatePlayVideoPassthrough,

  // Mini Video Player Functions
  updateIsMiniPlayer,
  updateMiniPlayerSource,
  updateMiniPlayerChannelId,
} from "../../../../../redux/store/livestreamingStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
// Custom Hooks
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../../../../shared/elements/custom-avatar/custom-avatar";
import Spinner from "../../../../shared/elements/spinner/spinner";
import LivestreamProfileViewDialog from "../../../../shared/dialog-content/livestream-profile-view-dialog/livestream-profile-view-dialog";

const Topbar = (props) => {
  const {
    state,
    showProfile = true,
    showVolume = true,
    showReport = true,
    showMiniPlayer = false,
    showBack = true,
  } = props;

  // API variables
  const [
    postFollowUser,
    {
      data: postFollowUserData,
      error: postFollowUserErrorData,
      isLoading: postFollowUserLoading,
      isSuccess: postFollowUserSuccess,
      isError: postFollowUserError,
    },
  ] = usePostFollowUserMutation();
  const [
    postUnfollowUser,
    {
      data: postUnfollowUserData,
      error: postUnfollowUserErrorData,
      isLoading: postUnfollowUserLoading,
      isSuccess: postUnfollowUserSuccess,
      isError: postUnfollowUserError,
    },
  ] = usePostUnfollowUserMutation();

  // Redux variables
  const livestreamerId = useSelector(
    (state) => state.livestreaming.livestreamerId
  );
  const livestreamerProfilePhoto = useSelector(
    (state) => state.livestreaming.livestreamerProfilePhoto
  );
  const livestreamerProfileDecorations = useSelector(
    (state) => state.livestreaming.livestreamerProfileDecorations
  );
  const livestreamerAchievements = useSelector(
    (state) => state.livestreaming.livestreamerAchievements
  );
  const livestreamerUsername = useSelector(
    (state) => state.livestreaming.livestreamerUsername
  );
  const livestreamerLevellingBadge = useSelector(
    (state) => state.livestreaming.livestreamerLevellingBadge
  );
  const livestreamerViewers = useSelector(
    (state) => state.livestreaming.livestreamerViewers
  );
  const livestreamerDiamonds = useSelector(
    (state) => state.livestreaming.livestreamerDiamonds
  );
  const livestreamerIsFollowing = useSelector(
    (state) => state.livestreaming.livestreamerIsFollowing
  );
  const videoPlayerMute = useSelector(
    (state) => state.livestreaming.videoPlayerMute
  );
  const channelId = useSelector((state) => state.livestreaming.channelId);
  const pullChannel = useSelector((state) => state.livestreaming.pullChannel);
  const dispatch = useDispatch();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Check for update | postFollowUser API Response
  useEffect(() => {
    if (postFollowUserLoading) {
    } else if (postFollowUserSuccess) {
      if (postFollowUserData?.status === 1) {
        dispatch(updateLivestreamerIsFollowing(true));
      }
    } else if (postFollowUserError) {
      if (postFollowUserErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [postFollowUserLoading, postFollowUserSuccess, postFollowUserError]);

  // Lifecycle | Check for update | postUnfollowUser API Response
  useEffect(() => {
    if (postUnfollowUserLoading) {
    } else if (postUnfollowUserSuccess) {
      if (postUnfollowUserData?.status === 1) {
        dispatch(updateLivestreamerIsFollowing(false));
      }
    } else if (postUnfollowUserError) {
      if (postUnfollowUserErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [postUnfollowUserLoading, postUnfollowUserSuccess, postUnfollowUserError]);

  // Lifecycle | Check for update | Viewer Count
  useEffect(() => {}, [livestreamerViewers]);

  // Event Handlers | Button
  const onViewProfile = () => {
    // GTM (Not Tested)
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-20-20.2-Button",
      },
    });

    dispatch(updateLivestreamProfileViewDialog(true));
  };
  const onFollowLivestreamer = () => {
    const obj = {
      user_id: livestreamerId,
    };

    if (livestreamerIsFollowing) {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-20-20.3-Button",
        },
      });

      postUnfollowUser(obj);
    } else {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-20-20.4-Button",
        },
      });

      postFollowUser(obj);
    }
  };
  const onToggleMute = () => {
    // GTM (Not Tested)
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-20-20.5-Button",
      },
    });

    dispatch(toggleVideoPlayerMute());
  };
  const onReportLivestream = () => {
    // GTM (Not Tested)
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-20-20.6-Button",
      },
    });

    dispatch(updateLivestreamReportDialog(true));
  };
  const onShowMiniPlayer = () => {
    dispatch(updateIsMiniPlayer(true));
    dispatch(updateMiniPlayerSource(getStreamPullChannel()));
    dispatch(updateMiniPlayerChannelId(channelId));

    onNavigate(routeConst.live.path);
  };
  const onBack = () => {
    // GTM (Not Tested)
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-20-20.7-Button",
      },
    });

    if (state === "livestreamer") {
      console.log("livestreamer");
    } else {
      onNavigate(routeConst.live.path);
    }
  };

  // Utility Functions
  const onGetDiamonds = (diamonds) => {
    if (diamonds >= 1000000000) {
      return `${(diamonds / 1000000000).toFixed(0)}B`;
    } else if (diamonds >= 1000000) {
      return `${(diamonds / 1000000).toFixed(0)}M`;
    } else if (diamonds >= 1000) {
      return `${(diamonds / 1000).toFixed(0)}K`;
    } else {
      return diamonds;
    }
  };
  const getStreamPullChannel = () => {
    // const hlsChannel = pullChannel?.find((channel) => channel.type === "hls");
    const hlsChannel = pullChannel?.find(
      (channel) => channel.type === "webrtc"
    );
    return hlsChannel?.url || null;
  };

  return (
    <div id="livestream-interactable-overlay-topbar-subcomponent">
      <div className="user-container">
        {showProfile && (
          <div className="user-content-container">
            <div className="profile-picture-container">
              <CustomAvatar
                className="profile-picture"
                src={livestreamerProfilePhoto}
                role={userConst.userRole.sugarbaby}
                profileBorder={
                  livestreamerProfileDecorations?.profile_border_url
                }
                onClick={onViewProfile}
              />

              {livestreamerLevellingBadge && (
                <div className="levelling-badge-container">
                  <img
                    className="levelling-badge"
                    src={livestreamerLevellingBadge}
                    alt="levelling-badge"
                  />
                </div>
              )}
            </div>

            <div className="user-details-container">
              <div className="username-container">
                {livestreamerAchievements?.chat_badges_set?.length > 0 && (
                  <div className="achievement-container">
                    {livestreamerAchievements?.chat_badges_set?.map(
                      (badge, index) => (
                        <img
                          className="livestreamer-badge"
                          src={badge?.url}
                          key={index}
                        />
                      )
                    )}
                  </div>
                )}
                <div className="username">{livestreamerUsername || "-"}</div>
              </div>

              <div className="livestream-stats-container">
                <div className="viewer-count-container">
                  <div className="viewer-count-icon-container">
                    {getIcon("liveViewerIcon", "viewer-count-icon")}
                  </div>

                  <div className="viewer-count">{livestreamerViewers || 0}</div>
                </div>

                <div className="diamond-count-container">
                  <div className="diamond-icon-container">
                    {getIcon("sbDiamondIcon", "diamond-icon")}
                  </div>

                  <div className="diamond-count">
                    {onGetDiamonds(livestreamerDiamonds) || 0}
                  </div>
                </div>
              </div>
            </div>

            <div className="follow-button-container">
              <div className="follow-button" onClick={onFollowLivestreamer}>
                {postFollowUserLoading || postUnfollowUserLoading ? (
                  <Spinner size={20} isPadding={false} />
                ) : livestreamerIsFollowing ? (
                  <CheckIcon />
                ) : (
                  <AddIcon />
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="livestream-buttons-container">
        {showVolume && (
          <div className="volume-button-container" onClick={onToggleMute}>
            {videoPlayerMute
              ? getIcon("volumeMuteIcon", "volume-button")
              : getIcon("volumeUnmuteIcon", "volume-button")}
          </div>
        )}

        {showReport && (
          <div
            className="live-report-button-container"
            onClick={onReportLivestream}
          >
            {getIcon("liveReportIcon", "live-report-button")}
          </div>
        )}

        {showMiniPlayer && (
          <div
            className="mini-player-button-container"
            onClick={onShowMiniPlayer}
          >
            <BrandingWatermarkIcon className="mini-player-button" />
          </div>
        )}

        {showBack && (
          <div className="back-button-container" onClick={onBack}>
            {getIcon("liveBackIcon", "back-button")}
          </div>
        )}
      </div>

      <LivestreamProfileViewDialog />
    </div>
  );
};

export default Topbar;
