// General
import "./release-channel-settings.scss";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateReleaseChannel } from "../../../../redux/store/appStore";
// Material UI
import { ToggleButtonGroup, ToggleButton } from "@mui/material";

const ReleaseChannelSettings = () => {
  // Redux variables
  const showHiddenSettings = useSelector(
    (state) => state.app.showHiddenSettings
  );
  const releaseChannel = useSelector((state) => state.app.releaseChannel);
  const dispatch = useDispatch();

  // Event Handlers | MUI Toggle Button
  const onReleaseChannelChange = (event, newAlignment) => {
    dispatch(updateReleaseChannel(newAlignment));
  };

  if (showHiddenSettings) {
    return (
      <div id="settings-release-channel-settings-subcomponent">
        <div className="padding-container">
          <div className="release-channel-label">Release Channel</div>

          <div className="toggle-button-container">
            <ToggleButtonGroup
              color="primary"
              value={releaseChannel}
              exclusive
              onChange={onReleaseChannelChange}
            >
              <ToggleButton value="nightly" aria-label="left aligned">
                Nightly
              </ToggleButton>
              <ToggleButton value="beta" aria-label="centered">
                Beta
              </ToggleButton>
              <ToggleButton value="stable" aria-label="right aligned">
                Stable
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
      </div>
    );
  }
};

export default ReleaseChannelSettings;
