// General
import "./registration-about-me-content-dialog.scss";
import { useState, useEffect, forwardRef } from "react";
// Services
import { useLazyGetJourneyQuestionsQuery } from "../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateAboutMeDialogDataPassthrough,
  updateAboutMeQuestions,
  updateAboutMeState,
} from "../../../../redux/store/registrationStore";
import { updateRegistrationAboutMeContentDialog } from "../../../../redux/store/dialogStore";
// Material UI
import { Dialog, Slide, useMediaQuery } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
// Components
import Spinner from "../../elements/spinner/spinner";

const RegistrationAboutMeContentDialog = () => {
  // API variables
  const [
    getJourneyQuestions,
    {
      data: getJourneyQuestionsData,
      error: getJourneyQuestionsErrorData,
      isFetching: getJourneyQuestionsFetching,
      isLoading: getJourneyQuestionsLoading,
      isSuccess: getJourneyQuestionsSuccess,
      isError: getJourneyQuestionsError,
    },
  ] = useLazyGetJourneyQuestionsQuery();

  // Redux variables
  const registrationAboutMeContentDialog = useSelector(
    (state) => state.dialog.registrationAboutMeContentDialog
  );
  const aboutMeQuestions = useSelector(
    (state) => state.registration.aboutMeQuestions
  );
  const aboutMeDialogDataPassthrough = useSelector(
    (state) => state.registration.aboutMeDialogDataPassthrough
  );
  const aboutMeState = useSelector((state) => state.registration.aboutMeState);
  const dispatch = useDispatch();

  // General variables
  const [searchInput, setSearchInput] = useState("");

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // Lifecycle | Mounted
  useEffect(() => {
    if (!registrationAboutMeContentDialog) return;

    getJourneyQuestions(null, true);
  }, [registrationAboutMeContentDialog]);

  // Event Handlers | Button
  const onSelectAnswer = (answer, index) => {
    let getAnswerIndex = aboutMeDialogDataPassthrough?.answers?.findIndex(
      (answers) => answers.id === answer.id
    );

    let aboutMeDialogDataPassthroughTemp = JSON.parse(
      JSON.stringify(aboutMeDialogDataPassthrough)
    );

    const countSelectedTrue =
      aboutMeDialogDataPassthroughTemp?.answers?.filter(
        (answer) => answer.selected === true
      ).length || 0;

    for (
      let i = 0;
      i < aboutMeDialogDataPassthroughTemp?.answers?.length;
      i++
    ) {
      if (countSelectedTrue < aboutMeDialogDataPassthroughTemp?.max) {
        aboutMeDialogDataPassthroughTemp.answers[i].selected =
          i === getAnswerIndex
            ? !aboutMeDialogDataPassthroughTemp.answers[i].selected
            : aboutMeDialogDataPassthroughTemp.answers[i].selected;
      } else if (aboutMeDialogDataPassthroughTemp?.max === 1) {
        aboutMeDialogDataPassthroughTemp.answers[i].selected =
          i === getAnswerIndex
            ? !aboutMeDialogDataPassthroughTemp.answers[i].selected
            : false;
      } else {
        aboutMeDialogDataPassthroughTemp.answers[i].selected =
          i === getAnswerIndex
            ? false
            : aboutMeDialogDataPassthroughTemp.answers[i].selected;
      }
    }

    let aboutMeQuestionsTemp = [...aboutMeQuestions];

    for (let i = 0; i < aboutMeQuestionsTemp?.length; i++) {
      if (
        aboutMeQuestionsTemp[i]?.question ===
        aboutMeDialogDataPassthroughTemp?.question
      ) {
        aboutMeQuestionsTemp[i] = aboutMeDialogDataPassthroughTemp;
        break;
      }
    }

    let aboutMeStateTemp = JSON.parse(JSON.stringify(aboutMeState));

    aboutMeStateTemp[aboutMeDialogDataPassthroughTemp.mainIndex].question =
      aboutMeDialogDataPassthroughTemp?.question;
    aboutMeStateTemp[aboutMeDialogDataPassthroughTemp.mainIndex].answers =
      aboutMeDialogDataPassthroughTemp?.answers
        ?.filter((answer) => answer.selected === true)
        .map((answer) => answer.displayLocale);
    aboutMeStateTemp[aboutMeDialogDataPassthroughTemp.mainIndex].questionIndex =
      aboutMeDialogDataPassthroughTemp?.questionIndex;

    dispatch(updateAboutMeQuestions(aboutMeQuestionsTemp));
    dispatch(
      updateAboutMeDialogDataPassthrough(aboutMeDialogDataPassthroughTemp)
    );
    dispatch(updateAboutMeState(aboutMeStateTemp));
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateRegistrationAboutMeContentDialog(false));
  };

  // Event Handlers | Input
  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  // Utility Functions
  const getFilteredAnswers = () => {
    if (aboutMeDialogDataPassthrough?.question === "hobbies") {
      let organizedHobbiesArray = [];

      aboutMeDialogDataPassthrough?.answers.forEach((hobby) => {
        let getSubCategoryIndex = organizedHobbiesArray.findIndex(
          (organizedHobby) => organizedHobby.subCategory === hobby.subCategory
        );

        if (getSubCategoryIndex === -1) {
          organizedHobbiesArray.push({
            subCategory: hobby.subCategory,
            hobbies: [hobby],
          });
        } else {
          organizedHobbiesArray[getSubCategoryIndex].hobbies.push(hobby);
        }
      });

      return organizedHobbiesArray
        .map((subcategory) => ({
          ...subcategory,
          hobbies: subcategory.hobbies.filter((hobby) =>
            hobby.displayLocale
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          ),
        }))
        .filter((subcategory) => subcategory.hobbies.length > 0);
    } else {
      return aboutMeDialogDataPassthrough?.answers?.filter((answer) =>
        answer.displayLocale.toLowerCase().includes(searchInput.toLowerCase())
      );
    }
  };
  const getHobbyCategoryName = (categoryName) => {
    const category = getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "hobbies_category"
    )?.selections;

    const selectedCategory = category?.find(
      (item) => item?.tag === categoryName
    );

    return selectedCategory?.display_locale;
  };

  return (
    <Dialog
      className={
        isMobile
          ? "custom-bottom-sheet-no-radius-fullscreen-dialog" //"custom-bottom-sheet-dialog registration-about-me-content-overflow"
          : "custom-radius10-dialog custom-dialog-height-inherit"
      }
      fullScreen={isMobile}
      open={registrationAboutMeContentDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      {getJourneyQuestionsFetching || getJourneyQuestionsLoading ? (
        <Spinner />
      ) : (
        <div id="registration-about-me-content-dialog">
          <div className="dialog-header">
            <div className="left-container" onClick={onCloseDialog}>
              <ChevronLeftIcon className="back-icon" />
            </div>
            <div className="middle-container"></div>
            <div className="right-container"></div>
          </div>

          <div className="padding-container">
            <div className="search-text-field-container">
              <SearchRoundedIcon className="search-icon" />
              <input
                className="search-text-field"
                value={searchInput}
                placeholder="Search"
                onChange={handleSearchInputChange}
              />
            </div>

            <div className="about-me-content-container">
              {getFilteredAnswers()?.map((answer, index) => {
                if (aboutMeDialogDataPassthrough?.question === "hobbies") {
                  return (
                    <div className="sub-category-about-me-content" key={index}>
                      <div className="sub-category-about-me-label">
                        {getHobbyCategoryName(answer?.subCategory)}
                      </div>

                      <div className="sub-category-about-me-content-container">
                        {answer?.hobbies?.map((hobby, hobbyIndex) => {
                          return (
                            <div
                              className="about-me-content about-me-content-padding-left"
                              onClick={() => onSelectAnswer(hobby, index)}
                              key={hobbyIndex}
                            >
                              <div
                                className={`about-me-label ${
                                  hobby?.selected
                                    ? "selected-about-me-label"
                                    : ""
                                }`}
                              >
                                {hobby?.displayLocale}
                              </div>

                              <div className="about-me-tick-container">
                                {hobby?.selected && (
                                  <DoneRoundedIcon className="about-me-tick" />
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      className="about-me-content"
                      onClick={() => onSelectAnswer(answer, index)}
                      key={index}
                    >
                      <div
                        className={`about-me-label ${
                          answer?.selected ? "selected-about-me-label" : ""
                        }`}
                      >
                        {answer?.displayLocale}
                      </div>

                      <div className="about-me-tick-container">
                        {answer?.selected && (
                          <DoneRoundedIcon className="about-me-tick" />
                        )}
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default RegistrationAboutMeContentDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
