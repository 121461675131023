// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, isSupported } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAYboAsnFjzk9FBEciGxkiyhp27Lvwrpgo",
  authDomain: "thesugarbook-41017.firebaseapp.com",
  databaseURL: "https://thesugarbook-41017.firebaseio.com",
  projectId: "thesugarbook-41017",
  storageBucket: "thesugarbook-41017.appspot.com",
  messagingSenderId: "213195333898",
  appId: "1:213195333898:web:742ec07cd6d7f5fdf3daf3",
  measurementId: "G-5J0DTRRDKB",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

let messaging;
let onMessageListener;

// Initialize Firebase Cloud Messaging and get a reference to the service
if (isSupported() && "serviceWorker" in navigator) {
  messaging = getMessaging(app);

  onMessageListener = () => {
    return new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    });
  };
} else {
  console.log("Service Worker is not supported in this browser");
}

// Export messaging and onMessageListener
export { messaging, onMessageListener };
