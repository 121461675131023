// General
import "./in-app-notification.scss";
import { useEffect } from "react";
// Services
import { useLazyGetProfileQuery } from "../../../../../services/data.service";
// Static Data
import routeConst from "../../../../../const/routeConst";
import userConst from "../../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateInterestTab } from "../../../../../redux/store/interestStore";
import { updateShouldCallApi } from "../../../../../redux/store/userCardStore";
// Custom Hooks
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import CustomAvatar from "../../custom-avatar/custom-avatar";

const InAppNotification = (props) => {
  const {
    type,
    profilePhoto,
    profileDecorations,
    username,
    userId,
    userIdInt,
    relatedId,
  } = props;

  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // Redux variables
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  // Event Handlers | Button
  const onRedirectToConversation = () => {
    switch (type) {
      case "view_profile":
      case "favorite_user":
      case "private_photo_approve":
      // dispatch(updateShouldCallApi(true));
      // onNavigate(`${routeConst.profile.view.clearPath}${userId}`);
      // break;
      case "private_photo_request":
      // dispatch(updateInterestTab(4));
      // onNavigate(routeConst.interest.path);
      // break;
      case "live":
        // onNavigate(`${routeConst.live.liveId.clearPath}${relatedId}`);
        // break;
        dispatch(updateInterestTab(0));
        onNavigate(routeConst.interest.path);
      default:
        break;
    }
  };

  // Utility Functions
  const getInAppNotificationCopywriting = () => {
    switch (type) {
      case "view_profile":
        return `viewed your profile`;
      case "favorite_user":
        return `favorited you`;
      case "private_photo_request":
        return `request access to view your private photo`;
      case "private_photo_approve":
        return `approved your private photo request`;
      case "text_message":
        return `send you a new text message`;
      case "live":
        return `went live`;
      default:
        break;
    }
  };

  return (
    <div id="in-app-nofitication-element" onClick={onRedirectToConversation}>
      <div className="padding-container">
        <div className="profile-picture-container">
          <CustomAvatar
            src={profilePhoto}
            isBlur={
              isDaddy &&
              getProfileData?.data?.membership_type ===
                userConst.membershipType.free
            }
            isLock={
              false &&
              isDaddy &&
              getProfileData?.data?.membership_type ===
                userConst.membershipType.free
            }
            profileBorder={profileDecorations?.profile_border_url}
          />
        </div>

        <div className="message-body-container">
          <div className="username">{username}</div>
          <div className="message-body">
            {getInAppNotificationCopywriting()}
          </div>
        </div>

        <div className="now-container">now</div>
      </div>
    </div>
  );
};

export default InAppNotification;
