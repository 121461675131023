// General
import "./edit-relationship.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetJourneyQuestionsQuery,
  useUpdateEditUserProfileMutation,
} from "../../../../../services/data.service";
// Static Data
import utilityConst from "../../../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateRelationshipStatus,
  updateUpdateProfileFullscreenTab,
  updateIsUnsavedChanges,
} from "../../../../../redux/store/editProfileStore";
import {
  updateProfileCompletionPercentage,
  updateProfileRequirementPopup,
} from "../../../../../redux/store/profileStore";
import { updateUpdateProfileFullscreenDialog } from "../../../../../redux/store/dialogStore";
// Material UI
import { useMediaQuery } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../../elements/spinner/spinner";

const EditRelationship = (props) => {
  const { addPaddingTop = false } = props;

  // API variables
  const [
    getJourneyQuestions,
    {
      data: getJourneyQuestionsData,
      error: getJourneyQuestionsErrorData,
      isFetching: getJourneyQuestionsFetching,
      isLoading: getJourneyQuestionsLoading,
      isSuccess: getJourneyQuestionsSuccess,
      isError: getJourneyQuestionsError,
    },
  ] = useLazyGetJourneyQuestionsQuery();
  const [
    updateProfile,
    {
      data: updateProfileData,
      error: updateProfileErrorData,
      isLoading: updateProfileLoading,
      isSuccess: updateProfileSuccess,
      isError: updateProfileError,
    },
  ] = useUpdateEditUserProfileMutation();

  // General variables
  const [localRelationshipStatus, setLocalRelationshipStatus] = useState(null);

  // Redux variables
  const relationshipStatus = useSelector(
    (state) => state.editProfile.relationshipStatus
  );
  const profileCompletionState = useSelector(
    (state) => state.editProfile.profileCompletionState
  );
  const updateProfileFullscreenTab = useSelector(
    (state) => state.editProfile.updateProfileFullscreenTab
  );
  const isDaddy = useSelector((state) => state.public.isDaddy);
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    setLocalRelationshipStatus(relationshipStatus);

    getJourneyQuestions(null, true);
  }, []);

  // Lifecycle | Check for update | updateProfile API Response
  useEffect(() => {
    if (updateProfileLoading) {
    } else if (updateProfileSuccess) {
      dispatch(
        updateRelationshipStatus(
          updateProfileData?.data?.user?.relationship?.relationship_status
        )
      );
      dispatch(
        updateProfileCompletionPercentage(
          updateProfileData?.data?.user?.profile_progress?.summary?.progress
        )
      );
      dispatch(
        updateProfileRequirementPopup(
          updateProfileData?.data?.user?.profile_progress?.summary?.requirement
        )
      );
      dispatch(updateIsUnsavedChanges(false));

      if (profileCompletionState) {
        const position = updateProfileData?.data?.user?.occupation?.position;
        const industry = updateProfileData?.data?.user?.occupation?.industry;
        const education = updateProfileData?.data?.user?.occupation?.education;
        const relationshipStatus =
          updateProfileData?.data?.user?.relationship?.relationship_status;
        const children = updateProfileData?.data?.user?.relationship?.children;
        const smoking = updateProfileData?.data?.user?.habit?.smoking;
        const drinking = updateProfileData?.data?.user?.habit?.drinking;
        const alcoholPreference = updateProfileData?.data?.user?.habit?.alcohol;

        if (isDaddy && (!position || !industry)) {
          dispatch(updateUpdateProfileFullscreenTab(0));
        } else if (!isDaddy && !position) {
          dispatch(updateUpdateProfileFullscreenTab(0));
        } else if (!education) {
          dispatch(updateUpdateProfileFullscreenTab(1));
        } else if (!relationshipStatus) {
          dispatch(updateUpdateProfileFullscreenTab(2));
        } else if (!children && children !== 0) {
          dispatch(updateUpdateProfileFullscreenTab(3));
        } else if (!smoking) {
          dispatch(updateUpdateProfileFullscreenTab(4));
        } else if (!drinking) {
          dispatch(updateUpdateProfileFullscreenTab(5));
        } else if (!alcoholPreference) {
          dispatch(updateUpdateProfileFullscreenTab(6));
        } else {
          dispatch(updateUpdateProfileFullscreenDialog(false));
        }
      } else {
        dispatch(updateUpdateProfileFullscreenDialog(false));
      }
    } else if (updateProfileError) {
    }
  }, [updateProfileLoading, updateProfileSuccess, updateProfileError]);

  // Lifecycle | Check for update | localRelationshipStatus
  useEffect(() => {
    if (localRelationshipStatus?.id !== relationshipStatus?.id) {
      dispatch(updateIsUnsavedChanges(true));
    } else {
      dispatch(updateIsUnsavedChanges(false));
    }
  }, [localRelationshipStatus]);

  // Event Handlers | Button
  const onSelectRelationship = (relationship) => {
    setLocalRelationshipStatus(relationship);
  };
  const onSaveRelationship = () => {
    if (!isFormValid()) return;

    const obj = {
      profile: {
        relationship: localRelationshipStatus?.id,
      },
    };
    updateProfile(obj);
  };

  // Utility Functions
  const isFormValid = () => {
    return localRelationshipStatus && !updateProfileLoading;
  };
  const getRelationshipData = () => {
    return getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "relationship"
    )?.selections;
  };

  return (
    <div id="relationship-subcomponent">
      <div className="padding-container">
        <div
          className={`top-container ${
            addPaddingTop ? "add-edit-padding-top" : ""
          }`}
        >
          <div className="relationship-icon-container">
            {getIcon("relationshipIcon", "relationship-icon")}
          </div>

          <div className="relationship-title">
            {t("profile.whats_your_relationship_status")}
          </div>

          {getJourneyQuestionsFetching || getJourneyQuestionsLoading ? (
            <Spinner />
          ) : (
            <div
              className={`relationship-body-container ${
                isMobile &&
                getRelationshipData()?.length >
                  utilityConst.registrationItemFlexAmount
                  ? "flex-start"
                  : "justify-center"
              }`}
            >
              {getRelationshipData()?.map((item, index) => (
                <div
                  className={`relationship-item ${
                    getRelationshipData()?.length <=
                    utilityConst.registrationItemFlexAmount
                      ? "partial-width"
                      : isMobile &&
                        getRelationshipData()?.length >
                          utilityConst.registrationItemFlexAmount
                      ? "fit-content"
                      : "fix-width"
                  } ${
                    item?.id === localRelationshipStatus?.id ? "selected" : ""
                  }`}
                  onClick={() => onSelectRelationship(item)}
                  key={index}
                >
                  {item?.display_locale}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="bottom-container">
        <div
          className={`save-button ${isFormValid() ? "" : "disabled-button"}`}
          onClick={onSaveRelationship}
        >
          {updateProfileLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("common.save")
          )}
        </div>
      </div>
    </div>
  );
};

export default EditRelationship;
