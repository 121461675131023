// General
import "./upgrade-payment.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetProfileQuery,
  useLazyGetPwaPaymentsQuery,
} from "../../../services/data.service";
// Static Data
import routeConst from "../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateCCBillLink,
  resetCreditCardDetails,
} from "../../../redux/store/paymentStore";
import SHA256 from "crypto-js/sha256";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
import AssetManager from "../../utility/manager/asset-manager/asset-manager";
import IconManager from "../../utility/manager/icon-manager/icon-manager";
import useSecurionPaymentFlowWithoutCard from "../../utility/custom-hooks/useSecurionPaymentFlowWithoutCard-hook";
// Components
import Spinner from "../../shared/elements/spinner/spinner";
import CardDetails from "../../shared/payment/card-details/card-details";

const UpgradePayment = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    getPwaPayments,
    {
      data: getPwaPaymentsData,
      error: getPwaPaymentsErrorData,
      isFetching: getPwaPaymentsFetching,
      isLoading: getPwaPaymentsLoading,
      isSuccess: getPwaPaymentsSuccess,
      isError: getPwaPaymentsError,
    },
  ] = useLazyGetPwaPaymentsQuery();

  // General variables
  const [selectedPaymentProvider, setSelectedPaymentProvider] = useState(null);
  const [tnc, setTnc] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Render variables
  const [paymentMethodsView, setPaymentMethodsView] = useState([]);

  // Redux variables
  const selectedUpgradePackage = useSelector(
    (state) => state.upgrade.selectedUpgradePackage
  );
  const discretionDescription = useSelector(
    (state) => state.upgrade.discretionDescription
  );
  const creditCardNoteDescription = useSelector(
    (state) => state.upgrade.creditCardNoteDescription
  );
  const isFormValid = useSelector((state) => state.payment.isFormValid);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const securionPaymentFlowWithoutCard = useSecurionPaymentFlowWithoutCard();
  const onNavigate = useCustomNavigate();
  const getAsset = AssetManager();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!isDetailValid()) {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-55",
        },
      });

      onNavigate(routeConst.upgrade.path);
    } else {
      dispatch(resetCreditCardDetails());
      getProfile(null, true);
      getPwaPayments(null, true);
    }
  }, []);

  // Lifecycle | Check for update | get Pwa Payment API Response
  useEffect(() => {
    if (getPwaPaymentsFetching || getPwaPaymentsLoading) {
    } else if (getPwaPaymentsSuccess) {
      if (getPwaPaymentsData?.status === 0) {
        for (
          let i = 0;
          i < getPwaPaymentsData?.data?.payment_methods?.length;
          i++
        ) {
          if (
            getPwaPaymentsData?.data?.payment_methods[i].is_selected === true
          ) {
            setSelectedPaymentProvider(
              getPwaPaymentsData?.data?.payment_methods[i].provider
            );
            break;
          }
        }
      }
    } else if (getPwaPaymentsError) {
    }
  }, [
    getPwaPaymentsFetching,
    getPwaPaymentsLoading,
    getPwaPaymentsSuccess,
    getPwaPaymentsError,
  ]);

  // Lifecycle | Check for update | selectedPaymentProvider
  useEffect(() => {
    if (!selectedPaymentProvider) return;

    let iconsList;

    for (
      let i = 0;
      i < getPwaPaymentsData?.data?.payment_methods?.length;
      i++
    ) {
      if (
        selectedPaymentProvider ===
        getPwaPaymentsData?.data?.payment_methods[i].provider
      ) {
        iconsList = getPwaPaymentsData?.data?.payment_methods[i].payment_icons;
      }
    }

    setPaymentMethodsView(
      iconsList?.map((icon, index) => {
        switch (icon) {
          case "visa":
            return (
              <img
                className="visa-logo"
                src={getAsset("visaLogo")}
                key={index}
              />
            );
          case "mastercard":
            return (
              <img
                className="mastercard-logo"
                src={getAsset("mastercardLogo")}
                key={index}
              />
            );
          case "electron":
            return (
              <img
                className="electron-logo"
                src={getAsset("visaElectronLogo")}
                key={index}
              />
            );
          case "maestro":
            return (
              <img
                className="maestro-logo"
                src={getAsset("maestroLogo")}
                key={index}
              />
            );
          case "discover":
            return (
              <img
                className="discover-logo"
                src={getAsset("discoverLogo")}
                key={index}
              />
            );
          case "jcb":
            return (
              <img className="jcb-logo" src={getAsset("jcbLogo")} key={index} />
            );
          case "diners":
            return (
              <img
                className="diners-logo"
                src={getAsset("dinersLogo")}
                key={index}
              />
            );
          case "bleue":
            return (
              <img
                className="bleue-logo"
                src={getAsset("bleueLogo")}
                key={index}
              />
            );
          default:
            break;
        }
      })
    );
  }, [selectedPaymentProvider]);

  // Event Handlers | Button
  const onSelectPaymentProvider = (provider) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-55-55.1-Button",
      },
    });

    setSelectedPaymentProvider(provider?.provider);

    if (provider?.provider === "ccbill") {
      dispatch(
        updateCCBillLink(
          `${
            provider?.payment_redirection[selectedUpgradePackage?.product_code]
              ?.link
          }&u=${SHA256(getProfileData?.data?.id_int.toString())}`
        )
      );
    }
  };
  const onPayNow = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-55-55.2-Button",
      },
    });

    if (isLoading) return;

    switch (selectedPaymentProvider) {
      case "securionpay":
        if (!isFormValid || !tnc) return;

        setIsLoading(true);

        setTimeout(() => {
          setIsLoading(false);
        }, 5000);

        securionPaymentFlowWithoutCard();
        break;
      case "ccbill":
        if (!tnc) return;

        setIsLoading(true);

        setTimeout(() => {
          setIsLoading(false);
        }, 5000);

        onNavigate(routeConst.upgrade.redirect.path);
        break;
      default:
        break;
    }
  };

  // Event Handlers | MUI Checkbox
  const onCheckboxChange = (event) => {
    setTnc(event.target.checked);
  };

  // Utility Functions
  const isDetailValid = () => {
    if (
      selectedUpgradePackage ||
      discretionDescription ||
      creditCardNoteDescription
    ) {
      return true;
    } else {
      return false;
    }
  };

  // Render Functions
  if (getPwaPaymentsFetching || getPwaPaymentsLoading) {
    return <Spinner />;
  } else if (getPwaPaymentsSuccess) {
    return (
      <div id="upgrade-payment-page">
        <div className="max-width-container">
          <div className="padding-container">
            <div className="top-container">
              <div className="payment-method-label">
                {t("upgrade.payment_method")}
              </div>

              <div className="trusted-image-container">
                {getIcon("discreteBadge", "discrete-badge")}
                {getIcon("securityBadge", "security-badge")}
                {getIcon("sslBadge", "ssl-badge")}
              </div>
            </div>

            <div className="payment-provider-container">
              {getPwaPaymentsData?.data?.payment_methods?.map(
                (provider, index) => {
                  let shouldShow = false;
                  let productCode;

                  if (provider?.provider === "ccbill") {
                    productCode = Object.keys(provider?.payment_redirection);

                    for (let i = 0; i < productCode?.length; i++) {
                      if (
                        selectedUpgradePackage?.product_code == productCode[i]
                      ) {
                        shouldShow = true;
                        break;
                      }
                    }
                  }

                  if (provider?.provider === "securionpay" || shouldShow) {
                    return (
                      <div
                        className="secure-payment-container"
                        onClick={() => onSelectPaymentProvider(provider)}
                        key={index}
                      >
                        <div className="lock-icon-container">
                          {provider?.icon === "lock"
                            ? getIcon("paymentSecureLockIcon", "lock-icon")
                            : provider?.icon === "shield"
                            ? getIcon("paymentSecureShieldIcon", "lock-icon")
                            : getIcon("paymentSecureLockIcon", "lock-icon")}
                        </div>

                        <div className="secure-payment-description">
                          <div className="secure-payment-label">
                            {provider?.title}
                          </div>
                          <div className="secure-payment-body">
                            {provider?.paragraph}
                          </div>
                        </div>

                        <div className="radio-button-container">
                          <div className="radio-button-outer">
                            <div
                              className={
                                selectedPaymentProvider === provider?.provider
                                  ? "radio-button-inner"
                                  : ""
                              }
                            ></div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                }
              )}
            </div>

            {selectedPaymentProvider === "securionpay" && <CardDetails />}

            <div className="we-accept-label">{t("purchase.we_accept")}:</div>

            <div className="credit-card-company-image-container">
              {paymentMethodsView}
            </div>

            <div className="amex-notice">
              {t("upgrade.payment_no_amex_in_secure_payment")}
            </div>

            <div className="agreement-checkbox-container">
              <FormGroup className="checkbox">
                <FormControlLabel
                  control={
                    <Checkbox
                      className="custom-checkbox"
                      onChange={onCheckboxChange}
                    />
                  }
                  label={t("upgrade.payment_agree")}
                />
              </FormGroup>
            </div>

            <div className="billed-and-tax-info-container">
              {selectedUpgradePackage && (
                <div className="billed-today-info">
                  <Trans
                    i18nKey={"upgrade.billed_desc"}
                    values={{
                      amount: `${selectedUpgradePackage?.symbol}${selectedUpgradePackage?.display_billed_today}`,
                    }}
                    components={{ span: <span className="highlight-amount" /> }}
                  />
                </div>
              )}

              {selectedUpgradePackage?.tax_percentage && (
                <div className="tax-container">
                  <div className="tax-label">
                    <Trans
                      i18nKey={"upgrade.includes_n_tax"}
                      values={{
                        taxValue: selectedUpgradePackage?.tax_percentage,
                        taxType: selectedUpgradePackage?.tax_label,
                      }}
                    />
                  </div>
                </div>
              )}
            </div>

            {selectedUpgradePackage && (
              <div className="billed-info">
                <Trans
                  i18nKey={"upgrade.billed_desc_2"}
                  values={{
                    amount: `${selectedUpgradePackage?.symbol}${selectedUpgradePackage?.renews_amount}`,
                    duration: selectedUpgradePackage?.period_length * 30,
                    renewDate: selectedUpgradePackage?.renews_at,
                  }}
                  components={{
                    span: <span />,
                    doublesize: <span className="doublesize" />,
                  }}
                />
              </div>
            )}

            <div className="cancellation-info">
              {t("upgrade.billed_cancel")}
            </div>
          </div>

          <div
            className={`pay-button ${
              (selectedPaymentProvider === "securionpay" &&
                isFormValid &&
                tnc) ||
              (selectedPaymentProvider === "ccbill" && tnc)
                ? ""
                : "disabled-button"
            }`}
            onClick={onPayNow}
          >
            {isLoading ? (
              <Spinner size={25} isPadding={false} color={"white-spinner"} />
            ) : (
              t("purchase.pay_now")
            )}
          </div>
        </div>
      </div>
    );
  } else if (getPwaPaymentsError) {
  }
};

export default UpgradePayment;
