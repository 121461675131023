// General
import "./edit-profile-unsaved-changes-dialog.scss";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateIsUnsavedChanges } from "../../../../redux/store/editProfileStore";
import {
  updateEditProfileUnsavedChangesDialog,
  updateUpdateProfileFullscreenDialog,
} from "../../../../redux/store/dialogStore";
// Material UI
import { Dialog } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";

const EditProfileUnsavedChangesDialog = () => {
  // Redux variables
  const editProfileUnsavedChangesDialog = useSelector(
    (state) => state.dialog.editProfileUnsavedChangesDialog
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateEditProfileUnsavedChangesDialog(false));
  };
  const onDiscardChanges = () => {
    dispatch(updateIsUnsavedChanges(false));
    dispatch(updateUpdateProfileFullscreenDialog(false));
    onCloseDialog();
  };

  return (
    <Dialog
      className="custom-radius25-dialog"
      open={editProfileUnsavedChangesDialog}
      onClose={onCloseDialog}
    >
      <div id="edit-profile-unsaved-changes-dialog">
        <div className="padding-container">
          <div className="top-container">
            <div className="unsaved-changes-title">
              {t("common.unsaved_dialog_title")}
            </div>

            <div className="unsaved-changes-description">
              {t("common.unsaved_dialog_desc")}
            </div>
          </div>

          <div className="button-container">
            <div className="bring-me-back-button" onClick={onCloseDialog}>
              {t("common.bring_me_back")}
            </div>
            <div className="discard-changes-button" onClick={onDiscardChanges}>
              {t("common.discard_changes")}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default EditProfileUnsavedChangesDialog;
