// General
import "./incomplete-profile.scss";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
// Services
import {
  useLazyGetProfileQuery,
  useLazyGetInboxSingleConversationQuery,
} from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
import userConst from "../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updatePaymentTag } from "../../../../redux/store/paymentStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Sentry
import * as Sentry from "@sentry/react";
// Material UI
import { useMediaQuery } from "@mui/material";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";

const IncompleteProfile = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    getInboxSingleConversation,
    {
      data: getInboxSingleConversationData,
      error: getInboxSingleConversationErrorData,
      isFetching: getInboxSingleConversationFetching,
      isLoading: getInboxSingleConversationLoading,
      isSuccess: getInboxSingleConversationSuccess,
      isError: getInboxSingleConversationError,
    },
  ] = useLazyGetInboxSingleConversationQuery();

  // Redux variables
  const conversationId = useSelector((state) => state.inbox.conversationId);
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 850px)");

  // Params variables
  const { id } = useParams();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-8.1-Incomplete-Profile-Message",
      },
    });

    getProfile(null, true);
    getInboxSingleConversation(`/${id ?? conversationId}`);
  }, []);

  // Lifecycle | Check for update | Get Profile API Response
  useEffect(() => {
    if (getProfileError) {
      // Send to Sentry
      Sentry.captureMessage(
        "Incomplete Profile /me Error: " + JSON.stringify(getProfileErrorData)
      );
    }
  }, [getProfileError]);

  // Event Handlers | Button
  const onNavigateToUpgrade = () => {
    dispatch(updatePaymentTag("pwa-chat-input-conversation-blocker"));
    onNavigate(routeConst.upgrade.path);
  };

  // Render Functions | API Response
  if (
    getProfileFetching ||
    getProfileLoading ||
    getInboxSingleConversationFetching ||
    getInboxSingleConversationLoading
  ) {
  } else if (getProfileSuccess && getInboxSingleConversationSuccess) {
    if (
      getProfileData?.status === 0 &&
      getInboxSingleConversationData?.status === 0
    ) {
      if (
        getProfileData?.data?.membership_type ===
          userConst.membershipType.free &&
        getInboxSingleConversationData?.data?.conversation_is_blocked
      ) {
        return (
          <div
            id="chat-conversation-incomplete-profile-subcomponent"
            className={!isMobile ? "rounded-corner" : ""}
          >
            {/* Rejected Profile */}
            {getInboxSingleConversationData?.data?.conversation_is_blocked ===
              "rejected-profile" && (
              <div className="sb-padding-container">
                <div className="sbf-description">
                  {isDaddy
                    ? t("inbox.const_msg.rejected-profile")
                    : t("inbox.const_msg_sbf.rejected-profile")}
                </div>

                {isDaddy ? (
                  <div className="upgrade-button" onClick={onNavigateToUpgrade}>
                    {t("profile.upgrade_now")}
                  </div>
                ) : (
                  <div
                    className="edit-profile-button"
                    onClick={() => onNavigate(routeConst.profile.edit.path)}
                  >
                    {t("profile.edit_profile")}
                  </div>
                )}
              </div>
            )}

            {/* Moderating */}
            {getInboxSingleConversationData?.data?.conversation_is_blocked ===
              "moderating" && (
              <div className="sb-padding-container">
                <div className="sbf-small-description">
                  <Trans
                    className="subheader"
                    i18nKey={
                      isDaddy
                        ? t("inbox.const_msg.moderating")
                        : t("inbox.const_msg_sbf.moderating")
                    }
                    components={{ br: <br /> }}
                  />
                </div>

                <div className="upgrade-button" onClick={onNavigateToUpgrade}>
                  {t("profile.upgrade_now")}
                </div>
              </div>
            )}

            {/* Complete Profile */}
            {getInboxSingleConversationData?.data?.conversation_is_blocked ===
              "complete-profile" && (
              <div className="sb-padding-container">
                <div className="sbf-description">
                  <Trans
                    className="subheader"
                    i18nKey={
                      isDaddy
                        ? t("inbox.const_msg.complete-profile")
                        : t("inbox.const_msg_sbf.complete-profile")
                    }
                    components={{ br: <br /> }}
                  />
                </div>

                {isDaddy ? (
                  <div className="upgrade-button" onClick={onNavigateToUpgrade}>
                    {t("profile.upgrade_now")}
                  </div>
                ) : (
                  <div
                    className="edit-profile-button"
                    onClick={() => onNavigate(routeConst.profile.edit.path)}
                  >
                    {t("profile.edit_profile")}
                  </div>
                )}
              </div>
            )}

            {/* Upgrade Now */}
            {getInboxSingleConversationData?.data?.conversation_is_blocked ===
              "upgrade-now" && (
              <div className="sb-padding-container">
                <div className="sbf-description">
                  <Trans
                    className="subheader"
                    i18nKey={
                      isDaddy
                        ? t("inbox.const_msg.upgrade-now")
                        : t("inbox.const_msg_sbf.upgrade-now")
                    }
                    components={{ br: <br /> }}
                  />
                </div>

                <div className="upgrade-button" onClick={onNavigateToUpgrade}>
                  {t("profile.upgrade_now")}
                </div>
              </div>
            )}
          </div>
        );
      }
    }
  } else if (getProfileError || getInboxSingleConversationError) {
    if (
      getProfileErrorData?.status === 401 ||
      getInboxSingleConversationErrorData?.status === 401
    ) {
      onNavigate(routeConst.logout.path);
    }
  }
};

export default IncompleteProfile;
