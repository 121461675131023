// General
import "./private-standby.scss";
import { useEffect, useRef } from "react";
// Services
import {
  useLazyGetProfileQuery,
  useLazyGetPrivateCallStandbyStartQuery,
  useLazyGetPrivateCallStandbyEndQuery,
  useLazyGetPrivateCallStandbyDetailsQuery,
  useLazyGetPrivateCallTipItemsQuery,
  useLazyGetPrivateCallStandbyKeepAliveQuery,
  useLazyGetPrivateCallRequestDetailsQuery,
  useLazyGetPrivateCallRoomKeepAliveQuery,
} from "../../../services/data.service";
import { sessionService } from "../../../services/session.service";
// Static Data
import routeConst from "../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  // General Functions
  updateIsPrivateStandbyMode,
  startPrivateCallStandbyTimer,
  resetPrivateCallStandbyTimer,
  startCallDurationRemainingTimer,
  updateIsHunterMode,
  updateExitHunterMode,
  updateShowVideoCallJoiningOverlay,
  updateShowVideoCallEndingOverlay,
  updateStandbyId,
  updateIsCaller,
  updateCallDurationRemaining,
  updateEndVideoCall,

  // TRTC Functions
  updateCallStatus,

  // Chat Functions
  clearVideoCallChatMessages,

  // Interval Functions
  updateStandbyTimerInterval,
  updateStandbyKeepAliveInterval,
  updateRoomKeepAliveInterval,
  updateCallDurationRemainingInterval,
  updateIsStandbyKeepAliveIntervalPaused,
  updateIsRoomKeepAliveIntervalPaused,
  clearStandbyTimerInterval,
  clearStandbyKeepAliveInterval,
  clearRoomKeepAliveInterval,

  // Diamonds Functions
  updateTotalDiamonds,
  updateCallRequestDiamonds,
  updateCurrentCallRequestDiamonds,
  updateClaimedDiamonds,
  updateUnclaimedDiamonds,

  // Calls Functions
  updateCallsAccepted,

  // Caller Functions
  updateCallerId,
  updateCallerUsername,
  updateCallerProfilePhoto,

  // Callee End Video Call Summary Functions
  updateCalleeEndVideoCallSummaryData,

  // Utility Functions
  updateCallStandbyKeepAlivePassthrough,
  updateCallRoomKeepAlivePassthrough,
  updateForceCallRoomKeepAlivePassthrough,
  updateCalleeId,
  updateIsLocalCameraEnabled,
} from "../../../redux/store/privateCallStore";
import {
  updateOnboardingVideoCallRoomDialog,
  updateCalleeCurrentSessionSummaryDialog,
} from "../../../redux/store/dialogStore";
import { updateWarningToast } from "../../../redux/store/toastStore";
import {
  updateVideoCallPusherSubscribe,
  updateVideoCallPusherUnsubscribe,
} from "../../../redux/store/pusherStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Custom Hooks
import useIsMounted from "../../utility/custom-hooks/useIsMounted-hook";
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
import useEndPrivateCall from "../../utility/custom-hooks/useEndPrivateCall-hook";
// Components
import CameraFeeds from "./camera-feeds/camera-feeds";
import GiftAnimationOverlay from "../../shared/elements/gift-animation-overlay/gift-animation-overlay";
import ProfilePhotoOverlay from "../../shared/video-call/profile-photo-overlay/profile-photo-overlay";
import InteractableOverlay from "../../shared/video-call/interactable-overlay/interactable-overlay";
import StandbyInfoOverlay from "./standby-info-overlay/standby-info-overlay";
import JoiningCallOverlay from "../../shared/video-call/joining-call-overlay/joining-call-overlay";
import InitializingStreamOverlay from "../../shared/video-call/initializing-stream-overlay/initializing-stream-overlay";
import EndingCallOverlay from "../../shared/video-call/ending-call-overlay/ending-call-overlay";
import PrivateCallCalleeCurrentSessionSummaryDialog from "../../shared/dialog-content/private-call-callee-current-summary-dialog/private-call-callee-current-session-summary-dialog";
import CameraMicrophoneSettingsDialog from "../../shared/dialog-content/camera-microphone-settings-dialog/camera-microphone-settings-dialog";

const PrivateStandby = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    getPrivateCallStandbyStart,
    {
      data: getPrivateCallStandbyStartData,
      error: getPrivateCallStandbyStartErrorData,
      isFetching: getPrivateCallStandbyStartFetching,
      isLoading: getPrivateCallStandbyStartLoading,
      isSuccess: getPrivateCallStandbyStartSuccess,
      isError: getPrivateCallStandbyStartError,
    },
  ] = useLazyGetPrivateCallStandbyStartQuery();
  const [
    getPrivateCallStandbyEnd,
    {
      data: getPrivateCallStandbyEndData,
      error: getPrivateCallStandbyEndErrorData,
      isFetching: getPrivateCallStandbyEndFetching,
      isLoading: getPrivateCallStandbyEndLoading,
      isSuccess: getPrivateCallStandbyEndSuccess,
      isError: getPrivateCallStandbyEndError,
    },
  ] = useLazyGetPrivateCallStandbyEndQuery();
  const [
    getPrivateCallStandbyDetails,
    {
      data: getPrivateCallStandbyDetailsData,
      error: getPrivateCallStandbyDetailsErrorData,
      isFetching: getPrivateCallStandbyDetailsFetching,
      isLoading: getPrivateCallStandbyDetailsLoading,
      isSuccess: getPrivateCallStandbyDetailsSuccess,
      isError: getPrivateCallStandbyDetailsError,
    },
  ] = useLazyGetPrivateCallStandbyDetailsQuery();
  const [
    getPrivateCallTipItems,
    {
      data: getPrivateCallTipItemsData,
      error: getPrivateCallTipItemsErrorData,
      isFetching: getPrivateCallTipItemsFetching,
      isLoading: getPrivateCallTipItemsLoading,
      isSuccess: getPrivateCallTipItemsSuccess,
      isError: getPrivateCallTipItemsError,
    },
  ] = useLazyGetPrivateCallTipItemsQuery();
  const [
    getPrivateCallStandbyKeepAlive,
    {
      data: getPrivateCallStandbyKeepAliveData,
      error: getPrivateCallStandbyKeepAliveErrorData,
      isFetching: getPrivateCallStandbyKeepAliveFetching,
      isLoading: getPrivateCallStandbyKeepAliveLoading,
      isSuccess: getPrivateCallStandbyKeepAliveSuccess,
      isError: getPrivateCallStandbyKeepAliveError,
    },
  ] = useLazyGetPrivateCallStandbyKeepAliveQuery();
  const [
    // Trigger when caller joins
    // Example trigger
    // getPrivateCallRequestDetails("?call_request_id=ozXZmrg1loAZ0G3b");
    getPrivateCallRequestDetails,
    {
      data: getPrivateCallRequestDetailsData,
      error: getPrivateCallRequestDetailsErrorData,
      isFetching: getPrivateCallRequestDetailsFetching,
      isLoading: getPrivateCallRequestDetailsLoading,
      isSuccess: getPrivateCallRequestDetailsSuccess,
      isError: getPrivateCallRequestDetailsError,
    },
  ] = useLazyGetPrivateCallRequestDetailsQuery();
  const [
    // Call when caller joins
    getPrivateCallRoomKeepAlive,
    {
      data: getPrivateCallRoomKeepAliveData,
      error: getPrivateCallRoomKeepAliveErrorData,
      isFetching: getPrivateCallRoomKeepAliveFetching,
      isLoading: getPrivateCallRoomKeepAliveLoading,
      isSuccess: getPrivateCallRoomKeepAliveSuccess,
      isError: getPrivateCallRoomKeepAliveError,
    },
  ] = useLazyGetPrivateCallRoomKeepAliveQuery();

  // General variables
  const isMountedCallStandby = useRef(false);
  const isMountedCallRoom = useRef(false);
  const isMountedEndCall = useRef(false);

  // Redux variables
  const isHunterMode = useSelector((state) => state.privateCall.isHunterMode);
  const standbyId = useSelector((state) => state.privateCall.standbyId);
  const requestId = useSelector((state) => state.privateCall.requestId);
  const callStatus = useSelector((state) => state.privateCall.callStatus);
  const callStandbyKeepAlivePassthrough = useSelector(
    (state) => state.privateCall.callStandbyKeepAlivePassthrough
  );
  const callRoomKeepAlivePassthrough = useSelector(
    (state) => state.privateCall.callRoomKeepAlivePassthrough
  );
  const notifyEndVideoCallPassthrough = useSelector(
    (state) => state.privateCall.notifyEndVideoCallPassthrough
  );
  const endVideoCall = useSelector((state) => state.privateCall.endVideoCall);
  const dispatch = useDispatch();

  // Custom Hooks Functions
  const mounted = useIsMounted();
  const onNavigate = useCustomNavigate();
  const onEndCall = useEndPrivateCall();

  // Lifecycle | Mounted
  useEffect(() => {
    // Check if "has-shown-video-call-room-dialog" in local storage is true or false
    if (!sessionService.getHasShownVideoCallRoomDialog()) {
      dispatch(updateOnboardingVideoCallRoomDialog(true));
    }

    // Initialize as Callee to show callee view for other components
    dispatch(updateIsCaller(false));

    // Initialize isPrivateStandbyMode
    dispatch(updateIsPrivateStandbyMode(true));

    // Initialize video call status
    dispatch(updateCallStatus("STANDBY"));

    // Initialize endVideoCall
    dispatch(updateEndVideoCall(false));

    // Clear private chat incase Unmounted did not clear it
    dispatch(clearVideoCallChatMessages());

    // Close summary dialog incase Unmounted did not clear it
    dispatch(updateCalleeCurrentSessionSummaryDialog(false));

    // Initialize Standby Keep Alive Interval Paused state
    dispatch(updateIsStandbyKeepAliveIntervalPaused(false));

    // Initialize Room Keep Alive Interval Paused state
    dispatch(updateIsRoomKeepAliveIntervalPaused(true));

    // Hide video call ending overlay if its open
    dispatch(updateShowVideoCallEndingOverlay(false));

    // Check if Hunter mode is true or false to prevent multiple calls & memory leak
    if (isHunterMode) {
      dispatch(updateIsHunterMode(false));
      dispatch(updateExitHunterMode(false));

      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-49-49.3",
        },
      });
    } else {
      getProfile(null, true);
      getPrivateCallStandbyStart({});
      getPrivateCallTipItems();

      // Start Private Call Standby Interval
      dispatch(
        updateStandbyTimerInterval(
          setInterval(() => {
            dispatch(startPrivateCallStandbyTimer());
          }, 1000)
        )
      );

      // Start Standby Keep Alive Interval
      dispatch(
        updateStandbyKeepAliveInterval(
          setInterval(() => {
            dispatch(updateCallStandbyKeepAlivePassthrough({}));
          }, 10000)
        )
      );

      // Start Room Keep Alive Interval
      dispatch(
        updateRoomKeepAliveInterval(
          setInterval(() => {
            dispatch(updateCallRoomKeepAlivePassthrough({}));
          }, 10000)
        )
      );

      // Start Call Duration Remaining Interval
      dispatch(
        updateCallDurationRemainingInterval(
          setInterval(() => {
            dispatch(startCallDurationRemainingTimer());
          }, 1000)
        )
      );

      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-49",
        },
      });
    }
  }, []);

  // Lifecycle | Unmounted
  useEffect(() => {
    return () => {
      if (!mounted()) {
        if (!isHunterMode) {
          dispatch(clearStandbyKeepAliveInterval());
          dispatch(clearStandbyTimerInterval());
          dispatch(resetPrivateCallStandbyTimer());
          dispatch(updateIsLocalCameraEnabled(false));

          TagManager.dataLayer({
            dataLayer: {
              event: "PWA-49-49.4",
            },
          });
        }
      }
    };
  }, [mounted]);

  // Lifecycle | Check for update | getProfile API Response
  useEffect(() => {
    if (getProfileFetching || getProfileLoading) {
    } else if (getProfileSuccess) {
      if (getProfileData?.status === 0) {
        dispatch(updateCalleeId(getProfileData?.data?.id));
      }
    } else if (getProfileError) {
      if (getProfileErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getProfileFetching,
    getProfileLoading,
    getProfileSuccess,
    getProfileError,
  ]);

  // Lifecycle | Check for update | Private Call Standby Start API Response
  useEffect(() => {
    if (
      getPrivateCallStandbyStartFetching ||
      getPrivateCallStandbyStartLoading
    ) {
    } else if (getPrivateCallStandbyStartSuccess) {
      if (getPrivateCallStandbyStartData?.status === 0) {
        dispatch(
          updateStandbyId(getPrivateCallStandbyStartData?.data?.standby?.id)
        );

        // Call First Standby Keep Alive API
        getPrivateCallStandbyKeepAlive();
      }
    } else if (getPrivateCallStandbyStartError) {
      if (getPrivateCallStandbyStartErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getPrivateCallStandbyStartFetching,
    getPrivateCallStandbyStartLoading,
    getPrivateCallStandbyStartSuccess,
    getPrivateCallStandbyStartError,
  ]);

  // Lifecycle | Check for update | Private Call Standby End API Response
  useEffect(() => {
    if (getPrivateCallStandbyEndFetching || getPrivateCallStandbyEndLoading) {
      dispatch(updateShowVideoCallEndingOverlay(true));
    } else if (getPrivateCallStandbyEndSuccess) {
      switch (getPrivateCallStandbyEndData?.status) {
        case 0:
          const obj = {
            standbyDuration:
              getPrivateCallStandbyEndData?.data?.standby?.duration,
            callDuration:
              getPrivateCallStandbyEndData?.data?.overall_stats?.call_duration,
            totalStandbyDiamondsEarned:
              getPrivateCallStandbyEndData?.data?.overall_stats
                ?.claimed_diamonds,
            totalDiamondsEarned:
              getPrivateCallStandbyEndData?.data?.overall_stats
                ?.total_diamonds_earned,
            standbyDiamondsEarned:
              getPrivateCallStandbyEndData?.data?.overall_stats
                ?.claimed_diamonds,
            videoCallDiamondsEarned:
              getPrivateCallStandbyEndData?.data?.overall_stats
                ?.call_charges_sum,
            chargedMessagesDiamondsEarned:
              getPrivateCallStandbyEndData?.data?.overall_stats
                ?.chat_messages_sum?.diamonds,
            tipsDiamondsEarned:
              getPrivateCallStandbyEndData?.data?.overall_stats?.tippings_sum,
            giftsDiamondsEarned:
              getPrivateCallStandbyEndData?.data?.overall_stats?.giftings_sum,
            actionCompleted: 0,
            requestsFromDaddy:
              getPrivateCallStandbyEndData?.data?.overall_stats
                ?.customized_tipping_count,
            incomingCallRequests:
              getPrivateCallStandbyEndData?.data?.call_requests,
          };

          dispatch(updateCalleeEndVideoCallSummaryData(obj));
          dispatch(updateShowVideoCallEndingOverlay(false));
          onEndCall();

          // Reset end video call to prevent rerun of API when joining back
          dispatch(updateEndVideoCall(false));
          break;
        case -1:
          dispatch(updateShowVideoCallEndingOverlay(false));
          onEndCall();

          // Reset end video call to prevent rerun of API when joining back
          dispatch(updateEndVideoCall(false));
          break;
        default:
          break;
      }
    } else if (getPrivateCallStandbyEndError) {
      if (getPrivateCallStandbyEndErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }

      dispatch(updateShowVideoCallEndingOverlay(false));
    }
  }, [
    getPrivateCallStandbyEndFetching,
    getPrivateCallStandbyEndLoading,
    getPrivateCallStandbyEndSuccess,
    getPrivateCallStandbyEndError,
  ]);

  // Lifecycle | Check for update | Private Call Standby Keep Alive API Response
  useEffect(() => {
    if (
      getPrivateCallStandbyKeepAliveFetching ||
      getPrivateCallStandbyKeepAliveLoading
    ) {
    } else if (getPrivateCallStandbyKeepAliveSuccess) {
      switch (getPrivateCallStandbyKeepAliveData?.status) {
        case 0:
          dispatch(
            updateTotalDiamonds(
              getPrivateCallStandbyKeepAliveData?.data?.standby?.stats
                ?.total_diamonds
            )
          );
          dispatch(
            updateCallRequestDiamonds(
              getPrivateCallStandbyKeepAliveData?.data?.standby?.stats
                ?.call_requests_diamonds
            )
          );
          dispatch(
            updateUnclaimedDiamonds(
              getPrivateCallStandbyKeepAliveData?.data?.standby?.stats
                ?.unclaimed_diamonds
            )
          );
          dispatch(
            updateClaimedDiamonds(
              getPrivateCallStandbyKeepAliveData?.data?.standby?.stats
                ?.claimed_diamonds
            )
          );
          dispatch(
            updateCallsAccepted(
              getPrivateCallStandbyKeepAliveData?.data?.standby?.stats
                ?.calls_accepted
            )
          );
          break;
        case -1:
          // Standby not available
          const warningToastObj = {
            message: getPrivateCallStandbyKeepAliveData?.message,
            autoClose: 3000,
          };
          dispatch(updateWarningToast(warningToastObj));

          // End Call
          dispatch(updateEndVideoCall(true));
        default:
          // Others
          const defaultToastObj = {
            message: getPrivateCallStandbyKeepAliveData?.message,
            autoClose: 3000,
          };
          dispatch(updateWarningToast(defaultToastObj));

          // End Call
          dispatch(updateEndVideoCall(true));
          break;
      }
    } else if (getPrivateCallStandbyKeepAliveError) {
      if (getPrivateCallStandbyKeepAliveErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getPrivateCallStandbyKeepAliveFetching,
    getPrivateCallStandbyKeepAliveLoading,
    getPrivateCallStandbyKeepAliveSuccess,
    getPrivateCallStandbyKeepAliveError,
  ]);

  // Lifecycle | Check for update | Private Call Room Keep Alive API Response
  useEffect(() => {
    if (
      getPrivateCallRoomKeepAliveFetching ||
      getPrivateCallRoomKeepAliveLoading
    ) {
    } else if (getPrivateCallRoomKeepAliveSuccess) {
      switch (getPrivateCallRoomKeepAliveData?.status) {
        case 0:
          dispatch(
            updateCurrentCallRequestDiamonds(
              getPrivateCallRoomKeepAliveData?.data?.summary
                ?.total_diamonds_earned
            )
          );

          dispatch(
            updateCallDurationRemaining(
              getPrivateCallRoomKeepAliveData?.data?.real_remaining_seconds
            )
          );
          break;
        default:
          dispatch(updateEndVideoCall(true));
          break;
      }
    } else if (getPrivateCallRoomKeepAliveError) {
      if (getPrivateCallRoomKeepAliveErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getPrivateCallRoomKeepAliveFetching,
    getPrivateCallRoomKeepAliveLoading,
    getPrivateCallRoomKeepAliveSuccess,
    getPrivateCallRoomKeepAliveError,
  ]);

  // Lifecycle | Check for update | Private Call Standby Details API Response
  useEffect(() => {
    if (
      getPrivateCallStandbyDetailsFetching ||
      getPrivateCallStandbyDetailsLoading
    ) {
    } else if (getPrivateCallStandbyDetailsSuccess) {
      if (getPrivateCallStandbyDetailsData?.status === 0) {
        dispatch(
          updateTotalDiamonds(
            getPrivateCallStandbyDetailsData?.data?.standby?.stats
              ?.total_diamonds
          )
        );
        dispatch(
          updateCallRequestDiamonds(
            getPrivateCallStandbyDetailsData?.data?.standby?.stats
              ?.call_requests_diamonds
          )
        );
        dispatch(
          updateUnclaimedDiamonds(
            getPrivateCallStandbyDetailsData?.data?.standby?.stats
              ?.unclaimed_diamonds
          )
        );
        dispatch(
          updateClaimedDiamonds(
            getPrivateCallStandbyDetailsData?.data?.standby?.stats
              ?.claimed_diamonds
          )
        );
        dispatch(
          updateCallsAccepted(
            getPrivateCallStandbyDetailsData?.data?.standby?.stats
              ?.calls_accepted
          )
        );
      }
    } else if (getPrivateCallStandbyDetailsError) {
      if (getPrivateCallStandbyDetailsErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getPrivateCallStandbyDetailsFetching,
    getPrivateCallStandbyDetailsLoading,
    getPrivateCallStandbyDetailsSuccess,
    getPrivateCallStandbyDetailsError,
  ]);

  // Lifecycle | Check for update | Private Call Request Details API Response
  useEffect(() => {
    if (
      getPrivateCallRequestDetailsFetching ||
      getPrivateCallRequestDetailsLoading
    ) {
    } else if (getPrivateCallRequestDetailsSuccess) {
      if (getPrivateCallRequestDetailsData?.status === 0) {
        dispatch(
          updateCallerId(
            getPrivateCallRequestDetailsData?.data?.call_request?.caller?.id
          )
        );
        dispatch(
          updateCallerUsername(
            getPrivateCallRequestDetailsData?.data?.call_request?.caller
              ?.username
          )
        );
        dispatch(
          updateCallerProfilePhoto(
            getPrivateCallRequestDetailsData?.data?.call_request?.caller
              ?.profile_photo?.original_photo
          )
        );
      }
    } else if (getPrivateCallRequestDetailsError) {
      if (getPrivateCallRequestDetailsErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getPrivateCallRequestDetailsFetching,
    getPrivateCallRequestDetailsLoading,
    getPrivateCallRequestDetailsSuccess,
    getPrivateCallRequestDetailsError,
  ]);

  // Lifecycle | Check for update | Receive Global Pusher event
  useEffect(() => {
    if (!requestId) return;

    // Call Room Keep Alive API for the first time
    dispatch(updateForceCallRoomKeepAlivePassthrough({}));

    dispatch(updateVideoCallPusherSubscribe({}));
    getPrivateCallRequestDetails(`?call_request_id=${requestId}`);
  }, [requestId]);

  // Lifecycle | Check for update | callStandbyKeepAlivePassthrough
  useEffect(() => {
    if (isMountedCallStandby.current) {
      if (callStandbyKeepAlivePassthrough) {
        getPrivateCallStandbyKeepAlive();
      }
    } else {
      isMountedCallStandby.current = true;
    }
  }, [callStandbyKeepAlivePassthrough]);

  // Lifecycle | Check for update | callRoomKeepAlivePassthrough
  useEffect(() => {
    if (isMountedCallRoom.current) {
      if (callRoomKeepAlivePassthrough && requestId) {
        getPrivateCallRoomKeepAlive(`?call_request_id=${requestId}`);
      }
    } else {
      isMountedCallRoom.current = true;
    }
  }, [callRoomKeepAlivePassthrough]);

  // Lifecycle | Check for update | notifyEndVideoCallPassthrough
  useEffect(() => {
    if (isMountedEndCall.current) {
      if (notifyEndVideoCallPassthrough && callStatus === "STANDBY") {
        // dispatch(updateEndVideoCall(true)); // Private Standby do not end call

        // This will trigger when caller does not have camera and microphone, thus, createLocalStream does not run on opposite side
        // Hide joining call overlay and ending call overlay from unsuccessful attempt
        dispatch(updateShowVideoCallJoiningOverlay(false));
        dispatch(updateShowVideoCallEndingOverlay(false));
      }
    } else {
      isMountedEndCall.current = true;
    }
  }, [notifyEndVideoCallPassthrough]);

  // Lifecycle | Check for update | endVideoCall
  useEffect(() => {
    if (!endVideoCall) return;

    getPrivateCallStandbyEnd({ standbyId: standbyId });
  }, [endVideoCall]);

  return (
    <div id="private-standby-page">
      <CameraFeeds />
      <GiftAnimationOverlay />
      <ProfilePhotoOverlay />

      <InteractableOverlay />

      <StandbyInfoOverlay />
      <JoiningCallOverlay />
      <InitializingStreamOverlay />
      <EndingCallOverlay />

      <PrivateCallCalleeCurrentSessionSummaryDialog />
      <CameraMicrophoneSettingsDialog />
    </div>
  );
};

export default PrivateStandby;
