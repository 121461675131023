// General
import "./hair-color-filter-dialog.scss";
import { useEffect, forwardRef } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateHairColorFilterDialog } from "../../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide } from "@mui/material";
// Components
import HairColorFilter from "../../../elements/filters/hair-color-filter/hair-color-filter";

const HairColorFilterDialog = () => {
  // Redux variables
  const hairColorFilterDialog = useSelector(
    (state) => state.dialog.hairColorFilterDialog
  );
  const dispatch = useDispatch();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!hairColorFilterDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-HairColorFilter-Dialog",
      },
    });
  }, [hairColorFilterDialog]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateHairColorFilterDialog(false));
  };

  return (
    <Dialog
      className="custom-bottom-sheet-dialog"
      fullScreen
      open={hairColorFilterDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      <HairColorFilter />
    </Dialog>
  );
};

export default HairColorFilterDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
