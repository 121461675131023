// General
import "./tipping-receive-element.scss";
// i18next
import { Trans } from "react-i18next";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";

const TippingReceiveElement = (props) => {
  const { diamondsValue, tipAction } = props;

  // Custom Hooks Functions
  const getIcon = IconManager();

  return (
    <div id="chat-section-tipping-receive-element-subcomponent">
      <div className="earned-minute-top">
        <Trans
          i18nKey={"1on1.youve_earned_diamond_from"}
          values={{
            diamond: `+${diamondsValue}`,
            source: tipAction,
          }}
          components={{
            diamondicon: getIcon("sbDiamondIcon", "diamond-icon"),
            coinicon: <span></span>,
          }}
        />
      </div>
    </div>
  );
};

export default TippingReceiveElement;
