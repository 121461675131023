// General
import "./verified-profile-badge.scss";
import { useEffect } from "react";
// Services
import { useLazyGetProfileQuery } from "../../../../services/data.service";
// Redux
import { useDispatch } from "react-redux";
import { updateOnboardingSugarbookVerifiedDialog } from "../../../../redux/store/dialogStore";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const VerifiedProfileBadge = (props) => {
  const { verifiedProfile = false, className } = props;

  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // Redux variables
  const dispatch = useDispatch();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  // Event Handlers | Button
  const onOpenVerifiedProfileDialog = () => {
    if (
      !getProfileData?.data?.verifications?.facial?.verified ||
      getProfileData?.data?.verifications?.facial?.verification_display === true
    ) {
      dispatch(updateOnboardingSugarbookVerifiedDialog(true));
    }
  };

  // Custom Hooks Functions
  const getIcon = IconManager();

  if (verifiedProfile) {
    return (
      <div
        className="verified-profile-badge-container"
        onClick={onOpenVerifiedProfileDialog}
      >
        {getIcon("verifiedProfileBadge", className)}
      </div>
    );
  } else if (verifiedProfile === false) {
    return (
      <div
        className="unverified-profile-badge-container"
        onClick={onOpenVerifiedProfileDialog}
      >
        {getIcon("unverifiedProfileBadge", className)}
      </div>
    );
  }
};

export default VerifiedProfileBadge;
