// General
import "./dual-pk-end-result-dialog.scss";
import { useEffect } from "react";
// Services
import { useLazyLivestreamingCoAnchorPkDetailsQuery } from "../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateDualPkEndResultDialog } from "../../../../redux/store/dialogStore";
// Material UI
import { Dialog, Divider } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../../elements/custom-avatar/custom-avatar";

const DualPkEndResultDialog = () => {
  // API variables
  const [
    getLivestreamingCoAnchorPkDetails,
    {
      data: getLivestreamingCoAnchorDetailsData,
      error: getLivestreamingCoAnchorDetailsErrorData,
      isFetching: getLivestreamingCoAnchorDetailsFetching,
      isLoading: getLivestreamingCoAnchorDetailsLoading,
      isSuccess: getLivestreamingCoAnchorDetailsSuccess,
      isError: getLivestreamingCoAnchorDetailsError,
    },
  ] = useLazyLivestreamingCoAnchorPkDetailsQuery();

  // Redux variables
  const dualPkEndResultDialog = useSelector(
    (state) => state.dialog.dualPkEndResultDialog
  );
  const coAnchorUser1 = useSelector(
    (state) => state.livestreaming.coAnchorUser1
  );
  const coAnchorUser2 = useSelector(
    (state) => state.livestreaming.coAnchorUser2
  );
  const dualPkData = useSelector((state) => state.livestreaming.dualPkData);
  const dualPkEnded = useSelector((state) => state.livestreaming.dualPkEnded);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Check for update | dualPkEnded
  useEffect(() => {
    if (dualPkEnded) {
      getLivestreamingCoAnchorPkDetails(`?co_anchor_pk_id=${dualPkData?.id}`);
    }
  }, [dualPkEnded]);

  // Lifecycle | Check for update | getLivestreamingCoAnchorPkDetails
  useEffect(() => {
    if (
      getLivestreamingCoAnchorDetailsFetching ||
      getLivestreamingCoAnchorDetailsLoading
    ) {
    } else if (getLivestreamingCoAnchorDetailsSuccess) {
      dispatch(updateDualPkEndResultDialog(true));
    } else if (getLivestreamingCoAnchorDetailsError) {
    }
  }, [
    getLivestreamingCoAnchorDetailsFetching,
    getLivestreamingCoAnchorDetailsLoading,
    getLivestreamingCoAnchorDetailsSuccess,
    getLivestreamingCoAnchorDetailsError,
  ]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateDualPkEndResultDialog(false));
  };

  // Utility Functions
  const getDualPkWinnerUsername = () => {
    if (
      getLivestreamingCoAnchorDetailsData?.data?.pk?.final_winner ===
      coAnchorUser1?.userId
    ) {
      return coAnchorUser1?.username;
    } else if (
      getLivestreamingCoAnchorDetailsData?.data?.pk?.final_winner ===
      coAnchorUser2?.userId
    ) {
      return coAnchorUser2?.username;
    } else {
      return "Draw";
    }
  };
  const getContestant1TotalDiamonds = () => {
    return getLivestreamingCoAnchorDetailsData?.data?.pk?.rounds
      ?.reduce((acc, round) => acc + round?.stats[0]?.diamonds, 0)
      ?.toLocaleString();
  };
  const getContestant2TotalDiamonds = () => {
    return getLivestreamingCoAnchorDetailsData?.data?.pk?.rounds
      ?.reduce((acc, round) => acc + round?.stats[1]?.diamonds, 0)
      ?.toLocaleString();
  };

  return (
    <Dialog
      className="custom-radius25-dialog"
      open={dualPkEndResultDialog}
      onClose={onCloseDialog}
    >
      <div id="dual-pk-end-result-dialog">
        <div className="padding-container">
          <div className="pk-end-label">PK has ended</div>

          <div className="dual-pk-winner-username-container">
            <div className="dual-pk-winner-label">Winner:</div>
            <div className="dual-pk-winner-username">
              {getDualPkWinnerUsername()}
            </div>
          </div>

          <div className="dual-pk-contestant-profile-photo-container">
            <div className="dual-pk-contestant-1-profile-photo-container">
              <div className="dual-pk-gradient-padding">
                <CustomAvatar className="" src={coAnchorUser1?.profilePhoto} />
              </div>

              <div className="dual-pk-contestant-1-username">
                {coAnchorUser1?.username}
              </div>
            </div>

            <div className="dual-pk-icon-container">
              {getIcon("pkIcon", "dual-pk-icon")}
            </div>

            <div className="dual-pk-contestant-2-profile-photo-container">
              <div className="dual-pk-gradient-padding">
                <CustomAvatar className="" src={coAnchorUser2?.profilePhoto} />
              </div>

              <div className="dual-pk-contestant-2-username">
                {coAnchorUser2?.username}
              </div>
            </div>
          </div>

          <div className="dual-pk-results-container">
            <Divider className="white-divider" />

            {getLivestreamingCoAnchorDetailsData?.data?.pk?.rounds?.map(
              (round, index) => (
                <div className="dual-pk-rounds-result-container" key={index}>
                  <div
                    className={`n-round-score ${
                      round?.winner === coAnchorUser1?.userId
                        ? "highlighted-yellow"
                        : ""
                    }`}
                  >
                    {round?.stats[0]?.diamonds?.toLocaleString() || 0}
                  </div>

                  <div className="n-round-container">
                    <div className="dual-pk-medal-container">
                      {getIcon(
                        "pkMedalIcon",
                        `dual-pk-medal-icon ${
                          round?.winner === coAnchorUser1?.userId
                            ? ""
                            : "grey-medal"
                        }`
                      )}
                    </div>

                    <div className="dual-pk-round-label">
                      Round {round?.round}
                    </div>

                    <div className="dual-pk-medal-container">
                      {getIcon(
                        "pkMedalIcon",
                        `dual-pk-medal-icon ${
                          round?.winner === coAnchorUser2?.userId
                            ? ""
                            : "grey-medal"
                        }`
                      )}
                    </div>
                  </div>

                  <div
                    className={`n-round-score ${
                      round?.winner === coAnchorUser2?.userId
                        ? "highlighted-yellow"
                        : ""
                    }`}
                  >
                    {round?.stats[1]?.diamonds?.toLocaleString() || 0}
                  </div>
                </div>
              )
            )}

            <Divider className="white-divider" />

            <div className="dual-pk-final-result-container">
              <div
                className={`final-score ${
                  getLivestreamingCoAnchorDetailsData?.data?.pk
                    ?.final_winner === coAnchorUser1?.userId
                    ? "highlighted-yellow"
                    : ""
                }`}
              >
                {getContestant1TotalDiamonds()}
              </div>

              <div className="total-diamonds-container">Total Diamonds</div>

              <div
                className={`final-score ${
                  getLivestreamingCoAnchorDetailsData?.data?.pk
                    ?.final_winner === coAnchorUser2?.userId
                    ? "highlighted-yellow"
                    : ""
                }`}
              >
                {getContestant2TotalDiamonds()}
              </div>
            </div>
          </div>

          {getLivestreamingCoAnchorDetailsData?.data?.top_gifters?.length >
            0 && (
            <div className="top-gifters-container">
              <div className="top-gifters-label">Top Gifters</div>

              <div className="top-gifters-list-container">
                {getLivestreamingCoAnchorDetailsData?.data?.top_gifters?.map(
                  (topGifter, index) => {
                    if (index < 3) {
                      return (
                        <div className="top-gifter-container" key={index}>
                          <CustomAvatar
                            className="top-gifter-profile-photo"
                            src={topGifter?.profile_photo}
                          />

                          <div className="dual-pk-placement-medal-icon-container">
                            {getIcon(
                              index === 0
                                ? "dualPkFirstPlaceIcon"
                                : index === 1
                                ? "dualPkSecondPlaceIcon"
                                : index === 2
                                ? "dualPkThirdPlaceIcon"
                                : null,
                              "dual-pk-placement-medal-icon"
                            )}
                          </div>

                          <div className="top-gifter-username">
                            {topGifter?.username || "-"}
                          </div>
                        </div>
                      );
                    }
                  }
                )}
              </div>
            </div>
          )}

          <div className="close-button" onClick={onCloseDialog}>
            {t("common.close")}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default DualPkEndResultDialog;
