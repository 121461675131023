// General
import "./gifts.scss";
import { useEffect } from "react";
// Services
import {
  usePurchaseProductMutation,
  usePostPrivateCallRoomGiftRequestMutation,
  useLazyGetProfileQuery,
} from "../../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updatePurchaseDialogTab } from "../../../../../redux/store/productsStore";
import { updateProductPurchaseDialog } from "../../../../../redux/store/dialogStore";
import { updateErrorToast } from "../../../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation } from "react-i18next";

const Gifts = (props) => {
  const { state, productHashId, giftImage, giftAmount, isEventBased } = props;

  // API variables
  const [
    purchaseProduct,
    {
      data: purchaseProductData,
      error: purchaseProductErrorData,
      isLoading: purchaseProductLoading,
      isSuccess: purchaseProductSuccess,
      isError: purchaseProductError,
    },
  ] = usePurchaseProductMutation();
  const [
    requestGift,
    {
      data: requestGiftData,
      error: requestGiftErrorData,
      isLoading: requestGiftLoading,
      isSuccess: requestGiftSuccess,
      isError: requestGiftError,
    },
  ] = usePostPrivateCallRoomGiftRequestMutation();
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // Redux variables
  const livestreamerId = useSelector(
    (state) => state.livestreaming.livestreamerId
  );
  const requestId = useSelector((state) => state.privateCall.requestId);
  const calleeId = useSelector((state) => state.privateCall.calleeId);
  const callerId = useSelector((state) => state.privateCall.callerId);
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const freeSecondsRemaining = useSelector(
    (state) => state.privateCall.freeSecondsRemaining
  );
  const channelId = useSelector((state) => state.livestreaming.channelId);
  const isCoAnchor = useSelector((state) => state.livestreaming.isCoAnchor);

  const coAnchorSelectedUser = useSelector(
    (state) => state.livestreaming.coAnchorSelectedUser
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  // Lifecycle | Check for update | Purchase Product API Response
  useEffect(() => {
    if (purchaseProductLoading) {
    } else if (purchaseProductSuccess) {
      switch (purchaseProductData?.status) {
        case -1:
          // Insufficient Funds (so far)
          if (state === "private-call" && freeSecondsRemaining < 0) {
            // Do not show toast if using free minutes
          } else {
            const toastObj = {
              message: purchaseProductData?.message,
              autoClose: 3000,
            };
            dispatch(updateErrorToast(toastObj));
          }

          TagManager.dataLayer({
            dataLayer: {
              event: `PWA-${
                state === "livestream" ? "Livestream" : "VideoCall"
              }-PurchaseGift-Error-InsufficientFunds`,
            },
          });

          if (state === "private-call") {
            dispatch(updatePurchaseDialogTab(0));
            onOpenProductsDialog();
          }

          break;
        default:
          break;
      }
    } else if (purchaseProductError) {
    }
  }, [purchaseProductLoading, purchaseProductSuccess, purchaseProductError]);

  // Event Handlers | Button
  const onSendGift = () => {
    if (state === "livestream") {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-20-20.9-Button",
        },
      });

      const obj = {
        payment_gateway: "virtual_currency",
        related_module: isCoAnchor ? "co-anchor" : "livestreaming",
        product_hash_id: productHashId,
        recipient_user_id: isCoAnchor ? coAnchorSelectedUser : livestreamerId,
        related_id: channelId,
      };

      purchaseProduct(obj);
    } else if (state === "private-call") {
      if (isDaddy) {
        TagManager.dataLayer({
          dataLayer: {
            event: "PWA-46-46.1-Button",
          },
        });

        const obj = {
          payment_gateway: "virtual_currency",
          product_hash_id: productHashId,
          recipient_user_id:
            getProfileData?.data?.id === calleeId ? callerId : calleeId,
          related_id: requestId,
          related_module: "private_calls",
        };

        purchaseProduct(obj);
      } else {
        TagManager.dataLayer({
          dataLayer: {
            event: "PWA-46-46.2-Button",
          },
        });

        const obj = {
          call_request_id: requestId,
          message: `${t("1on1.gift_request_1")} :gift`,
          product_hash_id: productHashId,
        };

        requestGift(obj);
      }
    }
  };

  // Event Handlers | MUI Dialog
  const onOpenProductsDialog = () => {
    dispatch(updateProductPurchaseDialog(true));
  };

  return (
    <div id="gifts-section-gifts-subcomponent">
      <div className="clickable-container" onClick={onSendGift}>
        <div className="gifts-image-container">
          <img
            className={`${
              state === "livestream" || isDaddy
                ? "gifts-image"
                : "grey-gifts-image"
            }`}
            src={giftImage}
            alt="gift"
          />

          {isEventBased && livestreamerId && (
            <div className="gift-event-container">
              <div className="event-label">{t("live.gifts_event")}</div>
            </div>
          )}
        </div>

        <div className="amount">{giftAmount}</div>
      </div>
    </div>
  );
};

export default Gifts;
