// General
import "./leaderboard-events-dialog.scss";
import { useEffect, forwardRef } from "react";
// Services
import { useLazyGetLivestreamingContestListQuery } from "../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateLeaderboardEventsDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide, useMediaQuery } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// Components
import EventsTab from "../../../leaderboard/leaderboard/livestreaming-tab/events-tab/events-tab";
import TopLeaderboardListDialog from "../top-leaderboard-list-dialog/top-leaderboard-list-dialog";
import UnfollowUserDialog from "../unfollow-user-dialog/unfollow-user-dialog";

const LeaderboardEventsDialog = () => {
  // API variables
  const [
    getLivestreamingContestList,
    {
      data: getLivestreamingContestListData,
      error: getLivestreamingContestListErrorData,
      isFetching: getLivestreamingContestListFetching,
      isLoading: getLivestreamingContestListLoading,
      isSuccess: getLivestreamingContestListSuccess,
      isError: getLivestreamingContestListError,
    },
  ] = useLazyGetLivestreamingContestListQuery();

  // Redux variables
  const leaderboardEventsDialog = useSelector(
    (state) => state.dialog.leaderboardEventsDialog
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 900px)");

  // Lifecycle | Mounted
  useEffect(() => {
    if (!leaderboardEventsDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-LeaderboardEvents-Dialog",
      },
    });

    getLivestreamingContestList(null, true);
  }, [leaderboardEventsDialog]);

  // Event Handlers | Button
  const onCloseDialog = () => {
    dispatch(updateLeaderboardEventsDialog(false));
  };

  return (
    <Dialog
      className={
        isMobile ? "custom-bottom-sheet-dialog" : "custom-radius35-dialog"
      }
      fullScreen={isMobile}
      open={leaderboardEventsDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      <div
        id="leaderboard-events-dialog"
        className={!isMobile ? "min-leaderboard-events-width" : ""}
      >
        <div className="dialog-header">
          <div className="left-container" onClick={onCloseDialog}>
            <ChevronLeftIcon className="back-icon" />
          </div>
          <div className="middle-container">Events Leaderboard</div>
          <div className="right-container"></div>
        </div>

        <div className="events-container">
          <EventsTab isStandalone={true} />
        </div>
      </div>

      <TopLeaderboardListDialog />
      <UnfollowUserDialog />
    </Dialog>
  );
};

export default LeaderboardEventsDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
