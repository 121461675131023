// General
import "./ethnicity-filter.scss";
import { useEffect } from "react";
// Services
import { useLazyGetJourneyQuestionsQuery } from "../../../../../services/data.service";
// Static Data
import searchConst from "../../../../../const/searchConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateEthnicityData,
  updateEthnicitySelected,
} from "../../../../../redux/store/searchFilterStore";
import { updateEthnicityFilterDialog } from "../../../../../redux/store/dialogStore";
// Material UI
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation } from "react-i18next";
// Components
import Spinner from "../../spinner/spinner";

const EthnicityFilter = (props) => {
  const { type, enableTitle = true } = props;

  // API variables
  const [
    getJourneyQuestions,
    {
      data: getJourneyQuestionsData,
      error: getJourneyQuestionsErrorData,
      isFetching: getJourneyQuestionsFetching,
      isLoading: getJourneyQuestionsLoading,
      isSuccess: getJourneyQuestionsSuccess,
      isError: getJourneyQuestionsError,
    },
  ] = useLazyGetJourneyQuestionsQuery();

  // Redux variables
  const ethnicityData = useSelector(
    (state) => state.searchFilter.ethnicityData
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    getJourneyQuestions(null, true);
  }, []);

  // Lifecycle | Check for update | getJourneyQuestions API Response
  useEffect(() => {
    if (getJourneyQuestionsFetching || getJourneyQuestionsLoading) {
    } else if (getJourneyQuestionsSuccess) {
      if (ethnicityData?.length > 0) return;

      let tempEthnicityData = getEthnicityData()?.map((item) => ({
        ...item,
        isSelected: false,
      }));
      dispatch(updateEthnicityData(tempEthnicityData));
    } else if (getJourneyQuestionsError) {
    }
  }, [
    getJourneyQuestionsFetching,
    getJourneyQuestionsLoading,
    getJourneyQuestionsSuccess,
    getJourneyQuestionsError,
  ]);

  // Lifecycle | Check for update | ethnicityData
  useEffect(() => {
    dispatch(updateEthnicitySelected(getSelectedEthnicity()));
  }, [ethnicityData]);

  // Event Handlers | MUI Checkbox
  const onCheckboxChange = (id) => (event) => {
    const updatedEthnicityData = ethnicityData?.map((item) =>
      item.id === id ? { ...item, isSelected: event.target.checked } : item
    );
    dispatch(updateEthnicityData(updatedEthnicityData));
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateEthnicityFilterDialog(false));
  };

  // Utility Functions
  const getEthnicityData = () => {
    return getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "ethnicity"
    )?.selections;
  };
  const getSelectedEthnicity = () => {
    return ethnicityData?.filter((item) => item.isSelected);
  };

  return (
    <div id="ethnicity-filter-shared-component">
      {enableTitle && (
        <div className="dialog-header">
          <div className="title">{t("filter.ethnicity")}</div>

          <div className="close-button-container">
            <CloseIcon className="close-button" onClick={onCloseDialog} />
          </div>
        </div>
      )}

      <div className="padding-container">
        {ethnicityData?.length > 0 ? (
          <div
            className={`checkbox-container ${
              type === searchConst.searchFilterType.full ? "flexwrap" : ""
            }`}
          >
            {ethnicityData?.map((ethnicity, index) => (
              <FormGroup className="checkbox" key={index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      className={`custom-checkbox ${
                        type === searchConst.searchFilterType.full
                          ? "custom-checkbox-no-padding"
                          : ""
                      }`}
                      size={
                        type === searchConst.searchFilterType.full
                          ? "small"
                          : "medium"
                      }
                      checked={ethnicity?.isSelected}
                      onChange={onCheckboxChange(ethnicity?.id)}
                    />
                  }
                  label={
                    <span
                      className={
                        type === searchConst.searchFilterType.full
                          ? "checkbox-font"
                          : ""
                      }
                    >
                      {ethnicity?.display_locale}
                    </span>
                  }
                />
              </FormGroup>
            ))}
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

export default EthnicityFilter;
