// General
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // General variables
  successToast: null,
  errorToast: null,
  warningToast: null,
  infoToast: null,

  // Messages variables
  messageToast: null,

  // In App variables
  inAppToast: null,
};

export const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    // General Functions
    updateSuccessToast: (state, action) => {
      state.successToast = action.payload;
    },
    updateErrorToast: (state, action) => {
      state.errorToast = action.payload;
    },
    updateWarningToast: (state, action) => {
      state.warningToast = action.payload;
    },
    updateInfoToast: (state, action) => {
      state.infoToast = action.payload;
    },

    // Messages Functions
    updateMessageToast: (state, action) => {
      state.messageToast = action.payload;
    },

    // In App Functions
    updateInAppToast: (state, action) => {
      state.inAppToast = action.payload;
    },

    // Utility Functions
    resetToastStore: () => {
      return { ...initialState };
    },
  },
});

export const {
  // General Functions
  updateSuccessToast,
  updateErrorToast,
  updateWarningToast,
  updateInfoToast,

  // Messages Functions
  updateMessageToast,

  // In App Functions
  updateInAppToast,

  // Utility Functions
  resetToastStore,
} = toastSlice.actions;

export default toastSlice.reducer;
