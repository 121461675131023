// General
import "./warning-notification.scss";
// Material UI
import WarningIcon from "@mui/icons-material/Warning";
import CloseIcon from "@mui/icons-material/Close";

const WarningNotification = (props) => {
  const { message } = props;

  return (
    <div id="warning-nofitication-element">
      <div className="padding-container">
        <div className="center-container">
          <div className="warning-icon-container">
            <div className="warning-inner-ring">
              <WarningIcon className="warning-icon" />
            </div>
          </div>
        </div>

        <div className="message-body-container">
          <div className="message-body">{message}</div>
        </div>

        {false && <div className="close-icon-container">
          <CloseIcon className="close-icon" />
        </div>}
      </div>
    </div>
  );
};

export default WarningNotification;
