// General
import "./top-leaderboard-list-dialog.scss";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateTopLeaderboardListDialog } from "../../../../redux/store/dialogStore";
// Material UI
import { Dialog } from "@mui/material";
// Components
import TopLeaderboardList from "../../../leaderboard/top-leaderboard-list/top-leaderboard-list";

const TopLeaderboardListDialog = () => {
  // Redux variables
  const topLeaderboardListDialog = useSelector(
    (state) => state.dialog.topLeaderboardListDialog
  );
  const dispatch = useDispatch();

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateTopLeaderboardListDialog(false));
  };

  return (
    <Dialog
      className="custom-radius25-dialog"
      open={topLeaderboardListDialog}
      onClose={onCloseDialog}
    >
      <div id="top-leaderboard-list-dialog">
        <TopLeaderboardList state={"dialog"} />
      </div>
    </Dialog>
  );
};

export default TopLeaderboardListDialog;
