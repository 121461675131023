// General
import "./save-to-homescreen-dialog.scss";
import { useEffect, forwardRef } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateSaveToHomescreenDialog,
  updateSaveToHomescreenTutorialDialog,
} from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import AssetManager from "../../../utility/manager/asset-manager/asset-manager";

const SaveToHomescreenDialog = () => {
  // Redux variables
  const saveToHomescreenDialog = useSelector(
    (state) => state.dialog.saveToHomescreenDialog
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getAsset = AssetManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!saveToHomescreenDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-SaveToHomescreen-Dialog",
      },
    });
  }, [saveToHomescreenDialog]);

  // Event Handlers | Button
  const onOpenTutorialDialog = () => {
    dispatch(updateSaveToHomescreenTutorialDialog(true));
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateSaveToHomescreenDialog(false));
  };

  return (
    <Dialog
      fullScreen
      open={saveToHomescreenDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      <div id="save-to-homescreen-dialog">
        <div className="header">
          <div className="close-button-container">
            <CloseIcon className="close-button" onClick={onCloseDialog} />
          </div>
        </div>

        <div className="pwa-background-container">
          <img className="pwa-background" src={getAsset("tryPwaBackground")} />
        </div>

        <div className="try-now-button" onClick={onOpenTutorialDialog}>
          {t("common.try_now")}
        </div>
      </div>
    </Dialog>
  );
};

export default SaveToHomescreenDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
