// General
import "./private-call-decline-dialog.scss";
import { useEffect, forwardRef } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updatePrivateCallDeclineDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const PrivateCallDeclineDialog = () => {
  // Redux variables
  const privateCallDeclineDialog = useSelector(
    (state) => state.dialog.privateCallDeclineDialog
  );
  const isCaller = useSelector((state) => state.privateCall.isCaller);
  const callerUsername = useSelector(
    (state) => state.privateCall.callerUsername
  );
  const calleeUsername = useSelector(
    (state) => state.privateCall.calleeUsername
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!privateCallDeclineDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-PrivateCallDecline-Dialog",
      },
    });
  }, [privateCallDeclineDialog]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updatePrivateCallDeclineDialog(false));
  };

  return (
    <Dialog
      className="custom-fullscreen-transparent-dialog"
      fullScreen
      open={privateCallDeclineDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={onCloseDialog}
    >
      <div id="private-call-decline-dialog">
        <div className="padding-container">
          <div className="call-rejected-container">
            <div className="call-reject-animation-container">
              {getIcon("callRejectGif", "call-reject-animation")}
            </div>

            <div className="call-failed-label">{t("inbox.call_declined")}</div>
            <div className="text">
              {t("inbox.calling_failed", {
                user: isCaller ? calleeUsername : callerUsername,
              })}
            </div>
            <div className="close-button" onClick={onCloseDialog}>
              {t("common.close")}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default PrivateCallDeclineDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
