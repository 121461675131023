// General
import { useState, useEffect, useRef } from "react";
// Services
import { useLazyGetProfileQuery } from "../../../../services/data.service";
import { API_CONFIG_HEADERS } from "../../../../const/apiConst";
import { sessionService } from "../../../../services/session.service";
// Static Data
import pusherConst from "../../../../const/pusherConst";
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  // General Functions
  updateChannelId,

  // Video Player Functions
  updateLivestreamLoading,
  updateVideoPlayerMute,

  // Chat Functions
  updateLivestreamChatMessages,

  // Emoji Functions
  updateLivestreamFloatingEmoji,

  // Gift Animation Functions
  updateLivestreamGiftAnimation,

  // Co Anchor Functions
  updateIsCoAnchor,
  updateCoAnchorUser1UserId,
  updateCoAnchorUser2UserId,
  updateCoAnchorUserDualPkWinInPusher,
  resetCoAnchorUser1,
  resetCoAnchorUser2,
  resetCoAnchorUser1DualPkDiamonds,
  resetCoAnchorUser2DualPkDiamonds,
  resetCoAnchorUsersDualPk,
  updateCoAnchorSelectedUser,

  // Dual PK Functions
  updateIsDualPk,
  updateDualPkSyncScorePassthrough,
  updateDualPkEnded,
  resetDualPkFunctions,

  // VIP Entrance Functions
  updateLivestreamVipEntrance,

  // PK Functions
  updateContestant1,
  updateContestant2,
  updateDualPkData,
  updateCurrentRound,
  updatePkWinner,

  // Pusher Event Functions
  updateLivestreamPusherEvent,

  // PK Functions
  updatePkIntervalPassthrough,

  // Utility Functions
  updatePinnedChatRefreshPassthrough,
  updateCoAnchorStartPassthrough,
  updateCoAnchorEndPassthrough,
  updateDualPkShowResultsOverlay,
} from "../../../../redux/store/livestreamingStore";
import {
  updateLiveViewPusherSubscribe,
  updateLiveViewPusherUnsubscribe,
} from "../../../../redux/store/pusherStore";
import {
  updateLivestreamKickedDialog,
  updateDualPkEndResultDialog,
} from "../../../../redux/store/dialogStore";
import { updateErrorToast } from "../../../../redux/store/toastStore";
import {
  updateLiveViewPusherState,
  updateLiveViewChannelState,
  updateLiveViewPublicChannelState,
  updateLiveViewPublicChannelGlobalState,
} from "../../../../redux/store/debugStore";
// Pusher-js
import Pusher from "pusher-js";
// Custom Hooks
import useCustomNavigate from "../../custom-hooks/useCustomNavigate-hook";

const LiveViewPusher = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // General variables
  const subscribeIsMounted = useRef(false);
  const unsubscribeIsMounted = useRef(false);
  const resetIsMounted = useRef(false);
  const destroyIsMounted = useRef(false);

  // Redux variables
  const isLoggedIn = useSelector((state) => state.public.isLoggedIn);
  const liveViewPusherSubscribe = useSelector(
    (state) => state.pusher.liveViewPusherSubscribe
  );
  const liveViewPusherUnsubscribe = useSelector(
    (state) => state.pusher.liveViewPusherUnsubscribe
  );
  const liveViewPusherTrigger = useSelector(
    (state) => state.pusher.liveViewPusherTrigger
  );
  const liveViewPusherReset = useSelector(
    (state) => state.pusher.liveViewPusherReset
  );
  const liveViewPusherDestroy = useSelector(
    (state) => state.pusher.liveViewPusherDestroy
  );
  const readOnlyChannelId = useSelector(
    (state) => state.livestreaming.readOnlyChannelId
  );
  const channelId = useSelector((state) => state.livestreaming.channelId);
  const showLog = useSelector((state) => state.debug.showLog);
  const dispatch = useDispatch();

  // Pusher variables
  let authEndpoint = `${process.env["REACT_APP_SPI_API"]}broadcasting/auth`;
  let [pusherInstance, setPusherInstance] = useState(null);
  let [channel, setChannel] = useState(null);
  let [publicChannel, setPublicChannel] = useState(null);
  let [publicChannelGlobal, setPublicChannelGlobal] = useState(null);

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!isLoggedIn) return;

    getProfile(null, true);
  }, [isLoggedIn]);

  // Lifecycle | Initiate
  useEffect(() => {
    if (subscribeIsMounted.current) {
      if (!liveViewPusherSubscribe || pusherInstance) return;

      let headers = {
        headers: API_CONFIG_HEADERS.SPI_HEADERS,
        Authorization: `${sessionService.getApiToken()}`,
      };

      setPusherInstance(
        new Pusher(process.env["REACT_APP_PUSHER_APP_KEY"], {
          authEndpoint: authEndpoint,
          cluster: "ap1",
          auth: {
            headers: headers,
          },
        })
      );
    } else {
      subscribeIsMounted.current = true;
    }
  }, [liveViewPusherSubscribe]);

  // Lifecycle | Check for update | Subscribe
  useEffect(() => {
    if (!pusherInstance || !channelId) return;

    pusherInstance?.connection?.bind("state_change", (state) => {
      dispatch(updateLiveViewPusherState(state.current));

      switch (state.current) {
        case "initialized":
          break;
        case "connecting":
          break;
        case "connected":
          break;
        case "disconnected":
          break;
        case "unavailable":
          break;
        case "failed":
          break;
        case "disconnected":
          break;
        default:
          break;
      }
    });

    setChannel(
      pusherInstance.subscribe(
        pusherConst.liveViewPusher.channel.privateStreamSubscribe + channelId
      )
    );
    setPublicChannel(
      pusherInstance.subscribe(
        pusherConst.liveViewPusher.channel.generalStreamSubscribe + channelId
      )
    );
    setPublicChannelGlobal(
      pusherInstance.subscribe(
        pusherConst.liveViewPusher.channel.generalStreamGlobalSubscribe
      )
    );
  }, [pusherInstance, channelId]);

  // Lifecycle | Check for update | Unsubscribe
  useEffect(() => {
    if (unsubscribeIsMounted.current) {
      if (!liveViewPusherUnsubscribe) return;

      channel?.unsubscribe();
      publicChannel?.unsubscribe();
      publicChannelGlobal?.unsubscribe();

      dispatch(updateLiveViewChannelState(false));
      dispatch(updateLiveViewPublicChannelState(false));
      dispatch(updateLiveViewPublicChannelGlobalState(false));

      if (pusherInstance) {
        // Disconnect Pusher and its channels
        pusherInstance.disconnect();
        setPusherInstance(null);
      }
    } else {
      unsubscribeIsMounted.current = true;
    }
  }, [liveViewPusherUnsubscribe]);

  // Lifecycle | Check for update | Private Payload
  useEffect(() => {
    if (!channel) return;

    // Event Listener | State
    channel?.bind("pusher:subscription_succeeded", () => {
      // console.log("Subscribed to channel");
      dispatch(updateLiveViewChannelState(true));
    });
    channel?.bind("pusher:subscription_error", (error) => {
      // console.log("Error subscribing to channel: ", error);
      dispatch(updateLiveViewChannelState(false));
    });

    // Event Listener | Response
    channel.bind(
      pusherConst.liveViewPusher.channel.privateStreamClientChatBind,
      (payload) => {
        if (showLog) {
          console.log("client-chat", payload);
        }

        if (payload?.message?.type) {
          switch (payload?.message?.type) {
            case pusherConst.liveViewPusher.payloadType.emoji:
              if (showLog) {
                console.log("LIVESTREAM PUSHER: Emoji");
              }

              dispatch(updateLivestreamChatMessages(payload?.message));
              break;
            case pusherConst.liveViewPusher.payloadType.emojiAnimation:
              if (showLog) {
                console.log("LIVESTREAM PUSHER: Emoji Animation");
              }

              dispatch(updateLivestreamFloatingEmoji(payload?.message));
            case pusherConst.liveViewPusher.payloadType.message: // Deprecated, replaced with message.v2
              break;
            default:
              break;
          }
        }
      }
    );
    channel.bind(
      pusherConst.liveViewPusher.channel.privateStreamChatBind,
      (payload) => {
        if (showLog) {
          console.log("chat", payload);
        }
      }
    );
  }, [channel]);

  // Lifecycle | Check for update | Public Payload
  useEffect(() => {
    if (!publicChannel) return;

    // Event Listener | State
    publicChannel?.bind("pusher:subscription_succeeded", () => {
      // console.log("Subscribed to channel");
      dispatch(updateLiveViewPublicChannelState(true));
    });
    publicChannel?.bind("pusher:subscription_error", (error) => {
      // console.log("Error subscribing to channel: ", error);
      dispatch(updateLiveViewPublicChannelState(false));
    });

    // Event Listener | Response
    publicChannel.bind(
      pusherConst.liveViewPusher.channel.generalStreamBind,
      (payload) => {
        if (showLog) {
          console.log("general-message", payload);
        }

        if (payload?.message?.type) {
          switch (payload?.message?.type) {
            case pusherConst.liveViewPusher.payloadType.joining:
              if (showLog) {
                console.log("LIVESTREAM PUSHER: Joining");
              }

              dispatch(updateLivestreamChatMessages(payload?.message));

              // Check if user has profile decoration (King of the Month, Queen of the Month, etc) and send to slide-in-out-alert-overlay.js
              if (
                payload?.message?.profile_decorations
                  ?.live_streaming_entrance_url
              ) {
                dispatch(updateLivestreamVipEntrance(payload?.message));
              }
              break;
            case pusherConst.liveViewPusher.payloadType.messageV2:
              if (showLog) {
                console.log("LIVESTREAM PUSHER: Message V2");
              }

              dispatch(updateLivestreamChatMessages(payload?.message));
              break;
            case pusherConst.liveViewPusher.payloadType.gifting:
              if (showLog) {
                console.log("LIVESTREAM PUSHER: Gifting");
              }

              dispatch(updateLivestreamChatMessages(payload?.message));
              dispatch(updateLivestreamGiftAnimation(payload?.message));
              break;
            case pusherConst.liveViewPusher.payloadType.following:
              if (showLog) {
                console.log("LIVESTREAM PUSHER: Following");
              }

              dispatch(updateLivestreamChatMessages(payload?.message));
              break;
            case pusherConst.liveViewPusher.payloadType.streamPaused:
              if (showLog) {
                console.log("LIVESTREAM PUSHER: Stream Paused");
              }

              // Deprecated due to business logic
              // Show pause overlay
              break;
            case pusherConst.liveViewPusher.payloadType.streamResume:
              if (showLog) {
                console.log("LIVESTREAM PUSHER: Stream Resume");
              }

              // Deprecated due to business logic
              // Hide pause overlay
              break;
            case pusherConst.liveViewPusher.payloadType.streamEnded:
              if (showLog) {
                console.log("LIVESTREAM PUSHER: Stream Ended");
              }

              // If livestream ended, navigate to live ended page
              onNavigate(routeConst.live.ended.path);
              break;
            case pusherConst.liveViewPusher.payloadType.viewerKicked:
            case pusherConst.liveViewPusher.payloadType.kicked:
              if (showLog) {
                console.log("LIVESTREAM PUSHER: KICKED");
              }

              if (payload?.message?.user_id === getProfileData?.data?.id) {
                // Pause or stop livestream and open kicked dialog
                dispatch(updateLivestreamPusherEvent(payload?.message));
                dispatch(updateLivestreamKickedDialog(true));

                onNavigate(routeConst.live.path);
              }
              break;
            case pusherConst.liveViewPusher.payloadType.refresh:
              if (showLog) {
                console.log("LIVESTREAM PUSHER: REFRESH");
              }

              // Refresh pinned message
              dispatch(updatePinnedChatRefreshPassthrough());
              break;
            case pusherConst.liveViewPusher.payloadType.coAnchorStart:
              if (showLog) {
                console.log("LIVESTREAM PUSHER: CO ANCHOR START");
              }
              // Show loading overlay
              dispatch(updateLivestreamLoading(true));

              // Set Co Anchor as true
              dispatch(updateIsCoAnchor(true));

              // Mute video player
              dispatch(updateVideoPlayerMute(true));

              // Get new channel ID and save it to redux store
              const channelIdStart = payload?.message?.channel?.channel_id;
              if (channelIdStart) {
                dispatch(updateChannelId(channelIdStart));
              }

              // User ID is the only available data in Pusher
              // Set Co Anchor User 1 ID
              dispatch(
                updateCoAnchorUser1UserId(
                  payload?.message?.channel?.positions[0]?.user_id
                )
              );
              // Set Co Anchor User 2 ID
              dispatch(
                updateCoAnchorUser2UserId(
                  payload?.message?.channel?.positions[1]?.user_id
                )
              );

              // Reset Co Anchor Dual PK
              dispatch(resetCoAnchorUsersDualPk());

              // Calculate win count for both users
              dispatch(
                updateCoAnchorUserDualPkWinInPusher({
                  rounds: payload?.message?.co_anchor_pk?.rounds,
                })
              );

              setTimeout(() => {
                dispatch(updateCoAnchorStartPassthrough());
                dispatch(updateLiveViewPusherUnsubscribe({}));

                setTimeout(() => {
                  dispatch(updateLiveViewPusherSubscribe({}));
                }, 2000);
              }, 1000);
              break;
            case pusherConst.liveViewPusher.payloadType.coAnchorEnd:
              if (showLog) {
                console.log("LIVESTREAM PUSHER: CO ANCHOR END");
              }
              // Show loading overlay
              dispatch(updateLivestreamLoading(true));

              // Set Co Anchor as false
              dispatch(updateIsCoAnchor(false));

              // Mute video player
              dispatch(updateVideoPlayerMute(true));

              // Reset co-anchor channel ID and navigate to main channel
              dispatch(updateChannelId(readOnlyChannelId));

              dispatch(resetCoAnchorUser1());
              dispatch(resetCoAnchorUser2());
              dispatch(updateCoAnchorSelectedUser(null));

              dispatch(resetDualPkFunctions());

              setTimeout(() => {
                dispatch(updateCoAnchorEndPassthrough());
                dispatch(updateLiveViewPusherUnsubscribe({}));

                setTimeout(() => {
                  dispatch(updateLiveViewPusherSubscribe({}));
                }, 2000);
              }, 1000);
              break;
            case pusherConst.liveViewPusher.payloadType.coAnchorPkStart:
              if (showLog) {
                console.log("LIVESTREAM PUSHER: CO ANCHOR PK START");
              }

              // Update PK state
              dispatch(updateIsDualPk(true));
              dispatch(resetCoAnchorUser1DualPkDiamonds());
              dispatch(resetCoAnchorUser2DualPkDiamonds());
              dispatch(updateDualPkEnded(false));
              dispatch(updateDualPkEndResultDialog(false));

              // Calculate win count for both users
              dispatch(
                updateCoAnchorUserDualPkWinInPusher({
                  rounds: payload?.message?.co_anchor_pk?.rounds,
                })
              );
              break;
            case pusherConst.liveViewPusher.payloadType.coAnchorPkRoundStart:
              if (showLog) {
                console.log("LIVESTREAM PUSHER: CO ANCHOR PK ROUND START");
              }

              // Update PK state
              dispatch(updateIsDualPk(true));
              dispatch(resetCoAnchorUser1DualPkDiamonds());
              dispatch(resetCoAnchorUser2DualPkDiamonds());

              // Update PK Data
              dispatch(
                updateDualPkData({
                  id: payload?.message?.co_anchor_pk?.id,
                  currentRound: payload?.message?.round,
                  rounds: payload?.message?.co_anchor_pk?.rounds,
                  finalWinner: payload?.message?.co_anchor_pk?.final_winner,
                })
              );

              // Calculate win count for both users
              dispatch(
                updateCoAnchorUserDualPkWinInPusher({
                  rounds: payload?.message?.co_anchor_pk?.rounds,
                })
              );
              break;
            case pusherConst.liveViewPusher.payloadType.coAnchorPkRoundEnd:
              if (showLog) {
                console.log("LIVESTREAM PUSHER: CO ANCHOR PK ROUND END");
              }

              // Update PK Data
              dispatch(
                updateDualPkData({
                  id: payload?.message?.co_anchor_pk?.id,
                  currentRound: payload?.message?.round,
                  rounds: payload?.message?.co_anchor_pk?.rounds,
                  finalWinner: payload?.message?.co_anchor_pk?.final_winner,
                })
              );

              // Calculate diamonds for both users
              setTimeout(() => {
                dispatch(updateDualPkSyncScorePassthrough());
              }, 1000);

              // Calculate win count for both users
              dispatch(
                updateCoAnchorUserDualPkWinInPusher({
                  rounds: payload?.message?.co_anchor_pk?.rounds,
                })
              );

              // Show graphic overlay
              dispatch(updateDualPkShowResultsOverlay(true));
              break;
            case pusherConst.liveViewPusher.payloadType.coAnchorPkEnd:
              if (showLog) {
                console.log("LIVESTREAM PUSHER: CO ANCHOR PK END");
              }

              // Update PK Data
              dispatch(
                updateDualPkData({
                  id: payload?.message?.co_anchor_pk?.id,
                  currentRound: payload?.message?.co_anchor_pk?.current_round,
                  rounds: payload?.message?.co_anchor_pk?.rounds,
                  finalWinner: payload?.message?.co_anchor_pk?.final_winner,
                })
              );

              // Calculate win count for both users
              dispatch(
                updateCoAnchorUserDualPkWinInPusher({
                  rounds: payload?.message?.co_anchor_pk?.rounds,
                })
              );

              // Update Pk State
              dispatch(updateDualPkEnded(true));

              // Show graphic overlay
              dispatch(updateDualPkShowResultsOverlay(true));
              break;
            default:
              break;
          }
        }
      }
    );
  }, [publicChannel]);

  // Lifecycle | Check for update | Public Global Payload
  useEffect(() => {
    if (!publicChannelGlobal) return;

    // Event Listener | State
    publicChannelGlobal?.bind("pusher:subscription_succeeded", () => {
      // console.log("Subscribed to channel");
      dispatch(updateLiveViewPublicChannelGlobalState(true));
    });
    publicChannelGlobal?.bind("pusher:subscription_error", (error) => {
      // console.log("Error subscribing to channel: ", error);
      dispatch(updateLiveViewPublicChannelGlobalState(false));
    });

    // Event Listener | Response
    publicChannelGlobal.bind(
      pusherConst.liveViewPusher.channel.generalStreamGlobalBind,
      (payload) => {
        if (showLog) {
          console.log("general-message", payload);
        }

        if (payload?.message?.type) {
          switch (payload?.message?.type) {
            case pusherConst.liveViewPusher.payloadType.pk:
              if (showLog) {
                console.log("LIVESTREAM PUSHER: PK");
              }

              // Update PK state
              dispatch(updateContestant1(payload?.message?.data?.users[0]));
              dispatch(updateContestant2(payload?.message?.data?.users[1]));
              dispatch(
                updateCurrentRound(payload?.message?.data?.current_round)
              );
              dispatch(updatePkWinner(payload?.message?.data?.final_winner));

              // Settings interval not possible in pusher, so we need to pass it to the component
              dispatch(
                updatePkIntervalPassthrough({
                  startEpoch: payload?.message?.data?.start_at_epoch,
                  endEpoch: payload?.message?.data?.end_at_epoch,
                })
              );
              break;
            default:
              break;
          }
        }
      }
    );
  }, [publicChannelGlobal]);

  // Lifecycle | Check for update | Channel Trigger
  useEffect(() => {
    if (!liveViewPusherTrigger || !channel) return;

    channel.trigger(
      pusherConst.liveViewPusher.channel.privateStreamClientChatBind,
      liveViewPusherTrigger
    );
  }, [liveViewPusherTrigger]);

  // Lifecycle | Check for update | Reset Pusher
  useEffect(() => {
    if (resetIsMounted.current) {
      if (!liveViewPusherReset) return;

      if (pusherInstance) {
        // Disconnect Pusher and its channels
        pusherInstance.disconnect();
        setPusherInstance(null);

        // Update State
        dispatch(updateLiveViewChannelState(false));
        dispatch(updateLiveViewPublicChannelState(false));
        dispatch(updateLiveViewPublicChannelGlobalState(false));
      } else {
        const toastObj = {
          message: "Live View Pusher Instance is unavailable",
          autoClose: 3000,
        };
        dispatch(updateErrorToast(toastObj));
      }
    } else {
      resetIsMounted.current = true;
    }
  }, [liveViewPusherReset]);

  // Lifecycle | Check for update | Destroy Pusher
  useEffect(() => {
    if (destroyIsMounted.current) {
      if (!liveViewPusherDestroy) return;

      if (pusherInstance) {
        pusherInstance.disconnect();
        setPusherInstance(null);

        // Update State
        dispatch(updateLiveViewChannelState(false));
        dispatch(updateLiveViewPublicChannelState(false));
        dispatch(updateLiveViewPublicChannelGlobalState(false));
      }
    } else {
      destroyIsMounted.current = true;
    }
  }, [liveViewPusherDestroy]);

  return <div id="live-view-pusher-shadow-component"></div>;
};

export default LiveViewPusher;
