// General
import "./my-wallet-dialog.scss";
import { useEffect, forwardRef } from "react";
// Static Data
import utilityConst from "../../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateMyWalletInfoDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide, Button } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";

const MyWalletDialog = () => {
  // Constant variables
  const contentList = utilityConst.walletInfoList;

  // Redux variables
  const myWalletInfoDialog = useSelector(
    (state) => state.dialog.myWalletInfoDialog
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!myWalletInfoDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-MyWallet-Dialog",
      },
    });
  }, [myWalletInfoDialog]);

  // Event Handlers | Button
  const onCloseDialog = () => {
    dispatch(updateMyWalletInfoDialog(false));
  };

  return (
    <Dialog
      className="custom-bottom-sheet-dialog"
      fullScreen
      open={myWalletInfoDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      <div id="my-wallet-dialog">
        <div className="padding-container">
          <div className="header">{t("profile.my_wallet")}</div>

          {contentList?.map((content, index) => (
            <div className="content-body" key={index}>
              <div className="icon-container">
                <img className="icon" src={content?.icon} alt="icon" />
              </div>

              <div className="description-container">
                <div className="subheader">{t(content?.title)}</div>

                <div className="text">{t(content?.description)}</div>
              </div>
            </div>
          ))}

          <Button
            className="close-button"
            variant="text"
            onClick={onCloseDialog}
          >
            {t("common.got_it_thanks")}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default MyWalletDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
