// General
import "./tier-tab.scss";
import { useEffect, useRef } from "react";
// Services
import { useLazyGetLivestreamingContestLeaderboardQuery } from "../../../../services/data.service";
// Static Data
import leaderboardConst from "../../../../const/leaderboardConst";
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  // Livestreaming Leaderboard Functions
  updateLivestreamingTopStreamersTier1Event,
  updateLivestreamingTopStreamersTier2Event,
  updateLivestreamingTopStreamersTier3Event,
  resetLivestreamingTopStreamersTier1Event,
  resetLivestreamingTopStreamersTier2Event,
  resetLivestreamingTopStreamersTier3Event,
} from "../../../../redux/store/leaderboardStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import Banner from "../../elements/banner/banner";
import LeaderboardSection from "../leaderboard-section/leaderboard-section";

const TierTab = (props) => {
  const { requireInfoIcon, infoType, tier, userType, seeAllButtonType } = props;

  // API variables
  const [
    getLivestreamingContestLeaderboardTopStreamers,
    {
      data: getLivestreamingContestLeaderboardTopStreamersData,
      error: getLivestreamingContestLeaderboardTopStreamersErrorData,
      isFetching: getLivestreamingContestLeaderboardTopStreamersFetching,
      isLoading: getLivestreamingContestLeaderboardTopStreamersLoading,
      isSuccess: getLivestreamingContestLeaderboardTopStreamersSuccess,
      isError: getLivestreamingContestLeaderboardTopStreamersError,
    },
  ] = useLazyGetLivestreamingContestLeaderboardQuery();

  // General variables
  const isMounted = useRef(false);

  // Redux variables
  const livestreamingTopStreamersTier1Event = useSelector(
    (state) => state.leaderboard.livestreamingTopStreamersTier1Event
  );
  const livestreamingTopStreamersTier2Event = useSelector(
    (state) => state.leaderboard.livestreamingTopStreamersTier2Event
  );
  const livestreamingTopStreamersTier3Event = useSelector(
    (state) => state.leaderboard.livestreamingTopStreamersTier3Event
  );
  const livestreamingContestId = useSelector(
    (state) => state.leaderboard.livestreamingContestId
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Utility variables
  let topStreamersParam = {
    category: "live_contest",
    board_type: "top_streamers",
    tier: 1,
    contest_id: livestreamingContestId,
  };

  // Custom Hooks
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!livestreamingContestId) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-19-19.1.6",
      },
    });

    onCallApi(false);
  }, []);

  // Lifecycle | Check for update | Livestreaming Contest Leaderboard API Response
  useEffect(() => {
    if (
      getLivestreamingContestLeaderboardTopStreamersFetching ||
      getLivestreamingContestLeaderboardTopStreamersLoading
    ) {
    } else if (getLivestreamingContestLeaderboardTopStreamersSuccess) {
      if (
        getLivestreamingContestLeaderboardTopStreamersData?.status === 1 &&
        getLivestreamingContestLeaderboardTopStreamersData?.data?.top_streamers
          ?.results?.length > 0
      ) {
        if (tier === 1) {
          dispatch(resetLivestreamingTopStreamersTier1Event());
          dispatch(
            updateLivestreamingTopStreamersTier1Event(
              getLivestreamingContestLeaderboardTopStreamersData?.data
                ?.top_streamers?.results
            )
          );
        } else if (tier === 2) {
          dispatch(resetLivestreamingTopStreamersTier2Event());
          dispatch(
            updateLivestreamingTopStreamersTier2Event(
              getLivestreamingContestLeaderboardTopStreamersData?.data
                ?.top_streamers?.results
            )
          );
        } else if (tier === 3) {
          dispatch(resetLivestreamingTopStreamersTier3Event());
          dispatch(
            updateLivestreamingTopStreamersTier3Event(
              getLivestreamingContestLeaderboardTopStreamersData?.data
                ?.top_streamers?.results
            )
          );
        }
      }
    } else if (getLivestreamingContestLeaderboardTopStreamersError) {
      if (
        getLivestreamingContestLeaderboardTopStreamersErrorData?.status === 401
      ) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getLivestreamingContestLeaderboardTopStreamersFetching,
    getLivestreamingContestLeaderboardTopStreamersLoading,
    getLivestreamingContestLeaderboardTopStreamersSuccess,
    getLivestreamingContestLeaderboardTopStreamersError,
  ]);

  // Lifecycle | Check for update | leaderboardPullToRefreshPassthrough
  useEffect(() => {
    if (isMounted.current) {
      if (!livestreamingContestId) return;

      onCallApi(false);
    } else {
      isMounted.current = true;
    }
  }, [livestreamingContestId]);

  // Utility Functions
  const onCallApi = (getFromCache) => {
    getLivestreamingContestLeaderboardTopStreamers(
      `?cat=${topStreamersParam.category}&board_type=${topStreamersParam.board_type}&tier=${tier}&contest_id=${topStreamersParam.contest_id}`,
      getFromCache
    );
  };

  return (
    <div id="tier-tab-shared-subcomponent">
      <Banner type={`leaderboard-livestreaming-tier-${tier}`} view={"stack"} />

      {/* Livestreaming | Top Streamer */}
      <LeaderboardSection
        type={leaderboardConst.type.livestreaming}
        recurrence={leaderboardConst.tabs.events[`tier${tier}`]}
        isLoading={
          ((tier === 1
            ? !livestreamingTopStreamersTier1Event?.length
            : tier === 2
            ? !livestreamingTopStreamersTier2Event?.length
            : !livestreamingTopStreamersTier3Event?.length) &&
            getLivestreamingContestLeaderboardTopStreamersFetching) ||
          getLivestreamingContestLeaderboardTopStreamersLoading
        }
        title={`${t("leaderboard.top_streamers")} - ${t("leaderboard.tier_n", {
          n: tier,
        })}`}
        requireInfoIcon={requireInfoIcon}
        infoType={infoType}
        userList={
          tier === 1
            ? livestreamingTopStreamersTier1Event
            : tier === 2
            ? livestreamingTopStreamersTier2Event
            : livestreamingTopStreamersTier3Event
        }
        userType={userType}
        seeAllButtonType={seeAllButtonType}
      />
    </div>
  );
};

export default TierTab;
