// General
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // General variables
  interestTab: 0,

  // Viewed Me variables
  viewedMeLists: [],
  viewedMePagination: null,

  // My Favorites variables
  myFavoritesLists: [],
  myFavoritesPagination: null,

  // Favorited Me variables
  favoritedMeLists: [],
  favoritedMePagination: null,

  // Photo Permission variables
  photoPermissionLists: [],
  photoPermissionPagination: null,

  // Scroll variables
  interestScrollYPosition: 0,
  interestScrollToTopPassthrough: {},
};

export const interestSlice = createSlice({
  name: "interest",
  initialState,
  reducers: {
    // General Functions
    updateInterestTab: (state, action) => {
      state.interestTab = action.payload;
    },

    // Viewed Me Functions
    updateViewedMeLists: (state, action) => {
      const viewedMeListsData = [...state.viewedMeLists, ...action.payload];
      state.viewedMeLists = viewedMeListsData;
    },
    updateViewedMePagination: (state, action) => {
      state.viewedMePagination = action.payload;
    },
    resetViewedMeLists: (state) => {
      state.viewedMeLists = [];
      state.viewedMePagination = null;
    },

    // My Favorites Functions
    updateMyFavoritesLists: (state, action) => {
      const myFavoritesListsData = [
        ...state.myFavoritesLists,
        ...action.payload,
      ];
      state.myFavoritesLists = myFavoritesListsData;
    },
    updateMyFavoritesPagination: (state, action) => {
      state.myFavoritesPagination = action.payload;
    },
    resetMyFavoritesLists: (state) => {
      state.myFavoritesLists = [];
      state.myFavoritesPagination = null;
    },

    // Favorited Me Functions
    updateFavoritedMeLists: (state, action) => {
      const favoritedMeListsData = [
        ...state.favoritedMeLists,
        ...action.payload,
      ];
      state.favoritedMeLists = favoritedMeListsData;
    },
    updateFavoritedMePagination: (state, action) => {
      state.favoritedMePagination = action.payload;
    },
    resetFavoritedMeLists: (state) => {
      state.favoritedMeLists = [];
      state.favoritedMePagination = null;
    },

    // Photo Permission Functions
    updatePhotoPermissionLists: (state, action) => {
      const photoPermissionListsData = [
        ...state.photoPermissionLists,
        ...action.payload,
      ];
      state.photoPermissionLists = photoPermissionListsData;
    },
    updateLocalPhotoPermissionList: (state, action) => {
      const idInt = action.payload.idInt;
      const approvedMyPrivatePhoto = action.payload.approvedMyPrivatePhoto;

      let photoPermissionListArr = [...state.photoPermissionLists];

      for (let i = 0; i < photoPermissionListArr.length; i++) {
        if (photoPermissionListArr[i].user.id_int === idInt) {
          photoPermissionListArr[i].granted = approvedMyPrivatePhoto;
          break;
        }
      }

      state.photoPermissionLists = photoPermissionListArr;
    },
    updatePhotoPermissionPagination: (state, action) => {
      state.photoPermissionPagination = action.payload;
    },
    resetPhotoPermissionLists: (state) => {
      state.photoPermissionLists = [];
      state.photoPermissionPagination = null;
    },

    // Scroll Functions
    updateInterestScrollYPosition: (state, action) => {
      state.interestScrollYPosition = action.payload;
    },
    updateInterestScrollToTopPassthrough: (state, action) => {
      state.interestScrollToTopPassthrough = action.payload;
    },

    // Utility Functions
    resetInterestStore: () => {
      return { ...initialState };
    },
  },
});

export const {
  // General Functions
  updateInterestTab,

  // My Favorites Functions
  updateViewedMeLists,
  updateViewedMePagination,
  resetViewedMeLists,

  // My Favorites Functions
  updateMyFavoritesLists,
  updateMyFavoritesPagination,
  resetMyFavoritesLists,

  // Favorited Me Functions
  updateFavoritedMeLists,
  updateFavoritedMePagination,
  resetFavoritedMeLists,

  // Photo Permission Functions
  updatePhotoPermissionLists,
  updateLocalPhotoPermissionList,
  updatePhotoPermissionPagination,
  resetPhotoPermissionLists,

  // Scroll Functions
  updateInterestScrollYPosition,
  updateInterestScrollToTopPassthrough,

  // Utility Functions
  resetInterestStore,
} = interestSlice.actions;

export default interestSlice.reducer;
