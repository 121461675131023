// General
import "./livestream-memory-leak-alert.scss";
// Redux
import { useSelector } from "react-redux";
// Material UI
import AutorenewIcon from "@mui/icons-material/Autorenew";
// i18next
import { useTranslation } from "react-i18next";

const LivestreamMemoryLeakAlert = () => {
  // Redux variables
  const isTcPlayerMemoryLeak = useSelector(
    (state) => state.livestreaming.isTcPlayerMemoryLeak
  );

  // i18next variables
  const { t } = useTranslation();

  // Event Handlers | Button
  const onRefreshApp = () => {
    window.location.reload(true);
  };

  if (isTcPlayerMemoryLeak) {
    return (
      <div id="livestream-memory-leak-alert-component">
        <div className="padding-container">
          <div className="refresh-logo-container">
            <div className="refresh-outer-circle">
              <AutorenewIcon className="refresh-logo" />
            </div>
          </div>

          <div className="refresh-description-container">
            <div className="update-available-label">
              {t("misc.a_little_hiccup_on_our_end")}
            </div>

            <div className="click-to-update-label">
              {t("misc.refresh_and_try_again")}
            </div>
          </div>

          <div className="update-button-container" onClick={onRefreshApp}>
            <div className="update-button">{t("common.refresh")}</div>
          </div>
        </div>
      </div>
    );
  }
};

export default LivestreamMemoryLeakAlert;
