// General
import "./profile-stats.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetUserAioQuery,
  useLazyGetStreamerCenterQuery,
} from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector } from "react-redux";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Divider, Skeleton } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";

const ProfileStats = () => {
  // API variables
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();
  const [
    getStreamerCenter,
    {
      data: getStreamerCenterData,
      error: getStreamerCenterErrorData,
      isFetching: getStreamerCenterFetching,
      isLoading: getStreamerCenterLoading,
      isSuccess: getStreamerCenterSuccess,
      isError: getStreamerCenterError,
    },
  ] = useLazyGetStreamerCenterQuery();

  // Redux variables
  const isDaddy = useSelector((state) => state.user.isDaddy);

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    getUserAio(null, true);
    getStreamerCenter(null, true);
  }, []);

  // Event Handlers | Button
  const onShowAllFollowers = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-ProfileStats-Subcomponent-Followers-Button",
      },
    });

    onNavigate(routeConst.profile.streamerCenter.follows.path);
  };

  if (
    getUserAioFetching ||
    getUserAioLoading ||
    getStreamerCenterFetching ||
    getStreamerCenterLoading
  ) {
    return (
      <div id="overview-profile-stats-subcomponent">
        <div className="followers-container">
          <Skeleton className="skeleton-follower-label" variant="text" />

          <div className="followers-label">
            {getStreamerCenterData?.data?.followers > 1
              ? t("common.followers")
              : t("common.follower")}
          </div>
        </div>

        <Divider orientation="vertical" flexItem />

        <div className="following-container">
          <Skeleton className="skeleton-follower-label" variant="text" />

          <div className="following-label">{t("common.following")}</div>
        </div>

        {!isDaddy && <Divider orientation="vertical" flexItem />}

        {!isDaddy && (
          <div className="gifters-container">
            <Skeleton className="skeleton-follower-label" variant="text" />

            <div className="gifters-label">
              {getStreamerCenterData?.data?.gifters > 0
                ? t("common.gifter")
                : t("common.gifter")}
            </div>
          </div>
        )}
      </div>
    );
  } else if (getUserAioSuccess || getStreamerCenterSuccess) {
    if (getUserAioData?.data?.modules?.follow_button_enabled) {
      return (
        <div
          id="overview-profile-stats-subcomponent"
          onClick={onShowAllFollowers}
        >
          <div className="followers-container">
            <div className="followers">
              {getStreamerCenterData?.data?.followers || 0}
            </div>
            <div className="followers-label">
              {getStreamerCenterData?.data?.followers > 1
                ? t("common.followers")
                : t("common.follower")}
            </div>
          </div>

          <Divider orientation="vertical" flexItem />

          <div className="following-container">
            <div className="following">
              {getStreamerCenterData?.data?.following || 0}
            </div>
            <div className="following-label">{t("common.following")}</div>
          </div>

          {!isDaddy && <Divider orientation="vertical" flexItem />}

          {!isDaddy && (
            <div className="gifters-container">
              <div className="gifters">
                {getStreamerCenterData?.data?.gifters || 0}
              </div>
              <div className="gifters-label">
                {getStreamerCenterData?.data?.gifters > 0
                  ? t("common.gifter")
                  : t("common.gifter")}
              </div>
            </div>
          )}
        </div>
      );
    }
  } else if (getUserAioError || getStreamerCenterError) {
    if (getStreamerCenterErrorData?.status === 401) {
      onNavigate(routeConst.logout.path);
    }
  }
};

export default ProfileStats;
