// General
import "./restart-phone-description-dialog.scss";
import { useEffect, forwardRef } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateRestartPhoneDescriptionDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation } from "react-i18next";

const RestartPhoneDescriptionDialog = () => {
  // Redux variables
  const restartPhoneDescriptionDialog = useSelector(
    (state) => state.dialog.restartPhoneDescriptionDialog
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!restartPhoneDescriptionDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-RestartPhoneDescription-Dialog",
      },
    });
  }, [restartPhoneDescriptionDialog]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateRestartPhoneDescriptionDialog(false));
  };

  return (
    <Dialog
      className="custom-fullscreen-transparent-dialog"
      fullScreen
      open={restartPhoneDescriptionDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      <div id="restart-phone-description-dialog">
        <div className="dialog-header">
          <div className="close-button-container">
            <CloseIcon className="close-button" onClick={onCloseDialog} />
          </div>
        </div>

        <div className="padding-container">
          <div className="restart-phone-title">
            {t("1on1.restart_phone_title")}
          </div>

          <div className="restart-phone-description">
            {t("1on1.restart_phone_desc")}
          </div>

          <div className="button-container">
            <div className="understand-button" onClick={onCloseDialog}>
              {t("common.i_understand")}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default RestartPhoneDescriptionDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
