// General
import "./clear-messages-dialog.scss";
import { useEffect } from "react";
// Services
import { useClearConversationMutation } from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateClearMessagesDialog } from "../../../../redux/store/dialogStore";
import { resetConversationLists } from "../../../../redux/store/inboxStore";
import { updateSuccessToast } from "../../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog } from "@mui/material";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const ClearMessagesDialog = () => {
  // API variables
  const [
    clearConversation,
    {
      data: clearConversationData,
      error: clearConversationErrorData,
      isLoading: clearConversationLoading,
      isSuccess: clearConversationSuccess,
      isError: clearConversationError,
    },
  ] = useClearConversationMutation();

  // Redux variables
  const clearMessagesDialog = useSelector(
    (state) => state.dialog.clearMessagesDialog
  );
  const conversationIdInt = useSelector(
    (state) => state.inbox.conversationIdInt
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!clearMessagesDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-ClearMessages-Dialog",
      },
    });
  }, [clearMessagesDialog]);

  // Lifecycle | Check for update | Clear Conversation API Response
  useEffect(() => {
    if (clearConversationLoading) {
    } else if (clearConversationSuccess) {
      if (clearConversationData?.status === 0) {
        const toastObj = {
          message: clearConversationData?.payload?.message,
          autoClose: 3000,
        };
        dispatch(updateSuccessToast(toastObj));

        dispatch(resetConversationLists());
      }
    } else if (clearConversationError) {
      if (clearConversationErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    clearConversationLoading,
    clearConversationSuccess,
    clearConversationError,
  ]);

  // Event Handlers | Button
  const onClearMessages = () => {
    clearConversation({ conversationid: conversationIdInt });
    dispatch(updateClearMessagesDialog(false));
  };

  const onCloseDialog = () => {
    dispatch(updateClearMessagesDialog(false));
  };

  return (
    <Dialog
      className="custom-radius10-dialog"
      open={clearMessagesDialog}
      onClose={onCloseDialog}
    >
      <div id="clear-messages-dialog">
        <div className="padding-container">
          <div className="delete-message-icon-container">
            {getIcon("deleteIcon", "delete-message-icon")}
          </div>

          <div className="text">{t("inbox.conversation_clear_confirm")}</div>
          <div className="text">
            <Trans
              className="text"
              i18nKey="inbox.conversation_clear_confirm_desc"
              components={{ br: <br /> }}
            />
          </div>

          <div className="button-container">
            <div className="confirm-button" onClick={onClearMessages}>
              {t("common.confirm")}
            </div>
            <div className="cancel-button" onClick={onCloseDialog}>
              {t("common.cancel")}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ClearMessagesDialog;
