// General
import "./report-dialog.scss";
import { forwardRef } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateReportDialog } from "../../../../redux/store/dialogStore";
// Material UI
import { Dialog, Slide, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation } from "react-i18next";
// Components
import Report from "../../../report/report";

const ReportDialog = () => {
  // Redux variables
  const reportDialog = useSelector((state) => state.dialog.reportDialog);
  const username = useSelector((state) => state.userCard.username);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateReportDialog(false));
  };

  return (
    <Dialog
      className={
        isMobile
          ? "custom-top-radius20-dialog custom-dialog-margin-top"
          : "custom-radius35-dialog"
      }
      fullScreen={isMobile ? true : false}
      open={reportDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      <div id="report-dialog" className={isMobile ? "" : "max-width-container"}>
        <div className="dialog-header">
          <div className="close-button-container">
            <CloseIcon className="close-button" onClick={onCloseDialog} />
          </div>

          <div className="title">
            {t("profile.report")} {username}
          </div>

          <div className="blank-container"></div>
        </div>
        <Report />
      </div>
    </Dialog>
  );
};

export default ReportDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
