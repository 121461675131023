// General
import "./go-private-button.scss";
import { useEffect } from "react";
// Services
import { useLazyGetUserAioQuery } from "../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateIsPrivateStandbyMode } from "../../../../redux/store/privateCallStore";
import { updateGoPrivateDontSwitchAppDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const GoPrivateButton = () => {
  // API variables
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();

  // Redux variables
  const isLoggedIn = useSelector((state) => state.public.isLoggedIn);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!isLoggedIn) return;

    getUserAio(null, true);
  }, [isLoggedIn]);

  // Event Handlers | MUI Dialog
  const onOpenDialog = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-46-46.3-Button",
      },
    });

    dispatch(updateIsPrivateStandbyMode(true));
    dispatch(updateGoPrivateDontSwitchAppDialog(true));
  };

  if (getUserAioData?.data?.private_calls?.user?.is_allowed_standby) {
    return (
      <div id="go-private-button-shared-subcomponent" onClick={onOpenDialog}>
        <div className="private-icon-container">
          {getIcon("privateStandbyIcon", "private-icon")}
        </div>
        <div className="label">{t("1on1.go_private")}</div>
      </div>
    );
  }
};

export default GoPrivateButton;
