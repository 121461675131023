// General
import "./alcohol-preference.scss";
// Static Data
import editProfileConst from "../../../../../const/editProfileConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateProfileCompletionState,
  updateUpdateProfileFullscreenTab,
  updateUpdateProfileDialogView,
} from "../../../../../redux/store/editProfileStore";
import {
  updateRestartRegistrationDialog,
  updateUpdateProfileFullscreenDialog,
} from "../../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { useMediaQuery } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hook
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";

const AlcoholPreference = () => {
  // Redux variables
  const drinking = useSelector((state) => state.editProfile.drinking);
  const alcoholPreference = useSelector(
    (state) => state.editProfile.alcoholPreference
  );
  const profileCompletionPercentage = useSelector(
    (state) => state.profile.profileCompletionPercentage
  );
  const profileRequirementPopup = useSelector(
    (state) => state.profile.profileRequirementPopup
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Event Handlers | Button
  const onEditSection = (section) => {
    TagManager.dataLayer({
      dataLayer: {
        event: `PWA-25-25.12`,
      },
    });

    if (profileCompletionPercentage < profileRequirementPopup) {
      dispatch(updateRestartRegistrationDialog(true));
    } else {
      // dispatch(
      //   updateProfileCompletionState({
      //     name: "personalInformation",
      //     slide: returnMaxPersonalInformation(),
      //   })
      // );

      switch (section) {
        case "alcoholPreference":
          dispatch(
            updateUpdateProfileDialogView(
              editProfileConst.profileDialogView.alcoholPreference
            )
          );
          // dispatch(updateUpdateProfileFullscreenTab(6));
          break;
        default:
          break;
      }

      dispatch(updateUpdateProfileFullscreenDialog(true));
    }
  };

  // Utility Functions
  const returnMaxPersonalInformation = () => {
    return 7;
  };
  const getAlcoholIcon = (alcoholId) => {
    switch (alcoholId) {
      case 70:
        return getIcon("redWineIcon", "alcohol-icon");
      case 71:
        return getIcon("whiteWineIcon", "alcohol-icon");
      case 72:
        return getIcon("champagneIcon", "alcohol-icon");
      case 73:
        return getIcon("cognacIcon", "alcohol-icon");
      case 74:
        return getIcon("tequilaIcon", "alcohol-icon");
      case 75:
        return getIcon("beerIcon", "alcohol-icon");
      case 76:
        return getIcon("cocktailIcon", "alcohol-icon");
      case 77:
        return getIcon("vodkaIcon", "alcohol-icon");
      case 78:
        return getIcon("brandyIcon", "alcohol-icon");
      case 79:
        return getIcon("highballIcon", "alcohol-icon");
      case 80:
        return getIcon("whiskeyIcon", "whiskey-icon");
      case 81:
        return getIcon("liqueurIcon", "alcohol-icon");
      case 82:
        return getIcon("absintheIcon", "alcohol-icon");
      case 83:
        return getIcon("rumIcon", "alcohol-icon");
      case 84:
        return getIcon("sangriaIcon", "alcohol-icon");
      case 85:
        return getIcon("ginIcon", "alcohol-icon");
      case 86:
        return getIcon("sakeIcon", "alcohol-icon");
      case 87:
        return getIcon("sojuIcon", "alcohol-icon");
      case 88:
        return getIcon("martiniIcon", "alcohol-icon");
      case 89:
        return getIcon("shotsIcon", "alcohol-icon");
    }
  };

  if (isMobile && drinking?.id !== 540) {
    return (
      <div id="mobile-edit-profile-alcohol-preference-subcomponent">
        <div className="alcohol-label-container">
          <div className="alcohol-label">
            {t("registration.common.drinking_preference")}
          </div>
        </div>

        <div
          className="alcohol-preference-list-container"
          onClick={() => onEditSection("alcoholPreference")}
        >
          <div className="left-container">
            {alcoholPreference?.length > 0 ? (
              alcoholPreference?.map((alcohol, index) => (
                <div className="alcohol-item-container" key={index}>
                  <div className="alcohol-icon-container">
                    {getAlcoholIcon(alcohol?.id)}
                  </div>
                  <div className="alcohol-item">{alcohol?.locale}</div>
                </div>
              ))
            ) : (
              <div className="to-be-completed-text-container">
                <AddRoundedIcon className="add-icon" />
                <div className="to-be-completed-text">
                  {t("profile.add_alcohol_preference")}
                </div>
              </div>
            )}
          </div>

          <div className="right-container">
            <ChevronRightIcon className="right-icon" />
          </div>
        </div>
      </div>
    );
  }
};

export default AlcoholPreference;
