// General
import "./description-signup.scss";
import { useState, useEffect } from "react";
// Services
import { useUpdateUserProfileMutation } from "../../../services/data.service";
import { removeHTMLEscape } from "../../../services/regex.service";
// Static Data
import routeConst from "../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateAboutMe,
  updateLookingForDescription,
} from "../../../redux/store/signupStore";
import { updateErrorToast } from "../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Sentry
import * as Sentry from "@sentry/react";
// Material UI
import { Box, TextField, Button } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../shared/elements/spinner/spinner";
import SignupStepper from "../../shared/elements/signup-stepper/signup-stepper";

const DescriptionSignup = () => {
  // API variables
  const [
    updateUserProfile,
    {
      data: updateUserProfileData,
      error: updateUserProfileErrorData,
      isLoading: updateUserProfileLoading,
      isSuccess: updateUserProfileSuccess,
      isError: updateUserProfileError,
    },
  ] = useUpdateUserProfileMutation();

  // General variables
  const [aboutMeErrorMessage, setAboutMeErrorMessage] = useState("");
  const [aboutMeFocus, setAboutMeFocus] = useState(false);
  const [aboutMeValid, setAboutMeValid] = useState(false);
  const [aboutMeError, setAboutMeError] = useState(false);
  const [lookingForErrorMessage, setLookingForErrorMessage] = useState("");
  const [lookingForFocus, setLookingForFocus] = useState(false);
  const [lookingForValid, setLookingForValid] = useState(false);
  const [lookingForError, setLookingForError] = useState(false);

  // Redux variables
  const aboutMe = useSelector((state) => state.signup.aboutMe);
  const lookingForDescription = useSelector(
    (state) => state.signup.lookingForDescription
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t, i18n } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Check for update | Update User Profile API Response
  useEffect(() => {
    if (updateUserProfileLoading) {
    } else if (updateUserProfileSuccess) {
      onNavigate(routeConst.lifestyleSignup.path);
    } else if (updateUserProfileError) {
      switch (updateUserProfileErrorData?.data?.status) {
        case -1:
          const errorToast = {
            message: updateUserProfileErrorData?.data?.message,
            autoClose: 3000,
          };
          dispatch(updateErrorToast(errorToast));
          break;
        default:
          break;
      }
    }
  }, [
    updateUserProfileLoading,
    updateUserProfileSuccess,
    updateUserProfileError,
  ]);

  // Event Handlers | Button
  const onSaveDetails = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-18-18.2-18.2.1-Save-Button",
      },
    });

    const obj = {
      aboutme: aboutMe,
      lookingfordetails: lookingForDescription,
    };
    updateUserProfile(obj);
  };
  const onSkip = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-18-18.2-18.2.2-Button",
      },
    });

    onNavigate(routeConst.lifestyleSignup.path);
  };

  // Event Handlers | MUI Text Field
  const onValueChange = (state) => (event) => {
    switch (state) {
      case "aboutMe":
        dispatch(updateAboutMe(event.target.value));
        break;
      case "lookingFor":
        dispatch(updateLookingForDescription(event.target.value));
        break;
      default:
        break;
    }
  };
  const onAboutMeFocus = () => {
    setAboutMeFocus(true);
  };
  const onLookingForFocus = () => {
    setLookingForFocus(true);
  };

  // Utility Functions
  const isFormValid = () => {
    let valid = false;
    if (aboutMeValid && lookingForValid) {
      valid = true;
    }

    return valid;
  };
  const aboutMeValidation = () => {
    let valid = true;
    let error = false;

    if (aboutMe === "") {
      setAboutMeErrorMessage(t("signup.about_me_is_required"));
      valid = false;
      error = true;
    } else if (removeHTMLEscape.test(aboutMe)) {
      valid = false;
      error = true;
      dispatch(updateAboutMe(""));

      // Send to Sentry
      Sentry.captureMessage("PWA-18-18.2-AboutMe-Input-HTML-Escape-Detected");

      // Send to GTM
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-18-18.2-AboutMe-Input-HTML-Escape-Detected",
        },
      });
    } else if (
      // Update to 10 when backend is ready
      i18n.language.toLowerCase().includes("zh-tw") &&
      aboutMe.length < 25
    ) {
      setAboutMeErrorMessage(
        t("signup.about_me_must_be_at_least_n_characters", { n: 25 })
      );
      valid = false;
      error = true;
    } else if (
      i18n.language.toLowerCase().includes("zh-tw") === false &&
      aboutMe.length < 25
    ) {
      setAboutMeErrorMessage(
        t("signup.about_me_must_be_at_least_n_characters", { n: 25 })
      );
      valid = false;
      error = true;
    } else if (aboutMe.length > 250) {
      setAboutMeErrorMessage(
        t("signup.about_me_must_be_less_than_n_characters", { n: 250 })
      );
      valid = false;
      error = true;
    } else {
      setAboutMeErrorMessage("");
      valid = true;
      error = false;
    }

    setAboutMeValid(valid);
    setAboutMeError(error);
  };
  const lookingForValidation = () => {
    let valid = true;
    let error = false;

    if (lookingForDescription === "") {
      setLookingForErrorMessage(t("signup.looking_for_is_required"));
      valid = false;
      error = true;
    } else if (removeHTMLEscape.test(lookingForDescription)) {
      valid = false;
      error = true;
      dispatch(updateLookingForDescription(""));

      // Send to Sentry
      Sentry.captureMessage(
        "PWA-18-18.2-LookingFor-Input-HTML-Escape-Detected"
      );

      // Send to GTM
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-18-18.2-LookingFor-Input-HTML-Escape-Detected",
        },
      });
    } else if (
      // Update to 10 when backend is ready
      i18n.language.toLowerCase("zh-tw") &&
      lookingForDescription.length < 25
    ) {
      setLookingForErrorMessage(
        t("signup.looking_for_must_be_at_least_n_characters", { n: 25 })
      );
      valid = false;
      error = true;
    } else if (
      i18n.language.toLowerCase().includes("zh-tw") === false &&
      lookingForDescription.length < 25
    ) {
      setLookingForErrorMessage(
        t("signup.looking_for_must_be_at_least_n_characters", { n: 25 })
      );
      valid = false;
      error = true;
    } else if (lookingForDescription.length > 250) {
      setLookingForErrorMessage(
        t("signup.looking_for_must_be_less_than_n_characters", { n: 250 })
      );
      valid = false;
      error = true;
    } else {
      setLookingForErrorMessage("");
      valid = true;
      error = false;
    }

    setLookingForValid(valid);
    setLookingForError(error);
  };

  // Check for about me and looking for validation
  useEffect(() => {
    aboutMeValidation();
    lookingForValidation();
  }, [
    aboutMe,
    aboutMeFocus,
    aboutMeErrorMessage,
    lookingForDescription,
    lookingForFocus,
    lookingForErrorMessage,
  ]);

  return (
    <Box id="description-signup-page">
      <div className="max-width-container">
        <div className="navbar-container">
          <div
            className="back-button"
            onClick={() => onNavigate(routeConst.torSignup.path)}
          >
            {getIcon("signupBackIcon", "signup-back-icon")}
          </div>
        </div>

        <div className="overflow-container">
          <div className="stepper-container">
            <div className="stepper-max-width-container">
              <div className="stepper-max-width-inner-container">
                <SignupStepper current={3} total={5} />
              </div>
            </div>
          </div>

          <div className="step-description-label">
            {t("signup.tell_us_about_yourself")}
          </div>

          <div className="description-signup-input-container">
            {/* About Me */}
            <div className="about-me-form-field-container">
              <div className="about-me-label">{t("profile.about_me")}</div>

              <div className="form-field-container">
                <TextField
                  className="about-me-form-field custom-text-field custom-text-field-15px-radius custom-text-field-outline-default custom-text-field-outline-background-color"
                  value={aboutMe}
                  onChange={onValueChange("aboutMe")}
                  multiline
                  rows={3}
                  variant="outlined"
                  onFocus={onAboutMeFocus}
                />
              </div>

              <div className="helper-text-container">
                <div className="error-text">
                  {aboutMeError ? aboutMeErrorMessage : ""}
                </div>

                <div className="character-limit-label">
                  {aboutMe?.length}/250
                </div>
              </div>
            </div>

            {/* Looking For */}
            <div className="looking-for-form-field-container">
              <div className="looking-for-label">
                {t("profile.looking_for")}
              </div>

              <div className="form-field-container">
                <TextField
                  className="looking-for-form-field custom-text-field custom-text-field-15px-radius custom-text-field-outline-default custom-text-field-outline-background-color"
                  value={lookingForDescription}
                  onChange={onValueChange("lookingFor")}
                  multiline
                  rows={3}
                  variant="outlined"
                  onFocus={onLookingForFocus}
                />
              </div>

              <div className="helper-text-container">
                <div className="error-text">
                  {lookingForError ? lookingForErrorMessage : ""}
                </div>

                <div className="character-limit-label">
                  {lookingForDescription?.length}/250
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom-container">
          <div className="button-container">
            <Button
              className={`next-button ${
                isFormValid() ? "" : "disabled-button"
              }`}
              variant="text"
              onClick={onSaveDetails}
              disabled={!isFormValid()}
            >
              {updateUserProfileLoading ? (
                <Spinner size={21} isPadding={false} color={"white-spinner"} />
              ) : (
                t("signup.next")
              )}
            </Button>

            <Button className="skip-button" onClick={onSkip}>
              {t("signup.skip_for_now")}
            </Button>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default DescriptionSignup;
