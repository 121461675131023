// General
import "./empty-list.scss";
// i18next
import { useTranslation } from "react-i18next";

const EmptyList = () => {
  // i18next variables
  const { t } = useTranslation();

  return (
    <div id="live-tab-empty-list-subcomponent">
      <div className="title">{t("top-fan.first_one_here_title")}</div>
    </div>
  );
};

export default EmptyList;
