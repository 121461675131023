// General
import "./redirect-handler.scss";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// Services
import {
  useAuthRefreshTokenMutation,
  useLazyVerifyTokenQuery,
} from "../../services/data.service";
import { sessionService } from "../../services/session.service";
// Static Data
import routeConst from "../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateApiToken,
  updateSpiToken,
  updateRefreshToken,
  updateIsLoggedIn,
  updateRedirectHandler,
} from "../../redux/store/publicStore";
import { updateEmail } from "../../redux/store/signinStore";
import { updateRedirectFromOldAppDialog } from "../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Custom Hooks
import useCustomNavigate from "../utility/custom-hooks/useCustomNavigate-hook";
// Components
import Spinner from "../shared/elements/spinner/spinner";

const RedirectHandler = () => {
  // API variables
  const [
    authRefreshToken,
    {
      data: authRefreshTokenData,
      error: authRefreshTokenErrorData,
      isLoading: authRefreshTokenLoading,
      isSuccess: authRefreshTokenSuccess,
      isError: authRefreshTokenError,
    },
  ] = useAuthRefreshTokenMutation();
  const [
    verifyToken,
    {
      data: verifyTokenData,
      error: verifyTokenErrorData,
      isFetching: verifyTokenFetching,
      isLoading: verifyTokenLoading,
      isSuccess: verifyTokenSuccess,
      isError: verifyTokenError,
    },
  ] = useLazyVerifyTokenQuery();

  // Redux variables
  const redirectHandler = useSelector((state) => state.public.redirectHandler);
  const dispatch = useDispatch();

  // Router variables
  const location = useLocation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!location) return;
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-31",
      },
    });

    const queryString = new URLSearchParams(location.search);

    let url = queryString.get("url");
    const fromCta = queryString.get("from_cta");
    const token = queryString.get("token");
    const showLanding = queryString.get("show_landing");
    const email = queryString.get("email");

    if (token) {
      dispatch(updateRefreshToken(token));
    }

    if (email) {
      dispatch(updateEmail(email));
    }

    if (showLanding === "true") {
      const hasShownNewAppOnboardingDialog =
        sessionService.getHasShownNewAppOnboardingDialog() || 0;

      if (hasShownNewAppOnboardingDialog < 3) {
        sessionService.setHasShownNewAppOnboardingDialog(
          hasShownNewAppOnboardingDialog + 1
        );
        dispatch(updateRedirectFromOldAppDialog(true));
      }
    }

    if (!url) {
      onNavigate(routeConst.search.path);
    } else {
      // Update url from API to match new route
      if (url.includes("coin_shop")) {
        url = "/coin-shop";
      } else if (url.includes("search")) {
        url = "/search";
      } else if (url.includes("landing")) {
        url = "/";
      }

      const obj = {
        url,
        fromCta,
        token,
      };
      dispatch(updateRedirectHandler(obj));

      // Use session service instead of redux because redux is not persistent if not redirected correctly
      // if (sessionService.getApiToken()) {
      //   onNavigate(url);
      // } else {

      // }

      // If user comes from old PWA, show landing
      if (showLanding === "true") {
        const hasShownNewAppOnboardingDialog =
          sessionService.getHasShownNewAppOnboardingDialog() || 0;

        if (hasShownNewAppOnboardingDialog < 3) {
          sessionService.setHasShownNewAppOnboardingDialog(
            hasShownNewAppOnboardingDialog + 1
          );
          dispatch(updateRedirectFromOldAppDialog(true));
        }
      }

      // Come from old PWA (m.sugarbook.com)
      if (fromCta === "m.sugarbook.com") {
        dispatch(updateApiToken(token));
        dispatch(updateIsLoggedIn(true));
        onNavigate(url);
      } else {
        setTimeout(() => {
          authRefreshToken();
          // verifyToken(`?token=${token}&from_cta=${fromCta}`);
        }, 1500);
      }
    }
  }, [location]);

  // Lifecycle | Refresh Token API Response
  useEffect(() => {
    if (authRefreshTokenLoading) {
    } else if (authRefreshTokenSuccess) {
      switch (authRefreshTokenData?.status) {
        case 0:
        case 1:
          dispatch(updateApiToken(authRefreshTokenData?.data?.legacy_token));
          dispatch(updateSpiToken(authRefreshTokenData?.data?.token));
          dispatch(updateIsLoggedIn(true));
          onNavigate(redirectHandler.url);
          break;
        default:
          break;
      }
    } else if (authRefreshTokenError) {
    }
  }, [authRefreshTokenLoading, authRefreshTokenSuccess, authRefreshTokenError]);

  // Lifecycle | Check for update | Verify Token API Response
  useEffect(() => {
    if (verifyTokenFetching || verifyTokenLoading) {
    } else if (verifyTokenSuccess) {
      if (verifyTokenData?.status === 0) {
        dispatch(updateApiToken(verifyTokenData?.data?.api_token));
        // dispatch(updateSpiToken(verifyTokenData?.data?.spi_token));
        dispatch(updateIsLoggedIn(true));
        onNavigate(redirectHandler.url);
      } else if (verifyTokenData?.status === -1) {
        onNavigate(routeConst.logout.path);
      }
    } else if (verifyTokenError) {
    }
  }, [
    verifyTokenFetching,
    verifyTokenLoading,
    verifyTokenSuccess,
    verifyTokenError,
  ]);

  return (
    <div id="redirect-page">
      <div className="padding-container">
        <Spinner type={"alt"} />
        <div className="redirect-label">Redirecting</div>
      </div>
    </div>
  );
};

export default RedirectHandler;
