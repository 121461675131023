// General
import "./logout.scss";
import { useEffect } from "react";
// Services
import { useLazyAuthSignoutQuery } from "../../services/data.service";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useLogout from "../utility/custom-hooks/useLogout-hook";
// Components
import Spinner from "../shared/elements/spinner/spinner";

const Logout = () => {
  // API variables
  const [
    signout,
    {
      data: signoutData,
      error: signoutErrorData,
      isLoading: signoutLoading,
      isSuccess: signoutSuccess,
      isError: signoutError,
    },
  ] = useLazyAuthSignoutQuery();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onLogout = useLogout();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-21",
      },
    });

    signout();
  }, []);

  // Lifecycle | Check for update | Auth Signout API Response
  useEffect(() => {
    if (signoutLoading) {
    } else if (signoutSuccess) {
      switch (signoutData?.status) {
        case 0:
        case -1:
        case -2:
          onLogout();
          break;
        default:
          break;
      }
    } else if (signoutError) {
      onLogout();
    }
  }, [signoutLoading, signoutSuccess, signoutError]);

  return (
    <div id="logout-page">
      <div className="padding-container">
        <Spinner type={"alt"} />
        <div className="logout-label">{t("logout.logging_you_out")}</div>
      </div>
    </div>
  );
};

export default Logout;
