// General
import "./theme-settings.scss";
// Static Data
import utilityConst from "../../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateMobileNavbarTheme,
  updateIconTheme,
} from "../../../../redux/store/appStore";
// Material UI
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";

const ThemeSettings = () => {
  // Redux variables
  const showHiddenSettings = useSelector(
    (state) => state.app.showHiddenSettings
  );
  const mobileNavbarTheme = useSelector((state) => state.app.mobileNavbarTheme);
  const iconTheme = useSelector((state) => state.app.iconTheme);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Event Handlers | MUI Toggle Button
  const onMobileNavbarThemeChange = (event, newAlignment) => {
    dispatch(updateMobileNavbarTheme(newAlignment));
  };
  const onIconThemeChange = (event, newAlignment) => {
    dispatch(updateIconTheme(newAlignment));
  };

  if (showHiddenSettings) {
    return (
      <div id="settings-theme-settings-subcomponent">
        <div className="padding-container">
          <div className="theme-label">{t("settings.theme")}</div>

          <div className="mobile-navbar-theme-container">
            <div className="mobile-navbar-label">Mobile Bottom Navbar</div>

            <div className="toggle-button-container">
              <ToggleButtonGroup
                color="primary"
                value={mobileNavbarTheme}
                exclusive
                onChange={onMobileNavbarThemeChange}
              >
                <ToggleButton
                  value={utilityConst.mobileNavbarTheme.default}
                  aria-label="left aligned"
                >
                  Sugarbook
                </ToggleButton>
                <ToggleButton
                  value={utilityConst.mobileNavbarTheme.octopus}
                  aria-label="centered"
                >
                  Octopus
                </ToggleButton>
                <ToggleButton value="stable" aria-label="right aligned">
                  Placeholder
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>

          <div className="icon-theme-container">
            <div className="icon-label">Icon</div>

            <div className="toggle-button-container">
              <ToggleButtonGroup
                color="primary"
                value={iconTheme}
                exclusive
                onChange={onIconThemeChange}
              >
                <ToggleButton
                  value={utilityConst.iconTheme.default}
                  aria-label="left aligned"
                >
                  Sugarbook
                </ToggleButton>
                <ToggleButton
                  value={utilityConst.iconTheme.material}
                  aria-label="centered"
                >
                  Material
                </ToggleButton>
                <ToggleButton value="stable" aria-label="right aligned">
                  Placeholder
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ThemeSettings;
