// General
import "./camera-feeds.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetPrivateCallRoomLeaveQuery,
  useLazyGetPrivateCallSummaryQuery,
} from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateCalleeCurrentSessionSummaryDialog } from "../../../../redux/store/dialogStore";
import {
  // General Functions
  updateShowVideoCallJoiningOverlay,
  updateShowVideoCallStreamInitializingOverlay,
  updateShowVideoCallEndingOverlay,
  resetCallDurationRemainingTimer,

  // TRTC Functions
  updateCallerUserSig,
  updateCallStatus,

  // Interval Functions
  updateIsStandbyKeepAliveIntervalPaused,
  updateIsRoomKeepAliveIntervalPaused,

  // Callee Current Video Call Summary Functions
  updateCalleeCurrentSummaryDuration,
  updateCalleeCurrentSummaryTotalDiamondsEarned,
  updateCalleeCurrentSummaryVideoCallDiamondsEarned,
  updateCalleeCurrentSummaryTipsDiamondsEarned,
  updateCalleeCurrentSummaryGiftsDiamondsEarned,
  updateCalleeCurrentSummaryActionsCompleted,
  updateCalleeCurrentSummaryRequestsFromDaddy,
  updateEndVideoCall,

  // Utility Functions
  updateNotifyEndVideoCallPassthrough,
} from "../../../../redux/store/privateCallStore";
// Tencent Real Time Communication (TRTC)
import TRTC from "trtc-js-sdk";
// Custom Hooks
import useIsMounted from "../../../utility/custom-hooks/useIsMounted-hook";
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import CalleeCameraFeed from "./callee-camera-feed-overlay/callee-camera-feed-overlay";
import CallerCameraFeed from "./caller-camera-feed/caller-camera-feed";
import VideoGradientOverlay from "../../../shared/video-call/video-gradient-overlay/video-gradient-overlay";

const CameraFeeds = () => {
  // API variables
  const [
    getPrivateCallRoomLeave,
    {
      data: getPrivateCallRoomLeaveData,
      error: getPrivateCallRoomLeaveErrorData,
      isFetching: getPrivateCallRoomLeaveFetching,
      isLoading: getPrivateCallRoomLeaveLoading,
      isSuccess: getPrivateCallRoomLeaveSuccess,
      isError: getPrivateCallRoomLeaveError,
    },
  ] = useLazyGetPrivateCallRoomLeaveQuery();
  const [
    getPrivateCallSummary,
    {
      data: getPrivateCallSummaryData,
      error: getPrivateCallSummaryErrorData,
      isFetching: getPrivateCallSummaryFetching,
      isLoading: getPrivateCallSummaryLoading,
      isSuccess: getPrivateCallSummarySuccess,
      isError: getPrivateCallSummaryError,
    },
  ] = useLazyGetPrivateCallSummaryQuery();

  // General variables
  const [trtcClient, setTrtcClient] = useState(null);

  // Redux variables
  const requestId = useSelector((state) => state.privateCall.requestId);
  const calleeId = useSelector((state) => state.privateCall.calleeId);
  const callerUserSig = useSelector((state) => state.privateCall.callerUserSig);
  const dispatch = useDispatch();

  // Custom Hooks Functions
  const mounted = useIsMounted();
  const onNavigate = useCustomNavigate();

  // Lifecycle | Unmounted
  useEffect(() => {
    return async () => {
      if (!mounted()) {
        if (trtcClient) {
          await trtcClient.leave().then(() => {
            trtcClient.destroy();
          });
        }
      }
    };
  }, [mounted]);

  // Lifecycle | Check for update | Private Call Leave API Response
  useEffect(() => {
    if (getPrivateCallRoomLeaveFetching || getPrivateCallRoomLeaveLoading) {
    } else if (getPrivateCallRoomLeaveSuccess) {
      dispatch(updateShowVideoCallEndingOverlay(false));
      getPrivateCallSummary(`?call_request_id=${requestId}`);
    } else if (getPrivateCallRoomLeaveError) {
      if (getPrivateCallRoomLeaveErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getPrivateCallRoomLeaveFetching,
    getPrivateCallRoomLeaveLoading,
    getPrivateCallRoomLeaveSuccess,
    getPrivateCallRoomLeaveError,
  ]);

  // Lifecycle | Check for update | Private Call Summart API Response
  useEffect(() => {
    if (getPrivateCallSummaryFetching || getPrivateCallSummaryLoading) {
    } else if (getPrivateCallSummarySuccess) {
      if (getPrivateCallSummaryData?.status === 0) {
        dispatch(
          updateCalleeCurrentSummaryDuration(
            getPrivateCallSummaryData?.data?.summary?.call_duration
          )
        );
        dispatch(
          updateCalleeCurrentSummaryTotalDiamondsEarned(
            getPrivateCallSummaryData?.data?.summary?.total_diamonds_earned
          )
        );
        dispatch(
          updateCalleeCurrentSummaryVideoCallDiamondsEarned(
            getPrivateCallSummaryData?.data?.summary?.call_charges_sum
          )
        );
        dispatch(
          updateCalleeCurrentSummaryTipsDiamondsEarned(
            getPrivateCallSummaryData?.data?.summary?.tippings_sum
          )
        );
        dispatch(
          updateCalleeCurrentSummaryGiftsDiamondsEarned(
            getPrivateCallSummaryData?.data?.summary?.giftings_sum
          )
        );
        dispatch(
          updateCalleeCurrentSummaryActionsCompleted(
            getPrivateCallSummaryData?.data?.summary
              ?.customized_tipping_count_accepted
          )
        );
        dispatch(
          updateCalleeCurrentSummaryRequestsFromDaddy(
            getPrivateCallSummaryData?.data?.summary?.customized_tipping_count +
              getPrivateCallSummaryData?.data?.summary?.tipping_count
          )
        );

        dispatch(updateCalleeCurrentSessionSummaryDialog(true));
      }
    } else if (getPrivateCallSummaryError) {
      if (getPrivateCallSummaryErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getPrivateCallSummaryFetching,
    getPrivateCallSummaryLoading,
    getPrivateCallSummarySuccess,
    getPrivateCallSummaryError,
  ]);

  // Lifecycle | Check for update | TRTC Client
  useEffect(() => {
    if (!trtcClient) return;

    trtcEventsListener();
  }, [trtcClient]);

  // Lifecycle | Check for update | calleeId & callerUserSig
  useEffect(() => {
    if (!calleeId || !callerUserSig) return;

    createVideoCallClient();
  }, [calleeId, callerUserSig]);

  // TRTC Functions
  const createVideoCallClient = () => {
    setTrtcClient(
      TRTC.createClient({
        mode: "rtc",
        sdkAppId: parseInt(process.env["REACT_APP_TRTC_SDK_APP_ID"]),
        userId: calleeId,
        userSig: callerUserSig,
      })
    );
  };
  const trtcEventsListener = () => {
    // trtcClient.on("error", (err) => {
    //   console.log(err);
    // });

    // trtcClient.on("client-banned", (err) => {
    //   console.log("TRTC Event Listener: CLIENT-BANNED: ", err);
    // });

    trtcClient.on("peer-join", (event) => {
      console.log("TRTC Event Listener: PEER-JOIN: ", event);

      // Close the current session summary dialog if its open
      dispatch(updateCalleeCurrentSessionSummaryDialog(false));

      dispatch(updateCallStatus("JOIN"));
      dispatch(updateIsStandbyKeepAliveIntervalPaused(true));
      dispatch(updateIsRoomKeepAliveIntervalPaused(false));
      dispatch(updateShowVideoCallJoiningOverlay(false));
      dispatch(updateShowVideoCallStreamInitializingOverlay(true));

      // Close video call ending overlay if its open
      dispatch(updateShowVideoCallEndingOverlay(false));
    });

    trtcClient.on("peer-leave", async (event) => {
      console.log("TRTC Event Listener: PEER-LEAVE: ", event);

      // Set Default
      dispatch(updateShowVideoCallStreamInitializingOverlay(false));

      dispatch(updateCallStatus("STANDBY"));

      // Reset callDurationRemaining
      dispatch(resetCallDurationRemainingTimer());

      // Toggle Keep Alive interval
      dispatch(updateIsStandbyKeepAliveIntervalPaused(false));
      dispatch(updateIsRoomKeepAliveIntervalPaused(true));

      // Reset callerUserSig
      dispatch(updateCallerUserSig(null));

      // Call Leave API when peer leave
      getPrivateCallRoomLeave({
        call_request_id: requestId,
        caller_cam_error: false,
      });

      await trtcClient.leave();
      trtcClient.destroy();

      setTrtcClient(null);
    });

    trtcClient.on("player-state-changed", (event) => {
      console.log("TRTC Event Listener: PLAYER-STATE-CHANGED: ", event);
    });

    // trtcClient.on("stream-added", (event) => {
    //   console.log("TRTC Event Listener: STREAM-ADDED: ", event);
    // });

    // trtcClient.on("stream-subscribed", (event) => {
    //   console.log("TRTC Event Listener: STREAM-SUBSCRIBED: ", event);
    // });

    // trtcClient.on("stream-removed", (event) => {
    //   console.log("TRTC Event Listener: STREAM-REMOVED: ", event);
    // });

    // trtcClient.on("stream-updated", (event) => {
    //   console.log("TRTC Event Listener: STREAM-UPDATED: ", event);
    // });
  };

  return (
    <div id="private-standby-camera-feeds-subcomponent">
      <CallerCameraFeed trtcClient={trtcClient} />
      <VideoGradientOverlay />
      <CalleeCameraFeed trtcClient={trtcClient} />
    </div>
  );
};

export default CameraFeeds;
