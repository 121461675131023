// General
import { createSlice } from "@reduxjs/toolkit";
// Static Data
import livestreamingConst from "../../const/livestreamingConst";

const initialState = {
  // Main Page variables
  livestreamChannelLists: [],
  livestreamChannelPagination: null,

  // General variables
  readOnlyChannelId: null, // This is the same as id from url params
  channelId: null, // This can be dynamic, currently used to accomodate Co Anchor and Dual PK
  livestreamerUserObj: null,
  livestreamerId: null,
  livestreamerIdInt: null,
  livestreamerProfilePhoto: null,
  livestreamerProfileDecorations: null,
  livestreamerAchievements: null,
  livestreamerUsername: null,
  livestreamerLevellingBadge: null,
  livestreamerFollowers: null,
  livestreamerViewers: null,
  livestreamerDiamonds: null,
  livestreamerIsFollowing: null,

  // Video Player variables
  pullChannel: null,
  livestreamLoading: true,
  livestreamBuffering: false,
  livestreamPaused: false, // Deprecated
  videoPlayerMute: false,
  hasTcPullstream: false,
  hasTcPlayerPlay: false,
  isTcPlayerMemoryLeak: false,

  // Mini Video Player variables
  isMiniPlayer: false,
  miniPlayerSource: null,
  miniPlayerChannelId: null,

  // Chat variables
  livestreamChatMessages: [],

  // Emoji variables
  livestreamFloatingEmoji: null,

  // Gift Animation variables
  livestreamGiftAnimation: null,

  // VIP Entrance variables
  livestreamVipEntrance: null,

  // Live Viewers variables
  liveViewers: [],

  // Top Fans variables
  topFansDaily: [],
  topFansWeekly: [],
  topFansMonthly: [],
  topFansOverall: [],

  // PK variables
  contestant1: null,
  contestant2: null,
  currentRound: null,
  pkWinner: null,
  pkPreStartTimer: null,
  pkTimer: null,
  pkPreStartInterval: null,
  pkInterval: null,
  currentRoundPkEndDate: null,

  // Co Anchor variables
  isCoAnchor: null,
  coAnchorUser1: {
    userId: null,
    userIdInt: null,
    username: null,
    profilePhoto: null,
    channelId: null,
    dualPk: {
      diamonds: 0,
      win: null,
    },
  },
  coAnchorUser2: {
    userId: null,
    userIdInt: null,
    username: null,
    profilePhoto: null,
    channelId: null,
    dualPk: {
      diamonds: 0,
      win: null,
    },
  },
  coAnchorSelectedUser: null,

  // Dual PK variables
  isDualPk: false,
  dualPkData: null,
  dualPkStatus: null,
  dualPkTimer: null,
  dualPkMasterInterval: null,
  dualPkMasterPassthrough: null,
  dualPkSyncScoreInterval: null,
  dualPkSyncScorePassthrough: null,
  dualPkShowCountdownTimer: false,
  dualPkShowResultsOverlay: false,
  dualPkEnded: false,

  // Gifts variables
  selectedGift: false,

  // Gifts Assets variables
  giftAssets: [],

  // Livestream Switching Counter variables
  livestreamSwitchingCounter: 0,

  // Last Stream Viewers List variables
  lastStreamViewersList: [],

  // User Is Live variables
  userIsLiveData: null,

  // Pusher Event variables
  livestreamPusherEvent: null,

  // Scroll variables
  livestreamScrollYPosition: 0,
  livestreamScrollToTopPassthrough: {},

  // Utility variables
  disableSwipe: false,
  hasInteract: true,
  livestreamRefreshPassthrough: {},
  pkIntervalPassthrough: {},
  playVideoPassthrough: {},
  pinnedChatRefreshPassthrough: null,
  coAnchorStartPassthrough: null,
  coAnchorEndPassthrough: null,
  viewerKeepAlivePassthrough: null,
  coAnchorViewerKeepAlivePassthrough: null,
  loadedMetaDataPlayVideoPassthrough: null,
};

export const livestreamingSlice = createSlice({
  name: "livestreaming",
  initialState,
  reducers: {
    // Main Page Functions
    updateLivestreamChannelLists: (state, action) => {
      const livestreamChannelListsData = [
        ...state.livestreamChannelLists,
        ...action.payload,
      ];
      state.livestreamChannelLists = livestreamChannelListsData;
    },
    updateLivestreamChannelPagination: (state, action) => {
      state.livestreamChannelPagination = action.payload;
    },
    resetLivestreamChannelLists: (state) => {
      state.livestreamChannelLists = [];
      state.livestreamChannelPagination = null;
    },

    // General Functions
    updateReadOnlyChannelId: (state, action) => {
      state.readOnlyChannelId = action.payload;
    },
    updateChannelId: (state, action) => {
      state.channelId = action.payload;
    },
    updateLivestreamerUserObj: (state, action) => {
      state.livestreamerUserObj = action.payload;
    },
    updateLivestreamerId: (state, action) => {
      state.livestreamerId = action.payload;
    },
    updateLivestreamerIdInt: (state, action) => {
      state.livestreamerIdInt = action.payload;
    },
    updateLivestreamerProfilePhoto: (state, action) => {
      state.livestreamerProfilePhoto = action.payload;
    },
    updateLivestreamerProfileDecorations: (state, action) => {
      state.livestreamerProfileDecorations = action.payload;
    },
    updateLivestreamerAchievements: (state, action) => {
      state.livestreamerAchievements = action.payload;
    },
    updateLivestreamerUsername: (state, action) => {
      state.livestreamerUsername = action.payload;
    },
    updateLivestreamerLevellingBadge: (state, action) => {
      state.livestreamerLevellingBadge = action.payload;
    },
    updateLivestreamerFollowers: (state, action) => {
      state.livestreamerFollowers = action.payload;
    },
    updateLivestreamerViewers: (state, action) => {
      state.livestreamerViewers = action.payload;
    },
    updateLivestreamerDiamonds: (state, action) => {
      state.livestreamerDiamonds = action.payload;
    },
    updateLivestreamerIsFollowing: (state, action) => {
      state.livestreamerIsFollowing = action.payload;
    },
    resetReadOnlyChannelId: (state) => {
      state.readOnlyChannelId = null;
    },
    resetChannelId: (state) => {
      state.channelId = null;
    },
    resetLivestreamerInfo: (state) => {
      state.livestreamerId = null;
      state.livestreamerUsername = null;
      state.livestreamerFollowers = null;
      state.livestreamerViewers = null;
      state.livestreamerDiamonds = null;
    },

    // Video Player Functions
    updatePullChannel: (state, action) => {
      state.pullChannel = action.payload;
    },
    resetPullChannel: (state) => {
      state.pullChannel = null;
    },
    updateLivestreamLoading: (state, action) => {
      state.livestreamLoading = action.payload;
    },
    updateLivestreamBuffering: (state, action) => {
      state.livestreamBuffering = action.payload;
    },
    // Deprecated
    // updateLivestreamPaused: (state, action) => {
    //   state.livestreamPaused = action.payload;
    // },
    updateVideoPlayerMute: (state, action) => {
      state.videoPlayerMute = action.payload;
    },
    toggleVideoPlayerMute: (state) => {
      state.videoPlayerMute = !state.videoPlayerMute;
    },
    updateHasTcPullstream: (state, action) => {
      state.hasTcPullstream = action.payload;
    },
    updateHasTcPlayerPlay: (state, action) => {
      state.hasTcPlayerPlay = action.payload;
    },
    updateIsTcPlayerMemoryLeak: (state, action) => {
      state.isTcPlayerMemoryLeak = action.payload;
    },

    // Mini Video Player Functions
    updateIsMiniPlayer: (state, action) => {
      state.isMiniPlayer = action.payload;
    },
    updateMiniPlayerSource: (state, action) => {
      state.miniPlayerSource = action.payload;
    },
    updateMiniPlayerChannelId: (state, action) => {
      state.miniPlayerChannelId = action.payload;
    },

    // Chat Functions
    updateLivestreamChatMessages: (state, action) => {
      state.livestreamChatMessages.push(action.payload);

      // Remove first element if array length is more than 80
      if (state.livestreamChatMessages.length > livestreamingConst.chatLimit) {
        state.livestreamChatMessages.shift();
      }
    },
    clearLivestreamChatMessages: (state) => {
      state.livestreamChatMessages = [];
    },

    // Emoji Functions
    updateLivestreamFloatingEmoji: (state, action) => {
      state.livestreamFloatingEmoji = action.payload;
    },
    resetLivestreamFloatingEmoji: (state) => {
      state.livestreamFloatingEmoji = null;
    },

    // Gift Animation Functions
    updateLivestreamGiftAnimation: (state, action) => {
      state.livestreamGiftAnimation = action.payload;
    },
    resetLivestreamGiftAnimation: (state) => {
      state.livestreamGiftAnimation = null;
    },

    // VIP Entrance Functions
    updateLivestreamVipEntrance: (state, action) => {
      state.livestreamVipEntrance = action.payload;
    },
    resetLivestreamVipEntrance: (state) => {
      state.livestreamVipEntrance = null;
    },

    // Live Viewers Functions
    updateLiveViewers: (state, action) => {
      state.liveViewers = action.payload;
    },
    updateLiveViewerByIndex: (state, action) => {
      let id = action.payload.id;
      let isFollowing = action.payload.isFollowing;

      let liveViewersData = [...state.liveViewers];

      for (let i = 0; i < liveViewersData.length; i++) {
        if (liveViewersData[i].id === id) {
          liveViewersData[i].live_streaming_meta.is_following = isFollowing;
          break;
        }
      }

      state.liveViewers = liveViewersData;
    },
    resetLiveViewers: (state) => {
      state.liveViewers = [];
    },

    // Top Fans Functions
    updateTopFansDaily: (state, action) => {
      const topFansDailyData = [...state.topFansDaily, ...action.payload];
      state.topFansDaily = topFansDailyData;
    },
    updateTopFansDailyByIndex: (state, action) => {
      let id = action.payload.id;
      let isFollowing = action.payload.isFollowing;

      let topFansDailyData = [...state.topFansDaily];
      let topFansWeeklyData = [...state.topFansWeekly];
      let topFansMonthlyData = [...state.topFansMonthly];
      let topFansOverallData = [...state.topFansOverall];

      for (let i = 0; i < topFansDailyData.length; i++) {
        if (topFansDailyData[i].user.id === id) {
          topFansDailyData[i].user.live_streaming_meta.is_following =
            isFollowing;
          break;
        }
      }

      for (let i = 0; i < topFansWeeklyData.length; i++) {
        if (topFansWeeklyData[i].user.id === id) {
          topFansWeeklyData[i].user.live_streaming_meta.is_following =
            isFollowing;
          break;
        }
      }

      for (let i = 0; i < topFansMonthlyData.length; i++) {
        if (topFansMonthlyData[i].user.id === id) {
          topFansMonthlyData[i].user.live_streaming_meta.is_following =
            isFollowing;
          break;
        }
      }

      for (let i = 0; i < topFansOverallData.length; i++) {
        if (topFansOverallData[i].user.id === id) {
          topFansOverallData[i].user.live_streaming_meta.is_following =
            isFollowing;
          break;
        }
      }

      state.topFansDaily = topFansDailyData;
      state.topFansWeekly = topFansWeeklyData;
      state.topFansMonthly = topFansMonthlyData;
      state.topFansOverall = topFansOverallData;
    },
    resetTopFansDaily: (state) => {
      state.topFansDaily = [];
    },
    updateTopFansWeekly: (state, action) => {
      const topFansWeeklyData = [...state.topFansWeekly, ...action.payload];
      state.topFansWeekly = topFansWeeklyData;
    },
    updateTopFansWeeklyByIndex: (state, action) => {
      let id = action.payload.id;
      let isFollowing = action.payload.isFollowing;

      let topFansDailyData = [...state.topFansDaily];
      let topFansWeeklyData = [...state.topFansWeekly];
      let topFansMonthlyData = [...state.topFansMonthly];
      let topFansOverallData = [...state.topFansOverall];

      for (let i = 0; i < topFansDailyData.length; i++) {
        if (topFansDailyData[i].user.id === id) {
          topFansDailyData[i].user.live_streaming_meta.is_following =
            isFollowing;
          break;
        }
      }

      for (let i = 0; i < topFansWeeklyData.length; i++) {
        if (topFansWeeklyData[i].user.id === id) {
          topFansWeeklyData[i].user.live_streaming_meta.is_following =
            isFollowing;
          break;
        }
      }

      for (let i = 0; i < topFansMonthlyData.length; i++) {
        if (topFansMonthlyData[i].user.id === id) {
          topFansMonthlyData[i].user.live_streaming_meta.is_following =
            isFollowing;
          break;
        }
      }

      for (let i = 0; i < topFansOverallData.length; i++) {
        if (topFansOverallData[i].user.id === id) {
          topFansOverallData[i].user.live_streaming_meta.is_following =
            isFollowing;
          break;
        }
      }

      state.topFansDaily = topFansDailyData;
      state.topFansWeekly = topFansWeeklyData;
      state.topFansMonthly = topFansMonthlyData;
      state.topFansOverall = topFansOverallData;
    },
    resetTopFansWeekly: (state) => {
      state.topFansWeekly = [];
    },
    updateTopFansMonthly: (state, action) => {
      const topFansMonthlyData = [...state.topFansMonthly, ...action.payload];
      state.topFansMonthly = topFansMonthlyData;
    },
    updateTopFansMonthlyByIndex: (state, action) => {
      let id = action.payload.id;
      let isFollowing = action.payload.isFollowing;

      let topFansDailyData = [...state.topFansDaily];
      let topFansWeeklyData = [...state.topFansWeekly];
      let topFansMonthlyData = [...state.topFansMonthly];
      let topFansOverallData = [...state.topFansOverall];

      for (let i = 0; i < topFansDailyData.length; i++) {
        if (topFansDailyData[i].user.id === id) {
          topFansDailyData[i].user.live_streaming_meta.is_following =
            isFollowing;
          break;
        }
      }

      for (let i = 0; i < topFansWeeklyData.length; i++) {
        if (topFansWeeklyData[i].user.id === id) {
          topFansWeeklyData[i].user.live_streaming_meta.is_following =
            isFollowing;
          break;
        }
      }

      for (let i = 0; i < topFansMonthlyData.length; i++) {
        if (topFansMonthlyData[i].user.id === id) {
          topFansMonthlyData[i].user.live_streaming_meta.is_following =
            isFollowing;
          break;
        }
      }

      for (let i = 0; i < topFansOverallData.length; i++) {
        if (topFansOverallData[i].user.id === id) {
          topFansOverallData[i].user.live_streaming_meta.is_following =
            isFollowing;
          break;
        }
      }

      state.topFansDaily = topFansDailyData;
      state.topFansWeekly = topFansWeeklyData;
      state.topFansMonthly = topFansMonthlyData;
      state.topFansOverall = topFansOverallData;
    },
    resetTopFansMonthly: (state) => {
      state.topFansMonthly = [];
    },
    updateTopFansOverall: (state, action) => {
      const topFansOverallData = [...state.topFansOverall, ...action.payload];
      state.topFansOverall = topFansOverallData;
    },
    updateTopFansOverallByIndex: (state, action) => {
      let id = action.payload.id;
      let isFollowing = action.payload.isFollowing;

      let topFansDailyData = [...state.topFansDaily];
      let topFansWeeklyData = [...state.topFansWeekly];
      let topFansMonthlyData = [...state.topFansMonthly];
      let topFansOverallData = [...state.topFansOverall];

      for (let i = 0; i < topFansDailyData.length; i++) {
        if (topFansDailyData[i].user.id === id) {
          topFansDailyData[i].user.live_streaming_meta.is_following =
            isFollowing;
          break;
        }
      }

      for (let i = 0; i < topFansWeeklyData.length; i++) {
        if (topFansWeeklyData[i].user.id === id) {
          topFansWeeklyData[i].user.live_streaming_meta.is_following =
            isFollowing;
          break;
        }
      }

      for (let i = 0; i < topFansMonthlyData.length; i++) {
        if (topFansMonthlyData[i].user.id === id) {
          topFansMonthlyData[i].user.live_streaming_meta.is_following =
            isFollowing;
          break;
        }
      }

      for (let i = 0; i < topFansOverallData.length; i++) {
        if (topFansOverallData[i].user.id === id) {
          topFansOverallData[i].user.live_streaming_meta.is_following =
            isFollowing;
          break;
        }
      }

      state.topFansDaily = topFansDailyData;
      state.topFansWeekly = topFansWeeklyData;
      state.topFansMonthly = topFansMonthlyData;
      state.topFansOverall = topFansOverallData;
    },
    resetTopFansOverall: (state) => {
      state.topFansOverall = [];
    },

    // PK Functions
    updateContestant1: (state, action) => {
      state.contestant1 = action.payload;
    },
    updateContestant2: (state, action) => {
      state.contestant2 = action.payload;
    },
    updateCurrentRound: (state, action) => {
      state.currentRound = action.payload;
    },
    updatePkWinner: (state, action) => {
      state.pkWinner = action.payload;
    },
    setPkPreStartTimer: (state, action) => {
      state.pkPreStartTimer = action.payload;
    },
    updatePkPreStartTimer: (state) => {
      if (state.pkPreStartTimer <= 0) {
        clearInterval(state.pkPreStartInterval);
        state.pkPreStartInterval = null;
        return;
      }

      state.pkPreStartTimer--;
    },
    setPkTimer: (state, action) => {
      state.pkTimer = action.payload;
    },
    updatePkTimer: (state, action) => {
      if (state.pkTimer <= 0) {
        clearInterval(state.pkInterval);
        state.pkInterval = null;
        return;
      }

      state.pkTimer = action.payload;
      // state.pkTimer--;
    },
    updatePkPreStartInterval: (state, action) => {
      if (state.pkPreStartInterval) return;

      state.pkPreStartInterval = action.payload;
    },
    updatePkInterval: (state, action) => {
      if (state.pkInterval) return;

      state.pkInterval = action.payload;
    },
    updateCurrentRoundPkEndDate: (state, action) => {
      state.currentRoundPkEndDate = action.payload;
    },
    clearPkPreStartInterval: (state) => {
      if (!state.pkPreStartInterval) return;

      clearInterval(state.pkPreStartInterval);
      state.pkPreStartInterval = null;
    },
    clearPkInterval: (state) => {
      if (!state.pkInterval) return;

      clearInterval(state.pkInterval);
      state.pkInterval = null;
    },
    resetPkInfo: (state) => {
      state.contestant1 = null;
      state.contestant2 = null;
      state.currentRound = 0;
      state.pkWinner = null;
      state.pkPreStartTimer = null;
      state.pkTimer = null;
      clearInterval(state.pkPreStartInterval);
      clearInterval(state.pkInterval);
      state.pkPreStartInterval = null;
      state.pkInterval = null;
    },

    // Co Anchor Functions
    updateIsCoAnchor: (state, action) => {
      state.isCoAnchor = action.payload;
    },
    updateCoAnchorUser1UserId: (state, action) => {
      state.coAnchorUser1.userId = action.payload;
    },
    updateCoAnchorUser2UserId: (state, action) => {
      state.coAnchorUser2.userId = action.payload;
    },
    updateCoAnchorUser1UserIdInt: (state, action) => {
      state.coAnchorUser1.userIdInt = action.payload;
    },
    updateCoAnchorUser1Username: (state, action) => {
      state.coAnchorUser1.username = action.payload;
    },
    updateCoAnchorUser1ProfilePhoto: (state, action) => {
      state.coAnchorUser1.profilePhoto = action.payload;
    },
    updateCoAnchorUser1DualPkDiamonds: (state, action) => {
      state.coAnchorUser1.dualPk.diamonds = action.payload;
    },
    updateCoAnchorUser1DualPkWin: (state, action) => {
      state.coAnchorUser1.dualPk.win = action.payload;
    },
    updateCoAnchorUser2UserIdInt: (state, action) => {
      state.coAnchorUser2.userIdInt = action.payload;
    },
    updateCoAnchorUser2Username: (state, action) => {
      state.coAnchorUser2.username = action.payload;
    },
    updateCoAnchorUser2ProfilePhoto: (state, action) => {
      state.coAnchorUser2.profilePhoto = action.payload;
    },
    updateCoAnchorUser2DualPkDiamonds: (state, action) => {
      state.coAnchorUser2.dualPk.diamonds = action.payload;
    },
    updateCoAnchorUser2DualPkWin: (state, action) => {
      state.coAnchorUser2.dualPk.win = action.payload;
    },
    updateCoAnchorUserDualPkWinInPusher: (state, action) => {
      const rounds = action.payload.rounds;

      let user1WinCount = 0;
      let user2WinCount = 0;

      if (rounds && rounds.length > 0) {
        rounds.forEach((round) => {
          const winnerId = round.winner;
          const user1 = state.coAnchorUser1.userId;
          const user2 = state.coAnchorUser2.userId;

          if (winnerId === user1) {
            user1WinCount++;
          } else if (winnerId === user2) {
            user2WinCount++;
          }
        });
      }
      state.coAnchorUser1.dualPk.win = user1WinCount;
      state.coAnchorUser2.dualPk.win = user2WinCount;
    },
    resetCoAnchorUser1: (state) => {
      state.coAnchorUser1.userId = null;
      state.coAnchorUser1.userIdInt = null;
      state.coAnchorUser1.username = null;
      state.coAnchorUser1.profilePhoto = null;
      state.coAnchorUser1.dualPk.diamonds = 0;
      state.coAnchorUser1.dualPk.win = null;
    },
    resetCoAnchorUser2: (state) => {
      state.coAnchorUser2.userId = null;
      state.coAnchorUser2.userIdInt = null;
      state.coAnchorUser2.username = null;
      state.coAnchorUser2.profilePhoto = null;
      state.coAnchorUser2.dualPk.diamonds = 0;
      state.coAnchorUser2.dualPk.win = null;
    },
    resetCoAnchorUser1DualPkDiamonds: (state) => {
      state.coAnchorUser1.dualPk.diamonds = 0;
    },
    resetCoAnchorUser2DualPkDiamonds: (state) => {
      state.coAnchorUser2.dualPk.diamonds = 0;
    },
    resetCoAnchorUsersDualPk: (state) => {
      state.coAnchorUser1.dualPk.diamonds = 0;
      state.coAnchorUser1.dualPk.win = null;
      state.coAnchorUser2.dualPk.diamonds = 0;
      state.coAnchorUser2.dualPk.win = null;
    },
    updateCoAnchorSelectedUser: (state, action) => {
      state.coAnchorSelectedUser = action.payload;
    },
    resetCoAnchorFunctions: (state) => {
      state.isCoAnchor = null;
      state.coAnchorUser1.userId = null;
      state.coAnchorUser1.userIdInt = null;
      state.coAnchorUser1.username = null;
      state.coAnchorUser1.profilePhoto = null;
      state.coAnchorUser1.dualPk.diamonds = 0;
      state.coAnchorUser1.dualPk.win = null;
      state.coAnchorUser2.userId = null;
      state.coAnchorUser2.userIdInt = null;
      state.coAnchorUser2.username = null;
      state.coAnchorUser2.profilePhoto = null;
      state.coAnchorUser2.dualPk.diamonds = 0;
      state.coAnchorUser2.dualPk.win = null;
      state.coAnchorSelectedUser = null;

      state.coAnchorStartPassthrough = null;
      state.coAnchorEndPassthrough = null;
    },

    // Dual PK Functions
    updateIsDualPk: (state, action) => {
      state.isDualPk = action.payload;
    },
    updateDualPkData: (state, action) => {
      state.dualPkData = action.payload;
    },
    updateDualPkCurrentRound: (state, action) => {
      state.dualPkData.currentRound = action.payload;
    },
    updateDualPkStatus: (state, action) => {
      state.dualPkStatus = action.payload;
    },
    setDualPkTimer: (state, action) => {
      state.dualPkTimer = action.payload;
    },
    updateDualPkMasterInterval: (state, action) => {
      if (state.dualPkMasterInterval) return;

      state.dualPkMasterInterval = action.payload;
    },
    clearDualPkMasterInterval: (state) => {
      if (!state.dualPkMasterInterval) return;

      clearInterval(state.dualPkMasterInterval);
      state.dualPkMasterInterval = null;
    },
    updateDualPkMasterPassthrough: (state) => {
      state.dualPkMasterPassthrough = {};
    },
    resetDualPkMasterPassthrough: (state) => {
      state.dualPkMasterPassthrough = null;
    },
    updateDualPkSyncScoreInterval: (state, action) => {
      if (state.dualPkSyncScoreInterval) return;

      state.dualPkSyncScoreInterval = action.payload;
    },
    clearDualPkSyncScoreInterval: (state) => {
      if (!state.dualPkSyncScoreInterval) return;

      clearInterval(state.dualPkSyncScoreInterval);
      state.dualPkSyncScoreInterval = null;
    },
    updateDualPkSyncScorePassthrough: (state) => {
      state.dualPkSyncScorePassthrough = {};
    },
    updateDualPkShowCountdownTimer: (state, action) => {
      state.dualPkShowCountdownTimer = action.payload;
    },
    updateDualPkShowResultsOverlay: (state, action) => {
      state.dualPkShowResultsOverlay = action.payload;
    },
    updateDualPkEnded: (state, action) => {
      state.dualPkEnded = action.payload;
    },
    resetDualPkFunctions: (state) => {
      state.isDualPk = false;
      state.dualPkData = null;
      state.dualPkStatus = null;
      state.dualPkTimer = null;
      if (state.dualPkMasterInterval) {
        clearInterval(state.dualPkMasterInterval);
      }
      state.dualPkMasterInterval = null;
      state.dualPkMasterPassthrough = null;
      if (state.dualPkSyncScoreInterval) {
        clearInterval(state.dualPkSyncScoreInterval);
      }
      state.dualPkSyncScoreInterval = null;
      state.dualPkSyncScorePassthrough = null;
      state.dualPkShowCountdownTimer = false;
      state.dualPkShowResultsOverlay = false;
      state.dualPkEnded = false;
    },

    // Gift Functions
    updateSelectedGift: (state, action) => {
      state.selectedGift = action.payload;
    },

    // Gift Assets Functions
    updateGiftAssets: (state, action) => {
      state.giftAssets = action.payload;
    },

    // Livestream Switching Counter Functions
    updateLivestreamSwitchingCounter: (state) => {
      state.livestreamSwitchingCounter += 1;
    },
    clearLivestreamSwitchingCounter: (state) => {
      state.livestreamSwitchingCounter = 0;
    },

    // Last Stream Viewers List Functions
    updateLastStreamViewersList: (state, action) => {
      const lastStreamViewersListData = [
        ...state.lastStreamViewersList,
        ...action.payload,
      ];
      state.lastStreamViewersList = lastStreamViewersListData;
    },
    clearLastStreamViewersList: (state) => {
      state.lastStreamViewersList = [];
    },

    // User Is Live Functions
    updateUserIsLiveData: (state, action) => {
      state.userIsLiveData = action.payload;
    },

    // Pusher Event Functions
    updateLivestreamPusherEvent: (state, action) => {
      state.livestreamPusherEvent = action.payload;
    },

    // Scroll Functions
    updateLivestreamScrollYPosition: (state, action) => {
      state.livestreamScrollYPosition = action.payload;
    },
    updateLivestreamScrollToTopPassthrough: (state, action) => {
      state.livestreamScrollToTopPassthrough = action.payload;
    },

    // Local Data Functions
    updateLocalLivestreamUserData: (state, action) => {
      const idInt = action.payload.idInt;
      const privatePhotoRequested = action.payload.privatePhotoRequested;
      const isBlocked = action.payload.isBlocked;
      const isFavorited = action.payload.isFavorited;

      const liveViewersData = [...state.liveViewers];
      const topFansDailyData = [...state.topFansDaily];
      const topFansWeeklyData = [...state.topFansWeekly];
      const topFansMonthlyData = [...state.topFansMonthly];
      const topFansOverallData = [...state.topFansOverall];

      // Live Viewers
      for (let i = 0; i < liveViewersData.length; i++) {
        if (liveViewersData[i].id_int === idInt) {
          if (privatePhotoRequested !== null) {
            liveViewersData[i].interaction.private_photo_requested =
              privatePhotoRequested;
          }

          if (isBlocked !== null) {
            liveViewersData[i].interaction.is_blocked = isBlocked;
          }

          if (isFavorited !== null) {
            liveViewersData[i].interaction.is_favorited = isFavorited;
          }
          break;
        }
      }

      // Top Fans Daily
      for (let i = 0; i < topFansDailyData.length; i++) {
        if (topFansDailyData[i].user.id_int === idInt) {
          if (privatePhotoRequested !== null) {
            topFansDailyData[i].user.interaction.private_photo_requested =
              privatePhotoRequested;
          }

          if (isBlocked !== null) {
            topFansDailyData[i].user.interaction.is_blocked = isBlocked;
          }

          if (isFavorited !== null) {
            topFansDailyData[i].user.interaction.is_favorited = isFavorited;
          }
          break;
        }
      }

      // Top Fans Weekly
      for (let i = 0; i < topFansWeeklyData.length; i++) {
        if (topFansWeeklyData[i].user.id_int === idInt) {
          if (privatePhotoRequested !== null) {
            topFansWeeklyData[i].user.interaction.private_photo_requested =
              privatePhotoRequested;
          }

          if (isBlocked !== null) {
            topFansWeeklyData[i].user.interaction.is_blocked = isBlocked;
          }

          if (isFavorited !== null) {
            topFansWeeklyData[i].user.interaction.is_favorited = isFavorited;
          }
          break;
        }
      }

      // Top Fans Monthly
      for (let i = 0; i < topFansMonthlyData.length; i++) {
        if (topFansMonthlyData[i].user.id_int === idInt) {
          if (privatePhotoRequested !== null) {
            topFansMonthlyData[i].user.interaction.private_photo_requested =
              privatePhotoRequested;
          }

          if (isBlocked !== null) {
            topFansMonthlyData[i].user.interaction.is_blocked = isBlocked;
          }

          if (isFavorited !== null) {
            topFansMonthlyData[i].user.interaction.is_favorited = isFavorited;
          }
          break;
        }
      }

      // Top Fans Overall
      for (let i = 0; i < topFansOverallData.length; i++) {
        if (topFansOverallData[i].user.id_int === idInt) {
          if (privatePhotoRequested !== null) {
            topFansOverallData[i].user.interaction.private_photo_requested =
              privatePhotoRequested;
          }

          if (isBlocked !== null) {
            topFansOverallData[i].user.interaction.is_blocked = isBlocked;
          }

          if (isFavorited !== null) {
            topFansOverallData[i].user.interaction.is_favorited = isFavorited;
          }
          break;
        }
      }

      state.liveViewers = liveViewersData;
      state.topFansDaily = topFansDailyData;
      state.topFansWeekly = topFansWeeklyData;
      state.topFansMonthly = topFansMonthlyData;
      state.topFansOverall = topFansOverallData;
    },

    // Utility Functions
    resetLivestreamingStore: () => {
      return { ...initialState };
    },
    updateLocalLivestreamList: (state, action) => {
      const idInt = action.payload.idInt;
      const privatePhotoRequested = action.payload.privatePhotoRequested;
      const isBlocked = action.payload.isBlocked;
      const isFavorited = action.payload.isFavorited;

      const livestreamChannelListsData = [...state.livestreamChannelLists];

      for (let i = 0; i < livestreamChannelListsData.length; i++) {
        if (
          livestreamChannelListsData[i].user &&
          livestreamChannelListsData[i].user.id_int === idInt
        ) {
          if (privatePhotoRequested !== null) {
            livestreamChannelListsData[
              i
            ].user.interaction.private_photo_requested = privatePhotoRequested;
          }

          if (isBlocked !== null) {
            livestreamChannelListsData[i].user.interaction.is_blocked =
              isBlocked;
          }

          if (isFavorited !== null) {
            livestreamChannelListsData[i].user.interaction.is_favorited =
              isFavorited;
          }
          break;
        }
      }

      state.livestreamChannelLists = livestreamChannelListsData;
    },
    updateDisableSwipe: (state, action) => {
      state.disableSwipe = action.payload;
    },
    updateHasInteract: (state, action) => {
      state.hasInteract = action.payload;
    },
    updateLivestreamRefreshPassthrough: (state) => {
      state.livestreamRefreshPassthrough = {};
    },
    updatePkIntervalPassthrough: (state, action) => {
      state.pkIntervalPassthrough = action.payload;
    },
    updatePlayVideoPassthrough: (state, action) => {
      state.playVideoPassthrough = action.payload;
    },
    updatePinnedChatRefreshPassthrough: (state) => {
      state.pinnedChatRefreshPassthrough = {};
    },
    updateCoAnchorStartPassthrough: (state) => {
      state.coAnchorStartPassthrough = {};
    },
    updateCoAnchorEndPassthrough: (state) => {
      state.coAnchorEndPassthrough = {};
    },
    updateViewerKeepAlivePassthrough: (state) => {
      if (state.isCoAnchor) {
        state.coAnchorViewerKeepAlivePassthrough = {};
      } else {
        state.viewerKeepAlivePassthrough = {};
      }
    },
    updateLoadedMetaDataPlayVideoPassthrough: (state) => {
      state.loadedMetaDataPlayVideoPassthrough = {};
    },
    resetLoadedMetaDataPlayVideoPassthrough: (state) => {
      state.loadedMetaDataPlayVideoPassthrough = null;
    },
  },
});

export const {
  // Main Page Functions
  updateLivestreamChannelLists,
  updateLivestreamChannelPagination,
  resetLivestreamChannelLists,

  // General Functions
  updateReadOnlyChannelId,
  updateChannelId,
  updateLivestreamerUserObj,
  updateLivestreamerId,
  updateLivestreamerIdInt,
  updateLivestreamerProfilePhoto,
  updateLivestreamerProfileDecorations,
  updateLivestreamerAchievements,
  updateLivestreamerUsername,
  updateLivestreamerLevellingBadge,
  updateLivestreamerFollowers,
  updateLivestreamerViewers,
  updateLivestreamerDiamonds,
  updateLivestreamerIsFollowing,
  resetReadOnlyChannelId,
  resetChannelId,
  resetLivestreamerInfo,

  // Video Player Functions
  updatePullChannel,
  resetPullChannel,
  updateLivestreamLoading,
  updateLivestreamBuffering,
  // updateLivestreamPaused, // Deprecated
  updateVideoPlayerMute,
  toggleVideoPlayerMute,
  updateHasTcPullstream,
  updateHasTcPlayerPlay,
  updateIsTcPlayerMemoryLeak,

  // Mini Video Player Functions
  updateIsMiniPlayer,
  updateMiniPlayerSource,
  updateMiniPlayerChannelId,

  // Chat Functions
  updateLivestreamChatMessages,
  clearLivestreamChatMessages,

  // Emoji Functions
  updateLivestreamFloatingEmoji,
  resetLivestreamFloatingEmoji,

  // Gift Animation Functions
  updateLivestreamGiftAnimation,
  resetLivestreamGiftAnimation,

  // VIP Entrance Functions
  updateLivestreamVipEntrance,
  resetLivestreamVipEntrance,

  // Live Viewers Functions
  updateLiveViewers,
  updateLiveViewerByIndex,
  resetLiveViewers,

  // Top Fans Functions
  updateTopFansDaily,
  updateTopFansDailyByIndex,
  resetTopFansDaily,
  updateTopFansWeekly,
  updateTopFansWeeklyByIndex,
  resetTopFansWeekly,
  updateTopFansMonthly,
  updateTopFansMonthlyByIndex,
  resetTopFansMonthly,
  updateTopFansOverall,
  updateTopFansOverallByIndex,
  resetTopFansOverall,

  // PK Functions
  updateContestant1,
  updateContestant2,
  updateCurrentRound,
  updatePkWinner,
  setPkPreStartTimer,
  updatePkPreStartTimer,
  setPkTimer,
  updatePkTimer,
  updatePkPreStartInterval,
  updatePkInterval,
  updateCurrentRoundPkEndDate,
  clearPkPreStartInterval,
  clearPkInterval,
  resetPkInfo,

  // Co Anchor Functions
  updateIsCoAnchor,
  updateCoAnchorUser1UserId,
  updateCoAnchorUser1UserIdInt,
  updateCoAnchorUser1Username,
  updateCoAnchorUser1ProfilePhoto,
  updateCoAnchorUser1DualPkDiamonds,
  updateCoAnchorUser1DualPkWin,
  updateCoAnchorUser2UserId,
  updateCoAnchorUser2UserIdInt,
  updateCoAnchorUser2Username,
  updateCoAnchorUser2ProfilePhoto,
  updateCoAnchorUser2DualPkDiamonds,
  updateCoAnchorUser2DualPkWin,
  updateCoAnchorUserDualPkWinInPusher,
  resetCoAnchorUser1,
  resetCoAnchorUser2,
  resetCoAnchorUser1DualPkDiamonds,
  resetCoAnchorUser2DualPkDiamonds,
  resetCoAnchorUsersDualPk,
  updateCoAnchorSelectedUser,
  resetCoAnchorFunctions,

  // Dual PK Functions
  updateIsDualPk,
  updateDualPkData,
  updateDualPkCurrentRound,
  updateDualPkStatus,
  setDualPkTimer,
  updateDualPkMasterInterval,
  clearDualPkMasterInterval,
  updateDualPkMasterPassthrough,
  resetDualPkMasterPassthrough,
  updateDualPkSyncScoreInterval,
  clearDualPkSyncScoreInterval,
  updateDualPkSyncScorePassthrough,
  updateDualPkShowCountdownTimer,
  updateDualPkShowResultsOverlay,
  updateDualPkEnded,
  resetDualPkFunctions,

  // Gift Functions
  updateSelectedGift,

  // Gift Assets Functions
  updateGiftAssets,

  // Livestream Switching Counter Functions
  updateLivestreamSwitchingCounter,
  clearLivestreamSwitchingCounter,

  // Last Stream Viewers List Functions
  updateLastStreamViewersList,
  clearLastStreamViewersList,

  updateUserIsLiveData,

  // Pusher Event Functions
  updateLivestreamPusherEvent,

  // Scroll Functions
  updateLivestreamScrollYPosition,
  updateLivestreamScrollToTopPassthrough,

  // Local Data Functions
  updateLocalLivestreamUserData,

  // Utility Functions
  resetLivestreamingStore,
  updateLocalLivestreamList,
  updateDisableSwipe,
  updateHasInteract,
  updateLivestreamRefreshPassthrough,
  updatePkIntervalPassthrough,
  updatePlayVideoPassthrough,
  updatePinnedChatRefreshPassthrough,
  updateCoAnchorStartPassthrough,
  updateCoAnchorEndPassthrough,
  updateViewerKeepAlivePassthrough,
  updateLoadedMetaDataPlayVideoPassthrough,
  resetLoadedMetaDataPlayVideoPassthrough,
} = livestreamingSlice.actions;

export default livestreamingSlice.reducer;
