// General
import "./notification-settings.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetAllSettingsQuery,
  useLazyGetUserAioQuery,
  useUpdateNotificationSettingsMutation,
} from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useDispatch } from "react-redux";
import { updateSuccessToast } from "../../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Switch } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";

const NotificationSettings = () => {
  // API variables
  const [
    getAllSettings,
    {
      data: allSettingsData,
      error: allSettingsErrorData,
      isFetching: allSettingsFetching,
      isLoading: allSettingsLoading,
      isSuccess: allSettingsSuccess,
      isError: allSettingsError,
    },
  ] = useLazyGetAllSettingsQuery();
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();
  const [
    updateNotificationSettings,
    {
      data: updateNotificationSettingsData,
      error: updateNotificationSettingsErrorData,
      isLoading: updateNotificationSettingsLoading,
      isSuccess: updateNotificationSettingsSuccess,
      isError: updateNotificationSettingsError,
    },
  ] = useUpdateNotificationSettingsMutation();

  // Redux variables
  const dispatch = useDispatch();

  // MUI variables
  const [switchEvent, setSwitchEvent] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [messagesPushChecked, setMessagesPushChecked] = useState(false);
  const [messagesEmailChecked, setMessagesEmailChecked] = useState(false);
  const [favoritesPushChecked, setFavoritesPushChecked] = useState(false);
  const [favoritesEmailChecked, setFavoritesEmailChecked] = useState(false);
  const [viewedPushChecked, setViewedPushChecked] = useState(false);
  const [viewedEmailChecked, setViewedEmailChecked] = useState(false);
  const [weeklyEmailChecked, setWeeklyEmailChecked] = useState(false);
  const [moderatedEmailChecked, setModeratedEmailChecked] = useState(false);
  const [streamersOnlineChecked, setStreamersOnlineChecked] = useState(false);

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    getAllSettings(null, true);
    getUserAio(null, true);
  }, []);

  // Lifecycle | Check for update | All Settings API Response
  useEffect(() => {
    if (allSettingsFetching || allSettingsLoading) {
    } else if (allSettingsSuccess) {
      if (allSettingsData?.status === 0) {
        setMessagesPushChecked(
          allSettingsData?.payload?.settings["push-notifications"]
            ?.sendmemessage === 1
            ? true
            : false
        );
        setMessagesEmailChecked(
          allSettingsData?.payload?.settings.sendmemessage === 1 ? true : false
        );
        setFavoritesPushChecked(
          allSettingsData?.payload?.settings["push-notifications"]
            ?.favoriteme === 1
            ? true
            : false
        );
        setFavoritesEmailChecked(
          allSettingsData?.payload?.settings?.favoriteme === 1 ? true : false
        );
        setViewedPushChecked(
          allSettingsData?.payload?.settings["push-notifications"]
            ?.viewedmyprofile === 1
            ? true
            : false
        );
        setViewedEmailChecked(
          allSettingsData?.payload?.settings?.viewmyprofile === 1 ? true : false
        );
        setWeeklyEmailChecked(
          allSettingsData?.payload?.settings?.weeklyactivityupdate === 1
            ? true
            : false
        );
        setModeratedEmailChecked(
          allSettingsData?.payload?.settings?.moderatecontent === 1
            ? true
            : false
        );
        setStreamersOnlineChecked(
          allSettingsData?.payload?.settings?.streamer_online_notification === 1
            ? true
            : false
        );
      }
    } else if (allSettingsError) {
    }
  }, [
    allSettingsFetching,
    allSettingsLoading,
    allSettingsSuccess,
    allSettingsError,
  ]);

  // Lifecycle | Check for update | Update Notification Settings API Response
  useEffect(() => {
    if (updateNotificationSettingsLoading) {
    } else if (updateNotificationSettingsSuccess) {
      const toastObj = {
        message: t("settings.alert_updated"),
        autoClose: 3000,
      };
      dispatch(updateSuccessToast(toastObj));
    } else if (updateNotificationSettingsError) {
      if (updateNotificationSettingsErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    updateNotificationSettingsLoading,
    updateNotificationSettingsSuccess,
    updateNotificationSettingsError,
  ]);

  // Lifecycle | Check for update | Switch event & Call Update Notification API
  useEffect(() => {
    if (switchEvent) {
      const obj = {
        "sendmemessage-push": messagesPushChecked,
        sendmemessage: messagesEmailChecked,
        "favoriteme-push": favoritesPushChecked,
        favoriteme: favoritesEmailChecked,
        "viewedmyprofile-push": viewedPushChecked,
        viewmyprofile: viewedEmailChecked,
        weeklyactivityupdate: weeklyEmailChecked,
        moderatecontent: moderatedEmailChecked,
        streamer_online_notification: streamersOnlineChecked,
      };

      updateNotificationSettings(obj);
    }
  }, [
    switchEvent,
    messagesPushChecked,
    messagesEmailChecked,
    favoritesPushChecked,
    favoritesEmailChecked,
    viewedPushChecked,
    viewedEmailChecked,
    weeklyEmailChecked,
    moderatedEmailChecked,
  ]);

  // Event Handlers | MUI Switch
  const onSwitchChange = (state) => (event) => {
    let eventName = "";
    setSwitchEvent(true);

    switch (state) {
      case "messages-push":
        setMessagesPushChecked(event.target.checked);
        eventName = "MessagePush";
        break;
      case "messages-email":
        setMessagesEmailChecked(event.target.checked);
        eventName = "MessageEmail";
        break;
      case "favorites-push":
        setFavoritesPushChecked(event.target.checked);
        eventName = "FavoritesPush";
        break;
      case "favorites-email":
        setFavoritesEmailChecked(event.target.checked);
        eventName = "FavoritesEmail";
        break;
      case "viewed-push":
        setViewedPushChecked(event.target.checked);
        eventName = "ViewedPush";
        break;
      case "viewed-email":
        setViewedEmailChecked(event.target.checked);
        eventName = "ViewedEmail";
        break;
      case "weekly-email":
        setWeeklyEmailChecked(event.target.checked);
        eventName = "WeeklyEmail";
        break;
      case "moderated-email":
        setModeratedEmailChecked(event.target.checked);
        eventName = "ModeratedEmail";
        break;
      case "streamers-online":
        setStreamersOnlineChecked(event.target.checked);
        eventName = "StreamersOnline";
        break;
      default:
        break;
    }

    TagManager.dataLayer({
      dataLayer: {
        event: `PWA-12-${eventName}-Toggle-${
          event.target.checked === true ? "On" : "Off"
        }`,
      },
    });
  };

  return (
    <div id="settings-notification-settings-subcomponent">
      <div className="padding-container">
        <div className="label-container">
          <div className="notification-label">
            {t("settings.notifications")}
          </div>
          <div className="right-container">
            <div className="push-label">{t("settings.notification_push")}</div>
            <div className="email-label">
              {t("settings.notification_email")}
            </div>
          </div>
        </div>

        <div className="switch-list-container">
          <div className="switch-container">
            <div className="switch-label">{t("settings.notify_messages")}</div>
            <div className="suffix">
              <Switch
                className="custom-switch"
                checked={messagesPushChecked}
                onChange={onSwitchChange("messages-push")}
              />
              <Switch
                className="custom-switch"
                checked={messagesEmailChecked}
                onChange={onSwitchChange("messages-email")}
              />
            </div>
          </div>

          <div className="switch-container">
            <div className="switch-label">{t("settings.notify_favorites")}</div>
            <div className="suffix">
              <Switch
                className="custom-switch"
                checked={favoritesPushChecked}
                onChange={onSwitchChange("favorites-push")}
              />
              <Switch
                className="custom-switch"
                checked={favoritesEmailChecked}
                onChange={onSwitchChange("favorites-email")}
              />
            </div>
          </div>

          <div className="switch-container">
            <div className="switch-label">{t("settings.notify_viewed")}</div>
            <div className="suffix">
              <Switch
                className="custom-switch"
                checked={viewedPushChecked}
                onChange={onSwitchChange("viewed-push")}
              />
              <Switch
                className="custom-switch"
                checked={viewedEmailChecked}
                onChange={onSwitchChange("viewed-email")}
              />
            </div>
          </div>

          <div className="switch-container">
            <div className="switch-label">
              {t("settings.notify_weekly_update")}
            </div>
            <div className="suffix">
              <Switch
                className="custom-switch"
                checked={weeklyEmailChecked}
                onChange={onSwitchChange("weekly-email")}
              />
            </div>
          </div>

          <div className="switch-container">
            <div className="switch-label">
              {t("settings.notify_moderated_content")}
            </div>
            <div className="suffix">
              <Switch
                className="custom-switch"
                checked={moderatedEmailChecked}
                onChange={onSwitchChange("moderated-email")}
              />
            </div>
          </div>

          {getUserAioData?.data?.modules?.live_streaming_enabled && (
            <div className="switch-container">
              <div className="switch-label">
                {t("settings.notify_streamer_online")}
              </div>
              <div className="suffix">
                <Switch
                  className="custom-switch"
                  checked={streamersOnlineChecked}
                  onChange={onSwitchChange("streamers-online")}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationSettings;
