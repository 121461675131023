// General
import "./network-status.scss";
// Redux
import { useSelector } from "react-redux";
// Material UI
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const NetworkStatus = () => {
  // Redux variables
  const networkQuality = useSelector(
    (state) => state.privateCall.networkQuality
  );
  const uplinkQuality = useSelector((state) => state.privateCall.uplinkQuality);
  const downlinkQuality = useSelector(
    (state) => state.privateCall.downlinkQuality
  );

  return (
    <div id="top-bar-network-status-subcomponent">
      <SignalCellularAltIcon
        className={`signal-icon ${
          networkQuality === 0 || networkQuality === 1 || networkQuality === 2
            ? "signal-good"
            : networkQuality === 3 || networkQuality === 4
            ? "signal-poor"
            : networkQuality === 5 || networkQuality === 6
            ? "signal-bad"
            : null
        }`}
      />
      <ArrowDropUpIcon className="up-arrow-icon" />
      <div className="upload-ping-text">{uplinkQuality}</div>
      <ArrowDropDownIcon className="down-arrow-icon" />
      <div className="download-ping-text">{downlinkQuality}</div>
    </div>
  );
};

export default NetworkStatus;
