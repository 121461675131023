// General
import "./edit-dating-styles.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetJourneyQuestionsQuery,
  useUpdateEditUserProfileMutation,
} from "../../../../../services/data.service";
// Static Data
import utilityConst from "../../../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateDatingStyles,
  updateIsUnsavedChanges,
} from "../../../../../redux/store/editProfileStore";
import {
  updateProfileCompletionPercentage,
  updateProfileRequirementPopup,
} from "../../../../../redux/store/profileStore";
import { updateUpdateProfileFullscreenDialog } from "../../../../../redux/store/dialogStore";
// Material UI
import { useMediaQuery } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Components
import Spinner from "../../../elements/spinner/spinner";

const EditDatingStyles = (props) => {
  const { addPaddingTop = false } = props;

  // API variables
  const [
    getJourneyQuestions,
    {
      data: getJourneyQuestionsData,
      error: getJourneyQuestionsErrorData,
      isFetching: getJourneyQuestionsFetching,
      isLoading: getJourneyQuestionsLoading,
      isSuccess: getJourneyQuestionsSuccess,
      isError: getJourneyQuestionsError,
    },
  ] = useLazyGetJourneyQuestionsQuery();
  const [
    updateProfile,
    {
      data: updateProfileData,
      error: updateProfileErrorData,
      isLoading: updateProfileLoading,
      isSuccess: updateProfileSuccess,
      isError: updateProfileError,
    },
  ] = useUpdateEditUserProfileMutation();

  // General variables
  const [localDatingStyles, setLocalDatingStyles] = useState(null);

  // Redux variables
  const datingStyles = useSelector((state) => state.editProfile.datingStyles);
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    setLocalDatingStyles(datingStyles);

    getJourneyQuestions(null, true);
  }, []);

  // Lifecycle | Check for update | updateProfile API Response
  useEffect(() => {
    if (updateProfileLoading) {
    } else if (updateProfileSuccess) {
      dispatch(
        updateDatingStyles(
          updateProfileData?.data?.user?.relationship?.dating_style
        )
      );
      dispatch(
        updateProfileCompletionPercentage(
          updateProfileData?.data?.user?.profile_progress?.summary?.progress
        )
      );
      dispatch(
        updateProfileRequirementPopup(
          updateProfileData?.data?.user?.profile_progress?.summary?.requirement
        )
      );
      dispatch(updateIsUnsavedChanges(false));
      dispatch(updateUpdateProfileFullscreenDialog(false));
    } else if (updateProfileError) {
    }
  }, [updateProfileLoading, updateProfileSuccess, updateProfileError]);

  // Lifecycle | Check for update | localDatingStyles
  useEffect(() => {
    if (
      datingStyles?.length !== localDatingStyles?.length ||
      datingStyles?.some(
        (item) =>
          !localDatingStyles?.some((localItem) => localItem?.id === item?.id)
      )
    ) {
      dispatch(updateIsUnsavedChanges(true));
    } else {
      dispatch(updateIsUnsavedChanges(false));
    }
  }, [localDatingStyles]);

  // Event Handlers | Button
  const onSelectDatingStyle = (datingStyle) => {
    let datingStyleTemp = [...localDatingStyles];

    if (!datingStyleTemp?.some((item) => item?.id === datingStyle?.id)) {
      if (datingStyleTemp?.length < returnMaxDatingStyle()) {
        datingStyleTemp?.push(datingStyle);
      } else if (returnMaxDatingStyle() === 1) {
        datingStyleTemp = [datingStyle];
      }
    } else {
      datingStyleTemp = datingStyleTemp?.filter(
        (item) => item?.id !== datingStyle?.id
      );
    }
    setLocalDatingStyles(datingStyleTemp);
  };
  const onSaveDatingStyle = () => {
    if (!isFormValid()) return;

    const obj = {
      profile: {
        dating_style: localDatingStyles?.map((item) => item?.id),
      },
    };
    updateProfile(obj);
  };

  // Utility Functions
  const isFormValid = () => {
    return (
      localDatingStyles?.length >= returnMinDatingStyle() &&
      localDatingStyles?.length <= returnMaxDatingStyle() &&
      !updateProfileLoading
    );
  };
  const getDatingStyleData = () => {
    return getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "dating_style"
    )?.selections;
  };
  const checkDatingStyle = (datingStyle) => {
    return localDatingStyles?.find((item) => item?.id === datingStyle?.id);
  };
  const returnMinDatingStyle = () => {
    return (
      getJourneyQuestionsData?.questions?.find(
        (item) => item?.category === "dating_style"
      )?.min_selection || 1
    );
  };
  const returnMaxDatingStyle = () => {
    return (
      getJourneyQuestionsData?.questions?.find(
        (item) => item?.category === "dating_style"
      )?.max_selection || 6
    );
  };

  return (
    <div id="dating-styles-subcomponent">
      <div className="padding-container">
        <div
          className={`top-container ${
            addPaddingTop ? "add-edit-padding-top" : ""
          }`}
        >
          <div className="dating-styles-title">
            {t("profile.express_your_dating_styles")}
          </div>

          <div className="dating-styles-description">
            {t("profile.express_your_dating_styles_desc")}
          </div>

          <div className="dating-styles-choose-options">
            {t("common.chosen_n_out_of_max_options", {
              n: localDatingStyles?.length,
              max: returnMaxDatingStyle(),
            })}
          </div>

          {getJourneyQuestionsFetching || getJourneyQuestionsLoading ? (
            <Spinner />
          ) : (
            <div
              className={`dating-style-body-container ${
                isMobile &&
                getDatingStyleData()?.length >
                  utilityConst.registrationItemFlexAmount
                  ? "flex-start"
                  : "justify-center"
              }`}
            >
              {getDatingStyleData()?.map((item, index) => (
                <div
                  className={`dating-item ${
                    isMobile &&
                    getDatingStyleData()?.length <=
                      utilityConst.registrationItemFlexAmount
                      ? "partial-width"
                      : isMobile &&
                        getDatingStyleData()?.length >
                          utilityConst.registrationItemFlexAmount
                      ? "fit-content"
                      : "fit-content"
                  } ${checkDatingStyle(item) ? "selected" : ""}`}
                  onClick={() => onSelectDatingStyle(item)}
                  key={index}
                >
                  <div className="dating-style-emoji-container">
                    {item?.emoji}
                  </div>

                  <div className="dating-style-label">
                    {item?.display_locale}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="bottom-container">
        <div
          className={`save-button ${isFormValid() ? "" : "disabled-button"}`}
          onClick={onSaveDatingStyle}
        >
          {updateProfileLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("profile.tor_selected", {
              n: localDatingStyles?.length,
              max: returnMaxDatingStyle(),
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default EditDatingStyles;
