// General
import { createSlice } from "@reduxjs/toolkit";
// Services
import { sessionService } from "../../services/session.service";

const initialState = {
  // General variables
  showDebugInfo: false,
  showLog: false,

  // Pusher variables
  globalPusherState: null,
  liveViewPusherState: null,
  videoCallPusherState: null,
  messagingPusherState: null,

  // Channel variables
  globalChannelState: null,
  liveViewChannelState: null,
  liveViewPublicChannelState: null,
  liveViewPublicChannelGlobalState: null,
  videoCallChannelState: null,
  messagingChannelState: null,

  // Livestream variables
  livestreamKingOfTheMonthTrigger: null,
  livestreamGiftAnimationTrigger: null,
};

export const debugSlice = createSlice({
  name: "debug",
  initialState,
  reducers: {
    // General Functions
    updateShowDebugInfo: (state, action) => {
      state.showDebugInfo = action.payload;
      sessionService.setDebugMode(action.payload ?? false);
    },
    updateShowLog: (state, action) => {
      state.showLog = action.payload;
      sessionService.setShowLog(action.payload ?? false);
    },

    // Pusher Functions
    updateGlobalPusherState: (state, action) => {
      state.globalPusherState = action.payload;
    },
    updateLiveViewPusherState: (state, action) => {
      state.liveViewPusherState = action.payload;
    },
    updateVideoCallPusherState: (state, action) => {
      state.videoCallPusherState = action.payload;
    },
    updateMessagingPusherState: (state, action) => {
      state.messagingPusherState = action.payload;
    },

    // Channel Functions
    updateGlobalChannelState: (state, action) => {
      state.globalChannelState = action.payload;
    },
    updateLiveViewChannelState: (state, action) => {
      state.liveViewChannelState = action.payload;
    },
    updateLiveViewPublicChannelState: (state, action) => {
      state.liveViewPublicChannelState = action.payload;
    },
    updateLiveViewPublicChannelGlobalState: (state, action) => {
      state.liveViewPublicChannelGlobalState = action.payload;
    },
    updateVideoCallChannelState: (state, action) => {
      state.videoCallChannelState = action.payload;
    },
    updateMessagingChannelState: (state, action) => {
      state.messagingChannelState = action.payload;
    },

    // Livestream Functions
    updateLivestreamKingOfTheMonthTrigger: (state, action) => {
      state.livestreamKingOfTheMonthTrigger = action.payload;
    },
    updateLivestreamGiftAnimationTrigger: (state, action) => {
      state.livestreamGiftAnimationTrigger = action.payload;
    },

    // Utility Functions
    resetDebugStore: () => {
      return { ...initialState };
    },
  },
});

export const {
  // General Functions
  updateShowDebugInfo,
  updateShowLog,

  // Pusher Functions
  updateGlobalPusherState,
  updateLiveViewPusherState,
  updateVideoCallPusherState,
  updateMessagingPusherState,

  updateGlobalChannelState,
  updateLiveViewChannelState,
  updateLiveViewPublicChannelState,
  updateLiveViewPublicChannelGlobalState,
  updateVideoCallChannelState,
  updateMessagingChannelState,

  // Livestream Functions
  updateLivestreamKingOfTheMonthTrigger,
  updateLivestreamGiftAnimationTrigger,

  // Utility Functions
  resetDebugStore,
} = debugSlice.actions;

export default debugSlice.reducer;
