// General
import "./search-filter.scss";
// Static Data
import searchConst from "../../../../const/searchConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  composeSearchBody,
  resetSearchBodyFilter,
  resetTorListSelected,
  updateSelectedTorAfterApply,
  resetSelectedTorAfterApply,
} from "../../../../redux/store/searchStore";
import {
  resetSearchFilter,
  updateIsFiltered,
} from "../../../../redux/store/searchFilterStore";
// Material UI
import { Divider } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Components
import SearchInput from "../../../shared/dialog-content/search-filter-dialog/search-input/search-input";
import Location from "../../../shared/dialog-content/search-filter-dialog/location/location";
import TorFilter from "../../../shared/elements/filters/tor-filter/tor-filter";
import BasicFilters from "../../../shared/dialog-content/search-filter-dialog/basic-filters/basic-filters";
import AgeRange from "../../../shared/dialog-content/search-filter-dialog/age-range/age-range";
import HeightRange from "../../../shared/dialog-content/search-filter-dialog/height-range/height-range";
import AdvancedFilters from "../../../shared/dialog-content/search-filter-dialog/advanced-filters/advanced-filters";

const SearchFilter = () => {
  // Redux variables
  const keyword = useSelector((state) => state.searchFilter.keyword);
  const location = useSelector((state) => state.searchFilter.location);
  const location_data = useSelector(
    (state) => state.searchFilter.location_data
  );
  const premiumOnly = useSelector((state) => state.searchFilter.premiumOnly);
  const photos = useSelector((state) => state.searchFilter.photos);
  const viewed = useSelector((state) => state.searchFilter.viewed);
  const unviewed = useSelector((state) => state.searchFilter.unviewed);
  const favorited = useSelector((state) => state.searchFilter.favorited);
  const favoritedMe = useSelector((state) => state.searchFilter.favoritedMe);
  const minAge = useSelector((state) => state.searchFilter.minAge);
  const maxAge = useSelector((state) => state.searchFilter.maxAge);
  const minHeight = useSelector((state) => state.searchFilter.minHeight);
  const maxHeight = useSelector((state) => state.searchFilter.maxHeight);
  const lifestyleData = useSelector(
    (state) => state.searchFilter.lifestyleData
  );
  const bodyTypeData = useSelector((state) => state.searchFilter.bodyTypeData);
  const ethnicityData = useSelector(
    (state) => state.searchFilter.ethnicityData
  );
  const eyeColorData = useSelector((state) => state.searchFilter.eyeColorData);
  const hairColorData = useSelector(
    (state) => state.searchFilter.hairColorData
  );
  const smokingData = useSelector((state) => state.searchFilter.smokingData);
  const drinkingData = useSelector((state) => state.searchFilter.drinkingData);
  const relationshipStatusData = useSelector(
    (state) => state.searchFilter.relationshipStatusData
  );
  const educationData = useSelector(
    (state) => state.searchFilter.educationData
  );
  const tor = useSelector((state) => state.searchFilter.tor);
  const isFiltered = useSelector((state) => state.searchFilter.isFiltered);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Event Handlers | Button
  const onApplyFilter = () => {
    const obj = {
      keyword,
      location,
      location_data,
      premiumOnly,
      photos,
      viewed,
      unviewed,
      favorited,
      favoritedMe,
      minAge,
      maxAge,
      minHeight,
      maxHeight,
      lifestyle: lifestyleData,
      bodyType: bodyTypeData,
      ethnicity: ethnicityData,
      eyeColor: eyeColorData,
      hairColor: hairColorData,
      smoking: smokingData,
      drinking: drinkingData,
      relationshipStatus: relationshipStatusData,
      education: educationData,
      tor,
    };

    dispatch(composeSearchBody(obj));
    dispatch(updateIsFiltered());
    dispatch(updateSelectedTorAfterApply(tor));
  };
  const onResetFilter = () => {
    dispatch(resetSearchBodyFilter());
    dispatch(resetSearchFilter());
    dispatch(resetTorListSelected());
    dispatch(updateIsFiltered());
    dispatch(resetSelectedTorAfterApply());
  };

  return (
    <div id="search-search-filter-subcomponent">
      <SearchInput />

      <Divider />

      <Location />

      <Divider />

      <TorFilter filtered={true} />

      <Divider />

      <BasicFilters type={searchConst.searchFilterType.full} />

      <Divider />

      <AgeRange type={searchConst.searchFilterType.full} />

      <Divider />

      <HeightRange type={searchConst.searchFilterType.full} />

      <AdvancedFilters type={searchConst.searchFilterType.full} />

      <div className="apply-filter-button" onClick={onApplyFilter}>
        {t("filter.apply_filters")}
      </div>

      {isFiltered && (
        <div className="reset-filter-button" onClick={onResetFilter}>
          {t("filter.reset")}
        </div>
      )}
    </div>
  );
};

export default SearchFilter;
