// General
import "./video-player.scss";
import { useState, useEffect } from "react";

const VideoPlayer = () => {
  // General variables
  const [livePusher, setLivePusher] = useState(null);

  // Lifecycle | Mounted
  useEffect(() => {
    window.TXLivePusher.checkSupport().then(function (data) {
      // Whether WebRTC is supported
      if (data.isWebRTCSupported) {
        console.log("WebRTC Support");

        setLivePusher(new window.TXLivePusher());
      } else {
        console.log("WebRTC Not Support");
      }
      // Whether H.264 is supported
      if (data.isH264EncodeSupported) {
        console.log("H264 Encode Support");
      } else {
        console.log("H264 Encode Not Support");
      }
    });
  }, []);

  // Lifecycle | Check for update | livePusher
  useEffect(() => {
    if (!livePusher) return;

    // Set video quality
    livePusher?.setProperty("setVideoResolution", { width: 720, height: 1280 });
    // Set audio quality
    livePusher?.setAudioQuality("standard");
    // Set the frame rate
    livePusher?.setProperty("setVideoFPS", 15);

    // Turn the camera on
    livePusher?.startCamera();
    // Turn the mic on
    livePusher?.startMicrophone();

    // Render to div
    livePusher?.setRenderView("livestreamer-video-player");

    // Event callbacks
    livePusher.setObserver({
      // Warnings for push
      onWarning: function (code, msg) {
        console.log(code, msg);
      },
      // Push status
      onPushStatusUpdate: function (status, msg) {
        console.log(status, msg);
      },
    });
  }, [livePusher]);

  return <div id="livestreamer-video-player"></div>;
};

export default VideoPlayer;
