// General
import "./edit-dating-budget.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetJourneyQuestionsQuery,
  useUpdateEditUserProfileMutation,
} from "../../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateDatingBudget,
  updateUpdateProfileFullscreenTab,
  updateIsUnsavedChanges,
} from "../../../../../redux/store/editProfileStore";
import {
  updateProfileCompletionPercentage,
  updateProfileRequirementPopup,
} from "../../../../../redux/store/profileStore";
import { updateUpdateProfileFullscreenDialog } from "../../../../../redux/store/dialogStore";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../../elements/spinner/spinner";

const EditDatingBudget = (props) => {
  const { addPaddingTop = false } = props;

  // API variables
  const [
    getJourneyQuestions,
    {
      data: getJourneyQuestionsData,
      error: getJourneyQuestionsErrorData,
      isFetching: getJourneyQuestionsFetching,
      isLoading: getJourneyQuestionsLoading,
      isSuccess: getJourneyQuestionsSuccess,
      isError: getJourneyQuestionsError,
    },
  ] = useLazyGetJourneyQuestionsQuery();
  const [
    updateProfile,
    {
      data: updateProfileData,
      error: updateProfileErrorData,
      isLoading: updateProfileLoading,
      isSuccess: updateProfileSuccess,
      isError: updateProfileError,
    },
  ] = useUpdateEditUserProfileMutation();

  // General variables
  const [localDatingBudget, setLocalDatingBudget] = useState(null);

  // Redux variables
  const datingBudget = useSelector((state) => state.editProfile.datingBudget);
  const profileCompletionState = useSelector(
    (state) => state.editProfile.profileCompletionState
  );
  const updateProfileFullscreenTab = useSelector(
    (state) => state.editProfile.updateProfileFullscreenTab
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    setLocalDatingBudget(datingBudget);

    getJourneyQuestions(null, true);
  }, []);

  // Lifecycle | Check for update | updateProfile API Response
  useEffect(() => {
    if (updateProfileLoading) {
    } else if (updateProfileSuccess) {
      dispatch(
        updateDatingBudget(
          updateProfileData?.data?.user?.relationship?.dating_budget
        )
      );
      dispatch(
        updateProfileCompletionPercentage(
          updateProfileData?.data?.user?.profile_progress?.summary?.progress
        )
      );
      dispatch(
        updateProfileRequirementPopup(
          updateProfileData?.data?.user?.profile_progress?.summary?.requirement
        )
      );
      dispatch(updateIsUnsavedChanges(false));

      if (profileCompletionState) {
        const networth = updateProfileData?.data?.user?.occupation?.networth;
        const annualIncome =
          updateProfileData?.data?.user?.occupation?.annual_income;
        const datingBudget =
          updateProfileData?.data?.user?.relationship?.dating_budget;

        if (!datingBudget) {
          dispatch(updateUpdateProfileFullscreenTab(0));
        } else if (!networth) {
          dispatch(updateUpdateProfileFullscreenTab(1));
        } else if (!annualIncome) {
          dispatch(updateUpdateProfileFullscreenTab(2));
        } else {
          dispatch(updateUpdateProfileFullscreenDialog(false));
        }
      } else {
        dispatch(updateUpdateProfileFullscreenDialog(false));
      }
    } else if (updateProfileError) {
    }
  }, [updateProfileLoading, updateProfileSuccess, updateProfileError]);

  // Lifecycle | Check for update | localRace
  useEffect(() => {
    if (localDatingBudget?.id !== datingBudget?.id) {
      dispatch(updateIsUnsavedChanges(true));
    } else {
      dispatch(updateIsUnsavedChanges(false));
    }
  }, [localDatingBudget]);

  // Event Handlers | Button
  const onSelectDatingBudget = (dating) => {
    setLocalDatingBudget(dating);
  };
  const onSaveDatingBudget = () => {
    if (!isFormValid()) return;

    const obj = {
      profile: {
        dating_budget: localDatingBudget?.id,
      },
    };
    updateProfile(obj);
  };

  // Utility Functions
  const isFormValid = () => {
    return localDatingBudget && !updateProfileLoading;
  };
  const getDatingBudgetData = () => {
    return getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "dating_budget"
    )?.selections;
  };

  return (
    <div id="dating-budget-subcomponent">
      <div className="padding-container">
        <div
          className={`top-container ${
            addPaddingTop ? "add-edit-padding-top" : ""
          }`}
        >
          <div className="dating-budget-icon-container">
            {getIcon("datingBudgetIcon", "dating-budget-icon")}
          </div>

          <div className="dating-budget-title">
            {t("profile.whats_your_dating_budget")}
          </div>

          {getJourneyQuestionsFetching || getJourneyQuestionsLoading ? (
            <Spinner />
          ) : (
            <div className="dating-budget-body-container">
              {getDatingBudgetData()?.map((item, index) => (
                <div
                  className={`dating-budget-item ${
                    item?.id === localDatingBudget?.id ? "selected" : ""
                  }`}
                  onClick={() => onSelectDatingBudget(item)}
                  key={index}
                >
                  {item?.display_locale}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="bottom-container">
        <div
          className={`save-button ${isFormValid() ? "" : "disabled-button"}`}
          onClick={onSaveDatingBudget}
        >
          {updateProfileLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("common.save")
          )}
        </div>
      </div>
    </div>
  );
};

export default EditDatingBudget;
