// General
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // General variables
  inboxTab: 0,

  // Main Page variables
  chatLists: [],
  unreadLists: [],
  chatPagination: null,
  unreadPagination: null,

  // Conversation variables
  conversationLists: [],
  conversationId: null,
  conversationIdInt: null,
  conversationUser: null,
  conversationObj: null,

  // Private Call variables
  ownPrivateCallEnable: null,
  otherUserPrivateCallEnable: null,
  initiatePrivateCall: null,

  // Scroll variables
  inboxScrollYPosition: 0,
  inboxScrollToTopPassthrough: {},

  // Utility variables
  conversationSelectPassthrough: {},
  conversationUserProfilePassthrough: {},
  selectedConversation: null,
  newUserConversation: null,
  newUserConversationPassthrough: null,
};

export const inboxSlice = createSlice({
  name: "inbox",
  initialState,
  reducers: {
    // General variables
    updateInboxTab: (state, action) => {
      state.inboxTab = action.payload;
    },

    // Main Page Functions
    updateChatLists: (state, action) => {
      const chatListsData = [...state.chatLists, ...action.payload];
      state.chatLists = chatListsData;
    },
    updateChatListsFromTop: (state, action) => {
      const chatListsData = [...action.payload, ...state.chatLists];
      state.chatLists = chatListsData;
    },
    updateUnreadLists: (state, action) => {
      const unreadListsData = [...state.unreadLists, ...action.payload];
      state.unreadLists = unreadListsData;
    },
    updateChatPagination: (state, action) => {
      state.chatPagination = action.payload;
    },
    updateUnreadPagination: (state, action) => {
      state.unreadPagination = action.payload;
    },
    updateChatListsLastMessage: (state, action) => {
      // Update last message shown in inbox when viewing conversations
      const conversationId = action.payload.conversation_id;
      const conversationIdInt = action.payload.conversation_id_int;
      const lastMessageObj = action.payload.data.message;
      const otherUser = action.payload.sender;

      const apiMessageObj = {
        conversation_accepted: true,
        conversation_id: conversationId,
        conversation_id_int: conversationIdInt,
        last_message: lastMessageObj,
        other_user: otherUser,
      };

      const chatListsData = [...state.chatLists];
      const unreadListsData = [...state.unreadLists];

      if (chatListsData.length > 0) {
        for (let i = 0; i < chatListsData.length; i++) {
          if (chatListsData[i].conversation_id === conversationId) {
            chatListsData[i].last_message = lastMessageObj;
            break;
          }
        }

        // Move conversation to top of chatLists
        const chatListsConversationObj = chatListsData.find(
          (item) => item.conversation_id === conversationId
        );
        if (chatListsConversationObj) {
          chatListsData.splice(
            chatListsData.indexOf(chatListsConversationObj),
            1
          );
          chatListsData.unshift(chatListsConversationObj);

          state.chatLists = chatListsData;
        } else {
          // If conversation not found, add to top of chatLists
          chatListsData.unshift(apiMessageObj);
          state.chatLists = chatListsData;
        }
      }

      if (unreadListsData.length > 0) {
        for (let i = 0; i < unreadListsData.length; i++) {
          if (unreadListsData[i].conversation_id === conversationId) {
            unreadListsData[i].last_message = lastMessageObj;
            break;
          }
        }

        // Move conversation to top of chatLists
        const unreadListsConversationObj = chatListsData.find(
          (item) => item.conversation_id === conversationId
        );
        if (unreadListsConversationObj) {
          unreadListsData.splice(
            unreadListsData.indexOf(unreadListsConversationObj),
            1
          );
          unreadListsData.unshift(unreadListsConversationObj);

          state.unreadLists = unreadListsData;
        }
      }
    },
    resetChatLists: (state) => {
      state.chatLists = [];
      state.chatPagination = null;
    },
    resetUnreadLists: (state) => {
      state.unreadLists = [];
      state.unreadPagination = null;
    },

    // Conversation Functions
    updateConversationLists: (state, action) => {
      // Will cause UI to jump
      const conversationListData = [
        ...state.conversationLists,
        ...action.payload,
      ];

      // Sort array by date decending order
      conversationListData.sort((a, b) => {
        return new Date(a.message_timestamp) - new Date(b.message_timestamp);
      });

      // Check if conversationListsData have more than 1 message_type = "video-call"
      // if yes, remove all message_type = "video-call" except the last one
      if (conversationListData.length > 0) {
        const videoCallIndexes = conversationListData.reduce(
          (indexes, item, index) => {
            if (item.message_type === "video-call") {
              indexes.push(index);
            }
            return indexes;
          },
          []
        );

        if (videoCallIndexes.length > 1) {
          // Remove all message_type = "video-call" except the last one
          videoCallIndexes.forEach((item, index) => {
            if (index !== videoCallIndexes.length - 1) {
              conversationListData.splice(item, 1);
            }
          });
        }
      }

      state.conversationLists = conversationListData;
    },
    updateConversationListsFromTop: (state, action) => {
      state.conversationLists = [...state.conversationLists, ...action.payload];
    },
    updateConversationListsFromBottom: (state, action) => {
      state.conversationLists = [...action.payload, ...state.conversationLists];
    },
    updateConversationId: (state, action) => {
      state.conversationId = action.payload;
    },
    updateConversationIdInt: (state, action) => {
      state.conversationIdInt = action.payload;
    },
    updateConversationUser: (state, action) => {
      state.conversationUser = action.payload;
    },
    updateConversationObj: (state, action) => {
      state.conversationObj = action.payload;
    },
    resetConversationObj: (state, action) => {
      state.conversationObj = null;
    },
    resetConversationLists: (state) => {
      state.conversationLists = [];
    },

    // Private Call Functions
    updateOwnPrivateCallEnable: (state, action) => {
      state.ownPrivateCallEnable = action.payload;
    },
    updateOtherUserPrivateCallEnable: (state, action) => {
      state.otherUserPrivateCallEnable = action.payload;
    },
    updateInitiatePrivateCall: (state, action) => {
      state.initiatePrivateCall = action.payload;
    },

    // Scroll Functions
    updateInboxScrollYPosition: (state, action) => {
      state.inboxScrollYPosition = action.payload;
    },
    updateInboxScrollToTopPassthrough: (state, action) => {
      state.inboxScrollToTopPassthrough = action.payload;
    },

    // Utility Functions
    resetInboxStore: () => {
      return { ...initialState };
    },
    updateConversationSelectPassthrough: (state, action) => {
      state.conversationSelectPassthrough = action.payload;
    },
    updateConversationUserProfilePassthrough: (state, action) => {
      state.conversationUserProfilePassthrough = action.payload;
    },
    updateSelectedConversation: (state, action) => {
      state.selectedConversation = action.payload;
    },
    updateNewUserConversation: (state, action) => {
      state.newUserConversation = action.payload;
    },
    updateNewUserConversationPassthrough: (state) => {
      state.newUserConversationPassthrough = {};
    },
  },
});

export const {
  // General Functions
  updateInboxTab,

  // Main Page Functions
  updateChatLists,
  updateChatListsFromTop,
  updateUnreadLists,
  updateChatPagination,
  updateUnreadPagination,
  updateChatListsLastMessage,
  resetChatLists,
  resetUnreadLists,

  // General Functions
  updateConversationLists,
  updateConversationListsFromTop,
  updateConversationListsFromBottom,
  updateConversationId,
  updateConversationIdInt,
  updateConversationUser,
  updateConversationObj,
  resetConversationObj,
  resetConversationLists,

  // Private Call Functions
  updateOwnPrivateCallEnable,
  updateOtherUserPrivateCallEnable,
  updateInitiatePrivateCall,

  // Scroll Functions
  updateInboxScrollYPosition,
  updateInboxScrollToTopPassthrough,

  // Utility Functions
  resetInboxStore,
  updateConversationSelectPassthrough,
  updateConversationUserProfilePassthrough,
  updateSelectedConversation,
  updateNewUserConversation,
  updateNewUserConversationPassthrough,
} = inboxSlice.actions;

export default inboxSlice.reducer;
