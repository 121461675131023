// General
import "./ethnicity.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetJourneyQuestionsQuery,
  useUpdateJourneyMutation,
} from "../../../../services/data.service";
// Static Data
import utilityConst from "../../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateEthnicity,
  updateJourneyNavigationPassthrough,
  updateJourneyDataPassthrough,
  updateRegistrationNavigationData,
} from "../../../../redux/store/registrationStore";
import { updateErrorToast } from "../../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { useMediaQuery } from "@mui/material";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Components
import Spinner from "../../../shared/elements/spinner/spinner";

const EthnicityDetails = () => {
  // API variables
  const [
    getJourneyQuestions,
    {
      data: getJourneyQuestionsData,
      error: getJourneyQuestionsErrorData,
      isFetching: getJourneyQuestionsFetching,
      isLoading: getJourneyQuestionsLoading,
      isSuccess: getJourneyQuestionsSuccess,
      isError: getJourneyQuestionsError,
    },
  ] = useLazyGetJourneyQuestionsQuery();
  const [
    updateJourney,
    {
      data: updateJourneyData,
      error: updateJourneyErrorData,
      isLoading: updateJourneyLoading,
      isSuccess: updateJourneySuccess,
      isError: updateJourneyError,
    },
  ] = useUpdateJourneyMutation();
  const [
    skipJourney,
    {
      data: skipJourneyData,
      error: skipJourneyErrorData,
      isLoading: skipJourneyLoading,
      isSuccess: skipJourneySuccess,
      isError: skipJourneyError,
    },
  ] = useUpdateJourneyMutation();

  // Redux variables
  const ethnicity = useSelector((state) => state.registration.ethnicity);
  const skippableJourney = useSelector(
    (state) => state.registration.skippableJourney
  );
  const registrationNavigationData = useSelector(
    (state) => state.registration.registrationNavigationData
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    getJourneyQuestions(null, true);
  }, []);

  // Lifecycle | Check for update | updateJourney API Response
  useEffect(() => {
    if (updateJourneyLoading) {
    } else if (updateJourneySuccess) {
      dispatch(updateJourneyDataPassthrough(updateJourneyData));
      dispatch(updateJourneyNavigationPassthrough({}));
    } else if (updateJourneyError) {
      const warningToast = {
        message: updateJourneyErrorData?.data?.message,
        autoClose: 3000,
      };
      dispatch(updateErrorToast(warningToast));
    }
  }, [updateJourneyLoading, updateJourneySuccess, updateJourneyError]);

  // Lifecycle | Check for update | skipJourney API Response
  useEffect(() => {
    if (skipJourneyLoading) {
    } else if (skipJourneySuccess) {
      const updatedItems = registrationNavigationData?.map((item, i) => ({
        ...item,
        skipped: i === 7 ? true : i > 7 ? false : item.skipped,
      }));
      dispatch(updateRegistrationNavigationData(updatedItems));

      dispatch(updateJourneyDataPassthrough(updateJourneyData));
      dispatch(updateJourneyNavigationPassthrough({}));
    } else if (skipJourneyError) {
    }
  }, [skipJourneyLoading, skipJourneySuccess, skipJourneyError]);

  // Event Handlers | Button
  const onSelectEthnicity = (ethnicity) => {
    dispatch(updateEthnicity(ethnicity));
  };
  const onNextPage = () => {
    if (!isFormValid()) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-62-62.8-62.8.1",
      },
    });

    const obj = {
      journey_step: "8",
      journey_data: {
        profile: {
          ethnicity: ethnicity?.id,
          race: null,
        },
      },
    };
    updateJourney(obj);
  };
  const onSkip = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-62-62.8-62.8.2",
      },
    });

    const obj = {
      journey_step: "9",
      journey_data: {
        looking_for: {},
      },
    };
    skipJourney(obj);
  };

  // Utility Functions
  const isFormValid = () => {
    return ethnicity && !updateJourneyLoading;
  };
  const getEthnicityData = () => {
    return getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "ethnicity"
    )?.selections;
  };

  return (
    <div id="details-signup-ethnicity-details-subcomponent">
      <div className="padding-container">
        <div className="top-container">
          <div className="ethnicity-details-label">
            <Trans
              i18nKey={"registration.ethnicity.title"}
              components={{ br: <br /> }}
            />
          </div>

          {getJourneyQuestionsFetching || getJourneyQuestionsLoading ? (
            <Spinner />
          ) : (
            <div
              className={`ethnicity-body-container ${
                isMobile &&
                getEthnicityData()?.length >
                  utilityConst.registrationItemFlexAmount
                  ? "flex-start"
                  : "justify-center"
              }`}
            >
              {getEthnicityData()?.map((item, index) => (
                <div
                  className={`ethnicity-item ${
                    getEthnicityData()?.length <=
                    utilityConst.registrationItemFlexAmount
                      ? "partial-width"
                      : getEthnicityData()?.length >
                        utilityConst.registrationItemFlexAmount
                      ? "fit-content"
                      : "fit-content"
                  } ${item?.id === ethnicity?.id ? "selected" : ""}`}
                  onClick={() => onSelectEthnicity(item)}
                  key={index}
                >
                  {item?.display_locale}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="bottom-container">
        <div
          className={`continue-button ${
            isMobile ? "full-width" : "fit-content"
          } ${isFormValid() ? "" : "disabled-button"}`}
          onClick={onNextPage}
        >
          {updateJourneyLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("common.continue")
          )}
        </div>

        <div className="skip-button" onClick={onSkip}>
          {skipJourneyLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("common.skip")
          )}
        </div>
      </div>
    </div>
  );
};

export default EthnicityDetails;
