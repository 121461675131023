// General
import "./livestream-mini-player.scss";
import { useState, useEffect, useRef } from "react";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateIsMiniPlayer,
  updateMiniPlayerSource,
} from "../../../../redux/store/livestreamingStore";
// react-draggable
import Draggable from "react-draggable";
// tcplayer.js
import TCPlayer from "tcplayer.js";
// Material UI
import CloseIcon from "@mui/icons-material/Close";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";

const LivestreamMiniPlayer = () => {
  // General variables
  const [tcPlayer, setTcPlayer] = useState(null);
  const videoRef = useRef(null);

  // Redux variables
  const isMiniPlayer = useSelector((state) => state.livestreaming.isMiniPlayer);
  const miniPlayerSource = useSelector(
    (state) => state.livestreaming.miniPlayerSource
  );
  const miniPlayerChannelId = useSelector(
    (state) => state.livestreaming.miniPlayerChannelId
  );
  const videoPlayerMute = useSelector(
    (state) => state.livestreaming.videoPlayerMute
  );
  const dispatch = useDispatch();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    if (isMiniPlayer && miniPlayerSource) {
      if (!tcPlayer) {
        setTcPlayer(
          TCPlayer("live-mini-tc-player", {
            // sources: [{ src: videoUrl }],
            language: "en",
            children: {},
            controls: false,
            muted: videoPlayerMute,
          })
        );
      }
    } else {
      if (tcPlayer) {
        tcPlayer.dispose();
        setTcPlayer(null);
      }
    }
  }, [isMiniPlayer, miniPlayerSource]);

  // Lifecycle | Check for update | tcPlayer
  useEffect(() => {
    if (!tcPlayer) return;

    tcPlayer.src(miniPlayerSource);
  }, [tcPlayer]);

  const eventLogger = (e, data) => {
    console.log("Event: ", e);
    console.log("Data: ", data);
  };
  const handleDrag = (e, data) => {
    // eventLogger("Drag", data);
  };

  // Event Handlers | Button
  const onCloseMiniPlayer = () => {
    dispatch(updateIsMiniPlayer(false));
    dispatch(updateMiniPlayerSource(null));

    if (tcPlayer) {
      tcPlayer.dispose();
      setTcPlayer(null);
    }
  };
  const onReturnToFullscreen = () => {
    dispatch(updateIsMiniPlayer(false));
    dispatch(updateMiniPlayerSource(null));

    if (tcPlayer) {
      tcPlayer.dispose();
      setTcPlayer(null);
    }

    onNavigate(`${routeConst.live.liveId.clearPath}${miniPlayerChannelId}`);
  };

  if (isMiniPlayer && miniPlayerSource) {
    return (
      <Draggable
        cancel="strong"
        defaultPosition={{ x: 10, y: 10 }}
        // positionOffset={{ x: "100%", y: "100%" }}
        scale={1}
        onDrag={handleDrag}
      >
        <div id="livestream-mini-player">
          <div className="live-mini-tc-player-container">
            <video
              id="live-mini-tc-player"
              ref={videoRef}
              playsInline
              preload="auto"
              muted={videoPlayerMute}
              autoPlay
            ></video>
          </div>

          <strong className="mini-player-controls-container">
            <CloseIcon className="close-icon" onClick={onCloseMiniPlayer} />

            <FullscreenIcon
              className="fullscreen-icon"
              onClick={onReturnToFullscreen}
            />
          </strong>
        </div>
      </Draggable>
    );
  }
};

export default LivestreamMiniPlayer;
