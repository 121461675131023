// General
import "./interactable-overlay.scss";
// Components
import TopBanner from "./top-banner/top-banner";
import TopBar from "./topbar/topbar";
import TipMenu from "./tip-menu/tip-menu";
import ChatSection from "../../elements/chat-section/chat-section";
import GiftsSection from "../../elements/gifts-section/gifts-section";
import ChatInput from "./chat-input/chat-input";

const InteractableOverlay = () => {
  return (
    <div id="private-standby-interactable-overlay-subcomponent">
      <div className="top-section">
        <TopBanner />

        <div className="padding-container">
          <TopBar />

          <TipMenu />
        </div>
      </div>

      <div className="bottom-section">
        <ChatSection state={"private-call"} />

        <GiftsSection state={"private-call"} />

        <ChatInput />
      </div>
    </div>
  );
};

export default InteractableOverlay;
