const tutorialConst = {
  videoCall: [
    { title: "profile.tutorial_introduction", value: "introduction", pages: 2 },
    { title: "profile.tutorial_tip_menu", value: "tip-menu", pages: 1 },
    // { title: "profile.tutorial_settings", value: "settings", pages: 1 }, Removed temporarily
    { title: "profile.tutorial_earnings", value: "earnings", pages: 1 },
    { title: "profile.tutorial_gift_request", value: "gift-request", pages: 2 },
  ],
};

export default tutorialConst;
