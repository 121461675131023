// General
import "./coins-discount-dialog.scss";
import { useEffect } from "react";
// Services
import { useLazyGetUserAioQuery } from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateCoinsDiscountDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";

const CoinsDiscountDialog = () => {
  // API variables
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();

  // Redux variables
  const coinsDiscountDialog = useSelector(
    (state) => state.dialog.coinsDiscountDialog
  );
  const dispatch = useDispatch();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!coinsDiscountDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-CoinsDiscount-Dialog",
      },
    });

    getUserAio(null, true);
  }, [coinsDiscountDialog]);

  // Event Handlers | Button
  const onOpenCoinShop = () => {
    onNavigate(routeConst.profile.wallet.path);
    onCloseDialog();
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateCoinsDiscountDialog(false));
  };

  // Utility Functions
  const getCoinsDiscountBanner = () => {
    if (!getUserAioSuccess) {
      return null;
    }

    const userGraphics =
      getUserAioData?.data?.promotions?.p_20210818?.graphics || [];

    const coinShopGraphic = userGraphics.find((graphic) => {
      return graphic.view === "close_stream" && graphic.cta === "coin_shop";
    });

    return coinShopGraphic?.asset_url || null;
  };

  return (
    <Dialog
      className="custom-radius35-dialog"
      open={coinsDiscountDialog}
      onClose={onCloseDialog}
    >
      <div id="coins-discount-dialog">
        <div className="dialog-header">
          <div className="close-button-container">
            <CloseIcon className="close-button" onClick={onCloseDialog} />
          </div>
        </div>

        <div className="coins-discount-banner-container">
          <img
            className="coins-discount-banner"
            src={getCoinsDiscountBanner()}
          />

          <div className="redirect-button" onClick={onOpenCoinShop}></div>
        </div>
      </div>
    </Dialog>
  );
};

export default CoinsDiscountDialog;
