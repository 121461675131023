// General
import "./viewer-list.scss";
// Static Data
import routeConst from "../../../../../../const/routeConst";
// Redux
import { useDispatch } from "react-redux";
import {
  // User View
  updateId,
  updateIdInt,
  updateUsername,
  updateAge,
  updateGender,
  updateLocation,
  updateMembershipType,
  updateVerifiedProfile,
  updateVerifiedCaller,
  updateLevellingBadge,
  updateLivestreamingAchievementBadge,
  updateSpecialBadges,
  updateProfilePhoto,
  updateProfileDecorations,
  updatePublicPhotos,
  updatePrivatePhotos,
  updateTorTags,
  updateProfileAchievementBadge,
  updateAboutMe,
  updateAboutMeManualText,
  updateAboutMeReadStatus,
  updateLookingFor,
  updateOccupation,
  updateLifestyle,
  updateEducation,
  updateRelationship,
  updateSmoking,
  updateDrinking,
  updateHeight,
  updateBodyType,
  updateEthnicity,
  updateEyeColor,
  updateHairColor,
  updateChildren,
  // About Me Functions
  updatePersonalityType,
  updateHobbies,
  updateStarSign,
  updateFitnessLevel,
  updateDominantOrSubmissive,
  updatePersonalityTraits,
  // Looking For Functions
  updateLookingForAgeRange,
  updateLookingForLocation,
  updateLookingForEthnicityRace,
  // User Utility
  updateShouldCallApi,
  updatePrivatePhotoRequested,
  updatePrivatePhotoApproval,
  updateRequestedMyPrivatePhoto,
  updateApprovedMyPrivatePhoto,
  updateIsBlocked,
  updateIsSilentInteraction,
  updateIsFavorited,
  updateIsCallEnabled,
  updateIsSugarbaby,
  updateRole,
} from "../../../../../../redux/store/userCardStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import CustomAvatar from "../../../../../shared/elements/custom-avatar/custom-avatar";

const ViewerList = (props) => {
  const {
    // User View
    id,
    idInt,
    username,
    age,
    gender,
    location,
    membershipType,
    verifiedProfile,
    verifiedCaller,
    levellingBadge,
    livestreamingAchievementBadge,
    specialBadges,
    profilePhoto,
    profileDecorations,
    publicPhotos,
    privatePhotos,
    torTags,
    profileAchievementBadge,
    aboutMe,
    aboutMeReadStatus,
    lookingFor,
    occupation,
    lifestyle,
    education,
    relationship,
    smoking,
    drinking,
    height,
    bodyType,
    ethnicity,
    eyeColor,
    hairColor,
    children,
    // About Me Functions
    personalityType,
    hobbies,
    starSign,
    fitnessLevel,
    dominantOrSubmissive,
    personalityTraits,
    // Looking For Functions
    lookingForAgeRange,
    lookingForLocation,
    lookingForEthnicityRace,
    // User Utility
    shouldCallApi,
    privatePhotoRequested,
    privatePhotoApproval,
    requestedMyPrivatePhoto,
    approvedMyPrivatePhoto,
    isBlocked,
    isSilentInteraction,
    isFavorited,
    isCallEnabled,
    isSugarbaby,
    role,
  } = props;

  // Redux variables
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Event Handlers | Button
  const onViewViewerProfile = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-28-28.1-Button",
      },
    });

    // User View
    dispatch(updateId(id));
    dispatch(updateIdInt(idInt));
    dispatch(updateUsername(username));
    dispatch(updateAge(age));
    dispatch(updateGender(gender));
    dispatch(updateLocation(location));
    dispatch(updateMembershipType(membershipType));
    dispatch(updateVerifiedProfile(verifiedProfile));
    dispatch(updateVerifiedCaller(verifiedCaller));
    dispatch(updateLevellingBadge(levellingBadge));
    dispatch(
      updateLivestreamingAchievementBadge(livestreamingAchievementBadge)
    );
    dispatch(updateSpecialBadges(specialBadges));
    dispatch(updateProfilePhoto(profilePhoto));
    dispatch(updateProfileDecorations(profileDecorations));
    dispatch(updatePublicPhotos(publicPhotos));
    dispatch(updatePrivatePhotos(privatePhotos));
    dispatch(updateTorTags(torTags));
    dispatch(updateProfileAchievementBadge(profileAchievementBadge));
    dispatch(updateAboutMeManualText(aboutMe));
    dispatch(updateAboutMeReadStatus(aboutMeReadStatus));
    dispatch(updateLookingFor(lookingFor));
    dispatch(updateOccupation(occupation));
    dispatch(updateLifestyle(lifestyle));
    dispatch(updateEducation(education));
    dispatch(updateRelationship(relationship));
    dispatch(updateSmoking(smoking));
    dispatch(updateDrinking(drinking));
    dispatch(updateHeight(height));
    dispatch(updateBodyType(bodyType));
    dispatch(updateEthnicity(ethnicity));
    dispatch(updateEyeColor(eyeColor));
    dispatch(updateHairColor(hairColor));
    dispatch(updateChildren(children));
    // About Me Functions
    let tempAboutMeList = [];

    tempAboutMeList?.push({
      name: "personality_type",
      data: personalityType?.length > 0 ? personalityType : null,
    });
    tempAboutMeList?.push({
      name: "hobbies",
      data: hobbies?.lengtj > 0 ? hobbies : null,
    });
    tempAboutMeList?.push({
      name: "star_sign",
      data: starSign,
    });
    tempAboutMeList?.push({
      name: "fitness_level",
      data: fitnessLevel,
    });
    tempAboutMeList?.push({
      name: "dominant_or_submissive",
      data: dominantOrSubmissive,
    });
    tempAboutMeList?.push({
      name: "personality_traits",
      data: personalityTraits,
    });
    dispatch(updatePersonalityType(personalityType));
    dispatch(updateHobbies(hobbies));
    dispatch(updateFitnessLevel(fitnessLevel));
    dispatch(updateStarSign(starSign));
    dispatch(updateDominantOrSubmissive(dominantOrSubmissive));
    dispatch(updatePersonalityTraits(personalityTraits));

    dispatch(updateAboutMe(tempAboutMeList));

    dispatch(
      updateLookingForAgeRange({
        min: lookingForAgeRange?.min,
        max: lookingForAgeRange?.max,
      })
    );
    dispatch(updateLookingForLocation(lookingForLocation));
    const temp = lookingForEthnicityRace?.map((item) => ({
      ethnicity: {
        id: item?.option?.id,
        locale: item?.option?.locale,
        display_locale: item?.option?.locale,
      },
      race: item?.sub_option
        ? {
            id: item?.sub_option?.id,
            locale: item?.sub_option?.locale,
            display_locale: item?.sub_option?.locale,
          }
        : null,
    }));
    dispatch(updateLookingForEthnicityRace(temp));

    // User Utility
    dispatch(updateShouldCallApi(shouldCallApi));
    dispatch(updatePrivatePhotoRequested(privatePhotoRequested));
    dispatch(updatePrivatePhotoApproval(privatePhotoApproval));
    dispatch(updateRequestedMyPrivatePhoto(requestedMyPrivatePhoto));
    dispatch(updateApprovedMyPrivatePhoto(approvedMyPrivatePhoto));
    dispatch(updateIsBlocked(isBlocked ? true : false));
    dispatch(updateIsSilentInteraction(isSilentInteraction));
    dispatch(updateIsFavorited(isFavorited));
    dispatch(updateIsCallEnabled(isCallEnabled));
    dispatch(updateIsSugarbaby(isSugarbaby));
    dispatch(updateRole(role));

    // dispatch(updateUserProfileDialog(true)); // Dialog
    onNavigate(`${routeConst.profile.view.clearPath}${id}`);
  };

  return (
    <div id="last-stream-viewers-viewer-list-subcomponent">
      <div className="user-profile-photo-container">
        <CustomAvatar
          className="user-profile-photo"
          src={profilePhoto}
          role={role}
          profileBorder={profileDecorations?.profile_border_url}
          onClick={onViewViewerProfile}
        />
      </div>

      <div className="viewer-username">{username || t("common.new_user")}</div>
    </div>
  );
};

export default ViewerList;
