// General
import "./pin-chat.scss";
import { useState, useEffect, useRef } from "react";
// Services
import { useLazyGetLivestreamingChannelRefreshInfoQuery } from "../../../../../services/data.service";
// Static Data
import utilityConst from "../../../../../const/utilityConst";
// Redux
import { useSelector } from "react-redux";
// Material UI
import CloseIcon from "@mui/icons-material/Close";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";

const PinChat = () => {
  // API variables
  const [
    getLivestreamingChannelRefreshInfo,
    {
      data: getLivestreamingChannelRefreshInfoData,
      error: getLivestreamingChannelRefreshInfoErrorData,
      isFetching: getLivestreamingChannelRefreshInfoFetching,
      isLoading: getLivestreamingChannelRefreshInfoLoading,
      isSuccess: getLivestreamingChannelRefreshInfoSuccess,
      isError: getLivestreamingChannelRefreshInfoError,
    },
  ] = useLazyGetLivestreamingChannelRefreshInfoQuery();

  // General variables
  const [showPinChat, setShowPinChat] = useState(true);
  const outerPinChatRef = useRef(null);
  const sliderTrackRef = useRef(null);
  const sliderTrackContRef = useRef(null);
  const [outerPinChatWidth, setOuterPinChatWidth] = useState(0);
  const [sliderTrackWidth, setSliderTrackWidth] = useState(0);
  const [sliderTrackContWidth, setSliderTrackContWidth] = useState(0);

  // Redux variables
  const channelId = useSelector((state) => state.livestreaming.channelId);
  const releaseChannel = useSelector((state) => state.app.releaseChannel);
  const pinnedChatRefreshPassthrough = useSelector(
    (state) => state.livestreaming.pinnedChatRefreshPassthrough
  );

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    setOuterPinChatWidth(
      outerPinChatRef.current?.getBoundingClientRect().width
    );
    setSliderTrackWidth(sliderTrackRef.current?.getBoundingClientRect().width);
    setSliderTrackContWidth(
      sliderTrackRef.current?.getBoundingClientRect().width
    );

    const handleResize = () => {
      setOuterPinChatWidth(
        outerPinChatRef.current?.getBoundingClientRect().width
      );

      if (sliderTrackRef.current) {
        sliderTrackRef.current.style.width = "auto";
        sliderTrackRef.current.style.minWidth = "auto";

        sliderTrackRef.current.style.width = `${getTransformedSliderTrackWidth(
          sliderTrackRef.current.getBoundingClientRect().width
        )}px`;
        sliderTrackRef.current.style.minWidth =
          sliderTrackRef.current.style.width;

        setSliderTrackWidth(
          sliderTrackRef.current.getBoundingClientRect().width
        );
      }

      if (sliderTrackContRef.current) {
        sliderTrackContRef.current.style.width = "auto";
        sliderTrackContRef.current.style.minWidth = "auto";

        sliderTrackContRef.current.style.width = `${getTransformedSliderTrackWidth(
          sliderTrackContRef.current?.getBoundingClientRect().width
        )}px`;
        sliderTrackContRef.current.style.minWidth = `${getTransformedSliderTrackWidth(
          sliderTrackContRef.current?.getBoundingClientRect().width
        )}px`;

        setSliderTrackContWidth(sliderTrackContRef.current.style.width);
      }
    };

    // Attached the event listener
    window.addEventListener("resize", handleResize);

    // Initial width check
    handleResize();

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Lifecycle | Check for update | channelId
  useEffect(() => {
    if (!channelId) return;

    getLivestreamingChannelRefreshInfo(`?channel_id=${channelId}`);
  }, [channelId, pinnedChatRefreshPassthrough]);

  // Lifecycle | Check for update | Sliding Banner
  useEffect(() => {
    if (sliderTrackContWidth > outerPinChatWidth) {
      if (sliderTrackContRef.current) {
        sliderTrackContRef.current.style.width = "auto";
        sliderTrackContRef.current.style.minWidth = "auto";

        sliderTrackContRef.current.style.width = `${getTransformedSliderTrackWidth(
          sliderTrackContRef.current?.getBoundingClientRect().width
        )}px`;
        sliderTrackContRef.current.style.minWidth = `${getTransformedSliderTrackWidth(
          sliderTrackContRef.current?.getBoundingClientRect().width
        )}px`;

        setSliderTrackContWidth(sliderTrackContRef.current.style.width);
      }
    }
  }, [sliderTrackWidth, outerPinChatWidth]);

  // Event Handlers | Button
  const onClosePinChat = () => {
    setShowPinChat(false);
  };

  // Utility Functions
  const getTransformedSliderTrackWidth = (width) => {
    const sliderTrackWidth = width;
    const modifiedValue = Math.floor((sliderTrackWidth + 50) / 10) * 10;
    return modifiedValue;
  };

  if (showPinChat) {
    if (
      getLivestreamingChannelRefreshInfoFetching ||
      getLivestreamingChannelRefreshInfoLoading
    ) {
    } else if (getLivestreamingChannelRefreshInfoSuccess) {
      if (getLivestreamingChannelRefreshInfoData?.data?.pinned_notes) {
        return (
          <div id="interactable-overlay-pin-chat-subcomponent">
            {/* Keyframe need to be applied here instead of in SCSS due to dynamic width */}
            <style>
              {`
                @keyframes pin-chat-animation {
                  0% {
                    -moz-transform: translateX(0);
                    -webkit-transform: translateX(0);
                    transform: translateX(0);
                  }
    
                  100% {
                    -moz-transform: translateX(calc(-${
                      sliderTrackRef?.current?.getBoundingClientRect()?.width
                    }px));
                    -webkit-transform: translateX(calc(-${
                      sliderTrackRef?.current?.getBoundingClientRect()?.width
                    }px));
                    transform: translateX(calc(-${
                      sliderTrackRef?.current?.getBoundingClientRect()?.width
                    }px));
                  }
                }
              `}
            </style>

            <div className="padding-container">
              <div className="pin-chat-container" ref={outerPinChatRef}>
                <div className="pin-chat-icon-container">
                  {getIcon("pinChatIcon", "pin-chat-icon")}
                </div>

                <div className="slider-track-container">
                  <div
                    className={`slider-track ${
                      getTransformedSliderTrackWidth(
                        sliderTrackRef?.current?.getBoundingClientRect()?.width
                      ) >
                      outerPinChatRef?.current?.getBoundingClientRect()?.width
                        ? "scrolling-animation"
                        : ""
                    }`}
                    ref={sliderTrackRef}
                  >
                    <div className="pin-chat-message">
                      {
                        getLivestreamingChannelRefreshInfoData?.data
                          ?.pinned_notes?.value
                      }
                    </div>
                  </div>

                  {getTransformedSliderTrackWidth(
                    sliderTrackRef?.current?.getBoundingClientRect()?.width
                  ) >
                    outerPinChatRef?.current?.getBoundingClientRect()
                      ?.width && (
                    <div
                      className={`slider-track ${
                        getTransformedSliderTrackWidth(
                          sliderTrackRef?.current?.getBoundingClientRect()
                            ?.width
                        ) >
                        outerPinChatRef?.current?.getBoundingClientRect()?.width
                          ? "scrolling-animation"
                          : ""
                      }`}
                      ref={sliderTrackContRef}
                    >
                      <div className="pin-chat-message">
                        {
                          getLivestreamingChannelRefreshInfoData?.data
                            ?.pinned_notes?.value
                        }
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* Disable due to business decision */}
              {/* <div className="close-button-container" onClick={onClosePinChat}>
                <CloseIcon className="close-button" />
              </div> */}
            </div>
          </div>
        );
      }
    } else if (getLivestreamingChannelRefreshInfoError) {
    }
  }
};

export default PinChat;
