// General
import "./emoji-element.scss";
// Static Data
import livestreamingConst from "../../../../../const/livestreamingConst";
// i18next
import { useTranslation } from "react-i18next";
// Components
import CustomAvatar from "../../custom-avatar/custom-avatar";

const EmojiElement = (props) => {
  const {
    livestreamingAchievementBadge,
    profilePhoto,
    profileDecorations,
    username,
    levellingBadge,
    emoji,

    // Style
    borderColor = livestreamingConst.config.emojiElement.border,
    font = livestreamingConst.config.emojiElement.font,
    textColor = livestreamingConst.config.emojiElement.mobileFontColor,
    backgroundColor = "none",
  } = props;

  // i18next variables
  const { t } = useTranslation();

  return (
    <div
      id="chat-section-emoji-element-subcomponent"
      style={{
        background: backgroundColor,
        border: borderColor !== "none" ? `1px solid ${borderColor}` : "none",
      }}
    >
      <div className="profile-picture-container">
        <CustomAvatar
          className="profile-picture"
          src={profilePhoto}
          profileBorder={profileDecorations?.profile_border_url}
        />
      </div>

      <div className="chat-info-container">
        {livestreamingAchievementBadge?.length > 0 && (
          <div className="livestreaming-achievement-badge-container">
            {livestreamingAchievementBadge?.map((badge, index) => (
              <img
                className="livestreaming-achievement-badge"
                src={badge?.url}
                alt={badge?.type}
                key={index}
              />
            ))}
          </div>
        )}

        <div className="username" style={{ font: font, color: textColor }}>
          {username || "-"}
        </div>

        {levellingBadge && (
          <div className="levelling-badge-container">
            <img
              className="levelling-badge"
              src={levellingBadge}
              alt="levelling-badge"
            />
          </div>
        )}

        <div
          className="emoji-icon-container"
          style={{ font: font, color: textColor }}
        >
          {t("pusher.sent_a_emoji", { emoji: emoji })}
        </div>
      </div>
    </div>
  );
};

export default EmojiElement;
