// General
import "./introduction-signup.scss";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateVerificationNavigatePassthrough } from "../../../redux/store/registrationStore";
// Material UI
import { useMediaQuery } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Components
import Spinner from "../../shared/elements/spinner/spinner";

const IntroductionSignup = () => {
  // Redux variables
  const initiateEmailApiLoading = useSelector(
    (state) => state.registration.initiateEmailApiLoading
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Event Handlers | Button
  const onNextPage = () => {
    if (initiateEmailApiLoading) return;

    dispatch(updateVerificationNavigatePassthrough());
  };

  return (
    <div id="introduction-signup-page">
      <div className="top-container">
        <div className="introduction-signup-title">
          {t("signup.introduction_signup_title")}
        </div>

        <div className="introduction-signup-description">
          {t("signup.introduction_signup_description")}
        </div>

        <div className="introduction-signup-description">
          {t("signup.introduction_signup_description_two")}
        </div>

        <div className="introduction-signup-description">
          {t("signup.introduction_signup_description_three")}
        </div>
      </div>

      <div className="bottom-container">
        <div
          className={`continue-button ${
            isMobile ? "full-width" : "fit-content"
          }`}
          onClick={onNextPage}
        >
          {initiateEmailApiLoading ? (
            <Spinner size={20} isPadding={false} color={"white-spinner"} />
          ) : (
            t("signup.lets_get_started")
          )}
        </div>
      </div>
    </div>
  );
};

export default IntroductionSignup;
