// General
import "./daily-check-in-dialog.scss";
import { useEffect, forwardRef } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateDailyCheckInDialog,
  updateLevelsDescriptionDialog,
} from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation } from "react-i18next";
// Components
import DailyCheckInReward from "../../daily-check-in-reward/daily-check-in-reward";

const DailyCheckInDialog = () => {
  // Redux variables
  const dailyCheckInDialog = useSelector(
    (state) => state.dialog.dailyCheckInDialog
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!dailyCheckInDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-DailyCheckIn-Dialog",
      },
    });
  }, [dailyCheckInDialog]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateLevelsDescriptionDialog(true));
    dispatch(updateDailyCheckInDialog(false));
  };

  return (
    <Dialog
      className={
        isMobile ? "custom-bottom-sheet-dialog" : "custom-radius35-dialog"
      }
      fullScreen={isMobile ? true : false}
      open={dailyCheckInDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={onCloseDialog}
    >
      <div
        id="daily-check-in-dialog"
        className={isMobile ? "" : "max-width-container"}
      >
        <div className="top-header-container">
          <CloseIcon className="close-button" onClick={onCloseDialog} />
        </div>

        <div className="padding-container">
          <div className="daily-check-in-title">
            {t("levelling.daily_popup_title")}
          </div>

          <div className="daily-check-in-description">
            {t("levelling.daily_popup_desc")}
          </div>

          <DailyCheckInReward />
        </div>
      </div>
    </Dialog>
  );
};

export default DailyCheckInDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
