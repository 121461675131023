// General
import "./tip-request-accepted-element.scss";
// i18next
import { useTranslation } from "react-i18next";
// Components
import CustomAvatar from "../../custom-avatar/custom-avatar";

const TipRequestAcceptedElement = (props) => {
  const {
    profilePhoto,
    profileDecorations,
    username,
    levellingBadge,

    // Style
    borderColor = "none",
    backgroundColor = "rgba(51, 51, 51, 0.4)",
  } = props;

  // i18next variables
  const { t } = useTranslation();

  return (
    <div
      id="chat-section-tip-request-accepted-element-subcomponent"
      style={{
        background: backgroundColor,
        border: borderColor !== "none" ? `1px solid ${borderColor}` : "none",
      }}
    >
      <div className="profile-picture-container">
        <CustomAvatar
          className="profile-picture"
          src={profilePhoto}
          profileBorder={profileDecorations?.profile_border_url}
        />
      </div>

      <div className="chat-info-container">
        <div className="chat-info">
          <span className="username">{username || "-"}</span>

          {levellingBadge && (
            <img
              className="levelling-badge"
              src={levellingBadge}
              alt="levelling-badge"
            />
          )}

          <span className="joined-text">
            {t("pusher.accepted_the_tip_request")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default TipRequestAcceptedElement;
