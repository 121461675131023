// General
import "./video-player.scss";
import { useState, useEffect, useRef } from "react";
// Services
import { useLazyGetUserAioQuery } from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateLivestreamLoading,
  updateLivestreamBuffering,
  updateVideoPlayerMute,
  toggleVideoPlayerMute,
  updateHasTcPullstream,
  updateHasTcPlayerPlay,
  updateIsTcPlayerMemoryLeak,

  // Utility Functions
  updateHasInteract,
  updateLoadedMetaDataPlayVideoPassthrough,
  resetLoadedMetaDataPlayVideoPassthrough,
} from "../../../../redux/store/livestreamingStore";
import { updateErrorToast } from "../../../../redux/store/toastStore";
import { updateLowPowerModeDialog } from "../../../../redux/store/dialogStore";
// tcplayer.js
import TCPlayer from "tcplayer.js";
// react-device-detect
import {
  isIOS,
  isMacOs,
  isDesktop as isDesktopDevice,
} from "react-device-detect";
// Material UI
import { useMediaQuery } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
// Custom Hooks
import useIsMounted from "../../../utility/custom-hooks/useIsMounted-hook";
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import GiftAnimationOverlay from "../../../shared/elements/gift-animation-overlay/gift-animation-overlay";
import PkSelectContestantOverlay from "../pk-select-contestant-overlay/pk-select-contestant-overlay";
import PkGraphicOverlay from "../pk-graphic-overlay/pk-graphic-overlay";

const VideoPlayer = () => {
  // API variables
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();

  // General variables
  const [tcPlayer, setTcPlayer] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [videoRetryAttemptCount, setVideoRetryAttemptCount] = useState(0);
  const [hlsStreamProvider, setHlsStreamProvider] = useState(null);
  const [hasInteraction, setHasInteraction] = useState(true);
  const [eventListenerLoaded, setEventListenerLoaded] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const videoRef = useRef(null);
  const isMounted = useRef(false);

  // Redux variables
  const pullChannel = useSelector((state) => state.livestreaming.pullChannel);
  const videoPlayerMute = useSelector(
    (state) => state.livestreaming.videoPlayerMute
  );
  const hasTcPullstream = useSelector(
    (state) => state.livestreaming.hasTcPullstream
  );
  const hasTcPlayerPlay = useSelector(
    (state) => state.livestreaming.hasTcPlayerPlay
  );
  const isTcPlayerMemoryLeak = useSelector(
    (state) => state.livestreaming.isTcPlayerMemoryLeak
  );
  const playVideoPassthrough = useSelector(
    (state) => state.livestreaming.playVideoPassthrough
  );
  const livestreamPusherEvent = useSelector(
    (state) => state.livestreaming.livestreamPusherEvent
  );
  const isCoAnchor = useSelector((state) => state.livestreaming.isCoAnchor);
  const loadedMetaDataPlayVideoPassthrough = useSelector(
    (state) => state.livestreaming.loadedMetaDataPlayVideoPassthrough
  );
  const dispatch = useDispatch();

  // MUI variables
  // const isDesktop = useMediaQuery("(min-width: 1305px)");
  // const isTablet = useMediaQuery("(min-width: 900px)");
  const isMobile = useMediaQuery("(max-width: 900px)");

  // Custom Hooks Functions
  const mounted = useIsMounted();
  const onNavigate = useCustomNavigate();

  // Lifecycle | Unmounted
  useEffect(() => {
    return () => {
      if (!mounted()) {
        // Destroy TC Player
        if (tcPlayer) {
          tcPlayer.dispose();
          setTcPlayer(null);
        }

        if (hasTcPullstream && !hasTcPlayerPlay && !isTcPlayerMemoryLeak) {
          dispatch(updateIsTcPlayerMemoryLeak(true));

          dispatch(updateHasTcPullstream(false));
          dispatch(updateHasTcPlayerPlay(false));
        }

        dispatch(resetLoadedMetaDataPlayVideoPassthrough());
      }
    };
  }, [mounted]);

  // Lifecycle | Check for update | getUserAio API Response
  useEffect(() => {
    if (getUserAioLoading) {
    } else if (getUserAioSuccess) {
      switch (getUserAioData?.status) {
        case 1:
          initVideoPlayer();
          break;
        default:
          break;
      }
    } else if (getUserAioError) {
    }
  }, [getUserAioLoading, getUserAioSuccess, getUserAioError]);

  // Lifecycle | Check for update | Check for HLS support
  useEffect(() => {
    if (!videoRef || !videoUrl) return;
    // Add Event Listeners for video player
    if (!eventListenerLoaded) {
      addDefaultListenerToVideoPlayer();
    }

    if (!tcPlayer) {
      setTcPlayer(
        TCPlayer("live-tc-player", {
          // sources: [{ src: videoUrl }],
          licenseUrl:
            "https://license.vod-control.com/license/v2/1307387886_1/v_cube.license",
          language: "en",
          children: {},
          controls: false,
          muted: videoPlayerMute,
        })
      );
    }
  }, [videoRef, videoUrl]);

  // Lifecycle | Check for update | tcPlayer
  useEffect(() => {
    if (!tcPlayer) return;

    tcPlayer.src(videoUrl);

    tcPlayer.ready(() => {
      // Set the style of the video player wrapper
      const tcPlayerLiveElement = document.getElementById("live-tc-player");
      for (let i = 0; i < tcPlayerLiveElement.classList.length; i++) {
        tcPlayerLiveElement.classList.remove(tcPlayerLiveElement.classList[i]);
      }
      tcPlayerLiveElement.classList.add(getParentVideoPlayerStyle());

      // Set the style of the video player
      const tcPlayerLiveVideoElement = document.getElementById(
        "live-tc-player_html5_api"
      );
      for (let i = 0; i < tcPlayerLiveVideoElement.classList.length; i++) {
        tcPlayerLiveVideoElement.classList.remove(
          tcPlayerLiveVideoElement.classList[i]
        );
      }
      tcPlayerLiveVideoElement.classList.add(getVideoPlayerStyle());
    });

    tcPlayer.on("canplaythrough", () => {
      dispatch(updateHasTcPlayerPlay(true));
      dispatch(updateLivestreamLoading(false));
    });

    tcPlayer.on("webrtcevent", (event) => {
      if (event?.data?.code === 1007 || event?.data?.code === 1008) {
        dispatch(updateHasTcPullstream(true));
      } else if (event?.data?.code === 1004) {
        // Stop pullstream. Cant use this to end stream because when switching stream this will trigger
        // onNavigate(routeConst.live.ended.path);
      }
    });

    if (videoRef.current) {
      videoRef.current
        ?.play()
        .then(() => {
          console.log("tcPlayer play success");
          // Autoplay started
          // dispatch(updateLivestreamLoading(false));
        })
        .catch((error) => {
          console.log("isIOS && !firstLoad error:", error);
          if (
            error.toString().includes("user didn't interact with the document")
          ) {
            // User didn't interact with document first

            // 2 Options
            // 1. Don't play the video and let user click the play button
            // dispatch(updateHasInteract(false));

            // 2. Mute video and play it
            dispatch(updateVideoPlayerMute(true));
            setTimeout(() => {
              videoRef.current?.play();
            }, 1000);
          } else if (error.toString().includes("NotAllowedError")) {
            // Low Power Mode (iOS)
            // dispatch(updateLowPowerModeDialog(true));
          }
        });
    }
  }, [tcPlayer]);

  // Lifecycle | Check for update | videoRetryAttemptCount
  useEffect(() => {
    if (videoRetryAttemptCount > 0) {
      setTimeout(() => {
        setVideoRetryAttemptCount(0);
      }, 30000);

      if (videoRetryAttemptCount > 10) {
        onNavigate(routeConst.live.ended.path);
      }
    }
  }, [videoRetryAttemptCount]);

  // Lifecycle | Check for update | livestreamPusherEvent
  useEffect(() => {
    if (!livestreamPusherEvent) return;

    switch (livestreamPusherEvent?.type) {
      case "viewer_kicked":
      case "kicked":
        videoRef.current.pause();
        break;
      default:
        break;
    }
  }, [livestreamPusherEvent]);

  // Lifecycle | Check for update | playVideoPassthrough
  useEffect(() => {
    if (isMounted.current) {
      if (playVideoPassthrough) {
        videoRef.current?.play();
      }
    } else {
      isMounted.current = true;
    }
  }, [playVideoPassthrough]);

  // Lifecycle | Check for update | pullChannel
  useEffect(() => {
    if (!pullChannel) return;
    // Destroy previous stream
    if (tcPlayer) {
      dispatch(updateLivestreamLoading(true));
      if (getUserAioData?.data?.pwa?.player_config?.type === "hls") {
        setTimeout(() => {
          tcPlayer.src(getStreamPullChannel());
        }, 8000);
      } else {
        tcPlayer.src(getStreamPullChannel());
      }
    } else {
      dispatch(updateLivestreamLoading(true));

      setVideoUrl(null);

      getUserAio(null, true);
      // initVideoPlayer();
    }
  }, [pullChannel]);

  // Lifecycle | Check for update | loadedMetaDataPlayVideoPassthrough
  useEffect(() => {
    if (!loadedMetaDataPlayVideoPassthrough) return;

    if (isIOS && !firstLoad) {
      setTimeout(() => {
        videoRef.current
          ?.play()
          .then(() => {
            console.log("isIOS && !firstLoad play success");
            // Autoplay started
            // dispatch(updateLivestreamLoading(false));
          })
          .catch((error) => {
            console.log("isIOS && !firstLoad error:", error);
            if (
              error
                .toString()
                .includes("user didn't interact with the document")
            ) {
              // User didn't interact with document first

              // 2 Options
              // 1. Don't play the video and let user click the play button
              // dispatch(updateHasInteract(false));

              // 2. Mute video and play it
              dispatch(updateVideoPlayerMute(true));
              setTimeout(() => {
                videoRef.current?.play();
              }, 1000);
            } else if (error.toString().includes("NotAllowedError")) {
              // Low Power Mode (iOS)
              // dispatch(updateLowPowerModeDialog(true));
            }
          });

        setFirstLoad(false);
      }, 6000);
    } else {
      videoRef.current
        ?.play()
        .then(() => {
          console.log("play success");
          // Autoplay started
          // dispatch(updateLivestreamLoading(false));
        })
        .catch((error) => {
          console.log("error:", error);
          if (
            error.toString().includes("user didn't interact with the document")
          ) {
            // User didn't interact with document first

            // 2 Options
            // 1. Don't play the video and let user click the play button
            // dispatch(updateHasInteract(false));

            // 2. Mute video and play it
            dispatch(updateVideoPlayerMute(true));
            setTimeout(() => {
              videoRef.current?.play();
            }, 1000);
          } else if (error.toString().includes("NotAllowedError")) {
            // Low Power Mode (iOS)
            // dispatch(updateLowPowerModeDialog(true));
          }
        });

      setFirstLoad(false);
    }
  }, [loadedMetaDataPlayVideoPassthrough]);

  // Video Player Functions
  const initVideoPlayer = () => {
    setVideoUrl(getStreamPullChannel());
    setHlsStreamProvider(getStreamProvider());
  };
  const addDefaultListenerToVideoPlayer = () => {
    setEventListenerLoaded(true);

    const events = [
      "abort",
      "canplay",
      "canplaythrough",
      "emptied",
      "encrypted",
      "ended",
      "error",
      "interruptbegin",
      "interruptend",
      "loadeddata",
      "loadedmetadata",
      "loadstart",
      "mozaudioavailable",
      "pause",
      "play",
      "playing",
      "ratechange",
      "seeked",
      "seeking",
      "stalled",
      "suspend",
      "volumechange",
      "waiting",
    ];

    let eventFunction = function (e) {
      // Event Listener if video recovers from stalled state
      switch (e.type) {
        case "canplay":
        case "playing":
        case "canplaythrough":
          dispatch(updateLivestreamBuffering(false));
          break;
        case "waiting":
          // dispatch(updateHasInteract(false));
          // dispatch(updateLivestreamLoading(false));

          break;
        case "seeking":
          dispatch(updateLivestreamBuffering(true));
          break;
        case "loadedmetadata":
          dispatch(updateLoadedMetaDataPlayVideoPassthrough());
          break;
        case "pause":
          if (e.target.error || e.target.readyState < 4) {
            // Ignore pauses due to errors or buffering
            return;
          }

          videoRef.current?.play();
          break;
        default:
          break;
      }
    };

    events.forEach((e) => {
      videoRef.current.addEventListener(e, eventFunction, false);
    });
  };

  // Utility Functions
  const getStreamPullChannel = () => {
    const streamChannel = pullChannel?.find(
      (channel) =>
        channel.type ===
        (getUserAioData?.data?.pwa?.player_config?.type || "webrtc")
    );
    return streamChannel?.url || null;
  };
  const getStreamProvider = () => {
    const hlsChannel = pullChannel?.find((channel) => channel.type === "hls");
    return hlsChannel?.provider || null;
  };

  // Utility Style
  const getParentVideoPlayerStyle = () => {
    if (isMobile) {
      if (isCoAnchor) {
        return "co-anchor-mobile-video-player";
      } else {
        return "mobile-video-player";
      }
    } else {
      return "desktop-video-player";
    }
  };
  const getVideoPlayerStyle = () => {
    if (isMobile) {
      if (isCoAnchor) {
        return "co-anchor-video-covered";
      } else {
        return "video-covered";
      }
    } else {
      return "maintain-aspect-ratio";
    }
  };

  return (
    <div
      id="livestream-video-player-subcomponent"
      className={getParentVideoPlayerStyle()}
      data-vjs-player
    >
      <video
        id="live-tc-player"
        className={`live-video ${getVideoPlayerStyle()}`}
        ref={videoRef}
        playsInline
        preload="auto"
        muted={videoPlayerMute}
        autoPlay
      ></video>

      {false && hasInteraction && (
        <div className="play-overlay-container">
          <PlayArrowIcon />
        </div>
      )}

      {isCoAnchor && isMobile && (
        <div className="dual-pk-gift-animation-overlay-container">
          <GiftAnimationOverlay />
        </div>
      )}

      {isCoAnchor && (
        <div className="pk-select-contestant-container">
          <PkSelectContestantOverlay />
        </div>
      )}

      {isCoAnchor && <PkGraphicOverlay />}
    </div>
  );
};

export default VideoPlayer;
