// General
import "./casino-game-dialog.scss";
import { useState, useEffect, forwardRef } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateCasinoGameDialog } from "../../../../redux/store/dialogStore";
// Material UI
import { Dialog, Slide, useMediaQuery } from "@mui/material";
// Components
import CasinoGames from "../../../games/casino-games/casino-games";

const CasinoGameDialog = () => {
  // General variables
  const [isOpened, setIsOpened] = useState(false);

  // Redux variables
  const casinoGameDialog = useSelector(
    (state) => state.dialog.casinoGameDialog
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // Lifecycle | Mounted
  useEffect(() => {
    if (casinoGameDialog) {
      if (!isOpened) {
        setIsOpened(true);
      }
    } else {
    }
  }, [casinoGameDialog]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = (event, reason) => {
    if (reason === "backdropClick") return;

    dispatch(updateCasinoGameDialog(false));
  };

  return (
    <Dialog
      className={isMobile ? "custom-bottom-sheet-dialog" : ""}
      fullScreen={isMobile ? true : false}
      keepMounted
      hideBackdrop
      open={casinoGameDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      <div id="casino-game-dialog">
        {isOpened && <CasinoGames showHeader={true} isDialog={true} />}
      </div>
    </Dialog>
  );
};

export default CasinoGameDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
