// General
import "./forgot-password.scss";
import { useState, useEffect } from "react";
// Services
import {
  usePasswordRecoveryMutation,
  useForgotPasswordMutation,
  useVerifyForgotPasswordMutation,
} from "../../services/data.service";
import { emailRegex } from "../../services/regex.service";
// Static Data
import routeConst from "../../const/routeConst";
// Redux
import { useDispatch } from "react-redux";
import {
  updateSuccessToast,
  updateWarningToast,
  updateErrorToast,
  updateInfoToast,
} from "../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import {
  Box,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Moment
import moment from "moment";
// Custom Hooks
import useCustomNavigate from "../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../shared/elements/spinner/spinner";

const ForgotPassword = () => {
  // API variables
  const [
    passwordRecovery,
    {
      data: passwordRecoveryData,
      error: passwordRecoveryErrorData,
      isLoading: passwordRecoveryLoading,
      isSuccess: passwordRecoverySuccess,
      isError: passwordRecoveryError,
    },
  ] = usePasswordRecoveryMutation();
  const [
    forgotPassword,
    {
      data: forgotPasswordData,
      error: forgotPasswordErrorData,
      isLoading: forgotPasswordLoading,
      isSuccess: forgotPasswordSuccess,
      isError: forgotPasswordError,
    },
  ] = useForgotPasswordMutation();
  const [
    verifyForgotPassword,
    {
      data: verifyForgotPasswordData,
      error: verifyForgotPasswordErrorData,
      isLoading: verifyForgotPasswordLoading,
      isSuccess: verifyForgotPasswordSuccess,
      isError: verifyForgotPasswordError,
    },
  ] = useVerifyForgotPasswordMutation();

  // General variables
  const [email, setEmail] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [emailFocus, setEmailFocus] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [sessionToken, setSessionToken] = useState(null);
  const [otp, setOtp] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordErrorMessage, setNewPasswordErrorMessage] = useState("");
  const [newPasswordFocus, setNewPasswordFocus] = useState(false);
  const [newPasswordValid, setNewPasswordValid] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [confirmedPasswordErrorMessage, setConfirmedPasswordErrorMessage] =
    useState("");
  const [confirmedPasswordFocus, setConfirmedPasswordFocus] = useState(false);
  const [confirmedPasswordValid, setConfirmedPasswordValid] = useState(false);
  const [confirmedPasswordError, setConfirmedPasswordError] = useState(false);

  const [verificationTimer, setVerificationTimer] = useState(0);
  const [verificationInterval, setVerificationInterval] = useState(null);

  // Redux variables
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-5",
      },
    });

    return () => {
      clearInterval(verificationInterval);
      setVerificationInterval(null);
    };
  }, []);

  // Lifecycle | Check for update | Password Recovery API Response
  useEffect(() => {
    if (passwordRecoveryLoading) {
    } else if (passwordRecoverySuccess) {
      switch (passwordRecoveryData?.status) {
        case 0:
          // No message
          const warningToastObj = {
            message: passwordRecoveryData?.message || "",
            autoClose: 3000,
          };
          dispatch(updateWarningToast(warningToastObj));
          break;
        case 1:
          TagManager.dataLayer({
            dataLayer: {
              event: "PWA-5-PasswordRecovery-Success",
            },
          });

          const successToastObj = {
            message: passwordRecoveryData?.message,
            autoClose: 3000,
          };
          dispatch(updateSuccessToast(successToastObj));
          break;
        default:
          const infoToastObj = {
            message: passwordRecoveryData?.message,
            autoClose: 3000,
          };
          dispatch(updateInfoToast(infoToastObj));
          break;
      }

      if (passwordRecoveryData?.message) {
        onNavigate(-1);
      }
    } else if (passwordRecoveryError) {
    }
  }, [passwordRecoveryLoading, passwordRecoverySuccess, passwordRecoveryError]);

  // Lifecycle | Check for update | Forgot Password API Response
  useEffect(() => {
    if (forgotPasswordLoading) {
    } else if (forgotPasswordSuccess) {
      switch (forgotPasswordData?.status) {
        case 1:
          setSessionToken(forgotPasswordData?.data?.session_token);
          setVerificationTimer(60);

          setVerificationInterval(
            setInterval(() => {
              setVerificationTimer((prev) => prev - 1);
            }, 1000)
          );
          break;
        case -1:
          const warningToastObj = {
            message: forgotPasswordData?.message,
            autoClose: 3000,
          };
          dispatch(updateWarningToast(warningToastObj));
          break;
        default:
          break;
      }
    } else if (forgotPasswordError) {
    }
  }, [forgotPasswordLoading, forgotPasswordSuccess, forgotPasswordError]);

  // Lifecycle | Check for update | Verify Forgot Password API Response
  useEffect(() => {
    if (verifyForgotPasswordLoading) {
    } else if (verifyForgotPasswordSuccess) {
      switch (verifyForgotPasswordData?.status) {
        case 0:
        case 1:
          const successToastObj = {
            message: verifyForgotPasswordData?.message,
            autoClose: 3000,
          };
          dispatch(updateSuccessToast(successToastObj));
          onNavigate(routeConst.login.path);
          break;
        case -1:
          const warningToastObj = {
            message: verifyForgotPasswordData?.message,
            autoClose: 3000,
          };
          dispatch(updateWarningToast(warningToastObj));
        default:
          break;
      }
    } else if (verifyForgotPasswordError) {
      const errorToastObj = {
        message: verifyForgotPasswordErrorData?.data?.message,
        autoClose: 3000,
      };
      dispatch(updateErrorToast(errorToastObj));
    }
  }, [
    verifyForgotPasswordLoading,
    verifyForgotPasswordSuccess,
    verifyForgotPasswordError,
  ]);

  // Event Handlers | Button
  const onResetPassword = () => {
    if (!isFormValid()) return;

    const obj = {
      email,
    };
    passwordRecovery(obj);
    return;

    if (sessionToken) {
      const obj = {
        code: otp,
        new_password: newPassword,
        confirmed_password: confirmedPassword,
        session_token: sessionToken,
      };
      verifyForgotPassword(obj);
    } else {
      const obj = {
        email,
      };
      forgotPassword(obj);
    }
  };
  const onShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const onResendCode = () => {
    if (verificationTimer > 0) return;

    const obj = {
      email,
    };
    forgotPassword(obj);
  };

  // Event Handlers | Input
  const handleVerificationCodeChange = (event) => {
    const input = event.target.value;
    const numericInput = input.replace(/\D/g, "");
    setOtp(numericInput);
  };

  // Event Handlers | MUI Text Field
  const onEmailChange = (event) => {
    setEmail(event.target.value.trim().toLowerCase());
  };
  const onEmailFocus = (event) => {
    setEmailFocus(true);
  };
  const onNewPasswordChange = (event) => {
    setNewPassword(event.target.value.trim());
  };
  const onNewPasswordFocus = (event) => {
    setNewPasswordFocus(true);
  };
  const onConfirmedPasswordChange = (event) => {
    setConfirmedPassword(event.target.value.trim());
  };
  const onConfirmedPasswordFocus = (event) => {
    setConfirmedPasswordFocus(true);
  };

  // Utility Functions
  const isFormValid = () => {
    return emailValid;

    if (sessionToken) {
      return (
        otp.length === 6 &&
        newPasswordValid &&
        confirmedPasswordValid &&
        !verifyForgotPasswordLoading
      );
    } else {
      return emailValid && !forgotPasswordLoading;
    }
  };
  const emailValidation = () => {
    let valid = true;
    let error = false;

    const regex = emailRegex;

    if (!emailFocus) {
      valid = false;
      error = false;
    } else if (emailFocus && email === "") {
      setEmailErrorMessage(t("login.email_address_is_required"));
      valid = false;
      error = true;
    } else if (!regex.test(email)) {
      setEmailErrorMessage(t("login.email_address_is_not_valid"));
      valid = false;
      error = true;
    } else {
      setEmailErrorMessage("");
      valid = true;
      error = false;
    }

    setEmailValid(valid);
    setEmailError(error);
  };
  const newPasswordValidation = () => {
    let valid = true;
    let error = false;

    if (newPassword === "") {
      setNewPasswordErrorMessage(t("login.password_is_required"));
      valid = false;
      error = true;
    } else if (newPassword.length < 6) {
      setNewPasswordErrorMessage(
        t("login.password_must_be_n_characters", { n: 6 })
      );
      valid = false;
      error = true;
    } else {
      setNewPasswordErrorMessage("");
      valid = true;
      error = false;
    }

    setNewPasswordValid(valid);
    setNewPasswordError(error);
  };
  const confirmedPasswordValidation = () => {
    let valid = true;
    let error = false;

    if (confirmedPassword === "") {
      setConfirmedPasswordErrorMessage(t("login.password_is_required"));
      valid = false;
      error = true;
    } else if (confirmedPassword.length < 6) {
      setConfirmedPasswordErrorMessage(
        t("login.password_must_be_n_characters", { n: 6 })
      );
      valid = false;
      error = true;
    } else if (confirmedPassword !== newPassword) {
      setConfirmedPasswordErrorMessage(t("signup.password_does_not_match"));
      valid = false;
      error = true;
    } else {
      setConfirmedPasswordErrorMessage("");
      valid = true;
      error = false;
    }

    setConfirmedPasswordValid(valid);
    setConfirmedPasswordError(error);
  };

  // Check for email validation
  useEffect(() => {
    if (sessionToken) {
      newPasswordValidation();
      confirmedPasswordValidation();
    } else {
      emailValidation();
    }
  }, [
    email,
    emailFocus,
    emailErrorMessage,
    newPassword,
    newPasswordFocus,
    newPasswordErrorMessage,
    confirmedPassword,
    confirmedPasswordFocus,
    confirmedPasswordErrorMessage,
  ]);

  return (
    <Box
      id="forgot-password-page"
      component="form"
      noValidate
      autoComplete="off"
    >
      <div className="max-width-container">
        <div className="top-container">
          <div className="navbar-container">
            <div className="back-button" onClick={() => onNavigate(-1)}>
              {getIcon("signupBackIcon", "signup-back-icon")}
            </div>
          </div>

          <div className="forgot-password-label">
            {t("signup.forgot_password")}
          </div>

          <div className="forgot-password-description">
            {t("signup.forgot_password_desc")}
          </div>

          <div className="email-form-field-container">
            <TextField
              className="email-form-field"
              required
              value={email}
              error={emailError}
              onChange={onEmailChange}
              // label={t("login.email_address")}
              placeholder={t("login.email_address")}
              helperText={emailErrorMessage}
              variant="standard"
              onFocus={onEmailFocus}
            />
          </div>
        </div>

        <div className="button-container">
          <div
            className={`reset-button ${isFormValid() ? "" : "disabled-button"}`}
            onClick={onResetPassword}
          >
            {passwordRecoveryLoading ? (
              <Spinner size={24} isPadding={false} color={"white-spinner"} />
            ) : (
              t("signup.reset_password")
            )}
          </div>
        </div>
      </div>
    </Box>
  );

  // New Design
  return (
    <Box
      id="forgot-password-page"
      component="form"
      noValidate
      autoComplete="off"
    >
      <div className="max-width-container">
        <div className="top-container">
          <div className="navbar-container">
            <div className="back-button" onClick={() => onNavigate(-1)}>
              {getIcon("signupBackIcon", "signup-back-icon")}
            </div>
          </div>

          {!sessionToken && (
            <div className="forgot-password-container">
              <div className="forgot-password-label">
                {t("signup.forgot_password")}
              </div>

              <div className="forgot-password-description">
                {t("signup.forgot_password_desc")}
              </div>

              <div className="email-form-field-container">
                <TextField
                  className="email-form-field"
                  required
                  value={email}
                  error={emailError}
                  onChange={onEmailChange}
                  // label={t("login.email_address")}
                  placeholder={t("login.email_address")}
                  helperText={emailErrorMessage}
                  variant="standard"
                  onFocus={onEmailFocus}
                />
              </div>
            </div>
          )}

          {sessionToken && (
            <div className="reset-password-container">
              <div className="reset-password-label">
                {t("signup.reset_your_password")}
              </div>

              <div className="reset-password-description">
                {t("signup.reset_your_password_description")}
              </div>

              <div className="reset-password-middle-container">
                <div className="code-input-container">
                  <input
                    className="email-code-input"
                    maxLength={6}
                    value={otp}
                    onChange={handleVerificationCodeChange}
                  />
                </div>

                {verifyForgotPasswordData?.status === -1 && (
                  <div className="invalid-code-label">
                    {t("verification.this_code_is_invalid")}
                  </div>
                )}

                <div className="resend-code-button-container">
                  <div className="resend-code-description">
                    {t("verification.did_not_receive_the_verification_code")}
                  </div>

                  {forgotPasswordLoading ? (
                    <Spinner size={20} isPadding={false} />
                  ) : verificationTimer > 0 ? (
                    <div
                      className="resend-code-label-container"
                      onClick={onResendCode}
                    >
                      <Trans
                        className={`resend-code-label ${
                          verificationTimer > 0 ? "disable-button" : ""
                        }`}
                        i18nKey={"verification.resend_code_in_n_sec"}
                        values={{ n: verificationTimer }}
                        components={{
                          span: <span className="resend-countdown-label" />,
                          div: (
                            <div
                              className={`resend-code-label ${
                                verificationTimer > 0 ? "disable-button" : ""
                              }`}
                            />
                          ),
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      className="resend-code-label-container"
                      onClick={onResendCode}
                    >
                      <div className="resend-code-label">
                        {t("verification.resend_code")}
                      </div>
                    </div>
                  )}
                </div>

                <div className="password-form-field-container">
                  <TextField
                    className="password-form-field"
                    required
                    value={newPassword}
                    error={newPasswordError}
                    onChange={onNewPasswordChange}
                    type={showPassword ? "text" : "password"}
                    // label={t("login.email_address")}
                    placeholder={t("signup.new_password")}
                    helperText={newPasswordErrorMessage}
                    variant="standard"
                    onFocus={onNewPasswordFocus}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={onShowPassword}>
                            {showPassword ? (
                              <VisibilityIcon className="visibility-icon" />
                            ) : (
                              <VisibilityOffIcon className="visibility-icon" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <div className="password-form-field-container">
                  <TextField
                    className="password-form-field"
                    required
                    value={confirmedPassword}
                    error={confirmedPasswordError}
                    onChange={onConfirmedPasswordChange}
                    type={showPassword ? "text" : "password"}
                    // label={t("login.email_address")}
                    placeholder={t("signup.confirm_password")}
                    helperText={confirmedPasswordErrorMessage}
                    variant="standard"
                    onFocus={onConfirmedPasswordFocus}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={onShowPassword}>
                            {showPassword ? (
                              <VisibilityIcon className="visibility-icon" />
                            ) : (
                              <VisibilityOffIcon className="visibility-icon" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>

              <div className="reset-password-info-container">
                <div className="light-bulb-icon-container">
                  {getIcon("lightBulbIcon", "light-bulb-icon")}
                </div>

                <div className="reset-password-info-label">
                  {t("signup.reset_your_password_pointer")}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="button-container">
          {!sessionToken && (
            <div
              className={`reset-button ${
                isFormValid() ? "" : "disabled-button"
              }`}
              onClick={onResetPassword}
            >
              {forgotPasswordLoading ? (
                <Spinner size={19} isPadding={false} color={"white-spinner"} />
              ) : (
                t("signup.reset_password")
              )}
            </div>
          )}

          {sessionToken && (
            <div
              className={`reset-button ${
                isFormValid() ? "" : "disabled-button"
              }`}
              onClick={onResetPassword}
            >
              {verifyForgotPasswordLoading ? (
                <Spinner size={19} isPadding={false} color={"white-spinner"} />
              ) : (
                t("signup.reset_password")
              )}
            </div>
          )}
        </div>
      </div>
    </Box>
  );
};

export default ForgotPassword;
