// General
import "./private-room-onboarding-daddy-dialog.scss";
import { useEffect, forwardRef } from "react";
// Services
import { useLazyGetUserAioQuery } from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateOnboardingPrivateRoomDaddyDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";

const PrivateRoomOnboardingDaddyDialog = () => {
  // API variables
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();

  // Redux variables
  const onboardingPrivateRoomDaddyDialog = useSelector(
    (state) => state.dialog.onboardingPrivateRoomDaddyDialog
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!onboardingPrivateRoomDaddyDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-D18",
      },
    });

    getUserAio(null, true);
  }, [onboardingPrivateRoomDaddyDialog]);

  // Event Handlers | Button
  const onImageClick = (landing) => {
    if (landing?.cta === "private_call_search") {
      TagManager.dataLayer({
        dataLayer: {
          event:
            "PWA-D18-18.1-Button",
        },
      });

      onNavigate(routeConst.live.path);
      onCloseDialog();
    }
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateOnboardingPrivateRoomDaddyDialog(false));
  };

  return (
    <Dialog
      fullScreen
      open={onboardingPrivateRoomDaddyDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      <div id="private-room-onboarding-daddy-dialog">
        <div className="dialog-header">
          <div className="private-room-label">{t("1on1.private_room")}</div>

          <div className="close-button-container">
            <CloseIcon className="close-button" onClick={onCloseDialog} />
          </div>
        </div>

        {getUserAioSuccess && (
          <div className="private-room-onboarding-container">
            {getUserAioData?.data?.private_calls?.landing_pages?.private_call_landing?.map(
              (landing, index) => (
                <div
                  className="private-room-image-container"
                  onClick={() => onImageClick(landing)}
                  key={index}
                >
                  <img
                    className="private-room-image"
                    src={landing?.graphic_url}
                    alt={"private-room-image"}
                  />
                </div>
              )
            )}
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default PrivateRoomOnboardingDaddyDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
