// General
import "./video-call.scss";
import { useEffect } from "react";
// Services
import { sessionService } from "../../../services/session.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateVideoCallTab } from "../../../redux/store/privateCallStore";
import { updateOnboardingVideoCallDialog } from "../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// embla-carousel-react
import useEmblaCarousel from "embla-carousel-react";
// Material UI
import { Tabs, Tab, Box, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
// i18next
import { useTranslation } from "react-i18next";
// Components
import Banner from "../../shared/elements/banner/banner";
import VideoCallTab from "./video-call-tab/video-call-tab";
import VideoCallRecentTab from "./video-call-recent-tab/video-call-recent-tab";

const VideoCall = () => {
  // Redux variables
  const videoCallTab = useSelector((state) => state.privateCall.videoCallTab);
  const dispatch = useDispatch();

  // MUI variables
  const isDesktop = useMediaQuery("(min-width: 720px)");

  // Embla variables
  const [emblaRef, emblaApi] = useEmblaCarousel({
    duration: 15,
  });

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-VideoCall-Page",
      },
    });

    // Check if "has-shown-video-call-dialog" in local storage is true or false
    if (!sessionService.getHasShownVideoCallDialog()) {
      dispatch(updateOnboardingVideoCallDialog(true));
    }
  }, []);

  // Lifecycle | Mounted | emblaApi
  useEffect(() => {
    if (!emblaApi) return;
    emblaApi?.scrollTo(videoCallTab);

    emblaApi?.on("select", () => {
      dispatch(updateVideoCallTab(emblaApi?.selectedScrollSnap()));
    });
  }, [emblaApi]);

  // Lifecycle | Check for update | videoCallTab
  useEffect(() => {
    emblaApi?.scrollTo(videoCallTab);
  }, [videoCallTab]);

  // Event Handlers | MUI Tabs
  const onTabChange = (event, newValue) => {
    TagManager.dataLayer({
      dataLayer: {
        event: `PWA-VideoCall-Page-TabChangeSwipe-Button-${
          newValue === 0 ? "PrivateRoom" : "Recent"
        }`,
      },
    });

    dispatch(updateVideoCallTab(newValue));
  };

  return (
    <div id="video-call-page">
      {isDesktop && (
        <div className="desktop-banner-container">
          <Banner type={"video-call"} view={"carousel"} />
        </div>
      )}

      <div className="max-width-container">
        <div className="tab-container">
          <Tabs
            className="custom-tabs"
            value={videoCallTab}
            onChange={onTabChange}
            TabIndicatorProps={{ style: { backgroundColor: "#710d0d" } }}
            variant={isDesktop ? "standard" : "fullWidth"}
          >
            <Tab label={t("1on1.private_room")} {...a11yProps(0)} />
            <Tab label={t("inbox.recent")} {...a11yProps(1)} />
          </Tabs>

          <div ref={emblaRef} className="embla">
            <div className="embla__container">
              <div className="embla__slide">
                {videoCallTab === 0 && <VideoCallTab />}
              </div>

              <div className="embla__slide">
                {videoCallTab === 1 && <VideoCallRecentTab />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoCall;

// Material UI | Tab Functions
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      // hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }} className="tab-box-container">
          {children}
          {/* <Typography component={"div"}>{children}</Typography> */}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
