// General
import "./debug-settings.scss";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateShowDebugInfo } from "../../../../redux/store/debugStore";
// Material UI
import { Switch } from "@mui/material";

const DebugSettings = () => {
  // Redux variables
  const showHiddenSettings = useSelector(
    (state) => state.app.showHiddenSettings
  );
  const showDebugInfo = useSelector((state) => state.debug.showDebugInfo);
  const dispatch = useDispatch();

  // Event Handlers | MUI Toggle Button
  const onToggleDebugMode = (event) => {
    dispatch(updateShowDebugInfo(event.target.checked));
  };

  if (showHiddenSettings) {
    return (
      <div id="settings-hidden-settings-subcomponent">
        <div className="padding-container">
          <div className="debug-mode-label">Debug Mode</div>

          <div className="debug-mode-switch-container">
            <Switch
              className="custom-switch"
              checked={showDebugInfo}
              onChange={onToggleDebugMode}
            />
          </div>
        </div>
      </div>
    );
  }
};

export default DebugSettings;
