// General
import "./terms-of-relationship.scss";
// Redux
import { useSelector } from "react-redux";
// Material UI
import { useMediaQuery } from "@mui/material";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Components
import TorTag from "../../tor-tag/tor-tag";

const TermsOfRelationship = (props) => {
  const { enableTitle = true, isShared = false, sharedTorTags } = props;

  // Redux variables
  const torTags = useSelector((state) => state.userCard.torTags);
  const selectedTorAfterApply = useSelector(
    (state) => state.search.selectedTorAfterApply
  );

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Utility Functions
  const returnMatchTor = (userTor) => {
    if (userTor?.matched) {
      return true;
    } else if (selectedTorAfterApply.includes(userTor?.id)) {
      return true;
    } else {
      return false;
    }
  };

  if (isShared) {
    return (
      <div id="profile-view-terms-of-relationship-subcomponent">
        <div className="padding-container">
          {enableTitle && (
            <div className="tor-label">
              <Trans
                i18nKey={"profile.terms_of_relationship"}
                components={{ sup: <sup className="trade-mark-label" /> }}
              />
            </div>
          )}

          <div className="tor-list-container">
            {sharedTorTags?.map((tor, index) => (
              <TorTag
                torName={tor?.locale}
                description={tor?.description}
                blur={tor?.blurred_out}
                match={tor?.matched}
                readOnly={isMobile ? false : true}
                unselectedBackgroundColor={isMobile ? "#f8f4f4" : "#F0E4E4"}
                selectedBackgroundColor={isMobile ? "#f8f4f4" : "#F0E4E4"}
                unselectedTextColor={isMobile ? "black" : "#710D0D"}
                selectedTextColor={isMobile ? "black" : "#710D0D"}
                unselectedBorderColor={isMobile ? "#d8d4d4" : "#F0E4E4"}
                selectedBorderColor={isMobile ? "#d8d4d4" : "#F0E4E4"}
                borderWidth={"1px"}
                fontSize={isMobile ? "0.9em" : "1em"}
                fontWeight={"600"}
                theme={tor?.theme}
                key={index}
              />
            ))}
          </div>
        </div>
      </div>
    );
  } else {
    if (torTags) {
      return (
        <div id="profile-view-terms-of-relationship-subcomponent">
          <div className="padding-container">
            {enableTitle && (
              <div className="tor-label">
                <Trans
                  i18nKey={"profile.terms_of_relationship"}
                  components={{ sup: <sup className="trade-mark-label" /> }}
                />
              </div>
            )}

            <div className="tor-list-container">
              {torTags?.map((tor, index) => (
                <TorTag
                  torName={tor?.locale}
                  description={tor?.description}
                  blur={tor?.blurred_out}
                  match={returnMatchTor(tor)}
                  readOnly={isMobile ? false : true}
                  unselectedBackgroundColor={isMobile ? "#f8f4f4" : "#F0E4E4"}
                  selectedBackgroundColor={isMobile ? "#f8f4f4" : "#F0E4E4"}
                  unselectedTextColor={isMobile ? "black" : "#710D0D"}
                  selectedTextColor={isMobile ? "black" : "#710D0D"}
                  unselectedBorderColor={isMobile ? "#d8d4d4" : "#F0E4E4"}
                  selectedBorderColor={isMobile ? "#d8d4d4" : "#F0E4E4"}
                  borderWidth={"1px"}
                  fontSize={isMobile ? "0.9em" : "1em"}
                  fontWeight={"600"}
                  theme={tor?.theme}
                  key={index}
                />
              ))}
            </div>
          </div>
        </div>
      );
    }
  }
};

export default TermsOfRelationship;
