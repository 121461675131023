// General
import "./payment-error-dialog.scss";
import { useEffect } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updatePaymentErrorDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog } from "@mui/material";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const PaymentErrorDialog = () => {
  // Redux variables
  const paymentErrorDialog = useSelector(
    (state) => state.dialog.paymentErrorDialog
  );
  const paymentErrorInfo = useSelector(
    (state) => state.payment.paymentErrorInfo
  );
  const dispatch = useDispatch();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!paymentErrorDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-PaymentError-Dialog",
      },
    });
  }, [paymentErrorDialog]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updatePaymentErrorDialog(false));
  };

  return (
    <Dialog
      className="custom-radius35-dialog"
      open={paymentErrorDialog}
      onClose={onCloseDialog}
    >
      <div id="payment-error-dialog">
        <div className="error-title-container">
          <div className="error-icon-container">
            {getIcon("paymentErrorIcon", "error-icon")}
          </div>
          <div className="error-title">{paymentErrorInfo?.title}</div>
          <div className="error-message">{paymentErrorInfo?.message}</div>
        </div>

        <div className="error-body-container">
          <div className="warning-icon-container">
            {getIcon("paymentWarningIcon", "warning-icon")}
          </div>

          <div className="error-subtitle">{paymentErrorInfo?.subtitle}</div>

          <div className="error-solution-container">
            {paymentErrorInfo?.solutions?.map((solution, index) => (
              <div className="solution-container" key={index}>
                <div className="solution-index">{index + 1}.</div>
                <div
                  className="error-solution"
                  dangerouslySetInnerHTML={{ __html: solution }}
                  key={index}
                ></div>
              </div>
            ))}
          </div>
        </div>

        <div className="error-button-container">
          <div className="got-it-button" onClick={onCloseDialog}>
            {paymentErrorInfo?.cta}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default PaymentErrorDialog;
