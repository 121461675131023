// General
import "./daily-check-in-reward.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetLevellingTasksQuery,
  useClaimLevellingTasksMutation,
} from "../../../services/data.service";
// Static Data
import routeConst from "../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateCheckInStreakDialog,
  updateDailyCheckInDialog,
  updateLevelsDescriptionDialog,
} from "../../../redux/store/dialogStore";
import { updateCheckInInfo } from "../../../redux/store/userStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import Spinner from "../elements/spinner/spinner";

const DailyCheckInReward = () => {
  // API variables
  const [
    getLevellingTasks,
    {
      data: getLevellingTasksData,
      error: getLevellingTasksErrorData,
      isFetching: getLevellingTasksFetching,
      isLoading: getLevellingTasksLoading,
      isSuccess: getLevellingTasksSuccess,
      isError: getLevellingTasksError,
    },
  ] = useLazyGetLevellingTasksQuery();
  const [
    claimLevellingTasks,
    {
      data: claimLevellingTasksData,
      error: claimLevellingTasksErrorData,
      isLoading: claimLevellingTasksLoading,
      isSuccess: claimLevellingTasksSuccess,
      isError: claimLevellingTasksError,
    },
  ] = useClaimLevellingTasksMutation();

  // Render variables
  let dailyCheckInView;
  let buttonView;

  // Redux variables
  const isLoggedIn = useSelector((state) => state.public.isLoggedIn);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!isLoggedIn) return;

    getLevellingTasks(null, true);
  }, [isLoggedIn]);

  // Lifecycle | Check for update | Claim Levelling Task API Response
  useEffect(() => {
    if (claimLevellingTasksLoading) {
    } else if (claimLevellingTasksSuccess) {
      if (claimLevellingTasksData?.status === 0) {
        // Pass data to CheckInStreakDialog
        dispatch(updateCheckInInfo(claimLevellingTasksData?.data?.task));

        // Close Dialog
        dispatch(updateDailyCheckInDialog(false));

        // Open Dialogs
        dispatch(updateCheckInStreakDialog(true));
        dispatch(updateLevelsDescriptionDialog(true));
      }
    } else if (claimLevellingTasksError) {
      if (claimLevellingTasksErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    claimLevellingTasksLoading,
    claimLevellingTasksSuccess,
    claimLevellingTasksError,
  ]);

  // Event Handlers | Button
  const onCheckIn = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-43-Button",
      },
    });

    const obj = {
      task_id: 3,
    };

    claimLevellingTasks(obj);
  };

  // Render Functions
  if (getLevellingTasksFetching || getLevellingTasksLoading) {
    return <Spinner />;
  } else if (getLevellingTasksSuccess) {
    if (getLevellingTasksData?.data?.tasks?.length > 0) {
      getLevellingTasksData?.data?.tasks?.forEach((level) => {
        if (level.task_id === 3) {
          dailyCheckInView = level.check_ins.map((task, index) => (
            <div className="exp-container" key={index}>
              <div className="day">{t("levelling.day_n", { n: task.day })}</div>
              <div
                className={`exp-icon-container ${
                  task.claimed ? "claimed" : ""
                }`}
              >
                EXP
              </div>

              {task.claimed ? (
                <CheckCircleIcon className="check-icon" />
              ) : (
                <div className="exp">+{task.exp}</div>
              )}
            </div>
          ));

          buttonView = (
            <Button
              className={`check-in-button ${
                level.claimed ? "disabled-button" : ""
              }`}
              variant="text"
              onClick={onCheckIn}
              disabled={level.claimed}
            >
              {claimLevellingTasksLoading ? (
                <Spinner size={24} isPadding={false} color={"white-spinner"} />
              ) : (
                t("levelling.checkin_now")
              )}
            </Button>
          );
        }
      });
    }
  } else if (getLevellingTasksError) {
    if (
      getLevellingTasksErrorData?.status === 401 ||
      getLevellingTasksErrorData?.message?.includes("Unauthenticated")
    ) {
      onNavigate(routeConst.logout.path);
    }
  }

  return (
    <div id="daily-check-in-reward-shared-subcomponent">
      <div className="exp-list-container">{dailyCheckInView}</div>

      {buttonView}
    </div>
  );
};

export default DailyCheckInReward;
