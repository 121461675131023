// General
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // General variables
  gameTitle: "Sugarbook Casino",
  isGameOpen: false,
  selectedGameUrl: null,

  // Utility variables
  gameListPassthrough: null,
};

export const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    // General Functions
    updateGameTitle: (state, action) => {
      state.gameTitle = action.payload;
    },
    resetGameTitle: (state) => {
      state.gameTitle = "Sugarbook Casino";
    },
    updateIsGameOpen: (state) => {
      state.isGameOpen = !state.isGameOpen;
    },
    updateSelectedGameUrl: (state, action) => {
      state.selectedGameUrl = action.payload;
    },
    resetSelectedGameUrl: (state) => {
      state.selectedGameUrl = null;
    },

    // Utility Functions
    resetGameStore: () => {
      return { ...initialState };
    },
    updateGameListPassthrough: (state) => {
      state.gameListPassthrough = {};
    },
  },
});

export const {
  updateGameTitle,
  resetGameTitle,
  updateIsGameOpen,
  updateSelectedGameUrl,
  resetSelectedGameUrl,

  // Utility Functions
  resetGameStore,
  updateGameListPassthrough,
} = gameSlice.actions;

export default gameSlice.reducer;
