// General
import "./topbar.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetProfileQuery,
  useLazyGetVirtualCurrencyCoinBalanceQuery,
  useUpdatePrivateCallSettingsMutation,
} from "../../../../../services/data.service";
// Static Data
import routeConst from "../../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  // Coins Functions
  updateTotalCoins,
} from "../../../../../redux/store/privateCallStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
import useEndPrivateCall from "../../../../utility/custom-hooks/useEndPrivateCall-hook";
// Components
import Spinner from "../../../elements/spinner/spinner";
import PermissionsStatus from "./permissions-status/permissions-status";
import ActionButtons from "./action-buttons/action-buttons";
import NetworkStatus from "./network-status/network-status";

const TopBar = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    getCoins,
    {
      data: getCoinsData,
      error: getCoinsErrorData,
      isFetching: getCoinsFetching,
      isLoading: getCoinsLoading,
      isSuccess: getCoinsSuccess,
      isError: getCoinsError,
    },
  ] = useLazyGetVirtualCurrencyCoinBalanceQuery();

  // General variables
  const [isRestartCameraButtonDisable, setIsRestartCameraButtonDisable] =
    useState(false);

  // Redux variables
  const callStatus = useSelector((state) => state.privateCall.callStatus);
  const totalDiamonds = useSelector((state) => state.privateCall.totalDiamonds);
  const callRequestDiamonds = useSelector(
    (state) => state.privateCall.callRequestDiamonds
  );
  const currentCallRequestDiamonds = useSelector(
    (state) => state.privateCall.currentCallRequestDiamonds
  );
  const totalCoins = useSelector((state) => state.privateCall.totalCoins);
  const showVideoCallEndingOverlay = useSelector(
    (state) => state.privateCall.showVideoCallEndingOverlay
  );
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();
  const onEndCall = useEndPrivateCall();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  // Lifecycle | Check for update | getProfile API Reponse
  useEffect(() => {
    if (getProfileFetching || getProfileLoading) {
    } else if (getProfileSuccess) {
      if (isDaddy) {
        getCoins();
      }
    } else if (getProfileError) {
    }
  }, [
    getProfileFetching,
    getProfileLoading,
    getProfileSuccess,
    getProfileError,
  ]);

  // Lifecycle | Check for update | Virtual Currency Coins API Response
  useEffect(() => {
    if (getCoinsFetching || getCoinsLoading) {
    } else if (getCoinsSuccess) {
      dispatch(updateTotalCoins(getCoinsData?.data?.balance));
    } else if (getCoinsError) {
      if (getCoinsErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [getCoinsFetching, getCoinsLoading, getCoinsSuccess, getCoinsError]);

  // Lifecycle | Check for update | showVideoCallEndingOverlay
  useEffect(() => {
    if (!showVideoCallEndingOverlay) return;

    // onEndCall();
  }, [showVideoCallEndingOverlay]);

  if (getProfileFetching || getProfileLoading) {
    return <Spinner />;
  } else if (getProfileSuccess) {
    return (
      <div id="video-call-interactable-overlay-topbar-subcomponent">
        <div className="front-camera-placeholder"></div>

        <div className="callee-container">
          <div className="username">
            {getProfileData?.data?.username || t("common.new_user")}
          </div>

          {/* Diamonds */}
          {!isDaddy && (
            <div className="diamonds-container">
              {getIcon("sbDiamondIcon", "diamonds-icon")}

              <div className="diamonds-count-container">
                <div className="diamonds-count">
                  {totalDiamonds?.toLocaleString()}
                </div>
                {callStatus === "JOIN" && (
                  <div className="diamonds-count-current-call">
                    +{currentCallRequestDiamonds?.toLocaleString()}
                  </div>
                )}
              </div>
            </div>
          )}

          {/* Coins */}
          {isDaddy && (
            <div className="coins-container">
              {getIcon("sbCoinIcon", "coins-icon")}
              <div className="coins-count">{totalCoins?.toLocaleString()}</div>
            </div>
          )}

          <PermissionsStatus />
        </div>

        <span className="spacer"></span>

        <div className="right-container">
          <ActionButtons />

          <NetworkStatus />
        </div>
      </div>
    );
  } else if (getProfileError) {
  }
};

export default TopBar;
