// General
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // Global Pusher variables
  globalPusherSubscribe: null,
  globalPusherUnsubscribe: null,
  globalPusherPayload: null,
  globalPusherReset: null,
  globalPusherDestroy: {},

  // Messaging Pusher variables
  messagingPusherSubscribe: null,
  messagingPusherUnsubscribe: null,
  messagingPusherPayload: null,
  messagingPusherReset: null,
  messagingPusherDestroy: {},

  // Live View Pusher variables
  liveViewPusherSubscribe: null,
  liveViewPusherUnsubscribe: null,
  liveViewPusherPayload: null,
  liveViewPusherTrigger: null,
  liveViewPusherReset: null,
  liveViewPusherDestroy: {},

  // Video Call Pusher variables
  videoCallPusherSubscribe: null,
  videoCallPusherUnsubscribe: null,
  videoCallPusherPayload: null,
  videoCallPusherReset: null,
  videoCallPusherDestroy: {},

  // Utility Functions
  globalPusherQueue: [],
};

export const pusherSlice = createSlice({
  name: "pusher",
  initialState,
  reducers: {
    // Global Pusher Functions
    updateGlobalPusherSubscribe: (state, action) => {
      state.globalPusherSubscribe = action.payload;
    },
    updateGlobalPusherUnsubscribe: (state, action) => {
      state.globalPusherUnsubscribe = action.payload;
    },
    updateGlobalPusherPayload: (state, action) => {
      state.globalPusherPayload = action.payload;
    },
    updateGlobalPusherReset: (state, action) => {
      state.globalPusherReset = action.payload;
    },
    updateGlobalPusherDestroy: (state, action) => {
      state.globalPusherDestroy = action.payload;
    },

    // Messaging Pusher Functions
    updateMessagingPusherSubscribe: (state, action) => {
      state.messagingPusherSubscribe = action.payload;
    },
    updateMessagingPusherUnsubscribe: (state, action) => {
      state.messagingPusherUnsubscribe = action.payload;
    },
    updateMessagingPusherPayload: (state, action) => {
      state.messagingPusherPayload = action.payload;
    },
    updateMessagingPusherReset: (state, action) => {
      state.messagingPusherReset = action.payload;
    },
    updateMessagingPusherDestroy: (state, action) => {
      state.messagingPusherDestroy = action.payload;
    },

    // Live View Pusher Functions
    updateLiveViewPusherSubscribe: (state, action) => {
      state.liveViewPusherSubscribe = action.payload;
    },
    updateLiveViewPusherUnsubscribe: (state, action) => {
      state.liveViewPusherUnsubscribe = action.payload;
    },
    updateLiveViewPusherPayload: (state, action) => {
      state.liveViewPusherPayload = action.payload;
    },
    updateLiveViewPusherTrigger: (state, action) => {
      state.liveViewPusherTrigger = action.payload;
    },
    updateLiveViewPusherReset: (state, action) => {
      state.liveViewPusherReset = action.payload;
    },
    updateLiveViewPusherDestroy: (state, action) => {
      state.liveViewPusherDestroy = action.payload;
    },

    // Video Call Pusher Functions
    updateVideoCallPusherSubscribe: (state, action) => {
      state.videoCallPusherSubscribe = action.payload;
    },
    updateVideoCallPusherUnsubscribe: (state, action) => {
      state.videoCallPusherUnsubscribe = action.payload;
    },
    updateVideoCallPusherPayload: (state, action) => {
      state.videoCallPusherPayload = action.payload;
    },
    updateVideoCallPusherReset: (state, action) => {
      state.videoCallPusherReset = action.payload;
    },
    updateVideoCallPusherDestroy: (state, action) => {
      state.videoCallPusherDestroy = action.payload;
    },

    // Utility Functions
    resetPusherStore: () => {
      return { ...initialState };
    },
    updateGlobalPusherQueue: (state, action) => {
      state.globalPusherQueue = action.payload;
    },
  },
});

export const {
  // Global Pusher Functions
  updateGlobalPusherSubscribe,
  updateGlobalPusherUnsubscribe,
  updateGlobalPusherPayload,
  updateGlobalPusherReset,
  updateGlobalPusherDestroy,

  // Messaging Pusher Functions
  updateMessagingPusherSubscribe,
  updateMessagingPusherUnsubscribe,
  updateMessagingPusherPayload,
  updateMessagingPusherReset,
  updateMessagingPusherDestroy,

  // Live View Pusher Functions
  updateLiveViewPusherSubscribe,
  updateLiveViewPusherUnsubscribe,
  updateLiveViewPusherPayload,
  updateLiveViewPusherTrigger,
  updateLiveViewPusherReset,
  updateLiveViewPusherDestroy,

  // Video Call Pusher Functions
  updateVideoCallPusherSubscribe,
  updateVideoCallPusherUnsubscribe,
  updateVideoCallPusherPayload,
  updateVideoCallPusherReset,
  updateVideoCallPusherDestroy,

  // Utility Functions
  resetPusherStore,
  updateGlobalPusherQueue,
} = pusherSlice.actions;

export default pusherSlice.reducer;
