// General
import "./user-is-live-dialog.scss";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateUserIsLiveData } from "../../../../redux/store/livestreamingStore";
import { updateUserIsLiveDialog } from "../../../../redux/store/dialogStore";
// Material UI
import { Dialog } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const UserIsLiveDialog = () => {
  // Redux variables
  const userIsLiveDialog = useSelector(
    (state) => state.dialog.userIsLiveDialog
  );
  const userIsLiveData = useSelector(
    (state) => state.livestreaming.userIsLiveData
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Event Handlers | Button
  const onRedirectToLivestream = () => {
    onCloseDialog();
    onNavigate(
      `${routeConst.live.liveId.clearPath}${userIsLiveData?.channelId}`
    );
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateUserIsLiveData(null));
    dispatch(updateUserIsLiveDialog(false));
  };

  return (
    <Dialog
      className="custom-radius35-dialog"
      open={userIsLiveDialog}
      onClose={onCloseDialog}
    >
      <div id="user-is-live-dialog">
        <div className="padding-container">
          <div className="live-icon-container">
            {getIcon("userIsLiveLiveIcon", "live-icon")}
          </div>

          <div className="user-is-live-title">
            {userIsLiveData?.username} {t("live.user_is_live_title")}
          </div>

          <div className="user-is-live-description">
            {t("live.user_is_live_description")}
          </div>

          <div className="button-container">
            <div className="continue-button" onClick={onRedirectToLivestream}>
              {t("live.user_is_live_cta")}
            </div>
            <div className="close-button" onClick={onCloseDialog}>
              {t("live.user_is_live_cta_close")}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default UserIsLiveDialog;
