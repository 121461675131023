// General
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // General variables
  reason: "",
  suggestion: "",
};

export const deactivateSlice = createSlice({
  name: "deactivate",
  initialState,
  reducers: {
    // General Functions
    updateReason: (state, action) => {
      state.reason = action.payload;
    },
    updateSuggestion: (state, action) => {
      state.suggestion = action.payload;
    },

    // Utility Functions
    resetDeactivateStore: () => {
      return { ...initialState };
    },
  },
});

export const {
  // General Functions
  updateReason,
  updateSuggestion,

  // Utility Functions
  resetDeactivateStore,
} = deactivateSlice.actions;

export default deactivateSlice.reducer;
