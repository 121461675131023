// General
import "./upgrade-redirect.scss";
import { useState, useEffect } from "react";
// Services
import { useLazyGetProfileQuery } from "../../../services/data.service";
// Static Data
import routeConst from "../../../const/routeConst";
// Redux
import { useSelector } from "react-redux";
// socket.io-client
import io from "socket.io-client";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
import { updatePremiumSubscribeInfo } from "../../../redux/store/upgradeStore";

const UpgradeRedirect = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // General variables
  const [socket, setSocket] = useState(null);

  // Redux variables
  const ccbillLink = useSelector((state) => state.payment.ccbillLink);

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);

    const newSocket = io("https://connect.thesugarbook.com:8443");
    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, []);

  // Lifecycle | Check for update
  useEffect(() => {
    if (getProfileSuccess && socket) {
      const userId = getProfileData?.data?.id_int;

      socket.on(`${userId}:App\\Events\\Payment\\GatewayStatus`, (data) => {
        const paymentStatus = data.payload.payment_status;

        if (paymentStatus.toLowerCase() === "success") {
          const paymentDetail = data.payload.content;

          dispatchEvent(updatePremiumSubscribeInfo(paymentDetail));
          onNavigate(routeConst.upgrade.status.path);
        }
      });

      return () => {
        socket.off(`${userId}:App\\Events\\Payment\\GatewayStatus`);
      };
    }
  }, [getProfileSuccess, socket]);

  if (ccbillLink) {
    window.open(ccbillLink);

    return (
      <div id="upgrade-redirect-page">
        <iframe className="iframe-container" src={ccbillLink}></iframe>
      </div>
    );
  }
};

export default UpgradeRedirect;
