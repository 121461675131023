// General
import "./photo-permissions.scss";
import { useState, useEffect } from "react";
// Services
import { useLazyGetPhotoPermissionListQuery } from "../../../services/data.service";
// Static Data
import routeConst from "../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updatePhotoPermissionLists,
  updatePhotoPermissionPagination,
  resetPhotoPermissionLists,
} from "../../../redux/store/interestStore";
// React Infinite Scroller
import InfiniteScroll from "react-infinite-scroller";
// react-simple-pull-to-refresh
import PullToRefresh from "react-simple-pull-to-refresh";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { useMediaQuery } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import PhotoPermissionsList from "./photo-permissions-list/photo-permissions-list";
import EmptyList from "./empty-list/empty-list";
import Spinner from "../../shared/elements/spinner/spinner";
import UtilityDesktopNavbar from "../../shared/elements/utility-desktop-navbar/utility-desktop-navbar";

const PhotoPermissions = () => {
  // API variables
  const [
    getPhotoPermissionList,
    {
      data: getPhotoPermissionListData,
      error: getPhotoPermissionListErrorData,
      isFetching: getPhotoPermissionListFetching,
      isLoading: getPhotoPermissionListLoading,
      isSuccess: getPhotoPermissionListSuccess,
      isError: getPhotoPermissionListError,
    },
  ] = useLazyGetPhotoPermissionListQuery();

  // General variables
  const [firstLoad, setFirstLoad] = useState(true);

  // Render variables
  let photoPermissionsListView;

  // Redux variables
  const photoPermissionLists = useSelector(
    (state) => state.interest.photoPermissionLists
  );
  const photoPermissionPagination = useSelector(
    (state) => state.interest.photoPermissionPagination
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 700px)");

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-36",
      },
    });

    dispatch(resetPhotoPermissionLists());
    getPhotoPermissionList(null, false);
  }, []);

  // Lifecycle | Check for update | Photo Permission List API Response
  useEffect(() => {
    if (getPhotoPermissionListFetching || getPhotoPermissionListLoading) {
    } else if (getPhotoPermissionListSuccess) {
      switch (getPhotoPermissionListData?.status) {
        case 0:
          // Pagination
          dispatch(
            updatePhotoPermissionPagination(
              getPhotoPermissionListData?.data?.pagination?.next_cursor
            )
          );

          // User List
          if (getPhotoPermissionListData?.data?.results?.length > 0) {
            dispatch(
              updatePhotoPermissionLists(
                getPhotoPermissionListData?.data?.results
              )
            );
          }
          break;
        default:
          break;
      }
    } else if (getPhotoPermissionListError) {
      if (getPhotoPermissionListErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getPhotoPermissionListFetching,
    getPhotoPermissionListLoading,
    getPhotoPermissionListSuccess,
    getPhotoPermissionListError,
  ]);

  // Infinite Scroller Functions
  const infiniteScrollFunc = () => {
    if (!photoPermissionPagination) return;

    setTimeout(() => {
      getPhotoPermissionList(
        `?pagination_token=${photoPermissionPagination}`,
        true
      );
    }, 1000);
  };

  // Pull To Refresh Functions
  const pullToRefreshFunc = () => {
    dispatch(resetPhotoPermissionLists());
    setFirstLoad(true);

    return new Promise((resolve) => {
      resolve(getPhotoPermissionList(null, false));
    });
  };

  // Render Functions | Photo Permissions Data
  if (photoPermissionLists?.length > 0) {
    photoPermissionsListView = (
      <div
        className={`infinite-scroller-container ${
          isMobile ? "" : "include-utility-height"
        }`}
      >
        {photoPermissionLists?.map((user, index) => (
          <PhotoPermissionsList
            id={user?.user?.id}
            idInt={user?.user?.id_int}
            username={user?.user?.username}
            membershipType={user?.user?.membership_type}
            verifiedProfile={user?.user?.is_verified_profile}
            verifiedCaller={user?.user?.verified_caller}
            levellingBadge={user?.user?.levelling_profile?.badge}
            profilePhoto={user?.user?.profile_photo?.original_photo}
            publicPhotos={user?.user?.public_photos?.map((photo) => {
              return {
                photoUrl: photo?.original_photo,
              };
            })}
            privatePhotos={user?.user?.private_photos.map((photo) => {
              return {
                photoUrl: photo?.original_photo,
                isSensitive: photo?.is_sensitive,
              };
            })}
            // About Me Functions
            personalityType={user?.user?.personality?.personality_type}
            hobbies={user?.user?.habit?.hobbies}
            starSign={user?.user?.personality?.star_sign}
            fitnessLevel={user?.user?.habit?.fitness_level}
            dominantOrSubmissive={
              user?.user?.personality?.dominant_or_submissive
            }
            personalityTraits={user?.user?.personality?.personality_traits}
            // Looking For Functions
            lookingForAgeRange={user?.user?.looking_fors?.age}
            lookingForLocation={user?.user?.looking_fors?.locations}
            lookingForEthnicityRace={user?.user?.looking_fors?.ethnicity}
            // User Utility
            shouldCallApi={true}
            privatePhotoRequested={
              user?.user?.interaction?.private_photo_requested
            }
            privatePhotoApproval={
              user?.user?.interaction?.private_photo_approval
            }
            approvedMyPrivatePhoto={user?.granted}
            role={user?.user?.role}
            key={index}
          />
        ))}
      </div>
    );
  } else if (
    firstLoad &&
    (getPhotoPermissionListFetching || getPhotoPermissionListLoading)
  ) {
    photoPermissionsListView = <Spinner />;
  } else {
    photoPermissionsListView = <EmptyList />;
  }

  return (
    <div id="photo-permissions-page">
      <div className="max-width-container">
        <UtilityDesktopNavbar leftButton={"back"} />
        <PullToRefresh
          className={`pull-to-refresh-container  ${
            isMobile ? "" : "include-utility-height"
          }`}
          onRefresh={pullToRefreshFunc}
          refreshingContent={t("common.release")}
          pullingContent={t("common.pull_to_refresh")}
          isPullable={
            !getPhotoPermissionListFetching && !getPhotoPermissionListLoading
          }
        >
          <div className="photo-permission-list-container">
            <InfiniteScroll
              pageStart={0}
              loadMore={infiniteScrollFunc}
              hasMore={photoPermissionPagination ? true : false}
              loader={<Spinner key={0} />}
              useWindow={false}
            >
              {photoPermissionsListView}
            </InfiniteScroll>
          </div>
        </PullToRefresh>
      </div>
    </div>
  );
};

export default PhotoPermissions;
