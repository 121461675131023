// General
import "./gift-element.scss";
// Static Data
import livestreamingConst from "../../../../../const/livestreamingConst";
// Redux
import { useSelector } from "react-redux";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../../custom-avatar/custom-avatar";

const GiftElement = (props) => {
  const {
    isCoAnchor = false,
    livestreamingAchievementBadge,
    profilePhoto,
    profileDecorations,
    username,
    recipientUsername = null,
    levellingBadge,
    giftName,
    giftValue,
    giftImage,

    // Style
    padding = livestreamingConst.config.giftElement.privateCall.padding,
    margin = livestreamingConst.config.giftElement.privateCall.margin,
    borderColor = livestreamingConst.config.giftElement.privateCall.borderColor,
    borderRadius = livestreamingConst.config.giftElement.privateCall
      .borderRadius,
    backgroundColor = "none",
    font = livestreamingConst.config.giftElement.font,
    textColor = livestreamingConst.config.giftElement.desktopFontColor,
  } = props;

  // Redux variables
  const isDaddy = useSelector((state) => state.user.isDaddy);

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  return (
    <div
      id="chat-section-gift-element-subcomponent"
      style={{
        padding: padding,
        margin: margin,
        border: borderColor !== "none" ? `1px solid ${borderColor}` : "none",
        borderRadius: borderRadius,
        backgroundColor: backgroundColor,
      }}
    >
      <div className="profile-picture-container">
        <CustomAvatar
          className="profile-picture"
          src={profilePhoto}
          profileBorder={profileDecorations?.profile_border_url}
        />
      </div>

      <div className="chat-info-container">
        {livestreamingAchievementBadge?.length > 0 && (
          <span className="livestreaming-achievement-badge-container">
            {livestreamingAchievementBadge?.map((badge, index) => (
              <img
                className="livestreaming-achievement-badge"
                src={badge?.url}
                alt={badge?.type}
                key={index}
              />
            ))}
          </span>
        )}

        <span className="username" style={{ font: font, color: textColor }}>
          {username || "-"}
        </span>

        {levellingBadge && (
          <span className="levelling-badge-container">
            <img
              className="levelling-badge"
              src={levellingBadge}
              alt="levelling-badge"
            />
          </span>
        )}

        <span className="sent-text" style={{ font: font, color: textColor }}>
          {t("common.sent_single")}
        </span>

        <span className="gift-name" style={{ font: font, color: textColor }}>
          {giftName}
        </span>

        {!isDaddy && giftValue && (
          <div className="gift-diamond-count">
            <div className="diamond-icon-container">
              {getIcon("sbDiamondIcon", "diamond-icon")}
            </div>
            +{giftValue}
          </div>
        )}

        {isCoAnchor && (
          <span
            className="recipient-username-container"
            style={{ font: font, color: textColor }}
          >
            to {recipientUsername}
          </span>
        )}
      </div>

      <div className="gift-icon-container">
        <img className="gift-icon" src={giftImage} alt="gift" />
      </div>
    </div>
  );
};

export default GiftElement;
