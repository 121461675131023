// General
import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const feedSlice = createSlice({
  name: "feed",
  initialState,
  reducers: {
    // Utility Functions
    resetFeedStore: () => {
      return { ...initialState };
    },
  },
});

export const {
  // Utility Functions
  resetFeedStore,
} = feedSlice.actions;

export default feedSlice.reducer;
