const registrationConst = {
  premiumBenefits: {
    unassigned: "unassigned",
    upgrade: "upgrade",
    skip: "skip",
    completed: "completed",
  },
  lookingForAgeRange: {
    min: 18,
    max: 70,
  },
};

export default registrationConst;
