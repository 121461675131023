// General
import "./video-call-error-dialog.scss";
import { useEffect } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateEndVideoCall } from "../../../../redux/store/privateCallStore";
import { updateVideoCallErrorDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const VideoCallErrorDialog = () => {
  // Redux variables
  const videoCallErrorDialog = useSelector(
    (state) => state.dialog.videoCallErrorDialog
  );
  const permissionErrorMessage = useSelector(
    (state) => state.privateCall.permissionErrorMessage
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!videoCallErrorDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-D32",
      },
    });
  }, [videoCallErrorDialog]);

  // Event Handlers | Button
  const onProceed = () => {
    dispatch(updateEndVideoCall(true));
    onCloseDialog();
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = (event, reason) => {
    if (reason === "backdropClick") return;

    dispatch(updateVideoCallErrorDialog(false));
  };

  return (
    <Dialog
      className="custom-radius35-dialog"
      open={videoCallErrorDialog}
      onClose={onCloseDialog}
    >
      <div id="video-call-error-dialog">
        <div className="padding-container">
          <div className="alert-icon-container">
            {getIcon("actionAlertIcon", "alert-icon")}
          </div>

          <div className="error-title">{t("1on1.unable_to_start_standby")}</div>

          <div className="error-message">{permissionErrorMessage}</div>

          <div className="got-it-button" onClick={onProceed}>
            {t("common.got_it")}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default VideoCallErrorDialog;
