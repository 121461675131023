// General
import "./survey-monkey-dialog.scss";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// Services
import {
  useLazyGetUserAioQuery,
  useCreateCustomVariableMutation,
  useLazyGetCustomVariableQuery,
  useUpdateCustomVariableMutation,
  useDeleteCustomVariableMutation,
} from "../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateSurveyMonkeyDialog } from "../../../../redux/store/dialogStore";
import { updateSurveyOpenCounter } from "../../../../redux/store/surveyStore";
// Material UI
import { Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import routeConst from "../../../../const/routeConst";

const SurveyMonkeyDialog = () => {
  // API variables
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();
  const [
    createCustomVariable,
    {
      data: createCustomVariableData,
      error: createCustomVariableErrorData,
      isLoading: createCustomVariableLoading,
      isSuccess: createCustomVariableSuccess,
      isError: createCustomVariableError,
    },
  ] = useCreateCustomVariableMutation();
  const [
    getCustomVariable,
    {
      data: getCustomVariableData,
      error: getCustomVariableErrorData,
      isFetching: getCustomVariableFetching,
      isLoading: getCustomVariableLoading,
      isSuccess: getCustomVariableSuccess,
      isError: getCustomVariableError,
    },
  ] = useLazyGetCustomVariableQuery();
  const [
    updateCustomVariable,
    {
      data: updateCustomVariableData,
      error: updateCustomVariableErrorData,
      isLoading: updateCustomVariableLoading,
      isSuccess: updateCustomVariableSuccess,
      isError: updateCustomVariableError,
    },
  ] = useUpdateCustomVariableMutation();
  const [
    deleteCustomVariable,
    {
      data: deleteCustomVariableData,
      error: deleteCustomVariableErrorData,
      isLoading: deleteCustomVariableLoading,
      isSuccess: deleteCustomVariableSuccess,
      isError: deleteCustomVariableError,
    },
  ] = useDeleteCustomVariableMutation();

  // General variables
  const [totalItems, setTotalItems] = useState(0);
  const [popupIndex, setPopupIndex] = useState(0);

  // Redux variables
  const surveyMonkeyDialog = useSelector(
    (state) => state.dialog.surveyMonkeyDialog
  );
  const surveyOpenCounter = useSelector(
    (state) => state.survey.surveyOpenCounter
  );
  const isLoggedIn = useSelector((state) => state.public.isLoggedIn);
  const dispatch = useDispatch();

  // Router variables
  const location = useLocation();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!isLoggedIn) return;

    getUserAio();
  }, [isLoggedIn]);

  // Lifecycle | Check for update | User AIO API Response
  useEffect(() => {
    if (getUserAioFetching || getUserAioLoading) {
    } else if (getUserAioSuccess) {
      setTotalItems(getUserAioData?.data?.popup?.length);

      if (getUserAioData?.data?.popup?.length > 0) {
        getCustomVariable(getUserAioData?.data?.popup[0]?.id);
        // deleteCustomVariable(getUserAioData?.data?.popup[0]?.id); // For testing
        // deleteCustomVariable("test_popup_1"); // For testing
        // deleteCustomVariable("test_popup_2"); // For testing
      }
    } else if (getUserAioError) {
    }
  }, [
    getUserAioFetching,
    getUserAioLoading,
    getUserAioSuccess,
    getUserAioError,
  ]);

  // Lifecycle | Check for update | Create Custom Variable API Response
  useEffect(() => {
    if (createCustomVariableLoading) {
    } else if (createCustomVariableSuccess) {
      dispatch(updateSurveyMonkeyDialog(true));
    } else if (createCustomVariableError) {
    }
  }, [
    createCustomVariableLoading,
    createCustomVariableSuccess,
    createCustomVariableError,
  ]);

  // Lifecycle | Check for update | Get Custom Variable API Response
  useEffect(() => {
    if (getCustomVariableFetching || getCustomVariableLoading) {
    } else if (getCustomVariableSuccess) {
      switch (getCustomVariableData?.status) {
        case 0:
          dispatch(
            updateSurveyOpenCounter(getCustomVariableData?.data?.payload)
          );

          if (
            getCustomVariableData?.data?.payload <=
            getUserAioData?.data?.popup[popupIndex]?.maximum_count
          ) {
            dispatch(updateSurveyMonkeyDialog(true));
          } else if (popupIndex + 1 < totalItems) {
            setPopupIndex(popupIndex + 1);
          }
          break;
        default:
          break;
      }
    } else if (getCustomVariableError) {
      switch (getCustomVariableErrorData?.data?.status) {
        case -1:
          const body = {
            id: getUserAioData?.data?.popup[popupIndex]?.id,
            data: 1,
            type: "int",
          };
          createCustomVariable(body);

          dispatch(updateSurveyOpenCounter(1));
          break;
        default:
          break;
      }
    }
  }, [
    getCustomVariableFetching,
    getCustomVariableLoading,
    getCustomVariableSuccess,
    getCustomVariableError,
  ]);

  // Lifecycle | Check for update | Update Custom Variable API Response
  useEffect(() => {
    if (updateCustomVariableLoading) {
    } else if (updateCustomVariableSuccess) {
      dispatch(
        updateSurveyOpenCounter(updateCustomVariableData?.data?.new_data)
      );
    } else if (updateCustomVariableError) {
    }
  }, [
    updateCustomVariableLoading,
    updateCustomVariableSuccess,
    updateCustomVariableError,
  ]);

  // Lifecycle | Check for update | Delete Custom Variable API Response
  useEffect(() => {
    if (deleteCustomVariableLoading) {
    } else if (deleteCustomVariableSuccess) {
    } else if (deleteCustomVariableError) {
    }
  }, [
    deleteCustomVariableLoading,
    deleteCustomVariableSuccess,
    deleteCustomVariableError,
  ]);

  // Lifecycle | Check for update | queue
  useEffect(() => {
    if (popupIndex < totalItems) {
      getCustomVariable(getUserAioData?.data?.popup[popupIndex]?.id);
    }
  }, [popupIndex]);

  // Lifecycle | Check for update | location
  useEffect(() => {
    if (!getUserAioSuccess || !surveyOpenCounter) return;

    let path = routeConst.search.path;

    switch (getUserAioData?.data?.popup[popupIndex]?.location) {
      case "home":
        path = routeConst.search.path;
        break;
      default:
        break;
    }

    if (
      location.pathname.includes(path) &&
      surveyOpenCounter <=
        getUserAioData?.data?.popup[popupIndex]?.maximum_count
    ) {
      dispatch(updateSurveyMonkeyDialog(true));
    }
  }, [getUserAioSuccess, location]);

  // Event Handlers | Button
  const onOpenSurveyLink = () => {
    window.open(getUserAioData?.data?.popup[popupIndex]?.link, "_blank");

    const bodyObj = {
      id: getUserAioData?.data?.popup[popupIndex]?.id,
      data: 10,
      type: "int",
    };
    updateCustomVariable(bodyObj);
    onCloseDialog();
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = (event, reason) => {
    if (
      reason === "backdropClick" ||
      reason === "clickaway" ||
      reason === "escapeKeyDown"
    )
      return;

    dispatch(updateSurveyMonkeyDialog(false));
    dispatch(updateSurveyOpenCounter(0));

    if (popupIndex + 1 < totalItems) {
      setPopupIndex(popupIndex + 1);
    }
  };

  // Utility Functions
  const onUpdateCustomVariable = () => {
    const bodyObj = {
      id: getUserAioData?.data?.popup[popupIndex]?.id,
      data: surveyOpenCounter + 1,
      type: "int",
    };
    updateCustomVariable(bodyObj);

    dispatch(updateSurveyOpenCounter(surveyOpenCounter + 1));
  };

  if (getUserAioSuccess && getUserAioData?.data?.popup?.length > 0) {
    return (
      <Dialog
        className="custom-radius35-dialog custom-dialog-width-full survey-max-width"
        open={surveyMonkeyDialog}
        onClose={onCloseDialog}
      >
        <div
          id="survey-monkey-dialog"
          style={{
            backgroundImage: `url(${
              getUserAioData?.data?.popup[popupIndex]?.graphics?.find(
                (graphic) => graphic.type === "background"
              )?.url
            })`,
          }}
        >
          <div className="dialog-header">
            <div className="close-button-container">
              <CloseIcon
                className="close-button"
                onClick={() => {
                  onCloseDialog();
                  onUpdateCustomVariable();
                }}
              />
            </div>
          </div>

          <div className="padding-container">
            <div className="survey-monkey-image-container">
              {/* <img className="survey-monkey-image" src={getUserAioData?.data?.popup[0]} alt="sm" /> */}
            </div>

            <span className="spacer"></span>

            {false && (
              <div className="survey-monkey-title">
                {
                  getUserAioData?.data?.popup[popupIndex]?.copywriting?.find(
                    (copy) => copy?.type === "title"
                  )?.content
                }
              </div>
            )}

            {false && (
              <div className="survey-monkey-description">
                {
                  getUserAioData?.data?.popup[popupIndex].copywriting?.find(
                    (copy) => copy?.type === "subtitle"
                  )?.content
                }
              </div>
            )}

            {false && (
              <div className="continue-button" onClick={onOpenSurveyLink}>
                {
                  getUserAioData?.data?.popup[popupIndex].copywriting?.find(
                    (copy) => copy?.type === "cta"
                  )?.content
                }
              </div>
            )}

            <div className="button-container" onClick={onOpenSurveyLink}></div>
          </div>
        </div>
      </Dialog>
    );
  }
};

export default SurveyMonkeyDialog;
