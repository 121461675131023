// General
import "./verify-phone.scss";
import { useState, useEffect, useRef } from "react";
// Services
import {
  useSendPhoneVerificationCodeMutation,
  useVerifyVerificationCodeMutation,
} from "../../../services/data.service";
// Static Data
import routeConst from "../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateSuccessToast,
  updateErrorToast,
} from "../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { TextField, Button } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import Spinner from "../../shared/elements/spinner/spinner";

const VerifyPhone = () => {
  // API variables
  const [
    sendPhoneVerificationCode,
    {
      data: sendPhoneVerificationCodeData,
      error: sendPhoneValidationCodeErrorData,
      isLoading: sendPhoneVerificationCodeLoading,
      isSuccess: sendPhoneVerificationCodeSuccess,
      isError: sendPhoneVerificationCodeError,
    },
  ] = useSendPhoneVerificationCodeMutation();
  const [
    verifyVerificationCode,
    {
      data: verifyVerificationCodeData,
      error: verifyVerificationCodeErrorData,
      isLoading: verifyVerificationCodeLoading,
      isSuccess: verifyVerificationCodeSuccess,
      isError: verifyVerificationCodeError,
    },
  ] = useVerifyVerificationCodeMutation();

  // General variables
  const [verificationCode, setVerificationCode] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const verificationCode1Ref = useRef(null);
  const verificationCode2Ref = useRef(null);
  const verificationCode3Ref = useRef(null);
  const verificationCode4Ref = useRef(null);
  const verificationCode5Ref = useRef(null);
  const verificationCode6Ref = useRef(null);

  // Redux variables
  const country = useSelector((state) => state.signup.country);
  const phone = useSelector((state) => state.signup.phone);
  const sessionToken = useSelector((state) => state.signup.sessionToken);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-VerifyPhone-Page",
      },
    });
  }, []);

  // Lifecycle | Check for update | Phone Verification API Response
  useEffect(() => {
    if (sendPhoneVerificationCodeLoading) {
    } else if (sendPhoneVerificationCodeSuccess) {
      const toastObj = {
        message: t("signup.verification_code_sent"),
        autoClose: 3000,
      };
      dispatch(updateSuccessToast(toastObj));
    } else if (sendPhoneVerificationCodeError) {
      const toastObj = {
        message: sendPhoneValidationCodeErrorData?.data?.message,
        autoClose: 3000,
      };
      dispatch(updateErrorToast(toastObj));
    }
  }, [
    sendPhoneVerificationCodeLoading,
    sendPhoneVerificationCodeSuccess,
    sendPhoneVerificationCodeError,
  ]);

  // Lifecycle | Check for update | Verify Verification Code API Response
  useEffect(() => {
    if (verifyVerificationCodeLoading) {
    } else if (verifyVerificationCodeSuccess) {
      const toastObj = {
        message: t("common.done"),
        autoClose: 3000,
      };
      dispatch(updateSuccessToast(toastObj));

      onNavigate(routeConst.accountSignup.path);
    } else if (verifyVerificationCodeError) {
      const toastObj = {
        message: verifyVerificationCodeErrorData?.data?.message,
        autoClose: 3000,
      };
      dispatch(updateErrorToast(toastObj));
    }
  }, [
    verifyVerificationCodeLoading,
    verifyVerificationCodeSuccess,
    verifyVerificationCodeError,
  ]);

  // Event Handlers | Button
  const onContinue = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-VerifyPhone-Page-Save-Button",
      },
    });

    const obj = {
      phone_country_iso_code: country?.country_iso_code,
      phone_number: phone,
      session_token: sessionToken || "",
      sms_code: verificationCode.join(""),
    };

    verifyVerificationCode(obj);
  };
  const onResendVerificationCode = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-VerifyPhone-Page-ResendVerificationCode-Button",
      },
    });

    const obj = {
      phone_country_iso_code: country?.country_iso_code,
      phone_number: phone,
      recaptcha_token: "000000",
    };

    sendPhoneVerificationCode(obj);
  };
  const onBackToPhoneAuth = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-VerifyPhone-Page-BackToPhoneAuth-Button",
      },
    });

    onNavigate(routeConst.phoneAuth.path);
  };

  // Event Handlers | MUI Text Field
  const onVerificationCodeChange = (index) => (event) => {
    setVerificationCode((prevState) => {
      const newState = [...prevState];
      newState[index] = event.target.value;
      return newState;
    });
  };

  // Utility Functions
  const isFormValid = () => {
    return true;
  };
  const onMoveToNextTextField = (index) => (event) => {
    switch (event.target) {
      case verificationCode1Ref.current:
        verificationCode2Ref.current.focus();
        break;
      case verificationCode2Ref.current:
        verificationCode3Ref.current.focus();
        break;
      case verificationCode3Ref.current:
        verificationCode4Ref.current.focus();
        break;
      case verificationCode4Ref.current:
        verificationCode5Ref.current.focus();
        break;
      case verificationCode5Ref.current:
        verificationCode6Ref.current.focus();
        break;
      case verificationCode6Ref.current:
        break;
      default:
        break;
    }
  };

  return (
    <div id="verify-phone-page">
      <div className="max-width-container">
        <div className="header">
          {t("phone_auth.verify_your_mobile_number")}
        </div>

        <div className="subheader">
          {t("phone_auth.enter_verification_code", {
            countryCode: country?.country_iso_code,
            phoneNumber: phone,
          })}
        </div>

        <div className="verification-code-container">
          <TextField
            className="verification-code-text-field custom-text-field"
            required
            type="number"
            value={verificationCode[0]}
            onChange={onVerificationCodeChange(0)}
            variant="standard"
            inputProps={{
              ref: verificationCode1Ref,
              style: { textAlign: "center", font: "500 1.5em Lato" },
            }}
            onKeyUp={onMoveToNextTextField(0)}
          />

          <TextField
            className="custom-text-field"
            required
            type="number"
            value={verificationCode[1]}
            onChange={onVerificationCodeChange(1)}
            variant="standard"
            inputProps={{
              ref: verificationCode2Ref,
              style: { textAlign: "center", font: "500 1.5em Lato" },
            }}
            onKeyUp={onMoveToNextTextField(1)}
          />

          <TextField
            className="custom-text-field"
            required
            type="number"
            value={verificationCode[2]}
            onChange={onVerificationCodeChange(2)}
            variant="standard"
            inputProps={{
              ref: verificationCode3Ref,
              style: { textAlign: "center", font: "500 1.5em Lato" },
            }}
            onKeyUp={onMoveToNextTextField(2)}
          />

          <TextField
            className="custom-text-field"
            required
            type="number"
            value={verificationCode[3]}
            onChange={onVerificationCodeChange(3)}
            variant="standard"
            inputProps={{
              ref: verificationCode4Ref,
              style: { textAlign: "center", font: "500 1.5em Lato" },
            }}
            onKeyUp={onMoveToNextTextField(3)}
          />

          <TextField
            className="custom-text-field"
            required
            type="number"
            value={verificationCode[4]}
            onChange={onVerificationCodeChange(4)}
            variant="standard"
            inputProps={{
              ref: verificationCode5Ref,
              style: { textAlign: "center", font: "500 1.5em Lato" },
            }}
            onKeyUp={onMoveToNextTextField(4)}
          />

          <TextField
            className="custom-text-field"
            required
            type="number"
            value={verificationCode[5]}
            onChange={onVerificationCodeChange(5)}
            variant="standard"
            inputProps={{
              ref: verificationCode6Ref,
              style: { textAlign: "center", font: "500 1.5em Lato" },
            }}
          />
        </div>

        <div className="did-not-receive-container">
          <div className="did-not-receive-label">
            {t("phone_auth.did_not_receive")}
          </div>
          <div className="resend-button" onClick={onResendVerificationCode}>
            {t("common.resend")}
          </div>
        </div>

        <Button
          className={`continue-button ${
            isFormValid() ? "" : "disabled-button"
          }`}
          variant="text"
          onClick={onContinue}
          disabled={!isFormValid()}
        >
          {verifyVerificationCodeLoading ? (
            <Spinner size={20} isPadding={false} color={"white-spinner"} />
          ) : (
            t("common.continue")
          )}
        </Button>

        <div className="update-contact-info-label" onClick={onBackToPhoneAuth}>
          {t("phone_auth.update_contact_info")}
        </div>
      </div>
    </div>
  );
};

export default VerifyPhone;
