// General
import "./follows.scss";
import { useEffect } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateFollowersTabValue } from "../../../../redux/store/userStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// embla-carousel-react
import useEmblaCarousel from "embla-carousel-react";
// Material UI
import { Tabs, Tab } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Components
import FollowersTab from "./followers-tab/followers-tab";
import FollowingTab from "./following-tab/following-tab";
import GiftersTab from "./gifters-tab/gifters-tab";

const Follows = () => {
  // Render variables
  let renderTabsView = null;
  let renderTabPanelView = null;

  // Redux variables
  const followersTabValue = useSelector(
    (state) => state.user.followersTabValue
  );
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // Embla variables
  const [emblaRef, emblaApi] = useEmblaCarousel({
    duration: 15,
  });

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-27",
      },
    });
  }, []);

  // Lifecycle | Mounted | emblaApi
  useEffect(() => {
    if (!emblaApi) return;
    emblaApi?.scrollTo(followersTabValue);

    emblaApi?.on("select", () => {
      dispatch(updateFollowersTabValue(emblaApi?.selectedScrollSnap()));
    });
  }, [emblaApi]);

  // Lifecycle | Check for update | followersTabValue
  useEffect(() => {
    emblaApi?.scrollTo(followersTabValue);
  }, [followersTabValue]);

  // Event Handlers | MUI Tabs
  const onTabChange = (event, newValue) => {
    TagManager.dataLayer({
      dataLayer: {
        event: `PWA-27-27.1-Button${
          newValue === 0
            ? "FollowersTab"
            : newValue === 1
            ? "FollowingTab"
            : "GiftersTab"
        }`,
      },
    });

    emblaApi?.scrollTo(newValue);
  };

  // Render Functions
  if (isDaddy) {
    renderTabsView = (
      <Tabs
        className="custom-tabs"
        value={followersTabValue}
        onChange={onTabChange}
        TabIndicatorProps={{ style: { backgroundColor: "#710d0d" } }}
        variant="fullWidth"
      >
        <Tab label={t("common.followers")} />
        <Tab label={t("common.following")} />
      </Tabs>
    );

    renderTabPanelView = (
      <div ref={emblaRef} className="embla">
        <div className="embla__container">
          <div className="embla__slide">
            {followersTabValue === 0 && <FollowersTab />}
          </div>

          <div className="embla__slide">
            {followersTabValue === 1 && <FollowingTab />}
          </div>

          <div className="embla__slide">
            {followersTabValue === 2 && <FollowingTab />}
          </div>
        </div>
      </div>
    );
  } else {
    renderTabsView = (
      <Tabs
        className="custom-tabs"
        value={followersTabValue}
        onChange={onTabChange}
        TabIndicatorProps={{ style: { backgroundColor: "#710d0d" } }}
        variant="fullWidth"
      >
        <Tab label={t("common.followers")} />
        <Tab label={t("common.following")} />
        <Tab label={t("common.gifter")} />
      </Tabs>
    );

    renderTabPanelView = (
      <div ref={emblaRef} className="embla">
        <div className="embla__container">
          <div className="embla__slide">
            {followersTabValue === 0 && <FollowersTab />}
          </div>

          <div className="embla__slide">
            {followersTabValue === 1 && <FollowingTab />}
          </div>

          {!isDaddy && (
            <div className="embla__slide">
              {followersTabValue === 2 && <GiftersTab />}
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div id="streamer-center-follows-subcomponent">
      <div className="max-width-container">
        <div className="tab-container">
          {renderTabsView}
          {renderTabPanelView}
        </div>
      </div>
    </div>
  );
};

export default Follows;
