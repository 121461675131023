// General
import "./financial-information.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetJourneyQuestionsQuery,
  useLazyGetProfileQuery,
  useUpdateEditUserProfileMutation,
} from "../../../../../services/data.service";
// Static Data
import editProfileConst from "../../../../../const/editProfileConst";
import userConst from "../../../../../const/userConst";
import utilityConst from "../../../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateLifestyle,
  updateNetworth,
  updateAnnualIncome,
  updateDatingBudget,
  updateUpdateProfileDialogView,
  updateProfileCompletionState,
  updateUpdateProfileFullscreenTab,
} from "../../../../../redux/store/editProfileStore";
import {
  updateProfileCompletionPercentage,
  updateProfileRequirementPopup,
} from "../../../../../redux/store/profileStore";
import {
  updateRestartRegistrationDialog,
  updateUpdateProfileFullscreenDialog,
} from "../../../../../redux/store/dialogStore";
import {
  updateSuccessToast,
  updateInfoToast,
} from "../../../../../redux/store/toastStore";
// Material UI
import { Divider, MenuItem, FormControl, Select } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../../../shared/elements/spinner/spinner";
import ModerationStatus from "../../moderation-status/moderation-status";

const FinancialInformation = (props) => {
  const { mode = utilityConst.editProfileMode.mobile } = props;

  // API variables
  const [
    getJourneyQuestions,
    {
      data: getJourneyQuestionsData,
      error: getJourneyQuestionsErrorData,
      isFetching: getJourneyQuestionsFetching,
      isLoading: getJourneyQuestionsLoading,
      isSuccess: getJourneyQuestionsSuccess,
      isError: getJourneyQuestionsError,
    },
  ] = useLazyGetJourneyQuestionsQuery();
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    updateUserProfile,
    {
      data: updateUserProfileData,
      error: updateUserProfileErrorData,
      isLoading: updateUserProfileLoading,
      isSuccess: updateUserProfileSuccess,
      isError: updateUserProfileError,
    },
  ] = useUpdateEditUserProfileMutation();

  // General variables
  const [localLifestyle, setLocalLifestyle] = useState(null);
  const [localDatingBudget, setLocalDatingBudget] = useState(null);
  const [localNetworth, setLocalNetworth] = useState(null);
  const [localAnnualIncome, setLocalAnnualIncome] = useState(null);

  // Redux variables
  const lifestyle = useSelector((state) => state.editProfile.lifestyle);
  const datingBudget = useSelector((state) => state.editProfile.datingBudget);
  const networth = useSelector((state) => state.editProfile.networth);
  const annualIncome = useSelector((state) => state.editProfile.annualIncome);
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const profileCompletionPercentage = useSelector(
    (state) => state.profile.profileCompletionPercentage
  );
  const profileRequirementPopup = useSelector(
    (state) => state.profile.profileRequirementPopup
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    setLocalLifestyle(lifestyle?.id);
    setLocalDatingBudget(datingBudget?.id);
    setLocalNetworth(networth?.id);
    setLocalAnnualIncome(annualIncome?.id);

    getJourneyQuestions(null, true);
    getProfile(null, true);
  }, []);

  // Lifecycle | Check for update | Update User Profile API Response
  useEffect(() => {
    if (updateUserProfileLoading) {
    } else if (updateUserProfileSuccess) {
      switch (updateUserProfileData?.status) {
        case 1:
          dispatch(
            updateProfileCompletionPercentage(
              updateUserProfileData?.data?.user?.profile_progress?.summary
                ?.progress
            )
          );
          dispatch(
            updateProfileRequirementPopup(
              updateUserProfileData?.data?.user?.profile_progress?.summary
                ?.requirement
            )
          );

          dispatch(
            updateLifestyle(
              updateUserProfileData?.data?.user?.occupation?.lifestyle_budget
            )
          );
          dispatch(
            updateDatingBudget(
              updateUserProfileData?.data?.user?.relationship?.dating_budget
            )
          );
          dispatch(
            updateNetworth(
              updateUserProfileData?.data?.user?.occupation?.networth
            )
          );
          dispatch(
            updateAnnualIncome(
              updateUserProfileData?.data?.user?.occupation?.annual_income
            )
          );

          const infoToast = {
            message: t("signup.profile_updated"),
            autoClose: 1500,
          };
          dispatch(updateInfoToast(infoToast));
          break;
        default:
          break;
      }
    } else if (updateUserProfileError) {
    }
  }, [
    updateUserProfileLoading,
    updateUserProfileSuccess,
    updateUserProfileError,
  ]);

  // Event Handlers | Button
  const onEditSection = (section) => {
    if (profileCompletionPercentage < profileRequirementPopup) {
      dispatch(updateRestartRegistrationDialog(true));
    } else {
      // dispatch(
      //   updateProfileCompletionState({
      //     name: "financialInformation",
      //     slide: returnMaxFinancial(),
      //   })
      // );

      switch (section) {
        case "lifestyle":
          dispatch(
            updateUpdateProfileDialogView(
              editProfileConst.profileDialogView.lifestyle
            )
          );
          // dispatch(updateUpdateProfileFullscreenTab(0));
          break;
        case "datingBudget":
          dispatch(
            updateUpdateProfileDialogView(
              editProfileConst.profileDialogView.datingBudget
            )
          );
          // dispatch(updateUpdateProfileFullscreenTab(0));
          break;
        case "netWorth":
          dispatch(
            updateUpdateProfileDialogView(
              editProfileConst.profileDialogView.networth
            )
          );
          // dispatch(updateUpdateProfileFullscreenTab(1));
          break;
        case "annualIncome":
          dispatch(
            updateUpdateProfileDialogView(
              editProfileConst.profileDialogView.annualIncome
            )
          );
          // dispatch(updateUpdateProfileFullscreenTab(2));
          break;
        default:
          break;
      }

      dispatch(updateUpdateProfileFullscreenDialog(true));
    }
  };
  const onCheckProfilePercentage = () => {
    if (profileCompletionPercentage < profileRequirementPopup) {
      dispatch(updateRestartRegistrationDialog(true));
    }
  };

  // Event Handlers | MUI Text Fields
  const onInputChange = (state) => (event) => {
    switch (state) {
      case "lifestyle":
        setLocalLifestyle(event.target.value);
        const lifestyleObj = {
          profile: {
            lifestyle: event.target.value,
          },
        };
        updateUserProfile(lifestyleObj);
        break;
      case "datingBudget":
        setLocalDatingBudget(event.target.value);
        const datingBudgetObj = {
          profile: {
            dating_budget: event.target.value,
          },
        };
        updateUserProfile(datingBudgetObj);
        break;
      case "networth":
        setLocalNetworth(event.target.value);
        const networthObj = {
          profile: {
            networth: event.target.value,
          },
        };
        updateUserProfile(networthObj);
        break;
      case "annual-income":
        setLocalAnnualIncome(event.target.value);
        const annualIncomeObj = {
          profile: {
            annual_income: event.target.value,
          },
        };
        updateUserProfile(annualIncomeObj);
        break;
      default:
        break;
    }
  };

  // Utility Functions
  const isFinancialInformationValid = () => {
    if (isDaddy) {
      if (
        lifestyle &&
        annualIncome &&
        annualIncome !== "" &&
        networth &&
        networth !== ""
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (lifestyle) {
        return true;
      } else {
        return false;
      }
    }
  };
  const getLifestyleData = () => {
    return getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "lifestyle"
    )?.selections;
  };
  const getDatingBudgetData = () => {
    return getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "dating_budget"
    )?.selections;
  };
  const getNetworthData = () => {
    return getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "networth"
    )?.selections;
  };
  const getAnnualIncomeData = () => {
    return getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "annual_income"
    )?.selections;
  };
  const returnMaxFinancial = () => {
    if (isDaddy) {
      return 3;
    } else {
      return 1;
    }
  };
  const returnIsJourneyIncomplete = () => {
    return profileCompletionPercentage < profileRequirementPopup;
  };

  if (mode === utilityConst.editProfileMode.mobile) {
    return (
      <div id="mobile-edit-profile-financial-information-subcomponent">
        <div className="financial-information-label-container">
          <div className="financial-information-label">
            {t("profile.financial_information")}
          </div>
        </div>

        <div className="form-field-padding-container">
          {/* Lifestyle */}
          {!isDaddy && (
            <div className="form-field-container">
              <div className="label-container">
                <div className={`label ${lifestyle ? "" : "incomplete-field"}`}>
                  {t("profile.lifestyle")}
                </div>
              </div>

              <div
                className="form-container"
                onClick={() => onEditSection("lifestyle")}
              >
                {lifestyle ? (
                  <div className="filled-form">{lifestyle?.locale}</div>
                ) : (
                  <div className="empty-form">+ {t("common.add")}</div>
                )}

                <div className="right-icon">
                  <ChevronRightIcon className="select-form-field-dropdown-icon" />
                </div>
              </div>
            </div>
          )}

          {/* Dating Budget */}
          {isDaddy && (
            <div className="form-field-container">
              <div className="label-container">
                <div
                  className={`label ${datingBudget ? "" : "incomplete-field"}`}
                >
                  {t("profile.dating_budget")}
                </div>
              </div>

              <div
                className="form-container"
                onClick={() => onEditSection("datingBudget")}
              >
                {datingBudget ? (
                  <div className="filled-form">{datingBudget?.locale}</div>
                ) : (
                  <div className="empty-form">+ {t("common.add")}</div>
                )}

                <div className="right-icon">
                  <ChevronRightIcon className="select-form-field-dropdown-icon" />
                </div>
              </div>
            </div>
          )}

          {/* Net Worth */}
          {isDaddy && (
            <div className="form-field-container">
              <div className="label-container">
                <div className={`label ${networth ? "" : "incomplete-field"}`}>
                  {t("profile.net_worth")}
                </div>
              </div>

              <div
                className="form-container"
                onClick={() => onEditSection("netWorth")}
              >
                {networth ? (
                  <div className="filled-form">{networth?.locale}</div>
                ) : (
                  <div className="empty-form">+ {t("common.add")}</div>
                )}

                <div className="right-icon">
                  <ChevronRightIcon className="select-form-field-dropdown-icon" />
                </div>
              </div>
            </div>
          )}

          {/* Annual Income */}
          {isDaddy && (
            <div className="form-field-container">
              <div className="label-container">
                <div
                  className={`label ${annualIncome ? "" : "incomplete-field"}`}
                >
                  {t("profile.annual_income")}
                </div>
              </div>

              <div
                className="form-container"
                onClick={() => onEditSection("annualIncome")}
              >
                {annualIncome ? (
                  <div className="filled-form">{annualIncome?.locale}</div>
                ) : (
                  <div className="empty-form">+ {t("common.add")}</div>
                )}

                <div className="right-icon">
                  <ChevronRightIcon className="select-form-field-dropdown-icon" />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  } else if (mode === utilityConst.editProfileMode.desktop) {
    return (
      <div
        id="desktop-edit-profile-financial-information-subcomponent"
        onClick={onCheckProfilePercentage}
      >
        {/* In Review */}
        <ModerationStatus
          status={userConst.profileGeneralApprovalStatus.inReview}
        />

        {/* Rejected */}
        <ModerationStatus
          status={userConst.profileGeneralApprovalStatus.rejected}
        />

        <div className="financial-information-static-label-container">
          <div className="financial-information-label">
            {t("profile.financial_information")}
          </div>
          <Divider className="short-divider" />
        </div>

        <div
          className={`form-field-padding-container ${
            returnIsJourneyIncomplete() ? "none-clickable" : ""
          }`}
        >
          {/* Lifestyle */}
          {!isDaddy && (
            <div className="form-field-container">
              <div className="label-container">
                <div
                  className={`label ${
                    localLifestyle ? "" : "incomplete-field"
                  }`}
                >
                  {t("profile.lifestyle")}
                </div>
              </div>

              <div className="form-container">
                <FormControl
                  className="form-field custom-text-field-max-radius custom-text-field-outline-default custom-text-field-outline-white-background-color"
                  variant="outlined"
                  fullWidth
                >
                  <Select
                    className="form-select"
                    displayEmpty
                    value={localLifestyle || ""}
                    onChange={onInputChange("lifestyle")}
                    disableUnderline={true}
                    renderValue={(selected) => {
                      if (!selected || selected === "") {
                        return (
                          <div className="default-select-value">
                            + {t("common.add")}
                          </div>
                        );
                      }

                      const selectedLifestyle = getLifestyleData()?.find(
                        (item) => item?.id === selected
                      );

                      return (
                        selectedLifestyle?.display_locale || (
                          <div className="default-select-value">
                            + {t("common.add")}
                          </div>
                        )
                      );
                    }}
                  >
                    <MenuItem disabled value="">
                      + {t("common.add")}
                    </MenuItem>
                    {getLifestyleData()?.map((lifestyle, index) => (
                      <MenuItem key={index} value={lifestyle?.id}>
                        {t(`${lifestyle?.display_locale}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          )}

          {/* Dating Budget */}
          {isDaddy && (
            <div className="form-field-container">
              <div className="label-container">
                <div
                  className={`label ${
                    localDatingBudget ? "" : "incomplete-field"
                  }`}
                >
                  {t("profile.dating_budget")}
                </div>
              </div>

              <div className="form-container">
                <FormControl
                  className="form-field custom-text-field-max-radius custom-text-field-outline-default custom-text-field-outline-white-background-color"
                  variant="outlined"
                  fullWidth
                >
                  <Select
                    className="form-select"
                    displayEmpty
                    value={localDatingBudget || ""}
                    onChange={onInputChange("datingBudget")}
                    disableUnderline={true}
                    renderValue={(selected) => {
                      if (!selected || selected === "") {
                        return (
                          <div className="default-select-value">
                            + {t("common.add")}
                          </div>
                        );
                      }

                      const selectedDatingBudget = getDatingBudgetData()?.find(
                        (item) => item?.id === selected
                      );

                      return (
                        selectedDatingBudget?.display_locale || (
                          <div className="default-select-value">
                            + {t("common.add")}
                          </div>
                        )
                      );
                    }}
                  >
                    <MenuItem disabled value="">
                      + {t("common.add")}
                    </MenuItem>
                    {getDatingBudgetData()?.map((dating, index) => (
                      <MenuItem key={index} value={dating?.id}>
                        {t(`${dating?.display_locale}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          )}

          {/* Net Worth */}
          {isDaddy && (
            <div className="form-field-container">
              <div className="label-container">
                <div
                  className={`label ${localNetworth ? "" : "incomplete-field"}`}
                >
                  {t("profile.net_worth")}
                </div>
              </div>

              <div className="form-container">
                <FormControl
                  className="form-field custom-text-field-max-radius custom-text-field-outline-default custom-text-field-outline-white-background-color"
                  variant="outlined"
                  fullWidth
                >
                  <Select
                    className="form-select"
                    displayEmpty
                    value={localNetworth || ""}
                    onChange={onInputChange("networth")}
                    disableUnderline={true}
                    renderValue={(selected) => {
                      if (!selected || selected === "") {
                        return (
                          <div className="default-select-value">
                            + {t("common.add")}
                          </div>
                        );
                      }

                      const selectedNetworth = getNetworthData()?.find(
                        (item) => item?.id === selected
                      );

                      return (
                        selectedNetworth?.display_locale || (
                          <div className="default-select-value">
                            + {t("common.add")}
                          </div>
                        )
                      );
                    }}
                  >
                    <MenuItem disabled value="">
                      + {t("common.add")}
                    </MenuItem>
                    {getNetworthData()?.map((networth, index) => (
                      <MenuItem key={index} value={networth?.id}>
                        {t(`${networth?.display_locale}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          )}

          {/* Annual Income */}
          {isDaddy && (
            <div className="form-field-container">
              <div className="label-container">
                <div
                  className={`label ${
                    localAnnualIncome ? "" : "incomplete-field"
                  }`}
                >
                  {t("profile.annual_income")}
                </div>
              </div>

              <div className="form-container">
                <FormControl
                  className="form-field custom-text-field-max-radius custom-text-field-outline-default custom-text-field-outline-white-background-color"
                  variant="outlined"
                  fullWidth
                >
                  <Select
                    className="form-select"
                    displayEmpty
                    value={localAnnualIncome || ""}
                    onChange={onInputChange("annual-income")}
                    disableUnderline={true}
                    renderValue={(selected) => {
                      if (!selected || selected === "") {
                        return (
                          <div className="default-select-value">
                            + {t("common.add")}
                          </div>
                        );
                      }

                      const selectedAnnualIncome = getAnnualIncomeData()?.find(
                        (item) => item?.id === selected
                      );

                      return (
                        selectedAnnualIncome?.display_locale || (
                          <div className="default-select-value">
                            + {t("common.add")}
                          </div>
                        )
                      );
                    }}
                  >
                    <MenuItem disabled value="">
                      + {t("common.add")}
                    </MenuItem>
                    {getAnnualIncomeData()?.map((income, index) => (
                      <MenuItem key={index} value={income?.id}>
                        {t(`${income?.display_locale}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
};

export default FinancialInformation;
