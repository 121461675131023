// General
import "./events-tab.scss";
import { useEffect, useRef } from "react";
// Services
import { useLazyGetPrivateCallContestLeaderboardQuery } from "../../../../../services/data.service";
// Static Data
import leaderboardConst from "../../../../../const/leaderboardConst";
import routeConst from "../../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  // Private Call Leaderboard Functions
  updatePrivateCallTopStreamersEvent,
  updatePrivateCallTopGiftersEvent,
  resetPrivateCallTopStreamersEvent,
  resetPrivateCallTopGiftersEvent,
} from "../../../../../redux/store/leaderboardStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import Banner from "../../../../shared/elements/banner/banner";
import LeaderboardSection from "../../../../shared/leaderboard/leaderboard-section/leaderboard-section";

const EventsTab = () => {
  // API variables
  const [
    getPrivateCallContestLeaderboardTopStreamers,
    {
      data: getPrivateCallContestLeaderboardTopStreamersData,
      error: getPrivateCallContestLeaderboardTopStreamersErrorData,
      isFetching: getPrivateCallContestLeaderboardTopStreamersFetching,
      isLoading: getPrivateCallContestLeaderboardTopStreamersLoading,
      isSuccess: getPrivateCallContestLeaderboardTopStreamersSuccess,
      isError: getPrivateCallContestLeaderboardTopStreamersError,
    },
  ] = useLazyGetPrivateCallContestLeaderboardQuery();
  const [
    getPrivateCallContestLeaderboardTopGifters,
    {
      data: getPrivateCallContestLeaderboardTopGiftersData,
      error: getPrivateCallContestLeaderboardTopGiftersErrorData,
      isFetching: getPrivateCallContestLeaderboardTopGiftersFetching,
      isLoading: getPrivateCallContestLeaderboardTopGiftersLoading,
      isSuccess: getPrivateCallContestLeaderboardTopGiftersSuccess,
      isError: getPrivateCallContestLeaderboardTopGiftersError,
    },
  ] = useLazyGetPrivateCallContestLeaderboardQuery();

  // General variables
  const isMounted = useRef(false);

  // Redux variables
  const privateCallTopStreamersEvent = useSelector(
    (state) => state.leaderboard.privateCallTopStreamersEvent
  );
  const privateCallTopGiftersEvent = useSelector(
    (state) => state.leaderboard.privateCallTopGiftersEvent
  );
  const privateCallContestId = useSelector(
    (state) => state.leaderboard.privateCallContestId
  );
  const leaderboardTabValue = useSelector(
    (state) => state.leaderboard.leaderboardTabValue
  );
  const leaderboardPullToRefreshPassthrough = useSelector(
    (state) => state.leaderboard.leaderboardPullToRefreshPassthrough
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Utility variables
  const topGiftersEventParam = {
    board_type: "top_gifters",
    contest_id: privateCallContestId,
  };
  const topStreamersEventParam = {
    board_type: "top_streamers",
    contest_id: privateCallContestId,
  };

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!privateCallContestId) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-19-19.2-19.2.1",
      },
    });

    onCallApi(true);
  }, [privateCallContestId, leaderboardTabValue]);

  // Lifecycle | Check for update | Private Call Contest Top Streamers API Response
  useEffect(() => {
    if (
      getPrivateCallContestLeaderboardTopStreamersFetching ||
      getPrivateCallContestLeaderboardTopStreamersLoading
    ) {
    } else if (getPrivateCallContestLeaderboardTopStreamersSuccess) {
      if (
        getPrivateCallContestLeaderboardTopStreamersData?.status === 1 &&
        getPrivateCallContestLeaderboardTopStreamersData?.data?.top_streamers
          ?.results?.length > 0 &&
        getPrivateCallContestLeaderboardTopStreamersData?.data?.top_streamers?.results?.toString() !==
          privateCallTopStreamersEvent?.toString()
      ) {
        dispatch(resetPrivateCallTopStreamersEvent());
        dispatch(
          updatePrivateCallTopStreamersEvent(
            getPrivateCallContestLeaderboardTopStreamersData?.data
              ?.top_streamers?.results
          )
        );
      }
    } else if (getPrivateCallContestLeaderboardTopStreamersError) {
      if (
        getPrivateCallContestLeaderboardTopStreamersErrorData?.status === 401
      ) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getPrivateCallContestLeaderboardTopStreamersFetching,
    getPrivateCallContestLeaderboardTopStreamersLoading,
    getPrivateCallContestLeaderboardTopStreamersSuccess,
    getPrivateCallContestLeaderboardTopStreamersError,
  ]);

  // Lifecycle | Check for update | Private Call Contest Top Gifters API Response
  useEffect(() => {
    if (
      getPrivateCallContestLeaderboardTopGiftersFetching ||
      getPrivateCallContestLeaderboardTopGiftersLoading
    ) {
    } else if (getPrivateCallContestLeaderboardTopGiftersSuccess) {
      if (
        getPrivateCallContestLeaderboardTopGiftersData?.status === 1 &&
        getPrivateCallContestLeaderboardTopGiftersData?.data?.top_gifters
          ?.results?.length > 0 &&
        getPrivateCallContestLeaderboardTopGiftersData?.data?.top_gifters?.results?.toString() !==
          privateCallTopGiftersEvent?.toString()
      ) {
        dispatch(resetPrivateCallTopGiftersEvent());
        dispatch(
          updatePrivateCallTopGiftersEvent(
            getPrivateCallContestLeaderboardTopGiftersData?.data?.top_gifters
              ?.results
          )
        );
      }
    } else if (getPrivateCallContestLeaderboardTopGiftersError) {
      if (getPrivateCallContestLeaderboardTopGiftersErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getPrivateCallContestLeaderboardTopGiftersFetching,
    getPrivateCallContestLeaderboardTopGiftersLoading,
    getPrivateCallContestLeaderboardTopGiftersSuccess,
    getPrivateCallContestLeaderboardTopGiftersError,
  ]);

  // Lifecycle | Check for update | leaderboardPullToRefreshPassthrough
  useEffect(() => {
    if (isMounted.current) {
      if (!leaderboardPullToRefreshPassthrough) return;

      onCallApi(false);
    } else {
      isMounted.current = true;
    }
  }, [leaderboardPullToRefreshPassthrough]);

  // Utility Functions
  const onCallApi = (getFromCache) => {
    if (leaderboardTabValue !== leaderboardConst.type.privateCall) return;

    getPrivateCallContestLeaderboardTopStreamers(
      `?board_type=${topStreamersEventParam.board_type}&contest_id=${topGiftersEventParam.contest_id}`,
      getFromCache
    );
    getPrivateCallContestLeaderboardTopGifters(
      `?board_type=${topGiftersEventParam.board_type}&contest_id=${topGiftersEventParam.contest_id}`,
      getFromCache
    );
  };

  return (
    <div id="private-call-tab-events-tab-subcomponent">
      <Banner type={"leaderboard-private-call-events"} view={"stack"} />

      {/* Private Call | Top Streamer */}
      <LeaderboardSection
        type={leaderboardConst.type.privateCall}
        recurrence={leaderboardConst.tabs.events.events}
        isLoading={
          getPrivateCallContestLeaderboardTopStreamersFetching ||
          getPrivateCallContestLeaderboardTopStreamersLoading
        }
        title={t("leaderboard.top_earners")}
        requireInfoIcon={false}
        userList={privateCallTopStreamersEvent}
        userType={leaderboardConst.userType.streamer}
      />

      {/* Private Call | Top Gifter */}
      <LeaderboardSection
        type={leaderboardConst.type.privateCall}
        recurrence={leaderboardConst.tabs.events.events}
        isLoading={
          getPrivateCallContestLeaderboardTopGiftersFetching ||
          getPrivateCallContestLeaderboardTopGiftersLoading
        }
        title={t("leaderboard.top_event_gifters")}
        requireInfoIcon={false}
        userList={privateCallTopGiftersEvent}
        userType={leaderboardConst.userType.gifter}
      />
    </div>
  );
};

export default EventsTab;
