// General
import "./edit-looking-for-ethnicity.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetJourneyQuestionsQuery,
  useUpdateEditUserProfileMutation,
} from "../../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateLookingForEthnicityRace,
  updateIsUnsavedChanges,
} from "../../../../../redux/store/editProfileStore";
import {
  updateProfileCompletionPercentage,
  updateProfileRequirementPopup,
} from "../../../../../redux/store/profileStore";
import { updateUpdateProfileFullscreenDialog } from "../../../../../redux/store/dialogStore";
// Material UI
import { MenuItem, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import Spinner from "../../../elements/spinner/spinner";

const EditLookingForEthnicity = (props) => {
  const { addPaddingTop = false } = props;

  // API variables
  const [
    getJourneyQuestions,
    {
      data: getJourneyQuestionsData,
      error: getJourneyQuestionsErrorData,
      isFetching: getJourneyQuestionsFetching,
      isLoading: getJourneyQuestionsLoading,
      isSuccess: getJourneyQuestionsSuccess,
      isError: getJourneyQuestionsError,
    },
  ] = useLazyGetJourneyQuestionsQuery();
  const [
    updateProfile,
    {
      data: updateProfileData,
      error: updateProfileErrorData,
      isLoading: updateProfileLoading,
      isSuccess: updateProfileSuccess,
      isError: updateProfileError,
    },
  ] = useUpdateEditUserProfileMutation();

  // General variables
  const [localEthnicity, setLocalEthnicity] = useState([]);
  const [ethnicity, setEthnicity] = useState("");
  const [race, setRace] = useState("");

  // Redux variables
  const lookingForEthnicityRace = useSelector(
    (state) => state.editProfile.lookingForEthnicityRace
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    setLocalEthnicity(lookingForEthnicityRace);

    getJourneyQuestions(null, true);
  }, []);

  // Lifecycle | Check for update | updateProfile API Response
  useEffect(() => {
    if (updateProfileLoading) {
    } else if (updateProfileSuccess) {
      const temp = updateProfileData?.data?.user?.looking_fors?.ethnicity?.map(
        (item) => ({
          ethnicity: {
            id: item?.option?.id,
            locale: item?.option?.locale,
            display_locale: item?.option?.locale,
          },
          race: item?.sub_option
            ? {
                id: item?.sub_option?.id,
                locale: item?.sub_option?.locale,
                display_locale: item?.sub_option?.locale,
              }
            : null,
        })
      );
      dispatch(updateLookingForEthnicityRace(temp));
      dispatch(
        updateProfileCompletionPercentage(
          updateProfileData?.data?.user?.profile_progress?.summary?.progress
        )
      );
      dispatch(
        updateProfileRequirementPopup(
          updateProfileData?.data?.user?.profile_progress?.summary?.requirement
        )
      );
      dispatch(updateIsUnsavedChanges(false));
      dispatch(updateUpdateProfileFullscreenDialog(false));
    } else if (updateProfileError) {
    }
  }, [updateProfileLoading, updateProfileSuccess, updateProfileError]);

  // Lifecycle | Check for update | localEthnicity
  useEffect(() => {
    const arraysAreEqual =
      lookingForEthnicityRace?.length === localEthnicity?.length &&
      lookingForEthnicityRace?.every((ethnicity) =>
        localEthnicity?.some(
          (local) =>
            ethnicity?.ethnicity?.id === local?.ethnicity?.id &&
            ethnicity?.race?.id === local?.race?.id
        )
      );

    dispatch(updateIsUnsavedChanges(!arraysAreEqual));
  }, [localEthnicity]);

  // Event Handlers | Button
  const onSaveEthnicity = () => {
    if (!isFormValid()) return;

    const obj = {
      looking_for: {
        ethnicities: localEthnicity?.map(({ ethnicity, race }) => ({
          ethnicity: ethnicity.id,
          race: race?.id || null,
        })),
      },
    };
    updateProfile(obj);
  };
  const onRemoveEthnicityRace = (index) => {
    let ethnicityRaceTemp = [...localEthnicity];
    ethnicityRaceTemp.splice(index, 1);
    setLocalEthnicity(ethnicityRaceTemp);
  };

  // Event Handlers | MUI Select
  const handleSelectChange = (type) => (event) => {
    if (localEthnicity?.length < returnMaxEthnicity()) {
      switch (type) {
        case "ethnicity":
          setEthnicity(event.target.value);

          if (checkRaceExist(event.target.value) === false) {
            let ethnicityRaceTemp = [...lookingForEthnicityRace];
            ethnicityRaceTemp.push({ ethnicity: event.target.value });
            setLocalEthnicity(ethnicityRaceTemp);

            setEthnicity("");
          }
          break;
        case "race":
          setRace(event.target.value);

          let ethnicityRaceTemp = [...localEthnicity];
          ethnicityRaceTemp.push({
            ethnicity: ethnicity,
            race: event.target.value,
          });
          setLocalEthnicity(ethnicityRaceTemp);

          setEthnicity("");
          setRace("");
          break;
        default:
          break;
      }
    }
  };

  // Utility Functions
  const isFormValid = () => {
    return localEthnicity && !updateProfileLoading;
  };
  const getEthnicityData = () => {
    // Ensure selections is defined and is an array
    if (!Array.isArray(getJourneyQuestionsData?.selections)) {
      return [];
    }

    // Find the ethnicity data
    const ethnicityCategory = getJourneyQuestionsData?.selections.find(
      (item) => item?.category === "ethnicity"
    );

    // If ethnicity data is not found, return an empty array
    if (!ethnicityCategory || !Array.isArray(ethnicityCategory?.selections)) {
      return [];
    }

    // Filter out the ethnicity data based on localEthnicity conditions
    return ethnicityCategory.selections.filter(
      (ethnicity) =>
        !localEthnicity.some(
          (local) =>
            (local.ethnicity.id === ethnicity.id && local.race === null) ||
            (local.ethnicity.id === 701 && ethnicity.id == 701) ||
            (local.ethnicity.id === 700 && ethnicity.id === 700)
        )
    );
  };
  const getRaceData = () => {
    return (
      getJourneyQuestionsData?.selections
        ?.find((item) => item?.category === "race")
        ?.selections?.filter(
          (raceItem) =>
            raceItem?.sub_category === ethnicity?.tag && // Check if the sub_category matches the ethnicity tag
            !localEthnicity?.some((local) => local?.race?.id === raceItem?.id) // Ensure the item is not in localEthnicity
        ) ?? []
    );
  };
  const checkRaceExist = (ethnicity) => {
    const raceData = getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "race"
    )?.selections;

    const foundParent = raceData?.find(
      (item) => item.sub_category === ethnicity?.tag
    );

    return !!foundParent;
  };
  const returnMaxEthnicity = () => {
    return 3;
  };

  return (
    <div id="looking-for-ethnicity-subcomponent">
      <div className="padding-container">
        <div className="top-container">
          <div
            className={`looking-for-ethnicity-title ${
              addPaddingTop ? "add-edit-padding-top" : ""
            }`}
          >
            {t("profile.select_ethnicity_race")}
          </div>

          <div className="looking-for-ethnicity-description">
            {t("profile.you_may_select_up_to_ethnicity", {
              n: localEthnicity?.length,
              max: returnMaxEthnicity(),
            })}
          </div>

          {getJourneyQuestionsFetching || getJourneyQuestionsLoading ? (
            <Spinner />
          ) : (
            <div className="ethnicity-body-container">
              <div className="ethnicity-form-field-container">
                <div className="ethnicity-select-form-field-container">
                  <TextField
                    className="ethnicity-select-form-field"
                    select
                    value={ethnicity}
                    placeholder={t("registration.looking_for.select_ethnicity")}
                    label={t("registration.looking_for.select_ethnicity")}
                    onChange={handleSelectChange("ethnicity")}
                    variant="standard"
                  >
                    {getEthnicityData()?.map((ethnicity, index) => (
                      <MenuItem value={ethnicity} key={index}>
                        {t(`${ethnicity?.display_locale}`)}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>

                {checkRaceExist(ethnicity) && (
                  <div className="race-select-form-field-container">
                    <TextField
                      className="race-select-form-field"
                      select
                      value={race}
                      placeholder={t("registration.looking_for.select_race")}
                      label={t("registration.looking_for.select_race")}
                      onChange={handleSelectChange("race")}
                      variant="standard"
                    >
                      {ethnicity?.id !== 700 && ethnicity?.id !== 701 && (
                        <MenuItem value={null}>{t("common.all")}</MenuItem>
                      )}

                      {getRaceData()?.map((race, index) => (
                        <MenuItem value={race} key={index}>
                          {t(`${race?.display_locale}`)}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                )}
              </div>

              <div className="selected-ethnicity-race-container">
                {localEthnicity?.map((item, index) => (
                  <div className="ethnicity-race-container" key={index}>
                    {item?.ethnicity?.display_locale}{" "}
                    {item?.ethnicity?.id !== 701 && item?.race?.display_locale
                      ? `, ${item?.race?.display_locale}`
                      : ""}
                    <CloseIcon
                      className="remove-selected-ethnicity-race-icon"
                      onClick={() => onRemoveEthnicityRace(index)}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="bottom-container">
        <div
          className={`save-button ${isFormValid() ? "" : "disabled-button"}`}
          onClick={onSaveEthnicity}
        >
          {updateProfileLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("common.save")
          )}
        </div>
      </div>
    </div>
  );
};

export default EditLookingForEthnicity;
