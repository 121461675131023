// General
import "./get-the-full-sugar-experience-dialog.scss";
import { useEffect } from "react";
// Service
import { useLazyGetProfileQuery } from "../../../../services/data.service";
// Static Data
import userConst from "../../../../const/userConst";
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updatePaymentTag } from "../../../../redux/store/paymentStore";
import { updateFullSugarExperienceDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const GetTheFullSugarExperienceDialog = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // Render variables
  let subtitleView = null;
  let listView = null;

  // Redux variables
  const fullSugarExperienceDialog = useSelector(
    (state) => state.dialog.fullSugarExperienceDialog
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!fullSugarExperienceDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-GetTheFullSugarExperience-Dialog",
      },
    });

    getProfile(null, true);
  }, [fullSugarExperienceDialog]);

  // Event Handlers | Button
  const onUpgrade = () => {
    dispatch(updatePaymentTag("pwa-premium-lock-dialog"));
    onCloseDialog();
    onNavigate(routeConst.upgrade.path);
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateFullSugarExperienceDialog(false));
  };

  switch (getProfileData?.data?.role) {
    case userConst.userRole.sugardaddy:
      subtitleView = t("settings.dialog_upgrade_sd_desc");

      listView = (
        <ul>
          <li>{t("settings.dialog_upgrade_sd_point_1")}</li>
          <li>{t("settings.dialog_upgrade_sd_point_2")}</li>
          <li>{t("settings.dialog_upgrade_sd_point_3")}</li>
          <li>{t("settings.dialog_upgrade_sd_point_4")}</li>
          <li>{t("settings.dialog_upgrade_sd_point_5")}</li>
          <li>{t("settings.dialog_upgrade_sd_point_6")}</li>
          <li>{t("settings.dialog_upgrade_sd_point_7")}</li>
        </ul>
      );
      break;
    case userConst.userRole.sugarbaby:
      subtitleView = t("settings.dialog_upgrade_sbf_desc");

      listView = (
        <ul>
          <li>{t("settings.dialog_upgrade_sbf_point_1")}</li>
          <li>{t("settings.dialog_upgrade_sbf_point_2")}</li>
          <li>{t("settings.dialog_upgrade_sbf_point_3")}</li>
          <li>{t("settings.dialog_upgrade_sbf_point_4")}</li>
          <li>{t("settings.dialog_upgrade_sbf_point_5")}</li>
          <li>{t("settings.dialog_upgrade_sbf_point_6")}</li>
          <li>{t("settings.dialog_upgrade_sbf_point_7")}</li>
        </ul>
      );
      break;
    case userConst.userRole.sugarmommy:
      subtitleView = t("settings.dialog_upgrade_sm_desc");

      listView = (
        <ul>
          <li>{t("settings.dialog_upgrade_sm_point_1")}</li>
          <li>{t("settings.dialog_upgrade_sm_point_2")}</li>
          <li>{t("settings.dialog_upgrade_sm_point_3")}</li>
          <li>{t("settings.dialog_upgrade_sm_point_4")}</li>
          <li>{t("settings.dialog_upgrade_sm_point_5")}</li>
          <li>{t("settings.dialog_upgrade_sm_point_6")}</li>
          <li>{t("settings.dialog_upgrade_sm_point_7")}</li>
        </ul>
      );
      break;
    case userConst.userRole.maleSugarbaby:
      subtitleView = t("settings.dialog_upgrade_sbm_desc");

      listView = (
        <ul>
          <li>{t("settings.dialog_upgrade_sbm_point_1")}</li>
          <li>{t("settings.dialog_upgrade_sbm_point_2")}</li>
          <li>{t("settings.dialog_upgrade_sbm_point_3")}</li>
          <li>{t("settings.dialog_upgrade_sbm_point_4")}</li>
          <li>{t("settings.dialog_upgrade_sbm_point_5")}</li>
          <li>{t("settings.dialog_upgrade_sbm_point_6")}</li>
          <li>{t("settings.dialog_upgrade_sbm_point_7")}</li>
        </ul>
      );
      break;
    default:
      break;
  }

  return (
    <Dialog
      className="custom-radius10-dialog"
      open={fullSugarExperienceDialog}
      onClose={onCloseDialog}
    >
      <div id="get-the-full-sugar-experience-dialog">
        <div className="dialog-header">
          <div className="close-button-container">
            <CloseIcon className="close-button" onClick={onCloseDialog} />
          </div>
        </div>

        <div className="padding-container">
          <div className="premium-unlock-icon-container">
            {getIcon("premiumUnlockIcon", "premium-unlock-icon")}
          </div>

          <div className="title">{t("settings.dialog_upgrade_title")}</div>

          {getProfileData?.data?.role === userConst.userRole.sugardaddy && (
            <div className="subtitle">{subtitleView}</div>
          )}

          <div className="list-container">{listView}</div>

          <div className="upgrade-button" onClick={onUpgrade}>
            {t("settings.dialog_upgrade_to_premium")}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default GetTheFullSugarExperienceDialog;
