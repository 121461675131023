// General
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // Email Verification variables
  emailVerificationInterval: null,
  emailVerificationTimer: 0,
  emailVerificationDisplay: null,
  emailVerificationRequired: null,

  // Mobile Verification variables
  mobileVerificationInterval: null,
  mobileVerificationTimer: 0,
  mobileCallVerificationInterval: null,
  mobileCallVerificationTimer: 0,
  mobileVerificationDisplay: null,
  mobileVerificationRequired: null,
  mobileVerificationMethod: null,
  mobileVerificationShowAlternateMethod: false,

  // Sugarbook Verified variables
  sugarbookVerifiedSelfieImage: null,
  sugarbookVerifiedSelfieInterval: null,
  sugarbookVerifiedSelfieTimer: 3,
};

export const verificationSlice = createSlice({
  name: "verification",
  initialState,
  reducers: {
    // Email Verification Functions
    setEmailVerificationTimer: (state, action) => {
      state.emailVerificationTimer = action.payload;
    },
    updateEmailVerificationTimer: (state) => {
      state.emailVerificationTimer--;

      if (state.emailVerificationTimer <= 0) {
        clearInterval(state.emailVerificationInterval);
        state.emailVerificationInterval = null;
      }
    },
    updateEmailVerificationInterval: (state, action) => {
      if (state.emailVerificationInterval) return;

      state.emailVerificationInterval = action.payload;
    },
    clearEmailVerificationInterval: (state) => {
      clearInterval(state.emailVerificationInterval);
      state.emailVerificationInterval = null;
    },
    updateEmailVerificationDisplay: (state, action) => {
      state.emailVerificationDisplay = action.payload;
    },
    updateEmailVerificationRequired: (state, action) => {
      state.emailVerificationRequired = action.payload;
    },

    // Mobile Verification Functions
    setMobileVerificationTimer: (state, action) => {
      state.mobileVerificationTimer = action.payload;
    },
    updateMobileVerificationTimer: (state) => {
      state.mobileVerificationTimer--;

      if (state.mobileVerificationTimer <= 0) {
        clearInterval(state.mobileVerificationInterval);
        state.mobileVerificationInterval = null;
      } else if (state.mobileVerificationTimer <= 30) {
        state.mobileVerificationShowAlternateMethod = true;
      }
    },
    updateMobileVerificationInterval: (state, action) => {
      if (state.mobileVerificationInterval) return;

      state.mobileVerificationInterval = action.payload;
    },
    clearMobileVerificationInterval: (state) => {
      clearInterval(state.mobileVerificationInterval);
      state.mobileVerificationInterval = null;
    },
    setMobileCallVerificationTimer: (state, action) => {
      state.mobileCallVerificationTimer = action.payload;
    },
    updateMobileCallVerificationTimer: (state) => {
      state.mobileCallVerificationTimer--;

      if (state.mobileCallVerificationTimer <= 0) {
        clearInterval(state.mobileCallVerificationInterval);
        state.mobileCallVerificationInterval = null;
      } else if (state.mobileCallVerificationTimer <= 30) {
        state.mobileVerificationShowAlternateMethod = true;
      }
    },
    updateMobileCallVerificationInterval: (state, action) => {
      if (state.mobileCallVerificationInterval) return;

      state.mobileCallVerificationInterval = action.payload;
    },
    clearMobileCallVerificationInterval: (state) => {
      clearInterval(state.mobileCallVerificationInterval);
      state.mobileCallVerificationInterval = null;
    },
    updateMobileVerificationDisplay: (state, action) => {
      state.mobileVerificationDisplay = action.payload;
    },
    updateMobileVerificationRequired: (state, action) => {
      state.mobileVerificationRequired = action.payload;
    },
    updateMobileVerificationMethod: (state, action) => {
      state.mobileVerificationMethod = action.payload;
    },
    updateMobileVerificationShowAlternateMethod: (state, action) => {
      state.mobileVerificationShowAlternateMethod = action.payload;
    },

    // Sugarbook Verified Functions
    updateSugarbookVerifiedSelfieImage: (state, action) => {
      state.sugarbookVerifiedSelfieImage = action.payload;
    },
    updateSugarbookVerifiedSelfieInterval: (state, action) => {
      if (state.sugarbookVerifiedSelfieInterval) return;

      state.sugarbookVerifiedSelfieInterval = action.payload;
    },
    setSugarbookVerifiedSelfieTimer: (state, action) => {
      state.sugarbookVerifiedSelfieTimer = action.payload;
    },
    updateSugarbookVerifiedSelfieTimer: (state) => {
      state.sugarbookVerifiedSelfieTimer--;

      if (state.sugarbookVerifiedSelfieTimer <= 0) {
        clearInterval(state.sugarbookVerifiedSelfieInterval);
        state.sugarbookVerifiedSelfieInterval = null;
      }
    },
    clearSugarbookVerifiedSelfieInterval: (state) => {
      clearInterval(state.sugarbookVerifiedSelfieInterval);
      state.sugarbookVerifiedSelfieInterval = null;
    },

    resetVerificationStore: () => {
      return { ...initialState };
    },
  },
});

export const {
  // Email Verification Functions
  setEmailVerificationTimer,
  updateEmailVerificationTimer,
  updateEmailVerificationInterval,
  clearEmailVerificationInterval,
  updateEmailVerificationDisplay,
  updateEmailVerificationRequired,

  // Mobile Verification Functions
  setMobileVerificationTimer,
  updateMobileVerificationTimer,
  updateMobileVerificationInterval,
  clearMobileVerificationInterval,
  setMobileCallVerificationTimer,
  updateMobileCallVerificationTimer,
  updateMobileCallVerificationInterval,
  clearMobileCallVerificationInterval,
  updateMobileVerificationDisplay,
  updateMobileVerificationRequired,
  updateMobileVerificationMethod,
  updateMobileVerificationShowAlternateMethod,

  // Sugarbook Verified Functions
  updateSugarbookVerifiedSelfieImage,
  updateSugarbookVerifiedSelfieInterval,
  setSugarbookVerifiedSelfieTimer,
  updateSugarbookVerifiedSelfieTimer,
  clearSugarbookVerifiedSelfieInterval,

  resetVerificationStore,
} = verificationSlice.actions;

export default verificationSlice.reducer;
