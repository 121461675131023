// General
import "./update-app.scss";
import { useEffect } from "react";
// Services
import { useLazyGetUserAioQuery } from "../../../services/data.service";
// Service Worker
import * as serviceWorkerRegistration from "../../../serviceWorkerRegistration";
// Static Data
import utilityConst from "../../../const/utilityConst";
// Environment
import environment from "../../../environment/environment";
// Redux
import { useSelector } from "react-redux";
// Material UI
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
// i18next
import { useTranslation } from "react-i18next";

const UpdateApp = () => {
  // API variables
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();

  // Redux variables
  const isLoggedIn = useSelector((state) => state.public.isLoggedIn);
  const releaseChannel = useSelector((state) => state.app.releaseChannel);

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!isLoggedIn) return;

    getUserAio(null, true);
  }, [isLoggedIn]);

  // Event Handlers | Button
  const onRefreshApp = () => {
    serviceWorkerRegistration.unregister();

    setTimeout(() => {
      // After unregistering the service worker,
      // reload the page to successfully remove and re-register the service worker
      window.location.reload(true);
    }, 1000);
  };

  if (
    getUserAioSuccess &&
    getUserAioData?.data?.pwa?.version !== environment.appVersion &&
    releaseChannel === utilityConst.releaseChannel.nightly
  ) {
    return (
      <div id="update-app-component">
        <div className="padding-container">
          <div className="refresh-logo-container">
            <div className="refresh-outer-circle">
              <SettingsOutlinedIcon className="refresh-logo" />
            </div>
          </div>

          <div className="refresh-description-container">
            <div className="update-available-label">
              {t("misc.update_available")}
            </div>

            <div className="click-to-update-label">
              {t("misc.click_to_update_sugarbook")}
            </div>
          </div>

          <div className="update-button-container" onClick={onRefreshApp}>
            <div className="update-button">{t("settings.update")}</div>
          </div>
        </div>
      </div>
    );
  }
};

export default UpdateApp;
