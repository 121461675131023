// General
import "./skeleton-user-card-list.scss";
// Components
import SkeletonCard from "../elements/skeleton-card/skeleton-card";

const SkeletonUserCardList = (props) => {
  const { numberOfCards = 1, selectedView } = props;

  return (
    <div
      className={`infinite-scroller-container ${
        selectedView ? selectedView : ""
      }`}
      key={0}
    >
      {Array(numberOfCards)
        .fill()
        .map((_, index) => (
          <div className="list-container" key={index}>
            <SkeletonCard />
          </div>
        ))}
    </div>
  );
};

export default SkeletonUserCardList;
