// General
import "./loading-overlay.scss";
import { useState, useEffect } from "react";
// Static Data
import livestreamingConst from "../../../../const/livestreamingConst";
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector } from "react-redux";
// Material UI
import RefreshIcon from "@mui/icons-material/Refresh";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import Spinner from "../../../shared/elements/spinner/spinner";

const LivestreamLoadingOverlay = () => {
  // Constant variables
  const [selectedCopywriting, setSelectedCopywriting] = useState("");

  // General variables
  const copywritingList = livestreamingConst.loadingCopywriting;

  // Redux variables
  const livestreamLoading = useSelector(
    (state) => state.livestreaming.livestreamLoading
  );

  // i18next variables
  const { t, i18n } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    if (livestreamLoading) {
      setSelectedCopywriting(getLoadingCopywriting());
    }
  }, [livestreamLoading]);

  // Event Handlers | Button
  const onBackToLivestream = () => {
    onNavigate(routeConst.live.path);
  };
  const onRefreshPage = () => {
    window.location.reload(true);
  };

  // Utility Functions
  const getLoadingCopywriting = () => {
    if (i18n.language.includes("en")) {
      return copywritingList[
        Math.floor(Math.random() * copywritingList.length)
      ];
    } else {
      return t("live.loading");
    }
  };

  if (livestreamLoading) {
    return (
      <div id="livestream-loading-overlay-subcomponent">
        <div className="padding-container">
          <div className="header-container">
            <div className="back-button" onClick={onBackToLivestream}>
              <ArrowBackIosNewIcon className="close-button-icon" />
            </div>

            <div className="refresh-livestream-button" onClick={onRefreshPage}>
              <RefreshIcon className="refresh-livestream-icon" />
            </div>
          </div>

          <Spinner type={"iOS"} />

          <div className="loading-text">{selectedCopywriting}</div>

          <div className="tips-container">{t("live.tip")}</div>
        </div>
      </div>
    );
  }
};

export default LivestreamLoadingOverlay;
