// General
import "./general-loading-dialog.scss";
import { useEffect } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateGeneralLoadingDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog } from "@mui/material";
// Components
import Spinner from "../../elements/spinner/spinner";

const GeneralLoadingDialog = () => {
  // Redux variables
  const generalLoadingDialog = useSelector(
    (state) => state.dialog.generalLoadingDialog
  );
  const dispatch = useDispatch();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!generalLoadingDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-GeneralLoading-Dialog",
      },
    });
  }, [generalLoadingDialog]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateGeneralLoadingDialog(false));
  };

  return (
    <Dialog
      className={"custom-radius10-dialog"}
      open={generalLoadingDialog}
      onClose={onCloseDialog}
    >
      <div id="general-loading-dialog">
        <Spinner isPadding={false} />
      </div>
    </Dialog>
  );
};

export default GeneralLoadingDialog;
