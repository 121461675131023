// General
import "./product-purchase-dialog.scss";
import { useState, useEffect, forwardRef } from "react";
import { useLocation } from "react-router-dom";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateProductPurchaseDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide } from "@mui/material";
// Components
import ProductPurchase from "../../shop/product-purchase/product-purchase";

const ProductPurchaseDialog = () => {
  // General variables
  const [view, setView] = useState(null);

  // Redux variables
  const productPurchaseDialog = useSelector(
    (state) => state.dialog.productPurchaseDialog
  );

  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // Router variables
  const location = useLocation();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!productPurchaseDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-D19",
      },
    });
  }, [productPurchaseDialog]);

  // Lifecycle | Check for update | location
  useEffect(() => {
    if (!location) return;

    if (location.pathname.includes("live")) {
      setView("live");
    } else if (location.pathname.includes("video-call")) {
      if (isDaddy) {
        setView("caller");
      } else {
        setView("callee");
      }
    }
  }, [location]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateProductPurchaseDialog(false));
  };

  return (
    <Dialog
      className="custom-bottom-sheet-dialog"
      fullScreen
      open={productPurchaseDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={onCloseDialog}
    >
      {productPurchaseDialog && <ProductPurchase state={"dialog"} />}
    </Dialog>
  );
};

export default ProductPurchaseDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
