// General
import "./moderation-status.scss";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// Services
import { useLazyGetProfileQuery } from "../../../../services/data.service";
// Static Data
import userConst from "../../../../const/userConst";
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector } from "react-redux";
// Material UI
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";

const ModerationStatus = (props) => {
  const { status, margin = false, width = false } = props;

  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // i18next variables
  const { t } = useTranslation();

  // Router variables
  const location = useLocation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  // Event Handlers | Button
  const onRedirect = () => {
    if (location.pathname.includes(routeConst.profile.edit.path)) return;

    onNavigate(routeConst.profile.edit.path);
  };

  // Utility Functions
  const getApprovalStatus = (approval, newContent, newContentRejected) => {
    if (approval === true && newContent === null) {
      return userConst.profileGeneralApprovalStatus.approved;
    } else if (
      approval === false &&
      newContent !== null &&
      newContentRejected === true
    ) {
      return userConst.profileGeneralApprovalStatus.rejected;
    } else {
      return userConst.profileGeneralApprovalStatus.inReview;
    }
  };
  const showInReviewContainer = () => {
    if (
      ((getProfileData?.data?.username ||
        getProfileData?.data?.profile_details_approval?.username
          ?.new_content) &&
        getApprovalStatus(
          getProfileData?.data?.profile_details_approval?.username?.approval,
          getProfileData?.data?.profile_details_approval?.username?.new_content,
          getProfileData?.data?.profile_details_approval?.username
            ?.new_content_rejected
        )) === userConst.profileGeneralApprovalStatus.inReview ||
      ((getProfileData?.data?.aboutme ||
        getProfileData?.data?.profile_details_approval?.aboutme?.new_content) &&
        getApprovalStatus(
          getProfileData?.data?.profile_details_approval?.aboutme?.approval,
          getProfileData?.data?.profile_details_approval?.aboutme?.new_content,
          getProfileData?.data?.profile_details_approval?.aboutme
            ?.new_content_rejected
        ) === userConst.profileGeneralApprovalStatus.inReview) ||
      ((getProfileData?.data?.lookingfor ||
        getProfileData?.data?.profile_details_approval?.lookingfor
          ?.new_content) &&
        getApprovalStatus(
          getProfileData?.data?.profile_details_approval?.lookingfor?.approval,
          getProfileData?.data?.profile_details_approval?.lookingfor
            ?.new_content,
          getProfileData?.data?.profile_details_approval?.lookingfor
            ?.new_content_rejected
        ) === userConst.profileGeneralApprovalStatus.inReview)
    ) {
      return true;
    } else {
      return false;
    }
  };
  const showRejectedContainer = () => {
    if (
      ((getProfileData?.data?.username ||
        getProfileData?.data?.profile_details_approval?.username
          ?.new_content) &&
        getApprovalStatus(
          getProfileData?.data?.profile_details_approval?.username?.approval,
          getProfileData?.data?.profile_details_approval?.username?.new_content,
          getProfileData?.data?.profile_details_approval?.username
            ?.new_content_rejected
        ) === userConst.profileGeneralApprovalStatus.rejected) ||
      ((getProfileData?.data?.aboutme ||
        getProfileData?.data?.profile_details_approval?.aboutme?.new_content) &&
        getApprovalStatus(
          getProfileData?.data?.profile_details_approval?.aboutme?.approval,
          getProfileData?.data?.profile_details_approval?.aboutme?.new_content,
          getProfileData?.data?.profile_details_approval?.aboutme
            ?.new_content_rejected
        ) === userConst.profileGeneralApprovalStatus.rejected) ||
      ((getProfileData?.data?.lookingfor ||
        getProfileData?.data?.profile_details_approval?.lookingfor
          ?.new_content) &&
        getApprovalStatus(
          getProfileData?.data?.profile_details_approval?.lookingfor?.approval,
          getProfileData?.data?.profile_details_approval?.lookingfor
            ?.new_content,
          getProfileData?.data?.profile_details_approval?.lookingfor
            ?.new_content_rejected
        ) === userConst.profileGeneralApprovalStatus.rejected)
    ) {
      return true;
    } else {
      return false;
    }
  };
  const getRejectedItems = () => {
    let rejectedItems = [];
    if (
      getApprovalStatus(
        getProfileData?.data?.profile_details_approval?.username?.approval,
        getProfileData?.data?.profile_details_approval?.username?.new_content,
        getProfileData?.data?.profile_details_approval?.username
          ?.new_content_rejected
      ) === userConst.profileGeneralApprovalStatus.rejected
    ) {
      rejectedItems.push("Username");
    }

    if (
      getApprovalStatus(
        getProfileData?.data?.profile_details_approval?.aboutme?.approval,
        getProfileData?.data?.profile_details_approval?.aboutme?.new_content,
        getProfileData?.data?.profile_details_approval?.aboutme
          ?.new_content_rejected
      ) === userConst.profileGeneralApprovalStatus.rejected
    ) {
      rejectedItems.push("About Me");
    }

    if (
      getApprovalStatus(
        getProfileData?.data?.profile_details_approval?.lookingfor?.approval,
        getProfileData?.data?.profile_details_approval?.lookingfor?.new_content,
        getProfileData?.data?.profile_details_approval?.lookingfor
          ?.new_content_rejected
      ) === userConst.profileGeneralApprovalStatus.rejected
    ) {
      rejectedItems.push("Looking For");
    }

    return rejectedItems.join(", ");
  };

  return null;
  if (getProfileSuccess) {
    if (
      status === userConst.profileGeneralApprovalStatus.rejected &&
      showRejectedContainer()
    ) {
      return (
        <div
          className={`rejected-container ${
            width ? "full-moderation-width" : ""
          } ${margin ? "apply-margin" : ""}`}
          onClick={onRedirect}
        >
          <div className="left-container">
            <div className="moderation-icon-container">
              <CloseIcon className="error-icon" />
            </div>
          </div>

          <div className="right-container">
            <div className="moderation-title">
              {t("profile.rejected_title")}
            </div>
            <div className="moderation-desc">{t("profile.rejected_desc")}</div>
          </div>
        </div>
      );
    } else if (
      status === userConst.profileGeneralApprovalStatus.inReview &&
      showInReviewContainer()
    ) {
      return (
        <div
          className={`in-review-container ${
            width ? "full-moderation-width" : ""
          } ${margin ? "apply-margin" : ""}`}
        >
          <div className="left-container">
            <div className="moderation-icon-container">
              <AccessTimeIcon className="info-icon" />
            </div>
          </div>

          <div className="right-container">
            <div className="moderation-title">
              {t("profile.in_review_title")}
            </div>
            <div className="moderation-desc">{t("profile.in_review_desc")}</div>
          </div>
        </div>
      );
    }
  }
};

export default ModerationStatus;
