// General
import "./video-call-camera-tester-dialog.scss";
import { useEffect, useRef } from "react";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateVideoCallCameraTesterDialog,
  updateRestartPhoneDescriptionDialog,
} from "../../../../redux/store/dialogStore";
import { updateWarningToast } from "../../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";

const VideoCallCameraTesterDialog = () => {
  // General variables
  let testerVideo = useRef(null);
  let mediaStream = useRef(null);

  // Redux variables
  const videoCallCameraTesterDialog = useSelector(
    (state) => state.dialog.videoCallCameraTesterDialog
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    if (videoCallCameraTesterDialog) {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-D31",
        },
      });

      onGetCameraAccess();
    } else {
      // clean up camera
      if (testerVideo.current) {
        testerVideo.current.srcObject = null;
      }

      if (mediaStream.current && mediaStream.current.getTracks) {
        mediaStream.current.getTracks().forEach((track) => track.stop());
        mediaStream.current = null;
      }
    }
  }, [videoCallCameraTesterDialog]);

  // Event Handlers | Button
  const onProceedToPrivateStandby = () => {
    TagManager.dataLayer({
      dataLayer: {
        event:
          "PWA-D31-31.1-Button",
      },
    });

    onCloseDialog();
    onNavigate(routeConst.videoCall.privateStandby.path);
  };
  const onCancel = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-D31-31.2-Button",
      },
    });

    dispatch(updateRestartPhoneDescriptionDialog(true));
    onCloseDialog();
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateVideoCallCameraTesterDialog(false));
  };

  // Camera & Microphone Functions | Native JS Method
  const onGetCameraAccess = () => {
    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: { facingMode: "user" } })
        .then((stream) => {
          if (testerVideo.current) {
            testerVideo.current.srcObject = stream;
            mediaStream.current = stream;

            var playPromise = testerVideo.current.play();

            if (playPromise !== undefined) {
              playPromise
                .then((_) => {
                  // Automatic playback started!
                  // Show playing UI.
                })
                .catch((error) => {
                  // Auto-play was prevented
                  // Show paused UI.
                });
            }
          }
        })
        .catch((err) => {
          const toastObj = {
            message: err?.toString(),
            autoClose: 3000,
          };
          dispatch(updateWarningToast(toastObj));
        });
    }
  };
  const onGetMicrophoneAccess = () => {
    const video = document.getElementById("callee-tester-video");

    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          video.srcObject = stream;

          var playPromise = video.play();

          if (playPromise !== undefined) {
            playPromise
              .then((_) => {
                // Automatic playback started!
                // Show playing UI.
              })
              .catch((error) => {
                // Auto-play was prevented
                // Show paused UI.
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Dialog
      className="custom-radius35-dialog custom-dialog-transparent"
      open={videoCallCameraTesterDialog}
      onClose={onCloseDialog}
    >
      <div id="video-call-camera-tester-dialog">
        <div className="dialog-header">
          <div className="close-button-container">
            <CloseIcon className="close-button" onClick={onCloseDialog} />
          </div>
        </div>

        <div className="padding-container">
          <video id="callee-tester-video" ref={testerVideo} playsInline></video>

          <div className="camera-tester-title">
            {t("1on1.video_call_tester_title")}
          </div>

          <div className="camera-tester-description">
            <Trans
              i18nKey={"1on1.video_call_tester_desc"}
              components={{
                b: <b />,
                span: <span className="red-underline" />,
              }}
            />
          </div>

          <div className="button-container">
            <div className="proceed-button" onClick={onProceedToPrivateStandby}>
              {t("1on1.yes_i_can_see_myself")}
            </div>
            <div className="cancel-button" onClick={onCancel}>
              {t("1on1.no_restart_phone_now")}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default VideoCallCameraTesterDialog;
