// General
import "./low-power-mode-dialog.scss";
import { useEffect } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateLowPowerModeDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Divider } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

// TODO: Not Implemented
const LowPowerModeDialog = () => {
  // Redux variables
  const lowPowerModeDialog = useSelector(
    (state) => state.dialog.lowPowerModeDialog
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!lowPowerModeDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-LowPowerMode-Dialog",
      },
    });
  }, [lowPowerModeDialog]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = (event, reason) => {
    if (reason === "backdropClick") return;

    dispatch(updateLowPowerModeDialog(false));
  };

  return (
    <Dialog
      className="custom-radius10-dialog"
      open={lowPowerModeDialog}
      onClose={onCloseDialog}
    >
      <div id="low-power-mode-dialog">
        <div className="padding-container">
          <div className="header-container">
            <div className="low-power-image-container">
              {getIcon("lowPowerIcon", "low-power-image")}
            </div>

            <div className="text">{t("low_power.you_are_on")}</div>
            <div className="low-power-label">
              {t("low_power.low_power_mode")}
            </div>
          </div>

          <div className="low-power-description">
            {t("low_power.low_power_mode_desc")}
          </div>
        </div>

        <Divider />

        <div className="close-button" onClick={onCloseDialog}>
          {t("common.close")}
        </div>
      </div>
    </Dialog>
  );
};

export default LowPowerModeDialog;
