// General
import "./deactivate-account-dialog.scss";
import { useEffect } from "react";
// Services
import { useUpdateAccountDeactivationMutation } from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateDeactivateAccountDialog } from "../../../../redux/store/dialogStore";
import { updateSuccessToast } from "../../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Button } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const DeactivateAccountDialog = () => {
  // API variables
  const [
    deactivateAccount,
    {
      data: deactivateAccountData,
      error: deactivateAccountErrorData,
      isLoading: deactivateAccountLoading,
      isSuccess: deactivateAccountSuccess,
      isError: deactivateAccountError,
    },
  ] = useUpdateAccountDeactivationMutation();

  // Redux variables
  const deactivateAccountDialog = useSelector(
    (state) => state.dialog.deactivateAccountDialog
  );
  const reason = useSelector((state) => state.deactivate.reason);
  const suggestion = useSelector((state) => state.deactivate.suggestion);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!deactivateAccountDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-DeactivateAccount-Dialog",
      },
    });
  }, [deactivateAccountDialog]);

  // Lifecycle | Check for update | Deactivate account API Response
  useEffect(() => {
    if (deactivateAccountLoading) {
    } else if (deactivateAccountSuccess) {
      if (deactivateAccountData.status === 0) {
        const toastObj = {
          message: deactivateAccountData.payload?.message,
          autoClose: 3000,
        };
        dispatch(updateSuccessToast(toastObj));

        onNavigate(routeConst.logout.path);
      }
    } else if (deactivateAccountError) {
      if (deactivateAccountErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    deactivateAccountLoading,
    deactivateAccountSuccess,
    deactivateAccountError,
  ]);

  // Event Handlers | Button
  const onConfirm = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-DeactivateAccount-Dialog-Deactivate-Button",
      },
    });

    const obj = {
      reason: reason,
      suggestion: suggestion,
    };

    deactivateAccount(obj);
  };

  const onCloseDialog = () => {
    dispatch(updateDeactivateAccountDialog(false));
  };

  return (
    <Dialog
      className="custom-radius10-dialog"
      open={deactivateAccountDialog}
      onClose={onCloseDialog}
    >
      <div id="deactivate-account-dialog">
        <div className="padding-container">
          <div className="deactivate-image-container">
            {getIcon("deactivateAccountIcon", "deactivate-image")}
          </div>

          <div className="header">
            {t("account.deactivate_confirmation.title")}
          </div>

          <div className="text">
            {t("account.deactivate_confirmation.description_1")}
          </div>

          <div className="text">
            {t("account.deactivate_confirmation.description_2")}
          </div>

          <Button className="confirm-button" variant="text" onClick={onConfirm}>
            {t("common.confirm")}
          </Button>

          <Button
            className="cancel-button"
            variant="text"
            onClick={onCloseDialog}
          >
            {t("common.not_now")}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default DeactivateAccountDialog;
