// General
import "./restart-journey-notice.scss";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// Static Data
import routeConst from "../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateRestartRegistrationDialog } from "../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
// i18next
import { useTranslation, Trans } from "react-i18next";

const RestartJourneyNotice = () => {
  // General variables
  const [isNoticeClose, setIsNoticeClose] = useState(false);

  // Redux variables
  const profileCompletionPercentage = useSelector(
    (state) => state.profile.profileCompletionPercentage
  );
  const profileRequirementPopup = useSelector(
    (state) => state.profile.profileRequirementPopup
  );
  const isInJourney = useSelector((state) => state.registration.isInJourney);
  const isLoggedIn = useSelector((state) => state.public.isLoggedIn);
  const dispatch = useDispatch();

  // Router variables
  const location = useLocation();

  // Lifecycle | Mounted
  useEffect(() => {}, []);

  // Event Handlers | Button
  const onOpenRestartDialog = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-63-63.1",
      },
    });

    dispatch(updateRestartRegistrationDialog(true));
  };
  const onCloseNotice = () => {
    setIsNoticeClose(true);
  };

  if (
    isLoggedIn &&
    location.pathname.includes(routeConst.search.path) &&
    !isInJourney &&
    !isNoticeClose &&
    profileCompletionPercentage &&
    profileCompletionPercentage < profileRequirementPopup
  )
    return (
      <div id="restart-journey-notice-component">
        <div className="restrat-journey-notice-padding-container">
          <div className="notice-container" onClick={onOpenRestartDialog}>
            <Trans
              i18nKey={"registration.common.restart_notice"}
              components={{ span: <span className="here-label" /> }}
            />
          </div>

          <div className="close-button-container">
            <CloseRoundedIcon
              className="close-button"
              onClick={onCloseNotice}
            />
          </div>
        </div>
      </div>
    );
};

export default RestartJourneyNotice;
