// General
import "./height-range.scss";
import { useEffect } from "react";
// Services
import { useLazyGetProfileQuery } from "../../../../../services/data.service";
// Static Data
import searchConst from "../../../../../const/searchConst";
import userConst from "../../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateMinHeight,
  updateMaxHeight,
} from "../../../../../redux/store/searchFilterStore";
// Material UI
import { Slider } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Components
import PremiumLock from "../../../elements/premium-lock/premium-lock";

// Removed due to business logic
const HeightRange = (props) => {
  const { type = searchConst.searchFilterType.dialog } = props;

  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // Redux variables
  const minHeight = useSelector((state) => state.searchFilter.minHeight);
  const maxHeight = useSelector((state) => state.searchFilter.maxHeight);
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  // Event Handlers | MUI Slider
  const onHeightRangeChange = (event, newValue) => {
    dispatch(updateMinHeight(newValue[0]));
    dispatch(updateMaxHeight(newValue[1]));
  };

  // Utility Functions
  const shouldShowPremiumLock = () => {
    if (
      isDaddy &&
      getProfileData?.data?.membership_type === userConst.membershipType.free
    ) {
      return true;
    } else {
      return false;
    }
  };

  if (false) {
    return (
      <div id="search-filter-height-range-subcomponent">
        <div
          className={`filter-padding-container ${
            type === searchConst.searchFilterType.full ? "no-gap" : ""
          }`}
        >
          <div className="subheader-container">
            <div className="subheader">{t("filter.height_range")}</div>

            {shouldShowPremiumLock() && <PremiumLock />}
          </div>

          <div
            className={`slider-container ${
              shouldShowPremiumLock() ? "locked" : ""
            }`}
          >
            <div className="min-label">{minHeight}</div>
            <Slider
              className={`slider ${
                shouldShowPremiumLock() ? "" : "custom-slider"
              }`}
              size="small"
              value={[minHeight, maxHeight]}
              onChange={onHeightRangeChange}
              disabled={shouldShowPremiumLock()}
              min={searchConst.filters.heightRange.min}
              max={searchConst.filters.heightRange.max}
            />
            <div className="max-label">{maxHeight}</div>
          </div>
        </div>
      </div>
    );
  }
};

export default HeightRange;
