// General
import "./pk-select-contestant-overlay.scss";
import { useState, useEffect } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateCoAnchorSelectedUser } from "../../../../redux/store/livestreamingStore";
// Material UI
import { useMediaQuery } from "@mui/material";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../../../shared/elements/custom-avatar/custom-avatar";

const PkSelectContestantOverlay = () => {
  // General variables
  const [showSelectedInfoModal, setShowSelectedInfoModal] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  // Redux variables
  const isCoAnchor = useSelector((state) => state.livestreaming.isCoAnchor);
  const coAnchorUser1 = useSelector(
    (state) => state.livestreaming.coAnchorUser1
  );
  const coAnchorUser2 = useSelector(
    (state) => state.livestreaming.coAnchorUser2
  );
  const coAnchorSelectedUser = useSelector(
    (state) => state.livestreaming.coAnchorSelectedUser
  );
  const isDualPk = useSelector((state) => state.livestreaming.isDualPk);
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 900px)");

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Check for update | coAnchorSelectedUser
  useEffect(() => {
    if (!coAnchorSelectedUser) return;

    if (!timeoutId) {
      setShowSelectedInfoModal(true);

      setTimeoutId(
        setTimeout(() => {
          setShowSelectedInfoModal(false);
          clearTimeout(timeoutId);
          setTimeoutId(null);
        }, 3000)
      );
    }
  }, [coAnchorSelectedUser]);

  // Event Handlers | Button
  const onSelectContestant = (userId) => {
    dispatch(updateCoAnchorSelectedUser(userId));
  };

  // Utility Functions
  const getSelectedContestantUsername = () => {
    if (coAnchorSelectedUser === coAnchorUser1?.userId) {
      return coAnchorUser1?.username;
    } else if (coAnchorSelectedUser === coAnchorUser2?.userId) {
      return coAnchorUser2?.username;
    }
  };

  if (isCoAnchor && coAnchorUser1 && coAnchorUser2) {
    return (
      <div id="pk-select-contestant-overlay-subcomponent">
        <div
          className={`${
            isMobile
              ? "mobile-pk-left-contestant-container"
              : "desktop-pk-left-contestant-container"
          } ${
            coAnchorSelectedUser === coAnchorUser1?.userId
              ? "pk-contestant-selected"
              : ""
          } ${isDualPk ? "mobile-dual-pk-reduced-height" : ""}`}
          onClick={() => onSelectContestant(coAnchorUser1?.userId)}
        >
          <div
            className={`dual-pk-contestant-1-container ${
              isMobile ? "" : "desktop-dual-pk-contestant-1-container"
            } ${isDualPk & isMobile ? "dual-pk-contestant-push-up" : ""}`}
          >
            {isDualPk && (
              <div className="dual-pk-contestant-win-badge">
                {Array.from(
                  { length: coAnchorUser1?.dualPk?.win || 0 },
                  (_, index) => (
                    <div className="medal-logo-container" key={index}>
                      {getIcon("pkMedalIcon", "pk-medal-icon")}
                    </div>
                  )
                )}
              </div>
            )}
            <div className="dual-pk-contestant-profile-photo-container">
              <CustomAvatar
                className={`dual-pk-contestant-profile-photo ${
                  isMobile ? "" : "desktop-pk-contestant-profile-photo"
                }`}
                src={coAnchorUser1?.profilePhoto}
              />
            </div>
            <div
              className={`dual-pk-contestant-username ${
                isMobile ? "" : "desktop-dual-pk-contestant-username"
              }`}
            >
              {coAnchorUser1?.username}
            </div>
          </div>
        </div>

        <div
          className={`${
            isMobile
              ? "mobile-pk-right-contestant-container"
              : "desktop-pk-right-contestant-container"
          } ${
            coAnchorSelectedUser === coAnchorUser2?.userId
              ? "pk-contestant-selected"
              : ""
          } ${isDualPk ? "mobile-dual-pk-reduced-height" : ""}`}
          onClick={() => onSelectContestant(coAnchorUser2?.userId)}
        >
          <div
            className={`dual-pk-contestant-2-container ${
              isMobile ? "" : "desktop-dual-pk-contestant-2-container"
            } ${isDualPk & isMobile ? "dual-pk-contestant-push-up" : ""}`}
          >
            <div
              className={`dual-pk-contestant-username dual-pk-contestant-2-username ${
                isMobile ? "" : "desktop-dual-pk-contestant-username"
              }`}
            >
              {coAnchorUser2?.username}
            </div>
            <div className="dual-pk-contestant-profile-photo-container">
              <CustomAvatar
                className={`dual-pk-contestant-profile-photo ${
                  isMobile ? "" : "desktop-pk-contestant-profile-photo"
                }`}
                src={coAnchorUser2?.profilePhoto}
              />
            </div>
            {isDualPk && (
              <div className="dual-pk-contestant-win-badge">
                {Array.from(
                  { length: coAnchorUser2?.dualPk?.win || 0 },
                  (_, index) => (
                    <div className="medal-logo-container" key={index}>
                      {getIcon("pkMedalIcon", "pk-medal-icon")}
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        </div>

        {showSelectedInfoModal && (
          <div className="selected-info-modal">
            <div className="selected-info-container">
              <div className="selected-info-label">Tap to change sides</div>
            </div>
          </div>
        )}
      </div>
    );
  }
};

export default PkSelectContestantOverlay;
