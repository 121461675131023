// General
import "./debug-info.scss";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateShowDebugInfo } from "../../../redux/store/debugStore";
// react-draggable
import Draggable from "react-draggable";
// hls.js
import Hls from "hls.js";

const DebugInfo = () => {
  // Redux variables
  const showDebugInfo = useSelector((state) => state.debug.showDebugInfo);
  const globalPusherState = useSelector(
    (state) => state.debug.globalPusherState
  );
  const liveViewPusherState = useSelector(
    (state) => state.debug.liveViewPusherState
  );
  const videoCallPusherState = useSelector(
    (state) => state.debug.videoCallPusherState
  );
  const messagingPusherState = useSelector(
    (state) => state.debug.messagingPusherState
  );
  const globalChannelState = useSelector(
    (state) => state.debug.globalChannelState
  );
  const liveViewChannelState = useSelector(
    (state) => state.debug.liveViewChannelState
  );
  const liveViewPublicChannelState = useSelector(
    (state) => state.debug.liveViewPublicChannelState
  );
  const liveViewPublicChannelGlobalState = useSelector(
    (state) => state.debug.liveViewPublicChannelGlobalState
  );
  const videoCallChannelState = useSelector(
    (state) => state.debug.videoCallChannelState
  );
  const messagingChannelState = useSelector(
    (state) => state.debug.messagingChannelState
  );
  const dispatch = useDispatch();

  // Event Handlers | Button
  const onCloseDebugInfo = () => {
    dispatch(updateShowDebugInfo(false));
  };

  // Utility CSS
  const pusherStateColor = (state) => {
    switch (state) {
      case "initialized":
        break;
      case "connecting":
        break;
      case "connected":
        return "state-connected";
        break;
      case "disconnected":
        return "state-disconnected";
        break;
      case "unavailable":
        break;
      case "failed":
        break;
      case "disconnected":
        break;
      default:
        break;
    }
  };

  if (showDebugInfo) {
    return (
      <Draggable>
        <div id="debug-info-subcomponent" onClick={onCloseDebugInfo}>
          <div className="pusher-container">
            <div className="pusher-label">PUSHER</div>

            <div className="global-pusher-container">
              <div className="global-pusher">
                Global Pusher State:{" "}
                <span
                  className={`pusher-state ${pusherStateColor(
                    globalPusherState
                  )}`}
                >
                  {globalPusherState || "Uninitiated"}
                </span>
              </div>

              <div className="global-channel">
                Global Channel State:{" "}
                <span
                  className={`pusher-state ${pusherStateColor(
                    globalChannelState
                  )}`}
                >
                  {globalChannelState ? "✅" : "❌"}
                </span>
              </div>
            </div>

            <div className="live-pusher-container">
              <div className="live-pusher">
                Live Pusher State:{" "}
                <span
                  className={`pusher-state ${pusherStateColor(
                    liveViewPusherState
                  )}`}
                >
                  {liveViewPusherState || "Uninitiated"}
                </span>
              </div>

              <div className="live-channel">
                Live Channel State:
                <span
                  className={`pusher-state ${pusherStateColor(
                    liveViewChannelState
                  )}`}
                >
                  {liveViewChannelState ? "✅" : "❌"}
                </span>
              </div>

              <div className="live-channel">
                Live Public Channel State:
                <span
                  className={`pusher-state ${pusherStateColor(
                    liveViewPublicChannelState
                  )}`}
                >
                  {liveViewPublicChannelState ? "✅" : "❌"}
                </span>
              </div>

              <div className="live-channel">
                Live Public Channel Global State:
                <span
                  className={`pusher-state ${pusherStateColor(
                    liveViewPublicChannelGlobalState
                  )}`}
                >
                  {liveViewPublicChannelGlobalState ? "✅" : "❌"}
                </span>
              </div>
            </div>

            <div className="video-pusher-container">
              <div className="video-pusher">
                Video Pusher State:{" "}
                <span
                  className={`pusher-state ${pusherStateColor(
                    videoCallPusherState
                  )}`}
                >
                  {videoCallPusherState || "Uninitiated"}
                </span>
              </div>

              <div className="video-channel">
                Video Call Channel State:
                <span
                  className={`pusher-state ${pusherStateColor(
                    videoCallChannelState
                  )}`}
                >
                  {videoCallChannelState ? "✅" : "❌"}
                </span>
              </div>
            </div>

            <div className="messaging-pusher-container">
              <div className="messaging-pusher">
                Messaging Pusher State:{" "}
                <span
                  className={`pusher-state ${pusherStateColor(
                    messagingPusherState
                  )}`}
                >
                  {messagingPusherState || "Uninitiated"}
                </span>
              </div>

              <div className="messaging-channel">
                Messaging Channel State:
                <span
                  className={`pusher-state ${pusherStateColor(
                    messagingChannelState
                  )}`}
                >
                  {messagingChannelState ? "✅" : "❌"}
                </span>
              </div>
            </div>
          </div>

          <div className="livestream-container">
            <div className="live-label">LIVESTREAM</div>

            <div className="hls">
              Support HLS:
              <span className="hls-state">
                {Hls.isSupported() ? "✅" : "❌"}
              </span>
            </div>
          </div>
        </div>
      </Draggable>
    );
  }
};

export default DebugInfo;
