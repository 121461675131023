// General
import "./profile-verification.scss";

const ProfileVerification = () => {
  return (
    <div id="profile-verification-page">
      <div>Profile Verification</div>
    </div>
  );
};

export default ProfileVerification;
