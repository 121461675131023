// General
import "./drinking.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetJourneyQuestionsQuery,
  useUpdateJourneyMutation,
} from "../../../../services/data.service";
// Static Data
import utilityConst from "../../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateDrinking,
  updateJourneyNavigationPassthrough,
  updateJourneyDataPassthrough,
  updateRegistrationNavigationData,
} from "../../../../redux/store/registrationStore";
import { updateErrorToast } from "../../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { useMediaQuery } from "@mui/material";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Components
import Spinner from "../../../shared/elements/spinner/spinner";

const DrinkingDetails = () => {
  // API variables
  const [
    getJourneyQuestions,
    {
      data: getJourneyQuestionsData,
      error: getJourneyQuestionsErrorData,
      isFetching: getJourneyQuestionsFetching,
      isLoading: getJourneyQuestionsLoading,
      isSuccess: getJourneyQuestionsSuccess,
      isError: getJourneyQuestionsError,
    },
  ] = useLazyGetJourneyQuestionsQuery();
  const [
    updateJourney,
    {
      data: updateJourneyData,
      error: updateJourneyErrorData,
      isLoading: updateJourneyLoading,
      isSuccess: updateJourneySuccess,
      isError: updateJourneyError,
    },
  ] = useUpdateJourneyMutation();
  const [
    skipJourney,
    {
      data: skipJourneyData,
      error: skipJourneyErrorData,
      isLoading: skipJourneyLoading,
      isSuccess: skipJourneySuccess,
      isError: skipJourneyError,
    },
  ] = useUpdateJourneyMutation();

  // Redux variables
  const drinking = useSelector((state) => state.registration.drinking);
  const skippableJourney = useSelector(
    (state) => state.registration.skippableJourney
  );
  const registrationNavigationData = useSelector(
    (state) => state.registration.registrationNavigationData
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    getJourneyQuestions(null, true);
  }, []);

  // Lifecycle | Check for update | updateJourney API Response
  useEffect(() => {
    if (updateJourneyLoading) {
    } else if (updateJourneySuccess) {
      if (
        updateJourneyData?.user_journey?.journey_data?.profile?.drinking === 540
      ) {
        let tempRegistrationNavigationData = JSON.parse(
          JSON.stringify(registrationNavigationData)
        );
        tempRegistrationNavigationData[11].isVisible = false;
        tempRegistrationNavigationData[11].isClickable = false;

        dispatch(
          updateRegistrationNavigationData(tempRegistrationNavigationData)
        );
      } else {
        let tempRegistrationNavigationData = JSON.parse(
          JSON.stringify(registrationNavigationData)
        );
        tempRegistrationNavigationData[11].isVisible = true;
        tempRegistrationNavigationData[11].isClickable = true;

        dispatch(
          updateRegistrationNavigationData(tempRegistrationNavigationData)
        );
      }

      dispatch(updateJourneyDataPassthrough(updateJourneyData));
      dispatch(updateJourneyNavigationPassthrough({}));
    } else if (updateJourneyError) {
      const warningToast = {
        message: updateJourneyErrorData?.data?.message,
        autoClose: 3000,
      };
      dispatch(updateErrorToast(warningToast));
    }
  }, [updateJourneyLoading, updateJourneySuccess, updateJourneyError]);

  // Lifecycle | Check for update | skipJourney API Response
  useEffect(() => {
    if (skipJourneyLoading) {
    } else if (skipJourneySuccess) {
      const updatedItems = registrationNavigationData?.map((item, i) => ({
        ...item,
        skipped: i === 10 ? true : i > 10 ? false : item.skipped,
      }));
      dispatch(updateRegistrationNavigationData(updatedItems));

      dispatch(updateJourneyDataPassthrough(updateJourneyData));
      dispatch(updateJourneyNavigationPassthrough({}));
    } else if (skipJourneyError) {
    }
  }, [skipJourneyLoading, skipJourneySuccess, skipJourneyError]);

  // Event Handlers | Button
  const onSelectDrinking = (drinking) => {
    dispatch(updateDrinking(drinking));
  };
  const onNextPage = () => {
    if (!isFormValid()) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-62-62.11-62.11.1",
      },
    });

    const obj = {
      journey_step: drinking?.id !== 540 ? "11" : "12",
      journey_data: {
        profile: {
          drinking: drinking?.id,
        },
      },
    };
    updateJourney(obj);
  };
  const onSkip = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-62-62.11-62.11.2",
      },
    });

    // Skip Alcohol Preference
    const obj = {
      journey_step: "12",
      journey_data: {
        looking_for: {},
      },
    };
    skipJourney(obj);
  };

  // Utility Functions
  const isFormValid = () => {
    return drinking && !updateJourneyLoading;
  };
  const getDrinkingData = () => {
    return getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "drinking"
    )?.selections;
  };

  return (
    <div id="details-signup-drinking-details-subcomponent">
      <div className="padding-container">
        <div className="top-container">
          <div className="drinking-details-label">
            {t("registration.drinking.title")}
          </div>

          {getJourneyQuestionsFetching || getJourneyQuestionsLoading ? (
            <Spinner />
          ) : (
            <div
              className={`drinking-body-container ${
                getDrinkingData()?.length >
                utilityConst.registrationItemFlexAmount
                  ? "flex-start"
                  : "justify-center"
              }`}
            >
              {getDrinkingData()?.map((item, index) => (
                <div
                  className={`drinking-item ${
                    getDrinkingData()?.length <=
                    utilityConst.registrationItemFlexAmount
                      ? "partial-width"
                      : getDrinkingData()?.length >
                        utilityConst.registrationItemFlexAmount
                      ? "fit-content"
                      : "fix-width"
                  } ${item?.id === drinking?.id ? "selected" : ""}`}
                  onClick={() => onSelectDrinking(item)}
                  key={index}
                >
                  {item?.display_locale}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="bottom-container">
        <div
          className={`continue-button ${
            isMobile ? "full-width" : "fit-content"
          } ${isFormValid() ? "" : "disabled-button"}`}
          onClick={onNextPage}
        >
          {updateJourneyLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("common.continue")
          )}
        </div>

        <div className="skip-button" onClick={onSkip}>
          {skipJourneyLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("common.skip")
          )}
        </div>
      </div>
    </div>
  );
};

export default DrinkingDetails;
