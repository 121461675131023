const leaderboardConst = {
  tabs: {
    events: {
      events: "events",
      tier1: "tier1",
      tier2: "tier2",
      tier3: "tier3",
      pk: "pk",
    },
    daily: "daily",
    weekly: "weekly",
    monthly: "monthly",
  },
  topFans: {
    daily: "daily",
    weekly: "weekly",
    monthly: "monthly",
    overall: "overall",
  },
  type: {
    livestreaming: "livestreaming",
    privateCall: "private-call",
    casino: "casino",
  },
  boardPeriodType: {
    daily: "daily",
    weekly: "weekly",
    monthly: "monthly",
  },
  userType: {
    streamer: "streamer",
    gifter: "gifter",
    player: "player",
    wager: "wager",
  },
};

export default leaderboardConst;
