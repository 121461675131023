// General
import "./tipping-coin-element.scss";
// Material UI
import { useMediaQuery } from "@mui/material";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../../custom-avatar/custom-avatar";

const TippingCoinElement = (props) => {
  const {
    state,
    isDaddy,
    livestreamingAchievementBadge,
    profilePhoto,
    profileDecorations,
    username,
    levellingBadge,
    diamondsValue,

    // Style
    borderColor = "none",
    backgroundColor = "none",
  } = props;

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 900px)");

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  return (
    <div
      id="chat-section-tipping-coin-element-subcomponent"
      className={isDaddy ? "" : "apply-background"}
    >
      {isDaddy && (
        <div
          className="sender-container"
          style={{
            background: backgroundColor,
            border:
              borderColor !== "none" ? `1px solid ${borderColor}` : "none",
          }}
        >
          <div className="profile-picture-container">
            <CustomAvatar
              className="profile-picture"
              src={profilePhoto}
              profileBorder={profileDecorations?.profile_border_url}
            />
          </div>

          <div className="chat-info-container">
            <div className="user-info-container">
              {livestreamingAchievementBadge?.length > 0 && (
                <div className="livestreaming-achievement-badge-container">
                  {livestreamingAchievementBadge?.map((badge, index) => (
                    <img
                      className="livestreaming-achievement-badge"
                      src={badge?.url}
                      alt={badge?.type}
                      key={index}
                    />
                  ))}
                </div>
              )}

              <div
                className={`username ${
                  state === "livestream" && !isMobile ? "" : "yellow"
                }`}
              >
                {username || "-"}
              </div>

              {levellingBadge && (
                <div className="levelling-badge-container">
                  <img
                    className="levelling-badge"
                    src={levellingBadge}
                    alt="levelling-badge"
                  />
                </div>
              )}

              <div className="sent-a">{t("pusher.sent_a")}</div>
            </div>

            <div className="coin-icon-container">
              {getIcon("sbCoinIcon", "coin-icon")}
            </div>
          </div>
        </div>
      )}

      {!isDaddy && (
        <div className="receiver-container">
          <div className="coin-top">
            <Trans
              i18nKey={"1on1.youve_earned_diamond_from"}
              values={{
                diamond: `+${diamondsValue}`,
                source: "",
              }}
              components={{
                diamondicon: getIcon("sbDiamondIcon", "diamond-icon"),
                coinicon: getIcon("sbCoinIcon", "coin-icon"),
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TippingCoinElement;
