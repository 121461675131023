// General
import "./update-registration-about-me-dialog.scss";
import { useEffect, forwardRef } from "react";
// Static Data
import editProfileConst from "../../../../const/editProfileConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateUpdateProfileFullscreenTab,
  updateProfileCompletionState,
  updateIsEditAboutMeNext,
} from "../../../../redux/store/editProfileStore";
import { updateUpdateRegistrationAboutMeDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide, useMediaQuery } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// i18next
import { useTranslation, Trans } from "react-i18next";
import EditAboutMe from "../../profile/edit/edit-about-me/edit-about-me";
import EditProfileUnsavedChangesDialog from "../edit-profile-unsaved-changes-dialog/edit-profile-unsaved-changes-dialog";

const UpdateRegistrationAboutMeDialog = () => {
  // Redux variables
  const updateRegistrationAboutMeDialog = useSelector(
    (state) => state.dialog.updateRegistrationAboutMeDialog
  );
  const isEditAboutMeNext = useSelector(
    (state) => state.editProfile.isEditAboutMeNext
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    if (updateRegistrationAboutMeDialog) {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-D28",
        },
      });
    } else {
      // dispatch(updateProfileCompletionState(null));
    }
  }, [updateRegistrationAboutMeDialog]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    if (isEditAboutMeNext) {
      dispatch(updateIsEditAboutMeNext(false));
    } else {
      dispatch(updateUpdateRegistrationAboutMeDialog(false));
    }
  };

  return (
    <Dialog
      className={
        isMobile
          ? "custom-bottom-sheet-no-radius-fullscreen-dialog"
          : "custom-radius10-dialog custom-dialog-height-inherit"
      }
      fullScreen={isMobile ? true : false}
      // scroll={"body"}
      open={updateRegistrationAboutMeDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      <div
        id="update-registration-about-me-dialog"
        className={isMobile ? "" : "apply-profile-fullscreen-min-width"}
      >
        <div className="dialog-header">
          <div className="left-container" onClick={onCloseDialog}>
            <ChevronLeftIcon className="back-icon" />
          </div>
          <div className="middle-container">{t("profile.about_me")}</div>
          <div className="right-container"></div>
        </div>

        <div className="select-view">
          <EditAboutMe state={"registration"} />
        </div>
      </div>

      <EditProfileUnsavedChangesDialog />
    </Dialog>
  );
};

export default UpdateRegistrationAboutMeDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
