// General
import "./edit-looking-for-location.scss";
import { useState, useEffect, useRef } from "react";
// Services
import { useUpdateEditUserProfileMutation } from "../../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateLookingForLocation,
  updateUpdateProfileFullscreenTab,
  updateIsUnsavedChanges,
} from "../../../../../redux/store/editProfileStore";
import {
  updateProfileCompletionPercentage,
  updateProfileRequirementPopup,
} from "../../../../../redux/store/profileStore";
import { updateUpdateProfileFullscreenDialog } from "../../../../../redux/store/dialogStore";
// Material UI
import { TextField, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ClearIcon from "@mui/icons-material/Clear";
// i18next
import { useTranslation } from "react-i18next";
// React Google Maps/api
import { Autocomplete } from "@react-google-maps/api";
// Custom Hooks
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import Spinner from "../../../elements/spinner/spinner";

const EditLookingForLocation = (props) => {
  const { addPaddingTop = false } = props;

  // API variables
  const [
    updateProfile,
    {
      data: updateProfileData,
      error: updateProfileErrorData,
      isLoading: updateProfileLoading,
      isSuccess: updateProfileSuccess,
      isError: updateProfileError,
    },
  ] = useUpdateEditUserProfileMutation();

  // General variables
  const [localLocation, setLocalLocation] = useState([]);
  const [searchLocation, setSearchLocation] = useState("");
  const [searchResult, setSearchResult] = useState();
  const [overlayLocalLocation, setOverlayLocalLocation] = useState("")
  const [locationLoading, setLocationLoading] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [subTimeoutId, setSubTimeoutId] = useState(null);
  const targetTextFieldRef = useRef(null);
  const overlayTextFieldRef = useRef(null);

  // Redux variables
  const lookingForLocation = useSelector(
    (state) => state.editProfile.lookingForLocation
  );
  const googleMapsLoaded = useSelector(
    (state) => state.noReset.googleMapsLoaded
  );
  const profileCompletionState = useSelector(
    (state) => state.editProfile.profileCompletionState
  );
  const updateProfileFullscreenTab = useSelector(
    (state) => state.editProfile.updateProfileFullscreenTab
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    setLocalLocation(lookingForLocation);
  }, []);

  // Lifecycle | Check for update | updateProfile API Response
  useEffect(() => {
    if (updateProfileLoading) {
    } else if (updateProfileSuccess) {
      dispatch(
        updateLookingForLocation(
          updateProfileData?.data?.user?.looking_fors?.locations
        )
      );
      dispatch(
        updateProfileCompletionPercentage(
          updateProfileData?.data?.user?.profile_progress?.summary?.progress
        )
      );
      dispatch(
        updateProfileRequirementPopup(
          updateProfileData?.data?.user?.profile_progress?.summary?.requirement
        )
      );
      dispatch(updateIsUnsavedChanges(false));

      if (profileCompletionState) {
        dispatch(
          updateUpdateProfileFullscreenTab(updateProfileFullscreenTab + 1)
        );
      } else {
        dispatch(updateUpdateProfileFullscreenDialog(false));
      }
    } else if (updateProfileError) {
    }
  }, [updateProfileLoading, updateProfileSuccess, updateProfileError]);

  // Lifecycle | Check for update | localLocation
  useEffect(() => {
    const arraysAreEqual =
      lookingForLocation?.length === localLocation?.length &&
      lookingForLocation?.every((location) =>
        localLocation?.some(
          (local) =>
            location?.lat === local?.lat && location?.lng === local?.lng
        )
      );

    dispatch(updateIsUnsavedChanges(!arraysAreEqual));
  }, [localLocation]);

  // Event Handlers | Button
  const onSaveLocation = () => {
    if (!isFormValid()) return;

    const obj = {
      looking_for: {
        locations: localLocation,
      },
    };
    updateProfile(obj);
  };
  const onRemoveLocation = (index) => {
    let selectedLocationsTemp = [...localLocation];
    selectedLocationsTemp.splice(index, 1);
    setLocalLocation(selectedLocationsTemp);
  };

  // Event Handlers | MUI Text Fields
  const onOverlayInputChange = (event) => {
    // setLocalLocation(event.target.value);
    setOverlayLocalLocation(event.target.value);
    setLocationLoading(true);

    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }

    setTimeoutId(
      setTimeout(() => {
        // dispatch(updateLocationFormattedAddress(event.target.value));
        // setLocalLocation(event.target.value);
        setSearchLocation(event.target.value);

        if (subTimeoutId) {
          clearTimeout(subTimeoutId);
          setSubTimeoutId(null);
        }

        setTimeout(() => {
          if (targetTextFieldRef.current) {
            targetTextFieldRef.current.focus();
            setLocationLoading(false);
          }
        }, 500);
      }, 1000)
    );
  };
  const onInputChange = (event) => {
    if (overlayTextFieldRef.current) {
      setOverlayLocalLocation(event.target.value);
      overlayTextFieldRef.current.focus();
    }
  };
  const onClearLocation = () => {
    setSearchLocation(null);
  };

  // Event Handlers | Google Maps Autocomplete
  const onPlaceChanged = () => {
    if (searchResult != null && localLocation?.length < returnMaxLocation()) {
      const place = searchResult.getPlace();
      let location_data = {
        formatted_address: place?.formatted_address,
      };

      if (place?.address_components) {
        for (let i = 0; i < place?.address_components?.length; i++) {
          if (place?.address_components[i]?.types?.length > 0) {
            switch (place?.address_components[i]?.types[0]) {
              case "locality":
                location_data.city = place?.address_components[i]?.long_name;
                break;
              case "neighborhood":
                if (location_data.city !== null) {
                  location_data.neighborhood =
                    place?.address_components[i]?.long_name;
                }
                break;
              case "administrative_area_level_1":
                location_data.state = place?.address_components[i]?.short_name;
                break;
              case "country":
                location_data.country = place?.address_components[i]?.long_name;
                location_data.country_iso =
                  place?.address_components[i]?.short_name;
                break;
              default:
                break;
            }
          }
        }
      }

      const locationObj = {
        lat: place?.geometry?.location?.lat(),
        lng: place?.geometry?.location?.lng(),
        location_data,
      };

      let selectedLocationsTemp = [...localLocation];
      selectedLocationsTemp.push(locationObj);
      setLocalLocation(selectedLocationsTemp);
      setSearchLocation("");
      setOverlayLocalLocation("");
    }
  };
  const onLoad = (autocomplete) => {
    setSearchResult(autocomplete);
  };

  // Utility Functions
  const isFormValid = () => {
    return localLocation?.length > 0 && !updateProfileLoading;
  };
  const returnMaxLocation = () => {
    return 3;
  };

  return (
    <div id="looking-for-location-subcomponent">
      <div className="padding-container">
        <div className="top-container">
          <div
            className={`looking-for-location-title ${
              addPaddingTop ? "add-edit-padding-top" : ""
            }`}
          >
            {t("profile.select_locations")}
          </div>

          <div className="looking-for-location-description">
            {t("profile.you_may_select_up_to_locations", {
              n: localLocation?.length,
              max: returnMaxLocation(),
            })}
          </div>

          {googleMapsLoaded ? (
            <div className="location-body-container">
              <div className="location-form-field-container">
                <Autocomplete
                  className="autocomplete-wrapper"
                  onPlaceChanged={onPlaceChanged}
                  onLoad={onLoad}
                  options={{ types: ["geocode"] }}
                >
                  <TextField
                    sx={{ "& .MuiSelect-select": { marginTop: "6px" } }}
                    className="form-field"
                    inputRef={targetTextFieldRef}
                    onChange={onInputChange}
                    value={searchLocation}
                    variant="standard"
                    InputProps={{
                      // disableUnderline: true,
                      endAdornment: (
                        <IconButton onClick={onClearLocation}>
                          <ClearIcon />
                        </IconButton>
                      ),
                    }}
                  ></TextField>
                </Autocomplete>

                <div className="overlay-form-field-container">
                  <TextField
                    sx={{ "& .MuiSelect-select": { marginTop: "6px" } }}
                    className="overlay-form-field"
                    inputRef={overlayTextFieldRef}
                    onChange={onOverlayInputChange}
                    value={overlayLocalLocation}
                    variant="standard"
                    placeholder={t("profile.enter_a_location")}
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: locationLoading ? (
                        <Spinner isPadding={false} size={17} />
                      ) : (
                        <IconButton onClick={onClearLocation}>
                          <ClearIcon />
                        </IconButton>
                      ),
                    }}
                  ></TextField>
                </div>
              </div>

              <div className="selected-locations-container">
                {localLocation?.map((item, index) => (
                  <div className="selected-location-container" key={index}>
                    {item?.location_data?.formatted_address ||
                      item?.location_data?.city ||
                      item?.location_data?.state ||
                      item?.location_data?.country}
                    <CloseIcon
                      className="remove-selected-location-icon"
                      onClick={() => onRemoveLocation(index)}
                    />
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <Spinner />
          )}
        </div>
      </div>

      <div className="bottom-container">
        <div
          className={`save-button ${isFormValid() ? "" : "disabled-button"}`}
          onClick={onSaveLocation}
        >
          {updateProfileLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("common.save")
          )}
        </div>
      </div>
    </div>
  );
};

export default EditLookingForLocation;
