const verificationConst = {
  emailOtpMaxLength: 6,
  mobileOtpMaxLength: 4,
  mobileOtpResendMaxCount: 2,
  manualVerificationType: {
    mobile: "mobile",
    facial: "facial",
  },
  mobileVerificationMethod: {
    sms: "sms",
    call: "call",
  },
};

export default verificationConst;
