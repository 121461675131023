// General
import "./navbar.scss";
// Components
import NavbarMobileView from "./navbar-mobile-view/navbar-mobile-view";
import NavbarDesktopView from "./navbar-desktop-view/navbar-desktop-view";

const NavBar = () => {
  return (
    <div id="navbar">
      <NavbarDesktopView />
      <NavbarMobileView />
    </div>
  );
};

export default NavBar;
