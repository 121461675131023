// General
import "./gift-request-element.scss";
// i18next
import { useTranslation } from "react-i18next";

// TODO: Get the text from pusher
const GiftRequestElement = (props) => {
  const { giftImage } = props;

  // i18next variables
  const { t } = useTranslation();

  return (
    <div id="chat-section-gift-request-element-subcomponent">
      {t("1on1.gift_request_1")}{" "}
      <div className="gift-icon-container">
        <img className="gift-icon" src={giftImage} alt="gift" />
      </div>
    </div>
  );
};

export default GiftRequestElement;
