// General
import "./occupation.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetJourneyQuestionsQuery,
  useUpdateJourneyMutation,
} from "../../../../services/data.service";
// Static Data
import user from "../../../../enums/user";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updatePosition,
  updateIndustry,
  updateJourneyNavigationPassthrough,
  updateJourneyDataPassthrough,
  updateRegistrationNavigationData,
} from "../../../../redux/store/registrationStore";
import { updateErrorToast } from "../../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { MenuItem, TextField, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Components
import Spinner from "../../../shared/elements/spinner/spinner";

const OccupationDetails = () => {
  // API variables
  const [
    getJourneyQuestions,
    {
      data: getJourneyQuestionsData,
      error: getJourneyQuestionsErrorData,
      isFetching: getJourneyQuestionsFetching,
      isLoading: getJourneyQuestionsLoading,
      isSuccess: getJourneyQuestionsSuccess,
      isError: getJourneyQuestionsError,
    },
  ] = useLazyGetJourneyQuestionsQuery();
  const [
    updateJourney,
    {
      data: updateJourneyData,
      error: updateJourneyErrorData,
      isLoading: updateJourneyLoading,
      isSuccess: updateJourneySuccess,
      isError: updateJourneyError,
    },
  ] = useUpdateJourneyMutation();
  const [
    skipJourney,
    {
      data: skipJourneyData,
      error: skipJourneyErrorData,
      isLoading: skipJourneyLoading,
      isSuccess: skipJourneySuccess,
      isError: skipJourneyError,
    },
  ] = useUpdateJourneyMutation();

  // Redux variables
  const position = useSelector((state) => state.registration.position);
  const industry = useSelector((state) => state.registration.industry);
  const gender = useSelector((state) => state.signup.gender);
  const skippableJourney = useSelector(
    (state) => state.registration.skippableJourney
  );
  const registrationNavigationData = useSelector(
    (state) => state.registration.registrationNavigationData
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    getJourneyQuestions(null, true);
  }, []);

  // Lifecycle | Check for update | updateJourney API Response
  useEffect(() => {
    if (updateJourneyLoading) {
    } else if (updateJourneySuccess) {
      dispatch(updateJourneyDataPassthrough(updateJourneyData));
      dispatch(updateJourneyNavigationPassthrough({}));
    } else if (updateJourneyError) {
      const warningToast = {
        message: updateJourneyErrorData?.data?.message,
        autoClose: 3000,
      };
      dispatch(updateErrorToast(warningToast));
    }
  }, [updateJourneyLoading, updateJourneySuccess, updateJourneyError]);

  // Lifecycle | Check for update | skipJourney API Response
  useEffect(() => {
    if (skipJourneyLoading) {
    } else if (skipJourneySuccess) {
      const updatedItems = registrationNavigationData?.map((item, i) => ({
        ...item,
        skipped: i === 6 ? true : i > 6 ? false : item.skipped,
      }));
      dispatch(updateRegistrationNavigationData(updatedItems));

      dispatch(updateJourneyDataPassthrough(updateJourneyData));
      dispatch(updateJourneyNavigationPassthrough({}));
    } else if (skipJourneyError) {
    }
  }, [skipJourneyLoading, skipJourneySuccess, skipJourneyError]);

  // Event Handlers | Button
  const onRemovePosition = () => {
    dispatch(updatePosition(""));
  };
  const onRemoveIndustry = () => {
    dispatch(updateIndustry(""));
  };
  const onNextPage = () => {
    if (!isFormValid()) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-62-62.7-62.7.1",
      },
    });

    let obj = {
      journey_step: "7",
      journey_data: {
        profile: {
          position: position?.id,
        },
      },
    };

    if (gender === user.gender.MAN) {
      obj.journey_data.profile.industry = industry?.id;
    }
    updateJourney(obj);
  };
  const onSkip = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-62-62.7-62.7.2",
      },
    });

    const obj = {
      journey_step: "7",
      journey_data: {
        looking_for: {},
      },
    };
    skipJourney(obj);
  };

  // Event Handlers | MUI Select
  const handleSelectChange = (type) => (event) => {
    switch (type) {
      case "position":
        dispatch(updatePosition(event.target.value));
        break;
      case "industry":
        dispatch(updateIndustry(event.target.value));
        break;
      default:
        break;
    }
  };

  // Utility Functions
  const isFormValid = () => {
    if (gender === user.gender.MAN) {
      return position && industry && !updateJourneyLoading;
    } else if (gender === user.gender.WOMAN) {
      return position && !updateJourneyLoading;
    }
    return false;
  };
  const getPositionData = () => {
    return getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "position"
    )?.selections;
  };
  const getIndustryData = () => {
    return getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "industry"
    )?.selections;
  };

  return (
    <div id="details-signup-occupation-details-subcomponent">
      <div className="top-container">
        {gender === user.gender.MAN ? (
          <div className="occupation-details-label">
            <Trans
              i18nKey={"registration.occupation.man_title"}
              components={{ br: <br /> }}
            />
          </div>
        ) : (
          <div className="occupation-details-label">
            <Trans
              i18nKey={"registration.occupation.female_title"}
              components={{ br: <br /> }}
            />
          </div>
        )}

        {gender === user.gender.MAN && (
          <div className="occupation-description-label">
            {t("registration.occupation.description")}
          </div>
        )}

        {getJourneyQuestionsFetching || getJourneyQuestionsLoading ? (
          <Spinner />
        ) : (
          <div className="occupation-body-data-container">
            {gender === user.gender.WOMAN && (
              <div className="occupation-container">
                <div className="occupation-body-container">
                  <div className="occupation-form-field-container">
                    <div className="occupation-select-form-field-container">
                      <TextField
                        className="occupation-select-form-field"
                        select
                        value={position}
                        placeholder={t(
                          "registration.occupation.select_occupation"
                        )}
                        // label={t("registration.occupation.select_occupation")}
                        onChange={handleSelectChange("position")}
                        variant="standard"
                        SelectProps={{
                          displayEmpty: true,
                        }}
                      >
                        <MenuItem disabled value="">
                          {t("registration.occupation.select_occupation")}
                        </MenuItem>
                        {getPositionData()?.map((position, index) => (
                          <MenuItem value={position} key={index}>
                            {position?.display_locale}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </div>

                  <div className="selected-occupation-container">
                    {position && (
                      <div className="selected-occupation">
                        {position?.display_locale}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {gender === user.gender.MAN && (
              <div className="position-container">
                <div className="position-label">
                  {t("registration.occupation.position")}
                </div>

                <div className="position-body-container">
                  <div className="position-form-field-container">
                    <div className="position-select-form-field-container">
                      <TextField
                        className="position-select-form-field"
                        select
                        value={position}
                        placeholder={t(
                          "registration.occupation.select_your_position"
                        )}
                        // label={t(
                        //   "registration.occupation.select_your_position"
                        // )}
                        onChange={handleSelectChange("position")}
                        variant="standard"
                        SelectProps={{
                          displayEmpty: true,
                        }}
                      >
                        <MenuItem disabled value="">
                          {t("registration.occupation.select_your_position")}
                        </MenuItem>
                        {getPositionData()?.map((position, index) => (
                          <MenuItem value={position} key={index}>
                            {position?.display_locale}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </div>

                  <div className="selected-position-container">
                    {position && (
                      <div className="selected-position">
                        {position?.display_locale}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {gender === user.gender.MAN && (
              <div className="industry-container">
                <div className="industry-label">
                  {t("registration.occupation.industry")}
                </div>

                <div className="industry-body-container">
                  <div className="industry-form-field-container">
                    <div className="industry-select-form-field-container">
                      <TextField
                        className="industry-select-form-field"
                        select
                        value={industry}
                        placeholder={t(
                          "registration.occupation.select_your_industry"
                        )}
                        // label={t(
                        //   "registration.occupation.select_your_industry"
                        // )}
                        onChange={handleSelectChange("industry")}
                        variant="standard"
                        SelectProps={{
                          displayEmpty: true,
                        }}
                      >
                        <MenuItem disabled value="">
                          {t("registration.occupation.select_your_industry")}
                        </MenuItem>
                        {getIndustryData()?.map((industry, index) => (
                          <MenuItem value={industry} key={index}>
                            {industry?.display_locale}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </div>

                  <div className="selected-industry-container">
                    {industry && (
                      <div className="selected-industry">
                        {industry?.display_locale}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="bottom-container">
        <div
          className={`continue-button ${
            isMobile ? "full-width" : "fit-content"
          } ${isFormValid() ? "" : "disabled-button"}`}
          onClick={onNextPage}
        >
          {updateJourneyLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("common.continue")
          )}
        </div>

        <div className="skip-button" onClick={onSkip}>
          {skipJourneyLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("common.skip")
          )}
        </div>
      </div>
    </div>
  );
};

export default OccupationDetails;
