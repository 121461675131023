// General
import "./pk-progress-bar.scss";
import { useEffect } from "react";
// Services
import { useLazyLivestreamingCoAnchorPkRoundStatsQuery } from "../../../../../services/data.service";
// Static Data
import livestreamingConst from "../../../../../const/livestreamingConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  // Co Anchor Functions
  updateCoAnchorUser1DualPkDiamonds,
  updateCoAnchorUser2DualPkDiamonds,
  updateCoAnchorUser1DualPkWin,
  updateCoAnchorUser2DualPkWin,

  // Dual PK Functions
  updateIsDualPk,
  updateDualPkCurrentRound,
  setDualPkTimer,
  updateDualPkSyncScoreInterval,
  updateDualPkMasterInterval,
  clearDualPkMasterInterval,
  updateDualPkMasterPassthrough,
  resetDualPkMasterPassthrough,
  updateDualPkStatus,
  updateDualPkEnded,
  updateDualPkShowCountdownTimer,
  updateDualPkSyncScorePassthrough,
} from "../../../../../redux/store/livestreamingStore";
import { updateDualPkEndResultDialog } from "../../../../../redux/store/dialogStore";
// Material UI
import { useMediaQuery } from "@mui/material";
// Moment
import moment from "moment";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import DualPkEndResultDialog from "../../../../shared/dialog-content/dual-pk-end-result-dialog/dual-pk-end-result-dialog";

const PkProgressBar = () => {
  // API variables
  const [
    getLivestreamingCoAnchorPkStats,
    {
      data: getLivestreamingCoAnchorPkStatsData,
      error: getLivestreamingCoAnchorPkStatsErrorData,
      isFetching: getLivestreamingCoAnchorPkStatsFetching,
      isLoading: getLivestreamingCoAnchorPkStatsLoading,
      isSuccess: getLivestreamingCoAnchorPkStatsSuccess,
      isError: getLivestreamingCoAnchorPkStatsError,
    },
  ] = useLazyLivestreamingCoAnchorPkRoundStatsQuery();

  // Redux variables
  const isCoAnchor = useSelector((state) => state.livestreaming.isCoAnchor);
  const coAnchorUser1 = useSelector(
    (state) => state.livestreaming.coAnchorUser1
  );
  const coAnchorUser2 = useSelector(
    (state) => state.livestreaming.coAnchorUser2
  );
  const isDualPk = useSelector((state) => state.livestreaming.isDualPk);
  const dualPkData = useSelector((state) => state.livestreaming.dualPkData);
  const dualPkTimer = useSelector((state) => state.livestreaming.dualPkTimer);
  const dualPkSyncScoreInterval = useSelector(
    (state) => state.livestreaming.dualPkSyncScoreInterval
  );

  const livestreamGiftAnimation = useSelector(
    (state) => state.livestreaming.livestreamGiftAnimation
  );
  const dualPkMasterInterval = useSelector(
    (state) => state.livestreaming.dualPkMasterInterval
  );
  const dualPkMasterPassthrough = useSelector(
    (state) => state.livestreaming.dualPkMasterPassthrough
  );
  const dualPkStatus = useSelector((state) => state.livestreaming.dualPkStatus);
  const dualPkShowCountdownTimer = useSelector(
    (state) => state.livestreaming.dualPkShowCountdownTimer
  );
  const dualPkSyncScorePassthrough = useSelector(
    (state) => state.livestreaming.dualPkSyncScorePassthrough
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 900px)");

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Unmount
  useEffect(() => {
    return () => {
      dispatch(updateDualPkEnded(false));

      if (dualPkMasterInterval) {
        dispatch(clearDualPkMasterInterval());
      }
    };
  }, []);

  // Lifecycle | Check for update | Livestreaming Co Anchor PK Stats API Response
  useEffect(() => {
    if (
      getLivestreamingCoAnchorPkStatsFetching ||
      getLivestreamingCoAnchorPkStatsLoading
    ) {
    } else if (getLivestreamingCoAnchorPkStatsSuccess) {
      switch (getLivestreamingCoAnchorPkStatsData?.status) {
        case 0:
          break;
        case 1:
          dispatch(
            updateCoAnchorUser1DualPkDiamonds(
              getLivestreamingCoAnchorPkStatsData?.data[0]?.diamonds
            )
          );
          dispatch(
            updateCoAnchorUser2DualPkDiamonds(
              getLivestreamingCoAnchorPkStatsData?.data[1]?.diamonds
            )
          );
          break;
        default:
          break;
      }
    } else if (getLivestreamingCoAnchorPkStatsError) {
    }
  }, [
    getLivestreamingCoAnchorPkStatsFetching,
    getLivestreamingCoAnchorPkStatsLoading,
    getLivestreamingCoAnchorPkStatsSuccess,
    getLivestreamingCoAnchorPkStatsError,
  ]);

  // Lifecycle | Check for update | dualPkTimer & dualPkData
  useEffect(() => {
    if (dualPkTimer > 0 && !dualPkSyncScoreInterval && dualPkData) {
      // Call round stats every 10 seconds
      dispatch(
        updateDualPkSyncScoreInterval(
          setInterval(() => {
            dispatch(updateDualPkSyncScorePassthrough());
          }, 10000)
        )
      );
    }
  }, [dualPkTimer, dualPkData]);

  // Lifecycle | Check for update | dualPkSyncScorePassthrough
  useEffect(() => {
    if (
      !dualPkSyncScorePassthrough ||
      !dualPkData?.id ||
      !dualPkData?.currentRound
    )
      return;

    const str = `?co_anchor_pk_id=${dualPkData?.id}&round=${dualPkData?.currentRound}`;
    getLivestreamingCoAnchorPkStats(str, false);
  }, [dualPkSyncScorePassthrough]);

  // Lifecycle | Check for update | livestreamGiftAnimation
  useEffect(() => {
    if (isCoAnchor && dualPkTimer > 0) {
      if (livestreamGiftAnimation?.type === "gifting") {
        const str = `?co_anchor_pk_id=${dualPkData?.id}&round=${dualPkData?.currentRound}`;
        getLivestreamingCoAnchorPkStats(str, false);
      }
    }
  }, [livestreamGiftAnimation]);

  // Lifecycle | Check for update | coAnchorUser1 diamonds
  useEffect(() => {
    if (coAnchorUser1?.dualPk?.diamonds > 0) {
      const element = document.getElementsByClassName("pk-left-score")[0];

      if (element) {
        element.classList.add("scale-animation");

        // Remove the animation class after it completes to reset the animation
        setTimeout(() => {
          element.classList.remove("scale-animation");
        }, 500); // Assuming the animation duration is 1 second
      }
    }
  }, [coAnchorUser1?.dualPk?.diamonds]);

  // Lifecycle | Check for update | coAnchorUser2 diamonds
  useEffect(() => {
    if (coAnchorUser2?.dualPk?.diamonds > 0) {
      const element = document.getElementsByClassName("pk-right-score")[0];

      if (element) {
        element.classList.add("scale-animation");

        // Remove the animation class after it completes to reset the animation
        setTimeout(() => {
          element.classList.remove("scale-animation");
        }, 500); // Assuming the animation duration is 1 second
      }
    }
  }, [coAnchorUser2?.dualPk?.diamonds]);

  // Lifecycle | Check for update | Testing
  useEffect(() => {
    if (!dualPkData) return;

    if (dualPkMasterInterval) return;

    pkMasterIntervalFunction();

    // TODO: Check if this is the correct way to handle this
    // if (dualPkMasterInterval) {
    //   dispatch(clearDualPkMasterInterval());

    //   setTimeout(() => {
    //     pkMasterIntervalFunction();
    //   }, 500);
    // } else {
    //   pkMasterIntervalFunction();
    // }
  }, [dualPkData]);

  // Lifecycle | Check for update | dualPkMasterPassthrough
  useEffect(() => {
    if (!dualPkMasterPassthrough) return;

    pkMasterFunction();
  }, [dualPkMasterPassthrough]);

  // PK Functions
  const pkMasterIntervalFunction = () => {
    dispatch(
      updateDualPkMasterInterval(
        setInterval(() => {
          dispatch(updateDualPkMasterPassthrough());
        }, 1000)
      )
    );
  };
  const pkMasterFunction = () => {
    const now = moment();
    const pkStart = dualPkData?.rounds[0]?.start_at;
    const pkEnd =
      dualPkData?.rounds[dualPkData?.rounds?.length - 1]?.end_at ||
      moment(pkStart).add(2, "hours");
    const pkEndVictoryLap = pkEnd ? moment(pkEnd).add(60, "seconds") : null;
    let currentRound;
    let isBreakRound = false;
    let currentIndex = 0;
    let isManualPk = false;

    // Check if final winner is declared
    // If there is a final winner, the end victory lap will be 60 seconds
    if (dualPkData?.finalWinner) {
      dispatch(
        updateDualPkStatus(livestreamingConst.dualPkStatus.endVictoryLap)
      );

      if (now.isAfter(pkEndVictoryLap)) {
        dispatch(updateIsDualPk(false));
        dispatch(updateDualPkStatus(livestreamingConst.dualPkStatus.end));
        dispatch(updateDualPkEndResultDialog(false));
        dispatch(clearDualPkMasterInterval());
      }
    } else {
      if (now.isBefore(moment(pkStart))) {
        // PK has not started yet but the PK bar is visible
        dispatch(updateIsDualPk(true));
      } else if (pkEnd && pkEndVictoryLap && now.isAfter(moment(pkEnd))) {
        if (now.isBetween(moment(pkEnd), pkEndVictoryLap)) {
          // PK Ended
          // Will show the PK bar for 60 seconds after the PK ends
          dispatch(
            updateDualPkStatus(livestreamingConst.dualPkStatus.endVictoryLap)
          );
        } else {
          // PK Ended, dismiss the PK bar
          dispatch(updateCoAnchorUser1DualPkWin(null));
          dispatch(updateCoAnchorUser2DualPkWin(null));
          dispatch(updateIsDualPk(false));
          dispatch(updateDualPkStatus(livestreamingConst.dualPkStatus.end));
          dispatch(clearDualPkMasterInterval());
        }
      } else if (now.isSameOrAfter(moment(pkStart))) {
        // PK started
        // PK is Ongoing
        dispatch(updateIsDualPk(true));

        // Check which round is it currently
        for (let i = 0; i < dualPkData?.rounds?.length; i++) {
          const roundStart = dualPkData?.rounds[i]?.start_at;
          const roundEnd = dualPkData?.rounds[i]?.end_at;
          const breakStart = dualPkData?.rounds[i]?.end_at;
          const breakEnd =
            i + 1 < dualPkData?.rounds?.length
              ? dualPkData?.rounds[i + 1]?.start_at
              : null;

          if (!roundStart || !roundEnd) {
            isManualPk = true;
            break;
          } else if (now.isBetween(moment(roundStart), moment(roundEnd))) {
            dispatch(updateDualPkCurrentRound(dualPkData?.rounds[i]?.round));
            currentRound = dualPkData?.rounds[i]?.round;
            break;
          } else if (
            breakEnd &&
            now.isBetween(moment(breakStart), moment(breakEnd))
          ) {
            isBreakRound = true;
            currentIndex = i;
            break;
          }
        }

        if (isManualPk) {
        } else if (isBreakRound) {
          dispatch(updateDualPkStatus(livestreamingConst.dualPkStatus.break));
          const breakStart = dualPkData?.rounds[currentIndex]?.end_at;
          const breakEnd = dualPkData?.rounds[currentIndex + 1]?.start_at;

          const tempPkTimer = now.isBetween(
            moment(breakStart),
            moment(breakEnd)
          )
            ? moment(breakEnd).diff(now, "seconds")
            : 0;

          dispatch(setDualPkTimer(tempPkTimer));
        } else if (currentRound) {
          dispatch(updateDualPkStatus(livestreamingConst.dualPkStatus.ongoing));
          // Calculate the time left for the current round
          const roundStart = dualPkData?.rounds[currentRound - 1]?.start_at;
          const roundEnd = dualPkData?.rounds[currentRound - 1]?.end_at;

          const pkTimer = now.isBetween(moment(roundStart), moment(roundEnd))
            ? moment(roundEnd).diff(now, "seconds")
            : 0;

          dispatch(setDualPkTimer(pkTimer));

          // Check if time is the first 5 seconds of the round
          const tempDiff = now.diff(roundStart, "seconds");
          dispatch(updateDualPkShowCountdownTimer(tempDiff));
        }
      }
    }
  };

  // Utility Functions
  const getLeftBarWidth = () => {
    if (
      coAnchorUser1?.dualPk?.diamonds === 0 &&
      coAnchorUser2?.dualPk?.diamonds === 0
    ) {
      return 50;
    } else {
      return (
        100 -
        (coAnchorUser1?.dualPk?.diamonds /
          (coAnchorUser1?.dualPk?.diamonds + coAnchorUser2?.dualPk?.diamonds)) *
          100
      );
    }
  };
  const getEmojiLeftBarWidth = () => {
    if (
      coAnchorUser1?.dualPk?.diamonds === 0 &&
      coAnchorUser2?.dualPk?.diamonds === 0
    ) {
      return 50;
    } else {
      return (
        (coAnchorUser1?.dualPk?.diamonds /
          (coAnchorUser1?.dualPk?.diamonds + coAnchorUser2?.dualPk?.diamonds)) *
        100
      );
    }
  };
  const getFormattedTime = (seconds) => {
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds / 60) % 60);
    let secs = Math.floor(seconds % 60);

    let formattedHours = hours < 10 ? "0" + hours : hours;
    let formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    let formattedSeconds = secs < 10 ? "0" + secs : secs;

    if (hours > 0) {
      return formattedHours + ":" + formattedMinutes + ":" + formattedSeconds;
    } else {
      return formattedMinutes + ":" + formattedSeconds;
    }
  };

  if (isDualPk) {
    return (
      <div id="pk-progress-bar-subcomponent">
        <div className="dual-pk-progress-bar-container">
          <div
            className="pk-left-bar"
            style={{ backgroundPosition: `${getLeftBarWidth()}% 50%` }}
          ></div>

          <div
            className={`wet-emoji-container ${
              isMobile
                ? "fixed-position-wet-emoji"
                : "absolute-position-wet-emoji"
            }`}
            style={{ marginLeft: `calc(${getEmojiLeftBarWidth()}% - 2%)` }}
          >
            <div className="wet-emoji">💦</div>
          </div>

          <div className="pk-score-container">
            <div className="pk-left-score">
              {coAnchorUser1?.dualPk?.diamonds || 0}
            </div>
            <div className="pk-right-score">
              {coAnchorUser2?.dualPk?.diamonds || 0}
            </div>
          </div>
        </div>

        {dualPkShowCountdownTimer >= 5 && (
          <div className="dual-pk-countdown-container">
            <div className="pk-icon-container">
              {dualPkStatus === livestreamingConst.dualPkStatus.ongoing ? (
                getIcon("pkIcon", "pk-icon")
              ) : dualPkStatus === livestreamingConst.dualPkStatus.break ? (
                <div className="break-label">Break</div>
              ) : dualPkStatus ===
                livestreamingConst.dualPkStatus.endVictoryLap ? (
                <div className="break-label">Ended</div>
              ) : null}
            </div>

            {dualPkStatus !== livestreamingConst.dualPkStatus.endVictoryLap && (
              <div className="dual-pk-countdown">
                {getFormattedTime(dualPkTimer)}
              </div>
            )}
          </div>
        )}

        <DualPkEndResultDialog />
      </div>
    );
  }
};

export default PkProgressBar;
