const videoCallConst = {
  goPrivate: {
    checklist: [
      {
        value: "1on1.make_sure_to_look_fabulous",
        title: "Make sure to look fabulous",
      },
      {
        value: "1on1.no_illegal_activities",
        title: "No illegal activities",
      },
      {
        value: "1on1.no_hate_speech",
        title: "No hate speech",
      },
      {
        value: "1on1.no_minor",
        title: "No minors allowed",
      },
      {
        value: "1on1.no_violence_and_graphic_content",
        title: "No violence & graphic content",
      },
    ],
  },
};

export default videoCallConst;
