// General
import "./pk-tab.scss";
import { useEffect } from "react";
// Services
import { useLazyGetLivestreamingPkLeaderboardQuery } from "../../../../services/data.service";
// Static Data
import leaderboardConst from "../../../../const/leaderboardConst";
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateLivestreamingTopGiftersPkEvent,
  updateLivestreamingTopStreamersPkEvent,
  resetLivestreamingTopGiftersPkEvent,
  resetLivestreamingTopStreamersPkEvent,
} from "../../../../redux/store/leaderboardStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import Banner from "../../elements/banner/banner";
import LeaderboardSection from "../leaderboard-section/leaderboard-section";

const PkTab = (props) => {
  const { seeAllButtonType = "page" } = props;

  // API variables
  const [
    getLivestreamingPkLeaderboardTopStreamers,
    {
      data: getLivestreamingPkLeaderboardTopStreamersData,
      error: getLivestreamingPkLeaderboardTopStreamersErrorData,
      isFetching: getLivestreamingPkLeaderboardTopStreamersFetching,
      isLoading: getLivestreamingPkLeaderboardTopStreamersLoading,
      isSuccess: getLivestreamingPkLeaderboardTopStreamersSuccess,
      isError: getLivestreamingPkLeaderboardTopStreamersError,
    },
  ] = useLazyGetLivestreamingPkLeaderboardQuery();
  const [
    getLivestreamingPkLeaderboardTopGifters,
    {
      data: getLivestreamingPkLeaderboardTopGiftersData,
      error: getLivestreamingPkLeaderboardTopGiftersErrorData,
      isFetching: getLivestreamingPkLeaderboardTopGiftersFetching,
      isLoading: getLivestreamingPkLeaderboardTopGiftersLoading,
      isSuccess: getLivestreamingPkLeaderboardTopGiftersSuccess,
      isError: getLivestreamingPkLeaderboardTopGiftersError,
    },
  ] = useLazyGetLivestreamingPkLeaderboardQuery();

  // Redux variables
  const livestreamingTopStreamersPkEvent = useSelector(
    (state) => state.leaderboard.livestreamingTopStreamersPkEvent
  );
  const livestreamingTopGiftersPkEvent = useSelector(
    (state) => state.leaderboard.livestreamingTopGiftersPkEvent
  );
  const livestreamingContestPkId = useSelector(
    (state) => state.leaderboard.livestreamingContestPkId
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Utility variables
  let topStreamersParam = {
    board_type: "top_streamers",
    contest_id: livestreamingContestPkId,
  };
  const topGiftersParam = {
    board_type: "top_gifters",
    contest_id: livestreamingContestPkId,
  };

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-19-19.1-19.1.5",
      },
    });

    if (topStreamersParam?.contest_id) {
      getLivestreamingPkLeaderboardTopStreamers(
        `?board_type=${topStreamersParam?.board_type}&contest_id=${topStreamersParam?.contest_id}`,
        false
      );
    }

    if (topGiftersParam?.contest_id) {
      getLivestreamingPkLeaderboardTopGifters(
        `?board_type=${topGiftersParam?.board_type}&contest_id=${topGiftersParam?.contest_id}`,
        false
      );
    }
  }, []);

  // Lifecycle | Check for update | Livestreaming PK Leaderboard Top Streamers API Response
  useEffect(() => {
    if (
      getLivestreamingPkLeaderboardTopStreamersFetching ||
      getLivestreamingPkLeaderboardTopStreamersLoading
    ) {
    } else if (getLivestreamingPkLeaderboardTopStreamersSuccess) {
      if (
        getLivestreamingPkLeaderboardTopStreamersData?.status === 1 &&
        getLivestreamingPkLeaderboardTopStreamersData?.data?.top_streamers
          ?.results?.length > 0
      ) {
        dispatch(resetLivestreamingTopStreamersPkEvent());
        dispatch(
          updateLivestreamingTopStreamersPkEvent(
            getLivestreamingPkLeaderboardTopStreamersData?.data?.top_streamers
              ?.results
          )
        );
      }
    } else if (getLivestreamingPkLeaderboardTopStreamersError) {
      if (getLivestreamingPkLeaderboardTopStreamersErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getLivestreamingPkLeaderboardTopStreamersFetching,
    getLivestreamingPkLeaderboardTopStreamersLoading,
    getLivestreamingPkLeaderboardTopStreamersSuccess,
    getLivestreamingPkLeaderboardTopStreamersError,
  ]);

  // Lifecycle | Check for update | Livestreaming PK Leaderboard Top Gifters API Response
  useEffect(() => {
    if (
      getLivestreamingPkLeaderboardTopGiftersFetching ||
      getLivestreamingPkLeaderboardTopGiftersLoading
    ) {
    } else if (getLivestreamingPkLeaderboardTopGiftersSuccess) {
      if (
        getLivestreamingPkLeaderboardTopGiftersData?.status === 1 &&
        getLivestreamingPkLeaderboardTopGiftersData?.data?.top_gifters?.results
          ?.length > 0
      ) {
        dispatch(resetLivestreamingTopGiftersPkEvent());
        dispatch(
          updateLivestreamingTopGiftersPkEvent(
            getLivestreamingPkLeaderboardTopGiftersData?.data?.top_gifters
              ?.results
          )
        );
      }
    } else if (getLivestreamingPkLeaderboardTopGiftersError) {
      if (getLivestreamingPkLeaderboardTopGiftersErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getLivestreamingPkLeaderboardTopGiftersFetching,
    getLivestreamingPkLeaderboardTopGiftersLoading,
    getLivestreamingPkLeaderboardTopGiftersSuccess,
    getLivestreamingPkLeaderboardTopGiftersError,
  ]);

  return (
    <div id="pk-tab-shared-subcomponent">
      <Banner type={"leaderboard-livestreaming-pk"} view={"stack"} />

      {/* Livestreaming | Top Streamer */}
      {livestreamingContestPkId && (
        <LeaderboardSection
          type={leaderboardConst.type.livestreaming}
          recurrence={leaderboardConst.tabs.events.pk}
          isLoading={
            !livestreamingTopStreamersPkEvent?.length &&
            (getLivestreamingPkLeaderboardTopStreamersFetching ||
              getLivestreamingPkLeaderboardTopStreamersLoading)
          }
          title={t("leaderboard.top_streamers")}
          requireInfoIcon={true}
          infoType={"pk"}
          userList={livestreamingTopStreamersPkEvent}
          userType={leaderboardConst.userType.streamer}
          seeAllButtonType={seeAllButtonType}
        />
      )}

      {/* Livestreaming | Top Gifters= */}
      {livestreamingContestPkId && (
        <LeaderboardSection
          type={leaderboardConst.type.livestreaming}
          recurrence={leaderboardConst.tabs.events.pk}
          isLoading={
            !livestreamingTopGiftersPkEvent?.length &&
            (getLivestreamingPkLeaderboardTopStreamersFetching ||
              getLivestreamingPkLeaderboardTopStreamersLoading)
          }
          title={t("leaderboard.top_gifters")}
          requireInfoIcon={false}
          userList={livestreamingTopGiftersPkEvent}
          userType={leaderboardConst.userType.gifter}
          seeAllButtonType={seeAllButtonType}
        />
      )}
    </div>
  );
};

export default PkTab;
