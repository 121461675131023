// General
import "./follower-user-list.scss";
import { useEffect } from "react";
// Services
import {
  usePostRemoveFollowersMutation,
  usePostFollowUserMutation,
  usePostUnfollowUserMutation,
} from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useDispatch } from "react-redux";
import {
  updateFollowState,
  updateFollowerRemoveState,
} from "../../../../redux/store/userStore";
import {
  updateId,
  updateIdInt,
  updateUsername,
  updateAge,
  updateGender,
  updateLocation,
  updateMembershipType,
  updateVerifiedProfile,
  updateVerifiedCaller,
  updateLevellingBadge,
  updateLivestreamingAchievementBadge,
  updateSpecialBadges,
  updateProfilePhoto,
  updateProfileDecorations,
  updatePublicPhotos,
  updatePrivatePhotos,
  updateTorTags,
  updateProfileAchievementBadge,
  updateAboutMe,
  updateAboutMeManualText,
  updateAboutMeReadStatus,
  updateLookingFor,
  updateOccupation,
  updateLifestyle,
  updateEducation,
  updateRelationship,
  updateSmoking,
  updateDrinking,
  updateHeight,
  updateBodyType,
  updateEthnicity,
  updateEyeColor,
  updateHairColor,
  updateChildren,
  // About Me Functions
  updatePersonalityType,
  updateHobbies,
  updateFitnessLevel,
  updateStarSign,
  updateDominantOrSubmissive,
  updatePersonalityTraits,
  // User Utility
  updateShouldCallApi,
  updatePrivatePhotoRequested,
  updatePrivatePhotoApproval,
  updateRequestedMyPrivatePhoto,
  updateApprovedMyPrivatePhoto,
  updateIsBlocked,
  updateIsSilentInteraction,
  updateIsFavorited,
  updateIsCallEnabled,
  updateIsSugarbaby,
  updateRole,
} from "../../../../redux/store/userCardStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import CustomAvatar from "../../elements/custom-avatar/custom-avatar";
import Spinner from "../../elements/spinner/spinner";

const FollowerUserList = (props) => {
  const {
    id,
    idInt,
    username,
    age,
    gender,
    location,
    membershipType,
    verifiedProfile,
    verifiedCaller,
    levellingBadge,
    livestreamingAchievementBadge,
    specialBadges,
    profilePhoto,
    profileDecorations,
    publicPhotos,
    privatePhotos,
    torTags,
    profileAchievementBadge,
    aboutMe,
    aboutMeReadStatus,
    lookingFor,
    occupation,
    lifestyle,
    education,
    relationship,
    smoking,
    drinking,
    height,
    bodyType,
    ethnicity,
    eyeColor,
    hairColor,
    children,
    // About Me Functions
    personalityType,
    hobbies,
    fitnessLevel,
    starSign,
    dominantOrSubmissive,
    personalityTraits,
    isFollowing,
    // User Utility
    shouldCallApi,
    privatePhotoRequested,
    privatePhotoApproval,
    requestedMyPrivatePhoto,
    approvedMyPrivatePhoto,
    isBlocked,
    isSilentInteraction,
    isFavorited,
    isCallEnabled,
    isSugarbaby,
    role,
    type,
  } = props;

  // API variables
  const [
    postRemoveFollowers,
    {
      data: postRemoveFollowersData,
      error: postRemoveFollowersErrorData,
      isLoading: postRemoveFollowersLoading,
      isSuccess: postRemoveFollowersSuccess,
      isError: postRemoveFollowersError,
    },
  ] = usePostRemoveFollowersMutation();
  const [
    postFollowUser,
    {
      data: postFollowUserData,
      error: postFollowUserErrorData,
      isLoading: postFollowUserLoading,
      isSuccess: postFollowUserSuccess,
      isError: postFollowUserError,
    },
  ] = usePostFollowUserMutation();
  const [
    postUnfollowUser,
    {
      data: postUnfollowUserData,
      error: postUnfollowUserErrorData,
      isLoading: postUnfollowUserLoading,
      isSuccess: postUnfollowUserSuccess,
      isError: postUnfollowUserError,
    },
  ] = usePostUnfollowUserMutation();

  // Redux variables
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Fuctions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Check for update | Post Remove Followers API Response
  useEffect(() => {
    if (postRemoveFollowersLoading) {
    } else if (postRemoveFollowersSuccess) {
      if (postRemoveFollowersData?.status === 1) {
        const obj = {
          userId: id,
        };
        dispatch(updateFollowerRemoveState(obj));
      }
    } else if (postRemoveFollowersError) {
      if (postRemoveFollowersErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    postRemoveFollowersLoading,
    postRemoveFollowersSuccess,
    postRemoveFollowersError,
  ]);

  // Lifecycle | Check for update | Follow user API Response
  useEffect(() => {
    if (postFollowUserLoading) {
    } else if (postFollowUserSuccess) {
      if (postFollowUserData?.status === 1) {
        const obj = {
          userId: id,
          isFollowing: true,
        };
        dispatch(updateFollowState(obj));
      }
    } else if (postFollowUserError) {
      if (postFollowUserErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [postFollowUserLoading, postFollowUserSuccess, postFollowUserError]);

  // Lifecycle | Check for update | Unfollow user API Response
  useEffect(() => {
    if (postUnfollowUserLoading) {
    } else if (postUnfollowUserSuccess) {
      if (postUnfollowUserData?.status === 1) {
        const obj = {
          userId: id,
          isFollowing: false,
        };
        dispatch(updateFollowState(obj));
      }
    } else if (postUnfollowUserError) {
      if (postUnfollowUserErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [postUnfollowUserLoading, postUnfollowUserSuccess, postUnfollowUserError]);

  // Event Handlers | Button
  const onViewProfile = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-27-27.2-Button",
      },
    });

    // User View
    dispatch(updateId(id));
    dispatch(updateIdInt(idInt));
    dispatch(updateUsername(username));
    dispatch(updateAge(age));
    dispatch(updateGender(gender));
    dispatch(updateLocation(location));
    dispatch(updateMembershipType(membershipType));
    dispatch(updateVerifiedProfile(verifiedProfile));
    dispatch(updateVerifiedCaller(verifiedCaller));
    dispatch(updateLevellingBadge(levellingBadge));
    dispatch(
      updateLivestreamingAchievementBadge(livestreamingAchievementBadge)
    );
    dispatch(updateSpecialBadges(specialBadges));
    dispatch(updateProfilePhoto(profilePhoto));
    dispatch(updateProfileDecorations(profileDecorations));
    dispatch(updatePublicPhotos(publicPhotos));
    dispatch(updatePrivatePhotos(privatePhotos));
    dispatch(updateTorTags(torTags));
    dispatch(updateProfileAchievementBadge(profileAchievementBadge));
    dispatch(updateAboutMeManualText(aboutMe));
    dispatch(updateAboutMeReadStatus(aboutMeReadStatus));
    dispatch(updateLookingFor(lookingFor));
    dispatch(updateOccupation(occupation));
    dispatch(updateLifestyle(lifestyle));
    dispatch(updateEducation(education));
    dispatch(updateRelationship(relationship));
    dispatch(updateSmoking(smoking));
    dispatch(updateDrinking(drinking));
    dispatch(updateHeight(height));
    dispatch(updateBodyType(bodyType));
    dispatch(updateEthnicity(ethnicity));
    dispatch(updateEyeColor(eyeColor));
    dispatch(updateHairColor(hairColor));
    dispatch(updateChildren(children));
    // About Me Functions
    dispatch(updatePersonalityType(personalityType));
    dispatch(updateHobbies(hobbies));
    dispatch(updateFitnessLevel(fitnessLevel));
    dispatch(updateStarSign(starSign));
    dispatch(updateDominantOrSubmissive(dominantOrSubmissive));
    dispatch(updatePersonalityTraits(personalityTraits));
    // User Utility
    dispatch(updateShouldCallApi(true));
    dispatch(updatePrivatePhotoRequested(privatePhotoRequested));
    dispatch(updatePrivatePhotoApproval(privatePhotoApproval));
    dispatch(updateRequestedMyPrivatePhoto(requestedMyPrivatePhoto));
    dispatch(updateApprovedMyPrivatePhoto(approvedMyPrivatePhoto));
    dispatch(updateIsBlocked(isBlocked ? true : false));
    dispatch(updateIsSilentInteraction(isSilentInteraction));
    dispatch(updateIsFavorited(isFavorited));
    dispatch(updateIsCallEnabled(isCallEnabled));
    dispatch(updateIsSugarbaby(isSugarbaby));
    dispatch(updateRole(role));

    onNavigate(`${routeConst.profile.view.clearPath}${id}`);
  };
  const onRemoveUser = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-27-27.3-Button",
      },
    });

    const obj = {
      user_id: id,
    };

    postRemoveFollowers(obj);
  };
  const onFollowUser = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-27-27.4-Button",
      },
    });

    const obj = {
      user_id: id,
    };

    postFollowUser(obj);
  };
  const onUnfollowUser = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-27-27.5-Button",
      },
    });

    const obj = {
      user_id: id,
    };

    postUnfollowUser(obj);
  };

  return (
    <div id="follower-user-list-subcomponent">
      <div className="user-profile-photo-container">
        <CustomAvatar
          className="user-profile-photo"
          src={profilePhoto}
          profileBorder={profileDecorations?.profile_border_url}
          onClick={onViewProfile}
        />
      </div>

      <div className="user-info-container">
        {livestreamingAchievementBadge?.length > 0 && (
          <div className="livestreaming-achievement-badge-container">
            {livestreamingAchievementBadge?.map((badge, index) => (
              <img
                className="livestreaming-achievement-badge"
                src={badge?.url}
                alt={badge?.type}
                key={index}
              />
            ))}
          </div>
        )}

        <div className="username">{username}</div>

        {levellingBadge && (
          <div className="level-badge-container">
            <img
              className="level-badge"
              src={levellingBadge}
              alt="level-badge"
            />
          </div>
        )}
      </div>

      {type === "followers" && (
        <div className="remove-button" onClick={onRemoveUser}>
          {postRemoveFollowersLoading ? (
            <Spinner size={12} isPadding={false} />
          ) : (
            t("common.remove")
          )}
        </div>
      )}

      {type !== "followers" && !isFollowing && (
        <div className="follow-button" onClick={onFollowUser}>
          {postFollowUserLoading ? (
            <Spinner size={12} isPadding={false} />
          ) : (
            t("common.follow")
          )}
        </div>
      )}

      {type !== "followers" && isFollowing && (
        <div className="unfollow-button" onClick={onUnfollowUser}>
          {postUnfollowUserLoading ? (
            <Spinner size={12} isPadding={false} color="white-spinner" />
          ) : (
            t("common.following")
          )}
        </div>
      )}
    </div>
  );
};

export default FollowerUserList;
