// General
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // General variables
  selectedUpgradePackage: null,

  // Special Event variables
  specialEvent: null,

  // Upgrade Status variables
  upgradeStatus: null, // success, error

  // Upgrade Status Info variables
  premiumSubscribeInfo: null,

  // Utility variables
  discretionDescription: null,
  creditCardNoteDescription: null,
};

export const upgradeSlice = createSlice({
  name: "upgrade",
  initialState,
  reducers: {
    // General Functions
    updateSelectedUpgradePackage: (state, action) => {
      state.selectedUpgradePackage = action.payload;
    },

    // Special Event Functions
    updateSpecialEvent: (state, action) => {
      state.specialEvent = action.payload;
    },

    // Upgrade Status Functions
    updateUpgradeStatus: (state, action) => {
      state.upgradeStatus = action.payload;
    },

    // Upgrade Status Info Functions
    updatePremiumSubscribeInfo: (state, action) => {
      state.premiumSubscribeInfo = action.payload;
    },

    // Utility Functions
    updateDiscretionDescription: (state, action) => {
      state.discretionDescription = action.payload;
    },
    updateCreditCardNoteDescription: (state, action) => {
      state.creditCardNoteDescription = action.payload;
    },
    resetUpgradeStore: () => {
      return { ...initialState };
    },
  },
});

export const {
  // General Functions
  updateSelectedUpgradePackage,

  // Special Event Functions
  updateSpecialEvent,

  // Upgrade Status Functions
  updateUpgradeStatus,

  // Upgrade Status Info Functions
  updatePremiumSubscribeInfo,

  // Utility Functions
  updateDiscretionDescription,
  updateCreditCardNoteDescription,
  resetUpgradeStore,
} = upgradeSlice.actions;

export default upgradeSlice.reducer;
