// General
import "./complete-terms-of-relationship-dialog.scss";
import { useEffect } from "react";
// Services
import { useLazyGetProfileQuery } from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateShouldCallApi } from "../../../../redux/store/userCardStore";
import { updateCompleteTorDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog } from "@mui/material";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import AssetManager from "../../../utility/manager/asset-manager/asset-manager";

const CompleteTermsOfRelationshipDialog = () => {
  // APi variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // Redux variables
  const completeTorDialog = useSelector(
    (state) => state.dialog.completeTorDialog
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t, i18n } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getAsset = AssetManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!completeTorDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-CompleteTor-Dialog",
      },
    });

    getProfile(null, true);
  }, [completeTorDialog]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateCompleteTorDialog(false));
    dispatch(updateShouldCallApi(true));
    onNavigate(
      `${routeConst.profile.view.clearPath}${getProfileData?.data?.id}`
    );
  };

  return (
    <Dialog
      className="custom-radius35-dialog"
      open={completeTorDialog}
      onClose={onCloseDialog}
    >
      <div id="complete-terms-of-relationship-dialog">
        <div className="padding-container">
          <div className="complete-tor-background-image-container">
            <img
              className="complete-tor-background-image"
              src={getAsset("completeTorBackground")}
            />
          </div>

          <div className="title">
            <Trans
              i18nKey="profile.tor_dialog_complete_title"
              components={{ br: <br /> }}
            />
          </div>

          <div className="description">
            <Trans
              i18nKey="profile.tor_dialog_complete_desc"
              components={{ b: <b /> }}
            />
          </div>

          <div className="got-it-button" onClick={onCloseDialog}>
            {t("common.got_it")}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default CompleteTermsOfRelationshipDialog;
