// General
import "./update-password.scss";
import { useState, useEffect } from "react";
// Services
import { useUpdatePasswordMutation } from "../../../services/data.service";
// Static Data
import routeConst from "../../../const/routeConst";
// Redux
import { useDispatch } from "react-redux";
import {
  updateSuccessToast,
  updateErrorToast,
} from "../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import {
  Box,
  TextField,
  InputAdornment,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import UtilityDesktopNavbar from "../../shared/elements/utility-desktop-navbar/utility-desktop-navbar";
import Spinner from "../../shared/elements/spinner/spinner";

const UpdatePassword = () => {
  // API variables
  const [
    updatePassword,
    {
      data: updatePasswordData,
      error: updatePasswordErrorData,
      isLoading: updatePasswordLoading,
      isSuccess: updatePasswordSuccess,
      isError: updatePasswordError,
    },
  ] = useUpdatePasswordMutation();

  // General variables
  const [oldPassword, setOldPassword] = useState("");
  const [oldPasswordErrorMessage, setOldPasswordErrorMessage] = useState("");
  const [oldPasswordFocus, setOldPasswordFocus] = useState(false);
  const [oldPasswordValid, setOldPasswordValid] = useState(false);
  const [oldPasswordError, setOldPasswordError] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordErrorMessage, setNewPasswordErrorMessage] = useState("");
  const [newPasswordFocus, setNewPasswordFocus] = useState(false);
  const [newPasswordValid, setNewPasswordValid] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] =
    useState("");
  const [confirmPasswordFocus, setConfirmPasswordFocus] = useState(false);
  const [confirmPasswordValid, setConfirmPasswordValid] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  // Redux variables
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Check for update | Check for email and password validation
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-39",
      },
    });

    passwordValidation("old-password");
    passwordValidation("new-password");
    passwordValidation("confirm-password");
  }, [
    oldPassword,
    newPassword,
    confirmPassword,
    oldPasswordFocus,
    newPasswordFocus,
    confirmPasswordFocus,
    oldPasswordErrorMessage,
    newPasswordErrorMessage,
    confirmPasswordErrorMessage,
  ]);

  // Lifecycle | Check for update | Update password API Response
  useEffect(() => {
    if (updatePasswordLoading) {
    } else if (updatePasswordSuccess) {
      switch (updatePasswordData?.status) {
        case 0:
        case 1:
          const successToastObj = {
            message: t("profile.update_success_password"),
            autoClose: 3000,
          };
          dispatch(updateSuccessToast(successToastObj));

          onNavigate(-1);
          break;
        case -1:
          const errorToastObj = {
            message: t("profile.update_error_password"),
            autoClose: 3000,
          };
          dispatch(updateErrorToast(errorToastObj));
          break;
      }
    } else if (updatePasswordError) {
      if (updatePasswordErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [updatePasswordLoading, updatePasswordSuccess, updatePasswordError]);

  // Event Handlers | Button
  const onUpdatePassword = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-39-39.1",
      },
    });

    const obj = {
      oldpassword: oldPassword,
      newpassword: newPassword,
    };

    updatePassword(obj);
  };

  // Event Handlers | MUI Text Fields
  const onPasswordChange = (type) => (e) => {
    const value = e.target.value;

    switch (type) {
      case "old-password":
        setOldPassword(value);
        break;
      case "new-password":
        setNewPassword(value);
        break;
      case "confirm-password":
        setConfirmPassword(value);
        break;
      default:
        break;
    }
  };
  const onPasswordFocus = (type) => (e) => {
    switch (type) {
      case "old-password":
        setOldPasswordFocus(true);
        break;
      case "new-password":
        setNewPasswordFocus(true);
        break;
      case "confirm-password":
        setConfirmPasswordFocus(true);
        break;
      default:
        break;
    }
  };
  const handleClickClearPassword = (type) => () => {
    switch (type) {
      case "old-password":
        setOldPassword("");
        break;
      case "new-password":
        setNewPassword("");
        break;
      case "confirm-password":
        setConfirmPassword("");
        break;
      default:
        break;
    }
  };
  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  // Utility Functions
  const isFormValid = () => {
    let valid = false;
    if (oldPasswordValid && newPasswordValid && confirmPasswordValid) {
      valid = true;
    }

    return valid;
  };
  const passwordValidation = (type) => {
    let valid = true;
    let error = false;

    switch (type) {
      case "old-password":
        if (!oldPasswordFocus) {
          valid = false;
          error = false;
        } else if (oldPasswordFocus && oldPassword === "") {
          setOldPasswordErrorMessage(t("login.password_is_required"));
          valid = false;
          error = true;
        } else if (oldPasswordFocus && oldPassword.length < 6) {
          setOldPasswordErrorMessage(
            t("login.password_must_be_n_characters", { n: 6 })
          );
          valid = false;
          error = true;
        } else {
          setOldPasswordErrorMessage("");
          valid = true;
          error = false;
        }

        setOldPasswordValid(valid);
        setOldPasswordError(error);
        break;
      case "new-password":
        if (!newPasswordFocus) {
          valid = false;
          error = false;
        } else if (newPasswordFocus && newPassword === "") {
          setNewPasswordErrorMessage(t("login.password_is_required"));
          valid = false;
          error = true;
        } else if (newPasswordFocus && newPassword.length < 6) {
          setNewPasswordErrorMessage(
            t("login.password_must_be_n_characters", { n: 6 })
          );
          valid = false;
          error = true;
        } else {
          setNewPasswordErrorMessage("");
          valid = true;
          error = false;
        }

        setNewPasswordValid(valid);
        setNewPasswordError(error);
        break;
      case "confirm-password":
        if (!confirmPasswordFocus) {
          valid = false;
          error = false;
        } else if (confirmPasswordFocus && confirmPassword === "") {
          setConfirmPasswordErrorMessage(t("login.password_is_required"));
          valid = false;
          error = true;
        } else if (confirmPasswordFocus && confirmPassword.length < 6) {
          setConfirmPasswordErrorMessage(
            t("login.password_must_be_n_characters", { n: 6 })
          );
          valid = false;
          error = true;
        } else if (newPassword !== confirmPassword) {
          setConfirmPasswordErrorMessage(t("login.password_do_not_match"));
          valid = false;
          error = true;
        } else {
          setConfirmPasswordErrorMessage("");
          valid = true;
          error = false;
        }

        setConfirmPasswordValid(valid);
        setConfirmPasswordError(error);
        break;
      default:
        break;
    }
  };

  return (
    <Box
      id="update-password-page"
      component="form"
      noValidate
      autoComplete="off"
    >
      <div className="max-width-container">
        <UtilityDesktopNavbar leftButton={"back"} />

        <div className="padding-container">
          <div className="old-password-form-field-container">
            <TextField
              className="password-form-field custom-text-field"
              required
              value={oldPassword}
              error={oldPasswordError}
              onChange={onPasswordChange("old-password")}
              label={t("settings.old_password")}
              type="password"
              placeholder={t("settings.old_password")}
              helperText={oldPasswordErrorMessage}
              variant="standard"
              onFocus={onPasswordFocus("old-password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {oldPassword && (
                      <IconButton
                        onClick={handleClickClearPassword("old-password")}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div className="new-password-form-field-container">
            <TextField
              className="password-form-field custom-text-field"
              required
              value={newPassword}
              error={newPasswordError}
              onChange={onPasswordChange("new-password")}
              label={t("settings.new_password")}
              type="password"
              placeholder={t("settings.new_password")}
              helperText={newPasswordErrorMessage}
              variant="standard"
              onFocus={onPasswordFocus("new-password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {newPassword && (
                      <IconButton
                        onClick={handleClickClearPassword("new-password")}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div className="confirm-password-form-field-container">
            <TextField
              className="password-form-field custom-text-field"
              required
              value={confirmPassword}
              error={confirmPasswordError}
              onChange={onPasswordChange("confirm-password")}
              label={t("settings.confirm_password")}
              type="password"
              placeholder={t("settings.confirm_password")}
              helperText={confirmPasswordErrorMessage}
              variant="standard"
              onFocus={onPasswordFocus("confirm-password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {confirmPassword && (
                      <IconButton
                        onClick={handleClickClearPassword("confirm-password")}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <Button
            className={`update-password-button ${
              isFormValid() ? "" : "disabled-button"
            }`}
            variant="text"
            onClick={onUpdatePassword}
            disabled={!isFormValid()}
          >
            {updatePasswordLoading ? (
              <Spinner size={24} isPadding={false} color={"white-spinner"} />
            ) : (
              t("settings.update_password")
            )}
          </Button>
        </div>
      </div>
    </Box>
  );
};

export default UpdatePassword;
