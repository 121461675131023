// General
import "./livestream.scss";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
// Services
import {
  useLazyGetLivestreamingChannelQuery,
  usePostLivestreamingEnterChannelMutation,
  usePostLivestreamingEnteredChannelMutation,
  usePostLivestreamingLeaveChannelMutation,
  useLazyGetLivestreamingViewersKeepAliveQuery,
  useLazyGetLivestreamingCoAnchorViewQuery,
  useEnterLivestreamingCoAnchorMutation,
  useLivestreamingCoAnchorKeepAliveMutation,
} from "../../../services/data.service";
// Static Data
import routeConst from "../../../const/routeConst";
import utilityConst from "../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  // General Functions
  updateReadOnlyChannelId,
  updateChannelId,
  updateLivestreamerUserObj,
  updateLivestreamerId,
  updateLivestreamerIdInt,
  updateLivestreamerProfilePhoto,
  updateLivestreamerAchievements,
  updateLivestreamerUsername,
  updateLivestreamerLevellingBadge,
  updateLivestreamerFollowers,
  updateLivestreamerViewers,
  updateLivestreamerDiamonds,
  updateLivestreamerIsFollowing,
  resetReadOnlyChannelId,
  resetChannelId,
  resetLivestreamerInfo,

  // Video Player Functions
  updatePullChannel,
  resetPullChannel,
  updateLivestreamLoading,
  updateVideoPlayerMute,

  // Mini Video Player Functions
  updateIsMiniPlayer,
  updateMiniPlayerSource,
  updateMiniPlayerChannelId,

  // Chat Functions
  clearLivestreamChatMessages,

  // Gift Animation Functions
  resetLivestreamGiftAnimation,

  // Co Anchor Functions
  updateIsCoAnchor,
  updateCoAnchorUser1UserId,
  updateCoAnchorUser1UserIdInt,
  updateCoAnchorUser1Username,
  updateCoAnchorUser1ProfilePhoto,
  updateCoAnchorUser1DualPkWin,
  updateCoAnchorUser2UserId,
  updateCoAnchorUser2UserIdInt,
  updateCoAnchorUser2Username,
  updateCoAnchorUser2ProfilePhoto,
  updateCoAnchorUser2DualPkWin,
  updateCoAnchorSelectedUser,

  // Dual PK Functions
  updateIsDualPk,
  updateDualPkData,

  // VIP Entrance Functions
  resetLivestreamVipEntrance,

  // Live Viewers Functions
  resetLiveViewers,

  // Top Fans Functions
  resetTopFansDaily,
  resetTopFansWeekly,
  resetTopFansMonthly,
  resetTopFansOverall,

  // Livestream Switching Counter Functions
  updateLivestreamSwitchingCounter,
  clearLivestreamSwitchingCounter,
  toggleVideoPlayerMute,

  // Utility Functions
  updateViewerKeepAlivePassthrough,
  resetCoAnchorFunctions,
  resetDualPkFunctions,
} from "../../../redux/store/livestreamingStore";
import { updateCardDetail } from "../../../redux/store/paymentStore";
import {
  updateLiveViewPusherSubscribe,
  updateLiveViewPusherUnsubscribe,
} from "../../../redux/store/pusherStore";
import {
  updateCoinsDiscountDialog,
  updateLivestreamKickedDialog,
  updateLivestreamReportDialog,
} from "../../../redux/store/dialogStore";
import { updateErrorToast } from "../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { useTheme, useMediaQuery } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Moment
import moment from "moment";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../utility/manager/icon-manager/icon-manager";
// Components
import VideoPlayer from "./video-player/video-player";
import GiftAnimationOverlay from "../../shared/elements/gift-animation-overlay/gift-animation-overlay";
import SlideInOutAlertOverlay from "./slide-in-out-alert-overlay/slide-in-out-alert-overlay";
import EmojiOverlay from "../../shared/elements/emoji-overlay/emoji-overlay";
import InteractableOverlay from "./interactable-overlay/interactable-overlay";
import LivestreamLoadingOverlay from "./loading-overlay/loading-overlay";
import LivestreamBufferingOverlay from "./buffering-overlay/buffering-overlay";
import PlayOverlay from "./play-overlay/play-overlay";
import DesktopLeftSection from "./desktop-left-section/desktop-left-section";
import TabletRightSection from "./tablet-right-section/tablet-right-section";
import FloatingBanners from "./interactable-overlay/ongoing-events/floating-banners/floating-banners";
import PkProgressBar from "./interactable-overlay/pk-progress-bar/pk-progress-bar";
import ChatSection from "../../shared/elements/chat-section/chat-section";
import PinChat from "./interactable-overlay/pin-chat/pin-chat";
import Topbar from "./interactable-overlay/topbar/topbar";
import OngoingEvents from "./interactable-overlay/ongoing-events/ongoing-events";
import GiftsSection from "../../shared/elements/gifts-section/gifts-section";
import ChatInput from "./interactable-overlay/chat-input/chat-input";
import LivestreamKickedDialog from "../../shared/dialog-content/livestream-kicked-dialog/livestream-kicked-dialog";
import LivestreamReportDialog from "../../shared/dialog-content/livestream-report-dialog/livestream-report-dialog";

const Livestream = () => {
  // API variables
  const [
    getLivestreamingChannel,
    {
      data: getLivestreamingChannelData,
      error: getLivestreamingChannelErrorData,
      isFetching: getLivestreamingChannelFetching,
      isLoading: getLivestreamingChannelLoading,
      isSuccess: getLivestreamingChannelSuccess,
      isError: getLivestreamingChannelError,
    },
  ] = useLazyGetLivestreamingChannelQuery();
  const [
    postLivestreamingEnterChannel,
    {
      data: postLivestreamingEnterChannelData,
      error: postLivestreamingEnterChannelErrorData,
      isLoading: postLivestreamingEnterChannelLoading,
      isSuccess: postLivestreamingEnterChannelSuccess,
      isError: postLivestreamingEnterChannelError,
    },
  ] = usePostLivestreamingEnterChannelMutation();
  const [
    postLivestreamingEnteredChannel,
    {
      data: postLivestreamingEnteredChannelData,
      error: postLivestreamingEnteredChannelErrorData,
      isLoading: postLivestreamingEnteredChannelLoading,
      isSuccess: postLivestreamingEnteredChannelSuccess,
      isError: postLivestreamingEnteredChannelError,
    },
  ] = usePostLivestreamingEnteredChannelMutation();
  const [
    postLivestreamingLeaveChannel,
    {
      data: postLivestreamingLeaveChannelData,
      error: postLivestreamingLeaveChannelErrorData,
      isLoading: postLivestreamingLeaveChannelLoading,
      isSuccess: postLivestreamingLeaveChannelSuccess,
      isError: postLivestreamingLeaveChannelError,
    },
  ] = usePostLivestreamingLeaveChannelMutation();
  const [
    getLivestreamingViewersKeepAlive,
    {
      data: getLivestreamingViewersKeepAliveData,
      error: getLivestreamingViewersKeepAliveErrorData,
      isFetching: getLivestreamingViewersKeepAliveFetching,
      isLoading: getLivestreamingViewersKeepAliveLoading,
      isSuccess: getLivestreamingViewersKeepAliveSuccess,
      isError: getLivestreamingViewersKeepAliveError,
    },
  ] = useLazyGetLivestreamingViewersKeepAliveQuery();
  const [
    getLivestreamingCoAnchorView,
    {
      data: getLivestreamingCoAnchorViewData,
      error: getLivestreamingCoAnchorViewErrorData,
      isFetching: getLivestreamingCoAnchorViewFetching,
      isLoading: getLivestreamingCoAnchorViewLoading,
      isSuccess: getLivestreamingCoAnchorViewSuccess,
      isError: getLivestreamingCoAnchorViewError,
    },
  ] = useLazyGetLivestreamingCoAnchorViewQuery();
  const [
    enterLivestreamingCoAnchor,
    {
      data: enterLivestreamingCoAnchorData,
      error: enterLivestreamingCoAnchorErrorData,
      isLoading: enterLivestreamingCoAnchorLoading,
      isSuccess: enterLivestreamingCoAnchorSuccess,
      isError: enterLivestreamingCoAnchorError,
    },
  ] = useEnterLivestreamingCoAnchorMutation();
  const [
    livestreamingCoAnchorKeepAlive,
    {
      data: livestreamingCoAnchorKeepAliveData,
      error: livestreamingCoAnchorKeepAliveErrorData,
      isLoading: livestreamingCoAnchorKeepAliveLoading,
      isSuccess: livestreamingCoAnchorKeepAliveSuccess,
      isError: livestreamingCoAnchorKeepAliveError,
    },
  ] = useLivestreamingCoAnchorKeepAliveMutation();

  // Redux variables
  const readOnlyChannelId = useSelector(
    (state) => state.livestreaming.readOnlyChannelId
  );
  const channelId = useSelector((state) => state.livestreaming.channelId);
  const livestreamerId = useSelector(
    (state) => state.livestreaming.livestreamerId
  );
  const livestreamSwitchingCounter = useSelector(
    (state) => state.livestreaming.livestreamSwitchingCounter
  );
  const isCoAnchor = useSelector((state) => state.livestreaming.isCoAnchor);
  const isDualPk = useSelector((state) => state.livestreaming.isDualPk);
  const coAnchorStartPassthrough = useSelector(
    (state) => state.livestreaming.coAnchorStartPassthrough
  );
  const coAnchorEndPassthrough = useSelector(
    (state) => state.livestreaming.coAnchorEndPassthrough
  );
  const viewerKeepAlivePassthrough = useSelector(
    (state) => state.livestreaming.viewerKeepAlivePassthrough
  );
  const coAnchorViewerKeepAlivePassthrough = useSelector(
    (state) => state.livestreaming.coAnchorViewerKeepAlivePassthrough
  );
  const releaseChannel = useSelector((state) => state.app.releaseChannel);
  const dispatch = useDispatch();

  // MUI variables
  const isDesktop = useMediaQuery("(min-width: 1305px)");
  const isTablet = useMediaQuery("(min-width: 900px)");
  const isMobile = useMediaQuery("(max-width: 900px)");

  // Router variables
  const { id } = useParams();

  // i18next variables
  const { t } = useTranslation();

  // Utility variables
  let keepAliveInterval;
  let viewerEnterLeaveBody = {
    channel_id: id,
  };

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted & Unmounted
  useEffect(() => {
    // Clear livestream chat incase Unmounted did not clear it
    dispatch(clearLivestreamChatMessages());

    dispatch(updateLivestreamLoading(true));
    dispatch(updateLivestreamKickedDialog(false));
    dispatch(updateIsMiniPlayer(false));
    dispatch(updateMiniPlayerSource(null));
    dispatch(updateMiniPlayerChannelId(null));
    getLivestreamingChannel(id, false);

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-20",
      },
    });

    return () => {
      const obj = {
        channel_id: id,
      };
      postLivestreamingLeaveChannel(obj);
      dispatch(resetReadOnlyChannelId());
      dispatch(resetChannelId());
      dispatch(resetPullChannel());
      dispatch(resetLivestreamerInfo());
      dispatch(resetLiveViewers());
      dispatch(resetTopFansDaily());
      dispatch(resetTopFansWeekly());
      dispatch(resetTopFansMonthly());
      dispatch(resetTopFansOverall());
      dispatch(clearLivestreamChatMessages());
      dispatch(resetLivestreamGiftAnimation());
      dispatch(resetLivestreamVipEntrance());
      dispatch(updateLivestreamSwitchingCounter());
      dispatch(updateLiveViewPusherUnsubscribe({}));
      dispatch(resetCoAnchorFunctions());
      dispatch(resetDualPkFunctions());

      clearInterval(keepAliveInterval);

      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-20-Exit",
        },
      });

      if (livestreamSwitchingCounter > 3) {
        dispatch(updateCoinsDiscountDialog(true));
        dispatch(clearLivestreamSwitchingCounter());

        TagManager.dataLayer({
          dataLayer: {
            event: "PWA-20-ExitStreamCoinPurchaseDialog-Open",
          },
        });
      }
    };
  }, []);

  // Lifecycle | Check for update | Livestreaming Channel API Response
  useEffect(() => {
    if (getLivestreamingChannelFetching || getLivestreamingChannelLoading) {
    } else if (getLivestreamingChannelSuccess) {
      if (getLivestreamingChannelData?.status === 1) {
        if (getLivestreamingChannelData?.data?.live_stream?.co_anchor) {
          // Co Anchor is activated
          dispatch(updateIsCoAnchor(true));

          // Set Channel ID
          dispatch(
            updateReadOnlyChannelId(
              getLivestreamingChannelData?.data?.live_stream?.channel_id
            )
          );
          dispatch(
            updateChannelId(
              getLivestreamingChannelData?.data?.live_stream?.co_anchor
                ?.channel_id
            )
          );

          // Set 1st user as default selected user
          dispatch(
            updateCoAnchorSelectedUser(
              getLivestreamingChannelData?.data?.user?.id
            )
          );

          const str = `?channel_id=${getLivestreamingChannelData?.data?.live_stream?.co_anchor?.channel_id}`;
          getLivestreamingCoAnchorView(str, false);
        } else {
          // Normal Livestream
          // Disable Co Anchor
          dispatch(updateIsCoAnchor(false));

          // Set Channel ID
          dispatch(updateReadOnlyChannelId(id));
          dispatch(updateChannelId(id));

          // Subscribe to Live View Pusher
          dispatch(updateLiveViewPusherSubscribe({}));

          // Call Enter Livestream API
          postLivestreamingEnterChannel(viewerEnterLeaveBody);

          // Set Pull Channel for Video Player
          dispatch(
            updatePullChannel(
              getLivestreamingChannelData?.data?.live_stream?.pull
            )
          );
        }

        // Call Keep Alive API every 30 seconds
        keepAliveInterval = setInterval(() => {
          dispatch(updateViewerKeepAlivePassthrough());
        }, 30000);

        // Livestreamer Info
        dispatch(
          updateLivestreamerUserObj(getLivestreamingChannelData?.data?.user)
        );
        dispatch(
          updateLivestreamerId(getLivestreamingChannelData?.data?.user?.id)
        );
        dispatch(
          updateLivestreamerIdInt(
            getLivestreamingChannelData?.data?.user?.id_int
          )
        );
        dispatch(
          updateLivestreamerProfilePhoto(
            getLivestreamingChannelData?.data?.user?.profile_photo
              ?.original_photo
          )
        );
        dispatch(
          updateLivestreamerAchievements(
            getLivestreamingChannelData?.data?.user?.live_streaming_achievements
          )
        );
        dispatch(
          updateLivestreamerUsername(
            getLivestreamingChannelData?.data?.user?.username
          )
        );
        dispatch(
          updateLivestreamerLevellingBadge(
            getLivestreamingChannelData?.data?.user?.levelling_profile?.badge
          )
        );
        dispatch(
          updateLivestreamerFollowers(
            getLivestreamingChannelData?.data?.user?.live_streaming_meta
              ?.follower_count
          )
        );
        dispatch(
          updateLivestreamerViewers(
            getLivestreamingChannelData?.data?.live_stream?.viewer_count
          )
        );
        dispatch(
          updateLivestreamerDiamonds(
            getLivestreamingChannelData?.data?.user?.live_streaming_meta
              ?.lifetime_diamonds_earned
          )
        );
        dispatch(
          updateLivestreamerIsFollowing(
            getLivestreamingChannelData?.data?.user?.live_streaming_meta
              ?.is_following
          )
        );

        if (
          getLivestreamingChannelData?.data?.live_stream?.live_status ===
          "ended"
        ) {
          onNavigate(routeConst.live.ended.path);
        }
      }
    } else if (getLivestreamingChannelError) {
      if (getLivestreamingChannelErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getLivestreamingChannelFetching,
    getLivestreamingChannelLoading,
    getLivestreamingChannelSuccess,
    getLivestreamingChannelError,
  ]);

  // Lifecycle | Check for update | Livestreaming Enter API Response
  useEffect(() => {
    if (postLivestreamingEnterChannelLoading) {
    } else if (postLivestreamingEnterChannelSuccess) {
      switch (postLivestreamingEnterChannelData?.status) {
        case 1:
          postLivestreamingEnteredChannel(viewerEnterLeaveBody);
          break;
        case -1:
          let errorToastMessage = "";

          if (
            postLivestreamingEnterChannelData?.message === "ERROR_JOIN_KICKED"
          ) {
            errorToastMessage = t("report.you_have_been_kicked_desc");
          } else {
            errorToastMessage = postLivestreamingEnterChannelData?.message;
          }

          const errorToastObj = {
            message: errorToastMessage,
            autoClose: 3000,
          };
          dispatch(updateErrorToast(errorToastObj));
          onNavigate(routeConst.live.path);
          break;
        default:
          break;
      }
    } else if (postLivestreamingEnterChannelError) {
    }
  }, [
    postLivestreamingEnterChannelLoading,
    postLivestreamingEnterChannelSuccess,
    postLivestreamingEnterChannelError,
  ]);

  // Lifecycle | Check for update | Viewers Keep Alive API Response
  useEffect(() => {
    if (
      getLivestreamingViewersKeepAliveFetching ||
      getLivestreamingViewersKeepAliveLoading
    ) {
    } else if (getLivestreamingViewersKeepAliveSuccess) {
      dispatch(
        updateLivestreamerViewers(
          getLivestreamingViewersKeepAliveData?.data?.live_stream?.viewer_count
        )
      );
    } else if (getLivestreamingViewersKeepAliveError) {
      if (getLivestreamingViewersKeepAliveErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getLivestreamingViewersKeepAliveFetching,
    getLivestreamingViewersKeepAliveLoading,
    getLivestreamingViewersKeepAliveSuccess,
    getLivestreamingViewersKeepAliveError,
  ]);

  // Lifecycle | Check for update | Livestreaming Co Anchor View API Response
  useEffect(() => {
    if (
      getLivestreamingCoAnchorViewFetching ||
      getLivestreamingCoAnchorViewLoading
    ) {
    } else if (getLivestreamingCoAnchorViewSuccess) {
      switch (getLivestreamingCoAnchorViewData?.status) {
        case 1:
          // Co Anchor is activated
          dispatch(updateIsCoAnchor(true));

          // Subscribe to Live View Pusher
          dispatch(updateLiveViewPusherSubscribe({}));

          // Show Loading Overlay
          // dispatch(updateLivestreamLoading(true));

          // Clear Chat Messages & Gift Animation
          dispatch(clearLivestreamChatMessages());
          dispatch(resetLivestreamGiftAnimation());

          // Set Co Anchor User 1 data
          dispatch(
            updateCoAnchorUser1UserId(
              getLivestreamingCoAnchorViewData?.data?.hosts[0]?.id
            )
          );
          dispatch(
            updateCoAnchorUser1UserIdInt(
              getLivestreamingCoAnchorViewData?.data?.hosts[0]?.id_int
            )
          );
          dispatch(
            updateCoAnchorUser1Username(
              getLivestreamingCoAnchorViewData?.data?.hosts[0]?.username
            )
          );
          dispatch(
            updateCoAnchorUser1ProfilePhoto(
              getLivestreamingCoAnchorViewData?.data?.hosts[0]?.profile_photo
                ?.original_photo
            )
          );

          // Set Co Anchor User 2 data
          dispatch(
            updateCoAnchorUser2UserId(
              getLivestreamingCoAnchorViewData?.data?.hosts[1]?.id
            )
          );
          dispatch(
            updateCoAnchorUser2UserIdInt(
              getLivestreamingCoAnchorViewData?.data?.hosts[1]?.id_int
            )
          );
          dispatch(
            updateCoAnchorUser2Username(
              getLivestreamingCoAnchorViewData?.data?.hosts[1]?.username
            )
          );
          dispatch(
            updateCoAnchorUser2ProfilePhoto(
              getLivestreamingCoAnchorViewData?.data?.hosts[1]?.profile_photo
                ?.original_photo
            )
          );

          // Set current livestreamer as default selected user
          // If null, set 1st livestreamer as default selected user
          dispatch(
            updateCoAnchorSelectedUser(
              livestreamerId ||
                getLivestreamingCoAnchorViewData?.data?.hosts[0]?.id
            )
          );

          // Get PK Data
          if (getLivestreamingCoAnchorViewData?.data?.channel?.pk) {
            const pkData =
              getLivestreamingCoAnchorViewData?.data?.channel?.pk[
                getLivestreamingCoAnchorViewData?.data?.channel?.pk?.length - 1
              ];

            const now = moment();
            const startAt = moment(pkData?.start_at);
            const endAt = pkData?.end_at
              ? moment(pkData?.end_at)
              : moment(startAt).add(2, "hours");

            if (
              now.isAfter(startAt) &&
              now.isBefore(endAt) &&
              !pkData?.final_winner
            ) {
              // Calculate win count for both users
              let user1WinCount = 0;
              let user2WinCount = 0;

              pkData?.rounds?.forEach((round) => {
                const winnerId = round?.winner;
                const hosts = getLivestreamingCoAnchorViewData?.data?.hosts;

                if (winnerId === hosts?.[0]?.id) {
                  user1WinCount++;
                } else if (winnerId === hosts?.[1]?.id) {
                  user2WinCount++;
                }
              });
              dispatch(updateCoAnchorUser1DualPkWin(user1WinCount));
              dispatch(updateCoAnchorUser2DualPkWin(user2WinCount));

              dispatch(updateIsDualPk(true));
              dispatch(
                updateDualPkData({
                  id: pkData?.id,
                  currentRound: pkData?.current_round,
                  rounds: pkData?.rounds,
                  finalWinner: pkData?.final_winner,
                })
              );
            }
          }

          // Mute Video Player
          dispatch(updateVideoPlayerMute(true));

          // Set Pull Channel for Video Player
          dispatch(
            updatePullChannel(
              getLivestreamingCoAnchorViewData?.data?.channel?.pull
            )
          );

          // Call Enter Livestream Co Anchor API
          const obj = {
            channel_id: channelId,
          };
          enterLivestreamingCoAnchor(obj);
          break;
        case -31005:
          // Invalid Channel
          onNavigate(routeConst.live.ended.path);
          break;
        default:
          break;
      }
    } else if (getLivestreamingCoAnchorViewError) {
    }
  }, [
    getLivestreamingCoAnchorViewFetching,
    getLivestreamingCoAnchorViewLoading,
    getLivestreamingCoAnchorViewSuccess,
    getLivestreamingCoAnchorViewError,
  ]);

  // Lifecycle | Check for update | Livestreaming Co Anchor Enter API Response
  useEffect(() => {
    if (enterLivestreamingCoAnchorLoading) {
    } else if (enterLivestreamingCoAnchorSuccess) {
      switch (enterLivestreamingCoAnchorData?.status) {
        case 0:
          // Not Sure
          break;
        case 1:
          // Set Co Anchor User 1 data
          dispatch(
            updateCoAnchorUser1UserId(
              enterLivestreamingCoAnchorData?.data?.hosts[0]?.id
            )
          );
          dispatch(
            updateCoAnchorUser1UserIdInt(
              enterLivestreamingCoAnchorData?.data?.hosts[0]?.id_int
            )
          );
          dispatch(
            updateCoAnchorUser1Username(
              enterLivestreamingCoAnchorData?.data?.hosts[0]?.username
            )
          );
          dispatch(
            updateCoAnchorUser1ProfilePhoto(
              enterLivestreamingCoAnchorData?.data?.hosts[0]?.profile_photo
                ?.original_photo
            )
          );

          // Set Co Anchor User 2 data
          dispatch(
            updateCoAnchorUser2UserId(
              enterLivestreamingCoAnchorData?.data?.hosts[1]?.id
            )
          );
          dispatch(
            updateCoAnchorUser2UserIdInt(
              enterLivestreamingCoAnchorData?.data?.hosts[1]?.id_int
            )
          );
          dispatch(
            updateCoAnchorUser2Username(
              enterLivestreamingCoAnchorData?.data?.hosts[1]?.username
            )
          );
          dispatch(
            updateCoAnchorUser2ProfilePhoto(
              enterLivestreamingCoAnchorData?.data?.hosts[1]?.profile_photo
                ?.original_photo
            )
          );

          // Set current livestreamer as default selected user
          // If null, set 1st user as default selected user
          dispatch(
            updateCoAnchorSelectedUser(
              livestreamerId ||
                enterLivestreamingCoAnchorData?.data?.hosts[0]?.id
            )
          );
          break;
        default:
          break;
      }
    } else if (enterLivestreamingCoAnchorError) {
    }
  }, [
    enterLivestreamingCoAnchorLoading,
    enterLivestreamingCoAnchorSuccess,
    enterLivestreamingCoAnchorError,
  ]);

  // Lifecycle | Check for update | Livestreaming Co Anchor Keep Alive API Response
  useEffect(() => {
    if (livestreamingCoAnchorKeepAliveLoading) {
    } else if (livestreamingCoAnchorKeepAliveSuccess) {
      switch (livestreamingCoAnchorKeepAliveData?.status) {
        case 0:
        case 1:
          break;
        default:
          break;
      }
    } else if (livestreamingCoAnchorKeepAliveError) {
    }
  }, [
    livestreamingCoAnchorKeepAliveLoading,
    livestreamingCoAnchorKeepAliveError,
    livestreamingCoAnchorKeepAliveSuccess,
  ]);

  // Lifecycle | Check for update | coAnchorStartPassthrough
  // Indicate that co-host is has started from Pusher
  useEffect(() => {
    if (!coAnchorStartPassthrough) return;

    // Preliminary
    dispatch(clearLivestreamChatMessages());
    dispatch(resetLivestreamGiftAnimation());
    dispatch(resetPullChannel());

    const str = `?channel_id=${channelId}`;
    getLivestreamingCoAnchorView(str, false);
  }, [coAnchorStartPassthrough]);

  // Lifecycle | Check for update | coAnchorEndPassthrough
  // Indicate that co-host has ended from Pusher
  useEffect(() => {
    if (!coAnchorEndPassthrough) return;

    dispatch(clearLivestreamChatMessages());
    dispatch(resetLivestreamGiftAnimation());
    dispatch(resetPullChannel());

    getLivestreamingChannel(readOnlyChannelId, false);

    setTimeout(() => {
      dispatch(updateIsCoAnchor(false));
    }, 2000);
  }, [coAnchorEndPassthrough]);

  // Lifecycle | Check for update | viewerKeepAlivePassthrough
  useEffect(() => {
    if (!viewerKeepAlivePassthrough) return;

    const obj = {
      channel_id: id,
    };
    getLivestreamingViewersKeepAlive(obj);
  }, [viewerKeepAlivePassthrough]);

  // Lifecycle | Check for update | coAnchorViewerKeepAlivePassthrough
  useEffect(() => {
    if (!coAnchorViewerKeepAlivePassthrough) return;

    const obj = {
      channel_id: channelId,
    };
    livestreamingCoAnchorKeepAlive(obj);
  }, [coAnchorViewerKeepAlivePassthrough]);

  return (
    <div id="livestream-page">
      {/* Left Section if view is Desktop */}
      <DesktopLeftSection />

      {/* Video Player */}
      <div
        className={`video-player-container ${
          isMobile ? "black-background" : ""
        }`}
      >
        <VideoPlayer />

        {isDualPk && (
          <div
            className={`pk-progress-bar-container ${
              isMobile ? "" : "desktop-pk-progress-bar-container"
            }`}
          >
            <PkProgressBar />
          </div>
        )}

        {isCoAnchor && isMobile && (
          <div className="co-anchor-bottom-section">
            <ChatSection state={"livestream"} />
            <PinChat />
            <GiftsSection state={"livestream"} />
            <ChatInput />
          </div>
        )}

        <LivestreamBufferingOverlay />

        {/* Gift & Emoji Overlay for Tablet & Desktop */}
        {!isMobile && (
          <div className="tablet-desktop-view-container">
            <GiftAnimationOverlay />
            <div className="slide-in-out-desktop-container">
              <SlideInOutAlertOverlay />
            </div>
            <EmojiOverlay />

            <div className="livestream-desktop-video-overlay-container">
              <Topbar
                showProfile={false}
                showBack={false}
                showMiniPlayer={
                  releaseChannel !== utilityConst.releaseChannel.stable
                }
              />
              <OngoingEvents />
            </div>

            <div className="quick-gift-container">
              <PinChat />
              <GiftsSection state={"livestream"} />
            </div>
          </div>
        )}
      </div>

      <LivestreamLoadingOverlay />

      <PlayOverlay />

      {/* Right Section if view is Tablet or Desktop */}
      <TabletRightSection />

      {/* Mobile View */}
      {isMobile && (
        <div className="mobile-view">
          <div className="interactable-container">
            {!isCoAnchor && <GiftAnimationOverlay />}
            <SlideInOutAlertOverlay />
            <EmojiOverlay />

            <InteractableOverlay
              showTopSection={true}
              showBottomSection={!isCoAnchor}
            />
          </div>
        </div>
      )}

      <LivestreamKickedDialog />
      <LivestreamReportDialog />
    </div>
  );
};

export default Livestream;
