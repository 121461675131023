// General
import { useNavigate, useLocation } from "react-router-dom";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateNavigationHistory } from "../../../redux/store/navigationStore";

const useCustomNavigate = () => {
  // Redux variables
  const utmSource = useSelector((state) => state.tracking.utmSource);
  const dispatch = useDispatch();

  // Router variables
  const navigate = useNavigate();
  const location = useLocation();

  // Main Function
  const onNavigate = (path, replace = false, search = null) => {
    if (
      (location.pathname.includes("logout") || location.pathname === "/") &&
      path.includes("logout")
    ) {
    } else if (path === -1) {
      dispatch(updateNavigationHistory(path));
      navigate(path, { replace: replace });
    } else {
      dispatch(
        updateNavigationHistory(`${path}${utmSource?.rawSearchParam ?? ""}`)
      );
      navigate(
        {
          pathname: path,
          search: search,
        },
        { replace: replace }
      );
      // navigate(
      //   {
      //     pathname: `${path}${utmSource?.rawSearchParam ?? ""}`,
      //     search: search,
      //   },
      //   { replace: replace }
      // );
    }
  };

  return onNavigate;
};

export default useCustomNavigate;
