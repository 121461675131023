// General
import "./confirm-app-reset-dialog.scss";
// Service Worker
import * as serviceWorkerRegistration from "../../../../serviceWorkerRegistration";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateConfirmAppResetDialog } from "../../../../redux/store/dialogStore";
// Material UI
import { Dialog } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";

const ConfirmAppResetDialog = () => {
  // Redux variables
  const confirmAppResetDialog = useSelector(
    (state) => state.dialog.confirmAppResetDialog
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Event Handlers | Button
  const onRestartServiceWorker = () => {
    serviceWorkerRegistration.unregister();

    setTimeout(() => {
      // After unregistering the service worker,
      // reload the page to successfully remove and re-register the service worker
      window.location.reload(true);
    }, 1000);
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateConfirmAppResetDialog(false));
  };

  return (
    <Dialog
      className="custom-radius10-dialog"
      open={confirmAppResetDialog}
      onClose={onCloseDialog}
    >
      <div id="confirm-app-reset-dialog">
        <div className="padding-container">
          <div className="are-you-sure-title">Are you sure?</div>
          <div className="confirm-app-reset-description">
            If your app isn’t updating, feel free to press this button to
            restart the app.
          </div>

          <div className="buttons-container">
            <div className="confirm-button" onClick={onRestartServiceWorker}>
              {t("common.continue")}
            </div>
            <div className="cancel-button" onClick={onCloseDialog}>
              {t("common.cancel")}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmAppResetDialog;
