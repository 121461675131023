// General
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // Subscription variables
  selectedSubscription: null,

  // Coin Package variables
  selectedCoinPackage: null,

  // Purchase Dialog variables
  purchaseDialogTab: 0,
};

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    // Subscription Functions
    updateSelectedSubscription: (state, action) => {
      state.selectedSubscription = action.payload;
    },

    // Coin Package Functions
    updateSelectedCoinPackage: (state, action) => {
      state.selectedCoinPackage = action.payload;
    },

    // Purchase Dialog Functions
    updatePurchaseDialogTab: (state, action) => {
      state.purchaseDialogTab = action.payload;
    },

    // Utility Functions
    resetProductsStore: () => {
      return { ...initialState };
    },
  },
});

export const {
  // Subscription Functions
  updateSelectedSubscription,

  // Coin Package Functions
  updateSelectedCoinPackage,

  // Purchase Dialog Functions
  updatePurchaseDialogTab,

  // Utility Functions
  resetProductsStore,
} = productsSlice.actions;

export default productsSlice.reducer;
