// General
import "./camera-feeds.scss";
import { useState, useEffect } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateEndVideoCall,
  updateShowVideoCallJoiningOverlay,
  updateShowVideoCallStreamInitializingOverlay,

  // TRTC Functions
  updateCallStatus,
} from "../../../../redux/store/privateCallStore";
// Tencent Real Time Communication (TRTC)
import TRTC from "trtc-js-sdk";
// Custom Hooks
import useIsMounted from "../../../utility/custom-hooks/useIsMounted-hook";
import useEndPrivateCall from "../../../utility/custom-hooks/useEndPrivateCall-hook";
// Components
import CalleeCameraFeed from "./callee-camera-feed-overlay/callee-camera-feed-overlay";
import CallerCameraFeed from "./caller-camera-feed/caller-camera-feed";
import VideoGradientOverlay from "../../../shared/video-call/video-gradient-overlay/video-gradient-overlay";

const CameraFeeds = () => {
  // General variables
  const [trtcClient, setTrtcClient] = useState(null);

  // Redux variables
  const isPrivateStandbyMode = useSelector(
    (state) => state.privateCall.isPrivateStandbyMode
  );
  const isCaller = useSelector((state) => state.privateCall.isCaller);
  const callerId = useSelector((state) => state.privateCall.callerId);
  const calleeId = useSelector((state) => state.privateCall.calleeId);
  const callerUserSig = useSelector((state) => state.privateCall.callerUserSig);
  const calleeUserSig = useSelector((state) => state.privateCall.calleeUserSig);
  const dispatch = useDispatch();

  // Custom Hooks Functions
  const mounted = useIsMounted();
  const onEndCall = useEndPrivateCall();

  // Lifecycle | Unmounted
  useEffect(() => {
    return async () => {
      if (!mounted()) {
        if (trtcClient) {
          await trtcClient.leave().then(() => {
            trtcClient.destroy();
          });
        }
      }
    };
  }, [mounted]);

  // Lifecycle | Check for update | TRTC Client
  useEffect(() => {
    if (!trtcClient) return;

    trtcEventsListener();
  }, [trtcClient]);

  // Lifecycle | Check for update | callerUserSig
  useEffect(() => {
    if (!callerId || !callerUserSig) return;

    createVideoCallClient();
  }, [callerId, callerUserSig]);

  // TRTC Functions
  const createVideoCallClient = () => {
    setTrtcClient(
      TRTC.createClient({
        mode: "rtc",
        sdkAppId: parseInt(process.env["REACT_APP_TRTC_SDK_APP_ID"]),
        userId: getUserId(),
        userSig: callerUserSig,
      })
    );
  };
  const trtcEventsListener = () => {
    // trtcClient.on("error", (err) => {
    //   console.log(err);
    // });

    // trtcClient.on("client-banned", (err) => {
    //   console.log("TRTC Event Listener: CLIENT-BANNED: ", err);
    // });

    trtcClient.on("peer-join", (event) => {
      console.log("TRTC Event Listener: PEER-JOIN: ", event);

      dispatch(updateShowVideoCallJoiningOverlay(false));
      dispatch(updateShowVideoCallStreamInitializingOverlay(true));
    });

    trtcClient.on("peer-leave", async (event) => {
      console.log("TRTC Event Listener: PEER-LEAVE: ", event);

      dispatch(updateShowVideoCallStreamInitializingOverlay(false));

      await trtcClient.leave();
      trtcClient.destroy();
      setTrtcClient(null);

      dispatch(updateEndVideoCall(true));

      // onEndCall(); // Not sure with conflict with privateStandbyMode
    });

    // trtcClient.on("stream-added", (event) => {
    //   console.log("TRTC Event Listener: STREAM-ADDED: ", event);
    // });

    // trtcClient.on("stream-subscribed", (event) => {
    //   console.log("TRTC Event Listener: STREAM-SUBSCRIBED: ", event);
    // });

    // trtcClient.on("stream-removed", (event) => {
    //   console.log("TRTC Event Listener: STREAM-REMOVED: ", event);
    // });

    // trtcClient.on("stream-updated", (event) => {
    //   console.log("TRTC Event Listener: STREAM-UPDATED: ", event);
    // });
  };

  // Utility Functions
  const getUserId = () => {
    if (isPrivateStandbyMode) {
      return callerId;
    } else {
      if (isCaller) {
        return callerId;
      } else {
        return calleeId;
      }
    }
  };

  return (
    <div id="video-call-room-camera-feeds-subcomponent">
      <CalleeCameraFeed trtcClient={trtcClient} />
      <VideoGradientOverlay />
      <CallerCameraFeed trtcClient={trtcClient} />
    </div>
  );
};

export default CameraFeeds;
