// General
import "./go-private-permission-dialog.scss";
import { useState, useEffect } from "react";
// Services
import { sessionService } from "../../../../services/session.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateGoPrivatePermissionDialog,
  updateGoPrivatePermissionTutorialDialog,
  updatePreJoinDialog,
  updateVideoCallCameraTesterDialog,
} from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import GoPrivatePermissionTutorialDialog from "../go-private-permission-tutorial-dialog/go-private-permission-tutorial-dialog";

const GoPrivatePermissionDialog = () => {
  // General variables
  const [read, setRead] = useState(false);

  // Redux variables
  const goPrivatePermissionDialog = useSelector(
    (state) => state.dialog.goPrivatePermissionDialog
  );
  const isCaller = useSelector((state) => state.privateCall.isCaller);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Function
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!goPrivatePermissionDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-D1",
      },
    });
  }, [goPrivatePermissionDialog]);

  // Event Handlers | Button
  const onProceedToStandby = () => {
    if (!read) return;

    // Save to local storage
    sessionService.setHasShownVideoCallPermissionDialog(true);

    if (isCaller) {
      dispatch(updatePreJoinDialog(true));
    } else {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-D1-1.1-Button",
        },
      });

      dispatch(updateVideoCallCameraTesterDialog(true));
      // onNavigate(routeConst.videoCall.privateStandby.path);
    }

    onCloseDialog();
  };
  const onOpenPermissionTutorial = () => {
    dispatch(updateGoPrivatePermissionTutorialDialog(true));
    setRead(true);
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateGoPrivatePermissionDialog(false));
  };

  return (
    <Dialog
      className="custom-radius35-dialog"
      open={goPrivatePermissionDialog}
      onClose={onCloseDialog}
    >
      <div id="go-private-permission-dialog">
        <div className="dialog-header">
          <div className="close-button-container">
            <CloseIcon className="close-button" onClick={onCloseDialog} />
          </div>
        </div>

        <div className="padding-container">
          <div className="alert-icon-container">
            {getIcon("dropPhotoIcon", "alert-icon")}
          </div>

          <div className="title">{t("1on1.setup_your_camera_title")}</div>

          <div className="description">
            <Trans
              i18nKey={"1on1.setup_your_camera_desc"}
              components={{ span: <span></span> }}
            />
          </div>

          <div className="guide-container">
            <div className="guide" onClick={onOpenPermissionTutorial}>
              {t("1on1.3_step_guide")}
            </div>

            <div className="please-read-label">
              {t("1on1.please_read_to_continue")}
            </div>
          </div>

          <div
            className={`proceed-button ${read ? "" : "disable-button"}`}
            onClick={onProceedToStandby}
          >
            {t("common.next")}
          </div>
        </div>
      </div>

      <GoPrivatePermissionTutorialDialog />
    </Dialog>
  );
};

export default GoPrivatePermissionDialog;
