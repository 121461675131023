// General
import "./profile-achievement-badge-dialog.scss";
import { useEffect } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateProfileAchievementBadgeDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ProfileAchievementBadgeDialog = () => {
  // Redux variables
  const profileAchievementBadgeDialog = useSelector(
    (state) => state.dialog.profileAchievementBadgeDialog
  );
  const profileBadgeInfo = useSelector(
    (state) => state.profile.profileBadgeInfo
  );
  const dispatch = useDispatch();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!profileAchievementBadgeDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-ProfileAchievementBadge-Dialog",
      },
    });
  }, [profileAchievementBadgeDialog]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateProfileAchievementBadgeDialog(false));
  };

  return (
    <Dialog
      className="custom-radius35-dialog"
      open={profileAchievementBadgeDialog}
      onClose={onCloseDialog}
    >
      <div id="profile-achievement-badge-dialog">
        <div className="dialog-header">
          <div className="close-button-container">
            <CloseIcon className="close-button" onClick={onCloseDialog} />
          </div>
        </div>

        <div className="padding-container">
          <div className="profile-badge-container">
            <img
              className="profile-badge"
              src={profileBadgeInfo?.url}
              alt="badge"
            />
          </div>

          <div
            className="badge-description"
            dangerouslySetInnerHTML={{ __html: profileBadgeInfo?.description }}
          ></div>
        </div>
      </div>
    </Dialog>
  );
};

export default ProfileAchievementBadgeDialog;
