// General
import "./skeleton-card.scss";
// Material UI
import { Skeleton } from "@mui/material";

const SkeletonCard = () => {
  return (
    <div id="skeleton-card-subcomponent">
      <div>
        <div className="search-card-container">
          <Skeleton className="profile-image-container" variant="rounded" />

          <div className="description-container">
            <div className="top-description-container"></div>

            <div className="bottom-description-container">
              <Skeleton className="username-skeleton" variant="rounded" />
              <Skeleton className="location-skeleton" variant="rounded" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonCard;
