// General
import "./octopus-navbar.scss";
import { Link } from "react-router-dom";
// Static Data
import routeConst from "../../../../../const/routeConst";
// Material UI
import HomeIcon from "@mui/icons-material/Home";
import InboxIcon from "@mui/icons-material/Inbox";
import VideocamIcon from "@mui/icons-material/Videocam";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PersonIcon from "@mui/icons-material/Person";

const OctopusNavbar = (props) => {
  const {
    showSearch = true,
    showInbox = false,
    showVideoCall = false,
    showLive = false,
    showInterest = true,
    showProfile = true,
    // Badge Count
    inboxBadgeCount,
    interestBadgeCount,
    // Selected Icon
    currentRoute,
    // Callback Functions
    onNavigateClickCallback,
  } = props;

  // Event Handlers | Button
  const onNavigateClick = (path) => {
    props.onNavigateClickCallback(path);
  };

  return (
    <div id="octopus-navbar-container">
      <nav className="octopus-navbar">
        <ul className="octopus-navbar-list">
          {showSearch && (
            <li
              className={
                currentRoute === routeConst.search.path ? "active" : ""
              }
              onClick={() => onNavigateClick(routeConst.search.path)}
            >
              <Link>
                <span>Home</span>
                <HomeIcon />
              </Link>
            </li>
          )}

          {showInbox && (
            <li
              className={currentRoute === routeConst.inbox.path ? "active" : ""}
              onClick={() => onNavigateClick(routeConst.inbox.path)}
            >
              <Link>
                <span>Inbox</span>
                <InboxIcon />
              </Link>
            </li>
          )}

          {showVideoCall && (
            <li
              className={
                currentRoute === routeConst.videoCall.path ? "active" : ""
              }
              onClick={() => onNavigateClick(routeConst.videoCall.path)}
            >
              <Link>
                <span>Video</span>
                <VideocamIcon />
              </Link>
            </li>
          )}

          {showLive && (
            <li
              className={currentRoute === routeConst.live.path ? "active" : ""}
              onClick={() => onNavigateClick(routeConst.live.path)}
            >
              <Link>
                <span>Live</span>
                <LiveTvIcon />
              </Link>
            </li>
          )}

          {showInterest && (
            <li
              className={
                currentRoute === routeConst.interest.path ? "active" : ""
              }
              onClick={() => onNavigateClick(routeConst.interest.path)}
            >
              <Link>
                <span>Fav</span>
                <FavoriteIcon />
              </Link>
            </li>
          )}

          {showProfile && (
            <li
              className={
                currentRoute === routeConst.profile.overview.path
                  ? "active"
                  : ""
              }
              onClick={() => onNavigateClick(routeConst.profile.overview.path)}
            >
              <Link>
                <span>Profile</span>
                <PersonIcon />
              </Link>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default OctopusNavbar;
