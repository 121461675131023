// General
import "./empty-list.scss";
// Static Data
import routeConst from "../../../../const/routeConst";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const EmptyList = () => {
  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Event Handlers | Button
  const onBrowseMembers = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-36-36.1-Button",
      },
    });

    onNavigate(routeConst.search.path);
  };

  return (
    <div id="photo-permissions-empty-list-subcomponent" className="empty-list">
      <div className="photo-permissions-icon-container">
        {getIcon("photoPermissionEmptyIcon", "")}
      </div>
      <div className="no-private-text">
        <Trans
          i18nKey={t("settings.photo_permissions_empty")}
          components={{ br: <br /> }}
        />
      </div>

      <div className="browse-member-button" onClick={onBrowseMembers}>
        {t("common.browse_members")}
      </div>
    </div>
  );
};

export default EmptyList;
