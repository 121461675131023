// General
import "./gaming-card.scss";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useDispatch } from "react-redux";
import { resetSelectedGameUrl } from "../../../../redux/store/gameStore";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import AssetManager from "../../../utility/manager/asset-manager/asset-manager";

const GamingCard = (props) => {
  const { backgroundImage } = props;

  // Redux variables
  const dispatch = useDispatch();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getAsset = AssetManager();

  // Event Handlers | Button
  const onRedirectoToGame = () => {
    dispatch(resetSelectedGameUrl());
    onNavigate(routeConst.games.casino.path);
  };

  return (
    <div id="gaming-card-shared-subcomponent" onClick={onRedirectoToGame}>
      <div className="gaming-card-container">
        <div className="gaming-image-container">
          <img
            className="gaming-image"
            src={backgroundImage || getAsset("casinoTempBackground")}
            alt={"gaming-test-background"}
          />
        </div>
      </div>
    </div>
  );
};

export default GamingCard;
