// General
import "./mobile-verification.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetUserAioQuery,
  useInitiateMobileVerificationMutation,
  useVerifyMobileVerificationMutation,
  useInitiateManualVerificationMutation,
} from "../../../services/data.service";
// Static Data
import routeConst from "../../../const/routeConst";
import verificationConst from "../../../const/verificationConst";
import utilityConst from "../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  setMobileVerificationTimer,
  updateMobileVerificationTimer,
  updateMobileVerificationInterval,
  clearMobileVerificationInterval,
  setMobileCallVerificationTimer,
  updateMobileCallVerificationTimer,
  updateMobileCallVerificationInterval,
  clearMobileCallVerificationInterval,
  updateMobileVerificationMethod,
  updateMobileVerificationShowAlternateMethod,
} from "../../../redux/store/verificationStore";
import {
  updateVerificationDataPassthrough,
  updateJourneyNavigationPassthrough,
} from "../../../redux/store/registrationStore";
import {
  updateErrorToast,
  updateInfoToast,
} from "../../../redux/store/toastStore";
import { updateVerificationStatusDialog } from "../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Moment
import moment from "moment";
// lottie-react
import Lottie from "lottie-react";
// Material UI
import { Divider } from "@mui/material";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
import AssetManager from "../../utility/manager/asset-manager/asset-manager";
import IconManager from "../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../shared/elements/spinner/spinner";

const MobileVerification = (props) => {
  const { type = utilityConst.fillingDetails.signup.type } = props;

  // API variables
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();
  const [
    initiateMobileVerification,
    {
      data: initiateMobileVerificationData,
      error: initiateMobileVerificationErrorData,
      isLoading: initiateMobileVerificationLoading,
      isSuccess: initiateMobileVerificationSuccess,
      isError: initiateMobileVerificationError,
    },
  ] = useInitiateMobileVerificationMutation();
  const [
    verifyMobileVerification,
    {
      data: verifyMobileVerificationData,
      error: verifyMobileVerificationErrorData,
      isLoading: verifyMobileVerificationLoading,
      isSuccess: verifyMobileVerificationSuccess,
      isError: verifyMobileVerificationError,
    },
  ] = useVerifyMobileVerificationMutation();
  const [
    initiateManualVerification,
    {
      data: initiateManualVerificationData,
      error: initiateManualVerificationErrorData,
      isLoading: initiateManualVerificationLoading,
      isSuccess: initiateManualVerificationSuccess,
      isError: initiateManualVerificationError,
    },
  ] = useInitiateManualVerificationMutation();

  // General variables
  const [otp, setOtp] = useState("");
  const [invalidCode, setInvalidCode] = useState(false);
  const [isAnimationPlaying, setIsAnimationPlaying] = useState(false);

  // Redux variables
  const country = useSelector((state) => state.signup.country);
  const phone = useSelector((state) => state.signup.phone);
  const mobileVerificationTimer = useSelector(
    (state) => state.verification.mobileVerificationTimer
  );
  const mobileCallVerificationTimer = useSelector(
    (state) => state.verification.mobileCallVerificationTimer
  );
  const mobileVerificationRequired = useSelector(
    (state) => state.verification.mobileVerificationRequired
  );
  const mobileVerificationMethod = useSelector(
    (state) => state.verification.mobileVerificationMethod
  );
  const mobileVerificationShowAlternateMethod = useSelector(
    (state) => state.verification.mobileVerificationShowAlternateMethod
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getAsset = AssetManager();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getUserAio(null, false);
    dispatch(updateMobileVerificationShowAlternateMethod(false));

    if (
      mobileVerificationMethod ===
      verificationConst.mobileVerificationMethod.sms
    ) {
      onMobileVerificationTimer();
    } else if (
      mobileVerificationMethod ===
      verificationConst.mobileVerificationMethod.call
    ) {
      onMobileCallVerificationTimer();
    }

    return () => {
      dispatch(setMobileVerificationTimer(0));
      dispatch(clearMobileVerificationInterval());
      dispatch(setMobileCallVerificationTimer(0));
      dispatch(clearMobileCallVerificationInterval());
      dispatch(updateMobileVerificationShowAlternateMethod(false));
    };
  }, []);

  // Lifecycle | Check for update | Initiate Mobile Verification API Response
  useEffect(() => {
    if (initiateMobileVerificationLoading) {
    } else if (initiateMobileVerificationSuccess) {
      switch (initiateMobileVerificationData?.status) {
        case 1:
          const createdAt =
            initiateMobileVerificationData?.data?.mobile_verification
              ?.created_at;
          const expiredAt =
            initiateMobileVerificationData?.data?.mobile_verification
              ?.expired_at;
          const diffInSeconds = moment(expiredAt).diff(createdAt, "seconds");

          if (
            mobileVerificationMethod ===
            verificationConst.mobileVerificationMethod.sms
          ) {
            dispatch(setMobileVerificationTimer(60));

            setTimeout(() => {
              onMobileVerificationTimer();
            }, 1000);
          } else if (
            mobileVerificationMethod ===
            verificationConst.mobileVerificationMethod.call
          ) {
            dispatch(setMobileCallVerificationTimer(60));

            setTimeout(() => {
              onMobileCallVerificationTimer();
            }, 1000);
          }

          break;
        case -1:
          // Need to wait 5 minutes
          const errorToast = {
            message: initiateMobileVerificationData?.message,
            autoClose: 3000,
          };
          dispatch(updateErrorToast(errorToast));
          break;
        default:
          // Not sure
          break;
      }
    } else if (initiateMobileVerificationError) {
      const errorToast = {
        message: initiateMobileVerificationErrorData?.data?.message,
        autoClose: 3000,
      };
      dispatch(updateErrorToast(errorToast));
    }
  }, [
    initiateMobileVerificationLoading,
    initiateMobileVerificationSuccess,
    initiateMobileVerificationError,
  ]);

  // Lifecycle | Check for update | Verify Mobile Verification API Response
  useEffect(() => {
    if (verifyMobileVerificationLoading) {
    } else if (verifyMobileVerificationSuccess) {
      switch (verifyMobileVerificationData?.status) {
        case 0:
        // Not sure yet
        case 1:
          const verificationData = {
            email: {
              verified: true,
              verification_display: false,
              verification_required: true,
            },
            mobile: {
              verified: true,
              verification_display: false,
              verification_required: true,
            },
          };
          dispatch(updateVerificationDataPassthrough(verificationData));

          dispatch(updateVerificationStatusDialog(true));

          setTimeout(() => {
            dispatch(updateVerificationStatusDialog(false));
            dispatch(updateJourneyNavigationPassthrough({}));
          }, 2500);
          break;
        case -1:
          // Mismatched Codes
          setInvalidCode(true);
          setOtp("");

          const errorToast = {
            message: verifyMobileVerificationData?.message,
            autoClose: 3000,
          };
          dispatch(updateErrorToast(errorToast));
          break;
        default:
          break;
      }
    } else if (verifyMobileVerificationError) {
      const errorToast = {
        message: verifyMobileVerificationErrorData?.data?.message,
        autoClose: 3000,
      };
      dispatch(updateErrorToast(errorToast));
    }
  }, [
    verifyMobileVerificationLoading,
    verifyMobileVerificationSuccess,
    verifyMobileVerificationError,
  ]);

  // Lifecycle | Check for update | Initiate Manual Verification API Response
  useEffect(() => {
    if (initiateManualVerificationLoading) {
    } else if (initiateManualVerificationSuccess) {
      switch (initiateManualVerificationData?.status) {
        case 1:
          onNavigate(routeConst.verify.customerSupportInfo.mobile.path);
          break;
        case -1:
          const infoToastObj = {
            message: t("verification.already_contacted_customer_support"),
            autoClose: 3000,
          };
          dispatch(updateInfoToast(infoToastObj));
          break;
        default:
          const warningObj = {
            message: t("verification.already_contacted_customer_support"),
            autoClose: 3000,
          };
          dispatch(updateInfoToast(warningObj));
          break;
      }
    } else if (initiateManualVerificationError) {
      const warningObj = {
        message: t("verification.already_contacted_customer_support"),
        autoClose: 3000,
      };
      dispatch(updateInfoToast(warningObj));
    }
  }, [
    initiateManualVerificationLoading,
    initiateManualVerificationSuccess,
    initiateManualVerificationError,
  ]);

  // Lifecycle | Check for update | mobileVerificationTimer
  useEffect(() => {
    if (mobileVerificationTimer && mobileVerificationTimer <= 0) {
      dispatch(clearMobileVerificationInterval());
    }
  }, [mobileVerificationTimer]);

  useEffect(() => {
    if (mobileCallVerificationTimer && mobileCallVerificationTimer <= 0) {
      dispatch(clearMobileCallVerificationInterval());
    }
  }, [mobileCallVerificationTimer]);

  // Event Handlers | Button
  const onVerifyMobile = () => {
    if (otp?.length !== verificationConst.mobileOtpMaxLength) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-60-60.1-button",
      },
    });

    const obj = {
      code: otp,
    };
    verifyMobileVerification(obj);
  };
  const onResendCode = (otpMethod) => {
    if (mobileVerificationTimer > 0 || mobileCallVerificationTimer > 0) return;

    TagManager.dataLayer({
      dataLayer: {
        event: `PWA-60-60.2-${otpMethod}-button`,
      },
    });

    const obj = {
      mobile_number: phone,
      mobile_country_code: country?.phone_country_code,
      country_iso_code: country?.country_iso_code,
      voice: otpMethod === verificationConst.mobileVerificationMethod.call,
    };

    initiateMobileVerification(obj);
  };
  const onUpdateMobile = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-60-60.3-button",
      },
    });

    if (type === utilityConst.fillingDetails.signup.type) {
      onNavigate(routeConst.phoneAuthentication.path);
    } else if (type === utilityConst.fillingDetails.missingDetails.type) {
      onNavigate(routeConst.phoneAuthentication.altPath);
    }
  };
  const onSwapVerificationMethod = (otpMethod) => {
    if (mobileVerificationTimer > 30 || mobileCallVerificationTimer > 30)
      return;

    TagManager.dataLayer({
      dataLayer: {
        event: `PWA-60-60.4-${otpMethod}-button`,
      },
    });

    dispatch(updateMobileVerificationMethod(otpMethod));
    dispatch(setMobileVerificationTimer(0));
    dispatch(clearMobileVerificationInterval());
    dispatch(setMobileCallVerificationTimer(0));
    dispatch(clearMobileCallVerificationInterval());
    dispatch(updateMobileVerificationShowAlternateMethod(false));

    const obj = {
      mobile_number: phone,
      mobile_country_code: country?.phone_country_code,
      country_iso_code: country?.country_iso_code,
      voice: otpMethod === verificationConst.mobileVerificationMethod.call,
    };

    initiateMobileVerification(obj);
  };
  const onContactCustomerSupport = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-60-60.5-button",
      },
    });

    const obj = {
      type: verificationConst.manualVerificationType.mobile,
      remarks: "Not receiving OTP on mobile number",
    };
    initiateManualVerification(obj);
  };
  const onNextPage = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-60-60.6-button",
      },
    });

    if (type === utilityConst.fillingDetails.signup.type) {
      onNavigate(routeConst.infoSignup.path);
    } else if (type === utilityConst.fillingDetails.missingDetails.type) {
      onNavigate(routeConst.search.path);
    }
  };

  // Utility Functions
  const onMobileVerificationTimer = () => {
    dispatch(
      updateMobileVerificationInterval(
        setInterval(() => {
          dispatch(updateMobileVerificationTimer());
        }, 1000)
      )
    );
  };
  const onMobileCallVerificationTimer = () => {
    dispatch(
      updateMobileCallVerificationInterval(
        setInterval(() => {
          dispatch(updateMobileCallVerificationTimer());
        }, 1000)
      )
    );
  };
  const handleVerificationCodeChange = (e) => {
    const input = e.target.value;
    const numericInput = input.replace(/\D/g, "");
    setOtp(numericInput);
  };

  return (
    <div id="mobile-verification-page">
      <div className="max-width-container">
        <div className="padding-container">
          <div className="navbar-container">
            <div className="back-button" onClick={() => onNavigate(-1)}>
              {getIcon("signupBackIcon", "signup-back-icon")}
            </div>
          </div>

          <div className="top-container">
            <div className="mobile-verification-top-container">
              <div className="enter-verification-label">
                <Trans
                  i18nKey={"verification.great_check_your_phone"}
                  components={{ br: <br /> }}
                />
              </div>

              <div className="code-sent-label">
                <Trans
                  i18nKey={"verification.your_code_has_been_sent_to_mobile"}
                  values={{
                    mobile: `${country?.phone_country_code}${phone || "-"}`,
                  }}
                  components={{
                    span: <span className="mobile-label" />,
                  }}
                />
              </div>

              <div className="update-mobile-label" onClick={onUpdateMobile}>
                {t("verification.update_number")}
              </div>
            </div>

            <div className="mobile-verification-middle-container">
              <div className="code-input-container">
                <input
                  className="mobile-code-input"
                  maxLength={verificationConst.mobileOtpMaxLength}
                  value={otp}
                  onChange={handleVerificationCodeChange}
                />
              </div>

              {invalidCode && (
                <div className="invalid-code-label">
                  {t("verification.this_code_is_invalid")}
                </div>
              )}

              <div className="resend-code-button-container">
                <div className="resend-code-description">
                  {t("verification.did_not_receive_the_verification_code")}
                </div>
                {initiateMobileVerificationLoading ? (
                  <Spinner size={20} isPadding={false} />
                ) : mobileVerificationMethod ===
                    verificationConst.mobileVerificationMethod.sms &&
                  mobileVerificationTimer > 0 ? (
                  <div
                    className="resend-code-label-container"
                    onClick={() =>
                      onResendCode(
                        verificationConst.mobileVerificationMethod.sms
                      )
                    }
                  >
                    <Trans
                      className={`resend-code-label ${
                        mobileVerificationTimer > 0 ? "disable-button" : ""
                      }`}
                      i18nKey={"verification.resend_code_in_n_sec"}
                      values={{ n: mobileVerificationTimer }}
                      components={{
                        span: <span className="resend-countdown-label" />,
                        div: (
                          <div
                            className={`resend-code-label ${
                              mobileVerificationTimer > 0
                                ? "disable-button"
                                : ""
                            }`}
                          />
                        ),
                      }}
                    />
                  </div>
                ) : mobileVerificationMethod ===
                    verificationConst.mobileVerificationMethod.sms &&
                  mobileVerificationTimer <= 0 ? (
                  <div
                    className="resend-code-label-container"
                    onClick={() =>
                      onResendCode(
                        verificationConst.mobileVerificationMethod.sms
                      )
                    }
                  >
                    <div
                      className={`resend-code-label ${
                        mobileVerificationTimer > 0 ||
                        mobileCallVerificationTimer > 0
                          ? "disable-buton"
                          : ""
                      }`}
                    >
                      {t("verification.resend_code")}
                    </div>
                  </div>
                ) : mobileVerificationMethod ===
                  verificationConst.mobileVerificationMethod.call ? (
                  <div
                    className="resend-code-label-container"
                    onClick={() =>
                      onResendCode(
                        verificationConst.mobileVerificationMethod.call
                      )
                    }
                  >
                    <div
                      className={`resend-code-label ${
                        mobileCallVerificationTimer > 0 ||
                        mobileVerificationTimer > 0
                          ? "disable-button"
                          : ""
                      }`}
                    >
                      {t("verification.get_a_new_call")}
                    </div>
                  </div>
                ) : null}
              </div>

              {mobileVerificationShowAlternateMethod && (
                <div className="mobile-verification-or-container">
                  <Divider className="or-divider" />
                  <div className="or-label">{t("verification.or")}</div>
                  <Divider className="or-divider" />
                </div>
              )}

              {mobileVerificationShowAlternateMethod &&
              mobileVerificationMethod ===
                verificationConst.mobileVerificationMethod.sms ? (
                <div
                  className={`phone-call-button ${
                    mobileVerificationTimer > 30 ? "disable-button" : ""
                  }`}
                  onClick={() =>
                    onSwapVerificationMethod(
                      verificationConst.mobileVerificationMethod.call
                    )
                  }
                >
                  {t("verification.verify_via_phone_call")}
                </div>
              ) : mobileVerificationShowAlternateMethod &&
                mobileVerificationMethod ===
                  verificationConst.mobileVerificationMethod.call ? (
                <div
                  className={`phone-call-button ${
                    mobileCallVerificationTimer > 30 ? "disable-button" : ""
                  }`}
                  onClick={() =>
                    onSwapVerificationMethod(
                      verificationConst.mobileVerificationMethod.sms
                    )
                  }
                >
                  {t("verification.verify_via_sms")}
                </div>
              ) : null}

              {mobileVerificationMethod ===
                verificationConst.mobileVerificationMethod.call &&
                mobileCallVerificationTimer > 0 && (
                  <div className="incoming-call-container">
                    <div className="incoming-call-icon-container">
                      {getIcon("mobileCallIcon", "incoming-call-icon")}
                    </div>

                    <div className="incoming-call-label">
                      {t("verification.incoming_call_from")}{" "}
                      {getUserAio?.data?.verifications?.call_from_number ||
                        "+60162869935"}
                    </div>

                    <i className="incoming-call-seconds-label">
                      ({mobileCallVerificationTimer || 0}{" "}
                      {t("verification.sec")})
                    </i>
                  </div>
                )}
            </div>
          </div>

          <div className="bottom-container">
            <div className="mobile-verification-bottom-container">
              <div
                className={`submit-button ${
                  otp?.length !== verificationConst.mobileOtpMaxLength
                    ? "disable-submit-button"
                    : ""
                }`}
                onClick={onVerifyMobile}
              >
                {verifyMobileVerificationLoading ? (
                  <Spinner
                    size={21}
                    isPadding={false}
                    color={"white-spinner"}
                  />
                ) : (
                  t("common.submit")
                )}
              </div>

              {mobileVerificationRequired === false && (
                <div className="skip-button" onClick={onNextPage}>
                  {t("signup.skip_for_now")}
                </div>
              )}

              <div className="mobile-verification-utility-container">
                <div className="contact-support-button-container">
                  <div className="need-help-label">
                    {/* {t("verification.dont_have_access_to_this_number")} */}
                    {t("verification.need_help")}
                  </div>
                  <div
                    className="contact-us-label"
                    onClick={onContactCustomerSupport}
                  >
                    {t("verification.contact_us")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isAnimationPlaying && (
          <div className="lottie-overlay-container">
            <Lottie
              className="general-success-lottie"
              animationData={getAsset("generalSuccessLottie")}
              autoPlay={true}
              loop={false}
            />

            <div className="verification-status-label">
              <Trans
                i18nKey={"verification.verification_successful"}
                components={{ br: <br /> }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileVerification;
