// General
import "./phone-authentication.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetAioQuery,
  useInitiateMobileVerificationMutation,
  useInitiateManualVerificationMutation,
} from "../../../services/data.service";
// Static Data
import routeConst from "../../../const/routeConst";
import verificationConst from "../../../const/verificationConst";
import utilityConst from "../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateCountry, updatePhone } from "../../../redux/store/signupStore";
import {
  setMobileCallVerificationTimer,
  setMobileVerificationTimer,
  updateMobileVerificationMethod,
} from "../../../redux/store/verificationStore";
import {
  updateInfoToast,
  updateErrorToast,
} from "../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Box, TextField, Avatar, MenuItem } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Moment
import moment from "moment";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../shared/elements/spinner/spinner";

const PhoneAuthentication = (props) => {
  const { type = utilityConst.fillingDetails.signup.type } = props;

  // API variables
  const [
    getAio,
    {
      data: getAioData,
      error: getAioErrorData,
      isFetching: getAioFetching,
      isLoading: getAioLoading,
      isSuccess: getAioSuccess,
      isError: getAioError,
    },
  ] = useLazyGetAioQuery();
  const [
    initiateMobileVerification,
    {
      data: initiateMobileVerificationData,
      error: initiateMobileVerificationErrorData,
      isLoading: initiateMobileVerificationLoading,
      isSuccess: initiateMobileVerificationSuccess,
      isError: initiateMobileVerificationError,
    },
  ] = useInitiateMobileVerificationMutation();
  const [
    initiateManualVerification,
    {
      data: initiateManualVerificationData,
      error: initiateManualVerificationErrorData,
      isLoading: initiateManualVerificationLoading,
      isSuccess: initiateManualVerificationSuccess,
      isError: initiateManualVerificationError,
    },
  ] = useInitiateManualVerificationMutation();

  // General variables
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("");
  const [phoneFocus, setPhoneFocus] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [numberExist, setNumberExist] = useState(false);

  // Redux variables
  const country = useSelector((state) => state.signup.country);
  const phone = useSelector((state) => state.signup.phone);
  const mobileVerificationMethod = useSelector(
    (state) => state.verification.mobileVerificationMethod
  );

  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getAio(null, true);
  }, []);

  // Lifecycle | Check for update | AIO API Response
  useEffect(() => {
    if (getAioFetching || getAioLoading) {
    } else if (getAioSuccess) {
      dispatch(updateCountry(getAioData?.payload?.default_country_data));
    } else if (getAioError) {
    }
  }, [getAioFetching, getAioLoading, getAioSuccess, getAioError]);

  // Lifecycle | Check for update | Initiate Mobile Verification API Response
  useEffect(() => {
    if (initiateMobileVerificationLoading) {
    } else if (initiateMobileVerificationSuccess) {
      let errorToast = {};
      switch (initiateMobileVerificationData?.status) {
        case 1:
          setNumberExist(false);

          const createdAt =
            initiateMobileVerificationData?.data?.mobile_verification
              ?.created_at;
          const expiredAt =
            initiateMobileVerificationData?.data?.mobile_verification
              ?.expired_at;
          const diffInSeconds = moment(expiredAt).diff(createdAt, "seconds");

          if (
            mobileVerificationMethod ===
            verificationConst.mobileVerificationMethod.sms
          ) {
            dispatch(setMobileVerificationTimer(60));
          } else if (
            mobileVerificationMethod ===
            verificationConst.mobileVerificationMethod.call
          ) {
            dispatch(setMobileCallVerificationTimer(60));
          }

          if (type === utilityConst.fillingDetails.signup.type) {
            onNavigate(routeConst.verify.mobileVerification.path);
          } else if (type === utilityConst.fillingDetails.missingDetails.type) {
            onNavigate(routeConst.verify.mobileVerification.altPath);
          }
          break;
        case -1:
          // General error
          errorToast = {
            message: initiateMobileVerificationData?.message,
            autoClose: 5000,
          };
          dispatch(updateErrorToast(errorToast));
          break;
        case -2:
          // Mobile number exist
          setNumberExist(true);

          errorToast = {
            message: initiateMobileVerificationData?.message,
            autoClose: 5000,
          };
          // dispatch(updateErrorToast(errorToast));
          break;
        default:
          break;
      }
    } else if (initiateMobileVerificationError) {
      const errorToast = {
        message: initiateMobileVerificationErrorData?.data?.message,
        autoClose: 3000,
      };
      dispatch(updateErrorToast(errorToast));
    }
  }, [
    initiateMobileVerificationLoading,
    initiateMobileVerificationSuccess,
    initiateMobileVerificationError,
  ]);

  // Lifecycle | Check for update | Initiate Manual Verification API Response
  useEffect(() => {
    if (initiateManualVerificationLoading) {
    } else if (initiateManualVerificationSuccess) {
      switch (initiateManualVerificationData?.status) {
        case 1:
          onNavigate(routeConst.verify.customerSupportInfo.mobile.path);
          break;
        case -1:
          const infoToastObj = {
            message: t("verification.already_contacted_customer_support"),
            autoClose: 3000,
          };
          dispatch(updateInfoToast(infoToastObj));
          break;
        default:
          const warningObj = {
            message: t("verification.already_contacted_customer_support"),
            autoClose: 3000,
          };
          dispatch(updateInfoToast(warningObj));
          break;
      }
    } else if (initiateManualVerificationError) {
      const infoToastObj = {
        message: initiateManualVerificationErrorData?.message,
        autoClose: 3000,
      };
      dispatch(updateInfoToast(infoToastObj));
    }
  }, [
    initiateManualVerificationLoading,
    initiateManualVerificationSuccess,
    initiateManualVerificationError,
  ]);

  // Event Handlers | Button
  const onMobileVerification = (otpMethod) => {
    if (initiateMobileVerificationLoading) return;

    if (phone?.length > 5) {
      TagManager.dataLayer({
        dataLayer: {
          event: `PWA-59-59.1-${otpMethod}-button`,
        },
      });

      dispatch(updateMobileVerificationMethod(otpMethod));

      // Backend has implemented hard code to bypass
      // if (phone === "123456789") {
      //   onNavigate(routeConst.verify.mobileVerification.path);
      //   return;
      // }

      const obj = {
        mobile_number: phone,
        mobile_country_code: country?.phone_country_code,
        country_iso_code: country?.country_iso_code,
        voice: otpMethod === verificationConst.mobileVerificationMethod.call,
      };

      initiateMobileVerification(obj);
    }
  };
  const onContactCustomerSupport = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-59-59.2-button",
      },
    });

    const obj = {
      type: verificationConst.manualVerificationType.mobile,
      remarks: initiateMobileVerificationData?.message,
    };
    initiateManualVerification(obj);
  };

  // Event Handlers | MUI Text Fields
  const onCountryChange = (event) => {
    dispatch(updateCountry(event.target.value));
  };
  const onPhoneChange = (event) => {
    if (isNaN(event.target.value)) return;

    setPhoneError(false);
    setPhoneErrorMessage("");

    dispatch(updatePhone(event.target.value.trim().toLowerCase()));
  };
  const onPhoneFocus = (event) => {
    setPhoneFocus(true);
  };

  // Utility Functions
  const isFormValid = () => {
    let valid = false;
    if (phone?.length > 5 && phone?.length < 16) {
      valid = true;
    }

    return valid;
  };

  return (
    <Box
      id="phone-authentication-page"
      component="form"
      noValidate
      autoComplete="off"
    >
      <div className="max-width-container">
        <div className="navbar-container"></div>

        <div className="top-container">
          <div className="create-account-label">
            {t("signup.verify_your_phone_number")}
          </div>

          <div className="phone-container">
            <div className="country-form-field-container">
              <TextField
                sx={{ "& .MuiSelect-select": { marginTop: "16px" } }}
                className="country-form-field"
                select
                value={country}
                onChange={onCountryChange}
                variant="standard"
                SelectProps={{
                  renderValue: (value) => value?.phone_country_code,
                }}
              >
                {getAioData?.payload?.country_list_with_iso_code?.map(
                  (country, index) => (
                    <MenuItem key={index} value={country}>
                      <div className="select-output">
                        <div className="flag-icon-container">
                          <Avatar
                            className="flag-icon"
                            src={country?.country_flag}
                            alt={country?.country_name}
                          />
                        </div>
                        <div className="country-name">
                          {country?.country_name}
                        </div>
                        <div className="country-name">
                          {country?.phone_country_code}
                        </div>
                      </div>
                    </MenuItem>
                  )
                )}
              </TextField>
            </div>

            <div className="phone-form-field-container">
              <TextField
                className="phone-form-field"
                required
                value={phone}
                error={phoneError}
                onChange={onPhoneChange}
                // label={t("login.mobile_number")}
                placeholder={t("login.mobile_number")}
                helperText={phoneErrorMessage}
                variant="standard"
                onFocus={onPhoneFocus}
              />
            </div>
          </div>

          {numberExist && (
            <div className="number-exist-container">
              <div className="caution-icon-container">
                {getIcon("redCautionIcon", "caution-icon")}
              </div>

              <div className="number-exist-description">
                {t("signup.duplicate_phone")}
              </div>
            </div>
          )}

          {numberExist ? (
            <div className="terms-and-condition">
              {t("signup.duplicate_phone_desc")}
            </div>
          ) : (
            <div className="terms-and-condition">
              {t("signup.duplicate_phone_contact_customer_support_desc")}
            </div>
          )}
        </div>

        <div className="bottom-container">
          <div
            className={`next-button ${isFormValid() ? "" : "disabled-button"}`}
            onClick={() =>
              onMobileVerification(
                verificationConst.mobileVerificationMethod.sms
              )
            }
          >
            {initiateMobileVerificationLoading ? (
              <Spinner size={22} isPading={false} color={"white-spinner"} />
            ) : (
              t("verification.verify_via_sms")
            )}
          </div>

          <div
            className={`next-button ${isFormValid() ? "" : "disabled-button"}`}
            onClick={() =>
              onMobileVerification(
                verificationConst.mobileVerificationMethod.call
              )
            }
          >
            {initiateMobileVerificationLoading ? (
              <Spinner size={22} isPading={false} color={"white-spinner"} />
            ) : (
              t("verification.verify_via_phone_call")
            )}
          </div>

          {numberExist && (
            <div className="skip-button" onClick={onContactCustomerSupport}>
              {initiateManualVerificationLoading ? (
                <Spinner size={22} isPading={false} color={"white-spinner"} />
              ) : (
                t("verification.contact_us")
              )}
            </div>
          )}
        </div>
      </div>
    </Box>
  );
};

export default PhoneAuthentication;
