// General
import "./default-tag.scss";

const DefaultTag = (props) => {
  const {
    torName,
    blur,
    match,
    unselectedBackgroundColor = "white",
    selectedBackgroundColor = "#f4ecec",
    unselectedTextColor = "#710d0d",
    selectedTextColor = "#710d0d",
    unselectedBorderColor = "rgba(113, 13, 13, .2)",
    selectedBorderColor = "rgba(113, 13, 13, .2)",
    borderWidth = "2px",
    fontSize = "0.9em",
    fontWeight = "500",
  } = props;

  return (
    <div
      id="tor-tag-default-tag-subcomponent"
      className={`${blur ? "blur" : ""} ${
        match ? "default-matched" : "default-unmatched"
      }`}
      style={{
        borderWidth: borderWidth,
        borderColor: match ? selectedBorderColor : unselectedBorderColor,
        fontWeight: fontWeight,
        fontSize: fontSize,
        backgroundColor: match
          ? selectedBackgroundColor
          : unselectedBackgroundColor,
      }}
    >
      <div
        className="tor-name"
        style={{ color: match ? selectedTextColor : unselectedTextColor }}
      >
        {torName}
      </div>
    </div>
  );
};

export default DefaultTag;
