// General
import "./thank-you-for-review-dialog.scss";
import { useEffect } from "react";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateThankYouForReviewDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const ThankYouForReviewDialog = () => {
  // Redux variables
  const thankYouForReviewDialog = useSelector(
    (state) => state.dialog.thankYouForReviewDialog
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!thankYouForReviewDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-D23",
      },
    });
  }, [thankYouForReviewDialog]);

  // Event Handler | MUI Dialog
  const onCloseDialog = () => {
    onNavigate(routeConst.live.path);
    dispatch(updateThankYouForReviewDialog(false));
  };

  return (
    <Dialog
      className="custom-radius35-dialog"
      open={thankYouForReviewDialog}
      onClose={onCloseDialog}
    >
      <div id="thank-you-for-review-dialog">
        <div className="padding-container">
          <div className="action-success-icon-container">
            {getIcon("actionSuccessIcon", "action-success-icon")}
          </div>

          <div className="thank-you-label">
            {t("1on1.thank_you_for_review")}
          </div>

          <div className="close-button" onClick={onCloseDialog}>
            {t("common.close")}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ThankYouForReviewDialog;
