// General
import "./joining-element.scss";
// Static Data
import livestreamingConst from "../../../../../const/livestreamingConst";
// i18next
import { useTranslation } from "react-i18next";
// Components
import CustomAvatar from "../../custom-avatar/custom-avatar";

const JoiningElement = (props) => {
  const {
    livestreamingAchievementBadge,
    profilePhoto,
    profileDecorations,
    username,
    levellingBadge,

    // Style
    borderColor = livestreamingConst.config.joiningElement.borderColor,
    font = livestreamingConst.config.joiningElement.font,
    textColor = livestreamingConst.config.joiningElement.mobileFontColor,
    backgroundColor = "none",
  } = props;

  // i18next variables
  const { t } = useTranslation();

  return (
    <div
      id="chat-section-joining-element-subcomponent"
      style={{
        background: backgroundColor,
        border: borderColor !== "none" ? `1px solid ${borderColor}` : "none",
      }}
    >
      <div className="profile-picture-container">
        <CustomAvatar
          className="profile-picture"
          src={profilePhoto}
          profileBorder={profileDecorations?.profile_border_url}
        />
      </div>

      <div className="chat-info-container">
        <div className="chat-info">
          {livestreamingAchievementBadge?.length > 0 && (
            <span className="livestreaming-achievement-badge-container">
              {livestreamingAchievementBadge?.map((badge, index) => (
                <img
                  className="livestreaming-achievement-badge"
                  src={badge?.url}
                  alt={badge?.type}
                  key={index}
                />
              ))}
            </span>
          )}

          <span className="username" style={{ font: font, color: textColor }}>
            {username || "-"}
          </span>

          {levellingBadge && (
            <span className="levelling-badge-container">
              <img
                className="levelling-badge"
                src={levellingBadge}
                alt="levelling-badge"
              />
            </span>
          )}

          <span
            className="joined-text"
            style={{ font: font, color: textColor }}
          >
            {t("pusher.joined")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default JoiningElement;
