// General
import "./go-private-dont-switch-app-dialog.scss";
import { useEffect } from "react";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateGoPrivateChecklistDialog,
  updateGoPrivateDontSwitchAppDialog,
} from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const GoPrivateDontSwitchAppDialog = () => {
  // Redux variables
  const goPrivateDontSwitchAppDialog = useSelector(
    (state) => state.dialog.goPrivateDontSwitchAppDialog
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!goPrivateDontSwitchAppDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-GoPrivateDontSwitchApp-Dialog",
      },
    });
  }, [goPrivateDontSwitchAppDialog]);

  // Event Handlers | Button
  const onPrivateStandbyPage = () => {
    onCloseDialog();
    onOpenChecklistDialog();

    // onNavigate(routeConst.videoCall.privateStandby.path); // Use this if you want to go to private standby page
  };
  const onOpenChecklistDialog = () => {
    // Not using based on business decision
    onCloseDialog();
    dispatch(updateGoPrivateChecklistDialog(true));
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateGoPrivateDontSwitchAppDialog(false));
  };

  return (
    <Dialog
      className="custom-radius35-dialog"
      open={goPrivateDontSwitchAppDialog}
      onClose={onCloseDialog}
    >
      <div id="go-private-dont-switch-app-dialog">
        <div className="dialog-header">
          <div className="close-button-container">
            <CloseIcon className="close-button" onClick={onCloseDialog} />
          </div>
        </div>

        <div className="padding-container">
          <div className="alert-icon-container">
            {getIcon("actionAlertIcon", "alert-icon")}
          </div>

          <div className="title">
            <Trans
              i18nKey="1on1.reminder_title"
              components={{ br: <br />, u: <u /> }}
            />
          </div>

          <div className="description">
            <Trans
              i18nKey="1on1.reminder_desc"
              components={{
                diamonds: getIcon("sbDiamondIcon", "diamond-icon"),
              }}
            />
          </div>

          <div className="description">{t("1on1.reminder_desc_2")}</div>

          <div className="proceed-button" onClick={onPrivateStandbyPage}>
            {t("common.got_it")}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default GoPrivateDontSwitchAppDialog;
