// General
import "./upgrade-plans.scss";
import { useEffect } from "react";
// Services
import { useLazyGetPwaPaymentsQuery } from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateSelectedUpgradePackage,
  updateDiscretionDescription,
  updateCreditCardNoteDescription,
} from "../../../../redux/store/upgradeStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../../shared/elements/spinner/spinner";

const UpgradePlans = () => {
  // API variables
  const [
    getPwaPayments,
    {
      data: getPwaPaymentsData,
      error: getPwaPaymentsErrorData,
      isFetching: getPwaPaymentsFetching,
      isLoading: getPwaPaymentsLoading,
      isSuccess: getPwaPaymentsSuccess,
      isError: getPwaPaymentsError,
    },
  ] = useLazyGetPwaPaymentsQuery();

  // Redux variables
  const selectedUpgradePackage = useSelector(
    (state) => state.upgrade.selectedUpgradePackage
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getPwaPayments(null, true);
  }, []);

  // Lifecycle | Check for update | Pwa Payments API Response
  useEffect(() => {
    if (getPwaPaymentsSuccess) {
      if (getPwaPaymentsData?.status === 0) {
        dispatch(
          updateDiscretionDescription(
            getPwaPaymentsData?.data?.copywriting?.descriptor
          )
        );
        dispatch(
          updateCreditCardNoteDescription(
            getPwaPaymentsData?.data?.copywriting?.permission
          )
        );

        // Select most popular package on mount
        getPwaPaymentsData?.data?.payment_packages.forEach((paymentPackage) => {
          if (paymentPackage?.is_selected) {
            dispatch(updateSelectedUpgradePackage(paymentPackage));
          }
        });
      }
    }
  }, [getPwaPaymentsSuccess]);

  // Event Handlers | Button
  const onSelectPlan = (paymentPackage) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-53-53.2-Button",
      },
    });

    dispatch(updateSelectedUpgradePackage(paymentPackage));
  };

  // Render Functions | API Response
  if (getPwaPaymentsFetching || getPwaPaymentsLoading) {
    return <Spinner />;
  } else if (getPwaPaymentsSuccess) {
    return (
      <div id="upgrade-upgrade-plans-subcomponent">
        <div className="max-width-container">
          <div className="padding-container">
            <div className="plans-container">
              {getPwaPaymentsData?.data?.payment_packages?.map(
                (paymentPackage, index) => (
                  <div
                    className={`plan-container ${
                      selectedUpgradePackage?.product_hash ===
                      paymentPackage?.product_hash
                        ? "selected-package"
                        : !selectedUpgradePackage && paymentPackage?.is_selected
                        ? "selected-package"
                        : ""
                    }`}
                    key={index}
                    onClick={() => onSelectPlan(paymentPackage)}
                  >
                    {paymentPackage?.is_popular && (
                      <div className="most-popular-tag">
                        {t("upgrade.most_popular")}
                      </div>
                    )}

                    <div className="save-percentage-label">
                      {t("upgrade.save_percent", {
                        percent: paymentPackage?.discount_percentage,
                      })}
                      %
                    </div>

                    <div className="number-of-months">
                      {paymentPackage?.period_length}
                    </div>
                    <div className="months-label">
                      {paymentPackage?.period_text}
                    </div>

                    <div className="discounted-price">
                      {paymentPackage?.display_string}
                    </div>

                    {false && (
                      <div className="original-price">
                        {paymentPackage?.original_amount}
                      </div>
                    )}
                    <div className="n-month-text">{t("upgrade.per_month")}</div>

                    {paymentPackage?.bonus_coins !== "0" && (
                      <div className="save-container">
                        <div className="bonus-container">
                          <div className="bonus-label">BONUS</div>
                        </div>

                        <div className="added-coins-value">
                          <span className="coin-icon-container">
                            {getIcon("sbCoinIcon", "coin-icon")}
                          </span>
                          +{paymentPackage?.bonus_coins}
                        </div>
                      </div>
                    )}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else if (getPwaPaymentsError) {
    if (getPwaPaymentsErrorData?.status === 401) {
      onNavigate(routeConst.logout.path);
    }
  }
};

export default UpgradePlans;
