// General
import "./edit-eye-color.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetJourneyQuestionsQuery,
  useUpdateEditUserProfileMutation,
} from "../../../../../services/data.service";
// Static Data
import utilityConst from "../../../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateEyeColor,
  updateUpdateProfileFullscreenTab,
  updateIsUnsavedChanges,
} from "../../../../../redux/store/editProfileStore";
import {
  updateProfileCompletionPercentage,
  updateProfileRequirementPopup,
} from "../../../../../redux/store/profileStore";
import { updateUpdateProfileFullscreenDialog } from "../../../../../redux/store/dialogStore";
// Material UI
import { useMediaQuery } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../../elements/spinner/spinner";

const EditEyeColor = (props) => {
  const { addPaddingTop = false } = props;

  // API variables
  const [
    getJourneyQuestions,
    {
      data: getJourneyQuestionsData,
      error: getJourneyQuestionsErrorData,
      isFetching: getJourneyQuestionsFetching,
      isLoading: getJourneyQuestionsLoading,
      isSuccess: getJourneyQuestionsSuccess,
      isError: getJourneyQuestionsError,
    },
  ] = useLazyGetJourneyQuestionsQuery();
  const [
    updateProfile,
    {
      data: updateProfileData,
      error: updateProfileErrorData,
      isLoading: updateProfileLoading,
      isSuccess: updateProfileSuccess,
      isError: updateProfileError,
    },
  ] = useUpdateEditUserProfileMutation();

  // General variables
  const [localEyeColor, setLocalEyeColor] = useState(null);

  // Redux variables
  const eyeColor = useSelector((state) => state.editProfile.eyeColor);
  const profileCompletionState = useSelector(
    (state) => state.editProfile.profileCompletionState
  );
  const updateProfileFullscreenTab = useSelector(
    (state) => state.editProfile.updateProfileFullscreenTab
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    setLocalEyeColor(eyeColor);

    getJourneyQuestions(null, true);
  }, []);

  // Lifecycle | Check for update | updateProfile API Response
  useEffect(() => {
    if (updateProfileLoading) {
    } else if (updateProfileSuccess) {
      dispatch(
        updateEyeColor(updateProfileData?.data?.user?.appearance?.eye_color)
      );
      dispatch(
        updateProfileCompletionPercentage(
          updateProfileData?.data?.user?.profile_progress?.summary?.progress
        )
      );
      dispatch(
        updateProfileRequirementPopup(
          updateProfileData?.data?.user?.profile_progress?.summary?.requirement
        )
      );
      dispatch(updateIsUnsavedChanges(false));

      if (profileCompletionState) {
        const height = updateProfileData?.data?.user?.appearance?.height;
        const bodyType = updateProfileData?.data?.user?.appearance?.body_type;
        const ethnicity = updateProfileData?.data?.user?.appearance?.ethnicity;
        const race = updateProfileData?.data?.user?.appearance?.race;
        const eyeColor = updateProfileData?.data?.user?.appearance?.eye_color;
        const hairColor = updateProfileData?.data?.user?.appearance?.hair_color;

        if (!height) {
          dispatch(updateUpdateProfileFullscreenTab(0));
        } else if (!bodyType) {
          dispatch(updateUpdateProfileFullscreenTab(1));
        } else if (!ethnicity) {
          dispatch(updateUpdateProfileFullscreenTab(2));
        } else if (!race) {
          dispatch(updateUpdateProfileFullscreenTab(2));
        } else if (!eyeColor) {
          dispatch(updateUpdateProfileFullscreenTab(3));
        } else if (!hairColor) {
          dispatch(updateUpdateProfileFullscreenTab(4));
        } else {
          dispatch(updateUpdateProfileFullscreenDialog(false));
        }
      } else {
        dispatch(updateUpdateProfileFullscreenDialog(false));
      }
    } else if (updateProfileError) {
    }
  }, [updateProfileLoading, updateProfileSuccess, updateProfileError]);

  // Lifecycle | Check for update | localEyeColor
  useEffect(() => {
    if (localEyeColor?.id !== eyeColor?.id) {
      dispatch(updateIsUnsavedChanges(true));
    } else {
      dispatch(updateIsUnsavedChanges(false));
    }
  }, [localEyeColor]);

  // Event Handlers | Button
  const onSelectEyeColor = (eyeColor) => {
    setLocalEyeColor(eyeColor);
  };
  const onSaveEyeColor = () => {
    if (!isFormValid()) return;

    const obj = {
      profile: {
        eye_color: localEyeColor?.id,
      },
    };
    updateProfile(obj);
  };

  // Utility Functions
  const isFormValid = () => {
    return localEyeColor && !updateProfileLoading;
  };
  const getEyeColorData = () => {
    return getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "eye_color"
    )?.selections;
  };

  return (
    <div id="eye-color-subcomponent">
      <div className="padding-container">
        <div
          className={`top-container ${
            addPaddingTop ? "add-edit-padding-top" : ""
          }`}
        >
          <div className="eye-color-icon-container">
            {getIcon("eyeColorIcon", "eye-color-icon")}
          </div>

          <div className="eye-color-title">
            {t("profile.whats_your_eye_color")}
          </div>

          {getJourneyQuestionsFetching || getJourneyQuestionsLoading ? (
            <Spinner />
          ) : (
            <div
              className={`eye-color-body-container ${
                isMobile &&
                getEyeColorData()?.length >
                  utilityConst.registrationItemFlexAmount
                  ? "flex-start"
                  : "justify-center"
              }`}
            >
              {getEyeColorData()?.map((item, index) => (
                <div
                  className={`eye-color-item ${
                    getEyeColorData()?.length <=
                    utilityConst.registrationItemFlexAmount
                      ? "partial-width"
                      : isMobile &&
                        getEyeColorData()?.length >
                          utilityConst.registrationItemFlexAmount
                      ? "fit-content"
                      : "fix-width"
                  } ${item?.id === localEyeColor?.id ? "selected" : ""}`}
                  onClick={() => onSelectEyeColor(item)}
                  key={index}
                >
                  {item?.display_locale}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="bottom-container">
        <div
          className={`save-button ${isFormValid() ? "" : "disabled-button"}`}
          onClick={onSaveEyeColor}
        >
          {updateProfileLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("common.save")
          )}
        </div>
      </div>
    </div>
  );
};

export default EditEyeColor;
