// General
import "./empty-list.scss";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import AssetManager from "../../../../utility/manager/asset-manager/asset-manager";

const EmptyList = () => {
  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getAsset = AssetManager();

  return (
    <div id="video-call-tab-empty-list-subcomponent">
      <div className="empty-live-list-image-container">
        <img
          className="empty-live-list-image"
          src={getAsset("emptyLiveList")}
          alt="empty"
        />
      </div>

      <div className="title">{t("search.empty_list_title")}</div>
      <div className="description">{t("search.empty_list_description")}</div>
    </div>
  );
};

export default EmptyList;
