// General
import "./casino-tab.scss";
import { useEffect } from "react";
// Services
import { useLazyGetGamingListQuery } from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateCasinoTab } from "../../../../redux/store/leaderboardStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// embla-carousel-react
import useEmblaCarousel from "embla-carousel-react";
// lottie-react
import Lottie from "lottie-react";
// Material UI
import { Tabs, Tab, Box } from "@mui/material";
import PropTypes from "prop-types";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import AssetManager from "../../../utility/manager/asset-manager/asset-manager";
// Components
import Spinner from "../../../shared/elements/spinner/spinner";
import GameListTab from "./game-list-tab/game-list-tab";

const CasinoTab = () => {
  // API variables
  const [
    getGamingList,
    {
      data: getGamingListData,
      error: getGamingListErrorData,
      isFetching: getGamingListFetching,
      isLoading: getGamingListLoading,
      isSuccess: getGamingListSuccess,
      isError: getGamingListError,
    },
  ] = useLazyGetGamingListQuery();

  // Redux variables
  const leaderboardTab = useSelector(
    (state) => state.leaderboard.leaderboardTab
  );
  const casinoTab = useSelector((state) => state.leaderboard.casinoTab);
  const dispatch = useDispatch();

  // Embla variables
  const [emblaRef, emblaApi] = useEmblaCarousel({
    duration: 15,
    watchDrag: false,
  });

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getAsset = AssetManager();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-19-19.3",
      },
    });

    getGamingList(null, true);
  }, []);

  // Lifecycle | Mounted | emblaApi
  useEffect(() => {
    if (!emblaApi) return;
    emblaApi?.scrollTo(casinoTab);

    emblaApi?.on("select", () => {
      dispatch(updateCasinoTab(emblaApi?.selectedScrollSnap()));
    });
  }, [emblaApi]);

  // Lifecycle | Check for update | casinoTab
  useEffect(() => {
    emblaApi?.scrollTo(casinoTab);
  }, [casinoTab]);

  // Event Handlers | MUI Tabs
  const onTabChange = (event, index) => {
    dispatch(updateCasinoTab(index));
  };

  // Render Functions | API Response
  if (getGamingListFetching || getGamingListLoading) {
    return <Spinner />;
  } else if (getGamingListSuccess) {
    if (getGamingListData?.status === 1) {
      return (
        <div id="leaderboard-casino-tab-subcomponent">
          {leaderboardTab === 2 && (
            <div id="casino-tab-lottie-container">
              <div className="lottie-center-container">
                <Lottie
                  animationData={getAsset("casinoLeaderboardLottie")}
                  autoPlay={true}
                  loop={false}
                />
              </div>
            </div>
          )}

          <div className="tab-container">
            <Tabs
              className="custom-tabs"
              value={casinoTab}
              onChange={onTabChange}
              TabIndicatorProps={{ style: { backgroundColor: "#710d0d" } }}
              centered
            >
              {getGamingListData?.data?.games?.map((game, index) => (
                <Tab label={game?.title} {...a11yProps(index)} key={index} />
              ))}
            </Tabs>

            <div ref={emblaRef} className="embla">
              <div className="embla__container">
                {getGamingListData?.data?.games?.map((game, index) => (
                  <div className="embla__slide" key={index}>
                    {casinoTab === index && (
                      <GameListTab gameId={game?.game_id} />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      );
    }
  } else if (getGamingListError) {
    if (getGamingListErrorData?.status === 401) {
      onNavigate(routeConst.logout.path);
    }
  }
};

export default CasinoTab;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }} className="tab-box-container">
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
