// General
import "./pk-graphic-overlay.scss";
import { useState, useEffect } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateDualPkShowResultsOverlay } from "../../../../redux/store/livestreamingStore";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const PkGraphicOverlay = () => {
  // General variables
  const [prePkTimer, setPrePkTimer] = useState(8);
  const [countdownInterval, setCountdownInterval] = useState(null);

  // Redux variables
  const coAnchorUser1 = useSelector(
    (state) => state.livestreaming.coAnchorUser1
  );
  const coAnchorUser2 = useSelector(
    (state) => state.livestreaming.coAnchorUser2
  );
  const dualPkData = useSelector((state) => state.livestreaming.dualPkData);
  const dualPkEnded = useSelector((state) => state.livestreaming.dualPkEnded);
  const dualPkShowCountdownTimer = useSelector(
    (state) => state.livestreaming.dualPkShowCountdownTimer
  );
  const dualPkShowResultsOverlay = useSelector(
    (state) => state.livestreaming.dualPkShowResultsOverlay
  );
  const dispatch = useDispatch();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Check for update | pkCountdown
  useEffect(() => {
    if (prePkTimer <= 0) {
      clearInterval(countdownInterval);
      setCountdownInterval(null);
      dispatch(updateDualPkShowResultsOverlay(false));
    }
  }, [prePkTimer]);

  // Lifecycle | Check for update | dualPkShowResultsOverlay
  useEffect(() => {
    if (!dualPkShowResultsOverlay) return;

    setPrePkTimer(8);
    setCountdownInterval(
      setInterval(() => {
        setPrePkTimer((prevCountdown) => prevCountdown - 1);
      }, 1000)
    );
  }, [dualPkShowResultsOverlay]);

  // Utility Style
  const getCountdownOverlay = () => {
    switch (dualPkShowCountdownTimer) {
      case 0:
      case 1:
        if (dualPkData?.currentRound === dualPkData?.rounds?.length) {
          return getIcon("dualPkFinalRoundIcon", "dual-pk-round-icon");
        } else if (dualPkData?.currentRound < dualPkData?.rounds?.length) {
          return getIcon(
            `dualPkRound${dualPkData?.currentRound}Icon`,
            "dual-pk-round-icon"
          );
        }
      case 2:
        return getIcon("dualPkCountdown3Icon", "dual-pk-countdown-3-icon");
      case 3:
        return getIcon("dualPkCountdown2Icon", "dual-pk-countdown-2-icon");
      case 4:
        return getIcon("dualPkCountdown1Icon", "dual-pk-countdown-1-icon");
      default:
        break;
    }
  };

  return (
    <div id="pk-graphic-overlay-subcomponent">
      {dualPkShowCountdownTimer > 0 && dualPkShowCountdownTimer <= 5 && (
        <div className="dual-pk-countdown-container">
          {getCountdownOverlay()}
        </div>
      )}

      {dualPkShowResultsOverlay &&
        ((!dualPkData?.currentRound && !dualPkData?.finalWinner) ||
          (dualPkData?.currentRound &&
            !dualPkData?.rounds[dualPkData?.currentRound - 1]?.winner)) && (
          <div className="dual-pk-draw-container">
            {getIcon("dualPkDrawEmojiIcon", "dual-pk-draw-emoji-icon")}
            {getIcon("dualPkDrawRoundIcon", "dual-pk-draw-icon")}
          </div>
        )}

      {dualPkShowResultsOverlay &&
        (dualPkData?.finalWinner ||
          dualPkData?.rounds[dualPkData?.currentRound - 1]?.winner) && (
          <div className="dual-pk-win-lose-container">
            <div className="dual-pk-emoji-container">
              <div className="dual-pk-emoji-icon-container">
                {(dualPkData?.finalWinner ||
                  dualPkData?.rounds[dualPkData?.currentRound - 1]?.winner) ===
                coAnchorUser1?.userId
                  ? getIcon("dualPkWinEmojiIcon", "dual-pk-win-emoji-icon")
                  : getIcon("dualPkLoseEmojiIcon", "dual-pk-lose-emoji-icon")}
                {(dualPkData?.finalWinner ||
                  dualPkData?.rounds[dualPkData?.currentRound - 1]?.winner) ===
                coAnchorUser1?.userId ? (
                  <div className="dual-pk-win-icon-container">
                    {getIcon("dualPkWinIcon", "dual-pk-win-icon")}
                  </div>
                ) : (
                  <div className="dual-pk-lose-icon-container">
                    {getIcon("dualPkLoseIcon", "dual-pk-lose-icon")}
                  </div>
                )}
              </div>

              <div className="dual-pk-emoji-icon-container">
                {(dualPkData?.finalWinner ||
                  dualPkData?.rounds[dualPkData?.currentRound - 1]?.winner) ===
                coAnchorUser2?.userId
                  ? getIcon("dualPkWinEmojiIcon", "dual-pk-win-emoji-icon")
                  : getIcon("dualPkLoseEmojiIcon", "dual-pk-lose-emoji-icon")}
                {(dualPkData?.finalWinner ||
                  dualPkData?.rounds[dualPkData?.currentRound - 1]?.winner) ===
                coAnchorUser2?.userId ? (
                  <div className="dual-pk-win-icon-container">
                    {getIcon("dualPkWinIcon", "dual-pk-win-icon")}
                  </div>
                ) : (
                  <div className="dual-pk-lose-icon-container">
                    {getIcon("dualPkLoseIcon", "dual-pk-lose-icon")}
                  </div>
                )}
              </div>
            </div>

            {dualPkEnded ? (
              <div className="dual-pk-winner-icon-container">
                {getIcon("dualPkWinnerIcon", "dual-pk-winner-icon")}
              </div>
            ) : (
              <div className="dual-pk-current-round-icon-container">
                {getIcon(
                  `dualPkRound${dualPkData?.currentRound}Icon`,
                  "dual-pk-round-icon"
                )}
              </div>
            )}

            {!dualPkEnded && <div className="finish-label">FINISHED</div>}
          </div>
        )}
    </div>
  );
};

export default PkGraphicOverlay;
