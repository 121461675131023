// General
import "./busy-in-private-dialog.scss";
import { useEffect } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateBusyInPrivateDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const BusyInPrivateDialog = () => {
  // Redux variables
  const busyInPrivateDialog = useSelector(
    (state) => state.dialog.busyInPrivateDialog
  );
  const calleeUsername = useSelector(
    (state) => state.privateCall.calleeUsername
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!busyInPrivateDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-BusyInPrivate-Dialog",
      },
    });
  }, [busyInPrivateDialog]);

  // Event Handlers | Button
  const onOpenInbox = () => {};

  // Event Handlers | MUI Dialog
  const onCloseDialog = (event, reason) => {
    dispatch(updateBusyInPrivateDialog(false));
  };

  return (
    <Dialog
      className="custom-radius35-dialog"
      open={busyInPrivateDialog}
      onClose={onCloseDialog}
    >
      <div id="busy-in-private-dialog">
        <div className="padding-container">
          <div className="busy-icon-container">
            {getIcon("videoCallUnavailableIcon", "busy-icon")}
          </div>

          <div className="busy-title">{calleeUsername} is busy in Private.</div>

          <div className="busy-message">
            Come back later or leave a Private Message, with available times.
          </div>

          <div className="button-container">
            <div className="back-button" onClick={onCloseDialog}>
              {t("common.back")}
            </div>

            <div className="inbox-button" onClick={onOpenInbox}>
              Open Inbox
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default BusyInPrivateDialog;
