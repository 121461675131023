// General
import { useEffect, useRef } from "react";
// Services
import { useLazyGetCardDetailsQuery } from "../../../../services/data.service";
// Static Data
import paymentConst from "../../../../const/paymentConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateCardDetail,
  updatePaymentState,
} from "../../../../redux/store/paymentStore";
import {
  updateAddCardDialog,
  updateBuyCoinsDialog,
  updateAddPaymentMethodDialog,
} from "../../../../redux/store/dialogStore";

const OpenPackageAfterAddCard = () => {
  // APi variables
  const [
    getCardDetails,
    {
      data: getCardDetailsData,
      error: getCardDetailsErrorData,
      isFetching: getCardDetailsFetching,
      isLoading: getCardDetailsLoading,
      isSuccess: getCardDetailsSuccess,
      isError: getCardDetailsError,
    },
  ] = useLazyGetCardDetailsQuery();

  // General variables
  const isMounted = useRef(null);

  // Redux variables
  const afterCardAddedPassthrough = useSelector(
    (state) => state.payment.afterCardAddedPassthrough
  );
  const securionPayTokenDetails = useSelector(
    (state) => state.payment.securionPayTokenDetails
  );
  const dispatch = useDispatch();

  // Lifecycle | Check for update | Get Card Details API Response
  useEffect(() => {
    if (getCardDetailsFetching || getCardDetailsLoading) {
    } else if (getCardDetailsSuccess) {
      // Close loading dialog
      dispatch(updateAddCardDialog(false));

      switch (getCardDetailsData?.status) {
        case 0:
          // Update Card List to Store
          dispatch(updateCardDetail(getCardDetailsData?.data));

          // Update Payment State to Store
          const paymentObj = {
            state: paymentConst.paymentState.addCardSaved,
            token: securionPayTokenDetails.id,
          };
          dispatch(updatePaymentState(paymentObj));

          // Route to Coin Page
          // onNavigate(-1);

          // Close Add Payment Method Dialog
          dispatch(updateAddPaymentMethodDialog(false));

          // Open Coin Package Dialog
          dispatch(updateBuyCoinsDialog(true));
          break;
        default:
          break;
      }
    } else if (getCardDetailsError) {
    }
  }, [
    getCardDetailsFetching,
    getCardDetailsLoading,
    getCardDetailsSuccess,
    getCardDetailsError,
  ]);

  // Lifecycle | Check for update | afterCardAddedPassthrough
  useEffect(() => {
    if (isMounted.current) {
      if (afterCardAddedPassthrough) {
        getCardDetails();
      }
    } else {
      isMounted.current = true;
    }
  }, [afterCardAddedPassthrough]);

  return (
    <div className="open-package-after-add-card-helper-subcomponent"></div>
  );
};

export default OpenPackageAfterAddCard;
