// General
import "./basic-info.scss";
// Static Data
import userConst from "../../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateVerifiedCallerDescriptionDialog } from "../../../../../redux/store/dialogStore";
// Material UI
import PlaceIcon from "@mui/icons-material/Place";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useGetMembershipBadge from "../../../../utility/custom-hooks/useGetMembershipBadge-hook";
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import VerifiedProfileBadge from "../../verified-profile-badge/verified-profile-badge";

const BasicInfo = () => {
  // Redux variables
  const idInt = useSelector((state) => state.userCard.idInt);
  const username = useSelector((state) => state.userCard.username);
  const age = useSelector((state) => state.userCard.age);
  const location = useSelector((state) => state.userCard.location);
  const membershipType = useSelector((state) => state.userCard.membershipType);
  const verifiedProfile = useSelector(
    (state) => state.userCard.verifiedProfile
  );
  const verifiedCaller = useSelector((state) => state.userCard.verifiedCaller);
  const levellingBadge = useSelector((state) => state.userCard.levellingBadge);
  const livestreamingAchievementBadge = useSelector(
    (state) => state.userCard.livestreamingAchievementBadge
  );
  const isOnline = useSelector((state) => state.userCard.isOnline);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getMembershipBadge = useGetMembershipBadge();
  const getIcon = IconManager();

  // Event Handlers | Button
  const onOpenVerifiedCallerDialog = () => {
    dispatch(updateVerifiedCallerDescriptionDialog(true));
  };

  return (
    <div id="profile-view-basic-info-subcomponent">
      <div className="padding-container">
        <div className="info-container">
          {livestreamingAchievementBadge?.map((badge, index) => (
            <img
              className="livestreaming-achievement-badge"
              src={badge?.url}
              alt={badge?.type}
              key={index}
            />
          ))}

          <div className="status-name-age-container">
            {isOnline && <div className="status"></div>}
            <div className="name">{username || t("common.new_user")}</div>
            <div className="age">{age || "-"}</div>

            <div className="misc-container">
              {/* Verified Profile */}
              <div className="verified-profile-badge-container">
                <VerifiedProfileBadge
                  verifiedProfile={verifiedProfile}
                  className={"verified-profile-badge"}
                />
              </div>

              {/* Membership */}
              {(membershipType === userConst.membershipType.premium ||
                membershipType === userConst.membershipType.freePremium ||
                membershipType === userConst.membershipType.diamond) && (
                <div className="premium-badge-container">
                  <img
                    className="premium-badge"
                    src={getMembershipBadge(membershipType)}
                  />
                </div>
              )}

              {/* Verified Caller */}
              {verifiedCaller && (
                <div
                  className="verified-caller-badge-container"
                  onClick={onOpenVerifiedCallerDialog}
                >
                  {getIcon("verifiedCallerBadge", "verified-caller-badge")}
                </div>
              )}
            </div>
          </div>

          <div className="location-container">
            <div className="pin-icon-container">
              <PlaceIcon className="pin-icon" />
            </div>
            <div className="location">{location || "-"}</div>

            {/* Levelling */}
            {levellingBadge && (
              <div className="badge-container">
                <img className="badge" src={levellingBadge} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
