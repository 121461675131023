// General
import { createSlice } from "@reduxjs/toolkit";
// Moment
import moment from "moment";

const initialState = {
  // Badge variables
  inboxBadgeCount: 0,

  // Notifications variables
  notificationList: [],
  notificationTodayList: [],
  notificationYesterdayList: [],
  notificationWeekList: [],
  notificationMonthList: [],
  notificationOlderList: [],
  notificationPagination: null,
  showNotificationBadge: false,
  notificationCount: null,
  notificationFirstLoad: true,
  notificationCountLoaded: false,

  // Scroll variables
  notificationScrollYPosition: 0,
  notificationScrollToTopPassthrough: {},

  // Utility variables
  closeNotificationTabPassthrough: null,
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    // Badge Functions
    updateInboxBadgeCount: (state, action) => {
      state.inboxBadgeCount = action.payload;
    },

    // Notifications Functions
    updateNotificationList: (state, action) => {
      const tempNotificationListData = action.payload;
      const currentDate = moment();

      tempNotificationListData.forEach((notification) => {
        const isDuplicate = state.notificationList.some(
          (existingNotification) =>
            existingNotification.notification_id ===
            notification.notification_id
        );

        if (!isDuplicate) {
          state.notificationList.push(notification);
          const notificationDate = notification.timestamp;
          const diffInDays = currentDate.diff(notificationDate, "days");
          const diffInWeeks = currentDate.diff(notificationDate, "weeks");
          const diffInMonths = currentDate.diff(notificationDate, "months");

          if (diffInDays <= 1) {
            state.notificationTodayList.push(notification);
          } else if (diffInDays <= 2) {
            state.notificationYesterdayList.push(notification);
          } else if (diffInWeeks <= 1) {
            state.notificationWeekList.push(notification);
          } else if (diffInMonths <= 1) {
            state.notificationMonthList.push(notification);
          } else {
            state.notificationOlderList.push(notification);
          }
        }
      });
    },
    updateNotificationListFromPusher: (state, action) => {
      const notificationData = action.payload;
      const commonUserFields = {
        id: notificationData.userId,
        id_int: notificationData.userIdInt,
        username: notificationData.username,
        profile_photo: {
          original_photo: notificationData.userPhoto,
        },
        is_verified_profile: notificationData.isVerifiedProfile,
        membership_type: notificationData.membershipType,
      };

      let obj;

      switch (notificationData.type) {
        case "view_profile":
        case "favorite_user":
        case "private_photo_request":
        case "private_photo_approve":
          obj = {
            type: notificationData.type,
            user: commonUserFields,
          };
          break;
        case "live":
          obj = {
            type: notificationData.type,
            channel_id: notificationData.relatedId,
            channel_status: notificationData.channelStatus,
            user: commonUserFields,
          };
          break;
        case "text_message":
          obj = {
            type: notificationData.type,
            conversation_id: notificationData.relatedId,
            user: commonUserFields,
          };
          break;
        default:
          break;
      }

      if (obj) {
        obj.notification_id = notificationData.notificationId;
        obj.timestamp = moment().format("YYYY-MM-DDTHH:mm:ssZ");
        obj.is_seen = false;

        const notificationListData = [...state.notificationList];
        notificationListData.unshift(obj);
        state.notificationList = notificationListData;

        const notificationTodayListData = [...state.notificationTodayList];
        notificationTodayListData.unshift(obj);
        state.notificationTodayList = notificationTodayListData;
      }
    },
    updateNotificationIsSeen: (state, action) => {
      const notificationId = action.payload.notificationId;
      const isSeen = action.payload.isSeen;

      const notificationListData = [...state.notificationList];
      const notificationTodayListData = [...state.notificationTodayList];
      const notificationYesterdayListData = [
        ...state.notificationYesterdayList,
      ];
      const notificationWeekListData = [...state.notificationWeekList];
      const notificationMonthListData = [...state.notificationMonthList];
      const notificationOlderListData = [...state.notificationOlderList];

      const notificationListIndex = notificationListData.findIndex(
        (notification) => notification.notification_id === notificationId
      );
      const notificationTodayListIndex = notificationTodayListData.findIndex(
        (notification) => notification.notification_id === notificationId
      );
      const notificationYesterdayListIndex =
        notificationYesterdayListData.findIndex(
          (notification) => notification.notification_id === notificationId
        );
      const notificationWeekListIndex = notificationWeekListData.findIndex(
        (notification) => notification.notification_id === notificationId
      );
      const notificationMonthListIndex = notificationMonthListData.findIndex(
        (notification) => notification.notification_id === notificationId
      );
      const notificationOlderListIndex = notificationOlderListData.findIndex(
        (notification) => notification.notification_id === notificationId
      );

      if (notificationListIndex !== -1) {
        notificationListData[notificationListIndex].is_seen = isSeen;
        state.notificationList = notificationListData;
      }
      if (notificationTodayListIndex !== -1) {
        notificationTodayListData[notificationTodayListIndex].is_seen = isSeen;
        state.notificationTodayList = notificationTodayListData;
      }
      if (notificationYesterdayListIndex !== -1) {
        notificationYesterdayListData[notificationYesterdayListIndex].is_seen =
          isSeen;
        state.notificationYesterdayList = notificationYesterdayListData;
      }
      if (notificationWeekListIndex !== -1) {
        notificationWeekListData[notificationWeekListIndex].is_seen = isSeen;
        state.notificationWeekList = notificationWeekListData;
      }
      if (notificationMonthListIndex !== -1) {
        notificationMonthListData[notificationMonthListIndex].is_seen = isSeen;
        state.notificationMonthList = notificationMonthListData;
      }
      if (notificationOlderListIndex !== -1) {
        notificationOlderListData[notificationOlderListIndex].is_seen = isSeen;
        state.notificationOlderList = notificationOlderListData;
      }
    },
    updateNotificationPagination: (state, action) => {
      state.notificationPagination = action.payload;
    },
    resetNotificationList: (state) => {
      state.notificationList = [];
      state.notificationTodayList = [];
      state.notificationYesterdayList = [];
      state.notificationWeekList = [];
      state.notificationMonthList = [];
      state.notificationOlderList = [];
      state.notificationPagination = null;
    },
    updateShowNotificationBadgeOld: (state) => {
      if (state.notificationCountLoaded) {
        if (state.notificationList.length > 0) {
          const notificationListData = [...state.notificationList];
          const notificationTodayListData = [...state.notificationTodayList];
          const notificationYesterdayListData = [
            ...state.notificationYesterdayList,
          ];
          const notificationWeekListData = [...state.notificationWeekList];
          const notificationMonthListData = [...state.notificationMonthList];
          const notificationOlderListData = [...state.notificationOlderList];

          const isUnseen = notificationListData
            .concat(
              notificationTodayListData,
              notificationYesterdayListData,
              notificationWeekListData,
              notificationMonthListData,
              notificationOlderListData
            )
            .some((notification) => !notification.is_seen);

          state.showNotificationBadge = isUnseen;
        } else {
          if (state.notificationCount > 0) {
            state.showNotificationBadge = true;
          } else {
            state.showNotificationBadge = false;
          }
        }
      } else {
        const notificationListData = [...state.notificationList];
        const notificationTodayListData = [...state.notificationTodayList];
        const notificationYesterdayListData = [
          ...state.notificationYesterdayList,
        ];
        const notificationWeekListData = [...state.notificationWeekList];
        const notificationMonthListData = [...state.notificationMonthList];
        const notificationOlderListData = [...state.notificationOlderList];

        const isUnseen = notificationListData
          .concat(
            notificationTodayListData,
            notificationYesterdayListData,
            notificationWeekListData,
            notificationMonthListData,
            notificationOlderListData
          )
          .some((notification) => !notification.is_seen);

        state.showNotificationBadge = isUnseen;
      }
    },
    updateShowNotificationBadge: (state) => {
      const combineNotificationLists = [
        state.notificationList,
        state.notificationTodayList,
        state.notificationYesterdayList,
        state.notificationWeekList,
        state.notificationMonthList,
        state.notificationOlderList,
      ].every(Array.isArray)
        ? [
            state.notificationList,
            state.notificationTodayList,
            state.notificationYesterdayList,
            state.notificationWeekList,
            state.notificationMonthList,
            state.notificationOlderList,
          ].flat()
        : [];

      const isUnseen = combineNotificationLists.some(
        (notification) => !notification.is_seen
      );

      state.showNotificationBadge = state.notificationCountLoaded
        ? isUnseen || state.notificationCount > 0
        : isUnseen;
    },
    updateNotificationCount: (state, action) => {
      state.notificationCount = action.payload;
    },
    updateNotificationFirstLoad: (state, action) => {
      state.notificationFirstLoad = action.payload;
    },
    updateNotificationCountLoaded: (state, action) => {
      state.notificationCountLoaded = action.payload;
    },

    // Scroll Functions
    updateNotificationScrollYPosition: (state, action) => {
      state.notificationScrollYPosition = action.payload;
    },
    updateNotificationScrollToTopPassthrough: (state, action) => {
      state.notificationScrollToTopPassthrough = action.payload;
    },

    // Utility Functions
    resetNotificationStore: () => {
      return { ...initialState };
    },
    updateCloseNotificationTabPassthrough: (state) => {
      state.closeNotificationTabPassthrough = {};
    },
  },
});

export const {
  // Badge Functions
  updateInboxBadgeCount,

  // Notifications Functions
  updateNotificationList,
  updateNotificationListFromPusher,
  updateNotificationIsSeen,
  updateNotificationPagination,
  resetNotificationList,
  updateShowNotificationBadge,
  updateNotificationCount,
  updateNotificationFirstLoad,
  updateNotificationCountLoaded,

  // Scroll Functions
  updateNotificationScrollYPosition,
  updateNotificationScrollToTopPassthrough,

  // Utility Functions
  resetNotificationStore,
  updateCloseNotificationTabPassthrough,
} = notificationSlice.actions;

export default notificationSlice.reducer;
