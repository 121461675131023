// General
import "./conversation-list.scss";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
// Services
import {
  useLazyGetProfileQuery,
  useLazyGetUserAioQuery,
  useLazyGetInboxSingleConversationQuery,
  useLazyGetPrivateCallPreRequestQuery,
} from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
import userConst from "../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateConversationId,
  updateConversationLists,
  updateConversationListsFromTop,
  updateConversationListsFromBottom,
  updateConversationUser,
  updateConversationObj,
  resetConversationObj,
  resetConversationLists,

  // Private Call Functions
  updateOtherUserPrivateCallEnable,
  updateInitiatePrivateCall,
} from "../../../../redux/store/inboxStore";
import {
  updateCalleeDiamonds,
  updateCalleeRatePerMinute,
  updateCallerCoins,
} from "../../../../redux/store/privateCallStore";
import {
  // User View
  updateId,
  updateIdInt,
  updateUsername,
  updateAge,
  updateLocation,
  updateMembershipType,
  updateVerifiedProfile,
  updateVerifiedCaller,
  updateLevellingBadge,
  updateLivestreamingAchievementBadge,
  updateSpecialBadges,
  updateProfilePhoto,
  updatePublicPhotos,
  updatePrivatePhotos,
  updateTorTags,
  updateProfileAchievementBadge,
  updateAboutMe,
  updateLookingFor,
  updateOccupation,
  updateLifestyle,
  updateEducation,
  updateRelationship,
  updateSmoking,
  updateDrinking,
  updateHeight,
  updateBodyType,
  updateEthnicity,
  updateEyeColor,
  updateHairColor,
  updateChildren,

  // User Utility
  updatePrivatePhotoRequested,
  updatePrivatePhotoApproval,
  updateRequestedMyPrivatePhoto,
  updateApprovedMyPrivatePhoto,
  updateIsBlocked,
  updateIsSilentInteraction,
  updateIsFavorited,
  updateIsCallEnabled,
  updateIsSugarbaby,
} from "../../../../redux/store/userCardStore";
import { updatePaymentTag } from "../../../../redux/store/paymentStore";
import { updateUserProfileDialog } from "../../../../redux/store/dialogStore";
import {
  updateWarningToast,
  updateErrorToast,
} from "../../../../redux/store/toastStore";
// React Infinite Scroller
// import InfiniteScroll from "react-infinite-scroller";
import InfiniteScroll from "react-infinite-scroll-component";
// react-gtm-module
import TagManager from "react-gtm-module";
// Moment
import moment from "moment";
// Material UI
import { useMediaQuery } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useIsMounted from "../../../utility/custom-hooks/useIsMounted-hook";
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../../shared/elements/spinner/spinner";
import PreRequestVideoCall from "./pre-request-video-call/pre-request-video-call";

const ConversationList = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();
  const [
    getInboxSingleConversation,
    {
      data: getInboxSingleConversationData,
      error: getInboxSingleConversationErrorData,
      isFetching: getInboxSingleConversationFetching,
      isLoading: getInboxSingleConversationLoading,
      isSuccess: getInboxSingleConversationSuccess,
      isError: getInboxSingleConversationError,
    },
  ] = useLazyGetInboxSingleConversationQuery();
  const [
    getPrivateCallPreRequest,
    {
      data: getPrivateCallPreRequestData,
      error: getPrivateCallPreRequestErrorData,
      isFetching: getPrivateCallPreRequestFetching,
      isLoading: getPrivateCallPreRequestLoading,
      isSuccess: getPrivateCallPreRequestSuccess,
      isError: getPrivateCallPreRequestError,
    },
  ] = useLazyGetPrivateCallPreRequestQuery();

  // General variables
  const [firstLoad, setFirstLoad] = useState(true);
  const [isAioLoaded, setIsAioLoaded] = useState(false);
  const isMounted = useRef(false);
  const userProfileMounted = useRef(false);
  const messagesEndRef = useRef(null);
  const infiniteScrollRef = useRef(null);

  // Render variables
  let conversationListView = <div></div>;

  // Redux variables
  const conversationLists = useSelector(
    (state) => state.inbox.conversationLists
  );
  const conversationId = useSelector((state) => state.inbox.conversationId);
  const conversationObj = useSelector((state) => state.inbox.conversationObj);
  const conversationUser = useSelector((state) => state.inbox.conversationUser);
  const conversationUserProfilePassthrough = useSelector(
    (state) => state.inbox.conversationUserProfilePassthrough
  );
  const videoCallPassthrough = useSelector(
    (state) => state.privateCall.videoCallPassthrough
  );
  const initiatePrivateCall = useSelector(
    (state) => state.inbox.initiatePrivateCall
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 850px)");

  // Params variables
  const { id } = useParams();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const mounted = useIsMounted();
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    // Reset conversationObj
    dispatch(resetConversationObj());

    // Reset conversation incase unmount did not run
    dispatch(resetConversationLists());

    // Reset conversation obj incase unmount did not run
    dispatch(resetConversationObj());

    getProfile(null, true);

    getInboxSingleConversation(`/${id ?? conversationId}`);
  }, []);

  // Lifecycle | Unmounted
  useEffect(() => {
    return () => {
      if (!mounted()) {
        dispatch(resetConversationLists());
        dispatch(resetConversationObj());
        dispatch(updateInitiatePrivateCall(false));
      }
    };
  }, [mounted]);

  // Lifecycle | Check for update | User AIO API Response
  useEffect(() => {
    if (getUserAioFetching || getUserAioLoading) {
    } else if (getUserAioSuccess) {
      if (!isAioLoaded) {
        if (getUserAioData?.data?.notices?.private_messaging?.length > 0) {
          const obj = {
            message_type: "system_caution",
            message_content:
              getUserAioData?.data?.notices?.private_messaging[0]?.content,
          };
          dispatch(updateConversationListsFromTop([obj]));
        }

        setIsAioLoaded(true);
      }
    } else if (getUserAioError) {
    }
  }, [
    getUserAioFetching,
    getUserAioLoading,
    getUserAioSuccess,
    getUserAioError,
  ]);

  // Lifecycle | Check for update | Inbox Single Conversation API Response
  useEffect(() => {
    if (
      getInboxSingleConversationFetching ||
      getInboxSingleConversationLoading
    ) {
    } else if (getInboxSingleConversationSuccess) {
      switch (getInboxSingleConversationData?.status) {
        case 0:
          setFirstLoad(false);

          dispatch(
            updateConversationId(
              getInboxSingleConversationData?.data?.conversation_id
            )
          );
          dispatch(
            updateOtherUserPrivateCallEnable(
              getInboxSingleConversationData?.data?.other_user
                ?.private_call_settings?.system_private_calls_enabled &&
                getInboxSingleConversationData?.data?.other_user
                  ?.private_call_settings?.enable_calls
            )
          );

          if (getInboxSingleConversationData?.data?.other_user) {
            const conversationUserObj = {
              // User Data
              userId: getInboxSingleConversationData?.data?.other_user?.id,
              userIdInt:
                getInboxSingleConversationData?.data?.other_user?.id_int,
              username:
                getInboxSingleConversationData?.data?.other_user?.username,
              age: getInboxSingleConversationData?.data?.other_user?.appearance
                ?.age,
              location:
                getInboxSingleConversationData?.data?.other_user?.location
                  ?.city,
              membershipType:
                getInboxSingleConversationData?.data?.other_user
                  ?.membership_type,
              verifiedProfile:
                getInboxSingleConversationData?.data?.other_user
                  ?.is_verified_profile,
              verifiedCaller:
                getInboxSingleConversationData?.data?.other_user
                  ?.verified_caller,
              levellingBadge:
                getInboxSingleConversationData?.data?.other_user
                  ?.levelling_profile?.badge,
              profilePhoto:
                getInboxSingleConversationData?.data?.other_user?.profile_photo
                  ?.original_photo,
              profileDecorations:
                getInboxSingleConversationData?.data?.other_user
                  ?.profile_decorations,
              publicPhotos:
                getInboxSingleConversationData?.data?.other_user?.public_photos?.map(
                  (photo) => {
                    return {
                      photoUrl: photo?.original_photo,
                    };
                  }
                ),
              privatePhotos:
                getInboxSingleConversationData?.data?.other_user?.private_photos?.map(
                  (photo) => {
                    return {
                      photoUrl: photo?.original_photo,
                      thumbnailData: photo?.thumbnail_data,
                      isSensitive: photo?.is_sensitive,
                    };
                  }
                ),
              torTags:
                getInboxSingleConversationData?.data?.other_user?.tor_tags,
              profileAchievementBadge:
                getInboxSingleConversationData?.data?.other_user
                  ?.live_streaming_achievements?.profile_badges,
              aboutMe:
                getInboxSingleConversationData?.data?.other_user?.aboutme,
              aboutMeReadStatus:
                getInboxSingleConversationData?.data?.other_user
                  ?.aboutme_read_status,
              lookingFor:
                getInboxSingleConversationData?.data?.other_user?.lookingfor,
              occupation:
                getInboxSingleConversationData?.data?.other_user?.occupation
                  ?.occupation,
              lifestyle:
                getInboxSingleConversationData?.data?.other_user?.occupation
                  ?.lifestyle_budget,
              education:
                getInboxSingleConversationData?.data?.other_user?.occupation
                  ?.education,
              relationship:
                getInboxSingleConversationData?.data?.other_user?.relationship
                  ?.relationship_status,
              smoking:
                getInboxSingleConversationData?.data?.other_user?.habit
                  ?.smoking,
              drinking:
                getInboxSingleConversationData?.data?.other_user?.habit
                  ?.drinking,
              height:
                getInboxSingleConversationData?.data?.other_user?.appearance
                  ?.height,
              bodyType:
                getInboxSingleConversationData?.data?.other_user?.appearance
                  ?.body_type,
              ethnicity:
                getInboxSingleConversationData?.data?.other_user?.appearance
                  ?.ethnicity,
              eyeColor:
                getInboxSingleConversationData?.data?.other_user?.appearance
                  ?.eye_color,
              hairColor:
                getInboxSingleConversationData?.data?.other_user?.appearance
                  ?.hair_color,
              children:
                getInboxSingleConversationData?.data?.other_user?.relationship
                  ?.children,
              // About Me Functions
              personalityType:
                getInboxSingleConversationData?.data?.other_user?.data
                  ?.personality?.personality_type,
              hobbies:
                getInboxSingleConversationData?.data?.other_user?.data
                  ?.personality?.hobbies,
              fitnessLevel:
                getInboxSingleConversationData?.data?.other_user?.data
                  ?.personality?.fitness_level,
              starSign:
                getInboxSingleConversationData?.data?.other_user?.data
                  ?.personality?.star_sign,
              dominantOrSubmissive:
                getInboxSingleConversationData?.data?.other_user?.data
                  ?.personality?.dominant_or_submissive,
              personalityTraits:
                getInboxSingleConversationData?.data?.other_user?.data
                  ?.personality?.personality_traits,
              // User Utility
              privatePhotoRequested:
                getInboxSingleConversationData?.data?.other_user?.interaction
                  ?.private_photo_requested,
              privatePhotoApproval:
                getInboxSingleConversationData?.data?.other_user?.interaction
                  ?.private_photo_approval,
              requestedMyPrivatePhoto:
                getInboxSingleConversationData?.data?.other_user?.interaction
                  ?.requested_my_private_photo,
              approvedMyPrivatePhoto:
                getInboxSingleConversationData?.data?.other_user?.interaction
                  ?.approved_my_private_photo,
              isOnline:
                getInboxSingleConversationData?.data?.other_user?.activity
                  ?.is_online,
              isBlocked:
                getInboxSingleConversationData?.data?.other_user?.interaction
                  ?.is_blocked,
              isSilentInteraction:
                getInboxSingleConversationData?.data?.other_user?.interaction
                  ?.should_silent_interactions,
              isFavorited:
                getInboxSingleConversationData?.data?.other_user?.interaction
                  ?.is_favorited,
              isCallEnabled:
                getInboxSingleConversationData?.data?.other_user
                  ?.private_call_settings?.enable_calls &&
                getInboxSingleConversationData?.data?.other_user
                  ?.private_call_settings?.system_private_calls_enabled,
              isSugarbaby:
                getInboxSingleConversationData?.data?.other_user?.role ===
                  userConst.userRole.sugarbaby ||
                getInboxSingleConversationData?.data?.other_user?.role ===
                  userConst.userRole.maleSugarbaby,
              role: getInboxSingleConversationData?.data?.other_user?.role,

              // Conversation Data
              id: getInboxSingleConversationData?.data?.conversation_id,
              idInt: getInboxSingleConversationData?.data?.conversation_id_int,
              livestreamingAchievementBadge:
                getInboxSingleConversationData?.data?.other_user
                  ?.live_streaming_achievements?.chat_badges_set,
              specialBadges:
                getInboxSingleConversationData?.data?.other_user
                  ?.special_badges,
              verifiedProfile:
                getInboxSingleConversationData?.data?.other_user
                  ?.is_verified_profile,
              verifiedCaller:
                getInboxSingleConversationData?.data?.other_user
                  ?.verified_caller,
            };

            // If user refreshes the page, inbox/conversationUser will be undefined, this serve as a backup
            dispatch(updateConversationUser(conversationUserObj));
          }

          // If user refreshes the page, idInt will be undefined, this serve as a backup
          // Use in report page only
          dispatch(
            updateIdInt(
              getInboxSingleConversationData?.data?.other_user?.id_int
            )
          );

          dispatch(
            updateConversationListsFromTop(
              getInboxSingleConversationData?.data?.results
            )
          );

          getUserAio(null, true);

          setTimeout(() => {
            // scrollToBottom();
          }, 500);
          break;
        case -1:
          const toastObj = {
            message: "Conversation not found",
            autoClose: 3000,
          };
          dispatch(updateWarningToast(toastObj));

          onNavigate(-1);
          break;
        default:
          break;
      }
    } else if (getInboxSingleConversationError) {
      if (getInboxSingleConversationErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getInboxSingleConversationFetching,
    getInboxSingleConversationLoading,
    getInboxSingleConversationSuccess,
    getInboxSingleConversationError,
  ]);

  // Lifecycle | Check for update | Pre Request API Response
  useEffect(() => {
    if (getPrivateCallPreRequestFetching || getPrivateCallPreRequestLoading) {
    } else if (getPrivateCallPreRequestSuccess) {
      switch (getPrivateCallPreRequestData?.status) {
        case 0:
          // Push to conversation list
          const obj = {
            conversation_id: conversationId,
            conversation_id_int: "",
            data: {
              message: {
                message_type: "video-call",
              },
            },
          };
          dispatch(updateConversationObj(obj));

          // Push to pre request conversation container
          dispatch(
            updateCallerCoins(getPrivateCallPreRequestData?.data?.rates?.coins)
          );
          dispatch(
            updateCalleeDiamonds(
              getPrivateCallPreRequestData?.data?.rates?.diamonds
            )
          );
          dispatch(
            updateCalleeRatePerMinute(
              getPrivateCallPreRequestData?.data?.rates?.usd
            )
          );
          break;
        case -1:
          // You are not allowed to make calls with {{user}}
          const warningToast = {
            message: getPrivateCallPreRequestData?.message,
            autoClose: 3000,
          };
          dispatch(updateErrorToast(warningToast));
        case -5:
          // User only accepts calls during certain hours
          const warningAltToast = {
            message: getPrivateCallPreRequestData?.message,
            autoClose: 3000,
          };
          dispatch(updateWarningToast(warningAltToast));
          break;
        case -10:
          const toastObj = {
            message: getPrivateCallPreRequestData?.message,
            autoClose: 3000,
          };
          dispatch(updateErrorToast(toastObj));
          break;
        default:
          break;
      }
      if (getPrivateCallPreRequestData?.status === 0) {
      }
    } else if (getPrivateCallPreRequestError) {
    }
  }, [
    getPrivateCallPreRequestFetching,
    getPrivateCallPreRequestLoading,
    getPrivateCallPreRequestSuccess,
    getPrivateCallPreRequestError,
  ]);

  // Lifecycle | Check for update | Conversation Obj
  useEffect(() => {
    if (!conversationObj || conversationObj?.conversation_id !== conversationId)
      return;

    dispatch(
      updateConversationListsFromBottom([conversationObj?.data?.message])
    );

    setTimeout(() => {
      // scrollToBottom();
    }, 500);
  }, [conversationObj]);

  // Lifecycle | Check for update | Initiate Private Call
  useEffect(() => {
    if (!initiatePrivateCall || firstLoad) return;

    const obj = {
      callee_id: getInboxSingleConversationData?.data?.other_user?.id,
    };
    getPrivateCallPreRequest(obj);
  }, [initiatePrivateCall, firstLoad]);

  // Lifecycle | Check for update | conversationUserProfilePassthrough
  useEffect(() => {
    if (userProfileMounted.current) {
      if (conversationUserProfilePassthrough) {
        // User View
        dispatch(updateId(conversationUser?.userId));
        dispatch(updateIdInt(conversationUser?.userIdInt));
        dispatch(updateUsername(conversationUser?.username));
        dispatch(updateAge(conversationUser?.age));
        dispatch(updateLocation(conversationUser?.location));
        dispatch(updateMembershipType(conversationUser?.membershipType));
        dispatch(updateVerifiedProfile(conversationUser?.verifiedProfile));
        dispatch(updateVerifiedCaller(conversationUser?.verifiedCaller));
        dispatch(updateLevellingBadge(conversationUser?.levellingBadge));
        dispatch(
          updateLivestreamingAchievementBadge(
            conversationUser?.livestreamingAchievementBadge
          )
        );
        dispatch(updateSpecialBadges(conversationUser?.specialBadges));
        dispatch(updateProfilePhoto(conversationUser?.profilePhoto));
        dispatch(updatePublicPhotos(conversationUser?.publicPhotos));
        dispatch(updatePrivatePhotos(conversationUser?.privatePhotos));
        dispatch(updateTorTags(conversationUser?.torTags));
        dispatch(
          updateProfileAchievementBadge(
            conversationUser?.profileAchievementBadge
          )
        );
        dispatch(updateAboutMe(conversationUser?.aboutMe));
        dispatch(updateLookingFor(conversationUser?.lookingFor));
        dispatch(updateOccupation(conversationUser?.occupation));
        dispatch(updateLifestyle(conversationUser?.lifestyle));
        dispatch(updateEducation(conversationUser?.education));
        dispatch(updateRelationship(conversationUser?.relationship));
        dispatch(updateSmoking(conversationUser?.smoking));
        dispatch(updateDrinking(conversationUser?.drinking));
        dispatch(updateHeight(conversationUser?.height));
        dispatch(updateBodyType(conversationUser?.bodyType));
        dispatch(updateEthnicity(conversationUser?.ethnicity));
        dispatch(updateEyeColor(conversationUser?.eyeColor));
        dispatch(updateHairColor(conversationUser?.hairColor));
        dispatch(updateChildren(conversationUser?.children));
        // User Utility
        dispatch(
          updatePrivatePhotoRequested(conversationUser?.privatePhotoRequested)
        );
        dispatch(
          updatePrivatePhotoApproval(conversationUser?.privatePhotoApproval)
        );
        dispatch(
          updateRequestedMyPrivatePhoto(
            conversationUser?.requestedMyPrivatePhoto
          )
        );
        dispatch(
          updateApprovedMyPrivatePhoto(conversationUser?.approvedMyPrivatePhoto)
        );
        dispatch(updateIsBlocked(conversationUser?.isBlocked ? true : false));
        dispatch(
          updateIsSilentInteraction(conversationUser?.isSilentInteraction)
        );
        dispatch(updateIsFavorited(conversationUser?.isFavorite));
        dispatch(updateIsCallEnabled(conversationUser?.isCallEnabled));
        dispatch(updateIsSugarbaby(conversationUser?.isSugarbaby));

        // dispatch(updateUserProfileDialog(true));
        onNavigate(
          `${routeConst.profile.view.clearPath}${conversationUser?.userId}`
        ); // Page
      }
    } else {
      userProfileMounted.current = true;
    }
  }, [conversationUserProfilePassthrough]);

  // Lifecycle | Check for update | Video Call Passthrough
  useEffect(() => {
    if (isMounted.current) {
      if (videoCallPassthrough) {
        const obj = {
          callee_id: getInboxSingleConversationData?.data?.other_user?.id,
        };
        getPrivateCallPreRequest(obj);
      }
    } else {
      isMounted.current = true;
    }
  }, [videoCallPassthrough]);

  // Event Handlers | Button
  const onNavigateToUpgrade = () => {
    if (getProfileData?.data?.membership_type !== userConst.membershipType.free)
      return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-8.1-Upgrade-Button",
      },
    });

    dispatch(updatePaymentTag("pwa-conversation-blocker"));
    onNavigate(routeConst.upgrade.path);
  };

  // Infinite Scroller Functions
  const infiniteScrollFunc = () => {
    if (
      !getInboxSingleConversationFetching &&
      getInboxSingleConversationData?.data?.pagination?.next_cursor
    ) {
      setTimeout(() => {
        getInboxSingleConversation(
          `/${getInboxSingleConversationData?.data?.conversation_id}?pagination_token=${getInboxSingleConversationData?.data?.pagination?.next_cursor}`,
          false
        );
      }, 1000);
    }
  };

  // Utility Functions
  const shouldDateShow = (index) => {
    let today = moment();
    let dateOutput = moment(conversationLists[index]?.message_timestamp);
    let dateOutputPrev = moment(
      conversationLists[index + 1]?.message_timestamp
    );

    if (today.diff(dateOutput, "days") < 7) {
      if (today.clone().day() === dateOutput.clone().day()) {
        if (today.clone().day() === dateOutputPrev.clone().day()) {
          return false;
        } else {
          return true;
        }
      } else {
        return dateOutput.format("dddd") !== dateOutputPrev.format("dddd");
      }
    }

    return dateOutput.format("MMM DD") !== dateOutputPrev.format("MMM DD");
  };
  const getMessageHeaderDate = (date) => {
    let today = moment();
    let dateOutput = moment(date);

    if (today.diff(dateOutput, "days") < 7) {
      if (today.clone().day() === dateOutput.clone().day()) {
        return t("common.today");
      } else if (today.diff(dateOutput, "days") <= 1) {
        return t("common.yesterday");
      } else {
        return dateOutput.format("dddd");
      }
    }

    return dateOutput.format("MMM DD");
  };
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const returnMessageContent = (message) => {
    if (message?.includes("just given you access to my private photos")) {
      return t("inbox.i_have_just_given_you_access");
    } else if (message?.includes("to view your private photos")) {
      return t("inbox.request_to_view_your_private_photos");
    } else {
      return message;
    }
  };

  // Render Functions | Conversation List Data
  if (conversationLists?.length > 0) {
    conversationListView = (
      <div className="infinite-scroller-body-container">
        {conversationLists?.map((conversation, index) => {
          if (conversation?.message_type === "call") {
            return (
              <div className="call-canceled-container" key={index}>
                <div className="call-canceled">{t("inbox.call_cancelled")}</div>
                <div className="timestamp">
                  {moment(conversation?.message_timestamp).format("h:mm A")}
                </div>
              </div>
            );
          } else if (conversation?.message_type === "video-call") {
            return <PreRequestVideoCall key={index} />;
          } else if (conversation?.message_type === "system_caution") {
            return (
              <div className="caution-container" key={index}>
                <div className="caution-padding-container">
                  <div className="caution-logo-container">
                    {getIcon("alertIcon")}
                  </div>

                  <div
                    className="caution-description"
                    dangerouslySetInnerHTML={{
                      __html: conversation?.message_content,
                    }}
                  ></div>
                </div>
              </div>
            );
          } else {
            return (
              <div className="single-conversation-container" key={index}>
                {shouldDateShow(index) && (
                  <div className="date-container">
                    {getMessageHeaderDate(conversation?.message_timestamp)}
                  </div>
                )}

                <div
                  className={`message-container ${
                    conversation?.message_mine
                      ? "own-message push-right"
                      : "other-message push-left"
                  }`}
                  key={index}
                  onClick={onNavigateToUpgrade}
                >
                  <div
                    className="message-content"
                    dangerouslySetInnerHTML={{
                      __html: returnMessageContent(
                        conversation?.message_content
                      ),
                    }}
                  ></div>

                  <div
                    className={`bottom-container ${
                      !conversation?.message_mine && "push-left"
                    }`}
                  >
                    <div className="message-timestamp">
                      {moment(conversation?.message_timestamp).format("h:mm A")}
                    </div>

                    {getProfileData?.data?.membership_type !==
                      userConst.membershipType.free &&
                    conversation?.message_mine ? (
                      <div className="tick-container">
                        {conversation?.message_seen
                          ? getIcon("blueTickIcon", "tick")
                          : getIcon("greyTickIcon", "tick")}
                      </div>
                    ) : conversation?.message_mine ? (
                      <div className="tick-container">
                        {getIcon("greyTickIcon", "tick")}
                      </div>
                    ) : null}

                    {getProfileData?.data?.membership_type ===
                      userConst.membershipType.free && (
                      <div className="premium-read-label">
                        {t("inbox.is_read")}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  } else if (
    firstLoad &&
    (getInboxSingleConversationFetching || getInboxSingleConversationLoading)
  ) {
    conversationListView = <Spinner />;
  }

  return (
    <div id="chat-conversation-conversation-list-subcomponent">
      <div className="padding-container">
        {/* <div className="caution-container">
          <div className="caution-padding-container">
            <div className="caution-logo-container">{getIcon("alertIcon")}</div>

            {getUserAioData?.data?.notices?.private_messaging?.length > 0 &&
              getUserAioData?.data?.notices?.private_messaging[0]?.content && (
                <div
                  className="caution-description"
                  dangerouslySetInnerHTML={{
                    __html:
                      getUserAioData?.data?.notices?.private_messaging[0]
                        ?.content,
                  }}
                ></div>
              )}
          </div>
        </div> */}

        <div
          id="infinite-scroller"
          className="conversation-list-container"
          ref={infiniteScrollRef}
        >
          {/* react-infinite-scroller-component */}
          {infiniteScrollRef.current && (
            <InfiniteScroll
              className="infinite-scroller-container"
              dataLength={conversationLists?.length}
              next={infiniteScrollFunc}
              style={{ display: "flex", flexDirection: "column-reverse" }}
              inverse={true}
              hasMore={
                !firstLoad &&
                !getInboxSingleConversationFetching &&
                getInboxSingleConversationData?.data?.pagination?.next_cursor
                  ? true
                  : false
              }
              loader={<Spinner key={0} />}
              scrollThreshold={0.8}
              scrollableTarget={"infinite-scroller"}
              height={infiniteScrollRef.current?.clientHeight}
            >
              {conversationListView}
            </InfiniteScroll>
          )}

          {/* react-infinite-scroller */}
          {/* <InfiniteScroll
            className="infinite-scroller-container"
            pageStart={0}
            loadMore={infiniteScrollFunc}
            hasMore={
              !firstLoad &&
              !getInboxSingleConversationFetching &&
              getInboxSingleConversationData?.data?.pagination?.next_cursor
                ? true
                : false
            }
            loader={<Spinner key={0} />}
            useWindow={false}
            isReverse={true}
            // getScrollParent={() => infiniteScrollRef.current}
          >
            {conversationListView}
          </InfiniteScroll> */}
        </div>
      </div>

      <div ref={messagesEndRef} />
    </div>
  );
};

export default ConversationList;
