// General
import "./private-call-calling-dialog.scss";
import { useEffect, forwardRef } from "react";
// Services
import { useCancelPrivateCallRequestMutation } from "../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updatePrivateCallCallingDialog } from "../../../../redux/store/dialogStore";
import {
  // General Functions
  startPrivateCallCallingTimer,
  resetPrivateCallCallingTimer,

  // Interval Functions
  clearPrivateCallCallingInterval,
  updatePrivateCallCallingInterval,
} from "../../../../redux/store/privateCallStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../../shared/elements/spinner/spinner";

const PrivateCallCallingDialog = () => {
  // API variables
  const [
    cancelPrivateCallRequest,
    {
      data: cancelPrivateCallRequestData,
      error: cancelPrivateCallRequestErrorData,
      isLoading: cancelPrivateCallRequestLoading,
      isSuccess: cancelPrivateCallRequestSuccess,
      isError: cancelPrivateCallRequestError,
    },
  ] = useCancelPrivateCallRequestMutation();

  // Redux variables
  const isCaller = useSelector((state) => state.privateCall.isCaller);
  const requestId = useSelector((state) => state.privateCall.requestId);
  const callerUsername = useSelector(
    (state) => state.privateCall.callerUsername
  );
  const calleeUsername = useSelector(
    (state) => state.privateCall.calleeUsername
  );
  const privateCallCallingDialog = useSelector(
    (state) => state.dialog.privateCallCallingDialog
  );
  const privateCallCallingTimer = useSelector(
    (state) => state.privateCall.privateCallCallingTimer
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (privateCallCallingDialog) {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-PrivateCallCalling-Dialog",
        },
      });

      dispatch(resetPrivateCallCallingTimer());
      dispatch(
        updatePrivateCallCallingInterval(
          setInterval(() => {
            dispatch(startPrivateCallCallingTimer());
          }, 1000)
        )
      );
    } else {
      dispatch(clearPrivateCallCallingInterval());
      dispatch(resetPrivateCallCallingTimer());
    }
  }, [privateCallCallingDialog]);

  // Lifecycle | Check for update | Cancel Private Call Request API Resposne
  useEffect(() => {
    if (cancelPrivateCallRequestLoading) {
    } else if (cancelPrivateCallRequestSuccess) {
      onCloseDialog();
    } else if (cancelPrivateCallRequestError) {
    }
  }, [
    cancelPrivateCallRequestLoading,
    cancelPrivateCallRequestSuccess,
    cancelPrivateCallRequestError,
  ]);

  // Event Handlers | Button
  const onCancelCall = () => {
    const obj = {
      call_request_id: requestId,
    };

    cancelPrivateCallRequest(obj);
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updatePrivateCallCallingDialog(false));
  };

  // Utility Functions
  const getFormattedTime = (seconds) => {
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds / 60) % 60);
    let secs = Math.floor(seconds % 60);

    let formattedHours = hours < 10 ? "0" + hours : hours;
    let formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    let formattedSeconds = secs < 10 ? "0" + secs : secs;

    return formattedHours + ":" + formattedMinutes + ":" + formattedSeconds;
  };

  return (
    <Dialog
      className="custom-fullscreen-transparent-dialog"
      fullScreen
      open={privateCallCallingDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={onCloseDialog}
    >
      <div id="private-call-calling-dialog">
        <div className="padding-container">
          {privateCallCallingTimer > 0 ? (
            <div className="calling-container">
              <div className="call-request-animation-container">
                {getIcon("callRequestGif", "call-request-animation")}
              </div>

              <div className="calling-label">{t("inbox.calling")}</div>
              <div className="text">
                {t("inbox.calling_waiting", {
                  user: isCaller ? callerUsername : calleeUsername,
                })}
              </div>

              <div className="call-timer">
                {getFormattedTime(privateCallCallingTimer)}
              </div>
              <div className="end-call-button" onClick={onCancelCall}>
                {cancelPrivateCallRequestLoading ? (
                  <Spinner
                    size={15}
                    isPadding={false}
                    color={"white-spinner"}
                  />
                ) : (
                  t("inbox.calling_end_call")
                )}
              </div>
            </div>
          ) : (
            <div className="call-failed-container">
              <div className="call-reject-animation-container">
                {getIcon("callRejectGif", "call-reject-animation")}
              </div>

              <div className="call-failed-label">
                {t("inbox.call_not_answered")}
              </div>
              <div className="text">
                {t("inbox.calling_not_answered", { user: "User" })}
              </div>
              <div className="close-button" onClick={onCloseDialog}>
                {t("common.close")}
              </div>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default PrivateCallCallingDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
