// General
import "./prevent-landscape-dialog.scss";
import { useEffect } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updatePreventLandscapeDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import AssetManager from "../../../utility/manager/asset-manager/asset-manager";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const PreventLandscapeDialog = () => {
  // Redux variables
  const preventLandscapeDialog = useSelector(
    (state) => state.dialog.preventLandscapeDialog
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getAsset = AssetManager();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!preventLandscapeDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-PreventLandscape-Dialog",
      },
    });
  }, [preventLandscapeDialog]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = (event, reason) => {
    if (reason === "backdropClick") return;

    dispatch(updatePreventLandscapeDialog(false));
  };

  return (
    <Dialog
      fullScreen
      open={preventLandscapeDialog}
      keepMounted
      onClose={onCloseDialog}
    >
      <div id="prevent-landscape-dialog">
        <div className="landscape-body-container">
          <div className="first-row">
            <div className="flip-icon-container">
              {getIcon("rotateIcon", "flip-icon")}
            </div>

            <div className="landscape-title">
              {t("splash.portrait_screen_title")}
            </div>
          </div>

          <div className="second-row">
            {t("splash.portrait_screen_title_desc")}
          </div>
        </div>

        <div className="landscape-image-container">
          <img
            className="landscape-image"
            src={getAsset("landscapeBackground")}
            alt="landscape"
          />
        </div>
      </div>
    </Dialog>
  );
};

export default PreventLandscapeDialog;
