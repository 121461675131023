// General
import "./livestream-ended.scss";
import { useState, useEffect } from "react";
// Services
import { useLazyGetLivestreamingEndChannelQuery } from "../../../services/data.service";
// Static Data
import routeConst from "../../../const/routeConst";
// Redux
import { useSelector } from "react-redux";
// Material UI
import { Divider } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useIsMounted from "../../utility/custom-hooks/useIsMounted-hook";
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import Spinner from "../../shared/elements/spinner/spinner";
import LiveCard from "../live/live-card/live-card";

const LivestreamEnded = () => {
  // API variables
  const [
    getLivestreamingEndChannel,
    {
      data: getLivestreamingEndChannelData,
      error: getLivestreamingEndChannelErrorData,
      isFetching: getLivestreamingEndChannelFetching,
      isLoading: getLivestreamingEndChannelLoading,
      isSuccess: getLivestreamingEndChannelSuccess,
      isError: getLivestreamingEndChannelError,
    },
  ] = useLazyGetLivestreamingEndChannelQuery();

  // General variables
  const [redirectInterval, setRedirectInterval] = useState();
  const [redirectCountdown, setRedirectCountdown] = useState(5);

  // Render variables
  const [liveListView, setLiveListView] = useState();

  // Redux variables
  const channelId = useSelector((state) => state.livestreaming.channelId);

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const mounted = useIsMounted();
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    if (channelId) {
      getLivestreamingEndChannel(`?channel_id=${channelId}`);
    } else {
      onNavigate(routeConst.live.path);
    }
  }, []);

  // Lifecycle | Unmounted
  useEffect(() => {
    return () => {
      if (!mounted()) {
        if (redirectInterval) {
          clearInterval(redirectInterval);
        }
      }
    };
  }, [mounted]);

  // Lifecycle | Check for update | Livestreaming End Channel API Response
  useEffect(() => {
    if (
      getLivestreamingEndChannelFetching ||
      getLivestreamingEndChannelLoading
    ) {
      setLiveListView(<Spinner />);
    } else if (getLivestreamingEndChannelSuccess) {
      if (
        getLivestreamingEndChannelData?.status === 1 &&
        getLivestreamingEndChannelData?.data?.suggested_channels?.length > 0
      ) {
        setLiveListView(
          <div className="live-list-container">
            {getLivestreamingEndChannelData?.data?.suggested_channels?.map(
              (channel, index) => {
                return (
                  <LiveCard
                    channelId={channel?.live_stream?.channel_id}
                    livestreamerId={channel?.user?.id}
                    profilePhoto={channel?.user?.profile_photo?.original_photo}
                    livestreamerAchievements={
                      channel?.user?.live_streaming_achievements
                        ?.chat_badges_set
                    }
                    username={channel?.user?.username}
                    age={channel?.user?.appearance?.age}
                    location={
                      channel?.user?.location?.display_address ||
                      channel?.user?.location?.city ||
                      channel?.user?.location?.state
                    }
                    levellingBadge={channel?.user?.levelling_profile?.badge}
                    followerCount={
                      channel?.user?.live_streaming_meta?.follower_count
                    }
                    viewerCount={channel?.live_stream?.viewer_count}
                    diamondCount={
                      channel?.user?.live_streaming_meta
                        ?.lifetime_diamonds_earned
                    }
                    isFollowing={
                      channel?.user?.live_streaming_meta?.is_following
                    }
                    key={index}
                  />
                );
              }
            )}
          </div>
        );

        // Start countdown to redirect
        setRedirectInterval(
          setInterval(() => {
            setRedirectCountdown((prev) => prev - 1);
          }, 1000)
        );
      }
    } else if (getLivestreamingEndChannelError) {
      if (getLivestreamingEndChannelErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getLivestreamingEndChannelFetching,
    getLivestreamingEndChannelLoading,
    getLivestreamingEndChannelSuccess,
    getLivestreamingEndChannelError,
  ]);

  // Lifecycle | Check for update | redirectCountdown
  useEffect(() => {
    if (redirectCountdown < 1) {
      clearInterval(redirectInterval);
      // TODO: Redirect to other streamers
    }
  }, [redirectCountdown]);

  return (
    <div id="livestream-ended-page">
      <div className="padding-container">
        <div className="livestream-ended-title">
          {t("live.livestream_has_ended")}
        </div>

        <div
          className="back-button"
          onClick={() => onNavigate(routeConst.live.path)}
        >
          {t("live.back_to_search")}
        </div>

        <div className="redirect-text">
          {t("live.redirect_to_next_streamer", {
            second: `${redirectCountdown}s`,
          })}
        </div>
      </div>

      <Divider />

      <div className="padding-container">
        <div className="you-may-like-text">{t("live.you_may_like")}</div>

        {liveListView}
      </div>
    </div>
  );
};

export default LivestreamEnded;
