// General
import "./coin-request-element.scss";
// i18next
import { useTranslation } from "react-i18next";

// TODO: Get the text from pusher
const CoinRequestElement = () => {
  // i18next variables
  const { t } = useTranslation();

  return (
    <div id="chat-section-coin-request-element-subcomponent">
      {t("1on1.coin_request_1")}
    </div>
  );
};

export default CoinRequestElement;
