// General
import "./streamer-buttons.scss";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// Service
import { useLazyGetUserAioQuery } from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector } from "react-redux";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { useMediaQuery } from "@mui/material";
// Components
import GoPrivateButton from "../go-private-button/go-private-button";
import GoLiveButton from "../go-live-button/go-live-button";

const StreamerButtons = () => {
  // API variables
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();

  // General variables
  const [showButton, setShowButton] = useState(false);

  // Redux variables
  const isLoggedIn = useSelector((state) => state.public.isLoggedIn);
  const isHunterMode = useSelector((state) => state.privateCall.isHunterMode);

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // Router variables
  const location = useLocation();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!isLoggedIn) return;

    getUserAio(null, true);
  }, [isLoggedIn]);

  // Lifecycle | Check for update | Show Go Private Button based on route
  useEffect(() => {
    if (isMobile) {
      if (
        location.pathname.includes(routeConst.search.path) ||
        location.pathname === routeConst.live.path ||
        location.pathname === routeConst.videoCall.path
      ) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    } else {
      if (
        location.pathname === routeConst.live.path ||
        location.pathname === routeConst.videoCall.path
      ) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    }
  }, [location, isMobile]);

  if (
    (getUserAioData?.data?.private_calls?.user?.is_allowed_standby ||
      getUserAioData?.data?.live_streaming?.user?.is_allowed_live_streaming) &&
    !isHunterMode &&
    showButton
  ) {
    return (
      <div id="streamer-buttons-shared-subcomponent">
        <GoPrivateButton />

        <GoLiveButton />
      </div>
    );
  }
};

export default StreamerButtons;
