// General
import "./username-signup-complete.scss";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateJourneyNavigationPassthrough } from "../../../redux/store/registrationStore";
// Material UI
import { useMediaQuery } from "@mui/material";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Components
import Spinner from "../../shared/elements/spinner/spinner";

const UsernameSignupComplete = () => {
  // Redux variables
  const username = useSelector((state) => state.registration.username);
  const isProfileMeLoading = useSelector(
    (state) => state.registration.isProfileMeLoading
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Event Handlers | Button
  const onNextPage = () => {
    if (isProfileMeLoading) return;

    dispatch(updateJourneyNavigationPassthrough({}));
  };

  return (
    <div id="username-signup-complete-page">
      <div className="top-container">
        <div className="username-description-container">
          <div className="username-description-one">
            {t("signup.hi")} {username}!
          </div>

          <div className="username-description-two">
            <Trans
              i18nKey={"signup.username_signup_complete_title"}
              components={{ br: <br /> }}
            />
            <br />
            <br />
            {t("signup.username_signup_complete_description")}
          </div>
        </div>
      </div>

      <div className="bottom-container">
        <div
          className={`continue-button ${
            isMobile ? "full-width" : "fit-content"
          }`}
          onClick={onNextPage}
        >
          {isProfileMeLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("common.continue")
          )}
        </div>
      </div>
    </div>
  );
};

export default UsernameSignupComplete;
