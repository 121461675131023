// General
import "./slide-in-out-alert-overlay.scss";
import { useState, useEffect, useRef } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateLivestreamKingOfTheMonthTrigger } from "../../../../redux/store/debugStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { useMediaQuery } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Components
import GiftAlertElement from "./gift-alert-element/gift-alert-element";
import VipEntranceElement from "./vip-entrance-element/vip-entrance-element";

const SlideInOutAlertOverlay = () => {
  // General variables
  const [counter, setCounter] = useState(1);
  const counterRef = useRef(1);

  // Render variables
  const [giftAlertView, setGiftAlertView] = useState([]);

  // Redux variables
  const livestreamGiftAnimation = useSelector(
    (state) => state.livestreaming.livestreamGiftAnimation
  );
  const livestreamKingOfTheMonthTrigger = useSelector(
    (state) => state.livestreaming.livestreamKingOfTheMonthTrigger
  );
  const livestreamVipEntrance = useSelector(
    (state) => state.livestreaming.livestreamVipEntrance
  );
  const isCoAnchor = useSelector((state) => state.livestreaming.isCoAnchor);
  const coAnchorUser1 = useSelector(
    (state) => state.livestreaming.coAnchorUser1
  );
  const coAnchorUser2 = useSelector(
    (state) => state.livestreaming.coAnchorUser2
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 900px)");

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted & Unmounted
  useEffect(() => {
    return () => {
      dispatch(updateLivestreamKingOfTheMonthTrigger(null));
    };
  }, []);

  // Lifecycle | Check for update | livestreamGiftAnimation
  useEffect(() => {
    // Only show gift alert on mobile
    if (!isMobile) return;

    if (livestreamGiftAnimation) {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-20-20.8",
        },
      });

      handleGiftAlertView(livestreamGiftAnimation, counterRef.current);

      setCounter((oldCounter) => oldCounter + 1);
      counterRef.current += 1;
    }
  }, [livestreamGiftAnimation]);

  // Lifecycle | Check for update | livestreamKingOfTheMonthTrigger
  useEffect(() => {
    if (livestreamKingOfTheMonthTrigger) {
      handleVipEntranceView(
        livestreamKingOfTheMonthTrigger,
        counterRef.current
      );

      setCounter((oldCounter) => oldCounter + 1);
      counterRef.current += 1;
    }
  }, [livestreamKingOfTheMonthTrigger]);

  // Lifecycle | Check for update | livestreamKingOfTheMonthTrigger
  useEffect(() => {
    if (livestreamVipEntrance) {
      handleVipEntranceView(livestreamVipEntrance, counterRef.current);

      setCounter((oldCounter) => oldCounter + 1);
      counterRef.current += 1;
    }
  }, [livestreamVipEntrance]);

  // Utility Functions
  const handleGiftAlertView = (livestreamGiftAnimation, counter) => {
    // Create a new div element
    const giftDiv = (
      <GiftAlertElement
        userPhoto={livestreamGiftAnimation?.user_photo}
        profileDecorations={livestreamGiftAnimation?.profile_decorations}
        username={livestreamGiftAnimation?.username}
        levellingBadge={livestreamGiftAnimation?.levelling_profile?.badge}
        giftName={livestreamGiftAnimation?.data?.gift_name}
        giftAsset={livestreamGiftAnimation?.data?.gift_graphic}
        // Style
        animation={getSelectedCoAnchorUser(
          livestreamGiftAnimation?.recipient?.id
        )}
        key={counter}
      />
    );

    // Push the gift alert to the gift alert view
    setGiftAlertView((oldArray) => [...oldArray, giftDiv]);

    // Remove the gift alert after 3 seconds, FIFO
    setTimeout(() => {
      setGiftAlertView((oldArray) => oldArray.slice(1));
    }, 3000);
  };
  const handleVipEntranceView = (livestreamVipEntrance, counter) => {
    // Create a new div element
    const vipDiv = (
      <VipEntranceElement
        asset={
          livestreamVipEntrance?.profile_decorations
            ?.live_streaming_entrance_url
        }
        profilePhoto={livestreamVipEntrance?.user_photo}
        username={livestreamVipEntrance?.username}
        description={
          livestreamVipEntrance?.profile_decorations
            ?.live_streaming_entrance_description || t("pusher.has_joined")
        }
        showCenterLine={false}
        animationDuration={6}
        key={counter}
      />
    );

    // Push the gift alert to the gift alert view
    setGiftAlertView((oldArray) => [...oldArray, vipDiv]);

    // Remove the gift alert after 5 seconds, FIFO
    setTimeout(() => {
      setGiftAlertView((oldArray) => oldArray.slice(1));
    }, 6000);
  };
  const getSelectedCoAnchorUser = (userId) => {
    if (isCoAnchor) {
      if (userId === coAnchorUser1?.userId) {
        return "slideInAndOutLeft";
      } else if (userId === coAnchorUser2?.userId) {
        return "slideInAndOutRight";
      }
    } else {
      return "slideInAndOutLeft";
    }
  };

  return (
    <div id="livestream-slide-in-out-alert-overlay-subcomponent">
      <div className="padding-container">{giftAlertView}</div>
    </div>
  );
};

export default SlideInOutAlertOverlay;
