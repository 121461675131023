// General
import "./chat-input.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetProfileQuery,
  useLazyGetUserAioQuery,
  usePostLivestreamingChannelChatMutation,
} from "../../../../../services/data.service";
import { removeHTMLEscape } from "../../../../../services/regex.service";
// Static Data
import emojiConst from "../../../../../const/emojiConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateCasinoGameDialog,
  updateProductPurchaseDialog,
} from "../../../../../redux/store/dialogStore";
import { updateLiveViewPusherTrigger } from "../../../../../redux/store/pusherStore";
import {
  updateDisableSwipe,
  updateLivestreamChatMessages,
  updateLivestreamFloatingEmoji,
} from "../../../../../redux/store/livestreamingStore";
import { updatePurchaseDialogTab } from "../../../../../redux/store/productsStore";
import { updateIsGameOpen } from "../../../../../redux/store/gameStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Sentry
import * as Sentry from "@sentry/react";
// Material UI
import {
  TextField,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  useMediaQuery,
} from "@mui/material";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import AssetManager from "../../../../utility/manager/asset-manager/asset-manager";
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";

const ChatInput = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();
  const [
    postLivestreamingConversation,
    {
      data: postLivestreamingConversationData,
      error: postLivestreamingConversationErrorData,
      isLoading: postLivestreamingConversationLoading,
      isSuccess: postLivestreamingConversationSuccess,
      isError: postLivestreamingConversationError,
    },
  ] = usePostLivestreamingChannelChatMutation();

  // Constant variables
  const emojiList = emojiConst.livestreamEmoji;

  // General variables
  const [inputValue, setInputValue] = useState("");
  const [hideEmojiList, setHideEmojiList] = useState(false);
  const [emojiTimer, setEmojiTimer] = useState(null);

  // Redux variables
  const channelId = useSelector((state) => state.livestreaming.channelId);
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 900px)");
  const isTablet = useMediaQuery("(min-width: 900px)");
  const isDesktop = useMediaQuery("(min-width: 1305px)");

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getAsset = AssetManager();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
    getUserAio(null, true);
  }, []);

  // Event Handlers | Button
  const onSendMessage = () => {
    if (inputValue === "") return;

    const obj = {
      channel_id: channelId,
      message: inputValue,
    };

    postLivestreamingConversation(obj);

    // Clear input after send
    setInputValue("");

    // GTM (Not Tested)
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-20-SendChat-Button",
      },
    });
  };
  const onSendEmoji = (emoji) => {
    let emojiUnicode;

    for (let i = 0; i < emojiConst.livestreamEmoji.length; i++) {
      if (emojiConst.livestreamEmoji[i].emojiId === emoji) {
        emojiUnicode = emojiConst.livestreamEmoji[i].emojiUnicode;
        break;
      }
    }

    // Prepare Payload
    let emojiPayload = {
      message: {
        type: "emoji",
        user_id: getProfileData?.data?.id,
        username: getProfileData?.data?.username || t("common.new_user"),
        user_photo: getProfileData?.data?.profile_photo?.original_photo,
        levelling_profile: getProfileData?.data?.levelling_profile,
        data: {
          emoji_id: emoji,
          emoji_unicode: emojiUnicode,
        },
        profile_decorations: getProfileData?.data?.profile_decorations,
      },
      channel_id: channelId,
    };
    let emojiAnimationPayload = {
      message: {
        type: "emoji_animation",
        user_id: getProfileData?.data?.id,
        username: getProfileData?.data?.username || t("common.new_user"),
        user_photo:
          getProfileData?.data?.profile_photos?.photo_status === "REJECTED"
            ? null
            : getProfileData?.data?.profile_photos?.original_photo,
        levelling_profile: getProfileData?.data?.levelling_profile,
        data: {
          emoji_id: emoji,
          emoji_unicode: emojiUnicode,
        },
      },
      channel_id: channelId,
    };

    // Send emoji animation payload to local emoji layer
    dispatch(updateLivestreamFloatingEmoji(emojiAnimationPayload?.message));

    // Send emoji animation payload to pusher
    dispatch(updateLiveViewPusherTrigger(emojiAnimationPayload));

    // After sending emoji, set a timer to prevent flooding local & pusher chat
    if (!emojiTimer) {
      setEmojiTimer(
        setTimeout(() => {
          setEmojiTimer(null);
        }, 60000)
      );

      // Send emoji payload to local chat
      dispatch(updateLivestreamChatMessages(emojiPayload?.message));

      // Send emoji payload to pusher
      dispatch(updateLiveViewPusherTrigger(emojiPayload));
    }

    // GTM (Not Tested)
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-20-SendEmoji-Button",
      },
    });
  };
  const onEnterPress = (event) => {
    if (event.keyCode === 13 && event.shiftKey === false) {
      onSendMessage();
    }
  };
  const onOpenGameLauncher = () => {
    if (isTablet && !isDesktop) {
      dispatch(updateCasinoGameDialog(true));
    } else {
      dispatch(updateIsGameOpen());
    }

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-20-ChatInput-GameLauncher-Button",
      },
    });
  };

  // Event Handlers | MUI Text Fields
  const onInputChange = (e) => {
    if (removeHTMLEscape.test(e.target.value)) {
      // Remove HTML Escape (prevent XSS)
      setInputValue("");

      // Send to Sentry
      Sentry.captureMessage("PWA-20-Chat-Input-HTML-Escape-Detected");

      // Send to GTM
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-20-Chat-Input-HTML-Escape-Detected",
        },
      });
    } else {
      setInputValue(e.target.value);
    }
  };
  const onInputFocus = (focus) => {
    // setHideEmojiList(focus);
  };

  // Event Handlers | MUI Dialog
  const onOpenProductsDialog = () => {
    dispatch(updatePurchaseDialogTab(0));
    dispatch(updateProductPurchaseDialog(true));
  };

  // Utility Functions
  const handleOnTouchOver = (event) => {
    dispatch(updateDisableSwipe(true));
  };
  const handleOnTouchEnd = (event) => {
    dispatch(updateDisableSwipe(false));
  };

  return (
    <div
      id="interactable-overlay-livestream-chat-input-subcomponent"
      onTouchMove={handleOnTouchOver}
      onTouchEnd={handleOnTouchEnd}
    >
      <div className="gift-and-input-container">
        {!isDesktop && (
          <div className="more-gifts-container" onClick={onOpenProductsDialog}>
            {getIcon("moreGiftBadge", "more-gifts")}
          </div>
        )}

        <div className="input-container">
          <TextField
            className={`${
              isMobile ? "transparent-field" : "non-transparent-field"
            }`}
            value={inputValue}
            placeholder={t("live.say_something")}
            onChange={onInputChange}
            onFocus={() => onInputFocus(true)}
            onBlur={() => onInputFocus(false)}
            variant="standard"
            InputProps={{ disableUnderline: true }}
            inputProps={{
              maxLength:
                getUserAioData?.data?.live_streaming
                  ?.chat_messages_text_limit || 150,
            }}
            onKeyDown={onEnterPress}
          />
        </div>

        {!isMobile && (
          <div className="send-icon-container" onClick={onSendMessage}>
            {getIcon("sendMessageDarkIcon", "send-icon")}
          </div>
        )}
      </div>

      {!isMobile && (
        <div>
          <div className="emoji-list-container">
            {getUserAioData?.data?.modules?.gaming_enabled && (
              <div
                className="game-launcher-icon-container"
                onClick={onOpenGameLauncher}
              >
                <img className="chip-logo" src={getAsset("casinoGameIcon")} />
              </div>
            )}

            <div
              className="blow-kiss-emoji-container"
              onClick={() => onSendEmoji("face_blowing_a_kiss")}
            >
              {getIcon("blowKissEmoji", "blow-kiss-emoji")}
            </div>

            <div
              className="heart-eyes-emoji-container"
              onClick={() => onSendEmoji("smiling_face_with_heart_eyes")}
            >
              {getIcon("heartEyesEmoji", "heart-eyes-emoji")}
            </div>

            <div
              className="thumbs-up-emoji-container"
              onClick={() => onSendEmoji("c_thumbs_up")}
            >
              {getIcon("thumbsUpEmoji", "thumbs-up-emoji")}
            </div>

            <div
              className="fire-emoji-container"
              onClick={() => onSendEmoji("fire")}
            >
              {getIcon("fireEmoji", "fire-emoji")}
            </div>
          </div>
        </div>
      )}

      {isMobile && (hideEmojiList || inputValue) && (
        <div className="send-icon-container" onClick={onSendMessage}>
          {getIcon("sendMessageLightIcon", "send-icon")}
        </div>
      )}

      {false && isMobile && !hideEmojiList && !inputValue && (
        <div className="emoji-list-container">
          <div
            className="blow-kiss-emoji-container"
            onClick={() => onSendEmoji("face_blowing_a_kiss")}
          >
            {getIcon("blowKissEmoji", "blow-kiss-emoji")}
          </div>

          <div
            className="heart-eyes-emoji-container"
            onClick={() => onSendEmoji("smiling_face_with_heart_eyes")}
          >
            {getIcon("heartEyesEmoji", "heart-eyes-emoji")}
          </div>

          <div
            className="thumbs-up-emoji-container"
            onClick={() => onSendEmoji("c_thumbs_up")}
          >
            {getIcon("thumbsUpEmoji", "thumbs-up-emoji")}
          </div>

          <div
            className="fire-emoji-container"
            onClick={() => onSendEmoji("fire")}
          >
            {getIcon("fireEmoji", "fire-emoji")}
          </div>
        </div>
      )}

      {isMobile && !hideEmojiList && !inputValue && (
        <div className="speed-dial-container">
          <SpeedDial
            className="custom-speed-dial-color custom-speed-dial-margin-bottom speed-dial"
            ariaLabel="SpeedDial basic example"
            icon={<EmojiEmotionsIcon />}
          >
            {emojiList?.map((emoji, index) => (
              <SpeedDialAction
                className="speed-dial-action"
                key={index}
                icon={emoji?.emojiImage}
                onClick={() => onSendEmoji(emoji?.emojiId)}
                // tooltipTitle={emoji.name}
              />
            ))}
          </SpeedDial>
        </div>
      )}
    </div>
  );
};

export default ChatInput;
