// General
import "./video-call-summary.scss";
import { useState, useEffect } from "react";
// Services
import { useLazyGetProfileQuery } from "../../../services/data.service";
// Static Data
import routeConst from "../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  // General Functions
  resetPrivateCallStandbyTimer,
  resetCallDurationRemainingTimer,

  // Interval Functions
  clearStandbyTimerInterval,
  clearStandbyKeepAliveInterval,
  clearRoomKeepAliveInterval,
  clearCallDurationRemainingInterval,

  // Callee Functions
  updateCalleeId,
  updateCalleeUsername,
  updateCalleeProfilePhoto,

  // Utility Functions
  resetPrivateCallStore,
} from "../../../redux/store/privateCallStore";
import { updateVideoCallPusherUnsubscribe } from "../../../redux/store/pusherStore";
// Material UI
import { Divider, List, ListItemButton, Collapse } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../utility/manager/icon-manager/icon-manager";

const VideoCallSummary = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // General variables
  const [open, setOpen] = useState([]);

  // Redux variables
  const isPrivateStandbyMode = useSelector(
    (state) => state.privateCall.isPrivateStandbyMode
  );
  const isCaller = useSelector((state) => state.privateCall.isCaller);
  const calleeEndVideoCallSummary = useSelector(
    (state) => state.privateCall.calleeEndVideoCallSummary
  );
  const callerEndVideoCallSummary = useSelector(
    (state) => state.privateCall.callerEndVideoCallSummary
  );
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    dispatch(updateVideoCallPusherUnsubscribe({}));

    // Clear intervals from video call page in case it is not clear before
    dispatch(clearStandbyTimerInterval());
    dispatch(clearStandbyKeepAliveInterval());
    dispatch(clearRoomKeepAliveInterval());
    dispatch(clearCallDurationRemainingInterval());

    // Reset timer from video call page in case it is not clear before
    dispatch(resetPrivateCallStandbyTimer());
    dispatch(resetCallDurationRemainingTimer());

    // Map call requests to MUI Collapse with default state of false
    for (
      let i = 0;
      i < calleeEndVideoCallSummary?.incomingCallRequests?.length;
      i++
    ) {
      setOpen((prev) => [...prev, false]);
    }

    getProfile(null, true);
  }, []);

  // Event Handlers | Button
  const onBackToHome = () => {
    resetPrivateCallState();
    onNavigate(routeConst.live.path);
  };
  const onRateVideoCall = () => {
    onNavigate(routeConst.videoCall.rate.path);
  };

  // Event Handlers | MUI Collapse
  const handleClick = (index) => {
    setOpen((prev) => {
      let newArr = [...prev];
      newArr[index] = !newArr[index];
      return newArr;
    });
  };

  // Utility Functions
  const getFormattedTime = (seconds) => {
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds / 60) % 60);
    let secs = Math.floor(seconds % 60);

    let formattedHours = hours < 10 ? "0" + hours : hours;
    let formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    let formattedSeconds = secs < 10 ? "0" + secs : secs;

    return formattedHours + ":" + formattedMinutes + ":" + formattedSeconds;
  };
  const resetPrivateCallState = () => {
    dispatch(resetPrivateCallStore());

    // Reintialize some private call variables
    dispatch(updateCalleeId(getProfileData?.data?.id));
    dispatch(updateCalleeUsername(getProfileData?.data?.username));
    dispatch(
      updateCalleeProfilePhoto(
        getProfileData?.data?.profile_photo?.original_photo
      )
    );
  };

  if (isPrivateStandbyMode) {
    if (isCaller) {
      return (
        <div id="video-call-summary-page">
          <div className="max-width-container">
            <div className="padding-container">
              <div className="video-call-ended-text">
                {t("1on1.video_call_has_ended")}
              </div>

              <div className="first-row">
                <div className="standby-duration-container">
                  <div className="standby-duration">
                    {getFormattedTime(callerEndVideoCallSummary?.callDuration)}
                  </div>
                  <div className="standby-duration-label">
                    {t("1on1.duration")}
                  </div>
                </div>
              </div>
            </div>

            <div className="diamonds-earned-padding-container">
              <div className="diamonds-earned-category-container">
                <div className="diamonds-earned-category-label">
                  <div className="category-label">{t("1on1.gifts_sent")}</div>
                  <div className="category-label">{t("1on1.tips_sent")}</div>
                  <div className="category-label">
                    {t("1on1.tip_coins_sent")}
                  </div>
                  <div className="category-label">
                    {t("1on1.tip_actions_requested")}
                  </div>
                  <div className="category-label">
                    {t("1on1.tip_actions_completed")}
                  </div>
                  <div className="category-label">
                    {t("1on1.charged_messages_sent")}
                  </div>
                </div>

                <div className="diamonds-earned-category-output">
                  <div className="diamonds-earned">
                    {callerEndVideoCallSummary?.giftsSent}
                  </div>
                  <div className="diamonds-earned">
                    {callerEndVideoCallSummary?.tipSent}
                  </div>
                  <div className="diamonds-earned">
                    {callerEndVideoCallSummary?.tipCoinsSent}
                  </div>
                  <div className="diamonds-earned">
                    {callerEndVideoCallSummary?.tipActionRequested}
                  </div>
                  <div className="diamonds-earned">
                    {callerEndVideoCallSummary?.tipActionCompleted}
                  </div>
                  <div className="diamonds-earned">
                    {callerEndVideoCallSummary?.chargedMessagesSent}
                  </div>
                </div>
              </div>
            </div>

            <div className="continue-button-container">
              <div className="continue-button" onClick={onRateVideoCall}>
                {t("common.continue")}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div id="video-call-summary-page">
          <div className="max-width-container">
            <div className="padding-container">
              <div className="video-call-ended-text">
                {t("1on1.video_call_has_ended")}
              </div>

              <div className="first-row">
                <div className="standby-duration-container">
                  <div className="standby-duration">
                    {getFormattedTime(
                      calleeEndVideoCallSummary?.standbyDuration
                    )}
                  </div>
                  <div className="standby-duration-label">
                    {t("1on1.stand_by_duration")}
                  </div>
                </div>

                <div className="total-standby-diamonds-earned-container">
                  <div className="total-standby-diamonds-earned">
                    {calleeEndVideoCallSummary?.totalStandbyDiamondsEarned || 0}
                  </div>
                  <div className="total-standby-diamonds-earned-label">
                    {t("1on1.total_standby_diamonds_earned")}
                  </div>
                </div>
              </div>

              <div className="second-row">
                <div className="call-duration-container">
                  <div className="call-duration">
                    {getFormattedTime(calleeEndVideoCallSummary?.callDuration)}
                  </div>
                  <div className="call-duration-label">
                    {t("1on1.call_duration")}
                  </div>
                </div>

                <div className="total-diamonds-earned-container">
                  <div className="total-diamonds-earned">
                    {calleeEndVideoCallSummary?.totalDiamondsEarned || 0}
                  </div>
                  <div className="total-diamonds-earned-label">
                    {t("1on1.total_diamonds_earned")}
                  </div>
                </div>
              </div>
            </div>

            <Divider />

            <div className="diamonds-earned-padding-container">
              <div className="diamonds-earned-text">
                {t("1on1.diamonds_earned")}:
              </div>

              <div className="diamonds-earned-category-container">
                <div className="diamonds-earned-category-label">
                  <div className="category-label">{t("1on1.standby")}</div>
                  <div className="category-label">{t("1on1.video_call")}</div>
                  <div className="category-label">{t("1on1.tips_earned")}</div>
                  <div className="category-label">
                    {t("1on1.gifts_received")}
                  </div>
                  <div className="category-label">
                    {t("1on1.charged_messages")}
                  </div>
                  <div className="category-label">
                    {t("1on1.actions_completed")}
                  </div>
                  <div className="category-label">
                    {t("1on1.requests_from_daddy")}
                  </div>
                </div>

                <div className="diamonds-earned-category-output">
                  <div className="diamonds-earned">
                    <div className="diamond-icon-container">
                      {getIcon("sbDiamondIcon", "diamond-icon")}
                    </div>
                    +{calleeEndVideoCallSummary?.standbyDiamondsEarned}
                  </div>
                  <div className="diamonds-earned">
                    <div className="diamond-icon-container">
                      {getIcon("sbDiamondIcon", "diamond-icon")}
                    </div>
                    +{calleeEndVideoCallSummary?.videoCallDiamondsEarned}
                  </div>
                  <div className="diamonds-earned">
                    <div className="diamond-icon-container">
                      {getIcon("sbDiamondIcon", "diamond-icon")}
                    </div>
                    +{calleeEndVideoCallSummary?.tipsDiamondsEarned}
                  </div>
                  <div className="diamonds-earned">
                    <div className="diamond-icon-container">
                      {getIcon("sbDiamondIcon", "diamond-icon")}
                    </div>
                    +{calleeEndVideoCallSummary?.giftsDiamondsEarned}
                  </div>
                  <div className="diamonds-earned">
                    <div className="diamond-icon-container">
                      {getIcon("sbDiamondIcon", "diamond-icon")}
                    </div>
                    +{calleeEndVideoCallSummary?.chargedMessagesDiamondsEarned}
                  </div>
                  <div className="diamonds-earned">
                    {calleeEndVideoCallSummary?.actionCompleted}
                  </div>
                  <div className="diamonds-earned">
                    {calleeEndVideoCallSummary?.requestsFromDaddy}
                  </div>
                </div>
              </div>
            </div>

            <Divider />

            {calleeEndVideoCallSummary?.incomingCallRequests?.length > 0 && (
              <div className="incoming-call-request-padding-container">
                <div className="your-call-details-text">
                  {t("1on1.your_call_details")}
                </div>

                <div className="expandable-container">
                  {calleeEndVideoCallSummary?.incomingCallRequests?.map(
                    (callRequest, index) => (
                      <List
                        sx={{
                          width: "100%",
                          bgcolor: "background.paper",
                        }}
                        key={index}
                      >
                        <ListItemButton onClick={() => handleClick(index)}>
                          <div className="expand-header">
                            <div className="incoming-call-request-username">
                              {callRequest?.call_request?.caller?.username}
                            </div>

                            {open[index] ? <ExpandLess /> : <ExpandMore />}
                          </div>
                        </ListItemButton>

                        <Collapse in={open[index]} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            <div className="diamonds-earned-category-container">
                              <div className="diamonds-earned-category-label">
                                <div className="category-label">
                                  {t("1on1.video_call")}
                                </div>
                                <div className="category-label">
                                  {t("1on1.tips")}
                                </div>
                                <div className="category-label">
                                  {t("1on1.gifts")}
                                </div>
                                <div className="category-label">
                                  {t("1on1.actions_completed")}
                                </div>
                                <div className="category-label">
                                  {t("1on1.requests_from_user", {
                                    username:
                                      callRequest?.call_request?.caller
                                        ?.username,
                                  })}
                                </div>
                              </div>

                              <div className="diamonds-earned-category-output">
                                <div className="diamonds-earned">
                                  <div className="diamond-icon-container">
                                    {getIcon("sbDiamondIcon", "diamond-icon")}
                                  </div>
                                  +{callRequest?.summary?.call_charges_sum}
                                </div>
                                <div className="diamonds-earned">
                                  <div className="diamond-icon-container">
                                    {getIcon("sbDiamondIcon", "diamond-icon")}
                                  </div>
                                  +{callRequest?.summary?.tippings_sum}
                                </div>
                                <div className="diamonds-earned">
                                  <div className="diamond-icon-container">
                                    {getIcon("sbDiamondIcon", "diamond-icon")}
                                  </div>
                                  +{callRequest?.summary?.giftings_sum}
                                </div>
                                <div className="diamonds-earned">
                                  {
                                    callRequest?.summary
                                      ?.customized_tipping_count_accepted
                                  }
                                </div>
                                <div className="diamonds-earned">
                                  {
                                    callRequest?.summary
                                      ?.customized_tipping_count
                                  }
                                </div>
                              </div>
                            </div>
                          </List>
                        </Collapse>
                      </List>
                    )
                  )}
                </div>
              </div>
            )}

            <div className="continue-button-container">
              <div className="continue-button" onClick={onBackToHome}>
                {t("common.continue")}
              </div>
            </div>
          </div>
        </div>
      );
    }
  } else {
    if (isDaddy) {
      return (
        <div id="video-call-summary-page">
          <div className="max-width-container">
            <div className="padding-container">
              <div className="video-call-ended-text">
                {t("1on1.video_call_has_ended")}
              </div>

              <div className="first-row">
                <div className="standby-duration-container">
                  <div className="standby-duration">
                    {getFormattedTime(callerEndVideoCallSummary?.callDuration)}
                  </div>
                  <div className="standby-duration-label">
                    {t("1on1.duration")}
                  </div>
                </div>
              </div>
            </div>

            <div className="diamonds-earned-padding-container">
              <div className="diamonds-earned-category-container">
                <div className="diamonds-earned-category-label">
                  <div className="category-label">{t("1on1.gifts_sent")}</div>
                  <div className="category-label">{t("1on1.tips_sent")}</div>
                  <div className="category-label">
                    {t("1on1.tip_coins_sent")}
                  </div>
                  <div className="category-label">
                    {t("1on1.tip_actions_requested")}
                  </div>
                  <div className="category-label">
                    {t("1on1.tip_actions_completed")}
                  </div>
                  <div className="category-label">
                    {t("1on1.charged_messages_sent")}
                  </div>
                </div>

                <div className="diamonds-earned-category-output">
                  <div className="diamonds-earned">
                    {callerEndVideoCallSummary?.giftsSent}
                  </div>
                  <div className="diamonds-earned">
                    {callerEndVideoCallSummary?.tipSent}
                  </div>
                  <div className="diamonds-earned">
                    {callerEndVideoCallSummary?.tipCoinsSent}
                  </div>
                  <div className="diamonds-earned">
                    {callerEndVideoCallSummary?.tipActionRequested}
                  </div>
                  <div className="diamonds-earned">
                    {callerEndVideoCallSummary?.tipActionCompleted}
                  </div>
                  <div className="diamonds-earned">
                    {callerEndVideoCallSummary?.chargedMessagesSent}
                  </div>
                </div>
              </div>
            </div>

            <div className="continue-button-container">
              <div className="continue-button" onClick={onRateVideoCall}>
                {t("common.continue")}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div id="video-call-summary-page">
          <div className="max-width-container">
            <div className="padding-container">
              <div className="video-call-ended-text">
                {t("1on1.video_call_has_ended")}
              </div>

              <div className="second-row">
                <div className="call-duration-container">
                  <div className="call-duration">
                    {getFormattedTime(calleeEndVideoCallSummary?.callDuration)}
                  </div>
                  <div className="call-duration-label">
                    {t("1on1.call_duration")}
                  </div>
                </div>

                <div className="total-diamonds-earned-container">
                  <div className="total-diamonds-earned">
                    {calleeEndVideoCallSummary?.totalDiamondsEarned || 0}
                  </div>
                  <div className="total-diamonds-earned-label">
                    {t("1on1.total_diamonds_earned")}
                  </div>
                </div>
              </div>
            </div>

            <Divider />

            <div className="diamonds-earned-padding-container">
              <div className="diamonds-earned-text">
                {t("1on1.diamonds_earned")}:
              </div>

              <div className="diamonds-earned-category-container">
                <div className="diamonds-earned-category-label">
                  <div className="category-label">{t("1on1.video_call")}</div>
                  <div className="category-label">{t("1on1.tips_earned")}</div>
                  <div className="category-label">
                    {t("1on1.gifts_received")}
                  </div>
                  <div className="category-label">
                    {t("1on1.actions_completed")}
                  </div>
                  <div className="category-label">
                    {t("1on1.requests_from_daddy")}
                  </div>
                </div>

                <div className="diamonds-earned-category-output">
                  <div className="diamonds-earned">
                    <div className="diamond-icon-container">
                      {getIcon("sbDiamondIcon", "diamond-icon")}
                    </div>
                    +{calleeEndVideoCallSummary?.videoCallDiamondsEarned}
                  </div>
                  <div className="diamonds-earned">
                    <div className="diamond-icon-container">
                      {getIcon("sbDiamondIcon", "diamond-icon")}
                    </div>
                    +{calleeEndVideoCallSummary?.tipsDiamondsEarned}
                  </div>
                  <div className="diamonds-earned">
                    <div className="diamond-icon-container">
                      {getIcon("sbDiamondIcon", "diamond-icon")}
                    </div>
                    +{calleeEndVideoCallSummary?.giftsDiamondsEarned}
                  </div>
                  <div className="diamonds-earned">
                    {calleeEndVideoCallSummary?.actionCompleted}
                  </div>
                  <div className="diamonds-earned">
                    {calleeEndVideoCallSummary?.requestsFromDaddy}
                  </div>
                </div>
              </div>
            </div>

            <div className="continue-button-container">
              <div className="continue-button" onClick={onBackToHome}>
                {t("common.continue")}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
};

export default VideoCallSummary;
