// General
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // General variables
  surveyOpenCounter: null,
};

export const surveySlice = createSlice({
  name: "survey",
  initialState,
  reducers: {
    // General Functions
    updateSurveyOpenCounter: (state, action) => {
      state.surveyOpenCounter = action.payload;
    },

    // Utility Functions
    resetSurveyStore: () => {
      return { ...initialState };
    },
  },
});

export const {
  // General Functions
  updateSurveyOpenCounter,

  // Utility Functions
  resetSurveyStore,
} = surveySlice.actions;

export default surveySlice.reducer;
