// General
// Components
import OpenPackageAfterAddCard from "../../helper/open-package-after-add-card/open-package-after-add-card";
import JourneyNavigationHelper from "../../helper/journey-navigation-helper/journey-navigation-helper";

const HelperMounted = () => {
  return (
    <div id="helper-mounted-shadow-component">
      <OpenPackageAfterAddCard />
      <JourneyNavigationHelper />
    </div>
  );
};

export default HelperMounted;
