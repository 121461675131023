// General
import "./private-call-tab.scss";
import { useEffect } from "react";
// Services
import { useLazyGetUserAioQuery } from "../../../../services/data.service";
// Static Data
import leaderboardConst from "../../../../const/leaderboardConst";
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updatePrivateCallTab } from "../../../../redux/store/leaderboardStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// embla-carousel-react
import useEmblaCarousel from "embla-carousel-react";
// Material UI
import { Tabs, Tab, Box } from "@mui/material";
import PropTypes from "prop-types";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import Spinner from "../../../shared/elements/spinner/spinner";
import EventsTab from "./events-tab/events-tab";
import WeeklyTab from "../../../shared/leaderboard/weekly-tab/weekly-tab";
import MonthlyTab from "../../../shared/leaderboard/monthly-tab/monthly-tab";

const PrivateCallTab = () => {
  // API variables
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();

  // Redux variables
  const privateCallTab = useSelector(
    (state) => state.leaderboard.privateCallTab
  );
  const dispatch = useDispatch();

  // Embla variables
  const [emblaRef, emblaApi] = useEmblaCarousel({
    duration: 15,
    watchDrag: false,
  });

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-19-19.2",
      },
    });

    getUserAio(null, true);
  }, []);

  // Lifecycle | Mounted | emblaApi
  useEffect(() => {
    if (!emblaApi) return;
    emblaApi?.scrollTo(privateCallTab);

    emblaApi?.on("select", () => {
      dispatch(updatePrivateCallTab(emblaApi?.selectedScrollSnap()));
    });
  }, [emblaApi]);

  // Lifecycle | Check for update | privateCallTab
  useEffect(() => {
    emblaApi?.scrollTo(privateCallTab);
  }, [privateCallTab]);

  // Event Handlers | MUI Tabs
  const onTabChange = (event, newValue) => {
    dispatch(updatePrivateCallTab(newValue));
  };

  // Render Functions | API Response
  if (getUserAioFetching || getUserAioLoading) {
    return <Spinner />;
  } else if (getUserAioSuccess) {
    if (getUserAioData?.status === 1) {
      return (
        <div id="leaderboard-one-on-one-tab-subcomponent">
          <div className="tab-container">
            <Tabs
              className="custom-tabs"
              value={privateCallTab}
              onChange={onTabChange}
              TabIndicatorProps={{ style: { backgroundColor: "#710d0d" } }}
              centered
            >
              {getUserAioData?.data?.private_call_leaderboard_config?.map(
                (tab, index) => (
                  <Tab label={t(tab?.name)} {...a11yProps(index)} key={index} />
                )
              )}
            </Tabs>

            <div ref={emblaRef} className="embla">
              <div className="embla__container">
                {getUserAioData?.data?.private_call_leaderboard_config?.map(
                  (tab, index) => (
                    <div className="embla__slide" key={index}>
                      {!tab?.board_period_type && privateCallTab === index ? (
                        <EventsTab state={leaderboardConst.type.privateCall} />
                      ) : tab?.board_period_type ===
                        leaderboardConst.boardPeriodType
                          .daily ? null : tab?.board_period_type ===
                          leaderboardConst.boardPeriodType.weekly &&
                        privateCallTab === index ? (
                        <WeeklyTab state={leaderboardConst.type.privateCall} />
                      ) : tab?.board_period_type ===
                          leaderboardConst.boardPeriodType.monthly &&
                        privateCallTab === index ? (
                        <MonthlyTab state={leaderboardConst.type.privateCall} />
                      ) : null}
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  } else if (getUserAioError) {
    if (getUserAioErrorData?.status === 401) {
      onNavigate(routeConst.logout.path);
    }
  }
};

export default PrivateCallTab;

// Material UI | Tab Functions
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }} className="tab-box-container">
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
