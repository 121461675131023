// General
import "./personal-information.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetJourneyQuestionsQuery,
  useUpdateEditUserProfileMutation,
} from "../../../../../services/data.service";
// Static Data
import editProfileConst from "../../../../../const/editProfileConst";
import userConst from "../../../../../const/userConst";
import utilityConst from "../../../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updatePosition,
  updateIndustry,
  updateEducation,
  updateRelationshipStatus,
  updateChildren,
  updateSmoking,
  updateDrinking,
  updateAlcoholPreference,
  updateUpdateProfileDialogView,
  updateUpdateProfileFullscreenTab,
  updateProfileCompletionState,
} from "../../../../../redux/store/editProfileStore";
import {
  updateProfileCompletionPercentage,
  updateProfileRequirementPopup,
} from "../../../../../redux/store/profileStore";
import {
  updateRestartRegistrationDialog,
  updateUpdateProfileFullscreenDialog,
} from "../../../../../redux/store/dialogStore";
import { updateInfoToast } from "../../../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Sentry
import * as Sentry from "@sentry/react";
// Material UI
import { Divider, MenuItem, FormControl, Select } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../../../shared/elements/spinner/spinner";
import ModerationStatus from "../../moderation-status/moderation-status";

const PersonalInformation = (props) => {
  const { mode = utilityConst.editProfileMode.mobile } = props;

  // API variables
  const [
    getJourneyQuestions,
    {
      data: getJourneyQuestionsData,
      error: getJourneyQuestionsErrorData,
      isFetching: getJourneyQuestionsFetching,
      isLoading: getJourneyQuestionsLoading,
      isSuccess: getJourneyQuestionsSuccess,
      isError: getJourneyQuestionsError,
    },
  ] = useLazyGetJourneyQuestionsQuery();
  const [
    updateUserProfile,
    {
      data: updateUserProfileData,
      error: updateUserProfileErrorData,
      isLoading: updateUserProfileLoading,
      isSuccess: updateUserProfileSuccess,
      isError: updateUserProfileError,
    },
  ] = useUpdateEditUserProfileMutation();

  // General variables
  const [localPosition, setLocalPosition] = useState(null);
  const [localIndustry, setLocalIndustry] = useState(null);
  const [localEducation, setLocalEducation] = useState(null);
  const [localRelationshipStatus, setLocalRelationshipStatus] = useState(null);
  const [localChildren, setLocalChildren] = useState(null);
  const [localSmoking, setLocalSmoking] = useState(null);
  const [localDrinking, setLocalDrinking] = useState(null);
  const [localAlcoholPreference, setLocalAlcoholPreference] = useState(null);

  // Redux variables
  const occupation = useSelector((state) => state.editProfile.occupation);
  const position = useSelector((state) => state.editProfile.position);
  const industry = useSelector((state) => state.editProfile.industry);
  const education = useSelector((state) => state.editProfile.education);
  const relationshipStatus = useSelector(
    (state) => state.editProfile.relationshipStatus
  );
  const children = useSelector((state) => state.editProfile.children);
  const smoking = useSelector((state) => state.editProfile.smoking);
  const drinking = useSelector((state) => state.editProfile.drinking);
  const alcoholPreference = useSelector(
    (state) => state.editProfile.alcoholPreference
  );
  const profileCompletionPercentage = useSelector(
    (state) => state.profile.profileCompletionPercentage
  );
  const profileRequirementPopup = useSelector(
    (state) => state.profile.profileRequirementPopup
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    setLocalPosition(position?.id);
    setLocalIndustry(industry?.id);
    setLocalEducation(education?.id);
    setLocalRelationshipStatus(relationshipStatus?.id);
    setLocalChildren(children);
    setLocalSmoking(smoking?.id);
    setLocalDrinking(drinking?.id);
    setLocalAlcoholPreference(alcoholPreference);

    getJourneyQuestions(null, true);
  }, []);

  // Lifecycle | Check for update | Update User Profile API Response
  useEffect(() => {
    if (updateUserProfileLoading) {
    } else if (updateUserProfileSuccess) {
      switch (updateUserProfileData?.status) {
        case 1:
          dispatch(
            updateProfileCompletionPercentage(
              updateUserProfileData?.data?.user?.profile_progress?.summary
                ?.progress
            )
          );
          dispatch(
            updateProfileRequirementPopup(
              updateUserProfileData?.data?.user?.profile_progress?.summary
                ?.requirement
            )
          );

          dispatch(
            updatePosition(
              updateUserProfileData?.data?.user?.occupation?.position
            )
          );
          dispatch(
            updateIndustry(
              updateUserProfileData?.data?.user?.occupation?.industry
            )
          );
          dispatch(
            updateEducation(
              updateUserProfileData?.data?.user?.occupation?.education
            )
          );
          dispatch(
            updateRelationshipStatus(
              updateUserProfileData?.data?.user?.relationship
                ?.relationship_status
            )
          );
          dispatch(
            updateChildren(
              updateUserProfileData?.data?.user?.relationship?.children
            )
          );
          dispatch(
            updateSmoking(updateUserProfileData?.data?.user?.habit?.smoking)
          );
          dispatch(
            updateDrinking(updateUserProfileData?.data?.user?.habit?.drinking)
          );
          dispatch(
            updateAlcoholPreference(
              updateUserProfileData?.data?.user?.habit?.alcohol
            )
          );

          const infoToast = {
            message: t("signup.profile_updated"),
            autoClose: 1500,
          };
          dispatch(updateInfoToast(infoToast));
          break;
        default:
          break;
      }
    } else if (updateUserProfileError) {
    }
  }, [
    updateUserProfileLoading,
    updateUserProfileSuccess,
    updateUserProfileError,
  ]);

  // Event Handlers | Button
  const onEditSection = (section) => {
    if (profileCompletionPercentage < profileRequirementPopup) {
      dispatch(updateRestartRegistrationDialog(true));
    } else {
      // dispatch(
      //   updateProfileCompletionState({
      //     name: "personalInformation",
      //     slide: returnMaxPersonalInformation(),
      //   })
      // );

      switch (section) {
        case "occupation":
          // dispatch(updateUpdateProfileFullscreenTab(0));
          dispatch(
            updateUpdateProfileDialogView(
              editProfileConst.profileDialogView.occupation
            )
          );
          break;
        case "education":
          // dispatch(updateUpdateProfileFullscreenTab(1));
          dispatch(
            updateUpdateProfileDialogView(
              editProfileConst.profileDialogView.education
            )
          );
          break;
        case "relationship":
          // dispatch(updateUpdateProfileFullscreenTab(2));
          dispatch(
            updateUpdateProfileDialogView(
              editProfileConst.profileDialogView.relationship
            )
          );
          break;
        case "children":
          // dispatch(updateUpdateProfileFullscreenTab(3));
          dispatch(
            updateUpdateProfileDialogView(
              editProfileConst.profileDialogView.children
            )
          );
          break;
        case "smoking":
          // dispatch(updateUpdateProfileFullscreenTab(4));
          dispatch(
            updateUpdateProfileDialogView(
              editProfileConst.profileDialogView.smoking
            )
          );
          break;
        case "drinking":
          dispatch(
            updateProfileCompletionState({
              name: "drinking",
              slide: returnMaxPersonalInformation(),
            })
          );
          dispatch(updateUpdateProfileFullscreenTab(0));
          // dispatch(
          //   updateUpdateProfileDialogView(
          //     editProfileConst.profileDialogView.drinking
          //   )
          // );
          break;
        case "alcoholPreference":
          // dispatch(updateUpdateProfileFullscreenTab(6));
          dispatch(
            updateUpdateProfileDialogView(
              editProfileConst.profileDialogView.alcoholPreference
            )
          );
          break;
        default:
          break;
      }

      dispatch(updateUpdateProfileFullscreenDialog(true));
    }
  };
  const onCheckProfilePercentage = () => {
    if (profileCompletionPercentage < profileRequirementPopup) {
      dispatch(updateRestartRegistrationDialog(true));
    }
  };

  // Event Handlers | MUI Text Fields
  const onInputChange = (state) => (event) => {
    switch (state) {
      case "position":
        setLocalPosition(event.target.value);
        const positionObj = {
          profile: {
            position: event.target.value,
          },
        };
        updateUserProfile(positionObj);
        break;
      case "industry":
        setLocalIndustry(event.target.value);
        const industryObj = {
          profile: {
            industry: event.target.value,
          },
        };
        updateUserProfile(industryObj);
        break;
      case "education":
        setLocalEducation(event.target.value);
        const educationObj = {
          profile: {
            education: event.target.value,
          },
        };
        updateUserProfile(educationObj);
        break;
      case "relationship":
        setLocalRelationshipStatus(event.target.value);
        const relationshipObj = {
          profile: {
            relationship: event.target.value,
          },
        };
        updateUserProfile(relationshipObj);
        break;
      case "children":
        setLocalChildren(event.target.value);
        const childrenObj = {
          profile: {
            children: event.target.value,
          },
        };
        updateUserProfile(childrenObj);
        break;
      case "smoking":
        setLocalSmoking(event.target.value);
        const smokingObj = {
          profile: {
            smoking: event.target.value,
          },
        };
        updateUserProfile(smokingObj);
        break;
      case "drinking":
        setLocalDrinking(event.target.value);
        const drinkingObj = {
          profile: {
            drinking: event.target.value,
          },
        };
        updateUserProfile(drinkingObj);
        break;
      default:
        break;
    }
  };

  // Utility Functions
  const getAlcoholIcon = (alcoholId) => {
    switch (alcoholId) {
      case 70:
        return getIcon("redWineIcon", "alcohol-icon");
      case 71:
        return getIcon("whiteWineIcon", "alcohol-icon");
      case 72:
        return getIcon("champagneIcon", "alcohol-icon");
      case 73:
        return getIcon("cognacIcon", "alcohol-icon");
      case 74:
        return getIcon("tequilaIcon", "alcohol-icon");
      case 75:
        return getIcon("beerIcon", "alcohol-icon");
      case 76:
        return getIcon("cocktailIcon", "alcohol-icon");
      case 77:
        return getIcon("vodkaIcon", "alcohol-icon");
      case 78:
        return getIcon("brandyIcon", "alcohol-icon");
      case 79:
        return getIcon("highballIcon", "alcohol-icon");
      case 80:
        return getIcon("whiskeyIcon", "whiskey-icon");
      case 81:
        return getIcon("liqueurIcon", "alcohol-icon");
      case 82:
        return getIcon("absintheIcon", "alcohol-icon");
      case 83:
        return getIcon("rumIcon", "alcohol-icon");
      case 84:
        return getIcon("sangriaIcon", "alcohol-icon");
      case 85:
        return getIcon("ginIcon", "alcohol-icon");
      case 86:
        return getIcon("sakeIcon", "alcohol-icon");
      case 87:
        return getIcon("sojuIcon", "alcohol-icon");
      case 88:
        return getIcon("martiniIcon", "alcohol-icon");
      case 89:
        return getIcon("shotsIcon", "alcohol-icon");
    }
  };
  const isPersonalInformationValid = () => {
    if (
      occupation &&
      education &&
      relationshipStatus &&
      children &&
      smoking &&
      drinking
    ) {
      return true;
    } else {
      return false;
    }
  };
  const getPositionData = () => {
    return getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "position"
    )?.selections;
  };
  const getIndustryData = () => {
    return getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "industry"
    )?.selections;
  };
  const getEducationData = () => {
    return getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "education"
    )?.selections;
  };
  const getRelationshipData = () => {
    return getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "relationship"
    )?.selections;
  };
  const getChildrenData = () => {
    return userConst.children;
  };
  const getSmokingData = () => {
    return getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "smoking"
    )?.selections;
  };
  const getDrinkingData = () => {
    return getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "drinking"
    )?.selections;
  };
  const returnMaxPersonalInformation = () => {
    return 2;
  };
  const returnIsJourneyIncomplete = () => {
    return profileCompletionPercentage < profileRequirementPopup;
  };

  if (mode === utilityConst.editProfileMode.mobile) {
    return (
      <div id="mobile-edit-profile-personal-information-subcomponent">
        <div className="personal-information-label-container">
          <div className="personal-information-label">
            {t("profile.personal_information")}
          </div>
        </div>

        <div className="form-field-padding-container">
          {/* Occupation */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label ${position ? "" : "incomplete-field"}`}>
                {t("profile.occupation")}
              </div>
            </div>

            <div
              className="form-container"
              onClick={() => onEditSection("occupation")}
            >
              {position ? (
                <div className="filled-form">
                  {position?.locale} {industry?.locale}
                </div>
              ) : (
                <div className="empty-form">+ {t("common.add")}</div>
              )}

              <div className="right-icon">
                <ChevronRightIcon className="select-form-field-dropdown-icon" />
              </div>
            </div>
          </div>

          {/* Education */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label ${education ? "" : "incomplete-field"}`}>
                {t("profile.education")}
              </div>
            </div>

            <div
              className="form-container"
              onClick={() => onEditSection("education")}
            >
              {education ? (
                <div className="filled-form">{education?.locale}</div>
              ) : (
                <div className="empty-form">+ {t("common.add")}</div>
              )}

              <div className="right-icon">
                <ChevronRightIcon className="select-form-field-dropdown-icon" />
              </div>
            </div>
          </div>

          {/* Relationship */}
          <div className="form-field-container">
            <div className="label-container">
              <div
                className={`label ${
                  relationshipStatus ? "" : "incomplete-field"
                }`}
              >
                {t("profile.relationship")}
              </div>
            </div>

            <div
              className="form-container"
              onClick={() => onEditSection("relationship")}
            >
              {relationshipStatus ? (
                <div className="filled-form">{relationshipStatus?.locale}</div>
              ) : (
                <div className="empty-form">+ {t("common.add")}</div>
              )}

              <div className="right-icon">
                <ChevronRightIcon className="select-form-field-dropdown-icon" />
              </div>
            </div>
          </div>

          {/* Children */}
          <div className="form-field-container">
            <div className="label-container">
              <div
                className={`label ${
                  children || children === 0 ? "" : "incomplete-field"
                }`}
              >
                {t("profile.children")}
              </div>
            </div>

            <div
              className="form-container"
              onClick={() => onEditSection("children")}
            >
              {children || children === 0 ? (
                <div className="filled-form">{children}</div>
              ) : (
                <div className="empty-form">+ {t("common.add")}</div>
              )}

              <div className="right-icon">
                <ChevronRightIcon className="select-form-field-dropdown-icon" />
              </div>
            </div>
          </div>

          {/* Smoking */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label ${smoking ? "" : "incomplete-field"}`}>
                {t("profile.smoking")}
              </div>
            </div>

            <div
              className="form-container"
              onClick={() => onEditSection("smoking")}
            >
              {smoking ? (
                <div className="filled-form">{smoking?.locale}</div>
              ) : (
                <div className="empty-form">+ {t("common.add")}</div>
              )}

              <div className="right-icon">
                <ChevronRightIcon className="select-form-field-dropdown-icon" />
              </div>
            </div>
          </div>

          {/* Drinking */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label ${drinking ? "" : "incomplete-field"}`}>
                {t("profile.drinking")}
              </div>
            </div>

            <div
              className="form-container"
              onClick={() => onEditSection("drinking")}
            >
              {drinking ? (
                <div className="filled-form">{drinking?.locale}</div>
              ) : (
                <div className="empty-form">+ {t("common.add")}</div>
              )}

              <div className="right-icon">
                <ChevronRightIcon className="select-form-field-dropdown-icon" />
              </div>
            </div>
          </div>

          {/* Alcohol Preference */}
          {false && drinking?.id !== 540 && (
            <div className="alcohol-preference-form-field-container">
              <div className="label-container">
                <div
                  className={`label ${
                    alcoholPreference?.length > 0 ? "" : "incomplete-field"
                  }`}
                >
                  {"Alcohol Preferences"}
                </div>
              </div>

              <div
                className="alcohol-preference-form-container"
                onClick={() => onEditSection("alcoholPreference")}
              >
                {alcoholPreference?.length > 0 ? (
                  <div className="filled-form-container">
                    {alcoholPreference?.map((item, index) => (
                      <div className="filled-form" key={index}>
                        {item?.locale}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="empty-form">+ {t("common.add")}</div>
                )}

                {alcoholPreference?.length <= 0 && (
                  <div className="right-icon">
                    <ChevronRightIcon className="select-form-field-dropdown-icon" />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  } else if (mode === utilityConst.editProfileMode.desktop) {
    return (
      <div
        id="desktop-edit-profile-personal-information-subcomponent"
        onClick={onCheckProfilePercentage}
      >
        {/* In Review */}
        <ModerationStatus
          status={userConst.profileGeneralApprovalStatus.inReview}
        />

        {/* Rejected */}
        <ModerationStatus
          status={userConst.profileGeneralApprovalStatus.rejected}
        />

        <div className="personal-information-static-label-container">
          <div className="personal-information-label">
            {t("profile.personal_information")}
          </div>
          <Divider className="short-divider" />
        </div>

        <div
          className={`form-field-padding-container ${
            returnIsJourneyIncomplete() ? "none-clickable" : ""
          }`}
        >
          {/* Position */}
          <div className="form-field-container">
            <div className="label-container">
              <div
                className={`label ${localPosition ? "" : "incomplete-field"}`}
              >
                {t("registration.occupation.position")}
              </div>
            </div>

            <div className="form-container">
              <FormControl
                className="form-field custom-text-field-max-radius custom-text-field-outline-default custom-text-field-outline-white-background-color"
                variant="outlined"
                fullWidth
              >
                <Select
                  className="form-select"
                  displayEmpty
                  value={localPosition || ""}
                  onChange={onInputChange("position")}
                  disableUnderline={true}
                  renderValue={(selected) => {
                    if (!selected || selected === "") {
                      return (
                        <div className="default-select-value">
                          + {t("common.add")}
                        </div>
                      );
                    }

                    const selectedPosition = getPositionData()?.find(
                      (item) => item?.id === selected
                    );

                    return (
                      selectedPosition?.display_locale || (
                        <div className="default-select-value">
                          + {t("common.add")}
                        </div>
                      )
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    + {t("common.add")}
                  </MenuItem>
                  {getPositionData()?.map((position, index) => (
                    <MenuItem key={index} value={position?.id}>
                      {t(`${position?.display_locale}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>

          {/* Industry */}
          <div className="form-field-container">
            <div className="label-container">
              <div
                className={`label ${localIndustry ? "" : "incomplete-field"}`}
              >
                {t("registration.occupation.industry")}
              </div>
            </div>

            <div className="form-container">
              <FormControl
                className="form-field custom-text-field-max-radius custom-text-field-outline-default custom-text-field-outline-white-background-color"
                variant="outlined"
                fullWidth
              >
                <Select
                  className="form-select"
                  displayEmpty
                  value={localIndustry || ""}
                  onChange={onInputChange("industry")}
                  disableUnderline={true}
                  renderValue={(selected) => {
                    if (!selected || selected === "") {
                      return (
                        <div className="default-select-value">
                          + {t("common.add")}
                        </div>
                      );
                    }

                    const selectedIndustry = getIndustryData()?.find(
                      (item) => item?.id === selected
                    );

                    return (
                      selectedIndustry?.display_locale || (
                        <div className="default-select-value">
                          + {t("common.add")}
                        </div>
                      )
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    + {t("common.add")}
                  </MenuItem>
                  {getIndustryData()?.map((industry, index) => (
                    <MenuItem key={index} value={industry?.id}>
                      {t(`${industry?.display_locale}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>

          {/* Education */}
          <div className="form-field-container">
            <div className="label-container">
              <div
                className={`label ${localEducation ? "" : "incomplete-field"}`}
              >
                {t("profile.education")}
              </div>
            </div>

            <div className="form-container">
              <FormControl
                className="form-field custom-text-field-max-radius custom-text-field-outline-default custom-text-field-outline-white-background-color"
                variant="outlined"
                fullWidth
              >
                <Select
                  className="form-select"
                  displayEmpty
                  value={localEducation || ""}
                  onChange={onInputChange("education")}
                  disableUnderline={true}
                  renderValue={(selected) => {
                    if (!selected || selected === "") {
                      return (
                        <div className="default-select-value">
                          + {t("common.add")}
                        </div>
                      );
                    }

                    const selectedEducation = getEducationData()?.find(
                      (item) => item?.id === selected
                    );

                    return (
                      selectedEducation?.display_locale || (
                        <div className="default-select-value">
                          + {t("common.add")}
                        </div>
                      )
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    + {t("common.add")}
                  </MenuItem>
                  {getEducationData()?.map((education, index) => (
                    <MenuItem key={index} value={education?.id}>
                      {t(`${education?.display_locale}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>

          {/* Relationship */}
          <div className="form-field-container">
            <div className="label-container">
              <div
                className={`label ${
                  localRelationshipStatus ? "" : "incomplete-field"
                }`}
              >
                {t("profile.relationship")}
              </div>
            </div>

            <div className="form-container">
              <FormControl
                className="form-field custom-text-field-max-radius custom-text-field-outline-default custom-text-field-outline-white-background-color"
                variant="outlined"
                fullWidth
              >
                <Select
                  className="form-select"
                  displayEmpty
                  value={localRelationshipStatus || ""}
                  onChange={onInputChange("relationship")}
                  disableUnderline={true}
                  renderValue={(selected) => {
                    if (!selected || selected === "") {
                      return (
                        <div className="default-select-value">
                          + {t("common.add")}
                        </div>
                      );
                    }

                    const selectedRelationship = getRelationshipData()?.find(
                      (item) => item?.id === selected
                    );

                    return (
                      selectedRelationship?.display_locale || (
                        <div className="default-select-value">
                          + {t("common.add")}
                        </div>
                      )
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    + {t("common.add")}
                  </MenuItem>
                  {getRelationshipData()?.map((relationship, index) => (
                    <MenuItem key={index} value={relationship?.id}>
                      {t(`${relationship?.display_locale}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>

          {/* Children */}
          <div className="form-field-container">
            <div className="label-container">
              <div
                className={`label ${
                  localChildren || localChildren === 0 ? "" : "incomplete-field"
                }`}
              >
                {t("profile.children")}
              </div>
            </div>

            <div className="form-container">
              <FormControl
                className="form-field custom-text-field-max-radius custom-text-field-outline-default custom-text-field-outline-white-background-color"
                variant="outlined"
                fullWidth
              >
                <Select
                  className="form-select"
                  displayEmpty
                  value={localChildren ?? ""}
                  onChange={onInputChange("children")}
                  disableUnderline={true}
                  renderValue={(selected) => {
                    if (
                      (!selected || selected === "") &&
                      selected !== 0 &&
                      selected !== "0"
                    ) {
                      return (
                        <div className="default-select-value">
                          + {t("common.add")}
                        </div>
                      );
                    }

                    const selectedChildren = getChildrenData()?.find(
                      (item) => item == selected
                    );

                    return (
                      selectedChildren ?? (
                        <div className="default-select-value">
                          + {t("common.add")}
                        </div>
                      )
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    + {t("common.add")}
                  </MenuItem>
                  {getChildrenData()?.map((children, index) => (
                    <MenuItem key={index} value={children}>
                      {t(`${children}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>

          {/* Smoking */}
          <div className="form-field-container">
            <div className="label-container">
              <div
                className={`label ${localSmoking ? "" : "incomplete-field"}`}
              >
                {t("profile.smoking")}
              </div>
            </div>

            <div className="form-container">
              <FormControl
                className="form-field custom-text-field-max-radius custom-text-field-outline-default custom-text-field-outline-white-background-color"
                variant="outlined"
                fullWidth
              >
                <Select
                  className="form-select"
                  displayEmpty
                  value={localSmoking || ""}
                  onChange={onInputChange("smoking")}
                  disableUnderline={true}
                  renderValue={(selected) => {
                    if (!selected || selected === "") {
                      return (
                        <div className="default-select-value">
                          + {t("common.add")}
                        </div>
                      );
                    }

                    const selectedSmoking = getSmokingData()?.find(
                      (item) => item?.id === selected
                    );

                    return (
                      selectedSmoking?.display_locale || (
                        <div className="default-select-value">
                          + {t("common.add")}
                        </div>
                      )
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    + {t("common.add")}
                  </MenuItem>
                  {getSmokingData()?.map((smoking, index) => (
                    <MenuItem key={index} value={smoking?.id}>
                      {t(`${smoking?.display_locale}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>

          {/* Drinking */}
          <div className="form-field-container">
            <div className="label-container">
              <div
                className={`label ${localDrinking ? "" : "incomplete-field"}`}
              >
                {t("profile.drinking")}
              </div>
            </div>

            <div className="form-container">
              <FormControl
                className="form-field custom-text-field-max-radius custom-text-field-outline-default custom-text-field-outline-white-background-color"
                variant="outlined"
                fullWidth
              >
                <Select
                  className="form-select"
                  displayEmpty
                  value={localDrinking || ""}
                  onChange={onInputChange("drinking")}
                  disableUnderline={true}
                  renderValue={(selected) => {
                    if (!selected || selected === "") {
                      return (
                        <div className="default-select-value">
                          + {t("common.add")}
                        </div>
                      );
                    }

                    const selectedDrinking = getDrinkingData()?.find(
                      (item) => item?.id === selected
                    );

                    return (
                      selectedDrinking?.display_locale || (
                        <div className="default-select-value">
                          + {t("common.add")}
                        </div>
                      )
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    + {t("common.add")}
                  </MenuItem>
                  {getDrinkingData()?.map((drinking, index) => (
                    <MenuItem key={index} value={drinking?.id}>
                      {t(`${drinking?.display_locale}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>

          {/* Alcohol Preference */}
          {drinking && drinking?.id !== 540 && (
            <div className="alcohol-form-field-container">
              <div className="label-container">
                <div
                  className={`label ${
                    localAlcoholPreference ? "" : "incomplete-field"
                  }`}
                >
                  {t("registration.common.drinking_preference")}
                </div>
              </div>

              <div
                className="alcohol-preference-form-container"
                onClick={() => onEditSection("alcoholPreference")}
              >
                {alcoholPreference?.length > 0 ? (
                  <div className="filled-form-container">
                    {alcoholPreference?.map((item, index) => (
                      <div className="alcohol-filled-form" key={index}>
                        <div className="alcohol-icon-container">
                          {getAlcoholIcon(item?.id)}
                        </div>
                        <div className="alcohol-item">{item?.locale}</div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="empty-form">+ {t("common.add")}</div>
                )}

                {alcoholPreference?.length <= 0 && (
                  <div className="right-icon">
                    <ChevronRightIcon className="select-form-field-dropdown-icon" />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
};

export default PersonalInformation;
