// General
import "./profile-photo-overlay.scss";
import { useEffect } from "react";
// Services
import { useLazyGetProfileQuery } from "../../../../services/data.service";
// Redux
import { useSelector } from "react-redux";

const ProfilePhotoOverlay = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // Redux variables
  const showVideoCallJoiningOverlay = useSelector(
    (state) => state.privateCall.showVideoCallJoiningOverlay
  );
  const callerId = useSelector((state) => state.privateCall.callerId);
  const callerProfilePhoto = useSelector(
    (state) => state.privateCall.callerProfilePhoto
  );
  const calleeProfilePhoto = useSelector(
    (state) => state.privateCall.calleeProfilePhoto
  );

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  if (showVideoCallJoiningOverlay) {
    return (
      <div id="video-call-profile-photo-overlay-subcomponent">
        <img
          className="profile-photo-overlay"
          src={
            getProfileData?.data?.id === callerId
              ? calleeProfilePhoto
              : callerProfilePhoto
          }
        />
      </div>
    );
  }
};

export default ProfilePhotoOverlay;
