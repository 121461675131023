// General
import "./top-fans-empty-list.scss";
// i18next
import { useTranslation } from "react-i18next";

const TopFansEmptyList = () => {
  // i18next variables
  const { t } = useTranslation();

  return (
    <div id="top-fans-empty-list-shared-subcomponent">
      <div className="title">{t("top-fan.first_one_here_title")}</div>
      <div className="subtitle">{t("top-fan.first_one_here_subtitle")}</div>
    </div>
  );
};

export default TopFansEmptyList;
