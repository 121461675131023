// General
import "./top-banner.scss";
import { useEffect } from "react";
// Services
import { useLazyGetLivestreamingTopBannersQuery } from "../../../../../services/data.service";
// Static Data
import routeConst from "../../../../../const/routeConst";
// Redux
import { useDispatch } from "react-redux";
import { updateDisableSwipe } from "../../../../../redux/store/livestreamingStore";
// Custom Hooks
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";

const TopBanner = () => {
  // API variables
  const [
    getLivestreamingTopBanners,
    {
      data: getLivestreamingTopBannersData,
      error: getLivestreamingTopBannersErrorData,
      isFetching: getLivestreamingTopBannersFetching,
      isLoading: getLivestreamingTopBannersLoading,
      isSuccess: getLivestreamingTopBannersSuccess,
      isError: getLivestreamingTopBannersError,
    },
  ] = useLazyGetLivestreamingTopBannersQuery();

  // Render variables
  let topBannersView;

  // Redux variables
  const dispatch = useDispatch();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    getLivestreamingTopBanners(null, true);
  }, []);

  // Event Handlers | Button
  const onOpenLink = (banner) => {
    window.open(banner?.url);
  };

  // Utility Functions
  const handleOnTouchOver = (event) => {
    dispatch(updateDisableSwipe(true));
  };
  const handleOnTouchEnd = (event) => {
    dispatch(updateDisableSwipe(false));
  };

  if (getLivestreamingTopBannersFetching || getLivestreamingTopBannersLoading) {
  } else if (getLivestreamingTopBannersSuccess) {
    if (
      getLivestreamingTopBannersData?.status === 0 &&
      getLivestreamingTopBannersData?.data?.top_banners?.length > 0
    )
      return (
        <div
          id="interactable-overlay-top-banner-subcomponent"
          onTouchMove={handleOnTouchOver}
          onTouchEnd={handleOnTouchEnd}
        >
          <div className="carousel-container">
            <swiper-container
              class="swiper-container"
              navigation="false"
              pagination="true"
              scrollbar="false"
              loop="false"
              speed="300"
              autoplay-delay="2500"
            >
              {getLivestreamingTopBannersData?.data?.top_banners?.map(
                (banner, index) => {
                  return (
                    <swiper-slide
                      class="banner-image-container"
                      onClick={() => onOpenLink(banner)}
                      key={index}
                    >
                      <img className="banner-image" src={banner?.asset_url} />
                    </swiper-slide>
                  );
                }
              )}
            </swiper-container>
          </div>
        </div>
      );
  } else if (getLivestreamingTopBannersError) {
    if (getLivestreamingTopBannersErrorData?.status === 401) {
      onNavigate(routeConst.logout.path);
    }
  }
};

export default TopBanner;
