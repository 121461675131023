// General
import "./chat-conversation.scss";
import { useEffect } from "react";
// Redux
import { useDispatch } from "react-redux";
import { updateConversationUser } from "../../../redux/store/inboxStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Divider, useMediaQuery } from "@mui/material";
// Components
import UtilityDesktopNavbar from "../../shared/elements/utility-desktop-navbar/utility-desktop-navbar";
import ConversationList from "./conversation-list/conversation-list";
import ChatInput from "./chat-input/chat-input";
import IncompleteProfile from "./incomplete-profile/incomplete-profile";

const ChatConversation = () => {
  // Redux variables
  const dispatch = useDispatch();

  // MUI variables
  const isDesktop = useMediaQuery("(min-width: 850px)");

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-8.1",
      },
    });

    return () => {
      // Clear user when leaving conversation page
      dispatch(updateConversationUser(null));
    };
  }, []);

  return (
    <div id="chat-conversation-page">
      <UtilityDesktopNavbar
        rightButton={"chat-conversation"}
        backgroundColor={isDesktop ? "none" : "white"}
      />

      <ConversationList />

      <Divider />

      <ChatInput />

      <IncompleteProfile />
    </div>
  );
};

export default ChatConversation;
