// General
import "./tip-request-dialog.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetProfileQuery,
  usePostPrivateCallRequestRejectMutation,
  usePostPrivateCallRequestAcceptMutation,
} from "../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateCustomizeTipRequestDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog } from "@mui/material";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../elements/spinner/spinner";

const TipRequestDialog = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    postPrivateCallRequestReject,
    {
      data: postPrivateCallRequestRejectData,
      error: postPrivateCallRequestRejectErrorData,
      isLoading: postPrivateCallRequestRejectLoading,
      isSuccess: postPrivateCallRequestRejectSuccess,
      isError: postPrivateCallRequestRejectError,
    },
  ] = usePostPrivateCallRequestRejectMutation();
  const [
    postPrivateCallRequestAccept,
    {
      data: postPrivateCallRequestAcceptData,
      error: postPrivateCallRequestAcceptErrorData,
      isLoading: postPrivateCallRequestAcceptLoading,
      isSuccess: postPrivateCallRequestAcceptSuccess,
      isError: postPrivateCallRequestAcceptError,
    },
  ] = usePostPrivateCallRequestAcceptMutation();

  // Redux variables
  const customizeTipRequestDialog = useSelector(
    (state) => state.dialog.customizeTipRequestDialog
  );
  const customizeTipRequestInfo = useSelector(
    (state) => state.privateCall.customizeTipRequestInfo
  );
  const requestId = useSelector((state) => state.privateCall.requestId);
  const dispatch = useDispatch();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!customizeTipRequestDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-D25",
      },
    });

    getProfile(null, true);
  }, [customizeTipRequestDialog]);

  // Lifecycle | Check for update | postPrivateCallRequestRejected
  useEffect(() => {
    if (postPrivateCallRequestRejectLoading) {
    } else if (postPrivateCallRequestRejectSuccess) {
      if (postPrivateCallRequestRejectData?.status === 0) {
        onCloseDialog();
      }
    } else if (postPrivateCallRequestRejectError) {
    }
  }, [
    postPrivateCallRequestRejectLoading,
    postPrivateCallRequestRejectSuccess,
    postPrivateCallRequestRejectError,
  ]);

  // Lifecycle | Check for update | postPrivateCallRequestAccept
  useEffect(() => {
    if (postPrivateCallRequestAcceptLoading) {
    } else if (postPrivateCallRequestAcceptSuccess) {
      if (postPrivateCallRequestAcceptData?.status === 0) {
        onCloseDialog();
      }
    } else if (postPrivateCallRequestAcceptError) {
    }
  }, [
    postPrivateCallRequestAcceptLoading,
    postPrivateCallRequestAcceptSuccess,
    postPrivateCallRequestAcceptError,
  ]);

  // Event Handlers | Button
  const onAcceptRequest = () => {
    if (
      postPrivateCallRequestRejectLoading ||
      postPrivateCallRequestAcceptLoading
    )
      return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-D25-25.1-Button",
      },
    });

    const obj = {
      related_id: requestId,
      related_module: "private_calls",
      request_id: customizeTipRequestInfo?.data?.tipping_request?.request_id,
    };
    postPrivateCallRequestAccept(obj);
  };
  const onRejectRequest = () => {
    if (
      postPrivateCallRequestRejectLoading ||
      postPrivateCallRequestAcceptLoading
    )
      return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-D25-25.2-Button",
      },
    });

    const obj = {
      related_id: requestId,
      related_module: "private_calls",
      request_id: customizeTipRequestInfo?.data?.tipping_request?.request_id,
    };
    postPrivateCallRequestReject(obj);
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = (event, reason) => {
    if (reason === "backdropClick") return;

    dispatch(updateCustomizeTipRequestDialog(false));
  };

  return (
    <Dialog
      className="custom-radius35-dialog"
      open={customizeTipRequestDialog}
      onClose={onCloseDialog}
    >
      <div id="tip-request-dialog">
        <div className="padding-container">
          <div className="tip-image-container">
            {getIcon("tipCoins", "tip-image")}
          </div>
          <div className="header">
            <Trans
              i18nKey={"1on1.tip_request_title"}
              values={{
                caller:
                  getProfileData?.data?.id ===
                  customizeTipRequestInfo?.data?.call_request?.callee_user_id
                    ? customizeTipRequestInfo?.data?.call_request?.caller
                        ?.username
                    : customizeTipRequestInfo?.data?.call_request?.callee
                        ?.username,
                action:
                  customizeTipRequestInfo?.data?.tipping_request?.description,
                coin: customizeTipRequestInfo?.data?.tipping_request?.value,
              }}
            />
          </div>
          <div className="description">
            <Trans
              i18nKey={"1on1.tip_request_body"}
              components={{ cashIcon: <div></div> }}
            />
          </div>
          <div className="accept-button" onClick={onAcceptRequest}>
            {postPrivateCallRequestAcceptLoading ? (
              <Spinner size={24} isPadding={false} color={"white-spinner"} />
            ) : (
              t("1on1.accept_request")
            )}
          </div>
          <div className="reject-button" onClick={onRejectRequest}>
            {postPrivateCallRequestRejectLoading ? (
              <Spinner size={24} isPadding={false} />
            ) : (
              t("1on1.reject_request")
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default TipRequestDialog;
