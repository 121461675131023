// General
import "./empty-list.scss";
// i18next
import { Trans } from "react-i18next";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const EmptyList = () => {
  // Custom Hooks Functions
  const getIcon = IconManager();

  return (
    <div
      id="blocked-members-empty-list-subcomponent"
      className="empty-list-container"
    >
      <div className="blocked-members-icon-container">
        {getIcon("blockedMembersIcon", "blocked-members-icon")}
      </div>
      <div className="no-blocked-text">
        <Trans
          i18nKey={"settings.blocked_list_empty"}
          components={{ br: <br /> }}
        />
      </div>
    </div>
  );
};

export default EmptyList;
