// General
import "./private-room-minimize-bar.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetPrivateCallStandbyEndQuery,
  useClaimPrivateCallDiamondsMutation,
  useLazyGetPrivateCallStandbyKeepAliveQuery,
} from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateEndCallDialog } from "../../../../redux/store/dialogStore";
import {
  // General Functions
  resetPrivateCallStandbyTimer,
  updateIsHunterMode,
  updateEndVideoCall,

  // Interval Functions
  updateStandbyKeepAliveInterval,
  clearStandbyTimerInterval,
  clearStandbyKeepAliveInterval,
  updateStandbyKeepAliveMinimizedInterval,
  clearStandbyKeepAliveMinimizedInterval,

  // Callee End Video Call Summary Functions
  updateCalleeEndVideoCallSummaryData,

  // Diamonds Functions
  updateTotalDiamonds,
  updateCallRequestDiamonds,
  updateUnclaimedDiamonds,
  updateClaimedDiamonds,

  // Calls Functions
  updateCallsAccepted,
} from "../../../../redux/store/privateCallStore";
import { updateWarningToast } from "../../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import useEndPrivateCall from "../../../utility/custom-hooks/useEndPrivateCall-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const PrivateRoomMinimizeBar = () => {
  // API variables
  const [
    getPrivateCallStandbyEnd,
    {
      data: getPrivateCallStandbyEndData,
      error: getPrivateCallStandbyEndErrorData,
      isFetching: getPrivateCallStandbyEndFetching,
      isLoading: getPrivateCallStandbyEndLoading,
      isSuccess: getPrivateCallStandbyEndSuccess,
      isError: getPrivateCallStandbyEndError,
    },
  ] = useLazyGetPrivateCallStandbyEndQuery();
  const [
    claimPrivateCallDiamonds,
    {
      data: claimPrivateCallDiamondsData,
      error: claimPrivateCallDiamondsErrorData,
      isLoading: claimPrivateCallDiamondsLoading,
      isSuccess: claimPrivateCallDiamondsSuccess,
      isError: claimPrivateCallDiamondsError,
    },
  ] = useClaimPrivateCallDiamondsMutation();
  const [
    getPrivateCallStandbyKeepAlive,
    {
      data: getPrivateCallStandbyKeepAliveData,
      error: getPrivateCallStandbyKeepAliveErrorData,
      isFetching: getPrivateCallStandbyKeepAliveFetching,
      isLoading: getPrivateCallStandbyKeepAliveLoading,
      isSuccess: getPrivateCallStandbyKeepAliveSuccess,
      isError: getPrivateCallStandbyKeepAliveError,
    },
  ] = useLazyGetPrivateCallStandbyKeepAliveQuery();

  // Redux variables
  const isPrivateStandbyMode = useSelector(
    (state) => state.privateCall.isPrivateStandbyMode
  );
  const privateCallStandbyTimer = useSelector(
    (state) => state.privateCall.privateCallStandbyTimer
  );
  const isHunterMode = useSelector((state) => state.privateCall.isHunterMode);
  const endVideoCall = useSelector((state) => state.privateCall.endVideoCall);
  const standbyId = useSelector((state) => state.privateCall.standbyId);
  const unclaimedDiamonds = useSelector(
    (state) => state.privateCall.unclaimedDiamonds
  );
  const isCaller = useSelector((state) => state.privateCall.isCaller);
  const exitHunterMode = useSelector(
    (state) => state.privateCall.exitHunterMode
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const onEndCall = useEndPrivateCall();
  const getIcon = IconManager();

  // Lifecycle | Check for update | isHunterMode
  useEffect(() => {
    if (isHunterMode) {
      // Call First Standby Keep Alive API in Hunter Mode
      getPrivateCallStandbyKeepAlive();

      // Start Standby Keep Alive Interval
      dispatch(
        updateStandbyKeepAliveMinimizedInterval(
          setInterval(() => {
            getPrivateCallStandbyKeepAlive();
            // dispatch(updateCallStandbyKeepAlivePassthrough({}));
          }, 10000)
        )
      );
    } else {
      // Clear Standby Keep Alive Interval
      dispatch(clearStandbyKeepAliveMinimizedInterval());
    }
  }, [isHunterMode]);

  // Lifecycle | Check for update | Private Call Standby End API Response
  useEffect(() => {
    if (getPrivateCallStandbyEndFetching || getPrivateCallStandbyEndLoading) {
    } else if (getPrivateCallStandbyEndSuccess) {
      if (getPrivateCallStandbyEndData?.status === 0) {
        const obj = {
          standbyDuration:
            getPrivateCallStandbyEndData?.data?.standby?.duration,
          callDuration:
            getPrivateCallStandbyEndData?.data?.overall_stats?.call_duration,
          totalStandbyDiamondsEarned:
            getPrivateCallStandbyEndData?.data?.overall_stats?.claimed_diamonds,
          totalDiamondsEarned:
            getPrivateCallStandbyEndData?.data?.overall_stats
              ?.total_diamonds_earned,
          standbyDiamondsEarned:
            getPrivateCallStandbyEndData?.data?.overall_stats?.claimed_diamonds,
          videoCallDiamondsEarned:
            getPrivateCallStandbyEndData?.data?.overall_stats?.call_charges_sum,
          chargedMessagesDiamondsEarned:
            getPrivateCallStandbyEndData?.data?.overall_stats?.chat_messages_sum
              ?.diamonds,
          tipsDiamondsEarned:
            getPrivateCallStandbyEndData?.data?.overall_stats?.tippings_sum,
          giftsDiamondsEarned:
            getPrivateCallStandbyEndData?.data?.overall_stats?.giftings_sum,
          actionCompleted: 0,
          requestsFromDaddy:
            getPrivateCallStandbyEndData?.data?.overall_stats
              ?.customized_tipping_count,
          incomingCallRequests:
            getPrivateCallStandbyEndData?.data?.call_requests,
        };

        dispatch(updateCalleeEndVideoCallSummaryData(obj));
        onEndCall();

        // Reset end video call to prevent rerun of API when joining back
        dispatch(updateEndVideoCall(false));
      }
    } else if (getPrivateCallStandbyEndError) {
      if (getPrivateCallStandbyEndErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getPrivateCallStandbyEndFetching,
    getPrivateCallStandbyEndLoading,
    getPrivateCallStandbyEndSuccess,
    getPrivateCallStandbyEndError,
  ]);

  // Lifecycle | Check for update | Private Call Standby Keep Alive API Response
  useEffect(() => {
    if (
      getPrivateCallStandbyKeepAliveFetching ||
      getPrivateCallStandbyKeepAliveLoading
    ) {
    } else if (getPrivateCallStandbyKeepAliveSuccess) {
      switch (getPrivateCallStandbyKeepAliveData?.status) {
        case 0:
          dispatch(
            updateTotalDiamonds(
              getPrivateCallStandbyKeepAliveData?.data?.standby?.stats
                ?.total_diamonds
            )
          );
          dispatch(
            updateCallRequestDiamonds(
              getPrivateCallStandbyKeepAliveData?.data?.standby?.stats
                ?.call_requests_diamonds
            )
          );
          dispatch(
            updateUnclaimedDiamonds(
              getPrivateCallStandbyKeepAliveData?.data?.standby?.stats
                ?.unclaimed_diamonds
            )
          );
          dispatch(
            updateClaimedDiamonds(
              getPrivateCallStandbyKeepAliveData?.data?.standby?.stats
                ?.claimed_diamonds
            )
          );
          dispatch(
            updateCallsAccepted(
              getPrivateCallStandbyKeepAliveData?.data?.standby?.stats
                ?.calls_accepted
            )
          );
          break;
        case -1:
          // Standby not available
          const warningToastObj = {
            message: getPrivateCallStandbyKeepAliveData?.message,
            autoClose: 3000,
          };
          dispatch(updateWarningToast(warningToastObj));

          // End Call
          dispatch(updateEndVideoCall(true));
          break;
        default:
          // Others
          const defaultToastObj = {
            message: getPrivateCallStandbyKeepAliveData?.message,
            autoClose: 3000,
          };
          dispatch(updateWarningToast(defaultToastObj));

          // End Call
          dispatch(updateEndVideoCall(true));
          break;
      }
    } else if (getPrivateCallStandbyKeepAliveError) {
      if (getPrivateCallStandbyKeepAliveErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getPrivateCallStandbyKeepAliveFetching,
    getPrivateCallStandbyKeepAliveLoading,
    getPrivateCallStandbyKeepAliveSuccess,
    getPrivateCallStandbyKeepAliveError,
  ]);

  // Lifecycle | Check for update | Claim Diamonds API Response
  useEffect(() => {
    if (claimPrivateCallDiamondsLoading) {
    } else if (claimPrivateCallDiamondsSuccess) {
      if (claimPrivateCallDiamondsData?.status === 0) {
        dispatch(
          updateTotalDiamonds(
            claimPrivateCallDiamondsData?.data?.standby?.stats?.total_diamonds
          )
        );
        dispatch(
          updateCallRequestDiamonds(
            claimPrivateCallDiamondsData?.data?.standby?.stats
              ?.call_requested_diamonds
          )
        );
        dispatch(
          updateUnclaimedDiamonds(
            claimPrivateCallDiamondsData?.data?.standby?.stats
              ?.unclaimed_diamonds
          )
        );
        dispatch(
          updateClaimedDiamonds(
            claimPrivateCallDiamondsData?.data?.standby?.stats?.claimed_diamonds
          )
        );
        dispatch(
          updateCallsAccepted(
            claimPrivateCallDiamondsData?.data?.standby?.stats?.calls_accepted
          )
        );
      }
    } else if (claimPrivateCallDiamondsError) {
      if (claimPrivateCallDiamondsErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    claimPrivateCallDiamondsLoading,
    claimPrivateCallDiamondsSuccess,
    claimPrivateCallDiamondsError,
  ]);

  // Lifecycle | Check for update | endVideoCall
  useEffect(() => {
    if (!isPrivateStandbyMode || isCaller || !endVideoCall) return;

    getPrivateCallStandbyEnd({ standbyId: standbyId });

    if (!isHunterMode) return;

    dispatch(clearStandbyTimerInterval());
    dispatch(clearStandbyKeepAliveInterval());
    dispatch(resetPrivateCallStandbyTimer());
    dispatch(updateIsHunterMode(false));
  }, [isCaller, endVideoCall]);

  // Lifecycle | Check for update | exitHunterMode
  useEffect(() => {
    if (!isPrivateStandbyMode || isCaller || !exitHunterMode) return;

    onNavigate(routeConst.videoCall.privateStandby.path);
  }, [isCaller, exitHunterMode]);

  // Event Handlers | Button
  const onMaximize = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-S6-6.1-Button",
      },
    });

    onNavigate(routeConst.videoCall.privateStandby.path);
  };
  const onClaimDiamonds = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-S6-6.2-Button",
      },
    });

    if (unclaimedDiamonds <= 0) return;

    claimPrivateCallDiamonds({});
  };
  const onEndCallDialog = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-S6-6.3-Button",
      },
    });

    dispatch(updateEndCallDialog(true));
  };

  // Utility Functions
  const getFormattedTime = (seconds) => {
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds / 60) % 60);
    let secs = Math.floor(seconds % 60);

    let formattedHours = hours < 10 ? "0" + hours : hours;
    let formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    let formattedSeconds = secs < 10 ? "0" + secs : secs;

    return formattedHours + ":" + formattedMinutes + ":" + formattedSeconds;
  };

  if (isHunterMode) {
    return (
      <div id="private-room-minimize-bar">
        <div className="padding-container">
          <div className="left-container" onClick={onMaximize}>
            <div className="timer-container">
              {getFormattedTime(privateCallStandbyTimer)}
            </div>
            <div className="text">{t("1on1.invite_daddies")}</div>
          </div>

          <div className="right-container">
            <div
              className={`claim-button-container ${
                unclaimedDiamonds <= 0 ? "no-diamonds" : "claim-diamonds-button"
              }`}
            >
              <div className="claim-button" onClick={onClaimDiamonds}>
                {t("1on1.claim")}
              </div>

              <div className="diamond-count">{unclaimedDiamonds}</div>

              <div className="diamond-icon-container">
                {getIcon("sbDiamondIcon", "diamond-icon")}
              </div>
            </div>

            <div className="close-button-container" onClick={onEndCallDialog}>
              {getIcon("closeLightIcon", "close-button")}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default PrivateRoomMinimizeBar;
