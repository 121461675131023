// General
import "./global-toast-mounted.scss";
import { useEffect } from "react";
// react-toastify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Material UI
import { Portal } from "@mui/material";

const GlobalToastMounted = () => {
  // Lifecycle | Check for update | MUI Snackbar | Global
  useEffect(() => {
    // Template
    // toast(<MessageNotification />, {
    //   position: "top-center",
    //   autoClose: 5000000,
    //   hideProgressBar: false,
    //   closeOnClick: false,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "colored",
    //   closeButton: false,
    // });
  }, []);

  return (
    <Portal>
      <div id="global-toast-container">
        <ToastContainer
          className="custom-toast"
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          draggablePercent={40}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          limit={3}
          closeButton={false}
          // theme="colored"
        />
      </div>
    </Portal>
  );
};

export default GlobalToastMounted;
