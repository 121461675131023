// General
import "./rates-dialog.scss";
import { useEffect, forwardRef } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateRatesInfoDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide, Button } from "@mui/material";
// i18next
import { useTranslation, Trans } from "react-i18next";

const RatesDialog = () => {
  // Redux variables
  const ratesInfoDialog = useSelector((state) => state.dialog.ratesInfoDialog);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!ratesInfoDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-RatesInfo-Dialog",
      },
    });
  }, [ratesInfoDialog]);

  // Event Handlers | Button
  const onCloseDialog = () => {
    dispatch(updateRatesInfoDialog(false));
  };

  return (
    <Dialog
      className="custom-bottom-sheet-dialog"
      fullScreen
      open={ratesInfoDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      <div id="rates-dialog">
        <div className="padding-container">
          <div className="header">{t("profile.rates")}</div>

          <div className="text">
            <Trans
              i18nKey="settings.rates_label_tooltip"
              components={{ br: <br />, b: <b />, i: <i /> }}
              values={{ free_min: 4, rate: 0.5 }}
            />
          </div>

          <Button
            className="close-button"
            variant="text"
            onClick={onCloseDialog}
          >
            {t("common.got_it")}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default RatesDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
