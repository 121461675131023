// General
import "./update-terms-of-relationship-dialog.scss";
import { useState, useEffect, forwardRef } from "react";
// Services
import {
  useLazyGetTorListQuery,
  useLazyGetProfileQuery,
  useSaveTorListMutation,
} from "../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateTor } from "../../../../redux/store/editProfileStore";
import { updateUpdateTorDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide, useMediaQuery } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Components
import Spinner from "../../elements/spinner/spinner";
import TorTag from "../../elements/tor-tag/tor-tag";

const UpdateTermsOfRelationshipDialog = () => {
  // API variables
  const [
    getTorList,
    {
      data: getTorListData,
      error: getTorListErrorData,
      isFetching: getTorListFetching,
      isLoading: getTorListLoading,
      isSuccess: getTorListSuccess,
      isError: getTorListError,
    },
  ] = useLazyGetTorListQuery();
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    saveTorList,
    {
      data: saveTorListData,
      error: saveTorListErrorData,
      isLoading: saveTorListLoading,
      isSuccess: saveTorListSuccess,
      isError: saveTorListError,
    },
  ] = useSaveTorListMutation();

  // General variables
  const [selectedTags, setSelectedTags] = useState([]);

  // Render variables
  const [tagDetails, setTagDetails] = useState("profile.tor_tap_to_see_detail");

  // Redux variables
  const updateTorDialog = useSelector((state) => state.dialog.updateTorDialog);
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!updateTorDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-D28",
      },
    });

    getProfile(null, true);
    getTorList();
  }, [updateTorDialog]);

  // Lifecycle | Check for update | Get Profile API Response
  useEffect(() => {
    if (getProfileFetching || getProfileLoading) {
    } else if (getProfileSuccess) {
      setSelectedTags(
        getProfileData?.data?.tor_tags?.map((item) => item.id) || []
      );
    } else if (getProfileError) {
    }
  }, [
    getProfileData,
    getProfileFetching,
    getProfileLoading,
    getProfileSuccess,
    getProfileError,
  ]);

  // Lifecycle | Check for update | Save Tor List API Response
  useEffect(() => {
    if (saveTorListLoading) {
    } else if (saveTorListSuccess) {
      if (saveTorListData?.status === 0) {
        dispatch(updateTor(saveTorListData?.data?.results));
        dispatch(updateUpdateTorDialog(false));
      }
    } else if (saveTorListError) {
    }
  }, [saveTorListLoading, saveTorListSuccess, saveTorListError]);

  // Event Handlers | Button
  const onSelectTag = (tag) => {
    setTagDetails(tag.description);

    if (selectedTags?.includes(tag.id)) {
      setSelectedTags((prev) => prev.filter((item) => item !== tag.id));
    } else {
      if (selectedTags?.length < 5) {
        setSelectedTags((prev) => [...prev, tag.id]);
      }
    }
  };
  const onSave = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-D28-28.1-Button",
      },
    });

    const obj = {
      tags: selectedTags,
    };
    saveTorList(obj);
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateUpdateTorDialog(false));
  };

  return (
    <Dialog
      className={
        isMobile
          ? "custom-bottom-sheet-no-radius-fullscreen-dialog"
          : "custom-radius10-dialog"
      }
      fullScreen={isMobile ? true : false}
      open={updateTorDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      <div id="update-terms-of-relationship-dialog">
        <div className="dialog-header">
          <div className="left-container" onClick={onCloseDialog}>
            <ChevronLeftIcon className="back-icon" />
          </div>
          <div className="middle-container">
            <Trans
              i18nKey={"profile.terms_of_relationship"}
              components={{ sup: <sup className="trade-mark-label" /> }}
            />
          </div>
          <div className="right-container"></div>
        </div>

        <div className="select-view">
          <div className="choose-tor-label">
            <Trans i18nKey={"profile.tor_desc"} />
          </div>

          <div className="padding-container">
            <div
              className={`selected-label ${
                selectedTags?.length === 5 ? "max-tags" : ""
              }`}
            >
              {t("profile.tor_selected", { n: selectedTags?.length, max: 5 })}
            </div>

            {getTorListFetching ? (
              <Spinner />
            ) : (
              <div className="tags-container">
                {getTorListData?.data?.results?.map((tag, index) => (
                  <div onClick={() => onSelectTag(tag)} key={index}>
                    <TorTag
                      torName={tag?.locale}
                      description={tag?.description}
                      blur={false}
                      match={selectedTags?.includes(tag?.id) ? true : false}
                      interactable={false}
                      readOnly={true}
                      theme={tag?.theme}
                    />
                  </div>
                ))}
              </div>
            )}

            {tagDetails && (
              <div className="tag-details">
                <Trans i18nKey={tagDetails} components={{ b: <b /> }} />
              </div>
            )}

            <div className="save-button" onClick={onSave}>
              {saveTorListLoading ? (
                <Spinner size={20} color={"white-spinner"} isPadding={false} />
              ) : (
                t("common.save")
              )}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default UpdateTermsOfRelationshipDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
