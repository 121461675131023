// General
import "./email-registered-dialog.scss";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateEmailRegisteredDialog } from "../../../../redux/store/dialogStore";
// Material UI
import { Dialog } from "@mui/material";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";

const EmailRegisteredDialog = () => {
  // Redux variables
  const emailRegisteredDialog = useSelector(
    (state) => state.dialog.emailRegisteredDialog
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Event Handlers | MUI Dialog
  const onBackToLogin = () => {
    onNavigate(routeConst.login.path);
    onCloseDialog();
  };
  const onCloseDialog = () => {
    dispatch(updateEmailRegisteredDialog(false));
  };

  return (
    <Dialog
      className="custom-radius25-dialog"
      open={emailRegisteredDialog}
      onClose={onCloseDialog}
    >
      <div id="email-registered-dialog">
        <div className="padding-container">
          <div className="top-container">
            <div className="unsaved-changes-title">
              {t("login.email_registered_title")}
            </div>

            <div className="unsaved-changes-description">
              <Trans
                i18nKey={"login.email_registered_description"}
                components={{ br: <br /> }}
              />
            </div>
          </div>

          <div className="button-container">
            <div className="bring-me-back-button" onClick={onBackToLogin}>
              {t("login.email_registered_button_cta")}
            </div>
            <div className="discard-changes-button" onClick={onCloseDialog}>
              {t("login.email_registered_button_back")}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default EmailRegisteredDialog;
