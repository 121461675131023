// General
import "./profile-dating-styles.scss";
import { useEffect } from "react";
// Services
import { useLazyGetProfileQuery } from "../../../../../services/data.service";
// Static Data
import editProfileConst from "../../../../../const/editProfileConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateDatingStyles,
  updateUpdateProfileDialogView,
} from "../../../../../redux/store/editProfileStore";
import { updateProfileCompletionPercentage } from "../../../../../redux/store/profileStore";
import {
  updateUpdateProfileFullscreenDialog,
  updateRestartRegistrationDialog,
} from "../../../../../redux/store/dialogStore";
// i18next
import { useTranslation } from "react-i18next";

const ProfileDatingStyle = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // Redux variables
  const datingStyles = useSelector((state) => state.userCard.datingStyles);
  const profileCompletionPercentage = useSelector(
    (state) => state.profile.profileCompletionPercentage
  );
  const profileRequirementPopup = useSelector(
    (state) => state.profile.profileRequirementPopup
  );
  const isSelf = useSelector((state) => state.userCard.isSelf);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  // Lifecycle | Check for update | getProfileData
  useEffect(() => {
    if (getProfileFetching || getProfileLoading) {
    } else if (getProfileSuccess) {
      // dispatch(
      //   updateProfileCompletionPercentage(
      //     getProfileData?.data?.profile_progress?.summary?.progress
      //   )
      // );

      dispatch(
        updateDatingStyles(getProfileData?.data?.relationship?.dating_style)
      );
    } else if (getProfileError) {
    }
  }, [
    getProfileFetching,
    getProfileLoading,
    getProfileSuccess,
    getProfileError,
  ]);

  // Event Handlers | Button
  const onEditSection = (section) => {
    if (isSelf) return;

    if (profileCompletionPercentage < profileRequirementPopup) {
      dispatch(updateRestartRegistrationDialog(true));
    } else {
      switch (section) {
        case editProfileConst.profileDialogView.datingStyle:
          dispatch(
            updateUpdateProfileDialogView(
              editProfileConst.profileDialogView.datingStyle
            )
          );
          break;
        default:
          break;
      }

      dispatch(updateUpdateProfileFullscreenDialog(true));
    }
  };

  if (datingStyles?.length > 0) {
    return (
      <div id="profile-dating-styles-subcomponent">
        <div className="padding-container">
          <div className="dating-styles-label">
            {t("profile.dating_styles")}
          </div>

          <div className="dating-styles-container">
            {datingStyles?.map((datingStyle, index) => (
              <div
                className={`dating-styles-item-container ${
                  isSelf ? "non-clickable" : ""
                }`}
                onClick={() => onEditSection("datingStyle")}
                key={index}
              >
                {datingStyle?.emoji} {datingStyle?.locale}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
};

export default ProfileDatingStyle;
