// General
import "./camera-microphone-settings-dialog.scss";
// Static Data
import utilityConst from "../../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  // Devices Functions
  updateSelectedCameraDevice,
  updateSelectedMicrophoneDevice,
  updateAdvancedCameraSettings,

  // Utility Functions
  updateChangeCameraMicrophonePassthrough,
} from "../../../../redux/store/privateCallStore";
import { updateCameraMicrophoneSettingsDialog } from "../../../../redux/store/dialogStore";
// react-device-detect
import { isMobile } from "react-device-detect";
// Material UI
import {
  Dialog,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";
import CameraFrontIcon from "@mui/icons-material/CameraFront";
import CameraRearIcon from "@mui/icons-material/CameraRear";
// i18next
import { useTranslation } from "react-i18next";

const CameraMicrophoneSettingsDialog = () => {
  // Redux variables
  const cameraMicrophoneSettingsDialog = useSelector(
    (state) => state.dialog.cameraMicrophoneSettingsDialog
  );
  const cameraDevices = useSelector((state) => state.privateCall.cameraDevices);
  const microphoneDevices = useSelector(
    (state) => state.privateCall.microphoneDevices
  );
  const selectedCameraDevice = useSelector(
    (state) => state.privateCall.selectedCameraDevice
  );
  const selectedMicrophoneDevice = useSelector(
    (state) => state.privateCall.selectedMicrophoneDevice
  );
  const advancedCameraSettings = useSelector(
    (state) => state.privateCall.advancedCameraSettings
  );
  const releaseChannel = useSelector((state) => state.app.releaseChannel);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Event Handlers | Button
  const onSaveSettings = () => {
    dispatch(updateChangeCameraMicrophonePassthrough());
    onCloseDialog();
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateCameraMicrophoneSettingsDialog(false));
  };

  // Event Handlers | MUI Switch
  const onSwitchAdvancedSettings = (event) => {
    dispatch(updateAdvancedCameraSettings(event.target.checked));
  };

  // Event Handlers | MUI ToggleButton
  const onToggleCamera = (event, newAlignment) => {
    dispatch(updateSelectedCameraDevice(newAlignment));
  };

  // Event Handlers | MUI Select
  const onCameraChange = (event) => {
    dispatch(updateSelectedCameraDevice(event.target.value));
  };
  const onMicrophoneChange = (event) => {
    dispatch(updateSelectedMicrophoneDevice(event.target.value));
  };

  return (
    <Dialog
      className="custom-radius10-dialog"
      open={cameraMicrophoneSettingsDialog}
      onClose={onCloseDialog}
    >
      <div id="camera-microphone-settings-dialog">
        {isMobile && (
          <div className="padding-container">
            {releaseChannel !== utilityConst.releaseChannel.stable && (
              <div className="advanced-camera-settings-switch-container">
                <FormGroup className="checkbox">
                  <FormControlLabel
                    control={
                      <Switch
                        className="custom-switch"
                        checked={advancedCameraSettings}
                        onChange={onSwitchAdvancedSettings}
                      />
                    }
                    label={t("1on1.advanced_settings")}
                  />
                </FormGroup>
              </div>
            )}

            {!advancedCameraSettings && (
              <div className="mobile-camera-toggle-container">
                <div className="choose-camera-label">
                  {t("1on1.choose_camera")}
                </div>

                <div className="toggle-camera-button-group-container">
                  <ToggleButtonGroup
                    value={selectedCameraDevice}
                    exclusive
                    onChange={onToggleCamera}
                  >
                    <ToggleButton className="toggle-camera-button" value="user">
                      <div className="toggle-camera-button-container">
                        <CameraFrontIcon />
                        <div className="front-camera-label">
                          {t("1on1.front")}
                        </div>
                      </div>
                    </ToggleButton>

                    <ToggleButton
                      className="toggle-camera-button"
                      value="environment"
                    >
                      <div className="toggle-camera-button-container">
                        <CameraRearIcon />
                        <div className="rear-camera-label">
                          {t("1on1.rear")}
                        </div>
                      </div>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </div>
            )}

            {advancedCameraSettings && (
              <div className="advanced-camera-settings-container">
                <div className="camera-settings-container">
                  <div className="camera-settings-label">
                    {t("1on1.camera")}
                  </div>

                  <div className="select-camera-container">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        {t("1on1.camera")}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedCameraDevice}
                        label="Camera"
                        onChange={onCameraChange}
                      >
                        {cameraDevices?.map((camera, index) => (
                          <MenuItem value={camera?.deviceId} key={index}>
                            {camera?.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className="microphone-settings-container">
                  <div className="microphone-settings-label">
                    {t("1on1.microphone")}
                  </div>

                  <div className="select-microphone-container">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        {t("1on1.microphone")}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedMicrophoneDevice}
                        label="Microphone"
                        onChange={onMicrophoneChange}
                      >
                        {microphoneDevices?.map((microphone, index) => (
                          <MenuItem value={microphone?.deviceId} key={index}>
                            {microphone?.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            )}

            <div className="save-button" onClick={onSaveSettings}>
              {t("common.save")}
            </div>
          </div>
        )}

        {!isMobile && (
          <div className="padding-container">
            <div className="advanced-camera-settings-container">
              <div className="camera-settings-container">
                <div className="camera-settings-label">{t("1on1.camera")}</div>

                <div className="select-camera-container">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      {t("1on1.camera")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedCameraDevice}
                      label="Camera"
                      onChange={onCameraChange}
                    >
                      {cameraDevices?.map((camera, index) => (
                        <MenuItem value={camera?.deviceId} key={index}>
                          {camera?.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className="microphone-settings-container">
                <div className="microphone-settings-label">
                  {t("1on1.microphone")}
                </div>

                <div className="select-microphone-container">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      {t("1on1.microphone")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedMicrophoneDevice}
                      label="Microphone"
                      onChange={onMicrophoneChange}
                    >
                      {microphoneDevices?.map((microphone, index) => (
                        <MenuItem value={microphone?.deviceId} key={index}>
                          {microphone?.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>

            <div className="save-button" onClick={onSaveSettings}>
              {t("common.save")}
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default CameraMicrophoneSettingsDialog;
