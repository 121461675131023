// General
import "./profile-action-buttons.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetProfileQuery,
  useLazyGetUserAioQuery,
  usePostSingleConversationMutation,
  useFavoriteUnfavoriteUserMutation,
} from "../../../../../services/data.service";
// Static Data
import routeConst from "../../../../../const/routeConst";
import profileConst from "../../../../../const/profileConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateIsFavorited } from "../../../../../redux/store/userCardStore";
import { updateLocalSearchList } from "../../../../../redux/store/searchStore";
import { updateLocalLivestreamList } from "../../../../../redux/store/livestreamingStore";
import { updateUserProfileDialog } from "../../../../../redux/store/dialogStore";
import {
  updateInitiatePrivateCall,
  updateNewUserConversation,
} from "../../../../../redux/store/inboxStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { useMediaQuery } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../spinner/spinner";
import MoreOptionsButton from "../more-options-button/more-options-button";
import userConst from "../../../../../const/userConst";

const ProfileActionButtons = (props) => {
  const { userProfileType = profileConst.userProfileType.dialog } = props;

  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();
  const [
    getConversationDetails,
    {
      data: getConversationDetailsData,
      error: getConversationDetailsErrorData,
      isLoading: getConversationDetailsLoading,
      isSuccess: getConversationDetailsSuccess,
      isError: getConversationDetailsError,
    },
  ] = usePostSingleConversationMutation();
  const [
    getVideoCallConversationDetails,
    {
      data: getVideoCallConversationDetailsData,
      error: getVideoCallConversationDetailsErrorData,
      isLoading: getVideoCallConversationDetailsLoading,
      isSuccess: getVideoCallConversationDetailsSuccess,
      isError: getVideoCallConversationDetailsError,
    },
  ] = usePostSingleConversationMutation();
  const [
    favoriteUnfavoriteUser,
    {
      data: favoriteUnfavoriteUserData,
      error: favoriteUnfavoriteUserErrorData,
      isLoading: favoriteUnfavoriteUserLoading,
      isSuccess: favoriteUnfavoriteUserSuccess,
      isError: favoriteUnfavoriteUserError,
    },
  ] = useFavoriteUnfavoriteUserMutation();

  // General variables
  const [isVideoCall, setIsVideoCall] = useState(null);

  // Redux variables
  const id = useSelector((state) => state.userCard.id);
  const idInt = useSelector((state) => state.userCard.idInt);
  const privatePhotos = useSelector((state) => state.userCard.privatePhotos);
  const privatePhotoRequested = useSelector(
    (state) => state.userCard.privatePhotoRequested
  );
  const privatePhotoApproval = useSelector(
    (state) => state.userCard.privatePhotoApproval
  );
  const requestedMyPrivatePhoto = useSelector(
    (state) => state.userCard.requestedMyPrivatePhoto
  );
  const approvedMyPrivatePhoto = useSelector(
    (state) => state.userCard.approvedMyPrivatePhoto
  );
  const isSilentInteraction = useSelector(
    (state) => state.userCard.isSilentInteraction
  );
  const isFavorited = useSelector((state) => state.userCard.isFavorited);
  const isCallEnabled = useSelector((state) => state.userCard.isCallEnabled);
  const isSugarbaby = useSelector((state) => state.userCard.isSugarbaby);
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const isMommy = useSelector((state) => state.user.isMommy);

  const isHunterMode = useSelector((state) => state.privateCall.isHunterMode);
  const userProfileDialog = useSelector(
    (state) => state.dialog.userProfileDialog
  );
  const role = useSelector((state) => state.userCard.role);
  const ownMembershipType = useSelector((state) => state.user.membershipType);
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");
  const inboxIsMobile = useMediaQuery("(max-width: 850px)");

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
    getUserAio(null, true);
  }, []);

  // Lifecycle | Check for update | conversationDetails API Response
  useEffect(() => {
    if (getConversationDetailsLoading) {
    } else if (getConversationDetailsSuccess) {
      if (getConversationDetailsData?.status === 0) {
        if (isVideoCall) {
          dispatch(updateInitiatePrivateCall(true));
          dispatch(updateUserProfileDialog(false));
          onNavigate(
            `${routeConst.inbox.messaging.chat.chatId.clearPath}${getConversationDetailsData?.data?.single_conversation?.id}`
          );
        } else {
          dispatch(updateUserProfileDialog(false));

          if (inboxIsMobile) {
            onNavigate(
              `${routeConst.inbox.messaging.chat.chatId.clearPath}${getConversationDetailsData?.data?.single_conversation?.id}`
            );
          } else {
            const obj = {
              conversation_accepted: false,
              conversation_id:
                getConversationDetailsData?.data?.single_conversation?.id,
              conversation_id_int: null,
              last_message: null,
              other_user:
                getConversationDetailsData?.data?.single_conversation
                  ?.other_user,
            };

            dispatch(updateNewUserConversation(obj));
            onNavigate(routeConst.inbox.path);
          }
        }
      }
    } else if (getConversationDetailsError) {
    }
  }, [
    getConversationDetailsLoading,
    getConversationDetailsSuccess,
    getConversationDetailsError,
  ]);

  // Lifecycle | Check for update | conversationDetails API Response
  useEffect(() => {
    if (getVideoCallConversationDetailsLoading) {
    } else if (getVideoCallConversationDetailsSuccess) {
      if (getVideoCallConversationDetailsData?.status === 0) {
        dispatch(updateInitiatePrivateCall(true));
        dispatch(updateUserProfileDialog(false));
        onNavigate(
          `${routeConst.inbox.messaging.chat.chatId.clearPath}${getVideoCallConversationDetailsData?.data?.single_conversation?.id}`
        );
      }
    } else if (getVideoCallConversationDetailsError) {
    }
  }, [
    getVideoCallConversationDetailsLoading,
    getVideoCallConversationDetailsSuccess,
    getVideoCallConversationDetailsError,
  ]);

  // Lifecycle | Check for update | Favorite Unfavorite User API Response
  useEffect(() => {
    if (favoriteUnfavoriteUserLoading) {
    } else if (favoriteUnfavoriteUserSuccess) {
      switch (favoriteUnfavoriteUserData?.status) {
        case 1:
          dispatch(updateIsFavorited(!isFavorited));

          // Update local view
          const obj = {
            idInt,
            isFavorited: !isFavorited,
          };
          dispatch(updateLocalSearchList(obj));
          dispatch(updateLocalLivestreamList(obj));
          break;
        default:
          break;
      }
    } else if (favoriteUnfavoriteUserError) {
    }
  }, [
    favoriteUnfavoriteUserLoading,
    favoriteUnfavoriteUserSuccess,
    favoriteUnfavoriteUserError,
  ]);

  // Event Handlers | Button
  const onMessageUser = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-S7-7.1-Button",
      },
    });

    setIsVideoCall(false);

    const obj = {
      recipient_user_id: id,
    };
    getConversationDetails({ id: null, body: obj });
  };
  const onFavoriteProfile = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-S7-7.2-Button",
      },
    });

    const obj = {
      user_id: id,
      favorite: !isFavorited,
    };
    favoriteUnfavoriteUser(obj);
  };
  const onVideoCallUser = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-S7-7.3-Button",
      },
    });

    setIsVideoCall(true);

    const obj = {
      recipient_user_id: id,
    };
    getVideoCallConversationDetails({ id: null, body: obj });
  };

  // Utility Functions
  const showMessageButton = () => {
    return (
      id &&
      (ownMembershipType === userConst.membershipType.admin ||
        (isSugarbaby && isDaddy) ||
        (!isSugarbaby && !isDaddy))
    );
  };

  if (getProfileData?.data?.id_int !== idInt) {
    if (getUserAioFetching || getUserAioLoading) {
    } else if (getUserAioSuccess) {
      if (!isSilentInteraction) {
        if (userProfileType === profileConst.userProfileType.dialog) {
          return (
            <div
              id="profile-view-floating-action-buttons-subcomponent"
              className={`${
                userProfileType === profileConst.userProfileType.dialog ||
                userProfileDialog
                  ? "fixed-position"
                  : ""
              } ${isHunterMode ? "push-up" : ""} ${isMobile ? "center" : ""}`}
            >
              {showMessageButton() && (
                <div className="message-icon-container" onClick={onMessageUser}>
                  {getConversationDetailsLoading ? (
                    <Spinner size={20} isPadding={false} />
                  ) : (
                    getIcon("messageRedIcon", "message-icon")
                  )}
                </div>
              )}

              <div
                className="favorite-icon-container"
                onClick={onFavoriteProfile}
              >
                {favoriteUnfavoriteUserLoading ? (
                  <Spinner size={20} isPadding={false} />
                ) : isFavorited ? (
                  getIcon("favoritedRedIcon", "favorite-icon")
                ) : (
                  getIcon("favoriteRedIcon", "favorite-icon")
                )}
              </div>

              {getUserAioData?.data?.private_call_settings?.enable_calls &&
                getUserAioData?.data?.private_call_settings
                  ?.system_private_calls_enabled &&
                isCallEnabled && (
                  <div
                    className="video-call-icon-container"
                    onClick={onVideoCallUser}
                  >
                    {getIcon("videoCallRedIcon", "video-call-icon")}
                  </div>
                )}
            </div>
          );
        } else if (userProfileType === profileConst.userProfileType.page) {
          return (
            <div
              id="profile-view-floating-action-buttons-subcomponent"
              className="flex-end"
            >
              {/* Message Button */}
              {showMessageButton() && (
                <div
                  className="message-button-container"
                  onClick={onMessageUser}
                >
                  <div className="message-content-container">
                    {getConversationDetailsLoading ? (
                      <Spinner
                        size={15}
                        isPadding={false}
                        color={"white-spinner"}
                      />
                    ) : (
                      getIcon("messageWhiteIcon", "message-icon")
                    )}

                    <div className="message-label">{t("profile.message")}</div>
                  </div>
                </div>
              )}

              {/* Favorite Button */}
              <div
                className="favorite-button-container"
                onClick={onFavoriteProfile}
              >
                <div className="favorite-content-container">
                  {favoriteUnfavoriteUserLoading ? (
                    <Spinner size={15} isPadding={false} />
                  ) : isFavorited ? (
                    getIcon("favoritedRedIcon", "favorite-icon")
                  ) : (
                    getIcon("favoriteWhiteIcon", "favorite-icon")
                  )}

                  <div className="favorite-label">{t("profile.favorite")}</div>
                </div>
              </div>

              {/* Video Call Button */}
              {getUserAioData?.data?.private_call_settings?.enable_calls &&
                getUserAioData?.data?.private_call_settings
                  ?.system_private_calls_enabled &&
                isCallEnabled && (
                  <div
                    className="video-call-button-container"
                    onClick={onVideoCallUser}
                  >
                    <div className="video-call-content-container">
                      {getVideoCallConversationDetailsLoading ? (
                        <Spinner size={15} isPadding={false} />
                      ) : (
                        getIcon("videoCallIcon", "video-call-icon")
                      )}

                      <div className="video-call-label">
                        {t("1on1.video_call")}
                      </div>
                    </div>
                  </div>
                )}

              <MoreOptionsButton
                userProfileType={userProfileType}
                id={id}
                idInt={idInt}
                privatePhotos={privatePhotos}
                privatePhotoRequested={privatePhotoRequested}
                privatePhotoApproval={privatePhotoApproval}
                requestedMyPrivatePhoto={requestedMyPrivatePhoto}
                approvedMyPrivatePhoto={approvedMyPrivatePhoto}
                showClearMessage={false}
              />
            </div>
          );
        }
      }
    } else if (getUserAioError) {
    }
  }
};

export default ProfileActionButtons;
