// General
import "./more-info.scss";
// Static Data
import routeConst from "../../../../const/routeConst";
// i18next
import { useTranslation } from "react-i18next";

const MoreInfo = () => {
  // i18next variables
  const { t, i18n } = useTranslation();

  // Utility Functions
  const getLevellingLitePath = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return routeConst.misc.levellingLite.pathZhTw;
    } else if (i18n.language.toLowerCase().includes("ja")) {
      return routeConst.misc.levellingLite.pathZhTw;
    }

    return routeConst.misc.levellingLite.path;
  };

  return (
    <div id="levelling-more-info-subcomponent">
      <iframe
        className="iframe-container"
        src={getLevellingLitePath()}
      ></iframe>
    </div>
  );
};

export default MoreInfo;
