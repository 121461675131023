// General
import "./livestream-report-success-dialog.scss";
import { useEffect } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateLivestreamReportSuccessDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";

const LivestreamReportSuccessDialog = () => {
  // Redux variables
  const livestreamReportSuccessDialog = useSelector(
    (state) => state.dialog.livestreamReportSuccessDialog
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!livestreamReportSuccessDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-LivestreamReportSuccess-Dialog",
      },
    });
  }, [livestreamReportSuccessDialog]);

  // Event Handlers | Button
  const onCloseDialog = () => {
    dispatch(updateLivestreamReportSuccessDialog(false));
  };

  return (
    <Dialog
      className="custom-radius35-dialog"
      open={livestreamReportSuccessDialog}
      onClose={onCloseDialog}
    >
      <div id="livestream-report-success-dialog">
        <div className="padding-container">
          <div className="header-container">
            <div className="header">{t("report.thank_you")}</div>
            <div className="header">{t("report.handle_the_rest")}</div>
          </div>

          <div className="text">{t("report.your_feedback")}</div>

          <div className="close-button" onClick={onCloseDialog}>
            {t("common.close")}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default LivestreamReportSuccessDialog;
