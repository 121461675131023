// Moment
import moment from "moment";
// i18next
import { useTranslation } from "react-i18next";

const useGetReadableDate = () => {
  // i18next variables
  const { i18n } = useTranslation();

  // Only works outside of useEffect
  if (i18n.language.toLowerCase().includes("zh-tw")) {
    moment.locale("zh-tw");
  } else {
    moment.locale("en");
  }

  // Main Function
  const getReadableDate = (date) => {
    return moment.utc(date).local().locale(i18n.language).fromNow();
  };

  return getReadableDate;
};

export default useGetReadableDate;
