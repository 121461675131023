// General
import "./end-call-dialog.scss";
import { useEffect, forwardRef } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateEndVideoCall } from "../../../../redux/store/privateCallStore";
import { updateEndCallDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide, useMediaQuery } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";

const EndCallDialog = () => {
  // Redux variables
  const endCallDialog = useSelector((state) => state.dialog.endCallDialog);
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 450px)");

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!endCallDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-EndCall-Dialog",
      },
    });
  }, [endCallDialog]);

  // Event Handlers | Button
  const onEndCallClick = () => {
    dispatch(updateEndVideoCall(true));
    onCloseDialog();
  };
  const onCancel = () => {
    onCloseDialog();
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateEndCallDialog(false));
  };

  return (
    <Dialog
      className={`${
        isMobile ? "custom-bottom-sheet-dialog" : "custom-radius25-dialog"
      }`}
      fullScreen={isMobile ? true : false}
      open={endCallDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      <div id="end-call-dialog">
        <div className="padding-container">
          <div className="text">{t("1on1.end_call_confirmation")}</div>

          <div className="end-call-button" onClick={onEndCallClick}>
            {t("1on1.yes_end_now")}
          </div>

          <div className="cancel-button" onClick={onCancel}>
            {t("common.cancel")}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default EndCallDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
