// General
import "./enable-call-dialog.scss";
import { useEffect, forwardRef } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateEnableCallInfoDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide, Button } from "@mui/material";
// i18next
import { useTranslation, Trans } from "react-i18next";

const EnableCallDialog = () => {
  // Redux variables
  const enableCallInfoDialog = useSelector(
    (state) => state.dialog.enableCallInfoDialog
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!enableCallInfoDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-EnableCall-Dialog",
      },
    });
  }, [enableCallInfoDialog]);

  // Event Handlers | Button
  const onCloseDialog = () => {
    dispatch(updateEnableCallInfoDialog(false));
  };

  return (
    <Dialog
      className="custom-bottom-sheet-dialog"
      fullScreen
      open={enableCallInfoDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={onCloseDialog}
    >
      <div id="enable-call-dialog">
        <div className="padding-container">
          <div className="header">{t("profile.enable_call")}</div>

          <div className="text">
            <Trans
              i18nKey={"profile.enable_call_tips"}
              components={{ br: <br /> }}
            />
          </div>

          <Button
            className="close-button"
            variant="text"
            onClick={onCloseDialog}
          >
            {t("common.got_it")}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default EnableCallDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
