// General
import "./last-stream-diamonds-earned.scss";
import { useEffect } from "react";
// Services
import { useLazyGetLastStreamResultQuery } from "../../../../../services/data.service";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../../../shared/elements/spinner/spinner";

const LastStreamDiamondsEarned = () => {
  // API variables
  const [
    getLastStreamSummary,
    {
      data: getLastStreamSummaryData,
      error: getLastStreamSummaryErrorData,
      isFetching: getLastStreamSummaryFetching,
      isLoading: getLastStreamSummaryLoading,
      isSuccess: getLastStreamSummarySuccess,
      isError: getLastStreamSummaryError,
    },
  ] = useLazyGetLastStreamResultQuery();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getLastStreamSummary(null, true);
  }, []);

  if (getLastStreamSummaryFetching || getLastStreamSummaryLoading) {
    return <Spinner />;
  } else if (getLastStreamSummarySuccess) {
    return (
      <div id="last-stream-summary-last-stream-diamonds-earned-subcomponent">
        <div className="padding-container">
          <div className="diamonds-earned-label">
            {t("1on1.diamonds_earned")}:
          </div>

          <div className="diamonds-earned-container">
            <div className="total-diamonds-earned-container">
              <div className="label">{t("1on1.total_diamonds_earned")}</div>
              <div className="diamond-icon-container">
                {getIcon("sbDiamondIcon", "diamond-icon")}
              </div>
              <div className="diamond-label">
                +{" "}
                {getLastStreamSummaryData?.data?.reports?.total_diamonds?.toLocaleString()}
              </div>
            </div>

            <div className="gift-diamonds-container">
              <div className="label">{t("1on1.gifts_received")}</div>
              <div className="diamond-icon-container">
                {getIcon("sbDiamondIcon", "diamond-icon")}
              </div>
              <div className="diamond-label">
                +{" "}
                {getLastStreamSummaryData?.data?.reports?.diamonds_from_gifts_received?.toLocaleString()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (getLastStreamSummaryError) {
  }
};

export default LastStreamDiamondsEarned;
