// General
import "./video-call-rate.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetProfileQuery,
  usePostPrivateCallRatingMutation,
} from "../../../services/data.service";
// Static Data
import routeConst from "../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  // Callee Functions
  updateCalleeId,
  updateCalleeProfilePhoto,
  updateCalleeUsername,

  // Utility Functions
  resetPrivateCallStore,
} from "../../../redux/store/privateCallStore";
import { updateThankYouForReviewDialog } from "../../../redux/store/dialogStore";
// Material UI
import { Rating, Divider, TextField } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import Spinner from "../../shared/elements/spinner/spinner";
import ThankYouForReviewDialog from "../../shared/dialog-content/thank-you-for-review-dialog/thank-you-for-review-dialog";

const VideoCallRate = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    postPrivateCallRating,
    {
      data: postPrivateCallRatingData,
      error: postPrivateCallRatingErrorData,
      isLoading: postPrivateCallRatingLoading,
      isSuccess: postPrivateCallRatingSuccess,
      isError: postPrivateCallRatingError,
    },
  ] = usePostPrivateCallRatingMutation();

  // General variables
  const [review, setReview] = useState("");

  // Redux variables
  const requestId = useSelector((state) => state.privateCall.requestId);
  const calleeUsername = useSelector(
    (state) => state.privateCall.calleeUsername
  );
  const dispatch = useDispatch();

  // MUI variables
  const [ratingValue, setRatingValue] = useState(0);

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  // Lifecycle | Check for update | Post Private Call Rating API Response
  useEffect(() => {
    if (postPrivateCallRatingLoading) {
    } else if (postPrivateCallRatingSuccess) {
      if (postPrivateCallRatingData?.status === 0) {
        resetPrivateCallState();
        dispatch(updateThankYouForReviewDialog(true));
      }
    } else if (postPrivateCallRatingError) {
      if (postPrivateCallRatingErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    postPrivateCallRatingLoading,
    postPrivateCallRatingSuccess,
    postPrivateCallRatingError,
  ]);

  // Event Handlers | Button
  const onSubmitRating = () => {
    if (ratingValue > 0) {
      const obj = {
        call_request_id: requestId,
        rating: ratingValue,
        review: review,
      };

      postPrivateCallRating(obj);
    } else {
      resetPrivateCallState();
      onNavigate(routeConst.live.path);
    }
  };

  // Event Handlers | MUI Rating
  const onRatingChange = (event, newValue) => {
    setRatingValue(newValue);
  };

  // Event Handlers | MUI Text Field
  const onReviewChange = (event) => {
    setReview(event.target.value);
  };

  // Utility Functions
  const resetPrivateCallState = () => {
    dispatch(resetPrivateCallStore());

    // Reintialize some private call variables
    dispatch(updateCalleeId(getProfileData?.data?.id));
    dispatch(updateCalleeUsername(getProfileData?.data?.username));
    dispatch(
      updateCalleeProfilePhoto(
        getProfileData?.data?.profile_photo?.original_photo
      )
    );
  };

  return (
    <div id="video-call-rate-page">
      <div className="max-width-container">
        <div className="padding-container">
          <div className="rating-container">
            <Rating
              className="custom-rating"
              name="simple-controlled"
              size="large"
              value={ratingValue}
              onChange={onRatingChange}
            />

            <div className="rate-label">{t("1on1.tap_to_rate")}</div>
          </div>
        </div>

        <Divider />

        <div className="padding-container">
          <div className="write-review-container">
            <div className="review-label">{t("1on1.write_a_review")}</div>

            <div className="review-input-container">
              <TextField
                className="review-input"
                placeholder={t("1on1.how_was_user_performance", {
                  user: calleeUsername,
                })}
                value={review}
                onChange={onReviewChange}
                multiline
                minRows={10}
                maxRows={10}
                variant="filled"
                InputProps={{ disableUnderline: true }}
              />
            </div>

            <div className="send-button" onClick={onSubmitRating}>
              {postPrivateCallRatingLoading ? (
                <Spinner size={25} isPadding={false} color="white-spinner" />
              ) : (
                t("common.send")
              )}
            </div>
          </div>
        </div>
      </div>

      <ThankYouForReviewDialog />
    </div>
  );
};

export default VideoCallRate;
