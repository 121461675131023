// General
import { useEffect } from "react";
// Environment
import environment from "../../../../environment/environment";
// Redux
import { useDispatch } from "react-redux";
import { updateGoogleMapsLoaded } from "../../../../redux/store/noResetStore";
// React Google Maps/api
import { useLoadScript } from "@react-google-maps/api";

const GoogleMapsMounted = () => {
  // Redux variables
  const dispatch = useDispatch();

  // Google Maps variables
  const { isLoaded, loadError } = useLoadScript({
    // id: "google-map-script",
    googleMapsApiKey: process.env["REACT_APP_GOOGLE_MAPS_API_KEY"],
    libraries: environment.googleMapsLibraries,
  });

  useEffect(() => {
    dispatch(updateGoogleMapsLoaded(isLoaded));
  }, [isLoaded]);

  return <div id="google-maps-mounted-shadow-component"></div>;
};

export default GoogleMapsMounted;
