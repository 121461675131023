// General
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // Inbox & Interest page
  viewedMeBanners: [],

  // Profile page
  profileBanners: [],

  // Diamonds Withdrawal Info Dialog
  diamondsWithdrawalInfoDialogBanner: [],
};

export const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {
    // Inbox & Interest page
    updateViewedMeBanners: (state, action) => {
      state.viewedMeBanners.push(action.payload);
    },

    // Profile page
    updateProfileBanners: (state, action) => {
      state.profileBanners.push(action.payload);
    },

    // Diamonds Withdrawal Info Dialog
    updateDiamondsWithdrawalInfoDialogBanner: (state, action) => {
      state.diamondsWithdrawalInfoDialogBanner.push(action.payload);
    },

    // Utility Functions
    resetBannerStore: () => {
      return { ...initialState };
    },
  },
});

export const {
  // Inbox & Interest page
  updateViewedMeBanners,

  // Profile page
  updateProfileBanners,

  // Diamonds Withdrawal Info Dialog
  updateDiamondsWithdrawalInfoDialogBanner,

  // Utility Functions
  resetBannerStore,
} = bannerSlice.actions;

export default bannerSlice.reducer;
