// General
import "./diamonds-withdrawal-info-dialog.scss";
import { useEffect, forwardRef } from "react";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateDiamondsWithdrawalInfoDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation } from "react-i18next";
// Components
import Banner from "../../elements/banner/banner";

const DiamondsWithdrawalInfoDialog = () => {
  // Redux variables
  const diamondsWithdrawalInfoDialog = useSelector(
    (state) => state.dialog.diamondsWithdrawalInfoDialog
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!diamondsWithdrawalInfoDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-DiamondsWithdrawalInfo-Dialog",
      },
    });
  }, [diamondsWithdrawalInfoDialog]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateDiamondsWithdrawalInfoDialog(false));
  };

  return (
    <Dialog
      className={
        isMobile ? "custom-bottom-sheet-dialog" : "custom-radius35-dialog"
      }
      fullScreen={isMobile ? true : false}
      open={diamondsWithdrawalInfoDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      <div
        id="diamonds-withdrawal-info-dialog"
        className={isMobile ? "" : "diamonds-withdrawal-max-width-container"}
      >
        <div className="dialog-header">
          <div className="close-button-container">
            <CloseIcon className="close-button" onClick={onCloseDialog} />
          </div>
        </div>

        <div className="padding-container">
          <Banner type={"diamonds-withdrawal-dialog"} view={"carousel"} />

          <div className="header">
            {t("settings.convert_your_diamonds_into_cash")}
          </div>

          <div className="text">
            {t("settings.our_support_team_will_assist_you")}
          </div>

          <div className="list-container">
            <div className="text">
              {t("settings.please_standby_the_following_items")}
            </div>

            <ul>
              <li>{t("settings.id_passport")}</li>
              <li>{t("settings.bank_account")}</li>
              <li>{t("settings.paypal_account")}</li>
            </ul>
          </div>

          <a
            className="close-button"
            href={routeConst.zendesk.diamondsWithdrawal.path}
            target="_blank"
          >
            {t("settings.contact_withdrawal_team")}
          </a>
        </div>
      </div>
    </Dialog>
  );
};

export default DiamondsWithdrawalInfoDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
