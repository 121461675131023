// General
import "./terms.scss";
import { useEffect } from "react";
// Static Data
import routeConst from "../../const/routeConst";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation } from "react-i18next";
// Components
import UtilityDesktopNavbar from "../shared/elements/utility-desktop-navbar/utility-desktop-navbar";

const Terms = () => {
  // i18next variables
  const { i18n } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-3",
      },
    });
  }, []);

  // Utility Functions
  const getTermsPath = () => {
    if (i18n.language.toLowerCase().includes("zh-tw")) {
      return routeConst.terms.pathAltZhTw;
    } else if (i18n.language.toLowerCase().includes("ja")) {
      return routeConst.terms.pathAltJaJp;
    }

    return routeConst.terms.pathAlt;
  };

  return (
    <div id="terms-page">
      <UtilityDesktopNavbar leftButton={"back"} />

      <iframe className="iframe-container" src={getTermsPath()}></iframe>
    </div>
  );
};

export default Terms;
