// General
import "./incomplete-profile.scss";
import { useEffect } from "react";
// Services
import { useLazyGetProfileCompletionQuery } from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
import userConst from "../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateProfileCompletionPercentage,
  updateProfileRequirementPopup,
} from "../../../../redux/store/profileStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const IncompleteProfile = () => {
  // API variables
  const [
    getProfileCompletion,
    {
      data: getProfileCompletionData,
      error: getProfileCompletionErrorData,
      isFetching: getProfileCompletionFetching,
      isLoading: getProfileCompletionLoading,
      isSuccess: getProfileCompletionSuccess,
      isError: getProfileCompletionError,
    },
  ] = useLazyGetProfileCompletionQuery();

  // Redux variables
  const profileCompletionPercentage = useSelector(
    (state) => state.profile.profileCompletionPercentage
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-7-7.3",
      },
    });

    getProfileCompletion(null, false);
  }, []);

  // Lifecycle | Check for update | getProfileCompletion API Response
  useEffect(() => {
    if (getProfileCompletionFetching || getProfileCompletionLoading) {
    } else if (getProfileCompletionSuccess) {
      if (getProfileCompletionData?.status === 0) {
        dispatch(
          updateProfileCompletionPercentage(
            getProfileCompletionData?.data?.profile_progress?.summary?.progress
          )
        );
        dispatch(
          updateProfileRequirementPopup(
            getProfileCompletionData?.data?.profile_progress?.summary
              ?.requirement
          )
        );
      }
    } else if (getProfileCompletionError) {
    }
  }, [
    getProfileCompletionFetching,
    getProfileCompletionLoading,
    getProfileCompletionSuccess,
    getProfileCompletionError,
  ]);

  // Render Functions | API Response
  if (getProfileCompletionFetching || getProfileCompletionLoading) {
  } else if (getProfileCompletionSuccess) {
    if (getProfileCompletionData?.status === 0) {
      if (profileCompletionPercentage < 100) {
        return (
          <div
            id="search-incomplete-profile-subcomponent"
            onClick={() => onNavigate(routeConst.profile.completion.path)}
          >
            <div className="left-container">
              <div className="profile-icon-container">
                {getIcon("userProfileCompleteIcon", "profile-icon")}
              </div>
            </div>

            <div className="description-container">
              <div className="subheader">{t("signup.complete_profile")}</div>
              <div className="text">
                {getProfileCompletionData?.data?.membership_type ===
                userConst.membershipType.free
                  ? t("signup.complete_profile_desc")
                  : t("signup.complete_profile_desc_premium")}
              </div>
              <div className="complete-container">
                <div className="progress-bar-container">
                  <div
                    className="progress-bar"
                    style={{
                      width: `${profileCompletionPercentage || 0}%`,
                    }}
                  ></div>
                </div>

                <div className="percentage">
                  {profileCompletionPercentage || 0}%
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  } else if (getProfileCompletionError) {
    if (getProfileCompletionErrorData?.status === 401) {
      onNavigate(routeConst.logout.path);
    }
  }
};

export default IncompleteProfile;
