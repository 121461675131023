// General
import "./ongoing-events.scss";
// Components
import PkContainer from "./pk-container/pk-container";
import FloatingBanners from "./floating-banners/floating-banners";

const OngoingEvents = () => {
  return (
    <div id="interactable-overlay-ongoing-events-subcomponent">
      <PkContainer />
      <FloatingBanners />
    </div>
  );
};

export default OngoingEvents;
