// General
import "./tip-menu.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetPrivateCallTipItemsQuery,
  useLazyGetPrivateCallTipItemsMenuQuery,
  useLazyGetProfileQuery,
} from "../../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updatePurchaseDialogTab } from "../../../../../redux/store/productsStore";
import {
  updateCustomizeTipsDialog,
  updateProductPurchaseDialog,
} from "../../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import CustomizeTipsDialog from "../../../dialog-content/customize-tips-dialog/customize-tips-dialog";

const TipMenu = () => {
  // API variables
  const [
    getPrivateCallTipItems,
    {
      data: getPrivateCallTipItemsData,
      error: getPrivateCallTipItemsErrorData,
      isFetching: getPrivateCallTipItemsFetching,
      isLoading: getPrivateCallTipItemsLoading,
      isSuccess: getPrivateCallTipItemsSuccess,
      isError: getPrivateCallTipItemsError,
    },
  ] = useLazyGetPrivateCallTipItemsQuery();
  const [
    getPrivateCallTipItemsMenu,
    {
      data: getPrivateCallTipItemsMenuData,
      error: getPrivateCallTipItemsMenuErrorData,
      isFetching: getPrivateCallTipItemsMenuFetching,
      isLoading: getPrivateCallTipItemsMenuLoading,
      isSuccess: getPrivateCallTipItemsMenuSuccess,
      isError: getPrivateCallTipItemsMenuError,
    },
  ] = useLazyGetPrivateCallTipItemsMenuQuery();
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // General variables
  const [isExpanded, setIsExpanded] = useState(false);

  // Redux variables
  const calleeId = useSelector((state) => state.privateCall.calleeId);
  const callerId = useSelector((state) => state.privateCall.callerId);
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!calleeId && !callerId) return;

    getProfile(null, true);
  }, [calleeId, callerId]);

  // Lifecycle | Check for update | getProfile API Response
  useEffect(() => {
    if (getProfileFetching || getProfileLoading) {
    } else if (getProfileSuccess) {
      if (isDaddy) {
        getPrivateCallTipItemsMenu(
          `?user_id=${
            getProfileData?.data?.id === calleeId ? callerId : calleeId
          }`
        );
      } else {
        getPrivateCallTipItems();
      }
    } else if (getProfileError) {
    }
  }, [
    getProfileFetching,
    getProfileLoading,
    getProfileSuccess,
    getProfileError,
  ]);

  // Lifecycle | Check for update | getPrivateCallTipItemsMenu API Response
  useEffect(() => {
    if (getPrivateCallTipItemsMenuFetching || getPrivateCallTipItemsLoading) {
    } else if (getPrivateCallTipItemsMenuSuccess) {
      setIsExpanded(true);

      setTimeout(() => {
        setIsExpanded(false);
      }, 3000);
    } else if (getPrivateCallTipItemsMenuError) {
    }
  }, [
    getPrivateCallTipItemsMenuFetching,
    getPrivateCallTipItemsMenuLoading,
    getPrivateCallTipItemsMenuSuccess,
    getPrivateCallTipItemsMenuError,
  ]);

  // Lifecycle | Check for update | getPrivateCallTipItems API Response
  useEffect(() => {
    if (getPrivateCallTipItemsFetching || getPrivateCallTipItemsLoading) {
    } else if (getPrivateCallTipItemsSuccess) {
      setIsExpanded(true);

      setTimeout(() => {
        setIsExpanded(false);
      }, 3000);
    } else if (getPrivateCallTipItemsError) {
    }
  }, [
    getPrivateCallTipItemsFetching,
    getPrivateCallTipItemsLoading,
    getPrivateCallTipItemsSuccess,
    getPrivateCallTipItemsError,
  ]);

  // Event Handlers | Button
  const onExpandTipMenu = () => {
    setIsExpanded(!isExpanded);

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-46-46.8-Button",
      },
    });
  };
  const onViewTipMenu = () => {
    if (isDaddy) {
      dispatch(updatePurchaseDialogTab(2));
      dispatch(updateProductPurchaseDialog(true));
    } else {
      dispatch(updateCustomizeTipsDialog(true));
    }
  };

  return (
    <div
      id="interactable-overlay-tip-menu-subcomponent"
      className={`${isExpanded ? "bring-to-front" : ""}`}
    >
      <div
        className={`tip-menu-header-container ${
          isExpanded ? "expanded-tip-header" : ""
        }`}
        onClick={onExpandTipMenu}
      >
        {isExpanded ? (
          <ExpandLessIcon className="left-icon" />
        ) : (
          <ChevronLeftIcon className="left-icon" />
        )}
        <div className="tip-menu-label">{t("1on1.tip_menu")}</div>
      </div>

      {isExpanded && (
        <div className="tip-menu-list-container">
          <div className="tip-padding-container">
            <div className="tips-container">
              {isDaddy &&
                getPrivateCallTipItemsMenuData?.data?.results?.map(
                  (tip, index) => {
                    if (index < 5 && tip?.description && tip?.value) {
                      return (
                        <div className="tip-item-container" key={index}>
                          <div className="tip-index">{`${
                            index + 1
                          }.\u00a0`}</div>
                          <div className="tip-description">
                            {tip?.description}:
                          </div>
                          <div className="coin-container">
                            {getIcon("sbCoinIcon", "coin-icon")}
                          </div>
                          <div>{tip?.value?.toLocaleString()}</div>
                        </div>
                      );
                    }
                  }
                )}

              {!isDaddy &&
                getPrivateCallTipItemsData?.data?.results?.map((tip, index) => {
                  if (index < 5) {
                    if (tip?.description && tip?.value) {
                      return (
                        <div className="tip-item-container" key={index}>
                          <div className="tip-index">{`${
                            index + 1
                          }.\u00a0`}</div>
                          <div className="tip-description">
                            {tip?.description ?? t("1on1.add_task")}:
                          </div>
                          <div className="coin-container">
                            {getIcon("sbCoinIcon", "coin-icon")}
                          </div>
                          <div>{tip?.value?.toLocaleString() ?? ""}</div>
                        </div>
                      );
                    } else {
                      return (
                        <div className="add-task-label" key={index}>
                          {t("1on1.add_task")}
                        </div>
                      );
                    }
                  }
                })}
            </div>
            <div className="view-tips-menu-button" onClick={onViewTipMenu}>
              {t("1on1.view_tips_menu")}
            </div>
          </div>
        </div>
      )}

      <CustomizeTipsDialog />
    </div>
  );
};

export default TipMenu;
