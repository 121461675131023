// General
import "./appearance.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetJourneyQuestionsQuery,
  useUpdateEditUserProfileMutation,
} from "../../../../../services/data.service";
// Static Data
import editProfileConst from "../../../../../const/editProfileConst";
import userConst from "../../../../../const/userConst";
import utilityConst from "../../../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateHeight,
  updateBodyType,
  updateEthnicity,
  updateRace,
  updateEyeColor,
  updateHairColor,
  updateUpdateProfileDialogView,
  updateUpdateProfileFullscreenTab,
  updateProfileCompletionState,
} from "../../../../../redux/store/editProfileStore";
import {
  updateProfileCompletionPercentage,
  updateProfileRequirementPopup,
} from "../../../../../redux/store/profileStore";
import {
  updateRestartRegistrationDialog,
  updateUpdateProfileFullscreenDialog,
} from "../../../../../redux/store/dialogStore";
import {
  updateSuccessToast,
  updateInfoToast,
} from "../../../../../redux/store/toastStore";
// Material UI
import { Divider, MenuItem, FormControl, Select } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../../../shared/elements/spinner/spinner";
import ModerationStatus from "../../moderation-status/moderation-status";

const Appearance = (props) => {
  const { mode = utilityConst.editProfileMode.mobile } = props;

  // API variables
  const [
    getJourneyQuestions,
    {
      data: getJourneyQuestionsData,
      error: getJourneyQuestionsErrorData,
      isFetching: getJourneyQuestionsFetching,
      isLoading: getJourneyQuestionsLoading,
      isSuccess: getJourneyQuestionsSuccess,
      isError: getJourneyQuestionsError,
    },
  ] = useLazyGetJourneyQuestionsQuery();
  const [
    updateUserProfile,
    {
      data: updateUserProfileData,
      error: updateUserProfileErrorData,
      isLoading: updateUserProfileLoading,
      isSuccess: updateUserProfileSuccess,
      isError: updateUserProfileError,
    },
  ] = useUpdateEditUserProfileMutation();

  // General variables
  const [localHeight, setLocalHeight] = useState(null);
  const [localBodyType, setLocalBodyType] = useState(null);
  const [localEthnicity, setLocalEthnicity] = useState(null);
  const [localRace, setLocalRace] = useState(null);
  const [localEyeColor, setLocalEyeColor] = useState(null);
  const [localHairColor, setLocalHairColor] = useState(null);

  // Redux variables
  const height = useSelector((state) => state.editProfile.height);
  const bodyType = useSelector((state) => state.editProfile.bodyType);
  const ethnicity = useSelector((state) => state.editProfile.ethnicity);
  const race = useSelector((state) => state.editProfile.race);
  const eyeColor = useSelector((state) => state.editProfile.eyeColor);
  const hairColor = useSelector((state) => state.editProfile.hairColor);
  const profileCompletionPercentage = useSelector(
    (state) => state.profile.profileCompletionPercentage
  );
  const profileRequirementPopup = useSelector(
    (state) => state.profile.profileRequirementPopup
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    setLocalHeight(height);
    setLocalBodyType(bodyType?.id);
    setLocalEthnicity(ethnicity?.id);
    setLocalRace(race?.id);
    setLocalEyeColor(eyeColor?.id);
    setLocalHairColor(hairColor?.id);

    getJourneyQuestions(null, true);
  }, []);

  // Lifecycle | Check for update | Update User Profile API Response
  useEffect(() => {
    if (updateUserProfileLoading) {
    } else if (updateUserProfileSuccess) {
      switch (updateUserProfileData?.status) {
        case 1:
          dispatch(
            updateProfileCompletionPercentage(
              updateUserProfileData?.data?.user?.profile_progress?.summary
                ?.progress
            )
          );
          dispatch(
            updateProfileRequirementPopup(
              updateUserProfileData?.data?.user?.profile_progress?.summary
                ?.requirement
            )
          );

          dispatch(
            updateHeight(updateUserProfileData?.data?.user?.appearance?.height)
          );
          dispatch(
            updateBodyType(
              updateUserProfileData?.data?.user?.appearance?.body_type
            )
          );
          dispatch(
            updateEthnicity(
              updateUserProfileData?.data?.user?.appearance?.ethnicity
            )
          );
          dispatch(
            updateRace(updateUserProfileData?.data?.user?.appearance?.race)
          );
          dispatch(
            updateEyeColor(
              updateUserProfileData?.data?.user?.appearance?.eye_color
            )
          );
          dispatch(
            updateHairColor(
              updateUserProfileData?.data?.user?.appearance?.hair_color
            )
          );

          const infoToast = {
            message: t("signup.profile_updated"),
            autoClose: 1500,
          };
          dispatch(updateInfoToast(infoToast));
          break;
        default:
          break;
      }
      // Backend returning empty if success
      // const toastObj = {
      //   message: t("signup.profile_updated"),
      //   autoClose: 3000,
      // };
      // dispatch(updateSuccessToast(toastObj));
    } else if (updateUserProfileError) {
    }
  }, [
    updateUserProfileLoading,
    updateUserProfileSuccess,
    updateUserProfileError,
  ]);

  // Event Handlers | Button
  const onSaveDetails = () => {
    if (!isAppearanceValid()) return;

    const obj = {
      height,
      body_type: bodyType,
      ethnicity,
      eye_color: eyeColor,
      hair_color: hairColor,
    };
    updateUserProfile(obj);
  };
  const onEditSection = (section) => {
    if (profileCompletionPercentage < profileRequirementPopup) {
      dispatch(updateRestartRegistrationDialog(true));
    } else {
      // dispatch(
      //   updateProfileCompletionState({
      //     name: "appearance",
      //     slide: returnMaxAppearance() - 1,
      //   })
      // );

      switch (section) {
        case "height":
          // dispatch(updateUpdateProfileFullscreenTab(0));
          dispatch(
            updateUpdateProfileDialogView(
              editProfileConst.profileDialogView.height
            )
          );
          break;
        case "bodyType":
          // dispatch(updateUpdateProfileFullscreenTab(1));
          dispatch(
            updateUpdateProfileDialogView(
              editProfileConst.profileDialogView.bodyType
            )
          );
          break;
        case "ethnicity":
        case "race":
          // dispatch(updateUpdateProfileFullscreenTab(2));
          dispatch(
            updateUpdateProfileDialogView(
              editProfileConst.profileDialogView.ethnicity
            )
          );
          break;
        case "eyeColor":
          // dispatch(updateUpdateProfileFullscreenTab(3));
          dispatch(
            updateUpdateProfileDialogView(
              editProfileConst.profileDialogView.eyeColor
            )
          );
          break;
        case "hairColor":
          // dispatch(updateUpdateProfileFullscreenTab(4));
          dispatch(
            updateUpdateProfileDialogView(
              editProfileConst.profileDialogView.hairColor
            )
          );
          break;
        default:
          break;
      }

      dispatch(updateUpdateProfileFullscreenDialog(true));
    }
  };
  const onCheckProfilePercentage = () => {
    if (profileCompletionPercentage < profileRequirementPopup) {
      dispatch(updateRestartRegistrationDialog(true));
    }
  };

  // Event Handlers | MUI Text Fields
  const onInputChange = (state) => (event) => {
    switch (state) {
      case "height":
        setLocalHeight(event.target.value);
        const heightObj = {
          profile: {
            height: event.target.value,
          },
        };
        updateUserProfile(heightObj);
        break;
      case "body-type":
        setLocalBodyType(event.target.value);
        const bodyTypeObj = {
          profile: {
            body_type: event.target.value,
          },
        };
        updateUserProfile(bodyTypeObj);
        break;
      case "ethnicity":
        setLocalEthnicity(event.target.value);
        setLocalRace(null);
        const ethnicityObj = {
          profile: {
            ethnicity: event.target.value,
          },
        };
        updateUserProfile(ethnicityObj);
        break;
      case "race":
        setLocalRace(event.target.value);
        const raceObj = {
          profile: {
            race: event.target.value,
          },
        };
        updateUserProfile(raceObj);
        break;
      case "eye-color":
        setLocalEyeColor(event.target.value);
        const eyeColorObj = {
          profile: {
            eye_color: event.target.value,
          },
        };
        updateUserProfile(eyeColorObj);
        break;
      case "hair-color":
        setLocalHairColor(event.target.value);
        const hairColorObj = {
          profile: {
            hair_color: event.target.value,
          },
        };
        updateUserProfile(hairColorObj);
        break;
      default:
        break;
    }
  };

  // Utility Functions
  const isAppearanceValid = () => {
    if (height && bodyType && ethnicity && eyeColor && hairColor) {
      // Removed due to clashing with different country
      // if (ethnicity === "asian" && !race) {
      //   return false;
      // }
      return true;
    } else {
      return false;
    }
  };
  const getHeightData = () => {
    return userConst.height;
  };
  const getBodyTypeData = () => {
    return getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "body_type"
    )?.selections;
  };
  const getEthnicityData = () => {
    return getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "ethnicity"
    )?.selections;
  };
  const getRaceData = () => {
    const raceData = getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "race"
    )?.selections;

    const ethnicityObj = getEthnicityData()?.find(
      (item) => item?.id === localEthnicity
    );
    const filteredRaceData = raceData?.filter(
      (item) => item?.sub_category === ethnicityObj?.tag
    );

    return filteredRaceData;
  };
  const getEyeColorData = () => {
    return getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "eye_color"
    )?.selections;
  };
  const getHairColorData = () => {
    return getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "hair_color"
    )?.selections;
  };
  const returnMaxAppearance = () => {
    return 6;
  };
  const returnIsJourneyIncomplete = () => {
    return profileCompletionPercentage < profileRequirementPopup;
  };

  if (mode === utilityConst.editProfileMode.mobile) {
    return (
      <div id="mobile-edit-profile-appearance-subcomponent">
        <div className="appearance-label-container">
          <div className="appearance-label">{t("profile.appearance")}</div>
        </div>

        <div className="form-field-padding-container">
          {/* Height */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label ${height ? "" : "incomplete-field"}`}>
                {t("profile.height")}
              </div>
            </div>

            <div
              className="form-container"
              onClick={() => onEditSection("height")}
            >
              {height ? (
                <div className="filled-form">{height} cm</div>
              ) : (
                <div className="empty-form">+ {t("common.add")}</div>
              )}

              <div className="right-icon">
                <ChevronRightIcon className="select-form-field-dropdown-icon" />
              </div>
            </div>
          </div>

          {/* Body Type */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label ${bodyType ? "" : "incomplete-field"}`}>
                {t("profile.body_type")}
              </div>
            </div>

            <div
              className="form-container"
              onClick={() => onEditSection("bodyType")}
            >
              {bodyType ? (
                <div className="filled-form">{bodyType?.locale}</div>
              ) : (
                <div className="empty-form">+ {t("common.add")}</div>
              )}

              <div className="right-icon">
                <ChevronRightIcon className="select-form-field-dropdown-icon" />
              </div>
            </div>
          </div>

          {/* Ethnicity */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label ${ethnicity ? "" : "incomplete-field"}`}>
                {t("profile.ethnicity")}
              </div>
            </div>

            <div
              className="form-container"
              onClick={() => onEditSection("ethnicity")}
            >
              {ethnicity ? (
                <div className="filled-form">{ethnicity?.locale}</div>
              ) : (
                <div className="empty-form">+ {t("common.add")}</div>
              )}

              <div className="right-icon">
                <ChevronRightIcon className="select-form-field-dropdown-icon" />
              </div>
            </div>
          </div>

          {/* Race */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label ${race ? "" : "incomplete-field"}`}>
                {t("profile.race")}
              </div>
            </div>

            <div
              className="form-container"
              onClick={() => onEditSection("race")}
            >
              {race ? (
                <div className="filled-form">{race?.locale}</div>
              ) : (
                <div className="empty-form">+ {t("common.add")}</div>
              )}

              <div className="right-icon">
                <ChevronRightIcon className="select-form-field-dropdown-icon" />
              </div>
            </div>
          </div>

          {/* Eye Color */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label ${eyeColor ? "" : "incomplete-field"}`}>
                {t("profile.eye_color")}
              </div>
            </div>

            <div
              className="form-container"
              onClick={() => onEditSection("eyeColor")}
            >
              {eyeColor ? (
                <div className="filled-form">{eyeColor?.locale}</div>
              ) : (
                <div className="empty-form">+ {t("common.add")}</div>
              )}

              <div className="right-icon">
                <ChevronRightIcon className="select-form-field-dropdown-icon" />
              </div>
            </div>
          </div>

          {/* Hair Color */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label ${hairColor ? "" : "incomplete-field"}`}>
                {t("profile.hair_color")}
              </div>
            </div>

            <div
              className="form-container"
              onClick={() => onEditSection("hairColor")}
            >
              {hairColor ? (
                <div className="filled-form">{hairColor?.locale}</div>
              ) : (
                <div className="empty-form">+ {t("common.add")}</div>
              )}

              <div className="right-icon">
                <ChevronRightIcon className="select-form-field-dropdown-icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (mode === utilityConst.editProfileMode.desktop) {
    return (
      <div
        id="desktop-edit-profile-appearance-subcomponent"
        onClick={onCheckProfilePercentage}
      >
        {/* In Review */}
        <ModerationStatus
          status={userConst.profileGeneralApprovalStatus.inReview}
        />

        {/* Rejected */}
        <ModerationStatus
          status={userConst.profileGeneralApprovalStatus.rejected}
        />

        <div className="appearance-static-label-container">
          <div className="appearance-label">{t("profile.appearance")}</div>
          <Divider className="short-divider" />
        </div>

        <div
          className={`form-field-padding-container ${
            returnIsJourneyIncomplete() ? "none-clickable" : ""
          }`}
        >
          {/* Height */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label ${height ? "" : "incomplete-field"}`}>
                {t("profile.height")}
              </div>
            </div>

            <div className="form-container">
              <FormControl
                className="form-field custom-text-field-max-radius custom-text-field-outline-default custom-text-field-outline-white-background-color"
                variant="outlined"
                fullWidth
              >
                <Select
                  className="form-select"
                  displayEmpty
                  value={localHeight || ""}
                  onChange={onInputChange("height")}
                  disableUnderline={true}
                  renderValue={(selected) => {
                    if (!selected || selected === "") {
                      return (
                        <div className="default-select-value">
                          + {t("common.add")}
                        </div>
                      );
                    }

                    const selectedHeight = getHeightData()?.find(
                      (item) => item === selected
                    );

                    return (
                      selectedHeight || (
                        <div className="default-select-value">
                          + {t("common.add")}
                        </div>
                      )
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    + {t("common.add")}
                  </MenuItem>
                  {getHeightData()?.map((height, index) => (
                    <MenuItem key={index} value={height}>
                      {t(`${height}`)}cm
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>

          {/* Body Type */}
          <div className="form-field-container">
            <div className="label-container">
              <div
                className={`label ${localBodyType ? "" : "incomplete-field"}`}
              >
                {t("profile.body_type")}
              </div>
            </div>

            <div className="form-container">
              <FormControl
                className="form-field custom-text-field-max-radius custom-text-field-outline-default custom-text-field-outline-white-background-color"
                variant="outlined"
                fullWidth
              >
                <Select
                  className="form-select"
                  displayEmpty
                  value={localBodyType || ""}
                  onChange={onInputChange("body-type")}
                  disableUnderline={true}
                  renderValue={(selected) => {
                    if (!selected || selected === "") {
                      return (
                        <div className="default-select-value">
                          + {t("common.add")}
                        </div>
                      );
                    }

                    const selectedBodyType = getBodyTypeData()?.find(
                      (item) => item?.id === selected
                    );

                    return (
                      selectedBodyType?.display_locale || (
                        <div className="default-select-value">
                          + {t("common.add")}
                        </div>
                      )
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    + {t("common.add")}
                  </MenuItem>
                  {getBodyTypeData()?.map((bodyType, index) => (
                    <MenuItem key={index} value={bodyType?.id}>
                      {t(`${bodyType?.display_locale}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>

          {/* Ethnicity */}
          <div className="form-field-container">
            <div className="label-container">
              <div
                className={`label ${localEthnicity ? "" : "incomplete-field"}`}
              >
                {t("profile.ethnicity")}
              </div>
            </div>

            <div className="form-container">
              <FormControl
                className="form-field custom-text-field-max-radius custom-text-field-outline-default custom-text-field-outline-white-background-color"
                variant="outlined"
                fullWidth
              >
                <Select
                  className="form-select"
                  displayEmpty
                  value={localEthnicity || ""}
                  onChange={onInputChange("ethnicity")}
                  disableUnderline={true}
                  renderValue={(selected) => {
                    if (!selected || selected === "") {
                      return (
                        <div className="default-select-value">
                          + {t("common.add")}
                        </div>
                      );
                    }

                    const selectedEthnicity = getEthnicityData()?.find(
                      (item) => item?.id === selected
                    );

                    return (
                      selectedEthnicity?.display_locale || (
                        <div className="default-select-value">
                          + {t("common.add")}
                        </div>
                      )
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    + {t("common.add")}
                  </MenuItem>
                  {getEthnicityData()?.map((ethnicity, index) => (
                    <MenuItem key={index} value={ethnicity?.id}>
                      {t(`${ethnicity?.display_locale}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>

          {/* Race */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label ${localRace ? "" : "incomplete-field"}`}>
                {t("profile.race")}
              </div>
            </div>

            <div className="form-container">
              <FormControl
                className="form-field custom-text-field-max-radius custom-text-field-outline-default custom-text-field-outline-white-background-color"
                variant="outlined"
                fullWidth
              >
                <Select
                  className="form-select"
                  displayEmpty
                  value={localRace || ""}
                  onChange={onInputChange("race")}
                  disableUnderline={true}
                  renderValue={(selected) => {
                    if (!selected || selected === "") {
                      return (
                        <div className="default-select-value">
                          + {t("common.add")}
                        </div>
                      );
                    }

                    const selectedRace = getRaceData()?.find(
                      (item) => item?.id === selected
                    );

                    return (
                      selectedRace?.display_locale || (
                        <div className="default-select-value">
                          + {t("common.add")}
                        </div>
                      )
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    + {t("common.add")}
                  </MenuItem>
                  {getRaceData()?.map((race, index) => (
                    <MenuItem key={index} value={race?.id}>
                      {t(`${race?.display_locale}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>

          {/* Eye Color */}
          <div className="form-field-container">
            <div className="label-container">
              <div
                className={`label ${localEyeColor ? "" : "incomplete-field"}`}
              >
                {t("profile.eye_color")}
              </div>
            </div>

            <div className="form-container">
              <FormControl
                className="form-field custom-text-field-max-radius custom-text-field-outline-default custom-text-field-outline-white-background-color"
                variant="outlined"
                fullWidth
              >
                <Select
                  className="form-select"
                  displayEmpty
                  value={localEyeColor || ""}
                  onChange={onInputChange("eye-color")}
                  disableUnderline={true}
                  renderValue={(selected) => {
                    if (!selected || selected === "") {
                      return (
                        <div className="default-select-value">
                          + {t("common.add")}
                        </div>
                      );
                    }

                    const selectedEyeColor = getEyeColorData()?.find(
                      (item) => item?.id === selected
                    );

                    return (
                      selectedEyeColor?.display_locale || (
                        <div className="default-select-value">
                          + {t("common.add")}
                        </div>
                      )
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    + {t("common.add")}
                  </MenuItem>
                  {getEyeColorData()?.map((eyeColor, index) => (
                    <MenuItem key={index} value={eyeColor?.id}>
                      {t(`${eyeColor?.display_locale}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>

          {/* Hair Color */}
          <div className="form-field-container">
            <div className="label-container">
              <div
                className={`label ${localHairColor ? "" : "incomplete-field"}`}
              >
                {t("profile.hair_color")}
              </div>
            </div>

            <div className="form-container">
              <FormControl
                className="form-field custom-text-field-max-radius custom-text-field-outline-default custom-text-field-outline-white-background-color"
                variant="outlined"
                fullWidth
              >
                <Select
                  className="form-select"
                  displayEmpty
                  value={localHairColor || ""}
                  onChange={onInputChange("hair-color")}
                  disableUnderline={true}
                  renderValue={(selected) => {
                    if (!selected || selected === "") {
                      return (
                        <div className="default-select-value">
                          + {t("common.add")}
                        </div>
                      );
                    }

                    const selectedHairColor = getHairColorData()?.find(
                      (item) => item?.id === selected
                    );

                    return (
                      selectedHairColor?.display_locale || (
                        <div className="default-select-value">
                          + {t("common.add")}
                        </div>
                      )
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    <em>+ {t("common.add")}</em>
                  </MenuItem>
                  {getHairColorData()?.map((hairColor, index) => (
                    <MenuItem key={index} value={hairColor?.id}>
                      {t(`${hairColor?.display_locale}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>

        {false && (
          <div className="bottom-section-container">
            <div
              className={`save-button ${
                isAppearanceValid() ? "" : "disabled-button"
              }`}
              onClick={onSaveDetails}
            >
              {updateUserProfileLoading ? (
                <Spinner size={19} isPadding={false} color={"white-spinner"} />
              ) : (
                t("common.save")
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
};

export default Appearance;
