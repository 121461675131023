// General
import "./card-details.scss";
import { useState, useEffect, useRef } from "react";
// Services
import { useLazyGetAioQuery } from "../../../../services/data.service";
import { cardExpiryMonth } from "../../../../services/regex.service";
// Static Data
import routeConst from "../../../../const/routeConst";
import cardConst from "../../../../const/cardConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateCardNumber,
  updateExpiryDateMonth,
  updateExpiryDateYear,
  updateCVV,
  updateCardHolderNameFirstName,
  updateCardHolderNameLastName,
  updateCountry,
  updateIsFormValid,
} from "../../../../redux/store/paymentStore";
// Material UI
import {
  Box,
  TextField,
  MenuItem,
  Avatar,
  InputAdornment,
  Input,
  Select,
  FormControl,
} from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Moment
import moment from "moment";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";

const CardDetails = () => {
  // API variables
  const [
    getAio,
    {
      data: getAioData,
      error: getAioErrorData,
      isFetching: getAioFetching,
      isLoading: getAioLoading,
      isSuccess: getAioSuccess,
      isError: getAioError,
    },
  ] = useLazyGetAioQuery();

  // Constant variables
  const monthList = cardConst.month;
  const yearList = Array.from({ length: 21 }, (_, index) => {
    const fullYear = new Date().getFullYear() + index;
    const shortYear = String(fullYear).slice(-2);
    return { shortYear, fullYear };
  });

  // General variables
  const [cardNumberErrorMessage, setCardNumberErrorMessage] = useState("");
  const [cardNumberFocus, setCardNumberFocus] = useState(false);
  const [cardNumberValid, setCardNumberValid] = useState(false);
  const [cardNumberError, setCardNumberError] = useState(false);

  const [expiryDateMonthErrorMessage, setExpiryDateMonthErrorMessage] =
    useState("");
  const [expiryDateMonthFocus, setExpiryDateMonthFocus] = useState(false);
  const [expiryDateMonthValid, setExpiryDateMonthValid] = useState(false);
  const [expiryDateMonthError, setExpiryDateMonthError] = useState(false);

  const [expiryDateYearErrorMessage, setExpiryDateYearErrorMessage] =
    useState("");
  const [expiryDateYearFocus, setExpiryDateYearFocus] = useState(false);
  const [expiryDateYearValid, setExpiryDateYearValid] = useState(false);
  const [expiryDateYearError, setExpiryDateYearError] = useState(false);

  const [cvvErrorMessage, setCvvErrorMessage] = useState("");
  const [cvvFocus, setCvvFocus] = useState(false);
  const [cvvValid, setCvvValid] = useState(false);
  const [cvvError, setCvvError] = useState(false);

  const [
    cardHolderNameFirstNameErrorMessage,
    setCardHolderNameFirstNameErrorMessage,
  ] = useState("");
  const [cardHolderNameFirstNameFocus, setCardHolderNameFirstNameFocus] =
    useState(false);
  const [cardHolderNameFirstNameValid, setCardHolderNameFirstNameValid] =
    useState(false);
  const [cardHolderNameFirstNameError, setCardHolderNameFirstNameError] =
    useState(false);

  const [
    cardHolderNameLastNameErrorMessage,
    setCardHolderNameLastNameErrorMessage,
  ] = useState("");
  const [cardHolderNameLastNameFocus, setCardHolderNameLastNameFocus] =
    useState(false);
  const [cardHolderNameLastNameValid, setCardHolderNameLastNameValid] =
    useState(false);
  const [cardHolderNameLastNameError, setCardHolderNameLastNameError] =
    useState(false);

  const [countryErrorMessage, setCountryErrorMessage] = useState("");
  const [countryFocus, setCountryFocus] = useState(false);
  const [countryValid, setCountryValid] = useState(false);
  const [countryError, setCountryError] = useState(false);

  const cardNumberRef = useRef(null);

  // Render variables
  const [countryTextFieldView, setCountryTextFieldView] = useState(false);

  // Redux variables
  const addPaymentMethodDialog = useSelector(
    (state) => state.dialog.addPaymentMethodDialog
  );
  const cardNumber = useSelector((state) => state.payment.cardNumber);
  const cardType = useSelector((state) => state.payment.cardType);
  const expiryDateMonth = useSelector(
    (state) => state.payment.expiryDate.month
  );
  const expiryDateYear = useSelector((state) => state.payment.expiryDate.year);
  const cvv = useSelector((state) => state.payment.cvv);
  const cardHolderNameFirstName = useSelector(
    (state) => state.payment.cardHolderName.firstName
  );
  const cardHolderNameLastName = useSelector(
    (state) => state.payment.cardHolderName.lastName
  );
  const country = useSelector((state) => state.payment.country);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Utility variables
  const cardMonthRegex = cardExpiryMonth;

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    getAio(null, true);
  }, [addPaymentMethodDialog]);

  // Lifecycle | Check for update | Get AIO API Response
  useEffect(() => {
    if (getAioFetching || getAioLoading) {
    } else if (getAioSuccess) {
      if (getAioData?.status === 0) {
        dispatch(updateCountry(getAioData?.payload?.default_country_data));
      }
    } else if (getAioError) {
      if (getAioErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [getAioFetching, getAioLoading, getAioSuccess, getAioError]);

  // Lifecycle | Check for update | Check text field validation
  useEffect(() => {
    isFormValid();
  }, [
    cardNumberValid,
    expiryDateMonthValid,
    expiryDateYearValid,
    cvvValid,
    cardHolderNameFirstNameValid,
    cardHolderNameLastNameValid,
    countryValid,
  ]);

  // Event Handlers | MUI Text Fields
  const onInputChange = (state) => (event) => {
    switch (state) {
      case "card-number":
        dispatch(updateCardNumber(event.target.value));
        break;
      case "expiry-date-month":
        if (Number(event.target.value) < 0) {
          dispatch(updateExpiryDateMonth(0));
        } else if (Number(event.target.value) > 12) {
          dispatch(updateExpiryDateMonth(12));
        } else {
          dispatch(updateExpiryDateMonth(event.target.value));
        }
        break;
      case "expiry-date-year":
        const currentYear = moment().format("YY");
        const yearValue = event.target.value;

        let expiryYear;

        if (yearValue.length < 2) {
          expiryYear = yearValue;
        } else if (yearValue.length > 2) {
          expiryYear = yearValue.substring(0, 2);
        } else {
          expiryYear = Math.max(Number(yearValue), Number(currentYear));
        }

        dispatch(updateExpiryDateYear(expiryYear));
        break;
      case "ccv":
        if (event.target.value.length < 4) {
          dispatch(updateCVV(event.target.value));
        }
        break;
      case "card-holder-name-first-name":
        dispatch(
          updateCardHolderNameFirstName(event.target.value?.trimStart())
        );
        break;
      case "card-holder-name-last-name":
        dispatch(updateCardHolderNameLastName(event.target.value?.trimStart()));
        break;
      case "country":
        dispatch(updateCountry(event.target.value));
        break;
      default:
        break;
    }
  };
  const onFocusChange = (state) => (event) => {
    switch (state) {
      case "card-number":
        setCardNumberFocus(true);
        break;
      case "expiry-date-month":
        setExpiryDateMonthFocus(true);
        break;
      case "expiry-date-year":
        setExpiryDateYearFocus(true);
        break;
      case "ccv":
        setCvvFocus(true);
        break;
      case "card-holder-name-first-name":
        setCardHolderNameFirstNameFocus(true);
        break;
      case "card-holder-name-last-name":
        setCardHolderNameLastNameFocus(true);
        break;
      case "country":
        setCountryFocus(true);
        break;
      default:
        break;
    }
  };
  const onBlurChange = (state) => (event) => {
    switch (state) {
      case "card-holder-name-first-name":
        dispatch(
          updateCardHolderNameFirstName(cardHolderNameFirstName?.trim())
        );
        break;
      case "card-holder-name-last-name":
        dispatch(updateCardHolderNameLastName(cardHolderNameLastName?.trim()));
        break;
      default:
        break;
    }
  };

  // Utility Functions
  const isFormValid = () => {
    dispatch(
      updateIsFormValid(
        cardNumberValid &&
          expiryDateMonthValid &&
          expiryDateYearValid &&
          cvvValid &&
          cardHolderNameFirstNameValid &&
          cardHolderNameLastNameValid &&
          countryValid
      )
    );
  };
  const cardNumberValidation = () => {
    let valid = true;
    let error = false;

    if (!cardNumberFocus) {
      valid = false;
      error = false;
    } else if (cardNumberFocus && cardNumber === "") {
      setCardNumberErrorMessage("Required");
      valid = false;
      error = true;
    } else {
      setCardNumberErrorMessage("");
      valid = true;
      error = false;
    }

    setCardNumberValid(valid);
    setCardNumberError(error);
  };
  const expiryDateMonthValidation = () => {
    let valid = true;
    let error = false;

    if (!expiryDateMonthFocus) {
      valid = false;
      error = false;
    } else if (expiryDateMonthFocus && expiryDateMonth === "") {
      setExpiryDateMonthErrorMessage("Required");
      valid = false;
      error = true;
    } else if (!cardMonthRegex.test(expiryDateMonth)) {
      setExpiryDateMonthErrorMessage("Invalid");
      valid = false;
      error = true;
    } else {
      setExpiryDateMonthErrorMessage("");
      valid = true;
      error = false;
    }

    setExpiryDateMonthValid(valid);
    setExpiryDateMonthError(error);
  };
  const expiryDateYearValidation = () => {
    let valid = true;
    let error = false;
    const currentYear = moment().format("YY");

    if (!expiryDateYearFocus) {
      valid = false;
      error = false;
    } else if (expiryDateYearFocus && expiryDateYear === "") {
      setExpiryDateYearErrorMessage("Required");
      valid = false;
      error = true;
    } else if (Number(expiryDateYear) < Number(currentYear)) {
      setExpiryDateYearErrorMessage("Invalid");
      valid = false;
      error = true;
    } else {
      setExpiryDateYearErrorMessage("");
      valid = true;
      error = false;
    }

    setExpiryDateYearValid(valid);
    setExpiryDateYearError(error);
  };
  const cvvValidation = () => {
    let valid = true;
    let error = false;

    if (!cvvFocus) {
      valid = false;
      error = false;
    } else if (cvvFocus && cvv === "") {
      setCvvErrorMessage("Required");
      valid = false;
      error = true;
    } else if (cvv.length !== 3) {
      setCvvErrorMessage("Invalid");
      valid = false;
      error = true;
    } else {
      setCvvErrorMessage("");
      valid = true;
      error = false;
    }

    setCvvValid(valid);
    setCvvError(error);
  };
  const cardHolderNameFirstNameValidation = () => {
    let valid = true;
    let error = false;

    if (!cardHolderNameFirstNameFocus) {
      valid = false;
      error = false;
    } else if (
      cardHolderNameFirstNameFocus &&
      (cardHolderNameFirstName === "" || cardHolderNameFirstName?.trim() === "")
    ) {
      setCardHolderNameFirstNameErrorMessage("Required");
      valid = false;
      error = true;
    } else {
      setCardHolderNameFirstNameErrorMessage("");
      valid = true;
      error = false;
    }

    setCardHolderNameFirstNameValid(valid);
    setCardHolderNameFirstNameError(error);
  };
  const cardHolderNameLastNameValidation = () => {
    let valid = true;
    let error = false;

    if (!cardHolderNameLastNameFocus) {
      valid = false;
      error = false;
    } else if (
      cardHolderNameLastNameFocus &&
      (cardHolderNameLastName === "" || cardHolderNameLastName?.trim() === "")
    ) {
      setCardHolderNameLastNameErrorMessage("Required");
      valid = false;
      error = true;
    } else {
      setCardHolderNameLastNameErrorMessage("");
      valid = true;
      error = false;
    }

    setCardHolderNameLastNameValid(valid);
    setCardHolderNameLastNameError(error);
  };
  const countryValidation = () => {
    let valid = true;
    let error = false;

    if (country === "") {
      setCountryErrorMessage("Required");
      valid = false;
      error = true;
    } else {
      setCountryErrorMessage("");
      valid = true;
      error = false;
    }

    setCountryValid(valid);
    setCountryError(error);
  };

  // Check for form validation
  useEffect(() => {
    cardNumberValidation();
    expiryDateMonthValidation();
    expiryDateYearValidation();
    cvvValidation();
    cardHolderNameFirstNameValidation();
    cardHolderNameLastNameValidation();
    countryValidation();
  }, [
    cardNumber,
    expiryDateMonth,
    expiryDateYear,
    cvv,
    cardHolderNameFirstName,
    cardHolderNameLastName,
    country,
    cardNumberFocus,
    expiryDateMonthFocus,
    expiryDateYearFocus,
    cvvFocus,
    cardHolderNameFirstNameFocus,
    cardHolderNameLastNameFocus,
    countryFocus,
    cardNumberErrorMessage,
    expiryDateMonthErrorMessage,
    expiryDateYearErrorMessage,
    cvvErrorMessage,
    cardHolderNameFirstNameErrorMessage,
    cardHolderNameLastNameErrorMessage,
    countryErrorMessage,
  ]);

  return (
    <Box id="upgrade-payment-card-details-subcomponent">
      <form
        id="payment-form"
        className="card-details-container"
        action=""
        method="post"
      >
        <div className="card-number-form-field-container">
          <div className="label-container">
            <div className="label">{t("purchase.card_number")}</div>
          </div>

          <div className="input-container">
            <TextField
              className="form-field custom-text-field"
              required
              value={cardNumber}
              error={cardNumberError}
              onChange={onInputChange("card-number")}
              placeholder={t("purchase.card_number")}
              helperText={cardNumberErrorMessage}
              variant="standard"
              onFocus={onFocusChange("card-number")}
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{cardType}</InputAdornment>
                ),
              }}
              inputProps={{
                "data-securionpay": "number",
              }}
            ></TextField>
          </div>
        </div>

        <div className="second-row">
          <div className="label-container">
            <div className="expiry-date-label">{t("purchase.expiry_date")}</div>
            <div className="cvv-label">CVV</div>
          </div>

          <div className="form-container">
            <div className="expiry-date-month-form-field-container">
              <FormControl
                className="form-field custom-text-field"
                variant="standard"
              >
                <Select
                  displayEmpty
                  value={expiryDateMonth}
                  error={expiryDateMonthError}
                  onChange={onInputChange("expiry-date-month")}
                  helperText={expiryDateMonthErrorMessage}
                  onFocus={onFocusChange("expiry-date-month")}
                  inputProps={{ "data-securionpay": "expMonth" }}
                >
                  <MenuItem disabled value="">
                    <em>{t("purchase.month")}</em>
                  </MenuItem>

                  {monthList?.map((month, index) => (
                    <MenuItem key={index} value={month}>
                      {month}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="expiry-date-year-form-field-container">
              <FormControl
                className="form-field custom-text-field"
                variant="standard"
              >
                <Select
                  displayEmpty
                  value={expiryDateYear}
                  error={expiryDateYearError}
                  onChange={onInputChange("expiry-date-year")}
                  helperText={expiryDateYearErrorMessage}
                  onFocus={onFocusChange("expiry-date-year")}
                  inputProps={{ "data-securionpay": "expYear" }}
                >
                  <MenuItem disabled value="">
                    <em>{t("purchase.year")}</em>
                  </MenuItem>

                  {yearList?.map((year, index) => (
                    <MenuItem key={index} value={year?.shortYear}>
                      {year?.fullYear}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="cvv-form-field-container">
              <TextField
                className="form-field custom-text-field"
                value={cvv}
                error={cvvError}
                onChange={onInputChange("ccv")}
                placeholder="CVV"
                helperText={cvvErrorMessage}
                variant="standard"
                onFocus={onFocusChange("ccv")}
                type="password"
                inputProps={{
                  "data-securionpay": "cvc",
                }}
              ></TextField>
            </div>
          </div>
        </div>

        <div className="third-row">
          <div className="label-container">
            <div className="label">{t("purchase.cardholder_name")}</div>
          </div>

          <div className="form-container">
            <div className="first-name-form-field-container">
              <TextField
                className="form-field custom-text-field"
                value={cardHolderNameFirstName}
                error={cardHolderNameFirstNameError}
                onChange={onInputChange("card-holder-name-first-name")}
                placeholder={t("purchase.first_name")}
                helperText={cardHolderNameFirstNameErrorMessage}
                variant="standard"
                onFocus={onFocusChange("card-holder-name-first-name")}
                onBlur={onBlurChange("card-holder-name-first-name")}
              ></TextField>
            </div>

            <div className="last-name-form-field-container">
              <TextField
                className="form-field custom-text-field"
                value={cardHolderNameLastName}
                error={cardHolderNameLastNameError}
                onChange={onInputChange("card-holder-name-last-name")}
                placeholder={t("purchase.last_name")}
                helperText={cardHolderNameLastNameErrorMessage}
                variant="standard"
                onFocus={onFocusChange("card-holder-name-last-name")}
                onBlur={onBlurChange("card-holder-name-last-name")}
              ></TextField>
            </div>
          </div>
        </div>

        {getAioData?.payload?.country_list_with_iso_code?.length > 0 ? (
          <div className="country-form-field-container">
            <div className="label-container">
              <div className="label">{t("purchase.country")}</div>
            </div>

            <div className="form-container">
              <TextField
                sx={{ "& .MuiSelect-select": { marginTop: "6px" } }}
                className="dropdown-field custom-text-field"
                select
                value={country}
                error={countryError}
                onChange={onInputChange("country")}
                helperText={countryErrorMessage}
                variant="standard"
                onFocus={onFocusChange("country")}
                SelectProps={{
                  renderValue: (value) => value?.country_name,
                }}
              >
                {getAioData?.payload?.country_list_with_iso_code?.map(
                  (country, index) => (
                    <MenuItem key={index} value={country}>
                      <div className="select-output">
                        <div className="flag-icon-container">
                          <Avatar
                            className="flag-icon"
                            src={country?.country_flag}
                            alt={country?.country_name}
                          />
                        </div>
                        <div className="country-name">
                          {country?.country_name}
                        </div>
                        <div className="country-name">
                          {country?.phone_country_code}
                        </div>
                      </div>
                    </MenuItem>
                  )
                )}
              </TextField>
            </div>
          </div>
        ) : (
          <div className="country-error-label">
            Error retrieving Country, please refresh
          </div>
        )}
      </form>
    </Box>
  );
};

export default CardDetails;
