// General
import "./go-private-checklist-dialog.scss";
import { useState, useEffect } from "react";
// Services
import { sessionService } from "../../../../services/session.service";
// Static Data
import videoCallConst from "../../../../const/videoCallConst";
import routeConst from "../../../../const/routeConst";
// react-gtm-module
import TagManager from "react-gtm-module";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateGoPrivateChecklistDialog,
  updateGoPrivatePermissionDialog,
  updateVideoCallCameraTesterDialog,
  updatePreJoinDialog,
} from "../../../../redux/store/dialogStore";
// Material UI
import { Dialog, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation, Trans } from "react-i18next";
// react-device-detect
import { deviceDetect, isIOS, isAndroid } from "react-device-detect";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const GoPrivateChecklistDialog = () => {
  // Constant variables
  const checklist = videoCallConst.goPrivate.checklist;

  // General variables
  const [agree, setAgree] = useState(false);

  // Redux variables
  const goPrivateChecklistDialog = useSelector(
    (state) => state.dialog.goPrivateChecklistDialog
  );
  const isCaller = useSelector((state) => state.privateCall.isCaller);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!goPrivateChecklistDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-GoPrivateChecklist-Dialog",
      },
    });
  }, [goPrivateChecklistDialog]);

  // Event Handlers | Button
  const onOpenPermissionGuide = () => {
    if (!agree) return;

    if (isIOS || isAndroid) {
      // Check if "has-shown-video-call-permission-dialog" in local storage is true or false
      if (!sessionService.getHasShownVideoCallPermissionDialog()) {
        dispatch(updateGoPrivatePermissionDialog(true));
      } else if (isCaller) {
        dispatch(updatePreJoinDialog(true));
      } else {
        // onNavigate(routeConst.videoCall.privateStandby.path);
        dispatch(updateVideoCallCameraTesterDialog(true));
      }
    } else if (isCaller) {
      dispatch(updatePreJoinDialog(true));
    } else {
      // onNavigate(routeConst.videoCall.privateStandby.path);
      dispatch(updateVideoCallCameraTesterDialog(true));
    }

    onCloseDialog();
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateGoPrivateChecklistDialog(false));
  };

  // Event Handlers | MUI Checkbox
  const onCheckboxChange = (event) => {
    setAgree(event.target.checked);
  };

  return (
    <Dialog
      className="custom-radius35-dialog"
      open={goPrivateChecklistDialog}
      onClose={onCloseDialog}
    >
      <div id="go-private-checklist-dialog">
        <div className="dialog-header">
          <div className="close-button-container">
            <CloseIcon className="close-button" onClick={onCloseDialog} />
          </div>
        </div>

        <div className="padding-container">
          <div className="alert-icon-container">
            {getIcon("playOutlineIcon", "alert-icon")}
          </div>

          <div className="title">{t("1on1.ready_to_go_private")}</div>

          <div className="description">
            {t("1on1.ready_to_go_private_desc")}
          </div>

          <div className="description-list">
            {checklist.map((item, index) => (
              <div className="checklist-container" key={index}>
                <div className="bulletine"></div>
                <div className="checklist-title">{t(item?.value)}</div>
              </div>
            ))}
          </div>

          <div className="agree-checkbox">
            <FormGroup>
              <FormControlLabel
                className="custom-checkbox-font"
                control={
                  <Checkbox
                    className="custom-checkbox"
                    onChange={onCheckboxChange}
                    size="small"
                  />
                }
                label={t("1on1.i_agree_to_all")}
              />
            </FormGroup>
          </div>

          <div
            className={`proceed-button ${agree ? "" : "disable-button"}`}
            onClick={onOpenPermissionGuide}
          >
            {t("common.next")}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default GoPrivateChecklistDialog;
