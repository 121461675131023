// General
import "./pusher-settings.scss";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateGlobalPusherReset,
  updateLiveViewPusherReset,
  updateMessagingPusherReset,
  updateVideoCallPusherReset,
} from "../../../../redux/store/pusherStore";
// Material UI
import { Divider } from "@mui/material";

const PusherSettings = () => {
  // Redux variables
  const showHiddenSettings = useSelector(
    (state) => state.app.showHiddenSettings
  );
  const globalPusherState = useSelector(
    (state) => state.debug.globalPusherState
  );
  const liveViewPusherState = useSelector(
    (state) => state.debug.liveViewPusherState
  );
  const videoCallPusherState = useSelector(
    (state) => state.debug.videoCallPusherState
  );
  const messagingPusherState = useSelector(
    (state) => state.debug.messagingPusherState
  );
  const dispatch = useDispatch();

  // Event Handlers | Button
  const onResetGlobalPusher = () => {
    dispatch(updateGlobalPusherReset({}));
  };
  const onResetLiveViewPusher = () => {
    dispatch(updateLiveViewPusherReset({}));
  };
  const onResetVideoCallPusher = () => {
    dispatch(updateVideoCallPusherReset({}));
  };
  const onResetMessagingPusher = () => {
    dispatch(updateMessagingPusherReset({}));
  };

  // Utility Functions
  const pusherStateColor = (state) => {
    switch (state) {
      case "initialized":
        break;
      case "connecting":
        break;
      case "connected":
        return "state-connected";
        break;
      case "disconnected":
        return "state-disconnected";
        break;
      case "unavailable":
        break;
      case "failed":
        break;
      case "disconnected":
        break;
      default:
        break;
    }
  };

  if (showHiddenSettings) {
    return (
      <div id="settings-hidden-settings-subcomponent">
        <div className="padding-container">
          <div className="hidden-label">Pusher Settings</div>

          <div className="buttons-list-container">
            <div className="button-container" onClick={onResetGlobalPusher}>
              <div className="button-label">Global Pusher</div>
              <div className={`suffix ${pusherStateColor(globalPusherState)}`}>
                {globalPusherState || "Uninitiated"}
              </div>
            </div>

            <div className="button-container" onClick={onResetLiveViewPusher}>
              <div className="button-label">Live View Pusher</div>
              <div
                className={`suffix ${pusherStateColor(liveViewPusherState)}`}
              >
                {liveViewPusherState || "Uninitiated"}
              </div>
            </div>

            <div className="button-container" onClick={onResetVideoCallPusher}>
              <div className="button-label">Video Call Pusher</div>
              <div
                className={`suffix ${pusherStateColor(videoCallPusherState)}`}
              >
                {videoCallPusherState || "Uninitiated"}
              </div>
            </div>

            <div className="button-container" onClick={onResetMessagingPusher}>
              <div className="button-label">Messaging Pusher</div>
              <div
                className={`suffix ${pusherStateColor(messagingPusherState)}`}
              >
                {messagingPusherState || "Uninitiated"}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default PusherSettings;
