// General
import "./upgrade-checkout.scss";
import { useEffect } from "react";
// Static Data
import routeConst from "../../../const/routeConst";
// Redux
import { useSelector } from "react-redux";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../utility/manager/icon-manager/icon-manager";

const UpgradeCheckout = () => {
  // Redux variables
  const selectedUpgradePackage = useSelector(
    (state) => state.upgrade.selectedUpgradePackage
  );
  const discretionDescription = useSelector(
    (state) => state.upgrade.discretionDescription
  );
  const creditCardNoteDescription = useSelector(
    (state) => state.upgrade.creditCardNoteDescription
  );

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!isDetailValid()) {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-54",
        },
      });

      onNavigate(routeConst.upgrade.path);
    }
  }, []);

  // Event Handlers | Button
  const onContinueToPaymentMethod = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-54-To-54.1-Button",
      },
    });

    onNavigate(routeConst.upgrade.payment.path);
  };

  // Utility Functions
  const isDetailValid = () => {
    if (
      selectedUpgradePackage ||
      discretionDescription ||
      creditCardNoteDescription
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div id="upgrade-checkout-page">
      <div className="max-width-container">
        <div className="padding-container">
          <div className="featured-container"></div>

          <div className="discretion-container">
            <div className="credit-card-icon-container">
              {getIcon("creditCardOutlineIcon", "credit-card-icon")}
            </div>

            <div className="discretion-description">
              <div className="discretion-description-head">
                {discretionDescription?.head}
              </div>
              {discretionDescription?.body?.map((description, index) => (
                <div className="discretion-description-body" key={index}>
                  {description}
                </div>
              ))}
            </div>
          </div>

          <div className="credit-card-note-container">
            <div className="world-icon-container">
              {getIcon("worldOutlineIcon", "world-icon")}
            </div>

            <div className="credit-card-note-description">
              {creditCardNoteDescription}
            </div>
          </div>

          <div className="upgrade-package-container">
            <div className="premium-icon-container">
              {getIcon("upgradePremiumIcon", "premium-icon")}
            </div>

            <div className="upgrade-package-description">
              <div className="months-label">
                {selectedUpgradePackage?.period_length}-
                {selectedUpgradePackage?.period_text} {t("common.premium")}
              </div>
              <div className="price-label">
                {selectedUpgradePackage?.display_string}/
                {selectedUpgradePackage?.period_type}
              </div>
            </div>
          </div>

          {false && (
            <div className="subscription-renew-date">
              {t("upgrade.subscription_renew_on", {
                date: selectedUpgradePackage?.renews_at,
              })}
            </div>
          )}

          <div className="total-and-tax-container">
            <div className="total-container">
              <div className="total-label">{t("upgrade.total")}</div>
              <div className="total-amount">
                {selectedUpgradePackage?.symbol}
                {selectedUpgradePackage?.display_billed_today}
              </div>
            </div>

            {selectedUpgradePackage?.tax_percentage && (
              <div className="tax-container">
                <div className="tax-label">
                  <Trans
                    i18nKey={"upgrade.includes_n_tax"}
                    values={{
                      taxValue: selectedUpgradePackage?.tax_percentage,
                      taxType: selectedUpgradePackage?.tax_label,
                    }}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="continue-button" onClick={onContinueToPaymentMethod}>
            {t("common.continue")}
          </div>

          {selectedUpgradePackage && (
            <div className="billed-info">
              <Trans
                i18nKey={"upgrade.billed_desc_2"}
                values={{
                  amount: `${selectedUpgradePackage?.symbol}${selectedUpgradePackage?.renews_amount}`,
                  duration: selectedUpgradePackage?.period_length * 30,
                  renewDate: selectedUpgradePackage?.renews_at,
                }}
                components={{
                  span: <span />,
                  doublesize: <span className="doublesize" />,
                }}
              />
            </div>
          )}

          <div className="cancellation-info">{t("upgrade.billed_cancel")}</div>
        </div>
      </div>
    </div>
  );
};

export default UpgradeCheckout;
