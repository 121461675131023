// General
import { useEffect } from "react";
// Services
import { sessionService } from "../../../../services/session.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateShowDebugInfo,
  updateShowLog,
} from "../../../../redux/store/debugStore";
import {
  updateShowHiddenSettings,
  updateReleaseChannel,
  updateIsDarkMode,
  updateDarkTheme,
  updateMobileNavbarTheme,
  updateIconTheme,
} from "../../../../redux/store/appStore";

const UtilityMounted = () => {
  // Redux variables
  const isDarkMode = useSelector((state) => state.app.isDarkMode);
  const darkTheme = useSelector((state) => state.app.darkTheme);
  const dispatch = useDispatch();

  // Lifecycle | Mounted
  useEffect(() => {
    dispatch(updateShowHiddenSettings(sessionService.getShowHiddenSettings()));
    dispatch(updateShowDebugInfo(sessionService.getDebugMode()));
    dispatch(updateReleaseChannel(sessionService.getReleaseChannel()));
    dispatch(updateShowLog(sessionService.getShowLog()));
    dispatch(updateIsDarkMode(sessionService.getDarkMode()));
    dispatch(updateDarkTheme(sessionService.getDarkTheme()));
    dispatch(updateMobileNavbarTheme(sessionService.getMobileNavbarTheme()));
    dispatch(updateIconTheme(sessionService.getIconTheme()));
  }, []);

  // Lifecycle | Check for update | isDarkMode, darkTheme
  useEffect(() => {
    if (isDarkMode) {
      switch (darkTheme) {
        case "oled":
          document.documentElement.style.setProperty(
            "--sugarbook-main-color",
            "#710d0d"
          );
          document.documentElement.style.setProperty(
            "--app-background-color",
            "black"
          );
          document.documentElement.style.setProperty(
            "--desktop-background-color",
            "#f6f6f7"
          );
          document.documentElement.style.setProperty(
            "--octopus-navbar-color",
            "#f7f7ff"
          );
          // Text Color
          document.documentElement.style.setProperty(
            "--sugarbook-main-text-color",
            "white"
          );
          document.documentElement.style.setProperty(
            "--sugarbook-default-text-color",
            "white"
          );
          document.documentElement.style.setProperty(
            "--sugarbook-grey-text-color",
            "white"
          );
          // MUI Text Field Color
          document.documentElement.style.setProperty(
            "--mui-text-field-underline-border-color",
            "1px solid white"
          );
          // MUI Icon Color
          document.documentElement.style.setProperty(
            "--mui-icon-default-color",
            "white"
          );
          // MUI Button Color
          document.documentElement.style.setProperty(
            "--mui-disabled-button-background-color",
            "rgba(255, 255, 255, 0.12)"
          );
          document.documentElement.style.setProperty(
            "--mui-disabled-button-color",
            "rgba(255, 255, 255, 0.3)"
          );
          // Join Page
          document.documentElement.style.setProperty(
            "--join-page-border-color",
            "white"
          );
          document.documentElement.style.setProperty(
            "--join-page-text-color",
            "white"
          );
          break;
        case "grey":
          break;
        case "mint":
          break;
        default:
          break;
      }
    } else {
      document.documentElement.style.setProperty(
        "--sugarbook-main-color",
        "#710d0d"
      );
      document.documentElement.style.setProperty(
        "--app-background-color",
        "white"
      );
      document.documentElement.style.setProperty(
        "--desktop-background-color",
        "#f6f6f7"
      );
      document.documentElement.style.setProperty(
        "--octopus-navbar-color",
        "#f7f7ff"
      );
      // Text Color
      document.documentElement.style.setProperty(
        "--sugarbook-main-text-color",
        "#710d0d"
      );
      document.documentElement.style.setProperty(
        "--sugarbook-default-text-color",
        "black"
      );
      document.documentElement.style.setProperty(
        "--sugarbook-grey-text-color",
        "#7c7c7c"
      );
      // MUI Text Field Color
      document.documentElement.style.setProperty(
        "--mui-text-field-underline-border-color",
        "1px solid rgba(0, 0, 0, 0.42)"
      );
      // MUI Icon Color
      document.documentElement.style.setProperty(
        "--mui-icon-default-color",
        "rgba(0, 0, 0, 0.54)"
      );
      // MUI Button Color
      document.documentElement.style.setProperty(
        "--mui-disabled-button-background-color",
        "rgba(0, 0, 0, 0.12)"
      );
      document.documentElement.style.setProperty(
        "--mui-disabled-button-color",
        "rgba(0, 0, 0, 0.26)"
      );
      // Join Page
      document.documentElement.style.setProperty(
        "--join-page-border-color",
        "#710d0d"
      );
      document.documentElement.style.setProperty(
        "--join-page-text-color",
        "#710d0d"
      );
    }
  }, [isDarkMode, darkTheme]);

  return <div id="utility-mounted-shadow-component"></div>;
};

export default UtilityMounted;
