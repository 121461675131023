const useCompactNumbers = () => {
  // Main Function
  const getCompactNumbers = (number) => {
    const suffixes = ["", "K", "M", "B"];
    let suffixIndex = 0;

    while (number >= 1000 && suffixIndex < suffixes.length - 1) {
      number /= 1000;
      suffixIndex++;
    }

    return number.toFixed(1).replace(/\.0$/, "") + suffixes[suffixIndex];
  };

  return getCompactNumbers;
};

export default useCompactNumbers;
