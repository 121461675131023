const livestreamingConst = {
  loadingCopywriting: [
    "Sit tight, she’s cumming",
    "Sliding into cleavage..",
    "Loading orgasms..",
    "Almost cumming..",
    "Waiting for her...",
    "Eat, pray, smash..loading",
    "Sexiness is loading...",
    "Loading love vibes...",
    "Loading sexiness..",
    "Something sexy awaits…",
    "Cooking orgasms..",
    "Something orgasmic is brewing..",
    "Finding love, almost there..",
    "Loading heat..",
    "Loading seduction..",
    "Loading playmates..",
    "Steamy moments incoming..",
    "Flirt, laugh, date.. Loading",
    "Playful stream loading..",
    "Ready, set, flirt.. loading",
  ],
  chatLimit: 80,
  config: {
    chatElement: {
      font: "700 0.8em Lato",
      borderColor: "none",
      desktopFontColor: "rgba(0, 0, 0, 0.87)",
      mobileFontColor: "#FFE18C",
      backgroundColor: "none",
    },
    giftElement: {
      font: "700 0.8em Lato",
      desktopFontColor: "rgba(0, 0, 0, 0.87)",
      mobileFontColor: "#FFE18C",
      backgroundColor: "rgba(13, 113, 65, 0.59)",
      livestreaming: {
        padding: "5px",
        margin: "0 10px",
        borderColor: "none",
        borderRadius: "10px",
      },
      privateCall: {
        padding: "5px 0 5px 15px",
        margin: "0",
        borderColor: "none",
        borderRadius: "0 25px 25px 0",
      },
    },
    joiningElement: {
      borderColor: "none",
      font: "700 0.8em/1.4 Lato",
      desktopFontColor: "rgb(204 24 31/1)",
      mobileFontColor: "#FFE18C",
      backgroundColor: "none",
    },
    followingElement: {
      borderColor: "none",
      font: "700 0.8em/1.4 Lato",
      desktopFontColor: "rgb(80 179 67/1)",
      mobileFontColor: "#FFE18C",
      backgroundColor: "none",
    },
    emojiElement: {
      borderColor: "none",
      font: "700 0.8em Lato",
      desktopFontColor: "rgba(0, 0, 0, 0.87)",
      mobileFontColor: "#FFE18C",
      backgroundColor: "none",
    },
  },
  dualPkStatus: {
    ongoing: "ongoing",
    break: "break",
    endVictoryLap: "end_victory_lap",
    end: "end",
  },
};

export default livestreamingConst;
