// General
import "./check-in-streak-dialog.scss";
import { useEffect } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateCheckInStreakDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";

const CheckInStreakDialog = () => {
  // Redux variables
  const checkInStreakDialog = useSelector(
    (state) => state.dialog.checkInStreakDialog
  );
  const checkInInfo = useSelector((state) => state.user.checkInInfo);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!checkInStreakDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-CheckInStreak-Dialog",
      },
    });
  }, [checkInStreakDialog]);

  // Event Handlers | Button
  const onCloseDialog = () => {
    dispatch(updateCheckInStreakDialog(false));
  };

  return (
    <Dialog
      className="custom-radius35-dialog"
      open={checkInStreakDialog}
      onClose={onCloseDialog}
    >
      <div id="check-in-streak-dialog">
        <div className="max-width-container">
          <div className="padding-container">
            <div className="header">
              <span className="days-label">
                {checkInInfo?.currently_achieved || 0}
              </span>{" "}
              {t("common.day")}
              <br />
              {t("levelling.checkin_streak")}
            </div>

            <div className="subheader">
              {t("levelling.congrats_youve_earned")}
            </div>

            <div className="exp-label">+{checkInInfo?.exp || 0}</div>

            <div className="exp-icon-container">EXP</div>

            <div className="description">
              {t("levelling.come_back_tomorrow")}
            </div>

            <div className="close-button" onClick={onCloseDialog}>
              {t("common.got_it")}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default CheckInStreakDialog;
