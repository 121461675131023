const deactivateAccountConst = {
  reason: [
    { value: "settings.reason_1", label: "I found the ideal arrangement" },
    {
      value: "settings.reason_2",
      label: "I am taking a break from the platform",
    },
    { value: "settings.reason_3", label: "I didn't find what I wanted" },
    { value: "settings.reason_4", label: "I didn't feel safe" },
    { value: "settings.reason_5", label: "I'd rather not say" },
    { value: "settings.reason_6", label: "I'm no longer dating online" },
    { value: "settings.reason_7", label: "I'm too busy to use this service" },
    { value: "settings.reason_8", label: "I'm not using this service" },
    { value: "settings.reason_9", label: "This is not working for me" },
    { value: "settings.reason_10", label: "It costs too much" },
    {
      value: "settings.reason_11",
      label: "I'm not satisfied with the service",
    },
  ],
};

export default deactivateAccountConst;
