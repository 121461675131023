// General
import "./following-element.scss";
// Static Data
import livestreamingConst from "../../../../../const/livestreamingConst";
// i18next
import { useTranslation } from "react-i18next";
// Components
import CustomAvatar from "../../custom-avatar/custom-avatar";

const FollowingElement = (props) => {
  const {
    livestreamingAchievementBadge,
    profilePhoto,
    profileDecorations,
    username,
    levellingBadge,
    livestreamerUsername,

    // Style
    borderColor = livestreamingConst.config.followingElement.borderColor,
    font = livestreamingConst.config.followingElement.font,
    textColor = livestreamingConst.config.followingElement.mobileFontColor,
    backgroundColor = "none",
  } = props;

  // i18next variables
  const { t } = useTranslation();

  // TODO: Need to add levelling badge, need to update translation to accomodate levelling badge
  return (
    <div
      id="chat-section-following-element-subcomponent"
      style={{
        background: backgroundColor,
        border: borderColor !== "none" ? `1px solid ${borderColor}` : "none",
      }}
    >
      <div className="profile-picture-container">
        <CustomAvatar
          className="profile-picture"
          src={profilePhoto}
          profileBorder={profileDecorations?.profile_border_url}
        />
      </div>

      <div
        className="chat-info-container"
        style={{ font: font, color: textColor }}
      >
        {livestreamingAchievementBadge?.length > 0 && (
          <div className="livestreaming-achievement-badge-container">
            {livestreamingAchievementBadge?.map((badge, index) => (
              <img
                className="livestreaming-achievement-badge"
                src={badge?.url}
                alt={badge?.type}
                key={index}
              />
            ))}
          </div>
        )}

        {t("pusher.is_now_following", {
          user: username,
          livestreamer: livestreamerUsername,
        })}
      </div>
    </div>
  );
};

export default FollowingElement;
