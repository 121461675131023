// General
import "./empty-list.scss";
// Static Data
import routeConst from "../../../const/routeConst";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../utility/manager/icon-manager/icon-manager";

const EmptyList = () => {
  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  return (
    <div
      id="notification-empty-list-subcomponent"
      className="empty-list-container"
    >
      <div className="message-icon-container">
        {getIcon("emptyNotificationIcon", "message-icon")}
      </div>
      <div className="no-notification-label">
        {t("notification.no_new_notifications_title")}
      </div>

      <div className="no-notification-text">
        {t("notification.no_new_notifications_desc")}
      </div>
    </div>
  );
};

export default EmptyList;
