// General
import "./signup-stepper.scss";

const SignupStepper = (props) => {
  const { current, total = 5 } = props;

  // Render Functions
  const renderStepperItem = () => {
    const stepper = [];

    for (let i = 0; i < total; i++) {
      stepper.push(
        <div
          className={`stepper-item ${
            i < current ? "selected-stepper" : "unselected-stepper"
          }`}
          key={i}
        ></div>
      );
    }
    return stepper;
  };

  return <div id="signup-stepper-shared-component">{renderStepperItem()}</div>;
};

export default SignupStepper;
