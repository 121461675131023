// General
import "./achievements-info.scss";
import { useState, useEffect } from "react";
// Services
import { useLazyGetUserAioQuery } from "../../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateProfileBadgeInfo } from "../../../../../redux/store/profileStore";
import { updateProfileAchievementBadgeDialog } from "../../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Components
import Banner from "../../banner/banner";

const ProfileAchievements = (props) => {
  const { mode = "dialog" } = props;

  // API variables
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();

  // General variables
  const [badgeInfo, setBadgeInfo] = useState(null);

  // Redux variables
  const profileAchievementBadge = useSelector(
    (state) => state.userCard.profileAchievementBadge
  );
  const isSugarbaby = useSelector((state) => state.userCard.isSugarbaby);
  const isSelf = useSelector((state) => state.userCard.isSelf);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    getUserAio(null, true);
  }, []);

  // Event Handlers | Button
  const onBadgeInfo = (badge) => {
    switch (mode) {
      case "page":
        TagManager.dataLayer({
          dataLayer: {
            event: "PWA-30-30.6-Button",
          },
        });

        setBadgeInfo(badge);
        break;
      case "dialog":
        TagManager.dataLayer({
          dataLayer: {
            event: "PWA-30-30.7-Button",
          },
        });

        dispatch(updateProfileBadgeInfo(badge));
        dispatch(updateProfileAchievementBadgeDialog(true));
        break;
      default:
        break;
    }
  };

  // Utility Functions
  const returnEmptyTitle = () => {
    if (isSelf) {
      if (isSugarbaby) {
        return (
          <Trans
            i18nKey={"profile.livestream_achievement_empty_self_sb_title"}
            components={{ br: <br /> }}
          />
        );
      } else {
        return (
          <Trans
            i18nKey={"profile.livestream_achievement_empty_self_sd_title"}
            components={{ br: <br /> }}
          />
        );
      }
    } else {
      if (isSugarbaby) {
        return (
          <Trans
            i18nKey={"profile.livestream_achievement_empty_sb_title"}
            components={{ br: <br /> }}
          />
        );
      } else {
        return (
          <Trans
            i18nKey={"profile.livestream_achievement_empty_sd_title"}
            components={{ br: <br /> }}
          />
        );
      }
    }
  };
  const returnEmptyDescription = () => {
    if (isSelf) {
      if (isSugarbaby) {
        return (
          <Trans
            i18nKey={"profile.livestream_achievement_empty_self_sb_description"}
            components={{ br: <br /> }}
          />
        );
      } else {
        return (
          <Trans
            i18nKey={"profile.livestream_achievement_empty_self_sd_description"}
            components={{ br: <br /> }}
          />
        );
      }
    } else {
      if (isSugarbaby) {
        return (
          <Trans
            i18nKey={"profile.livestream_achievement_empty_sb_description"}
            components={{ br: <br /> }}
          />
        );
      } else {
        return (
          <Trans
            i18nKey={"profile.livestream_achievement_empty_sd_description"}
            components={{ br: <br /> }}
          />
        );
      }
    }
  };

  if (profileAchievementBadge) {
    return (
      <div id="profile-view-achievements-subcomponent">
        <div className="padding-container">
          <div className="achievements-label">{t("profile.achievements")}</div>

          <div className="achievements-container">
            {profileAchievementBadge?.map((badge, index) => (
              <div
                className="achievement-image-container"
                onClick={() => onBadgeInfo(badge)}
                key={index}
              >
                <img
                  className={`achievement-image ${
                    mode === "page" ? "larger-image" : ""
                  }`}
                  src={badge?.url}
                  alt={badge?.ranking}
                />
              </div>
            ))}
          </div>

          {mode === "page" && badgeInfo && (
            <div className="achievement-info-container">
              <div className="achievement-image-container">
                <img
                  className="achievement-image"
                  src={badgeInfo?.url}
                  alt={"badge"}
                />
              </div>

              <div
                className="badge-description"
                dangerouslySetInnerHTML={{ __html: badgeInfo?.description }}
              ></div>
            </div>
          )}

          <div className="ongoing-events-label">
            {t("profile.ongoing_events")}
          </div>

          <div className="user-profile-banners-container">
            {getUserAioData?.data?.modules?.live_streaming_enabled && (
              <Banner type={"live"} view={"carousel"} />
            )}

            <Banner
              type={"leaderboard-private-call-events"}
              view={"carousel"}
            />
          </div>
        </div>
      </div>
    );
  } else {
    if (mode === "page") {
      return (
        <div className="livestream-achievement-empty">
          <div className="achievement-empty-title">{returnEmptyTitle()}</div>

          <div className="achievement-empty-description">
            {returnEmptyDescription()}
          </div>

          <div className="ongoing-events-label">
            {t("profile.ongoing_events")}
          </div>

          <div className="user-profile-banners-container">
            {getUserAioData?.data?.modules?.live_streaming_enabled && (
              <Banner type={"live"} view={"carousel"} />
            )}

            <Banner
              type={"leaderboard-private-call-events"}
              view={"carousel"}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="livestream-achievement-empty">
          <div className="achievement-empty-title">{returnEmptyTitle()}</div>

          <div className="achievement-empty-description">
            {returnEmptyDescription()}
          </div>

          <div className="ongoing-events-label">
            {t("profile.ongoing_events")}
          </div>

          <div className="user-profile-banners-container">
            {getUserAioData?.data?.modules?.live_streaming_enabled && (
              <Banner type={"live"} view={"carousel"} />
            )}

            <Banner
              type={"leaderboard-private-call-events"}
              view={"carousel"}
            />
          </div>
        </div>
      );
    }
  }
};

export default ProfileAchievements;
