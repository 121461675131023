// Components
import GlobalPusher from "./global-pusher";
import MessagingPusher from "./messaging-pusher";
import LiveViewPusher from "./live-view-pusher";
import VideoCallPusher from "./video-call-pusher";

const PusherMounted = () => {
  return (
    <div id="pusher-mounted-shadow-component">
      <GlobalPusher />
      <MessagingPusher />
      <LiveViewPusher />
      <VideoCallPusher />
    </div>
  );
};

export default PusherMounted;
