// General
import "./onboarding-video-call-room-dialog.scss";
import { useState, useEffect, forwardRef } from "react";
// Services
import { sessionService } from "../../../../services/session.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateOnboardingVideoCallRoomDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import AssetManager from "../../../utility/manager/asset-manager/asset-manager";

const OnboardingVideoCallRoomDialog = () => {
  // General variables
  const [page, setPage] = useState(1);

  // Render variables
  let titleView;
  let descriptionView;
  let backgroundImageView;
  let imageView;
  let backgroundOverlayStyle;

  // Redux variables
  const onboardingVideoCallRoomDialog = useSelector(
    (state) => state.dialog.onboardingVideoCallRoomDialog
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t, i18n } = useTranslation();

  // Custom Hooks Functions
  const getAsset = AssetManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!onboardingVideoCallRoomDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-D10",
      },
    });
  }, [onboardingVideoCallRoomDialog]);

  // Event Handlers | Button
  const onPrevious = () => {
    setPage((prevPage) => {
      if (prevPage === 1) {
        return 1;
      }
      return prevPage - 1;
    });
  };
  const onNext = () => {
    setPage((prevPage) => {
      if (prevPage === 2) {
        onCloseDialog();
      }
      return prevPage + 1;
    });
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = (event, reason) => {
    if (reason === "backdropClick") return;

    // Save to local storage
    sessionService.setHasShownVideoCallRoomDialog(true);
    dispatch(updateOnboardingVideoCallRoomDialog(false));
  };

  // Render Functions
  switch (page) {
    case 1:
      titleView = (
        <div className="live-title">
          <Trans
            i18nKey={"tutorial.quick_gift_title"}
            components={{ b: <span className="x5-text" /> }}
          />
        </div>
      );
      descriptionView = (
        <div className="live-description">{t("tutorial.quick_gift_desc")}</div>
      );
      backgroundImageView = (
        <img
          className="live-background"
          src={getAsset("onboardingVideoCallQuickGiftBackground")}
        />
      );
      backgroundOverlayStyle = {
        background: `url(${getAsset(
          "onboardingVideoCallQuickGiftBackground"
        )}) center center / cover no-repeat`,
      };
      imageView = (
        <img
          className="overlay"
          src={getAsset("onboardingVideoCallQuickGiftBackground")}
          alt="tutorial-overlay"
        />
      );
      break;
    case 2:
      titleView = (
        <div className="live-title">
          <Trans i18nKey="tutorial.my_gift_title" />
        </div>
      );
      descriptionView = (
        <div className="live-description">
          <Trans i18nKey={"tutorial.my_gift_desc"} />
        </div>
      );
      backgroundImageView = (
        <img
          className="live-background"
          src={getAsset("onboardingVideoCallMoreGiftBackground")}
        />
      );
      backgroundOverlayStyle = {
        background: `url(${getAsset(
          "onboardingVideoCallMoreGiftBackground"
        )}) center center / cover no-repeat`,
      };
      imageView = (
        <img
          className="overlay"
          src={getAsset("onboardingVideoCallMoreGiftBackground")}
          alt="tutorial-overlay"
        />
      );
      break;
    default:
      break;
  }

  return (
    <Dialog
      className={
        isMobile ? "custom-dialog-transparent" : "custom-radius35-dialog"
      }
      fullScreen={isMobile ? true : false}
      open={onboardingVideoCallRoomDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      <div
        id="onboarding-video-call-room-dialog"
        className={isMobile ? "" : "max-width-container"}
      >
        <div
          className={`background-overlay ${
            isMobile ? "full-background" : "fix-background"
          }`}
          style={backgroundOverlayStyle}
        ></div>
        {imageView}

        <div className="interactable-container">
          <div className="button-container" onClick={onNext}>
            <CloseIcon className="close-icon" />
          </div>

          <div className="description-container">
            <div className="padding-container">
              <div className="subheader">{titleView}</div>

              <div className="text">{descriptionView}</div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default OnboardingVideoCallRoomDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
