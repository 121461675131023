// General
import "./leveling.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetUserAioQuery,
  useLazyGetProfileQuery,
} from "../../../services/data.service";
// Static Data
import routeConst from "../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateProfileLevellingTab } from "../../../redux/store/levellingStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// embla-carousel-react
import useEmblaCarousel from "embla-carousel-react";
// Material UI
import { Tabs, Tab, Divider } from "@mui/material";
import PropTypes from "prop-types";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import Spinner from "../../shared/elements/spinner/spinner";
import CheckIn from "./check-in/check-in";
import DailyTasks from "./daily-tasks/daily-tasks";
import MoreInfo from "./more-info/more-info";

const LevelingCenter = () => {
  // API variables
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // Redux variables
  const profileLevellingTab = useSelector(
    (state) => state.levelling.profileLevellingTab
  );
  const dispatch = useDispatch();

  // Embla variables
  const [emblaRef, emblaApi] = useEmblaCarousel({
    duration: 15,
  });

  // i18next variables
  const { t, i18n } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-26",
      },
    });

    getUserAio(null, true);
    getProfile(null, true);
  }, []);

  // Lifecycle | Mounted | emblaApi
  useEffect(() => {
    if (!emblaApi) return;
    emblaApi?.scrollTo(profileLevellingTab);

    emblaApi?.on("select", () => {
      dispatch(updateProfileLevellingTab(emblaApi?.selectedScrollSnap()));
    });
  }, [emblaApi]);

  // Lifecycle | Check for update | profileLevellingTab
  useEffect(() => {
    emblaApi?.scrollTo(profileLevellingTab);
  }, [profileLevellingTab]);

  // Event Handlers | MUI Tabs
  const onTabChange = (event, newValue) => {
    TagManager.dataLayer({
      dataLayer: {
        event: `PWA-26-26.1-${
          newValue === 0
            ? "CheckIn"
            : newValue === 1
            ? "DailyTasks"
            : "MoreInfo"
        }`,
      },
    });

    dispatch(updateProfileLevellingTab(newValue));
  };

  if (
    getProfileFetching ||
    getProfileLoading ||
    getUserAioFetching ||
    getUserAioLoading
  ) {
    return <Spinner />;
  } else if (getProfileSuccess && getUserAioSuccess) {
    if (getProfileData?.status === 0) {
      return (
        <div id="leveling-center-page">
          <div className="max-width-container">
            <div className="tab-container">
              <Tabs
                className="custom-tabs"
                value={profileLevellingTab}
                onChange={onTabChange}
                TabIndicatorProps={{ style: { backgroundColor: "#710d0d" } }}
                variant="fullWidth"
              >
                <Tab label={t("profile.check_in")} {...a11yProps(0)} />
                <Tab label={t("profile.daily_tasks")} {...a11yProps(1)} />
                <Tab label={t("profile.more_info")} {...a11yProps(2)} />
              </Tabs>

              {profileLevellingTab !== 2 && (
                <div className="top-static-container padding-container">
                  <div className="exp-icon-container">EXP</div>

                  <div className="level-exp-container">
                    <div className="level-container">
                      <div className="level-label">{t("profile.level")}:</div>
                      {getProfileData?.data?.levelling_profile?.badge && (
                        <div className="badge-container">
                          <img
                            className="badge"
                            src={getProfileData?.data?.levelling_profile?.badge}
                            alt="badge"
                          />
                        </div>
                      )}
                    </div>

                    <Divider
                      className="divider-vertical"
                      orientation="vertical"
                      flexItem
                    />

                    <div className="exp-container">
                      <div className="exp-label">
                        {t("profile.exp_earned")}:
                      </div>
                      <div className="exp">
                        {getProfileData?.data?.levelling_profile?.accumulated_exp.toLocaleString() ||
                          0}
                      </div>
                    </div>
                  </div>

                  <div className="progress-bar-container">
                    <div className="diamond-count-labels-container">
                      <div className="start-diamond-label">
                        {t("profile.level")}{" "}
                        {getProfileData?.data?.levelling_profile?.level || 0}
                      </div>

                      <div className="end-diamond-label">
                        {t("profile.level")}{" "}
                        {getProfileData?.data?.levelling_profile?.next_level ||
                          0}
                      </div>
                    </div>

                    <div className="progress-bar">
                      <div
                        className="progress-bar-load"
                        style={{
                          width: `calc(${
                            (getProfileData?.data?.levelling_profile
                              ?.accumulated_exp /
                              getProfileData?.data?.levelling_profile
                                ?.next_level_exp) *
                            100
                          }%)`,
                        }}
                      ></div>
                      <div className="progress-bar-value">
                        {getProfileData?.data?.levelling_profile?.accumulated_exp?.toLocaleString() ||
                          0}{" "}
                        /{" "}
                        {getProfileData?.data?.levelling_profile?.next_level_exp?.toLocaleString() ||
                          0}{" "}
                        EXP
                      </div>
                    </div>

                    <div className="your-progress-label">
                      {t("profile.exp_progress")}
                    </div>
                  </div>
                </div>
              )}

              <Divider />

              <div ref={emblaRef} className="embla">
                <div className="embla__container">
                  <div className="embla__slide">
                    {profileLevellingTab === 0 && <CheckIn />}
                  </div>

                  <div className="embla__slide">
                    {profileLevellingTab === 1 && <DailyTasks />}
                  </div>

                  <div className="embla__slide">
                    {profileLevellingTab === 2 && <MoreInfo />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  } else if (getProfileError || getUserAioError) {
    if (getProfileErrorData?.status === 401) {
      onNavigate(routeConst.logout.path);
    }
  }
};

export default LevelingCenter;

// Material UI | Tab Functions
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <div className="tab-children">{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
