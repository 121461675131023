// General
import "./update-email-verification.scss";
import { useState, useEffect } from "react";
// Services
import {
  useResendAccountEmailVerificationMutation,
  useUpdateAccountEmailIdentityVerificationMutation,
  useLazyGetProfileCheckQuery,
} from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateEmail as updateLocalEmail } from "../../../../redux/store/userStore";
import {
  updateSuccessToast,
  updateErrorToast,
} from "../../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Box, TextField, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import Spinner from "../../../shared/elements/spinner/spinner";

const UpdateEmailVerification = () => {
  // General variables
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationCodeErrorMessage, setVerificationCodeErrorMessage] =
    useState("");
  const [verificationCodeFocus, setVerificationCodeFocus] = useState(false);
  const [verificationCodeValid, setVerificationCodeValid] = useState(false);
  const [verificationCodeError, setVerificationCodeError] = useState(false);

  // API variables
  const [
    resendAccountEmailVerification,
    {
      data: resendAccountEmailVerificationData,
      error: resendAccountEmailVerificationErrorData,
      isLoading: resendAccountEmailVerificationLoading,
      isSuccess: resendAccountEmailVerificationSuccess,
      isError: resendAccountEmailVerificationError,
    },
  ] = useResendAccountEmailVerificationMutation();
  const [
    updateAccountEmailIdentityVerification,
    {
      data: updateAccountEmailIdentityVerificationData,
      error: updateAccountEmailIdentityVerificationErrorData,
      isLoading: updateAccountEmailIdentityVerificationLoading,
      isSuccess: updateAccountEmailIdentityVerificationSuccess,
      isError: updateAccountEmailIdentityVerificationError,
    },
  ] = useUpdateAccountEmailIdentityVerificationMutation();
  const [
    getProfileCheck,
    {
      data: getProfileCheckData,
      error: getProfileCheckErrorData,
      isFetching: getProfileCheckFetching,
      isLoading: getProfileCheckLoading,
      isSuccess: getProfileCheckSuccess,
      isError: getProfileCheckError,
    },
  ] = useLazyGetProfileCheckQuery();

  // Redux variables
  const changeEmail = useSelector((state) => state.user.changeEmail);
  const dispatch = useDispatch();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Check for update | Check for email validation
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-38",
      },
    });

    verificationCodeValidation();
  }, [verificationCode, verificationCodeFocus, verificationCodeErrorMessage]);

  // Lifecycle | Check for update | resendAccountEmailVerification API Response
  useEffect(() => {
    if (resendAccountEmailVerificationLoading) {
    } else if (resendAccountEmailVerificationSuccess) {
      switch (resendAccountEmailVerificationData?.status) {
        case 0:
        case 1:
          getProfileCheck();

          const successToastObj = {
            message: resendAccountEmailVerificationData?.message,
            autoClose: 3000,
          };
          dispatch(updateSuccessToast(successToastObj));
          break;
        default:
          const errorToastObj = {
            message: resendAccountEmailVerificationData?.message,
            autoClose: 3000,
          };
          dispatch(updateErrorToast(errorToastObj));
          break;
      }
    } else if (resendAccountEmailVerificationError) {
    }
  }, [
    resendAccountEmailVerificationLoading,
    resendAccountEmailVerificationSuccess,
    resendAccountEmailVerificationError,
  ]);

  // Lifecycle | Check for update | updateAccountEmailIdentityVerification API Response
  useEffect(() => {
    if (updateAccountEmailIdentityVerificationLoading) {
    } else if (updateAccountEmailIdentityVerificationSuccess) {
      switch (updateAccountEmailIdentityVerificationData?.status) {
        case 0:
        case 1:
          getProfileCheck();

          // Update Local Email for displaying purposes
          dispatch(updateLocalEmail(changeEmail?.newEmail));

          const successToastObj = {
            message: updateAccountEmailIdentityVerificationData?.message,
            autoClose: 3000,
          };
          dispatch(updateSuccessToast(successToastObj));

          onNavigate(routeConst.profile.overview.path);
          break;
        case -1:
        default:
          const errorToastObj = {
            message: updateAccountEmailIdentityVerificationData?.message,
            autoClose: 3000,
          };
          dispatch(updateErrorToast(errorToastObj));
          break;
      }
    } else if (updateAccountEmailIdentityVerificationError) {
    }
  }, [
    updateAccountEmailIdentityVerificationLoading,
    updateAccountEmailIdentityVerificationSuccess,
    updateAccountEmailIdentityVerificationError,
  ]);

  // Event Handlers | Button
  const onSubmitVerificationCode = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-38-38.1-Button",
      },
    });

    updateAccountEmailIdentityVerification(verificationCode);
  };

  const onResendVerificationCode = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-38-38.2-Button",
      },
    });

    resendAccountEmailVerification();
  };

  // Event Handlers | MUI Text Fields
  const onVerificationCodeChange = (e) => {
    const value = e.target.value;

    setVerificationCode(value);
  };
  const onVerificationCodeFocus = (e) => {
    setVerificationCodeFocus(true);
  };

  // Utility Functions
  const isFormValid = () => {
    let valid = false;
    if (verificationCodeValid) {
      valid = true;
    }

    return valid;
  };
  const verificationCodeValidation = () => {
    let valid = true;
    let error = false;

    if (!verificationCodeFocus) {
      valid = false;
      error = false;
    } else if (verificationCodeFocus && verificationCode === "") {
      setVerificationCodeErrorMessage("Verification Code cannot be empty");
      valid = false;
      error = true;
    } else if (verificationCode.length !== 6) {
      setVerificationCodeErrorMessage("Verification Code must be 6 digits");
      valid = false;
      error = true;
    } else {
      setVerificationCodeErrorMessage("");
      valid = true;
      error = false;
    }

    setVerificationCodeValid(valid);
    setVerificationCodeError(error);
  };

  return (
    <Box
      id="update-email-verification-page"
      component="form"
      noValidate
      autoComplete="off"
    >
      <div className="max-width-container">
        <div className="verification-label">Enter Verification Code</div>
        <div className="verification-text">
          Your verification code has been sent to {changeEmail?.oldEmail}
        </div>

        <div className="verification-form-field-container">
          <TextField
            className="verification-form-field custom-text-field"
            required
            value={verificationCode}
            error={verificationCodeError}
            onChange={onVerificationCodeChange}
            label="Verification Code"
            type="number"
            placeholder="Verification Code"
            helperText={verificationCodeErrorMessage}
            variant="standard"
            onFocus={onVerificationCodeFocus}
          />
        </div>

        <Button
          className={`submit-verification-button ${
            isFormValid() ? "" : "disabled-button"
          }`}
          variant="text"
          onClick={onSubmitVerificationCode}
          disabled={!isFormValid()}
        >
          {updateAccountEmailIdentityVerificationLoading ? (
            <Spinner size={24} isPadding={false} />
          ) : (
            "Submit"
          )}
        </Button>

        <Button
          className={"resend-verification-button"}
          variant="text"
          onClick={onResendVerificationCode}
        >
          {resendAccountEmailVerificationLoading ? (
            <Spinner size={20} isPadding={false} />
          ) : (
            "Resend Verification code"
          )}
        </Button>
      </div>
    </Box>
  );
};

export default UpdateEmailVerification;
