// General
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // General variables
};

export const navbarSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    // Utility Functions
    resetNavbarStore: () => {
      return { ...initialState };
    },
  },
});

export const {
  // Utility Functions
  resetNavbarStore,
} = navbarSlice.actions;

export default navbarSlice.reducer;
