// General
import "./play-overlay.scss";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateHasInteract,
  updatePlayVideoPassthrough,
} from "../../../../redux/store/livestreamingStore";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

// NOTES: Not Using
const PlayOverlay = () => {
  // Redux variables
  const hasInteract = useSelector((state) => state.livestreaming.hasInteract);
  const dispatch = useDispatch();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Event Handlers | Button
  const onPlayVideo = () => {
    dispatch(updateHasInteract(true));
    dispatch(updatePlayVideoPassthrough({}));
  };

  if (!hasInteract) {
    return (
      <div id="livestreaming-play-overlay-subcomponent" onClick={onPlayVideo}>
        {getIcon("playIcon", "play-icon")}
      </div>
    );
  }
};

export default PlayOverlay;
