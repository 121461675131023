// General
import "./smoking-filter-dialog.scss";
import { useEffect, forwardRef } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateSmokingFilterDialog } from "../../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide } from "@mui/material";
// Components
import SmokingFilter from "../../../elements/filters/smoking-filter/smoking-filter";

const SmokingFilterDialog = () => {
  // Redux variables
  const smokingFilterDialog = useSelector(
    (state) => state.dialog.smokingFilterDialog
  );
  const dispatch = useDispatch();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!smokingFilterDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-SmokingFilter-Dialog",
      },
    });
  }, [smokingFilterDialog]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateSmokingFilterDialog(false));
  };

  return (
    <Dialog
      className="custom-bottom-sheet-dialog"
      fullScreen
      open={smokingFilterDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      <SmokingFilter />
    </Dialog>
  );
};

export default SmokingFilterDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
