// General
import "./coin-shop.scss";
import { useEffect } from "react";
// react-gtm-module
import TagManager from "react-gtm-module";
// Redux
import { useDispatch } from "react-redux";
import { updateLimitedTimeCoinPurchaseDialog } from "../../redux/store/dialogStore";
// Custom Hooks
import AssetManager from "../utility/manager/asset-manager/asset-manager";
// Components
import LimitedTimeCoinPurchaseDialog from "../shared/dialog-content/limited-time-coin-purchase-dialog/limited-time-coin-purchase-dialog";

const CoinShop = () => {
  // Redux variables
  const dispatch = useDispatch();

  // Custom Hooks Functions
  const getAsset = AssetManager();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-14",
      },
    });

    dispatch(updateLimitedTimeCoinPurchaseDialog(true));
  }, []);

  return (
    <div id="coin-shop-page">
      <div className="coin-shop-background-container">
        <img
          className="coin-shop-background"
          src={getAsset("coinShopBackground")}
        />
      </div>

      <LimitedTimeCoinPurchaseDialog />
    </div>
  );
};

export default CoinShop;
