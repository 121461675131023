// General
import "./video-splash-screen-dialog.scss";
import { useEffect, useRef } from "react";
// Service
import {
  useCreateCustomVariableMutation,
  useLazyGetCustomVariableQuery,
  useUpdateCustomVariableMutation,
  useDeleteCustomVariableMutation,
} from "../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateVideoSplashScreenDialog } from "../../../../redux/store/dialogStore";
// Material UI
import { Dialog, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import AssetManager from "../../../utility/manager/asset-manager/asset-manager";

const VideoSplashScreenDialog = () => {
  // API variables
  const [
    createCustomVariable,
    {
      data: createCustomVariableData,
      error: createCustomVariableErrorData,
      isLoading: createCustomVariableLoading,
      isSuccess: createCustomVariableSuccess,
      isError: createCustomVariableError,
    },
  ] = useCreateCustomVariableMutation();
  const [
    getCustomVariable,
    {
      data: getCustomVariableData,
      error: getCustomVariableErrorData,
      isFetching: getCustomVariableFetching,
      isLoading: getCustomVariableLoading,
      isSuccess: getCustomVariableSuccess,
      isError: getCustomVariableError,
    },
  ] = useLazyGetCustomVariableQuery();
  const [
    updateCustomVariable,
    {
      data: updateCustomVariableData,
      error: updateCustomVariableErrorData,
      isLoading: updateCustomVariableLoading,
      isSuccess: updateCustomVariableSuccess,
      isError: updateCustomVariableError,
    },
  ] = useUpdateCustomVariableMutation();
  const [
    deleteCustomVariable,
    {
      data: deleteCustomVariableData,
      error: deleteCustomVariableErrorData,
      isLoading: deleteCustomVariableLoading,
      isSuccess: deleteCustomVariableSuccess,
      isError: deleteCustomVariableError,
    },
  ] = useDeleteCustomVariableMutation();

  // Constant variables
  const taiwanSplashScreenId = "tw_splash_screen_v1";
  const taiwanSplashScreenMaxCount = 5;

  // General variables
  const videoRef = useRef(null);

  // Redux variables
  const videoSplashScreenDialog = useSelector(
    (state) => state.dialog.videoSplashScreenDialog
  );
  const isLoggedIn = useSelector((state) => state.public.isLoggedIn);
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { i18n } = useTranslation();

  // Custom Hooks Functions
  const getAsset = AssetManager();

  // Lifecycle
  useEffect(() => {
    if (!isLoggedIn) return;

    // For INTERNAL use only, use this to clear the existing custom variable
    // deleteCustomVariable(taiwanSplashScreenId);

    if (i18n.language.toLowerCase().includes("zh-tw") && isLoggedIn) {
      getCustomVariable(taiwanSplashScreenId);
    }
  }, [isLoggedIn]);

  // Lifecycle | Check for update | Create Custom Variable API Response
  useEffect(() => {
    if (createCustomVariableLoading) {
    } else if (createCustomVariableSuccess) {
      getCustomVariable(taiwanSplashScreenId);
    } else if (createCustomVariableError) {
    }
  }, [
    createCustomVariableLoading,
    createCustomVariableSuccess,
    createCustomVariableError,
  ]);

  // Lifecycle | Check for update | Get Custom Variable API Response
  useEffect(() => {
    if (getCustomVariableFetching || getCustomVariableLoading) {
    } else if (getCustomVariableSuccess) {
      switch (getCustomVariableData?.data?.status) {
        case 0:
          break;
        default:
          break;
      }
    } else if (getCustomVariableError) {
      switch (getCustomVariableErrorData?.data?.status) {
        case -1:
          const body = {
            id: taiwanSplashScreenId,
            data: 1,
            type: "int",
          };
          createCustomVariable(body);
          break;
        default:
          break;
      }
    }
  }, [
    getCustomVariableFetching,
    getCustomVariableLoading,
    getCustomVariableSuccess,
    getCustomVariableError,
  ]);

  // Lifecycle | Check for update | Update Custom Variable API Response
  useEffect(() => {
    if (updateCustomVariableLoading) {
    } else if (updateCustomVariableSuccess) {
    } else if (updateCustomVariableError) {
    }
  }, [
    updateCustomVariableLoading,
    updateCustomVariableSuccess,
    updateCustomVariableError,
  ]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = (event, reason) => {
    if (
      reason === "backdropClick" ||
      reason === "clickaway" ||
      reason === "escapeKeyDown"
    )
      return;

    dispatch(updateVideoSplashScreenDialog(false));
  };

  // Utility Functions
  const onVideoLoaded = () => {
    dispatch(updateVideoSplashScreenDialog(true));

    const bodyObj = {
      id: taiwanSplashScreenId,
      data: getCustomVariableData?.data?.payload + 1,
      type: "int",
    };
    updateCustomVariable(bodyObj);
  };
  const onVideoEnded = () => {
    onCloseDialog();
  };

  if (
    i18n.language.toLowerCase().includes("zh-tw") &&
    isLoggedIn &&
    getCustomVariableData?.data?.payload < taiwanSplashScreenMaxCount
  ) {
    return (
      <Dialog
        className={
          isMobile ? "custom-dialog-always-top" : "custom-radius35-dialog"
        }
        fullScreen={isMobile ? true : false}
        open={videoSplashScreenDialog}
        keepMounted={true}
        onClose={onCloseDialog}
      >
        <div id="video-splash-screen-dialog">
          <div className="dialog-header">
            <div className="close-button-container">
              <CloseIcon className="close-button" onClick={onCloseDialog} />
            </div>
          </div>

          <video
            className={`splash-screen-video-player ${
              isMobile ? "" : "splash-screen-max-width"
            }`}
            ref={videoRef}
            src={getAsset("taiwanSplashScreenVideo")}
            playsInline
            autoPlay={true}
            muted={true}
            controls={false}
            onLoadedData={onVideoLoaded}
            onEnded={onVideoEnded}
          ></video>
        </div>
      </Dialog>
    );
  }
};

export default VideoSplashScreenDialog;
