// General
import "./video-call-recent-tab.scss";
import { useState, useEffect, useRef } from "react";
// Services
import {
  useLazyGetVideoCallHistoryQuery,
  useLazyGetProfileQuery,
} from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  // Main Page Functions
  updateVideoCallRecentList,
  updateVideoCallRecentPagination,
  resetVideoCallRecentList,
} from "../../../../redux/store/privateCallStore";
// React Infinite Scroller
import InfiniteScroll from "react-infinite-scroller";
// react-simple-pull-to-refresh
import PullToRefresh from "react-simple-pull-to-refresh";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import RecentList from "./recent-list/recent-list";
import EmptyList from "./empty-list/empty-list";
import Spinner from "../../../shared/elements/spinner/spinner";

const VideoCallRecentTab = () => {
  // API variables
  const [
    getVideoCallHistory,
    {
      data: getVideoCallHistoryData,
      error: getVideoCallHistoryErrorData,
      isFetching: getVideoCallHistoryFetching,
      isLoading: getVideoCallHistoryLoading,
      isSuccess: getVideoCallHistorySuccess,
      isError: getVideoCallHistoryError,
    },
  ] = useLazyGetVideoCallHistoryQuery();
  const [getProfile, { data: getProfileData }] = useLazyGetProfileQuery();

  // General variables
  const [firstLoad, setFirstLoad] = useState(true);
  const scrollContainerRef = useRef(null);
  const isMounted = useRef(false);

  // Render variables
  let historyListView = <div></div>;

  // Redux variables
  const videoCallRecentList = useSelector(
    (state) => state.privateCall.videoCallRecentList
  );
  const videoCallRecentPagination = useSelector(
    (state) => state.privateCall.videoCallRecentPagination
  );
  const videoCallScrollToTopPassthrough = useSelector(
    (state) => state.privateCall.videoCallScrollToTopPassthrough
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);

    if (videoCallRecentList?.length <= 0) {
      getVideoCallHistory(null, true);
    }
  }, []);

  // Lifecycle | Check for update | Messages Video Call History API Response
  useEffect(() => {
    if (getVideoCallHistoryFetching || getVideoCallHistoryLoading) {
    } else if (getVideoCallHistorySuccess) {
      if (getVideoCallHistoryData?.status === 0) {
        setFirstLoad(false);

        if (getVideoCallHistoryData?.data?.results?.length > 0) {
          dispatch(
            updateVideoCallRecentPagination(
              getVideoCallHistoryData?.data?.pagination?.next_cursor
            )
          );
          dispatch(
            updateVideoCallRecentList(getVideoCallHistoryData?.data?.results)
          );
        }
      }
    } else if (getVideoCallHistoryError) {
      if (getVideoCallHistoryErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getVideoCallHistoryFetching,
    getVideoCallHistoryLoading,
    getVideoCallHistorySuccess,
    getVideoCallHistoryError,
  ]);

  // Lifecycle | Check for update | videoCallScrollToTopPassthrough
  useEffect(() => {
    if (isMounted.current) {
      if (videoCallScrollToTopPassthrough) {
        scrollContainerRef.current.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    } else {
      isMounted.current = true;
    }
  }, [videoCallScrollToTopPassthrough]);

  // Infinite Scroller Functions
  const infiniteScrollFunc = () => {
    if (!videoCallRecentPagination) return;

    setTimeout(() => {
      getVideoCallHistory(
        `?pagination_token=${videoCallRecentPagination}`,
        true
      );
    }, 1000);
  };

  // Pull To Refresh Functions
  const pullToRefreshFunc = () => {
    dispatch(resetVideoCallRecentList());
    dispatch(updateVideoCallRecentPagination(null));
    setFirstLoad(true);

    return new Promise((resolve) => {
      resolve(getVideoCallHistory());
    });
  };

  // Render Functions | Video Call History Data
  if (videoCallRecentList?.length > 0) {
    historyListView = (
      <div className="infinite-scroller-container">
        {videoCallRecentList?.map((call, index) => (
          <RecentList
            id={
              getProfileData?.data?.id === call?.callee_user_id
                ? call?.caller?.id
                : call?.callee?.id
            }
            idInt={
              getProfileData?.data?.id === call?.callee_user_id
                ? call?.caller?.id_int
                : call?.callee?.id_int
            }
            profilePhoto={
              getProfileData?.data?.id === call?.callee_user_id
                ? call?.caller?.profile_photo?.original_photo
                : call?.callee?.profile_photo?.original_photo
            }
            profileDecorations={
              getProfileData?.data?.id === call?.callee_user_id
                ? call?.caller?.profile_decorations
                : call?.callee?.profile_decorations
            }
            username={
              getProfileData?.data?.id === call?.callee_user_id
                ? call?.caller?.username
                : call?.callee?.username
            }
            membershipType={
              getProfileData?.data?.id === call?.callee_user_id
                ? call?.caller?.membership_type
                : call?.callee?.membership_type
            }
            levellingBadge={
              getProfileData?.data?.id === call?.callee_user_id
                ? call?.caller?.levelling_profile?.badge
                : call?.callee?.levelling_profile?.badge
            }
            livestreamingAchievementsBadge={
              getProfileData?.data?.id === call?.callee_user_id
                ? call?.caller?.live_streaming_achievements?.chat_badges_set
                : call?.callee?.live_streaming_achievements?.chat_badges_set
            }
            verifiedProfile={
              getProfileData?.data?.id === call?.callee_user_id
                ? call?.caller?.is_verified_profile
                : call?.callee?.is_verified_profile
            }
            status={call?.status}
            callStartAt={call?.call_started_at}
            callEndAt={call?.call_ended_at}
            callUpdatedAt={call?.updated_at}
            duration={call?.call_duration}
            conversationId={call?.conversation_id}
            key={index}
          />
        ))}
      </div>
    );
  } else if (
    firstLoad &&
    (getVideoCallHistoryFetching || getVideoCallHistoryLoading)
  ) {
    historyListView = <Spinner />;
  } else {
    historyListView = <EmptyList />;
  }

  return (
    <div id="video-call-video-call-tab-subcomponent">
      <div className="max-width-container">
        <PullToRefresh
          className="pull-to-refresh-container"
          onRefresh={pullToRefreshFunc}
          refreshingContent={t("common.release")}
          pullingContent={t("common.pull_to_refresh")}
          isPullable={
            !getVideoCallHistoryFetching && !getVideoCallHistoryLoading
          }
        >
          <div className="header-container">
            <div className="header">{t("inbox.recent")}</div>
          </div>

          <div
            className="video-call-history-list-container"
            ref={scrollContainerRef}
          >
            <InfiniteScroll
              pageStart={0}
              loadMore={infiniteScrollFunc}
              hasMore={videoCallRecentPagination ? true : false}
              loader={<Spinner key={0} />}
              useWindow={false}
            >
              {historyListView}
            </InfiniteScroll>
          </div>
        </PullToRefresh>
      </div>
    </div>
  );
};

export default VideoCallRecentTab;
