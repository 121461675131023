// General
import "./edit-occupation.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetJourneyQuestionsQuery,
  useUpdateEditUserProfileMutation,
} from "../../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updatePosition,
  updateIndustry,
  updateUpdateProfileFullscreenTab,
  updateIsUnsavedChanges,
} from "../../../../../redux/store/editProfileStore";
import {
  updateProfileCompletionPercentage,
  updateProfileRequirementPopup,
} from "../../../../../redux/store/profileStore";
import { updateUpdateProfileFullscreenDialog } from "../../../../../redux/store/dialogStore";
// Material UI
import { MenuItem, TextField } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../../elements/spinner/spinner";

const EditOccupation = (props) => {
  const { addPaddingTop = false } = props;

  // API variables
  const [
    getJourneyQuestions,
    {
      data: getJourneyQuestionsData,
      error: getJourneyQuestionsErrorData,
      isFetching: getJourneyQuestionsFetching,
      isLoading: getJourneyQuestionsLoading,
      isSuccess: getJourneyQuestionsSuccess,
      isError: getJourneyQuestionsError,
    },
  ] = useLazyGetJourneyQuestionsQuery();
  const [
    updateProfile,
    {
      data: updateProfileData,
      error: updateProfileErrorData,
      isLoading: updateProfileLoading,
      isSuccess: updateProfileSuccess,
      isError: updateProfileError,
    },
  ] = useUpdateEditUserProfileMutation();

  // General variables
  const [localPositionId, setLocalPositionId] = useState(null);
  const [localPosition, setLocalPosition] = useState(null);
  const [localIndustryId, setLocalIndustryId] = useState(null);
  const [localIndustry, setLocalIndustry] = useState(null);

  // Redux variables
  const position = useSelector((state) => state.editProfile.position);
  const industry = useSelector((state) => state.editProfile.industry);
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const profileCompletionState = useSelector(
    (state) => state.editProfile.profileCompletionState
  );
  const updateProfileFullscreenTab = useSelector(
    (state) => state.editProfile.updateProfileFullscreenTab
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    setLocalPosition(position?.id);
    setLocalIndustry(industry?.id);

    getJourneyQuestions(null, true);
  }, []);

  // Lifecycle | Check for update | getJourneyQuestions API Response
  useEffect(() => {
    if (getJourneyQuestionsSuccess) {
      const positionData = getPositionData();
      const positionObject = positionData?.find(
        (item) => item?.id === position?.id
      );
      setLocalPosition(positionObject);

      if (isDaddy) {
        const industryData = getIndustryData();
        const industryObject = industryData?.find(
          (item) => item?.id === industry?.id
        );
        setLocalIndustry(industryObject);
      }
    }
  }, [getJourneyQuestionsSuccess]);

  // Lifecycle | Check for update | updateProfile API Response
  useEffect(() => {
    if (updateProfileLoading) {
    } else if (updateProfileSuccess) {
      dispatch(
        updatePosition(updateProfileData?.data?.user?.occupation?.position)
      );
      dispatch(
        updateIndustry(updateProfileData?.data?.user?.occupation?.industry)
      );
      dispatch(
        updateProfileCompletionPercentage(
          updateProfileData?.data?.user?.profile_progress?.summary?.progress
        )
      );
      dispatch(
        updateProfileRequirementPopup(
          updateProfileData?.data?.user?.profile_progress?.summary?.requirement
        )
      );
      dispatch(updateIsUnsavedChanges(false));

      if (profileCompletionState) {
        const position = updateProfileData?.data?.user?.occupation?.position;
        const industry = updateProfileData?.data?.user?.occupation?.industry;
        const education = updateProfileData?.data?.user?.occupation?.education;
        const relationshipStatus =
          updateProfileData?.data?.user?.relationship?.relationship_status;
        const children = updateProfileData?.data?.user?.relationship?.children;
        const smoking = updateProfileData?.data?.user?.habit?.smoking;
        const drinking = updateProfileData?.data?.user?.habit?.drinking;
        const alcoholPreference = updateProfileData?.data?.user?.habit?.alcohol;

        if (isDaddy && (!position || !industry)) {
          dispatch(updateUpdateProfileFullscreenTab(0));
        } else if (!isDaddy && !position) {
          dispatch(updateUpdateProfileFullscreenTab(0));
        } else if (!education) {
          dispatch(updateUpdateProfileFullscreenTab(1));
        } else if (!relationshipStatus) {
          dispatch(updateUpdateProfileFullscreenTab(2));
        } else if (!children && children !== 0) {
          dispatch(updateUpdateProfileFullscreenTab(3));
        } else if (!smoking) {
          dispatch(updateUpdateProfileFullscreenTab(4));
        } else if (!drinking) {
          dispatch(updateUpdateProfileFullscreenTab(5));
        } else if (!alcoholPreference) {
          dispatch(updateUpdateProfileFullscreenTab(6));
        } else {
          dispatch(updateUpdateProfileFullscreenDialog(false));
        }
      } else {
        dispatch(
          updateIndustry(updateProfileData?.data?.user?.occupation?.industry)
        );
        dispatch(updateUpdateProfileFullscreenDialog(false));
      }
    } else if (updateProfileError) {
    }
  }, [updateProfileLoading, updateProfileSuccess, updateProfileError]);

  // Lifecycle | Check for update | localPosition & localIndustry
  useEffect(() => {
    if (isDaddy) {
      if (
        localPosition?.id !== position?.id ||
        localIndustry?.id !== industry?.id
      ) {
        dispatch(updateIsUnsavedChanges(true));
      } else {
        dispatch(updateIsUnsavedChanges(false));
      }
    } else {
      if (localPosition?.id !== position?.id) {
        dispatch(updateIsUnsavedChanges(true));
      } else {
        dispatch(updateIsUnsavedChanges(false));
      }
    }
  }, [localPosition, localIndustry]);

  // Event Handlers | Button
  const onSaveOccupation = () => {
    if (!isFormValid()) return;

    let obj = {
      profile: {},
    };

    if (isDaddy) {
      obj = {
        profile: {
          position: localPosition?.id,
          industry: localIndustry?.id,
        },
      };
    } else {
      obj = {
        profile: {
          position: localPosition?.id,
        },
      };
    }
    updateProfile(obj);
  };

  // Event Handlers | MUI Select
  const handleSelectChange = (type) => (event) => {
    switch (type) {
      case "position":
        setLocalPositionId(event.target.value);

        const positionData = getPositionData();
        const positionObject = positionData?.find(
          (item) => item?.id === event.target.value
        );
        setLocalPosition(positionObject);
        break;
      case "industry":
        setLocalIndustryId(event.target.value);

        const industryData = getIndustryData();
        const industryObject = industryData?.find(
          (item) => item?.id === event.target.value
        );
        setLocalIndustry(industryObject);
        break;
      default:
        break;
    }
  };

  // Utility Functions
  const isFormValid = () => {
    if (isDaddy) {
      return localPosition && localIndustry && !updateProfileLoading;
    } else {
      return localPosition && !updateProfileLoading;
    }
  };
  const getPositionData = () => {
    return getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "position"
    )?.selections;
  };
  const getIndustryData = () => {
    return getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "industry"
    )?.selections;
  };

  return (
    <div id="occupation-subcomponent">
      <div className="padding-container">
        <div
          className={`top-container ${
            addPaddingTop ? "add-edit-padding-top" : ""
          }`}
        >
          <div className="occupation-icon-container">
            {getIcon("occupationIcon", "education-icon")}
          </div>

          <div className="occupation-title">
            {isDaddy
              ? t("profile.whats_your_position_industry")
              : t("profile.whats_your_occupation")}
          </div>

          {getJourneyQuestionsFetching || getJourneyQuestionsLoading ? (
            <Spinner />
          ) : (
            <div className="occupation-body-container">
              <div className="position-body-container">
                <div className="position-form-field-container">
                  <div className="position-select-form-field-container">
                    <TextField
                      className="position-select-form-field"
                      select
                      value={localPositionId || ""}
                      placeholder={
                        isDaddy
                          ? t("profile.select_position")
                          : t("profile.select_occupation")
                      }
                      label={
                        isDaddy
                          ? t("profile.select_position")
                          : t("profile.select_occupation")
                      }
                      onChange={handleSelectChange("position")}
                      variant="standard"
                      SelectProps={{
                        displayEmpty: true,
                      }}
                    >
                      <MenuItem disabled value="">
                        {isDaddy
                          ? t("profile.select_position")
                          : t("profile.select_occupation")}
                      </MenuItem>
                      {getPositionData()?.map((position, index) => (
                        <MenuItem value={position?.id} key={index}>
                          {position?.display_locale || position?.locale}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>

                {localPosition?.id && (
                  <div className="selected-position">
                    {localPosition?.display_locale || localPosition?.locale}
                  </div>
                )}
              </div>

              {isDaddy && (
                <div className="industry-body-container">
                  <div className="industry-form-field-container">
                    <div className="industry-select-form-field-container">
                      <TextField
                        className="industry-select-form-field"
                        select
                        value={localIndustryId || ""}
                        // placeholder={t("profile.select_industry")}
                        // label={t("profile.select_industry")}
                        onChange={handleSelectChange("industry")}
                        variant="standard"
                        SelectProps={{
                          displayEmpty: true,
                        }}
                      >
                        <MenuItem disabled value="">
                          {t("profile.select_industry")}
                        </MenuItem>
                        {getIndustryData()?.map((industry, index) => (
                          <MenuItem value={industry?.id} key={index}>
                            {industry?.display_locale || industry?.locale}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </div>

                  {localIndustry?.id && (
                    <div className="selected-industry">
                      {localIndustry?.display_locale || localIndustry?.locale}
                    </div>
                  )}
                </div>
              )}
            </div>

            // <div className="occupation-body-container">
            //   <div className="position-label">Position</div>
            //   <div className="occupation-flex-body-container">
            //     {getPositionData()?.map((item, index) => (
            //       <div
            //         className={`occupation-item ${
            //           item?.id === localPosition?.id ? "selected" : ""
            //         }`}
            //         onClick={() => onSelectPosition(item)}
            //         key={index}
            //       >
            //         {item?.display_locale}
            //       </div>
            //     ))}
            //   </div>

            //   {isDaddy && <div className="industry-label">Industry</div>}
            //   {isDaddy && (
            //     <div className="occupation-flex-body-container">
            //       {getIndustryData()?.map((item, index) => (
            //         <div
            //           className={`occupation-item ${
            //             item?.id === localIndustry?.id ? "selected" : ""
            //           }`}
            //           onClick={() => onSelectIndustry(item)}
            //           key={index}
            //         >
            //           {item?.display_locale}
            //         </div>
            //       ))}
            //     </div>
            //   )}
            // </div>
          )}
        </div>
      </div>

      <div className="bottom-container">
        <div
          className={`save-button ${isFormValid() ? "" : "disabled-button"}`}
          onClick={onSaveOccupation}
        >
          {updateProfileLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("common.save")
          )}
        </div>
      </div>
    </div>
  );
};

export default EditOccupation;
