// General
import { useEffect } from "react";
// Services
import {
  useLazyGetCardDetailsQuery,
  useSubmitNewCardTokenMutation,
  usePurchaseProductMutation,
  usePostPaymentTagMutation,
} from "../../../services/data.service";
// Static Data
import paymentConst from "../../../const/paymentConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateCardDetail,
  updatePaymentState,
  updateSecurionPayTokenDetails,
} from "../../../redux/store/paymentStore";
import {
  updateAddCardDialog,
  updateAddPaymentMethodDialog,
  updateBuyCoinsDialog,
} from "../../../redux/store/dialogStore";
import { updateErrorToast } from "../../../redux/store/toastStore";
// Custom Hooks
import useCustomNavigate from "./useCustomNavigate-hook";

const useSecurionCreateCardFlow = () => {
  // API variables
  const [
    getCardDetails,
    {
      data: getCardDetailsData,
      error: getCardDetailsErrorData,
      isFetching: getCardDetailsFetching,
      isLoading: getCardDetailsLoading,
      isSuccess: getCardDetailsSuccess,
      isError: getCardDetailsError,
    },
  ] = useLazyGetCardDetailsQuery();
  const [
    submitNewCardToken,
    {
      data: submitNewCardTokenData,
      error: submitNewCardTokenErrorData,
      isLoading: submitNewCardTokenLoading,
      isSuccess: submitNewCardTokenSuccess,
      isError: submitNewCardTokenError,
    },
  ] = useSubmitNewCardTokenMutation();
  const [
    postPaymentTag,
    {
      data: postPaymentTagData,
      error: postPaymentTagErrorData,
      isLoading: postPaymentTagLoading,
      isSuccess: postPaymentTagSuccess,
      isError: postPaymentTagError,
    },
  ] = usePostPaymentTagMutation();

  // Redux variables
  const securionPayTokenDetails = useSelector(
    (state) => state.payment.securionPayTokenDetails
  );
  const dispatch = useDispatch();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Check for update | Get Card Details API Response
  useEffect(() => {
    if (getCardDetailsFetching || getCardDetailsLoading) {
    } else if (getCardDetailsSuccess) {
      // Close loading dialog
      dispatch(updateAddCardDialog(false));

      if (getCardDetailsData?.status === 0) {
        // Update Card List to Store
        dispatch(updateCardDetail(getCardDetailsData?.data));

        // Update Payment State to Store
        const paymentObj = {
          state: paymentConst.paymentState.addCardSaved,
          token: securionPayTokenDetails.id,
        };
        dispatch(updatePaymentState(paymentObj));

        // Route to Coin Page
        // onNavigate(-1);

        // Close Add Payment Method Dialog
        dispatch(updateAddPaymentMethodDialog(false));

        // Open Coin Package Dialog
        dispatch(updateBuyCoinsDialog(true));
      }
    } else if (getCardDetailsError) {
    }
  }, [
    getCardDetailsFetching,
    getCardDetailsLoading,
    getCardDetailsSuccess,
    getCardDetailsError,
  ]);

  // Lifecycle | Check for update | Submit New Card Token API Response
  useEffect(() => {
    if (submitNewCardTokenLoading) {
    } else if (submitNewCardTokenSuccess) {
      getCardDetails();
    } else if (submitNewCardTokenError) {
      // Close loading dialog
      dispatch(updateAddCardDialog(false));
    }
  }, [
    submitNewCardTokenLoading,
    submitNewCardTokenSuccess,
    submitNewCardTokenError,
  ]);

  // Main Function
  const securionCreateCardFlow = () => {
    addCard();
  };

  // Part 1 | Call SecurionPay that we are going to make a custom form payment
  const addCard = () => {
    // Open loading dialog
    dispatch(updateAddCardDialog(true));

    const form = document.getElementById("payment-form");

    // Update Payment State to Store
    const paymentObj = {
      attempt: true,
      state: paymentConst.paymentState.addingCard, // pendingToken
      resultMessage: null,
      token: null,
    };
    dispatch(updatePaymentState(paymentObj));

    Securionpay.setPublicKey(process.env["REACT_APP_SP_PUBLIC_KEY"]);
    Securionpay.createCardToken(form, createCardTokenCallback);

    // Prevent default form submission
    return false;
  };

  // Part 2 | SecurionPay informs if payment attempt is available
  const createCardTokenCallback = (token) => {
    dispatch(updateSecurionPayTokenDetails(token));

    if (token?.error) {
      // Close loading dialog
      dispatch(updateAddCardDialog(false));

      // Display error message
      // re-enable form submit button
      const toastObj = {
        message: token?.error?.message,
        autoClose: 3000,
      };
      dispatch(updateErrorToast(toastObj));

      // Update Payment State to Store
      const paymentObj = {
        attempt: false,
        state: paymentConst.paymentState.addCardFailed,
        resultMessage: token.error.message,
        token: null,
      };
      dispatch(updatePaymentState(paymentObj));
    } else {
      // PENDING Add Card into Backend
      submitFormToBackend(token);
    }
  };

  // Part 3 | Submit form to backend
  const submitFormToBackend = async (token) => {
    let params = {
      payment_token: token.id,
    };

    submitNewCardToken(params);
  };

  return securionCreateCardFlow;
};

export default useSecurionCreateCardFlow;
