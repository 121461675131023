// General
import "./customize-tips-dialog.scss";
import { useState, useEffect, forwardRef } from "react";
// Services
import {
  useLazyGetPrivateCallTipItemsQuery,
  useUpdatePrivateCallTipItemsMutation,
} from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateCustomizeTipsDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide, Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";

const CustomizeTipsDialog = () => {
  // API variables
  const [
    getPrivateCallTipMenuItems,
    {
      data: getPrivateCallTipItemsData,
      error: getPrivateCallTipItemsErrorData,
      isFetching: getPrivateCallTipItemsFetching,
      isLoading: getPrivateCallTipItemsLoading,
      isSuccess: getPrivateCallTipItemsSuccess,
      isError: getPrivateCallTipItemsError,
    },
  ] = useLazyGetPrivateCallTipItemsQuery();
  const [
    updatePrivateCallTipItems,
    {
      data: updatePrivateCallTipItemsData,
      error: updatePrivateCallTipItemsErrorData,
      isLoading: updatePrivateCallTipItemsLoading,
      isSuccess: updatePrivateCallTipItemsSuccess,
      isError: updatePrivateCallTipItemsError,
    },
  ] = useUpdatePrivateCallTipItemsMutation();

  // General variables
  const [tipItems, setTipItems] = useState([]);

  // Redux variables
  const customizeTipsDialog = useSelector(
    (state) => state.dialog.customizeTipsDialog
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted | customizeTipsDialog
  useEffect(() => {
    if (!customizeTipsDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-CustomizeTips-Dialog",
      },
    });

    getPrivateCallTipMenuItems();
  }, [customizeTipsDialog]);

  // Lifecycle | Check for update | getPrivateCallTipMenuItems API Response
  useEffect(() => {
    if (getPrivateCallTipItemsFetching || getPrivateCallTipItemsLoading) {
    } else if (getPrivateCallTipItemsSuccess) {
      setTipItems(getPrivateCallTipItemsData?.data?.results);
    } else if (getPrivateCallTipItemsError) {
      if (getPrivateCallTipItemsErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getPrivateCallTipItemsFetching,
    getPrivateCallTipItemsLoading,
    getPrivateCallTipItemsSuccess,
    getPrivateCallTipItemsError,
  ]);

  // Lifecycle | Check for update | updatePrivateCallTipItems API Response
  useEffect(() => {
    if (updatePrivateCallTipItemsLoading) {
    } else if (updatePrivateCallTipItemsSuccess) {
      onCloseDialog();
    } else if (updatePrivateCallTipItemsError) {
    }
  }, [
    updatePrivateCallTipItemsLoading,
    updatePrivateCallTipItemsSuccess,
    updatePrivateCallTipItemsError,
  ]);

  // Event Handlers | Button
  const onSaveTips = () => {
    if (getPrivateCallTipItemsData?.data?.results === tipItems) {
      onCloseDialog();
    } else {
      const obj = {
        related_module: "private_calls",
        data: tipItems,
      };
      updatePrivateCallTipItems(obj);
    }
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateCustomizeTipsDialog(false));
  };

  // Event Handlers | Input
  const onChangeDescription = (index) => (event) => {
    if (event.target.value.length > 20) return;

    setTipItems((prevTipItems) => {
      const newTipItems = [...prevTipItems];
      newTipItems[index] = {
        ...newTipItems[index],
        description: event.target.value,
      };
      return newTipItems;
    });
  };
  const onChangeValue = (index) => (event) => {
    if (event.target.value.length > 5) return;

    setTipItems((prevTipItems) => {
      const newTipItems = [...prevTipItems];
      newTipItems[index] = { ...newTipItems[index], value: event.target.value };
      return newTipItems;
    });
  };

  return (
    <Dialog
      className="custom-bottom-sheet-dialog"
      fullScreen
      open={customizeTipsDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      <div id="customize-tips-dialog">
        <div className="dialog-header">
          <div className="blank-container"></div>

          <div className="title">{t("profile.tutorial_tip_menu")}</div>

          <div className="close-button-container">
            <CloseIcon className="close-button" onClick={onCloseDialog} />
          </div>
        </div>

        <div className="padding-container">
          <div className="description">
            <Trans
              i18nKey={"1on1.customise_tips_desc"}
              values={{ min: 500, max: 5000 }}
              components={{ span: <span className="highlight-yellow" /> }}
            />
          </div>

          {tipItems?.map((tip, index) => {
            if (index < 5) {
              return (
                <div className="actions-container" key={index}>
                  <div className="action-label">
                    {t("1on1.action")} {index + 1}
                  </div>
                  <div className="action-container">
                    <div className="action-description">
                      <input
                        className="action-description-input"
                        type="text"
                        value={tip?.description || ""}
                        placeholder={t("1on1.add_action")}
                        onChange={onChangeDescription(index)}
                      />
                    </div>

                    <Divider orientation="vertical" />

                    <div className="action-value">
                      <input
                        className="action-value-input"
                        type="number"
                        value={tip?.value || ""}
                        placeholder={t("1on1.coins_value")}
                        onChange={onChangeValue(index)}
                      />
                    </div>
                  </div>
                </div>
              );
            }
          })}

          <div className="footer-container">
            <div className="done-button" onClick={onSaveTips}>
              {t("common.done")}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default CustomizeTipsDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
