import * as crypto from "crypto-js";
// Static Data
import utilityConst from "../const/utilityConst";

export const sessionService = {
  // General
  deleteAllSessionData() {
    this.deleteApiToken();
    this.deleteSpiToken();
    this.deleteNotificationToken();
    this.deleteUserProfile();
    this.deleteEmail();
    this.deleteDailyCheckIn();
    this.deleteShowLog();
    this.deleteHasShownUpgradeToPremiumDialog();
    this.deleteSearchPageFullscreenGeneralDialogLastShown();
    this.deleteLocationData();
    this.deleteHasShownVideoCallDialog();
    this.deleteHasShownLiveStreamingDialog();
    this.deleteHasShownVideoCallRoomDialog();
    this.deleteHasShownVideoCallSettingsTutorialDialog();
    this.deleteHasShownDiamondsInfoDialog();
    this.deleteRestartJourneyDialogLastShown();
  },

  // API Token
  setApiToken(data) {
    var ciphertext = crypto.AES.encrypt(
      JSON.stringify(data),
      process.env["REACT_APP_CRYPTO_SECRET_KEY"]
    ).toString();
    return localStorage.setItem("api-token", ciphertext);
  },
  getApiToken() {
    try {
      const data = localStorage.getItem("api-token");

      if (!data) {
        return null;
      }
      var bytes = crypto.AES.decrypt(
        data,
        process.env["REACT_APP_CRYPTO_SECRET_KEY"]
      );
      var decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
      return decryptedData;
    } catch (err) {
      return null;
    }
  },
  deleteApiToken() {
    return localStorage.removeItem("api-token");
  },

  // SPI Token
  setSpiToken(data) {
    var ciphertext = crypto.AES.encrypt(
      JSON.stringify(data),
      process.env["REACT_APP_CRYPTO_SECRET_KEY"]
    ).toString();
    return localStorage.setItem("spi-token", ciphertext);
  },
  getSpiToken() {
    try {
      const data = localStorage.getItem("spi-token");
      if (!data) {
        return null;
      }
      var bytes = crypto.AES.decrypt(
        data,
        process.env["REACT_APP_CRYPTO_SECRET_KEY"]
      );
      var decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
      return decryptedData;
    } catch (err) {
      return null;
    }
  },
  deleteSpiToken() {
    return localStorage.removeItem("spi-token");
  },

  // Notification Token
  setNotificationToken(data) {
    var ciphertext = crypto.AES.encrypt(
      JSON.stringify(data),
      process.env["REACT_APP_CRYPTO_SECRET_KEY"]
    ).toString();
    return localStorage.setItem("notification-token", ciphertext);
  },
  getNotificationToken() {
    try {
      const data = localStorage.getItem("notification-token");

      if (!data) {
        return null;
      }
      var bytes = crypto.AES.decrypt(
        data,
        process.env["REACT_APP_CRYPTO_SECRET_KEY"]
      );
      var decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
      return decryptedData;
    } catch (err) {
      return null;
    }
  },
  deleteNotificationToken() {
    return localStorage.removeItem("notification-token");
  },

  // User Profile
  setUserProfile(data) {
    var ciphertext = crypto.AES.encrypt(
      JSON.stringify(data),
      process.env["REACT_APP_CRYPTO_SECRET_KEY"]
    ).toString();
    return localStorage.setItem("user-profile", ciphertext);
  },
  getUserProfile() {
    try {
      const data = localStorage.getItem("user-profile");

      if (!data) {
        return null;
      }
      var bytes = crypto.AES.decrypt(
        data,
        process.env["REACT_APP_CRYPTO_SECRET_KEY"]
      );
      var decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
      return decryptedData;
    } catch (err) {
      return null;
    }
  },
  deleteUserProfile() {
    return localStorage.removeItem("user-profile");
  },

  // Email
  setEmail(data) {
    var ciphertext = crypto.AES.encrypt(
      JSON.stringify(data),
      process.env["REACT_APP_CRYPTO_SECRET_KEY"]
    ).toString();
    return localStorage.setItem("email", ciphertext);
  },
  getEmail() {
    try {
      const data = localStorage.getItem("email");

      if (!data) {
        return null;
      }
      var bytes = crypto.AES.decrypt(
        data,
        process.env["REACT_APP_CRYPTO_SECRET_KEY"]
      );
      var decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
      return decryptedData;
    } catch (err) {
      return null;
    }
  },
  deleteEmail() {
    return localStorage.removeItem("email");
  },

  // Daily Check In
  setDailyCheckIn(data) {
    var ciphertext = crypto.AES.encrypt(
      JSON.stringify(data),
      process.env["REACT_APP_CRYPTO_SECRET_KEY"]
    ).toString();
    return localStorage.setItem("daily-check-in", ciphertext);
  },
  getDailyCheckIn() {
    try {
      const data = localStorage.getItem("daily-check-in");

      if (!data) {
        return null;
      }
      var bytes = crypto.AES.decrypt(
        data,
        process.env["REACT_APP_CRYPTO_SECRET_KEY"]
      );
      var decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
      return decryptedData;
    } catch (err) {
      return null;
    }
  },
  deleteDailyCheckIn() {
    return localStorage.removeItem("daily-check-in");
  },

  // Hidden Settings
  setShowHiddenSettings(data) {
    var ciphertext = crypto.AES.encrypt(
      JSON.stringify(data),
      process.env["REACT_APP_CRYPTO_SECRET_KEY"]
    ).toString();
    return localStorage.setItem("show-hidden-settings", ciphertext);
  },
  getShowHiddenSettings() {
    try {
      const data = localStorage.getItem("show-hidden-settings");

      if (!data) {
        return null;
      }
      var bytes = crypto.AES.decrypt(
        data,
        process.env["REACT_APP_CRYPTO_SECRET_KEY"]
      );
      var decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
      return decryptedData;
    } catch (err) {
      return null;
    }
  },
  deleteShowHiddenSettings() {
    return localStorage.removeItem("show-hidden-settings");
  },

  // Release Channel
  setReleaseChannel(data) {
    var ciphertext = crypto.AES.encrypt(
      JSON.stringify(data),
      process.env["REACT_APP_CRYPTO_SECRET_KEY"]
    ).toString();
    return localStorage.setItem("release-channel", ciphertext);
  },
  getReleaseChannel() {
    try {
      const data = localStorage.getItem("release-channel");

      if (!data) {
        return "stable";
      }
      var bytes = crypto.AES.decrypt(
        data,
        process.env["REACT_APP_CRYPTO_SECRET_KEY"]
      );
      var decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
      return decryptedData;
    } catch (err) {
      return "stable";
    }
  },
  deleteReleaseChannel() {
    return localStorage.removeItem("release-channel");
  },

  // Manual Language
  setManualLanguage(data) {
    var ciphertext = crypto.AES.encrypt(
      JSON.stringify(data),
      process.env["REACT_APP_CRYPTO_SECRET_KEY"]
    ).toString();
    return localStorage.setItem("manual-language", ciphertext);
  },
  getManualLanguage() {
    try {
      const data = localStorage.getItem("manual-language");

      if (!data) {
        return false;
      }
      var bytes = crypto.AES.decrypt(
        data,
        process.env["REACT_APP_CRYPTO_SECRET_KEY"]
      );
      var decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
      return decryptedData;
    } catch (err) {
      return false;
    }
  },
  deleteManualLanguage() {
    return localStorage.removeItem("manual-language");
  },

  // Language
  setLanguage(data) {
    var ciphertext = crypto.AES.encrypt(
      JSON.stringify(data),
      process.env["REACT_APP_CRYPTO_SECRET_KEY"]
    ).toString();
    return localStorage.setItem("language", ciphertext);
  },
  getLanguage() {
    try {
      const data = localStorage.getItem("language");

      if (!data) {
        return utilityConst.language.en;
      }
      var bytes = crypto.AES.decrypt(
        data,
        process.env["REACT_APP_CRYPTO_SECRET_KEY"]
      );
      var decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
      return decryptedData;
    } catch (err) {
      return utilityConst.language.en;
    }
  },
  deleteLanguage() {
    return localStorage.removeItem("language");
  },

  // Show Log
  setShowLog(data) {
    var ciphertext = crypto.AES.encrypt(
      JSON.stringify(data),
      process.env["REACT_APP_CRYPTO_SECRET_KEY"]
    ).toString();
    return localStorage.setItem("show-log", ciphertext);
  },
  getShowLog() {
    try {
      const data = localStorage.getItem("show-log");

      if (!data) {
        return false;
      }
      var bytes = crypto.AES.decrypt(
        data,
        process.env["REACT_APP_CRYPTO_SECRET_KEY"]
      );
      var decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
      return decryptedData;
    } catch (err) {
      return false;
    }
  },
  deleteShowLog() {
    return localStorage.removeItem("show-log");
  },

  // Debug Mode
  setDebugMode(data) {
    var ciphertext = crypto.AES.encrypt(
      JSON.stringify(data),
      process.env["REACT_APP_CRYPTO_SECRET_KEY"]
    ).toString();
    return localStorage.setItem("debug-mode", ciphertext);
  },
  getDebugMode() {
    try {
      const data = localStorage.getItem("debug-mode");

      if (!data) {
        return false;
      }
      var bytes = crypto.AES.decrypt(
        data,
        process.env["REACT_APP_CRYPTO_SECRET_KEY"]
      );
      var decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
      return decryptedData;
    } catch (err) {
      return false;
    }
  },
  deleteDebugMode() {
    return localStorage.removeItem("debug-mode");
  },

  // Mobile Navbar Theme
  setMobileNavbarTheme(data) {
    var ciphertext = crypto.AES.encrypt(
      JSON.stringify(data),
      process.env["REACT_APP_CRYPTO_SECRET_KEY"]
    ).toString();
    return localStorage.setItem("mobile-navbar-theme", ciphertext);
  },
  getMobileNavbarTheme() {
    try {
      const data = localStorage.getItem("mobile-navbar-theme");

      if (!data) {
        return "default";
      }
      var bytes = crypto.AES.decrypt(
        data,
        process.env["REACT_APP_CRYPTO_SECRET_KEY"]
      );
      var decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
      return decryptedData;
    } catch (err) {
      return false;
    }
  },
  deleteMobileNavbarTheme() {
    return localStorage.removeItem("mobile-navbar-theme");
  },

  // Icon Theme
  setIconTheme(data) {
    var ciphertext = crypto.AES.encrypt(
      JSON.stringify(data),
      process.env["REACT_APP_CRYPTO_SECRET_KEY"]
    ).toString();
    return localStorage.setItem("icon-theme", ciphertext);
  },
  getIconTheme() {
    try {
      const data = localStorage.getItem("icon-theme");

      if (!data) {
        return utilityConst.iconTheme.default;
      }
      var bytes = crypto.AES.decrypt(
        data,
        process.env["REACT_APP_CRYPTO_SECRET_KEY"]
      );
      var decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
      return decryptedData;
    } catch (err) {
      return false;
    }
  },
  deleteIconTheme() {
    return localStorage.removeItem("icon-theme");
  },

  // Dark Mode
  setDarkMode(data) {
    var ciphertext = crypto.AES.encrypt(
      JSON.stringify(data),
      process.env["REACT_APP_CRYPTO_SECRET_KEY"]
    ).toString();
    return localStorage.setItem("dark-mode", ciphertext);
  },
  getDarkMode() {
    try {
      const data = localStorage.getItem("dark-mode");

      if (!data) {
        return false;
      }
      var bytes = crypto.AES.decrypt(
        data,
        process.env["REACT_APP_CRYPTO_SECRET_KEY"]
      );
      var decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
      return decryptedData;
    } catch (err) {
      return false;
    }
  },
  deleteDarkMode() {
    return localStorage.removeItem("dark-mode");
  },

  // Dark Theme
  setDarkTheme(data) {
    var ciphertext = crypto.AES.encrypt(
      JSON.stringify(data),
      process.env["REACT_APP_CRYPTO_SECRET_KEY"]
    ).toString();
    return localStorage.setItem("dark-theme", ciphertext);
  },
  getDarkTheme() {
    try {
      const data = localStorage.getItem("dark-theme");

      if (!data) {
        return "oled";
      }
      var bytes = crypto.AES.decrypt(
        data,
        process.env["REACT_APP_CRYPTO_SECRET_KEY"]
      );
      var decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
      return decryptedData;
    } catch (err) {
      return "oled";
    }
  },
  deleteDarkTheme() {
    return localStorage.removeItem("dark-theme");
  },

  // Upgrade to Premium Dialog (Deprecated)
  setHasShownUpgradeToPremiumDialog(data) {
    var ciphertext = crypto.AES.encrypt(
      JSON.stringify(data),
      process.env["REACT_APP_CRYPTO_SECRET_KEY"]
    ).toString();
    return localStorage.setItem(
      "has-shown-upgrade-to-premium-dialog",
      ciphertext
    );
  },
  getHasShownUpgradeToPremiumDialog() {
    try {
      const data = localStorage.getItem("has-shown-upgrade-to-premium-dialog");

      if (!data) {
        return null;
      }
      var bytes = crypto.AES.decrypt(
        data,
        process.env["REACT_APP_CRYPTO_SECRET_KEY"]
      );
      var decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
      return decryptedData;
    } catch (err) {
      return null;
    }
  },
  deleteHasShownUpgradeToPremiumDialog() {
    return localStorage.removeItem("has-shown-upgrade-to-premium-dialog");
  },

  // Upgrade to Premium Dialog Counter (Deprecated)
  setUpgradeToPremiumDialogCounter(data) {
    var ciphertext = crypto.AES.encrypt(
      JSON.stringify(data),
      process.env["REACT_APP_CRYPTO_SECRET_KEY"]
    ).toString();
    return localStorage.setItem(
      "upgrade-to-premium-dialog-counter",
      ciphertext
    );
  },
  getUpgradeToPremiumDialogCounter() {
    try {
      const data = localStorage.getItem("upgrade-to-premium-dialog-counter");

      if (!data) {
        return null;
      }
      var bytes = crypto.AES.decrypt(
        data,
        process.env["REACT_APP_CRYPTO_SECRET_KEY"]
      );
      var decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
      return decryptedData;
    } catch (err) {
      return null;
    }
  },
  deleteUpgradeToPremiumDialogCounter() {
    return localStorage.removeItem("upgrade-to-premium-dialog-counter");
  },

  // Search Page Dialog
  setSearchPageFullscreenGeneralDialogLastShown(data) {
    var ciphertext = crypto.AES.encrypt(
      JSON.stringify(data),
      process.env["REACT_APP_CRYPTO_SECRET_KEY"]
    ).toString();
    return localStorage.setItem(
      "search-page-fullscreen-general-dialog-last-shown",
      ciphertext
    );
  },
  getSearchPageFullscreenGeneralDialogLastShown() {
    try {
      const data = localStorage.getItem(
        "search-page-fullscreen-general-dialog-last-shown"
      );

      if (!data) {
        return null;
      }
      var bytes = crypto.AES.decrypt(
        data,
        process.env["REACT_APP_CRYPTO_SECRET_KEY"]
      );
      var decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
      return decryptedData;
    } catch (err) {
      return null;
    }
  },
  deleteSearchPageFullscreenGeneralDialogLastShown() {
    return localStorage.removeItem(
      "search-page-fullscreen-general-dialog-last-shown"
    );
  },

  // Check In Dialog Counter
  setCheckInDialogCounter(data) {
    var ciphertext = crypto.AES.encrypt(
      JSON.stringify(data),
      process.env["REACT_APP_CRYPTO_SECRET_KEY"]
    ).toString();
    return localStorage.setItem("check-in-dialog-counter", ciphertext);
  },
  getCheckInDialogCounter() {
    try {
      const data = localStorage.getItem("check-in-dialog-counter");

      if (!data) {
        return null;
      }
      var bytes = crypto.AES.decrypt(
        data,
        process.env["REACT_APP_CRYPTO_SECRET_KEY"]
      );
      var decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
      return decryptedData;
    } catch (err) {
      return null;
    }
  },
  deleteCheckInDialogCounter() {
    return localStorage.removeItem("check-in-dialog-counter");
  },

  // Geolocation Permission Dialog
  setLocationData(data) {
    var ciphertext = crypto.AES.encrypt(
      JSON.stringify(data),
      process.env["REACT_APP_CRYPTO_SECRET_KEY"]
    ).toString();
    return localStorage.setItem("location-data", ciphertext);
  },
  getLocationData() {
    try {
      const data = localStorage.getItem("location-data");

      if (!data) {
        return null;
      }
      var bytes = crypto.AES.decrypt(
        data,
        process.env["REACT_APP_CRYPTO_SECRET_KEY"]
      );
      var decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
      return decryptedData;
    } catch (err) {
      return null;
    }
  },
  deleteLocationData() {
    return localStorage.removeItem("location-data");
  },

  // New App Onboarding Dialog
  setHasShownNewAppOnboardingDialog(data) {
    var ciphertext = crypto.AES.encrypt(
      JSON.stringify(data),
      process.env["REACT_APP_CRYPTO_SECRET_KEY"]
    ).toString();
    return localStorage.setItem(
      "has-shown-new-app-onboarding-dialog",
      ciphertext
    );
  },
  getHasShownNewAppOnboardingDialog() {
    try {
      const data = localStorage.getItem("has-shown-new-app-onboarding-dialog");

      if (!data) {
        return null;
      }
      var bytes = crypto.AES.decrypt(
        data,
        process.env["REACT_APP_CRYPTO_SECRET_KEY"]
      );
      var decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
      return decryptedData;
    } catch (err) {
      return null;
    }
  },
  deleteHasShownNewAppOnboardingDialog() {
    return localStorage.removeItem("has-shown-new-app-onboarding-dialog");
  },

  /// Onboarding
  // Video Call Dialog
  setHasShownVideoCallDialog(data) {
    var ciphertext = crypto.AES.encrypt(
      JSON.stringify(data),
      process.env["REACT_APP_CRYPTO_SECRET_KEY"]
    ).toString();
    return localStorage.setItem("has-shown-video-call-dialog", ciphertext);
  },
  getHasShownVideoCallDialog() {
    try {
      const data = localStorage.getItem("has-shown-video-call-dialog");

      if (!data) {
        return null;
      }
      var bytes = crypto.AES.decrypt(
        data,
        process.env["REACT_APP_CRYPTO_SECRET_KEY"]
      );
      var decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
      return decryptedData;
    } catch (err) {
      return null;
    }
  },
  deleteHasShownVideoCallDialog() {
    return localStorage.removeItem("has-shown-video-call-dialog");
  },

  // Live Streaming Dialog
  setHasShownLiveStreamingDialog(data) {
    var ciphertext = crypto.AES.encrypt(
      JSON.stringify(data),
      process.env["REACT_APP_CRYPTO_SECRET_KEY"]
    ).toString();
    return localStorage.setItem("has-shown-live-streaming-dialog", ciphertext);
  },
  getHasShownLiveStreamingDialog() {
    try {
      const data = localStorage.getItem("has-shown-live-streaming-dialog");

      if (!data) {
        return null;
      }
      var bytes = crypto.AES.decrypt(
        data,
        process.env["REACT_APP_CRYPTO_SECRET_KEY"]
      );
      var decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
      return decryptedData;
    } catch (err) {
      return null;
    }
  },
  deleteHasShownLiveStreamingDialog() {
    return localStorage.removeItem("has-shown-live-streaming-dialog");
  },

  // Video Call Room Dialog
  setHasShownVideoCallRoomDialog(data) {
    var ciphertext = crypto.AES.encrypt(
      JSON.stringify(data),
      process.env["REACT_APP_CRYPTO_SECRET_KEY"]
    ).toString();
    return localStorage.setItem("has-shown-video-call-room-dialog", ciphertext);
  },
  getHasShownVideoCallRoomDialog() {
    try {
      const data = localStorage.getItem("has-shown-video-call-room-dialog");

      if (!data) {
        return null;
      }
      var bytes = crypto.AES.decrypt(
        data,
        process.env["REACT_APP_CRYPTO_SECRET_KEY"]
      );
      var decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
      return decryptedData;
    } catch (err) {
      return null;
    }
  },
  deleteHasShownVideoCallRoomDialog() {
    return localStorage.removeItem("has-shown-video-call-room-dialog");
  },

  // Video Call Settings Tutorial Dialog
  setHasShownVideoCallSettingsTutorialDialog(data) {
    var ciphertext = crypto.AES.encrypt(
      JSON.stringify(data),
      process.env["REACT_APP_CRYPTO_SECRET_KEY"]
    ).toString();
    return localStorage.setItem(
      "has-shown-video-call-settings-tutorial-dialog",
      ciphertext
    );
  },
  getHasShownVideoCallSettingsTutorialDialog() {
    try {
      const data = localStorage.getItem(
        "has-shown-video-call-settings-tutorial-dialog"
      );

      if (!data) {
        return null;
      }
      var bytes = crypto.AES.decrypt(
        data,
        process.env["REACT_APP_CRYPTO_SECRET_KEY"]
      );
      var decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
      return decryptedData;
    } catch (err) {
      return null;
    }
  },
  deleteHasShownVideoCallSettingsTutorialDialog() {
    return localStorage.removeItem(
      "has-shown-video-call-settings-tutorial-dialog"
    );
  },

  // Video Call Permission Dialog
  setHasShownVideoCallPermissionDialog(data) {
    var ciphertext = crypto.AES.encrypt(
      JSON.stringify(data),
      process.env["REACT_APP_CRYPTO_SECRET_KEY"]
    ).toString();
    return localStorage.setItem(
      "has-shown-video-call-permission-dialog",
      ciphertext
    );
  },
  getHasShownVideoCallPermissionDialog() {
    try {
      const data = localStorage.getItem(
        "has-shown-video-call-permission-dialog"
      );

      if (!data) {
        return null;
      }
      var bytes = crypto.AES.decrypt(
        data,
        process.env["REACT_APP_CRYPTO_SECRET_KEY"]
      );
      var decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
      return decryptedData;
    } catch (err) {
      return null;
    }
  },
  deleteHasShownVideoCallPermissionDialog() {
    return localStorage.removeItem("has-shown-video-call-permission-dialog");
  },

  // Diamonds Info Dialog
  setHasShownDiamondsInfoDialog(data) {
    var ciphertext = crypto.AES.encrypt(
      JSON.stringify(data),
      process.env["REACT_APP_CRYPTO_SECRET_KEY"]
    ).toString();
    return localStorage.setItem("has-shown-diamonds-info-dialog", ciphertext);
  },
  getHasShownDiamondsInfoDialog() {
    try {
      const data = localStorage.getItem("has-shown-diamonds-info-dialog");

      if (!data) {
        return null;
      }
      var bytes = crypto.AES.decrypt(
        data,
        process.env["REACT_APP_CRYPTO_SECRET_KEY"]
      );
      var decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
      return decryptedData;
    } catch (err) {
      return null;
    }
  },
  deleteHasShownDiamondsInfoDialog() {
    return localStorage.removeItem("has-shown-diamonds-info-dialog");
  },

  // Restart Journey Dialog
  setRestartJourneyDialogLastShown(data) {
    var ciphertext = crypto.AES.encrypt(
      JSON.stringify(data),
      process.env["REACT_APP_CRYPTO_SECRET_KEY"]
    ).toString();
    return localStorage.setItem(
      "restart-journey-dialog-last-shown",
      ciphertext
    );
  },
  getRestartJourneyDialogLastShown() {
    try {
      const data = localStorage.getItem("restart-journey-dialog-last-shown");

      if (!data) {
        return null;
      }
      var bytes = crypto.AES.decrypt(
        data,
        process.env["REACT_APP_CRYPTO_SECRET_KEY"]
      );
      var decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
      return decryptedData;
    } catch (err) {
      return null;
    }
  },
  deleteRestartJourneyDialogLastShown() {
    return localStorage.removeItem("restart-journey-dialog-last-shown");
  },
};
