// General
import "./product-purchase.scss";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// Services
import {
  useLazyGetProfileQuery,
  useLazyGetVirtualCurrencyCoinBalanceQuery,
  usePurchaseProductMutation,
} from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateProductPurchaseDialog } from "../../../../redux/store/dialogStore";
import { updatePurchaseDialogTab } from "../../../../redux/store/productsStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// embla-carousel-react
import useEmblaCarousel from "embla-carousel-react";
// Material UI
import { Tabs, Tab, Box } from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import CoinsTab from "../../dialog-content/product-purchase-dialog/coins-tab/coins-tab";
import GiftsTab from "../../dialog-content/product-purchase-dialog/gifts-tab/gifts-tab";
import PopularTab from "../../dialog-content/product-purchase-dialog/popular-tab/popular-tab";
import TipMenuTab from "../../dialog-content/product-purchase-dialog/tip-menu-tab/tip-menu-tab";

const ProductPurchase = (props) => {
  const { state } = props;

  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    getCoins,
    {
      data: getCoinsData,
      error: getCoinsErrorData,
      isFetching: getCoinsFetching,
      isLoading: getCoinsLoading,
      isSuccess: getCoinsSuccess,
      isError: getCoinsError,
    },
  ] = useLazyGetVirtualCurrencyCoinBalanceQuery();
  const [
    purchaseProduct,
    {
      data: purchaseProductData,
      error: purchaseProductErrorData,
      isLoading: purchaseProductLoading,
      isSuccess: purchaseProductSuccess,
      isError: purchaseProductError,
    },
  ] = usePurchaseProductMutation();

  // General variables
  const [view, setView] = useState(null);
  const [showSendButton, setShowSendButton] = useState(false);
  const [page, setPage] = useState("");

  // Redux variables
  const purchaseDialogTab = useSelector(
    (state) => state.products.purchaseDialogTab
  );
  const livestreamerId = useSelector(
    (state) => state.livestreaming.livestreamerId
  );
  const livestreamerUsername = useSelector(
    (state) => state.livestreaming.livestreamerUsername
  );
  const channelId = useSelector((state) => state.livestreaming.channelId);
  const isCoAnchor = useSelector((state) => state.livestreaming.isCoAnchor);
  const coAnchorSelectedUser = useSelector(
    (state) => state.livestreaming.coAnchorSelectedUser
  );
  const selectedGift = useSelector((state) => state.livestreaming.selectedGift);
  const requestId = useSelector((state) => state.privateCall.requestId);
  const calleeId = useSelector((state) => state.privateCall.calleeId);
  const callerId = useSelector((state) => state.privateCall.callerId);
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // Embla variables
  const [emblaRef, emblaApi] = useEmblaCarousel({
    duration: 15,
  });

  // Router variables
  const location = useLocation();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
    getCoins();
  }, []);

  // Lifecycle | Mounted | emblaApi
  useEffect(() => {
    if (!emblaApi) return;
    emblaApi?.scrollTo(purchaseDialogTab);

    emblaApi?.on("select", () => {
      dispatch(updatePurchaseDialogTab(emblaApi?.selectedScrollSnap()));
    });
  }, [emblaApi]);

  // Lifecycle | Check for update | interestTab
  useEffect(() => {
    emblaApi?.scrollTo(purchaseDialogTab);
  }, [purchaseDialogTab]);

  // Lifecycle | Check for update | Purchase Product API Response
  useEffect(() => {
    if (purchaseProductLoading) {
    } else if (purchaseProductSuccess) {
      if (purchaseProductData?.status === 0) {
        dispatch(updateProductPurchaseDialog(false));
      }
    } else if (purchaseProductError) {
      if (purchaseProductErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [purchaseProductLoading, purchaseProductSuccess, purchaseProductError]);

  // Lifecycle | Check for update | location
  useEffect(() => {
    if (!location) return;

    if (location.pathname.includes("live")) {
      setView("live");
      setPage("livestream");
    } else if (location.pathname.includes("video-call")) {
      setPage("private-call");
      if (isDaddy) {
        setView("caller");
      } else {
        setView("callee");
      }
    }
  }, [location]);

  // Lifecycle | Check for update | view
  useEffect(() => {
    switch (view) {
      case "live":
        setShowSendButton(purchaseDialogTab !== 0);
        break;
      case "caller":
        setShowSendButton(purchaseDialogTab === 1);
        break;
      case "callee":
        setShowSendButton(false);
        break;
      default:
        setShowSendButton(false);
    }
  }, [purchaseDialogTab, view]);

  // Event Handlers | Button
  const onSendGift = () => {
    if (location?.pathname.includes("live")) {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-S2-2.1-Button",
        },
      });

      const obj = {
        payment_gateway: "virtual_currency",
        related_module: isCoAnchor ? "co-anchor" : "livestreaming",
        product_hash_id: selectedGift,
        recipient_user_id: isCoAnchor ? coAnchorSelectedUser : livestreamerId,
        related_id: channelId,
      };

      purchaseProduct(obj);
    } else if (location?.pathname.includes("video-call")) {
      if (isDaddy) {
        TagManager.dataLayer({
          dataLayer: {
            event: "PWA-S2-2.2-Button",
          },
        });

        const obj = {
          payment_gateway: "virtual_currency",
          product_hash_id: selectedGift,
          recipient_user_id:
            getProfileData?.data?.id === calleeId ? callerId : calleeId,
          related_id: requestId,
          related_module: "private_calls",
        };

        purchaseProduct(obj);
      }
    }
  };
  const onMoveToCoinShop = () => {
    dispatch(updatePurchaseDialogTab(0));
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateProductPurchaseDialog(false));
  };

  // Event Handlers | MUI Tabs
  const onTabChange = (event, newValue) => {
    dispatch(updatePurchaseDialogTab(newValue));
  };

  // Utility Functions
  const getFormattedCoins = (coins) => {
    if (coins >= 100000) {
      return `${(coins / 1000).toFixed(1)}K`;
    } else {
      return coins?.toLocaleString();
    }
  };

  return (
    <div id="product-purchase-subcomponent">
      <div className="padding-container">
        {state === "dialog" && (
          <div className="header-container">
            <div className="left-container"></div>
            <div className="middle-container">
              {view === "live"
                ? t("live.gifts_for_user", { name: livestreamerUsername })
                : t("live.tips")}
            </div>
            <div className="right-container">
              <div className="close-icon-container" onClick={onCloseDialog}>
                <CloseIcon className="close-icon" />
              </div>
            </div>
          </div>
        )}

        <div
          className={`tab-container ${state === "dialog" ? "" : "headerless"}`}
        >
          <Tabs
            className="custom-tabs"
            value={purchaseDialogTab}
            onChange={onTabChange}
            TabIndicatorProps={{ style: { backgroundColor: "#710d0d" } }}
            variant="fullWidth"
          >
            {location?.pathname.includes("live") && (
              <Tab label={t("1on1.coins")} {...a11yProps(0)} />
            )}
            {location?.pathname.includes("video-call") && isDaddy && (
              <Tab label={t("1on1.coins")} {...a11yProps(0)} />
            )}
            <Tab
              label={t("1on1.gifts")}
              {...a11yProps(
                location?.pathname.includes("live")
                  ? 1
                  : isDaddy
                  ? 1
                  : !isDaddy
                  ? 0
                  : 1
              )}
            />
            {location?.pathname.includes("live") && (
              <Tab label={t("1on1.popular")} {...a11yProps(2)} />
            )}
            {location?.pathname.includes("video-call") && isDaddy && (
              <Tab label={t("1on1.tip_menu")} {...a11yProps(2)} />
            )}
          </Tabs>

          <div ref={emblaRef} className="embla">
            <div className="embla__container">
              <div className="embla__slide">
                {purchaseDialogTab === 0 && (
                  <div>
                    {location?.pathname.includes("live") && <CoinsTab />}
                    {location?.pathname.includes("video-call") && isDaddy && (
                      <CoinsTab state={state} />
                    )}
                    {location?.pathname.includes("video-call") && !isDaddy && (
                      <GiftsTab state={state} page={page} />
                    )}
                  </div>
                )}
              </div>

              <div className="embla__slide">
                {purchaseDialogTab === 1 && (
                  <GiftsTab state={state} page={page} />
                )}
              </div>

              <div className="embla__slide">
                {purchaseDialogTab === 2 && (
                  <div>
                    {location?.pathname.includes("live") && <PopularTab />}
                    {location?.pathname.includes("video-call") && isDaddy && (
                      <TipMenuTab state={state} />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="bottom-container">
          <div className="balance-container" onClick={onMoveToCoinShop}>
            <div className="add-icon-container">
              {getIcon("liveAddCoinIcon", "add-icon")}
            </div>

            <div className="balance-label">{t("common.balance")}:</div>

            <div className="coin-icon-container">
              {getIcon("sbCoinIcon", "coin-icon")}
            </div>

            <div className="coin-value">
              {getFormattedCoins(getCoinsData?.data?.balance)}
            </div>
          </div>

          {showSendButton && (
            <div className="send-button" onClick={onSendGift}>
              {t("common.send")}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductPurchase;

// Material UI | Tab Functions
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      // hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }} className="tab-box-container">
          {children}
          {/* <Typography component={"div"}>{children}</Typography> */}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
