// General
import "./edit-alcohol-preference.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetJourneyQuestionsQuery,
  useUpdateEditUserProfileMutation,
} from "../../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateAlcoholPreference,
  updateIsUnsavedChanges,
} from "../../../../../redux/store/editProfileStore";
import {
  updateProfileCompletionPercentage,
  updateProfileRequirementPopup,
} from "../../../../../redux/store/profileStore";
import { updateUpdateProfileFullscreenDialog } from "../../../../../redux/store/dialogStore";
// Material UI
import { useMediaQuery } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../../elements/spinner/spinner";

const EditAlcoholPreference = (props) => {
  const { addPaddingTop = false } = props;

  // API variables
  const [
    getJourneyQuestions,
    {
      data: getJourneyQuestionsData,
      error: getJourneyQuestionsErrorData,
      isFetching: getJourneyQuestionsFetching,
      isLoading: getJourneyQuestionsLoading,
      isSuccess: getJourneyQuestionsSuccess,
      isError: getJourneyQuestionsError,
    },
  ] = useLazyGetJourneyQuestionsQuery();
  const [
    updateProfile,
    {
      data: updateProfileData,
      error: updateProfileErrorData,
      isLoading: updateProfileLoading,
      isSuccess: updateProfileSuccess,
      isError: updateProfileError,
    },
  ] = useUpdateEditUserProfileMutation();

  // General variables
  const [localAlcoholPreference, setLocalAlcoholPreference] = useState(null);

  // Redux variables
  const alcoholPreference = useSelector(
    (state) => state.editProfile.alcoholPreference
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    setLocalAlcoholPreference(alcoholPreference);

    getJourneyQuestions(null, true);
  }, []);

  // Lifecycle | Check for update | updateProfile API Response
  useEffect(() => {
    if (updateProfileLoading) {
    } else if (updateProfileSuccess) {
      dispatch(
        updateAlcoholPreference(updateProfileData?.data?.user?.habit?.alcohol)
      );
      dispatch(
        updateProfileCompletionPercentage(
          updateProfileData?.data?.user?.profile_progress?.summary?.progress
        )
      );
      dispatch(
        updateProfileRequirementPopup(
          updateProfileData?.data?.user?.profile_progress?.summary?.requirement
        )
      );
      dispatch(updateIsUnsavedChanges(false));
      dispatch(updateUpdateProfileFullscreenDialog(false));
    } else if (updateProfileError) {
    }
  }, [updateProfileLoading, updateProfileSuccess, updateProfileError]);

  // Lifecycle | Check for update | localAlcoholPreference
  useEffect(() => {
    if (
      alcoholPreference?.length !== localAlcoholPreference?.length ||
      alcoholPreference?.some(
        (item) =>
          !localAlcoholPreference?.some(
            (localItem) => localItem?.id == item?.id
          )
      )
    ) {
      dispatch(updateIsUnsavedChanges(true));
    } else {
      dispatch(updateIsUnsavedChanges(false));
    }
  }, [localAlcoholPreference]);

  // Event Handlers | Button
  const onSelectAlcoholPreference = (alcohol) => {
    let alcoholPreferenceTemp = [...localAlcoholPreference];

    if (!alcoholPreferenceTemp?.some((item) => item?.id === alcohol?.id)) {
      if (alcoholPreferenceTemp?.length < returnMaxAlcoholPreference()) {
        alcoholPreferenceTemp?.push(alcohol);
      } else if (returnMaxAlcoholPreference() === 1) {
        alcoholPreferenceTemp = [alcohol];
      }
    } else {
      alcoholPreferenceTemp = alcoholPreferenceTemp?.filter(
        (item) => item?.id !== alcohol?.id
      );
    }
    setLocalAlcoholPreference(alcoholPreferenceTemp);
  };
  const onSaveAlcoholPreference = () => {
    if (!isFormValid()) return;

    const obj = {
      profile: {
        alcohol: localAlcoholPreference?.map((item) => item?.id),
      },
    };
    updateProfile(obj);
  };

  // Utility Functions
  const isFormValid = () => {
    return (
      localAlcoholPreference?.length >= returnMinAlcoholPreference() &&
      localAlcoholPreference?.length <= returnMaxAlcoholPreference() &&
      !updateProfileLoading
    );
  };
  const getAlcoholPreferenceData = () => {
    return getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "alcohol"
    )?.selections;
  };
  const checkAlcoholPreference = (alcohol) => {
    return localAlcoholPreference?.find((item) => item?.id === alcohol?.id);
  };
  const returnMinAlcoholPreference = () => {
    return (
      getJourneyQuestionsData?.questions?.find(
        (item) => item?.category === "alcohol"
      )?.min_selection || 1
    );
  };
  const returnMaxAlcoholPreference = () => {
    return (
      getJourneyQuestionsData?.questions?.find(
        (item) => item?.category === "alcohol"
      )?.max_selection || 6
    );
  };
  const getAlcoholIcon = (alcoholId) => {
    switch (alcoholId) {
      case 70:
        return getIcon("redWineIcon", "alcohol-icon");
      case 71:
        return getIcon("whiteWineIcon", "alcohol-icon");
      case 72:
        return getIcon("champagneIcon", "alcohol-icon");
      case 73:
        return getIcon("cognacIcon", "alcohol-icon");
      case 74:
        return getIcon("tequilaIcon", "alcohol-icon");
      case 75:
        return getIcon("beerIcon", "alcohol-icon");
      case 76:
        return getIcon("cocktailIcon", "alcohol-icon");
      case 77:
        return getIcon("vodkaIcon", "alcohol-icon");
      case 78:
        return getIcon("brandyIcon", "alcohol-icon");
      case 79:
        return getIcon("highballIcon", "alcohol-icon");
      case 80:
        return getIcon("whiskeyIcon", "whiskey-icon");
      case 81:
        return getIcon("liqueurIcon", "alcohol-icon");
      case 82:
        return getIcon("absintheIcon", "alcohol-icon");
      case 83:
        return getIcon("rumIcon", "alcohol-icon");
      case 84:
        return getIcon("sangriaIcon", "alcohol-icon");
      case 85:
        return getIcon("ginIcon", "alcohol-icon");
      case 86:
        return getIcon("sakeIcon", "alcohol-icon");
      case 87:
        return getIcon("sojuIcon", "alcohol-icon");
      case 88:
        return getIcon("martiniIcon", "alcohol-icon");
      case 89:
        return getIcon("shotsIcon", "alcohol-icon");
    }
  };

  return (
    <div id="alcohol-preference-subcomponent">
      <div className="padding-container">
        <div
          className={`top-container ${
            addPaddingTop ? "add-edit-padding-top" : ""
          }`}
        >
          <div className="drinking-icon-container">
            {getIcon("drinkingIcon", "drinking-icon")}
          </div>

          <div className="alcohol-preference-title">
            {t("profile.whats_your_drinking_preference")}
          </div>

          <div className={`alcohol-preference-choose-options`}>
            {t("common.chosen_n_out_of_max_options", {
              n: localAlcoholPreference?.length,
              max: returnMaxAlcoholPreference(),
            })}
          </div>

          {getJourneyQuestionsFetching || getJourneyQuestionsLoading ? (
            <Spinner />
          ) : (
            <div className="alcohol-preference-body-container">
              {getAlcoholPreferenceData()?.map((item, index) => (
                <div
                  className={`alcohol-preference-item ${
                    checkAlcoholPreference(item) ? "selected" : ""
                  }`}
                  onClick={() => onSelectAlcoholPreference(item)}
                  key={index}
                >
                  <div className="alcohol-icon-container">
                    {getAlcoholIcon(item?.id)}
                  </div>
                  <div className="alcohol-item">{item?.display_locale}</div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="bottom-container">
        <div
          className={`save-button ${isFormValid() ? "" : "disabled-button"}`}
          onClick={onSaveAlcoholPreference}
        >
          {updateProfileLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("profile.tor_selected", {
              n: localAlcoholPreference?.length,
              max: returnMaxAlcoholPreference(),
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default EditAlcoholPreference;
