// General
import "./profile-rejected-info-dialog.scss";
import { useEffect, forwardRef } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateProfileRejectedInfoDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide, useMediaQuery } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const ProfileRejectedInfoDialog = () => {
  // Redux variables
  const profileRejectedInfoDialog = useSelector(
    (state) => state.dialog.profileRejectedInfoDialog
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!profileRejectedInfoDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-ProfileRejectedInfo-Dialog",
      },
    });
  }, [profileRejectedInfoDialog]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateProfileRejectedInfoDialog(false));
  };

  return (
    <Dialog
      className={
        isMobile ? "custom-bottom-sheet-dialog" : "custom-radius10-dialog"
      }
      fullScreen={isMobile}
      open={profileRejectedInfoDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      <div id="profile-rejected-info-dialog">
        <div className="padding-container">
          <div className="profile-rejected-header">
            <div className="profile-rejected-icon-container">
              {getIcon("rejectedProfileIcon", "profile-rejected-icon")}
            </div>

            <div className="profile-rejected-title">
              Your information is being rejected
            </div>
          </div>

          <div className="reason-container">
            <div className="reason-title">Reason</div>

            <div className="reason-description">
              Our system detects an error (e.g., offensive language,
              identifiable info, etc.) in one of your info
            </div>
          </div>

          <div className="tips-container">
            <div className="tips-title">Tips</div>

            <div className="tips-description">
              Please avoid using offensive, vulgar words, sharing phone numbers,
              social IDs, and personal info.
            </div>
          </div>

          <div className="understand-button-container" onClick={onCloseDialog}>
            {t("common.understand")}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ProfileRejectedInfoDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
