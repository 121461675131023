// General
import "./join.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetAioQuery,
  useUpdateProfileMutation,
} from "../../services/data.service";
// Static Data
import routeConst from "../../const/routeConst";
// Enums
import user from "../../enums/user";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateGender, updateLookingFor } from "../../redux/store/signupStore";
import { updateWhatsARoleDialog } from "../../redux/store/dialogStore";
import { updateErrorToast } from "../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Button, useMediaQuery } from "@mui/material";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../utility/manager/icon-manager/icon-manager";

const Join = () => {
  // API variables
  const [
    getAio,
    {
      data: getAioData,
      error: getAioErrorData,
      isFetching: getAioFetching,
      isLoading: getAioLoading,
      isSuccess: getAioSuccess,
      isError: getAioError,
    },
  ] = useLazyGetAioQuery();
  const [
    updateProfile,
    {
      data: updateProfileData,
      error: updateProfileErrorData,
      isLoading: updateProfileLoading,
      isSuccess: updateProfileSuccess,
      isError: updateProfileError,
    },
  ] = useUpdateProfileMutation();

  // Redux variables
  const email = useSelector((state) => state.signup.email);
  const gender = useSelector((state) => state.signup.gender);
  const lookingFor = useSelector((state) => state.signup.lookingFor);
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-6",
      },
    });

    getAio();
  }, []);

  // Lifecycle | Check for update | Update Profile API Response
  useEffect(() => {
    if (updateProfileLoading) {
    } else if (updateProfileSuccess) {
      switch (updateProfileData?.status) {
        case 0:
        case 1:
          onNavigate(routeConst.infoSignup.path);
          break;
        default:
          const toastObj = {
            message: t("common.something_went_wrong"),
            autoClose: 3000,
          };
          dispatch(updateErrorToast(toastObj));
          break;
      }
    } else if (updateProfileError) {
    }
  }, [updateProfileLoading, updateProfileSuccess, updateProfileError]);

  // Event Handlers | Button
  const onNextPage = () => {
    if (!isFormValid()) return;
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-Join-Page-To-Account-Signup-Page",
      },
    });

    onNavigate(routeConst.accountSignup.path);
  };
  const onOpenRoleInfoDialog = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-6-6.1",
      },
    });

    dispatch(updateWhatsARoleDialog(true));
  };
  const onSwitchGender = () => {
    if (gender === user.gender.MAN) {
      dispatch(updateGender(user.gender.WOMAN));
      dispatch(updateLookingFor(user.lookingFor.SUGARDADDY));
    } else if (gender === user.gender.WOMAN) {
      dispatch(updateGender(user.gender.MAN));
      dispatch(updateLookingFor(user.lookingFor.SUGARBABY));
    }
  };

  // Event Handlers | MUI Select
  const onGenderSelect = (event) => {
    dispatch(updateGender(event));
    // dispatch(updateLookingFor(""));

    if (event === "man") {
      dispatch(updateLookingFor(user.lookingFor.SUGARBABY));
    } else if (event === "woman") {
      dispatch(updateLookingFor(user.lookingFor.SUGARDADDY));
    }
  };
  const onLookingForSelect = (event) => {
    dispatch(updateLookingFor(event));
  };

  // Utility Functions
  const isFormValid = () => {
    let valid = false;
    if (gender !== "" && lookingFor !== "") {
      valid = true;
    }

    return valid;
  };
  const getGender = (gender) => {
    if (gender === user.gender.MAN) {
      return t("signup.opt_man");
    } else if (gender === user.gender.WOMAN) {
      return t("signup.opt_woman");
    }
  };
  const getRoles = (gender, lookingFor) => {
    if (gender === "man") {
      if (lookingFor === "sugardaddy") {
        return "sugarbaby";
      } else if (lookingFor === "sugarbaby") {
        return "sugardaddy";
      }
    } else if (gender === "woman") {
      if (lookingFor === "sugardaddy") {
        return "sugarbaby";
      } else if (lookingFor === "sugarbaby") {
        return "sugardaddy";
      }
    }
  };

  return (
    <div id="join-page" className="join-container">
      <div className="max-width-container">
        <div className="navbar-container">
          <div
            className="back-button"
            onClick={() => onNavigate(routeConst.login.path)}
          >
            {getIcon("signupBackIcon", "signup-back-icon")}
          </div>
        </div>

        <div className="join-body-container">
          {!gender && (
            <div className="gender-select-description-container">
              <div className="where-romance-meets-finance-mobile-label">
                <Trans
                  i18nKey={"signup.join_title"}
                  components={{
                    br: <br />,
                  }}
                />
              </div>
            </div>
          )}

          {gender && (
            <div className="looking-for-select-description-container">
              <div className="who-are-you-looking-for-label">
                {t("signup.who_are_you_looking_for")}
              </div>
            </div>
          )}

          <div className="i-am-container">
            <div className="i-am-label">
              <div className="i-am-a-label">
                {gender ? t("signup.i_am_a_plain") : t("signup.i_am_a")}
              </div>
              <div className="selected-gender">{getGender(gender)}</div>

              {gender && (
                <div
                  className="switch-gender-container"
                  onClick={onSwitchGender}
                >
                  {getIcon("switchIcon", "switch-icon")}
                  <div className="switch-gender-label">
                    {t("common.switch")}
                  </div>
                </div>
              )}
            </div>

            {!gender && (
              <div className="i-am-a-button-container">
                <div
                  className={`man-label ${gender === "man" ? "selected" : ""}`}
                  onClick={() => onGenderSelect(user.gender.MAN)}
                >
                  {t("signup.opt_man")}
                </div>

                <div
                  className={`woman-label ${
                    gender === "woman" ? "selected" : ""
                  }`}
                  onClick={() => onGenderSelect(user.gender.WOMAN)}
                >
                  {t("signup.opt_woman")}
                </div>
              </div>
            )}
          </div>

          {gender && (
            <div className="looking-for-container">
              <div className="looking-for-label-container">
                <div className="looking-for-label">
                  {t("signup.i_am_looking_for_a")}
                </div>

                <div className="role-info" onClick={onOpenRoleInfoDialog}>
                  {getIcon("helpIcon", "help-icon")}
                </div>
              </div>

              <div className="looking-for-button-container">
                {gender === "man" && (
                  <div className="selection-container">
                    <div
                      className={`body-label ${
                        lookingFor === "sugarbaby" ? "selected" : ""
                      }`}
                      onClick={() =>
                        onLookingForSelect(user.lookingFor.SUGARBABY)
                      }
                    >
                      {t("signup.opt_sugar_baby")}
                    </div>
                    {false && (
                      <div
                        className={`body-label ${
                          lookingFor === "sugardaddy" ? "selected" : ""
                        }`}
                        onClick={() =>
                          onLookingForSelect(user.lookingFor.SUGARMOMMY)
                        }
                      >
                        {t("signup.opt_sugar_mommy")}
                      </div>
                    )}
                  </div>
                )}

                {gender === "woman" && (
                  <div className="selection-container">
                    <div
                      className={`body-label ${
                        lookingFor === "sugardaddy" ? "selected" : ""
                      }`}
                      onClick={() =>
                        onLookingForSelect(user.lookingFor.SUGARDADDY)
                      }
                    >
                      {t("signup.opt_sugar_daddy")}
                    </div>
                    {false && (
                      <div
                        className={`body-label ${
                          lookingFor === "sugarbaby" ? "selected" : ""
                        }`}
                        onClick={() =>
                          onLookingForSelect(user.lookingFor.MALE_SUGARBABY)
                        }
                      >
                        {t("signup.opt_male_sugar_baby")}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        {gender && (
          <div className="bottom-container">
            <div
              className={`continue-button ${
                isMobile ? "full-width" : "partial-width"
              } ${isFormValid() ? "" : "disabled-button"}`}
              onClick={onNextPage}
            >
              {t("common.continue")}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Join;
