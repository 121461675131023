// Assets
import BlowKissEmoji from "../assets/icon/clickable/live/emoji/face_blowing_a_kiss.svg";
import HeartEyesEmoji from "../assets/icon/clickable/live/emoji/smiling_face_with_heart_eyes.svg";
import ThumbsUpEmoji from "../assets/icon/clickable/live/emoji/c_thumbs_up.svg";
import FireEmoji from "../assets/icon/clickable/live/emoji/fire.svg";

const emojiConst = {
  livestreamEmoji: [
    {
      emojiId: "face_blowing_a_kiss",
      emojiUnicode: "😘",
      emojiImage: (
        <img
          className="blow-kiss-emoji"
          src={BlowKissEmoji}
          alt="blow-kiss-emoji"
        />
      ),
    },
    {
      emojiId: "smiling_face_with_heart_eyes",
      emojiUnicode: "😍",
      emojiImage: (
        <img
          className="heart-eyes-emoji"
          src={HeartEyesEmoji}
          alt="heart-eyes-emoji"
        />
      ),
    },
    {
      emojiId: "c_thumbs_up",
      emojiUnicode: "👍",
      emojiImage: (
        <img
          className="thumbs-up-emoji"
          src={ThumbsUpEmoji}
          alt="thumbs-up-emoji"
        />
      ),
    },
    {
      emojiId: "fire",
      emojiUnicode: "🔥",
      emojiImage: (
        <img className="fire-emoji" src={FireEmoji} alt="fire-emoji" />
      ),
    },
  ],
  videoCallEmoji: [
    {
      emojiId: "coin",
    },
  ],
};

export default emojiConst;
