// General
import "./vip-entrance-element.scss";
import { useState, useRef } from "react";
// Material UI
import { Divider } from "@mui/material";
// react-device-detect
import { isIOS } from "react-device-detect";
// Components
import CustomAvatar from "../../../../shared/elements/custom-avatar/custom-avatar";
import Spinner from "../../../../shared/elements/spinner/spinner";

const VipEntranceElement = (props) => {
  const {
    asset,
    profilePhoto,
    username,
    description,
    showCenterLine = false,
    animationDuration = 3,
  } = props;

  // General variables
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const imageData = useRef(null);

  const handleImageLoad = () => {
    if (!isIOS) return;

    setTimeout(() => {
      setIsImageLoaded(true);
    }, 1000);

    setTimeout(() => {
      setIsImageLoaded(false);
    }, animationDuration * 1000);
  };

  return (
    <div
      id="vip-entrance-container"
      style={
        animationDuration > 0
          ? {
              WebkitAnimation: `slideInAndOutLeft ${animationDuration}s forwards`,
              MozAnimation: `slideInAndOutLeft ${animationDuration}s forwards`,
              animation: `slideInAndOutLeft ${animationDuration}s ease-in-out`,
            }
          : {}
      }
    >
      {isIOS ? (
        <div className="vip-entrance-gif-container">
          {imageData && (
            <img
              className="vip-entrance-asset"
              ref={imageData}
              src={asset}
              style={{ display: isImageLoaded ? "block" : "none" }}
              onLoad={handleImageLoad}
            />
          )}

          {!isImageLoaded && <Spinner size={12} isPadding={false} />}
        </div>
      ) : (
        <div className="vip-entrance-gif-container">
          {imageData && (
            <img className="vip-entrance-asset" ref={imageData} src={asset} />
          )}
        </div>
      )}

      <div className="vip-entrance-description-container">
        <div className="vip-entrance-description">
          <div className="profile-photo-container">
            <CustomAvatar className="vip-profile-photo" src={profilePhoto} />
          </div>

          <div className="vip-description-container">
            <div className="vip-top-description-container">
              <div className="vip-username">{username || ""}</div>
              <div className="vip-levelling-badge"></div>
            </div>

            <div className="vip-bottom-description-container">
              {description || ""}
            </div>
          </div>
        </div>
      </div>

      {showCenterLine && (
        <div className="vip-entrance-center-line-container">
          <Divider className="vip-center-line" />
        </div>
      )}
    </div>
  );
};

export default VipEntranceElement;
