// General
import "./call-minute-element.scss";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";

const CallMinuteElement = (props) => {
  const { diamondsValue } = props;

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  return (
    <div id="chat-section-call-minute-element-subcomponent">
      <div className="earned-minute-top">
        <Trans
          i18nKey={"1on1.youve_earned_diamond_from"}
          values={{
            diamond: `+${diamondsValue}`,
            source: t("1on1.a_minute_on_call"),
          }}
          components={{
            diamondicon: getIcon("sbDiamondIcon", "diamond-icon"),
            coinicon: <span></span>,
          }}
        />
      </div>
    </div>
  );
};

export default CallMinuteElement;
