// General
import "./spinner.scss";
// Material UI
import { CircularProgress } from "@mui/material";

const Spinner = (props) => {
  const {
    type = "default",
    isPadding = true,
    size = 50,
    thickness = 5,
    color = "default-spinner",
  } = props;

  // Render variables
  let spinnerView;

  switch (type) {
    case "default":
      spinnerView = <CircularProgress size={size} thickness={thickness} />;
      break;
    case "alt":
      spinnerView = (
        <div className="spinner-alt">
          <div className="spinner-alt-blade"></div>
          <div className="spinner-alt-blade"></div>
          <div className="spinner-alt-blade"></div>
          <div className="spinner-alt-blade"></div>
          <div className="spinner-alt-blade"></div>
          <div className="spinner-alt-blade"></div>
          <div className="spinner-alt-blade"></div>
          <div className="spinner-alt-blade"></div>
          <div className="spinner-alt-blade"></div>
          <div className="spinner-alt-blade"></div>
          <div className="spinner-alt-blade"></div>
          <div className="spinner-alt-blade"></div>
        </div>
      );
      break;
    case "iOS":
      spinnerView = (
        <div className="ios-spinner">
          <div className="spinner-blade"></div>
          <div className="spinner-blade"></div>
          <div className="spinner-blade"></div>
          <div className="spinner-blade"></div>
          <div className="spinner-blade"></div>
          <div className="spinner-blade"></div>
          <div className="spinner-blade"></div>
          <div className="spinner-blade"></div>
          <div className="spinner-blade"></div>
          <div className="spinner-blade"></div>
          <div className="spinner-blade"></div>
          <div className="spinner-blade"></div>
        </div>
      );
      break;
    default:
      break;
  }

  return (
    <div
      id="spinner-subcomponent"
      className={`${isPadding && "apply-padding"} ${color ? color : ""}`}
    >
      {spinnerView}
    </div>
  );
};

export default Spinner;
