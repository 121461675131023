// General
import "./basic-information.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetProfileQuery,
  useUpdateEditUserProfileMutation,
} from "../../../../../services/data.service";
import { removeHTMLEscape } from "../../../../../services/regex.service";
// Static Data
import userConst from "../../../../../const/userConst";
import editProfileConst from "../../../../../const/editProfileConst";
import utilityConst from "../../../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateUsername,
  updateBirthdate,
  updateTagline,
  updateUsernameNewContent,
  updateUpdateProfileDialogView,
} from "../../../../../redux/store/editProfileStore";
import { updateUpdateProfileFullscreenDialog } from "../../../../../redux/store/dialogStore";
import {
  updateErrorToast,
  updateSuccessToast,
} from "../../../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Sentry
import * as Sentry from "@sentry/react";
// react-date-picker
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
// Material UI
import { Divider, TextField } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// i18next
import { useTranslation } from "react-i18next";
// Moment
import moment from "moment";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../../../shared/elements/spinner/spinner";
import ModerationStatus from "../../moderation-status/moderation-status";

const BasicInformation = (props) => {
  const { mode = utilityConst.editProfileMode.mobile } = props;

  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    updateUserProfile,
    {
      data: updateUserProfileData,
      error: updateUserProfileErrorData,
      isLoading: updateUserProfileLoading,
      isSuccess: updateUserProfileSuccess,
      isError: updateUserProfileError,
    },
  ] = useUpdateEditUserProfileMutation();

  // General variables
  const minDate = moment().subtract(userConst.userAgeRange.min, "years");
  const maxDate = moment().subtract(userConst.userAgeRange.max, "years");

  // Redux variables
  const email = useSelector((state) => state.editProfile.email);
  const username = useSelector((state) => state.editProfile.username);
  const age = useSelector((state) => state.editProfile.age);
  const birthdate = useSelector((state) => state.editProfile.birthdate);
  const usernameStatus = useSelector(
    (state) => state.editProfile.usernameStatus
  );
  const usernameNewContent = useSelector(
    (state) => state.editProfile.usernameNewContent
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  // Lifecycle | Check for update | Update User Profile API Response
  useEffect(() => {
    if (updateUserProfileLoading) {
    } else if (updateUserProfileSuccess) {
      // Backend returning empty if success
      const toastObj = {
        message: t("signup.profile_updated"),
        autoClose: 3000,
      };
      dispatch(updateSuccessToast(toastObj));
    } else if (updateUserProfileError) {
      switch (updateUserProfileErrorData?.data?.status) {
        case -1:
          const errorToastObj = {
            message: updateUserProfileErrorData?.data?.message,
            autoClose: 3000,
          };
          dispatch(updateErrorToast(errorToastObj));
          break;
        default:
          break;
      }
    }
  }, [
    updateUserProfileLoading,
    updateUserProfileSuccess,
    updateUserProfileError,
  ]);

  // Event Handlers | Button
  const onSaveDetails = () => {
    if (!isBasicInformationValid()) return;

    let obj = {
      username: returnUsernameValue(),
      birth_date: moment(birthdate).format("YYYY-MM-DD"),
    };
    updateUserProfile(obj);
  };
  const onEditSection = (section) => {
    switch (section) {
      case "username":
        dispatch(
          updateUpdateProfileDialogView(
            editProfileConst.profileDialogView.username
          )
        );
        dispatch(updateUpdateProfileFullscreenDialog(true));
        break;
      case "birthdate":
        if (
          !age ||
          (age < userConst.userAgeRange.max && age > userConst.userAgeRange.min)
        ) {
          dispatch(
            updateUpdateProfileDialogView(
              editProfileConst.profileDialogView.birthdate
            )
          );
          dispatch(updateUpdateProfileFullscreenDialog(true));
        }
        break;
      default:
        break;
    }
  };

  // Event Handlers | MUI Text Fields
  const onInputChange = (state) => (event) => {
    switch (state) {
      case "username":
        if (
          usernameStatus === userConst.profileGeneralApprovalStatus.inReview ||
          usernameStatus === userConst.profileGeneralApprovalStatus.rejected
        ) {
          dispatch(updateUsernameNewContent(event.target.value));
        } else {
          dispatch(updateUsername(event.target.value));
        }
        break;
      case "birthday":
        dispatch(updateBirthdate(moment(event).format("YYYY-MM-DD")));
        break;
      case "tagline":
        dispatch(updateTagline(event.target.value));
        break;
      default:
        break;
    }
  };

  // Utility Functions
  const isBasicInformationValid = () => {
    if (
      ((usernameNewContent && usernameNewContent !== "") ||
        (username && username !== "")) &&
      birthdate &&
      birthdate !== ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  const returnUsernameValue = () => {
    if (
      usernameStatus === userConst.profileGeneralApprovalStatus.inReview ||
      usernameStatus === userConst.profileGeneralApprovalStatus.rejected
    ) {
      return usernameNewContent;
    } else {
      return username;
    }
  };
  const getTranslatedStatus = (status) => {
    switch (status) {
      case userConst.profileGeneralApprovalStatus.inReview:
        return t("common.in_review");
      case userConst.profileGeneralApprovalStatus.rejected:
        return t("common.rejected");
      default:
        return "";
    }
  };

  if (mode === utilityConst.editProfileMode.mobile) {
    return (
      <div id="mobile-edit-profile-basic-information-subcomponent">
        <div className="basic-information-label-container">
          <div className="basic-information-label">
            {t("profile.basic_information")}
          </div>
        </div>

        <div className="form-field-padding-container">
          {/* Email */}
          <div className="form-field-container">
            <div className="label-container">
              <div className="label">{t("settings.email")}</div>
            </div>

            <div className="form-container">
              <div className="email-label">{email || ""}</div>
            </div>
          </div>

          {/* Username */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label ${!username ? "incomplete-field" : ""}`}>
                {t("profile.username")}
              </div>
            </div>

            <div
              className="form-container"
              onClick={() => onEditSection("username")}
            >
              {returnUsernameValue() ? (
                <div className="filled-form username-filled-form">
                  {returnUsernameValue()}
                </div>
              ) : (
                <div className="empty-form">+ {t("common.add")}</div>
              )}

              <div className="right-icon">
                <ChevronRightIcon className="select-form-field-dropdown-icon" />
              </div>

              {usernameStatus &&
                usernameStatus !==
                  userConst.profileGeneralApprovalStatus.approved && (
                  <div
                    className={`approval-status-container ${
                      usernameStatus ===
                      userConst.profileGeneralApprovalStatus.inReview
                        ? "in-review-status-container"
                        : usernameStatus ===
                          userConst.profileGeneralApprovalStatus.rejected
                        ? "rejected-status-container"
                        : ""
                    }`}
                  >
                    {getTranslatedStatus(usernameStatus)}
                  </div>
                )}
            </div>
          </div>

          {/* Birthday */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label ${birthdate ? "" : "incomplete-field"}`}>
                {t("common.age")}
              </div>
            </div>

            <div
              className="form-container"
              onClick={() => onEditSection("birthdate")}
            >
              {age ? (
                <div className="filled-form">{age}</div>
              ) : (
                <div className="empty-form">+ {t("common.add")}</div>
              )}

              <div className="right-icon">
                {(!age ||
                  age < userConst.userAgeRange.max ||
                  age > userConst.userAgeRange.min) && (
                  <ChevronRightIcon className="select-form-field-dropdown-icon" />
                )}
              </div>
            </div>

            {/* <div className="form-container">
              {age &&
              age > userConst.userAgeRange.max &&
              age < userConst.userAgeRange.min ? (
                <div className="age-label">{age}</div>
              ) : (
                <div className="date-picker-container">
                  <DatePicker
                    onChange={onInputChange("birthday")}
                    value={birthdate || moment().toDate()}
                    minDate={minDate.toDate()}
                    maxDate={maxDate.toDate()}
                    clearIcon={null}
                    format={"dd/MM/yyyy"}
                  />
                </div>
              )}
            </div> */}
          </div>
        </div>
      </div>
    );
  } else if (mode === utilityConst.editProfileMode.desktop) {
    return (
      <div id="desktop-edit-profile-basic-information-subcomponent">
        {/* In Review */}
        <ModerationStatus
          status={userConst.profileGeneralApprovalStatus.inReview}
        />

        {/* Rejected */}
        <ModerationStatus
          status={userConst.profileGeneralApprovalStatus.rejected}
        />

        <div className="basic-information-static-label-container">
          <div className="basic-information-label">
            {t("profile.basic_information")}
          </div>
          <Divider className="short-divider" />
        </div>

        <div className="form-field-padding-container">
          {/* Email */}
          <div className="form-field-container">
            <div className="label-container">
              <div className="label">{t("settings.email")}</div>
            </div>

            <div className="form-container">
              <div className="email-label">{email || ""}</div>
            </div>
          </div>

          {/* Username */}
          <div className="form-field-container">
            <div className="label-container">
              <div
                className={`label ${
                  !username && !usernameNewContent ? "incomplete-field" : ""
                }`}
              >
                {t("profile.username")}

                {usernameStatus &&
                  usernameStatus !==
                    userConst.profileGeneralApprovalStatus.approved && (
                    <div className="approval-status-container">
                      {getTranslatedStatus(usernameStatus)}
                    </div>
                  )}
              </div>
            </div>

            <div className="form-container">
              <TextField
                sx={{ "& .MuiSelect-select": { marginTop: "6px" } }}
                className="form-field custom-text-field-max-radius custom-text-field-outline-default custom-text-field-outline-white-background-color"
                value={returnUsernameValue() || ""}
                placeholder={`+ ${t("common.add")}`}
                onChange={onInputChange("username")}
                variant="outlined"
                inputProps={{
                  sx: {
                    "&::placeholder": {
                      opacity: "1",
                      font: "500 1em Lato",
                      color: "#CCCCCC",
                    },
                  },
                }}
              ></TextField>
            </div>
          </div>

          {/* Birthday */}
          <div className="form-field-container">
            <div className="label-container">
              <div className={`label ${birthdate ? "" : "incomplete-field"}`}>
                {t("common.age")}
              </div>
            </div>

            <div className="form-container">
              {!age ||
              age < userConst.userAgeRange.max ||
              age > userConst.userAgeRange.min ? (
                <div className="date-picker-container">
                  <DatePicker
                    onChange={onInputChange("birthday")}
                    value={birthdate || moment().toDate()}
                    minDate={minDate.toDate()}
                    maxDate={maxDate.toDate()}
                    clearIcon={null}
                    format={"dd/MM/yyyy"}
                  />
                </div>
              ) : (
                <div className="age-label">{age}</div>
              )}
            </div>
          </div>
        </div>

        <div className="bottom-section-container">
          <div
            className={`save-button ${
              isBasicInformationValid() ? "" : "disabled-button"
            }`}
            onClick={onSaveDetails}
          >
            {updateUserProfileLoading ? (
              <Spinner size={19} isPadding={false} color={"white-spinner"} />
            ) : (
              t("common.save")
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default BasicInformation;
