// General
import "./looking-for.scss";
import { useState, useEffect, useRef } from "react";
// Services
import {
  useLazyGetJourneyQuestionsQuery,
  useUpdateJourneyMutation,
} from "../../../../services/data.service";
// Static Data
import searchConst from "../../../../const/searchConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateLookingForAgeRangeMin,
  updateLookingForAgeRangeMax,
  updateLookingForLocation,
  updateLookingForEthnicity,

  // Utility Functions
  updateDetailsSignupScroll,
  updateJourneyNavigationPassthrough,
  updateJourneyDataPassthrough,
  updateRegistrationNavigationData,
} from "../../../../redux/store/registrationStore";
import { updateErrorToast } from "../../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import {
  Slider,
  MenuItem,
  TextField,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ClearIcon from "@mui/icons-material/Clear";
// React Google Maps/api
import { Autocomplete } from "@react-google-maps/api";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Components
import Spinner from "../../../shared/elements/spinner/spinner";

const LookingForDetails = () => {
  // API variables
  const [
    getJourneyQuestions,
    {
      data: getJourneyQuestionsData,
      error: getJourneyQuestionsErrorData,
      isFetching: getJourneyQuestionsFetching,
      isLoading: getJourneyQuestionsLoading,
      isSuccess: getJourneyQuestionsSuccess,
      isError: getJourneyQuestionsError,
    },
  ] = useLazyGetJourneyQuestionsQuery();
  const [
    updateJourney,
    {
      data: updateJourneyData,
      error: updateJourneyErrorData,
      isLoading: updateJourneyLoading,
      isSuccess: updateJourneySuccess,
      isError: updateJourneyError,
    },
  ] = useUpdateJourneyMutation();
  const [
    skipJourney,
    {
      data: skipJourneyData,
      error: skipJourneyErrorData,
      isLoading: skipJourneyLoading,
      isSuccess: skipJourneySuccess,
      isError: skipJourneyError,
    },
  ] = useUpdateJourneyMutation();

  // General variables
  const [searchLocation, setSearchLocation] = useState("");
  const [searchResult, setSearchResult] = useState();
  const [ethnicity, setEthnicity] = useState("");
  const [race, setRace] = useState("");
  const [localLocation, setLocalLocation] = useState("");
  const [locationLoading, setLocationLoading] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [subTimeoutId, setSubTimeoutId] = useState(null);
  const targetTextFieldRef = useRef(null);
  const overlayTextFieldRef = useRef(null);

  // Redux variables
  const lookingFor = useSelector((state) => state.registration.lookingFor);
  const lookingForAgeRangeMin = useSelector(
    (state) => state.registration.lookingForAgeRangeMin
  );
  const lookingForAgeRangeMax = useSelector(
    (state) => state.registration.lookingForAgeRangeMax
  );
  const googleMapsLoaded = useSelector(
    (state) => state.noReset.googleMapsLoaded
  );
  const skippableJourney = useSelector(
    (state) => state.registration.skippableJourney
  );
  const registrationNavigationData = useSelector(
    (state) => state.registration.registrationNavigationData
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t, i18n } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    getJourneyQuestions(null, true);
  }, []);

  // Lifecycle | Check for update | updateJourney API Response
  useEffect(() => {
    if (updateJourneyLoading) {
    } else if (updateJourneySuccess) {
      dispatch(updateJourneyDataPassthrough(updateJourneyData));
      dispatch(updateJourneyNavigationPassthrough({}));
    } else if (updateJourneyError) {
      const warningToast = {
        message: updateJourneyErrorData?.data?.message,
        autoClose: 3000,
      };
      dispatch(updateErrorToast(warningToast));
    }
  }, [updateJourneyLoading, updateJourneySuccess, updateJourneyError]);

  // Lifecycle | Check for update | skipJourney API Response
  useEffect(() => {
    if (skipJourneyLoading) {
    } else if (skipJourneySuccess) {
      const updatedItems = registrationNavigationData?.map((item, i) => ({
        ...item,
        skipped: i === 13 ? true : i > 13 ? false : item.skipped,
      }));
      dispatch(updateRegistrationNavigationData(updatedItems));

      dispatch(updateJourneyDataPassthrough(updateJourneyData));
      dispatch(updateJourneyNavigationPassthrough({}));
    } else if (skipJourneyError) {
    }
  }, [skipJourneyLoading, skipJourneySuccess, skipJourneyError]);

  // Event Handlers | Button
  const onRemoveEthnicityRace = (index) => {
    let ethnicityRaceTemp = [...lookingFor?.ethnicity];
    ethnicityRaceTemp.splice(index, 1);
    dispatch(updateLookingForEthnicity(ethnicityRaceTemp));
  };
  const onRemoveLocation = (index) => {
    let selectedLocationsTemp = [...lookingFor?.location];
    selectedLocationsTemp.splice(index, 1);
    dispatch(updateLookingForLocation(selectedLocationsTemp));
  };
  const onNextPage = () => {
    if (!isFormValid()) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-62-62.14-62.14.1",
      },
    });

    const obj = {
      journey_step: i18n.language.toLowerCase().includes("zh-tw") ? "15" : "14",
      journey_data: {
        looking_for: {
          age_min: lookingForAgeRangeMin,
          age_max: lookingForAgeRangeMax,
          locations: lookingFor.location,
          ethnicities: lookingFor.ethnicity?.map(({ ethnicity, race }) => ({
            ethnicity: ethnicity.id,
            race: race?.id || null,
          })),
        },
      },
    };
    updateJourney(obj);
  };
  const onSkip = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-62-62.14-62.14.2",
      },
    });

    const obj = {
      journey_step: i18n.language.toLowerCase().includes("zh-tw") ? "15" : "14",
      journey_data: {
        looking_for: {},
      },
    };
    skipJourney(obj);
  };

  // Event Handlers | MUI Text Fields
  const onOverlayInputChange = (event) => {
    setLocalLocation(event.target.value);
    setLocationLoading(true);

    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }

    setTimeoutId(
      setTimeout(() => {
        setSearchLocation(event.target.value);

        if (subTimeoutId) {
          clearTimeout(subTimeoutId);
          setSubTimeoutId(null);
        }

        setTimeout(() => {
          if (targetTextFieldRef.current) {
            targetTextFieldRef.current.focus();
            setLocationLoading(false);
          }
        }, 500);
      }, 1000)
    );
  };
  const onInputChange = (event) => {
    if (overlayTextFieldRef.current) {
      setLocalLocation(event.target.value);
      overlayTextFieldRef.current.focus();
    }
  };
  const onClearLocation = () => {
    setLocalLocation("");
    setSearchLocation(null);
  };

  // Event Handlers | Slider
  const onAgeRangeChange = (event, newValue) => {
    dispatch(updateLookingForAgeRangeMin(newValue[0]));
    dispatch(updateLookingForAgeRangeMax(newValue[1]));
  };

  // Event Handlers | MUI Select
  const handleSelectChange = (type) => (event) => {
    if (lookingFor.ethnicity?.length < returnMaxEthnicity()) {
      switch (type) {
        case "ethnicity":
          setEthnicity(event.target.value);

          if (checkRaceExist(event.target.value) === false) {
            let ethnicityRaceTemp = [...lookingFor.ethnicity];
            ethnicityRaceTemp.push({ ethnicity: event.target.value });
            dispatch(updateLookingForEthnicity(ethnicityRaceTemp));

            setEthnicity("");
          }
          break;
        case "race":
          setRace(event.target.value);

          let ethnicityRaceTemp = [...lookingFor.ethnicity];
          ethnicityRaceTemp.push({
            ethnicity: ethnicity,
            race: event.target.value,
          });
          dispatch(updateLookingForEthnicity(ethnicityRaceTemp));

          setEthnicity("");
          setRace("");
          break;
        default:
          break;
      }
    }
  };

  // Event Handlers | Google Maps Autocomplete
  const onPlaceChanged = () => {
    if (
      searchResult != null &&
      lookingFor.location?.length < returnMaxLocation()
    ) {
      const place = searchResult.getPlace();
      let location_data = {
        formatted_address: place?.formatted_address,
      };

      if (place?.address_components) {
        for (let i = 0; i < place?.address_components?.length; i++) {
          if (place?.address_components[i]?.types?.length > 0) {
            switch (place?.address_components[i]?.types[0]) {
              case "locality":
                location_data.city = place?.address_components[i]?.long_name;
                break;
              case "neighborhood":
                if (location_data.city !== null) {
                  location_data.neighborhood =
                    place?.address_components[i]?.long_name;
                }
                break;
              case "administrative_area_level_1":
                location_data.state = place?.address_components[i]?.short_name;
                break;
              case "country":
                location_data.country = place?.address_components[i]?.long_name;
                location_data.country_iso =
                  place?.address_components[i]?.short_name;
                break;
              default:
                break;
            }
          }
        }
      }

      const locationObj = {
        lat: place?.geometry?.location?.lat(),
        lng: place?.geometry?.location?.lng(),
        location_data,
      };

      let selectedLocationsTemp = [...lookingFor?.location];
      selectedLocationsTemp.push(locationObj);
      dispatch(updateLookingForLocation(selectedLocationsTemp));
      setSearchLocation("");
      setLocalLocation("");
    }
  };
  const onLoad = (autocomplete) => {
    setSearchResult(autocomplete);
  };

  // Utility Functions
  const isFormValid = () => {
    return (
      lookingForAgeRangeMin &&
      lookingForAgeRangeMax &&
      lookingFor?.location?.length > 0 &&
      lookingFor?.ethnicity?.length > 0 &&
      !updateJourneyLoading
    );
  };
  const getEthnicityData = () => {
    // Ensure selections is defined and is an array
    if (!Array.isArray(getJourneyQuestionsData?.selections)) {
      return [];
    }

    // Find the ethnicity data
    const ethnicityCategory = getJourneyQuestionsData?.selections.find(
      (item) => item?.category === "ethnicity"
    );

    // If ethnicity data is not found, return an empty array
    if (!ethnicityCategory || !Array.isArray(ethnicityCategory?.selections)) {
      return [];
    }

    // Filter out the ethnicity data based on localEthnicity conditions
    return ethnicityCategory.selections.filter(
      (ethnicity) =>
        !lookingFor?.ethnicity.some(
          (local) =>
            (local.ethnicity.id === ethnicity.id && local.race === null) ||
            (local.ethnicity.id === 701 && ethnicity.id == 701) ||
            (local.ethnicity.id === 700 && ethnicity.id === 700)
        )
    );
  };
  const getRaceData = () => {
    return (
      getJourneyQuestionsData?.selections
        ?.find((item) => item?.category === "race")
        ?.selections?.filter(
          (raceItem) =>
            raceItem?.sub_category === ethnicity?.tag && // Check if the sub_category matches the ethnicity tag
            !lookingFor?.ethnicity?.some(
              (local) => local?.race?.id === raceItem?.id
            ) // Ensure the item is not in localEthnicity
        ) ?? []
    );
  };
  const checkRaceExist = (ethnicity) => {
    const raceData = getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "race"
    )?.selections;

    const foundParent = raceData?.find(
      (item) => item.sub_category === ethnicity?.tag
    );

    return !!foundParent;
  };
  const handleSliderInteractionStart = () => {
    dispatch(updateDetailsSignupScroll(false));
  };
  const handleSliderInteractionEnd = () => {
    dispatch(updateDetailsSignupScroll(true));
  };
  const returnMaxLocation = () => {
    return 3;
  };
  const returnMaxEthnicity = () => {
    return 3;
  };

  return (
    <div id="details-signup-looking-for-details-subcomponent">
      <div className="top-container">
        <div className="looking-for-details-label">
          <Trans
            i18nKey={"registration.looking_for.title"}
            components={{ br: <br /> }}
          />
        </div>

        <div className="looking-for-details-description-label">
          {t("registration.looking_for.description")}
        </div>

        {getJourneyQuestionsFetching || getJourneyQuestionsLoading ? (
          <Spinner />
        ) : (
          <div className="looking-for-body-container">
            <div className="age-range-container">
              <div className="age-range-label">
                {t("registration.looking_for.age_range")}
              </div>

              <div className="age-range-body-container">
                <div className="age-range-slider-container">
                  <Slider
                    className="custom-slider-white my-slider"
                    value={[lookingForAgeRangeMin, lookingForAgeRangeMax]}
                    onChange={onAgeRangeChange}
                    valueLabelDisplay="off"
                    disableSwap
                    min={searchConst.filters.ageRange.min}
                    max={searchConst.filters.ageRange.max}
                    // onTouchStart={handleSliderInteractionStart}
                    // onMouseDown={handleSliderInteractionStart}
                    // onTouchEnd={handleSliderInteractionEnd}
                    // onMouseUp={handleSliderInteractionEnd}
                  />
                </div>

                <div className="selected-age-range-label">
                  {lookingForAgeRangeMin} - {lookingForAgeRangeMax}{" "}
                  {t("registration.looking_for.yo")}
                </div>

                <div></div>
              </div>
            </div>

            <div className="location-container">
              <div className="location-label">
                {t("registration.looking_for.location")}
              </div>

              {googleMapsLoaded ? (
                <div className="location-body-container">
                  <div className="location-form-field-container">
                    <Autocomplete
                      className="autocomplete-wrapper"
                      onPlaceChanged={onPlaceChanged}
                      onLoad={onLoad}
                      options={{ types: ["geocode"] }}
                    >
                      <TextField
                        sx={{ "& .MuiSelect-select": { marginTop: "6px" } }}
                        className="form-field"
                        inputRef={targetTextFieldRef}
                        onChange={onInputChange}
                        value={searchLocation}
                        variant="standard"
                        InputProps={{
                          placeholder: t(
                            "registration.looking_for.select_preferred_location"
                          ),
                          // disableUnderline: true,
                          endAdornment: (
                            <IconButton onClick={onClearLocation}>
                              <ClearIcon />
                            </IconButton>
                          ),
                        }}
                      ></TextField>
                    </Autocomplete>

                    <div className="overlay-form-field-container">
                      <TextField
                        sx={{ "& .MuiSelect-select": { marginTop: "6px" } }}
                        className="overlay-form-field"
                        inputRef={overlayTextFieldRef}
                        onChange={onOverlayInputChange}
                        value={localLocation}
                        variant="standard"
                        placeholder={t("profile.enter_a_location")}
                        InputProps={{
                          // disableUnderline: true,
                          endAdornment: (
                            <IconButton onClick={onClearLocation}>
                              <ClearIcon />
                            </IconButton>
                          ),
                        }}
                      ></TextField>
                    </div>
                  </div>

                  <div className="selected-locations-container">
                    {lookingFor?.location?.map((item, index) => (
                      <div
                        className={`selected-location-container ${
                          item?.location_data?.formatted_address?.length > 40
                            ? "less-radius"
                            : "more-radius"
                        }`}
                        key={index}
                      >
                        {item?.location_data?.formatted_address ||
                          item?.location_data?.city ||
                          item?.location_data?.state ||
                          item?.location_data?.country}
                        <CloseIcon
                          className="remove-selected-location-icon"
                          onClick={() => onRemoveLocation(index)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <Spinner />
              )}

              <div className="location-max-selection">
                {t("registration.looking_for.select_n_locations", {
                  n: returnMaxLocation(),
                })}
              </div>
            </div>

            <div className="ethnicity-container">
              <div className="ethnicity-label">
                {t("registration.looking_for.ethnicity")}
              </div>

              <div className="ethnicity-body-container">
                <div className="ethnicity-form-field-container">
                  <div className="ethnicity-select-form-field-container">
                    <TextField
                      className="ethnicity-select-form-field"
                      select
                      value={ethnicity}
                      placeholder={t(
                        "registration.looking_for.select_ethnicity"
                      )}
                      label={t("registration.looking_for.select_ethnicity")}
                      onChange={handleSelectChange("ethnicity")}
                      variant="standard"
                    >
                      {getEthnicityData()?.map((ethnicity, index) => (
                        <MenuItem value={ethnicity} key={index}>
                          {t(`${ethnicity?.display_locale}`)}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>

                  {checkRaceExist(ethnicity) && (
                    <div className="race-select-form-field-container">
                      <TextField
                        className="race-select-form-field"
                        select
                        value={race}
                        placeholder={t("registration.looking_for.select_race")}
                        label={t("registration.looking_for.select_race")}
                        onChange={handleSelectChange("race")}
                        variant="standard"
                      >
                        {ethnicity?.id !== 700 && ethnicity?.id !== 701 && (
                          <MenuItem value={null}>{t("common.all")}</MenuItem>
                        )}

                        {getRaceData()?.map((race, index) => (
                          <MenuItem value={race} key={index}>
                            {t(`${race?.display_locale}`)}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  )}
                </div>

                <div className="selected-ethnicity-race-container">
                  {lookingFor?.ethnicity?.map((item, index) => (
                    <div className="ethnicity-race-container" key={index}>
                      {item?.ethnicity?.display_locale}{" "}
                      {item?.ethnicity?.id !== 701 && item?.race?.display_locale
                        ? `, ${item?.race?.display_locale}`
                        : ""}
                      <CloseIcon
                        className="remove-selected-ethnicity-race-icon"
                        onClick={() => onRemoveEthnicityRace(index)}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div className="ethnicity-max-selection">
                {t("registration.looking_for.select_n_ethnicity", {
                  n: returnMaxEthnicity(),
                })}
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="bottom-container">
        <div
          className={`continue-button ${
            isMobile ? "full-width" : "fit-content"
          } ${isFormValid() ? "" : "disabled-button"}`}
          onClick={onNextPage}
        >
          {updateJourneyLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("common.continue")
          )}
        </div>

        <div className="skip-button" onClick={onSkip}>
          {skipJourneyLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("common.skip")
          )}
        </div>
      </div>
    </div>
  );
};

export default LookingForDetails;
