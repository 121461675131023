// General
import "./user-list.scss";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// Services
import {
  useLazyGetProfileQuery,
  usePostFollowUserMutation,
  usePostUnfollowUserMutation,
} from "../../../../../services/data.service";
// Static Data
import userConst from "../../../../../const/userConst";
import routeConst from "../../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateUnfollowUserDialog,
  updateVerifiedCallerDescriptionDialog,
  updateUserProfileDialog,
} from "../../../../../redux/store/dialogStore";
import {
  updateOtherUserId,
  updateOtherUserInfo,
  updateFollowState,
} from "../../../../../redux/store/leaderboardStore";
import {
  // User View
  updateId,
  updateIdInt,
  updateUsername,
  updateAge,
  updateGender,
  updateLocation,
  updateMembershipType,
  updateVerifiedProfile,
  updateVerifiedCaller,
  updateLevellingBadge,
  updateLivestreamingAchievementBadge,
  updateSpecialBadges,
  updateProfilePhoto,
  updateProfileDecorations,
  updatePublicPhotos,
  updatePrivatePhotos,
  updateTorTags,
  updateProfileAchievementBadge,
  updateAboutMe,
  updateAboutMeManualText,
  updateAboutMeReadStatus,
  updateLookingFor,
  updateOccupation,
  updateLifestyle,
  updateEducation,
  updateRelationship,
  updateSmoking,
  updateDrinking,
  updateHeight,
  updateBodyType,
  updateEthnicity,
  updateEyeColor,
  updateHairColor,
  updateChildren,
  // About Me Functions
  updatePersonalityType,
  updateHobbies,
  updateFitnessLevel,
  updateStarSign,
  updateDominantOrSubmissive,
  updatePersonalityTraits,
  // User Utility
  updateShouldCallApi,
  updatePrivatePhotoRequested,
  updatePrivatePhotoApproval,
  updateRequestedMyPrivatePhoto,
  updateApprovedMyPrivatePhoto,
  updateIsBlocked,
  updateIsSilentInteraction,
  updateIsFavorited,
  updateIsCallEnabled,
  updateIsSugarbaby,
  updateRole,
  updateDatingBudget,
} from "../../../../../redux/store/userCardStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../../../elements/custom-avatar/custom-avatar";
import VerifiedProfileBadge from "../../../elements/verified-profile-badge/verified-profile-badge";

const UserList = (props) => {
  const { type, userList, userType, renderLimit = 99 } = props;

  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    postFollowUser,
    {
      data: postFollowUserData,
      error: postFollowUserErrorData,
      isLoading: postFollowUserLoading,
      isSuccess: postFollowUserSuccess,
      isError: postFollowUserError,
    },
  ] = usePostFollowUserMutation();

  // Redux variables
  const otherUserId = useSelector((state) => state.leaderboard.otherUserId);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Router variables
  const location = useLocation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  // Lifecycle | Check for update | Follow User API Response
  useEffect(() => {
    if (postFollowUserLoading) {
    } else if (postFollowUserSuccess) {
      if (postFollowUserData?.status === 1) {
        const obj = {
          userId: otherUserId,
          isFollowing: true,
        };
        dispatch(updateFollowState(obj));
      }
    } else if (postFollowUserError) {
    }
  }, [postFollowUserLoading, postFollowUserSuccess, postFollowUserError]);

  // Event Handlers | Button
  const onViewProfile = (user) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-19-19.4-Button",
      },
    });

    // User View
    dispatch(updateId(user?.id));
    dispatch(updateIdInt(user?.id_int));
    dispatch(updateUsername(user?.username));
    dispatch(updateAge(user?.appearance?.age));
    dispatch(updateGender(user?.gender));
    dispatch(updateLocation(user?.location?.city));
    dispatch(updateMembershipType(user?.membership_type));
    dispatch(updateVerifiedProfile(user?.is_verified_profile));
    dispatch(updateVerifiedCaller(user?.verified_caller));
    dispatch(updateLevellingBadge(user?.levelling_profile?.badge));
    dispatch(
      updateLivestreamingAchievementBadge(
        user?.live_streaming_achievements?.chat_badges_set
      )
    );
    dispatch(updateSpecialBadges(user?.special_badges));
    dispatch(updateProfilePhoto(user?.profile_photo?.original_photo));
    dispatch(updateProfileDecorations(user?.profile_decorations));
    dispatch(
      updatePublicPhotos(
        user?.public_photos?.map((photo) => {
          return {
            photoUrl: photo?.original_photo,
          };
        })
      )
    );
    dispatch(
      updatePrivatePhotos(
        user?.private_photos?.map((photo) => {
          return {
            photoUrl: photo?.original_photo,
            thumbnailData: photo?.thumbnail_data,
            isSensitive: photo?.is_sensitive,
          };
        })
      )
    );
    dispatch(updateTorTags(user?.tor_tags));
    dispatch(
      updateProfileAchievementBadge(
        user?.live_streaming_achievements?.profile_badges
      )
    );
    dispatch(updateAboutMeManualText(user?.aboutme));
    dispatch(updateAboutMeReadStatus(user?.aboutme_read_status));
    dispatch(updateLookingFor(user?.interest));
    dispatch(updateOccupation(user?.occupation?.occupation));
    dispatch(updateLifestyle(user?.occupation?.lifestyle_budget));
    dispatch(updateDatingBudget(user?.relationship?.dating_budget));
    dispatch(updateEducation(user?.occupation?.education));
    dispatch(updateRelationship(user?.relationship?.relationship_status));
    dispatch(updateSmoking(user?.habit?.smoking));
    dispatch(updateDrinking(user?.habit?.drinking));
    dispatch(updateHeight(user?.appearance?.height));
    dispatch(updateBodyType(user?.appearance?.body_type));
    dispatch(updateEthnicity(user?.appearance?.ethnicity));
    dispatch(updateEyeColor(user?.appearance?.eye_color));
    dispatch(updateHairColor(user?.appearance?.hair_color));
    dispatch(updateChildren(user?.appearance?.children));
    // About Me Functions
    dispatch(updatePersonalityType(user?.personality?.personality_type));
    dispatch(updateHobbies(user?.habit?.hobbies));
    dispatch(updateFitnessLevel(user?.habit?.fitness_level));
    dispatch(updateStarSign(user?.personality?.star_sign));
    dispatch(
      updateDominantOrSubmissive(user?.personality?.dominant_or_submissive)
    );
    dispatch(updatePersonalityTraits(user?.personality?.personality_traits));

    // User Utility
    dispatch(updateShouldCallApi(true));
    dispatch(
      updatePrivatePhotoRequested(user?.interaction?.private_photo_requested)
    );
    dispatch(
      updatePrivatePhotoApproval(user?.interaction?.private_photo_approval)
    );
    dispatch(
      updateRequestedMyPrivatePhoto(
        user?.interaction?.requested_my_private_photo
      )
    );
    dispatch(
      updateApprovedMyPrivatePhoto(user?.interaction?.approved_my_private_photo)
    );
    dispatch(updateIsBlocked(user?.interaction?.is_blocked ? true : false));
    dispatch(
      updateIsSilentInteraction(user?.interaction?.should_silent_interactions)
    );
    dispatch(updateIsFavorited(user?.interaction?.is_favorited));
    dispatch(
      updateIsCallEnabled(
        user?.private_call_settings?.enable_calls &&
          user?.private_call_settings?.system_private_calls_enabled
      )
    );
    dispatch(
      updateIsSugarbaby(
        user?.role === userConst.userRole.sugarbaby ||
          user?.role === userConst.userRole.maleSugarbaby
      )
    );
    dispatch(updateRole(user?.role));

    // In livestream, use dialog to prevent disruption in livestream
    if (location.pathname.includes(routeConst.leaderboard.path)) {
      onNavigate(`${routeConst.profile.view.clearPath}${user?.id}`); // Page
    } else {
      dispatch(updateUserProfileDialog(true)); // Dialog
    }
  };
  const onFollowUser = (userId) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-19-19.5-Button",
      },
    });

    // User ID is stored in store because follow/unfollow API response does not return user ID, which is needed to update the follow state
    dispatch(updateOtherUserId(userId));

    const obj = {
      user_id: userId,
    };

    postFollowUser(obj);
  };
  const onUnfollowUser = (user) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-19-19.6-Button",
      },
    });

    dispatch(updateOtherUserInfo(user));
    dispatch(updateUnfollowUserDialog(true));
  };
  const onOpenVerifiedCallerDialog = () => {
    dispatch(updateVerifiedCallerDescriptionDialog(true));
  };

  return (
    <div id="leaderboard-section-user-list-subcomponent">
      {userList?.map(
        (user, index) =>
          index < renderLimit && (
            <div className="user-container" key={index}>
              <div className="trophy-container">
                {index === 0 && getIcon("trophyRank1Badge", "trophy")}
                {index === 1 && getIcon("trophyRank2Badge", "trophy")}
                {index === 2 && getIcon("trophyRank3Badge", "trophy")}
              </div>

              <CustomAvatar
                className="profile-photo"
                src={user?.user?.profile_photo?.original_photo}
                profileBorder={
                  user?.user?.profile_decorations?.profile_border_url
                }
                onClick={() => onViewProfile(user?.user)}
              />

              <div className="user-description-container">
                <div className="top-container">
                  {user?.user?.live_streaming_achievements?.chat_badges_set
                    ?.length > 0 && (
                    <div className="livestreaming-achievement-badge-container">
                      {user?.user?.live_streaming_achievements?.chat_badges_set?.map(
                        (badge, index) => (
                          <img
                            className="livestreaming-achievement-badge"
                            src={badge?.url}
                            alt={badge?.type}
                            key={index}
                          />
                        )
                      )}
                    </div>
                  )}

                  <div className="username">{user?.user?.username || "-"}</div>
                  <div className="badge-container">
                    {/* Membership */}
                    {(user?.user?.membership_type ===
                      userConst.membershipType.premium ||
                      user?.user?.membership_type ===
                        userConst.membershipType.freePremium) &&
                      getIcon("premiumMiniBadge", "premium-mini-badge")}

                    {/* Verified Profile */}
                    <div className="verified-profile-container">
                      <VerifiedProfileBadge
                        verifiedProfile={user?.user?.is_verified_profile}
                        className={"verified-profile-badge"}
                      />
                    </div>

                    {/* Verified Caller */}
                    {user?.user?.verified_caller && (
                      <div
                        className="verified-caller-container"
                        onClick={onOpenVerifiedCallerDialog}
                      >
                        {getIcon(
                          "verifiedCallerBadge",
                          "verified-caller-badge"
                        )}
                      </div>
                    )}

                    {/* Levelling */}
                    {user?.user?.levelling_profile?.badge && (
                      <div className="level-container">
                        <img
                          className="level-badge"
                          src={user?.user?.levelling_profile?.badge}
                          alt="levelling-badge"
                        />
                      </div>
                    )}

                    {/* Special Badges */}
                    {user?.user?.special_badges?.length > 0 && (
                      <div className="special-badges-container">
                        {user?.user?.special_badges?.map((badge, index) => (
                          <img
                            className="special-badges"
                            src={badge?.asset_short}
                            alt={"special"}
                            key={index}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className="bottom-container">
                  <div className="diamond-icon-container">
                    {userType === "streamer"
                      ? getIcon("sbDiamondIcon", "diamond-icon")
                      : getIcon("sbCoinIcon", "diamond-icon")}
                  </div>

                  {userType === "streamer" ? (
                    <div className="diamond-count">
                      {user?.total_amount_received?.toLocaleString()}
                    </div>
                  ) : (
                    <div className="coin-count">
                      {type === "casino"
                        ? user?.total_amount?.toLocaleString()
                        : user?.total_amount_spent?.toLocaleString()}
                    </div>
                  )}
                </div>
              </div>

              <span className="spacer"></span>

              {getProfileData?.data?.id_int === user?.user?.id_int ||
              user?.user?.interaction?.should_silent_interactions ? null : user
                  ?.user?.live_streaming_meta?.is_following ? (
                <div
                  className="following-button"
                  onClick={() => onUnfollowUser(user?.user)}
                >
                  {t("common.following")}
                </div>
              ) : !user?.user?.live_streaming_meta?.is_following ? (
                <div
                  className="follow-button"
                  onClick={() => onFollowUser(user?.user?.id)}
                >
                  {t("common.follow")}
                </div>
              ) : null}
            </div>
          )
      )}
    </div>
  );
};

export default UserList;
