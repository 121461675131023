// General
import "./orientation-mounted.scss";
import { useEffect } from "react";
// Static Data
import utilityConst from "../../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateScreenOrientation } from "../../../../redux/store/appStore";
import { updatePreventLandscapeDialog } from "../../../../redux/store/dialogStore";
// react-device-detect
import {
  isAndroid,
  isIOS,
  isIPad13,
  isMobile,
  deviceDetect,
  isTablet,
} from "react-device-detect";

const OrientationMounted = () => {
  // Redux variables
  const screenOrientation = useSelector((state) => state.app.screenOrientation);
  const dispatch = useDispatch();

  // Lifecycle | Mounted
  useEffect(() => {
    const handleOrientationChange = () => {
      dispatch(
        updateScreenOrientation(
          window?.screen?.orientation?.type?.includes("landscape")
            ? utilityConst.screenOrientation.landscape
            : utilityConst.screenOrientation.portrait
        )
      );
    };

    window.addEventListener("orientationchange", handleOrientationChange);

    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  // Lifecycle | Check for update | orientation
  useEffect(() => {
    if (isMobile && !isTablet) {
      if (
        screenOrientation === utilityConst.screenOrientation.landscape &&
        process.env["REACT_APP_ENVIRONMENT"] !== "development"
      ) {
        dispatch(updatePreventLandscapeDialog(true));
      } else {
        dispatch(updatePreventLandscapeDialog(false));
      }
    }
  }, [screenOrientation]);

  return <div id="orientation-mounted"></div>;
};

export default OrientationMounted;
