// General
import "./support.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetAioQuery,
  useLazyGetUserAioQuery,
  useLazyGetProfileQuery,
  useSendSupportTicketMutation,
} from "../../services/data.service";
import { removeHTMLEscape } from "../../services/regex.service";
// Static Data
import routeConst from "../../const/routeConst";
// Redux
import { useDispatch } from "react-redux";
import {
  updateSuccessToast,
  updateErrorToast,
  updateWarningToast,
} from "../../redux/store/toastStore";
// browser-image-compression
import imageCompression from "browser-image-compression";
// react-gtm-module
import TagManager from "react-gtm-module";
// Sentry
import * as Sentry from "@sentry/react";
// Material UI
import { TextField, MenuItem, Button } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../utility/custom-hooks/useCustomNavigate-hook";
// Components
import UtilityDesktopNavbar from "../shared/elements/utility-desktop-navbar/utility-desktop-navbar";
import Spinner from "../shared/elements/spinner/spinner";

const Support = () => {
  // API variables
  const [
    getAio,
    {
      data: getAioData,
      error: getAioErrorData,
      isFetching: getAioFetching,
      isLoading: getAioLoading,
      isSuccess: getAioSuccess,
      isError: getAioError,
    },
  ] = useLazyGetAioQuery();
  const [
    getUserAio,
    {
      data: getUserAioData,
      error: getUserAioErrorData,
      isFetching: getUserAioFetching,
      isLoading: getUserAioLoading,
      isSuccess: getUserAioSuccess,
      isError: getUserAioError,
    },
  ] = useLazyGetUserAioQuery();
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    sendSupportTicket,
    {
      data: sendSupportTicketData,
      error: sendSupportTicketErrorData,
      isLoading: sendSupportTicketLoading,
      isSuccess: sendSupportTicketSuccess,
      isError: sendSupportTicketError,
    },
  ] = useSendSupportTicketMutation();

  // General variables
  const [category, setCategory] = useState("");
  const [categoryErrorMessage, setCategoryErrorMessage] = useState("");
  const [categoryFocus, setCategoryFocus] = useState(false);
  const [categoryValid, setCategoryValid] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  const [issueDescription, setIssueDescription] = useState("");
  const [issueDescriptionErrorMessage, setIssueDescriptionErrorMessage] =
    useState("");
  const [issueDescriptionFocus, setIssueDescriptionFocus] = useState(false);
  const [issueDescriptionValid, setIssueDescriptionValid] = useState(false);
  const [issueDescriptionError, setIssueDescriptionError] = useState(false);
  const [showLiveSubtitle, setShowLiveSubtitle] = useState(false);

  const [imageData, setImageData] = useState(null);

  // Redux variables
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-51",
      },
    });

    getAio(null, true);
    getUserAio(null, true);
    getProfile(null, true);
  }, []);

  // Lifecycle | Check for update | Send Support Ticket API Response
  useEffect(() => {
    if (sendSupportTicketLoading) {
    } else if (sendSupportTicketSuccess) {
      if (sendSupportTicketData?.status === 0) {
        const toastObj = {
          message: t("settings.support_sent_success"),
          autoClose: 3000,
        };
        dispatch(updateSuccessToast(toastObj));

        onNavigate(-1);
      } else {
        const toastObj = {
          message: t("settings.support_sent_failed"),
          autoClose: 3000,
        };
        dispatch(updateErrorToast(toastObj));
      }
    } else if (sendSupportTicketError) {
      if (sendSupportTicketErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    sendSupportTicketLoading,
    sendSupportTicketSuccess,
    sendSupportTicketError,
  ]);

  // Event Handlers | Button
  const onSendInformation = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-51-51.1-Button",
      },
    });

    const formData = new FormData();

    formData.append("category", category);
    formData.append("description", issueDescription);
    formData.append("attachment", imageData?.file);
    formData.append("reported_user_id", getProfileData?.data?.id_int);

    sendSupportTicket(formData);
  };
  const onRemoveImage = () => {
    setImageData(null);
  };
  const onFilePicker = async (event) => {
    const file = event.target.files[0];

    const options = {
      maxSizeMB: 1,
    };

    if (file !== undefined) {
      try {
        if (file?.size > 1024 * 1024 * 10) {
          const warningToastObj = {
            message: t("common.file_size_is_too_large"),
            autoClose: 3000,
          };
          dispatch(updateWarningToast(warningToastObj));
        } else {
          if (file.type.startsWith("image")) {
            const compressedFile = await imageCompression(file, options);
            const imageData = await readFile(compressedFile);

            setImageData((prevState) => ({
              ...prevState,
              image: imageData,
              file: compressedFile,
            }));
          } else if (file.type.startsWith("video")) {
            generateVideoThumbnail(file);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const onOpenSocialsLink = () => {
    window.open(
      getUserAioData?.data?.urls?.cash_out_banner?.url ||
        routeConst.socialMedia.sbPrTeamTelegram.path,
      "_blank"
    );
  };

  // Event Handlers | MUI Text Fields
  const onCategoryChange = (e) => {
    setCategory(e.target.value);

    if (e.target.value === "Live & Private Room") {
      setShowLiveSubtitle(true);
    } else {
      setShowLiveSubtitle(false);
    }
  };
  const onCategoryFocus = (e) => {
    setCategoryFocus(true);
  };
  const onIssueDescriptionChange = (e) => {
    if (removeHTMLEscape.test(e.target.value)) {
      setIssueDescription("");

      // Send to Sentry
      Sentry.captureMessage("PWA-51-Description-Input-HTML-Escape-Detected");

      // Send to GTM
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-51-Description-Input-HTML-Escape-Detected",
        },
      });
    } else {
      setIssueDescription(e.target.value);
    }
  };
  const onIssueDescriptionFocus = (e) => {
    setIssueDescriptionFocus(true);
  };

  // Utility Functions
  const isFormValid = () => {
    return !sendSupportTicketLoading && categoryValid && issueDescriptionValid;
  };
  const categoryValidation = () => {
    let valid = true;
    let error = false;

    if (!categoryFocus) {
      valid = false;
      error = false;
    } else if (categoryFocus && category === "") {
      setCategoryErrorMessage("Category is required");
      valid = false;
      error = true;
    } else {
      setCategoryErrorMessage("");
      valid = true;
      error = false;
    }

    setCategoryValid(valid);
    setCategoryError(error);
  };
  const issueDescriptionValidation = () => {
    let valid = true;
    let error = false;

    if (!issueDescriptionFocus) {
      valid = false;
      error = false;
    } else if (issueDescriptionFocus && issueDescription === "") {
      setIssueDescriptionErrorMessage("Issue description is required");
      valid = false;
      error = true;
    } else {
      setIssueDescriptionErrorMessage("");
      valid = true;
      error = false;
    }

    setIssueDescriptionValid(valid);
    setIssueDescriptionError(error);
  };
  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };
  const generateVideoThumbnail = (videoFile) => {
    const video = document.createElement("video");

    video.preload = "metadata";
    video.onloadedmetadata = async () => {
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      const thumbnail = canvas.toDataURL("image/jpeg");
      setImageData((prevState) => ({
        ...prevState,
        image: thumbnail,
        file: videoFile,
      }));
    };
    video.src = URL.createObjectURL(videoFile);
  };

  // Lifecycle | Check for update | Validation
  useEffect(() => {
    categoryValidation();
    issueDescriptionValidation();
  }, [
    category,
    categoryFocus,
    categoryErrorMessage,
    issueDescription,
    issueDescriptionFocus,
    issueDescriptionErrorMessage,
  ]);

  return (
    <div id="support-page">
      <div className="max-width-container">
        <UtilityDesktopNavbar leftButton={"back"} />

        <div className="padding-container">
          <div className="form-container">
            <TextField
              className="form-field custom-text-field"
              select
              label={t("settings.support_form.select_category")}
              value={category}
              error={categoryError}
              onChange={onCategoryChange}
              variant="standard"
              onFocus={onCategoryFocus}
            >
              {getAioData?.payload?.dropdown?.support?.categories?.map(
                (report, index) => (
                  <MenuItem value={report?.value} key={index}>
                    {t(report?.name)}
                  </MenuItem>
                )
              )}
            </TextField>
          </div>

          <div className="more-description">
            {t("settings.support_form.tell_us_more")}
          </div>

          {showLiveSubtitle && (
            <div className="live-subtitle">
              <Trans
                i18nKey={"settings.support_live_subtitle"}
                components={{
                  span: (
                    <span
                      className="socials-link"
                      onClick={onOpenSocialsLink}
                    />
                  ),
                }}
              />
            </div>
          )}

          <div className="form-container">
            <TextField
              className="form-field custom-text-field"
              placeholder={t("settings.support_form.tell_us_more_placeholder")}
              value={issueDescription}
              onChange={onIssueDescriptionChange}
              multiline
              maxRows={10}
              variant="standard"
              onFocus={onIssueDescriptionFocus}
            />
          </div>

          <div className="additional-info-label">
            {t("settings.support_form.additional_info")}
          </div>

          <Button
            className="upload-screenshot-container"
            disableRipple
            component="label"
          >
            {imageData?.image ? (
              <div className="preview-container">
                <img
                  className="upload-screenshot-image"
                  src={imageData?.image}
                  alt="upload-screenshot"
                />

                <div className="upload-screenshot-details-container">
                  <div className="upload-screenshot-name">
                    {imageData?.file?.name}
                  </div>
                  <div className="upload-screenshot-size">
                    {(imageData?.file?.size / 1024).toFixed(0)} KB
                  </div>
                </div>

                <CloseIcon className="close-icon" onClick={onRemoveImage} />
              </div>
            ) : (
              <div className="upload-screenshot-label">
                <input
                  hidden
                  accept="image/*, video/*"
                  type="file"
                  onChange={onFilePicker}
                />
                <UploadIcon className="upload-icon" />
                <div className="upload-screenshot-text">
                  {t("settings.support_form.upload_screenshot")}
                </div>
              </div>
            )}
          </Button>

          <Button
            className={`send-button ${isFormValid() ? "" : "disabled-button"}`}
            variant="text"
            onClick={onSendInformation}
            disabled={!isFormValid()}
          >
            {sendSupportTicketLoading ? (
              <Spinner size={24} isPadding={false} color={"white-spinner"} />
            ) : (
              t("common.send")
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Support;
