// General
import "./emoji-overlay.scss";
import { useEffect, useRef } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { resetLivestreamFloatingEmoji } from "../../../../redux/store/livestreamingStore";
// Custom Hooks
import AssetManager from "../../../utility/manager/asset-manager/asset-manager";

const EmojiOverlay = () => {
  // General variables
  const emojiOverlayRef = useRef(null);

  // Redux variables
  const livestreamFloatingEmoji = useSelector(
    (state) => state.livestreaming.livestreamFloatingEmoji
  );
  const videoCallFloatingEmoji = useSelector(
    (state) => state.privateCall.videoCallFloatingEmoji
  );
  const dispatch = useDispatch();

  // Custom Hooks Functions
  const getAsset = AssetManager();

  // Lifecycle | Unmounted
  useEffect(() => {
    return () => {
      dispatch(resetLivestreamFloatingEmoji());
    };
  }, []);

  // Lifecycle | Check for update | Livestream Floating Emoji
  useEffect(() => {
    if (livestreamFloatingEmoji) {
      animateFloatingEmoji(livestreamFloatingEmoji?.data?.emoji_id);
    }
  }, [livestreamFloatingEmoji]);

  // Lifecycle | Check for update | Video Call Floating Emoji
  useEffect(() => {
    if (videoCallFloatingEmoji) {
      animateFloatingEmoji(videoCallFloatingEmoji?.emoji);
    }
  }, [videoCallFloatingEmoji]);

  // Utility Functions
  const animateFloatingEmoji = (emoji) => {
    let emojiElementLimit = 15;
    let wrapper = emojiOverlayRef.current;

    if (wrapper.childElementCount < emojiElementLimit) {
      // Trigger animation
      let animated = document.createElement("div");
      if (animated) {
        animated.classList.add("emoji-positioning");

        let randomSelection = Math.floor(Math.random() * 5) + 1;
        let animateClass = "animate-fly-up-" + randomSelection;

        let emojiAsset;

        switch (emoji) {
          case "face_blowing_a_kiss":
            emojiAsset = getAsset("blowKissEmoji");
            break;
          case "smiling_face_with_heart_eyes":
            emojiAsset = getAsset("heartEyesEmoji");
            break;
          case "c_thumbs_up":
            emojiAsset = getAsset("thumbsUpEmoji");
            break;
          case "fire":
            emojiAsset = getAsset("fireEmoji");
            break;
          case "coin":
            emojiAsset = getAsset("sbCoinIcon");
          default:
            break;
        }

        animated.style.setProperty(
          "--content-data",
          "url('" + emojiAsset + "')"
        );
        animated.classList.add(animateClass);
        wrapper.appendChild(animated);

        setTimeout(function () {
          animated.remove();
        }, 2200);
      }
    }
  };

  return <div id="emoji-overlay" ref={emojiOverlayRef}></div>;
};

export default EmojiOverlay;
