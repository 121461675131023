// General
import "./feed.scss";
import { useEffect } from "react";
// Services
import { useLazyGetProfileQuery } from "../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
// react-gtm-module
import TagManager from "react-gtm-module";
// Custom Hooks
import IconManager from "../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../shared/elements/custom-avatar/custom-avatar";

const Feed = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-15",
      },
    });

    getProfile(null, true);
  }, []);

  return (
    <div id="feed-page">
      <div className="own-user-post-container">
        <div className="own-profile-picture-container">
          <CustomAvatar
            className="own-profile-picture"
            src={getProfileData?.data?.profile_photo?.original_photo}
            role={getProfileData?.data?.role}
          />
        </div>

        <div className="own-post-container">What's on your mind?</div>

        <div className="post-button">Post</div>
      </div>

      <div className="feeds-container">
        <div className="feed-container">
          <div className="user-details-container">
            <div className="profile-picture-container">
              <CustomAvatar />
            </div>

            <div className="name-location-container">
              <div className="username">TashaXx</div>

              <div className="location">11m ago, Taipei</div>
            </div>
          </div>

          <div className="post-description-container"></div>

          <div className="post-picture-container">
            <img className="post-picture" alt="post-picture" />
          </div>

          <div className="post-action-container">
            <div className="post-action-left-container">
              <div className="like-post-icon-container">
                {getIcon("feedLikeIcon", "like-icon")}
              </div>

              <div className="send-tip-container">
                <div className="send-tip-icon-container">
                  {getIcon("feedSendTipIcon", "send-tip-icon")}
                </div>

                <div className="send-tip-label">Send Tip</div>
              </div>
            </div>

            <div className="post-action-right-container">
              <div className="message-icon-container">
                {getIcon("feedMessageIcon", "message-icon")}
              </div>
            </div>
          </div>

          <div className="likes-tip-container">
            <div className="likes">785 likes</div>

            <div className="circle-separator"></div>

            <div className="tips">$768 tips</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feed;
