// General
import "./restart-journey-dialog.scss";
import { useEffect, forwardRef } from "react";
// Services
import {
  useLazyGetProfileQuery,
  useLazyGetJourneyQuestionsQuery,
  useLazyRestartJourneyQuery,
  useLazyGetProfileCompletionQuery,
} from "../../../../services/data.service";
import { sessionService } from "../../../../services/session.service";
// Static Data
import user from "../../../../enums/user";
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateUsername,
  updateBirthdate,
  updateRegistrationActiveTab,
  updateAnnualIncomeIndex,
  updateAnnualIncome,
  updateNetworthIndex,
  updateNetworth,
  updateLifestyle,
  updateDatingBudget,
  updateRelationshipStatus,
  updatePosition,
  updateIndustry,
  updateDatingStyles,
  updateEthnicity,
  updateRace,
  updatePublicFantasies,
  updatePrivateFantasies,
  updateSmoking,
  updateDrinking,
  updateAlcoholPreference,
  updateAboutMeQuestions,
  updateAboutMeState,
  updateAboutMeDisableState,
  updateJoinLivestreamCommunity,
  updateLookingForAgeRangeMax,
  updateLookingForAgeRangeMin,
  updateLookingForLocation,
  updateLookingForEthnicity,
  updateRegistrationNavigationData,
  updateAboutMeList,
  updateAboutMeLength,
  updateSelectedAboutMe,
  updateJourneyNavigationPassthrough,
  updateJourneyDataPassthrough,
  updateSkippableJourney,
  updateAboutMeManualText,
} from "../../../../redux/store/registrationStore";
import {
  updateGender,
  updateWantToBe,
} from "../../../../redux/store/signupStore";
import { resetRestartJourneyCounter } from "../../../../redux/store/searchStore";
import { updateErrorToast } from "../../../../redux/store/toastStore";
import {
  updateUserProfileDialog,
  updateRestartRegistrationDialog,
} from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide, CircularProgress, useMediaQuery } from "@mui/material";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Moment
import moment from "moment";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import Spinner from "../../elements/spinner/spinner";
import CustomAvatar from "../../elements/custom-avatar/custom-avatar";
import userConst from "../../../../const/userConst";

const RestartJourneyDialog = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    getJourneyQuestions,
    {
      data: getJourneyQuestionsData,
      error: getJourneyQuestionsErrorData,
      isFetching: getJourneyQuestionsFetching,
      isLoading: getJourneyQuestionsLoading,
      isSuccess: getJourneyQuestionsSuccess,
      isError: getJourneyQuestionsError,
    },
  ] = useLazyGetJourneyQuestionsQuery();
  const [
    restartJourney,
    {
      data: restartJourneyData,
      error: restartJourneyErrorData,
      isFetching: restartJourneyFetching,
      isLoading: restartJourneyLoading,
      isSuccess: restartJourneySuccess,
      isError: restartJourneyError,
    },
  ] = useLazyRestartJourneyQuery();
  const [
    getProfileCompletion,
    {
      data: getProfileCompletionData,
      error: getProfileCompletionErrorData,
      isFetching: getProfileCompletionFetching,
      isLoading: getProfileCompletionLoading,
      isSuccess: getProfileCompletionSuccess,
      isError: getProfileCompletionError,
    },
  ] = useLazyGetProfileCompletionQuery();

  // Redux variables
  const restartRegistrationDialog = useSelector(
    (state) => state.dialog.restartRegistrationDialog
  );
  const registrationNavigationData = useSelector(
    (state) => state.registration.registrationNavigationData
  );
  const restartJourneyCounter = useSelector(
    (state) => state.search.restartJourneyCounter
  );
  const profileCompletionPercentage = useSelector(
    (state) => state.profile.profileCompletionPercentage
  );
  const profileRequirementPopup = useSelector(
    (state) => state.profile.profileRequirementPopup
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    if (restartRegistrationDialog) {
      getProfile(null, false);
    }
  }, [restartRegistrationDialog]);

  // Lifecycle | Check for update | getProfile API Response
  useEffect(() => {
    if (getProfileFetching || getProfileLoading) {
    } else if (getProfileSuccess) {
      if (getProfileData?.status === 1) {
        const aboutMeManualTextStatus =
          getProfileData?.data?.profile_details_approval?.aboutme?.approval;
        const aboutMeManualText = getProfileData?.data?.aboutme || "";
        const aboutMeManualTextNewContent =
          getProfileData?.data?.profile_details_approval?.aboutme
            ?.new_content || "";

        dispatch(
          updateAboutMeManualText(
            aboutMeManualTextStatus ===
              userConst.profileGeneralApprovalStatus.inReview ||
              aboutMeManualTextStatus ===
                userConst.profileGeneralApprovalStatus.rejected
              ? aboutMeManualTextNewContent
              : aboutMeManualText
          )
        );
        getProfileCompletion(null, false);
      }
    } else if (getProfileError) {
    }
  }, [
    getProfileFetching,
    getProfileLoading,
    getProfileSuccess,
    getProfileError,
  ]);

  // Lifecycle | Check for update | getJourneyQuestions API Response
  useEffect(() => {
    if (getJourneyQuestionsFetching || getJourneyQuestionsLoading) {
    } else if (getJourneyQuestionsSuccess) {
      restartJourney(null, false);
    } else {
    }
  }, [
    getJourneyQuestionsFetching,
    getJourneyQuestionsLoading,
    getJourneyQuestionsSuccess,
    getJourneyQuestionsError,
  ]);

  // Lifecycle | Check for update | restartJourney API Response
  useEffect(() => {
    if (restartJourneyFetching || restartJourneyLoading) {
    } else if (restartJourneySuccess) {
      if (restartJourneyData?.status === -1) {
        const errorToast = {
          message: restartJourneyData?.message,
          autoClose: 3000,
        };
        dispatch(updateErrorToast(errorToast));
      } else {
        dispatch(updateSkippableJourney(false));

        let annualIncomeExist = false;
        let networthExist = false;
        let lifestyleExist = false;
        let relationshipStatusExist = false;
        let positionExist = false;
        let industryExist = false;
        let datingStylesExist = false;
        let bedroomFantasiesExist = false;
        let ethnicityExist = false;
        let raceExist = false;
        let smokingExist = false;
        let drinkingExist = false;
        let alcoholPreferenceExist = false;
        let aboutMeExist = false;
        let lookingForExist = false;
        let livestreamExist = false;

        // Annual Income
        if (
          restartJourneyData?.user_journey?.journey_data?.profile?.annual_income
        ) {
          annualIncomeExist = true;

          const getAnnualIncomeData = restartJourneyData?.selections?.find(
            (item) => item?.category === "annual_income"
          )?.selections;
          const annualIncomeId =
            restartJourneyData?.user_journey?.journey_data?.profile
              ?.annual_income;
          const annualIncome = getAnnualIncomeData?.find(
            (item) => item?.id === annualIncomeId
          );
          dispatch(updateAnnualIncome(annualIncome?.id));
          const index = getAnnualIncomeData?.findIndex(
            (item) => item?.id === annualIncomeId
          );
          if (index !== -1) {
            dispatch(updateAnnualIncomeIndex(index + 2));
          }
        }

        // Networth
        if (restartJourneyData?.user_journey?.journey_data?.profile?.networth) {
          networthExist = true;

          const getNetworthData = restartJourneyData?.selections?.find(
            (item) => item?.category === "networth"
          )?.selections;
          const networthId =
            restartJourneyData?.user_journey?.journey_data?.profile?.networth;
          const networth = getNetworthData?.find(
            (item) => item?.id === networthId
          );
          dispatch(updateNetworth(networth?.id));
          const index = getNetworthData?.findIndex(
            (item) => item?.id === networthId
          );
          if (index !== -1) {
            dispatch(updateNetworthIndex(index + 2));
          }
        }

        // Lifestyle
        if (
          restartJourneyData?.user_journey?.journey_data?.profile?.lifestyle
        ) {
          lifestyleExist = true;

          const getLifestyleData = restartJourneyData?.selections?.find(
            (item) => item?.category === "lifestyle"
          )?.selections;
          const lifestyleId =
            restartJourneyData?.user_journey?.journey_data?.profile?.lifestyle;
          const lifestyle = getLifestyleData?.find(
            (item) => item?.id === lifestyleId
          );
          dispatch(updateLifestyle(lifestyle));
        }

        // Dating Budget
        if (
          restartJourneyData?.user_journey?.journey_data?.profile?.dating_budget
        ) {
          lifestyleExist = true;

          const getDatingBudgetData = restartJourneyData?.selections?.find(
            (item) => item?.category === "dating_budget"
          )?.selections;
          const datingBudgetId =
            restartJourneyData?.user_journey?.journey_data?.profile
              ?.dating_budget;
          const datingBudget = getDatingBudgetData?.find(
            (item) => item?.id === datingBudgetId
          );
          dispatch(updateDatingBudget(datingBudget));
        }

        // Relationship Status
        if (
          restartJourneyData?.user_journey?.journey_data?.profile?.relationship
        ) {
          relationshipStatusExist = true;

          const getRelationshipStatusData =
            restartJourneyData?.selections?.find(
              (item) => item?.category === "relationship"
            )?.selections;

          const relatiionshipStatusId =
            restartJourneyData?.user_journey?.journey_data?.profile
              ?.relationship;
          const relationshipStatus = getRelationshipStatusData?.find(
            (item) => item?.id === relatiionshipStatusId
          );
          dispatch(updateRelationshipStatus(relationshipStatus));
        }

        // Position
        if (restartJourneyData?.user_journey?.journey_data?.profile?.position) {
          positionExist = true;

          const getPositionData = restartJourneyData?.selections?.find(
            (item) => item?.category === "position"
          )?.selections;

          const positionId =
            restartJourneyData?.user_journey?.journey_data?.profile?.position;
          const position = getPositionData?.find(
            (item) => item?.id === positionId
          );
          dispatch(updatePosition(position));
        }

        // Industry
        if (restartJourneyData?.user_journey?.journey_data?.profile?.industry) {
          industryExist = true;

          const getIndustryData = restartJourneyData?.selections?.find(
            (item) => item?.category === "industry"
          )?.selections;

          const industryId =
            restartJourneyData?.user_journey?.journey_data?.profile?.industry;
          const industry = getIndustryData?.find(
            (item) => item?.id === industryId
          );
          dispatch(updateIndustry(industry));
        }

        // Dating Styles
        if (
          restartJourneyData?.user_journey?.journey_data?.profile
            ?.dating_style &&
          restartJourneyData?.user_journey?.journey_data?.profile?.dating_style
            ?.length > 0
        ) {
          datingStylesExist = true;

          const getDatingStylesData = restartJourneyData?.selections?.find(
            (item) => item?.category === "dating_style"
          )?.selections;

          const datingStyleIds =
            restartJourneyData?.user_journey?.journey_data?.profile
              ?.dating_style;
          const datingStyles = getDatingStylesData?.filter((item) =>
            datingStyleIds?.includes(item?.id)
          );
          dispatch(updateDatingStyles(datingStyles));
        }

        // Bedroom Fantasies
        if (
          restartJourneyData?.user_journey?.journey_data?.profile?.br_fantasy
        ) {
          bedroomFantasiesExist = true;

          const getBedroomFantasiesData = restartJourneyData?.selections?.find(
            (item) => item?.category === "br_fantasy"
          )?.selections;

          const bedroomFantasiesIds =
            restartJourneyData?.user_journey?.journey_data?.profile?.br_fantasy;
          const bedroomFantasiesPrivateIds =
            restartJourneyData?.user_journey?.journey_data?.profile
              ?.br_fantasy_private;
          const bedroomFantasiesPublic = getBedroomFantasiesData?.filter(
            (item) => bedroomFantasiesIds?.includes(item?.id)
          );
          const bedroomFantasiesPrivate = getBedroomFantasiesData?.filter(
            (item) => bedroomFantasiesPrivateIds?.includes(item?.id)
          );
          dispatch(updatePublicFantasies(bedroomFantasiesPublic));
          dispatch(updatePrivateFantasies(bedroomFantasiesPrivate));
        }

        // Ethnicity
        if (
          restartJourneyData?.user_journey?.journey_data?.profile?.ethnicity
        ) {
          ethnicityExist = true;

          const getEthnicityData = restartJourneyData?.selections?.find(
            (item) => item?.category === "ethnicity"
          )?.selections;

          const ethnicityId =
            restartJourneyData?.user_journey?.journey_data?.profile?.ethnicity;
          const ethnicity = getEthnicityData?.find(
            (item) => item?.id === ethnicityId
          );
          dispatch(updateEthnicity(ethnicity));
        }

        // Race
        if (restartJourneyData?.user_journey?.journey_data?.profile?.race) {
          raceExist = true;

          const getRaceData = restartJourneyData?.selections?.find(
            (item) => item?.category === "race"
          )?.selections;

          const raceId =
            restartJourneyData?.user_journey?.journey_data?.profile?.race;
          const race = getRaceData?.find((item) => item?.id === raceId);
          dispatch(updateRace(race));
        }

        // Smoking
        if (restartJourneyData?.user_journey?.journey_data?.profile?.smoking) {
          smokingExist = true;

          const getSmokingData = restartJourneyData?.selections?.find(
            (item) => item?.category === "smoking"
          )?.selections;

          const smokingId =
            restartJourneyData?.user_journey?.journey_data?.profile?.smoking;
          const smoking = getSmokingData?.find(
            (item) => item?.id === smokingId
          );
          dispatch(updateSmoking(smoking));
        }

        // Drinking
        if (restartJourneyData?.user_journey?.journey_data?.profile?.drinking) {
          drinkingExist = true;

          const getDrinkingData = restartJourneyData?.selections?.find(
            (item) => item?.category === "drinking"
          )?.selections;

          const drinkingId =
            restartJourneyData?.user_journey?.journey_data?.profile?.drinking;
          const drinking = getDrinkingData?.find(
            (item) => item?.id === drinkingId
          );
          dispatch(updateDrinking(drinking));
        }

        // Alcohol Preference
        if (restartJourneyData?.user_journey?.journey_data?.profile?.alcohol) {
          alcoholPreferenceExist = true;

          const getAlcoholPreferenceData = restartJourneyData?.selections?.find(
            (item) => item?.category === "alcohol"
          )?.selections;

          const alcoholPreferenceIds =
            restartJourneyData?.user_journey?.journey_data?.profile?.alcohol;
          const alcoholPreference = getAlcoholPreferenceData?.filter((item) =>
            alcoholPreferenceIds?.includes(item?.id)
          );
          dispatch(updateAlcoholPreference(alcoholPreference));
        }

        // About Me
        const personalityTraits =
          restartJourneyData?.user_journey?.journey_data?.profile
            ?.personality_traits;
        const personalityType =
          restartJourneyData?.user_journey?.journey_data?.profile
            ?.personality_type?.length > 0
            ? restartJourneyData?.user_journey?.journey_data?.profile
                ?.personality_type
            : null;
        const starSign =
          restartJourneyData?.user_journey?.journey_data?.profile?.star_sign;
        const dominantOrSubmissive =
          restartJourneyData?.user_journey?.journey_data?.profile
            ?.dominant_or_submissive;
        const fitnessLevel =
          restartJourneyData?.user_journey?.journey_data?.profile
            ?.fitness_level;
        const hobbies =
          restartJourneyData?.user_journey?.journey_data?.profile?.hobbies
            ?.length > 0
            ? restartJourneyData?.user_journey?.journey_data?.profile?.hobbies
            : null;

        let tempAboutMeList = [];
        let tempAboutMeLength = 0;
        let tempSelectedAboutMe = [];

        const pushAboutMe = (name, data) => {
          tempAboutMeList?.push({ name, data });
          if (data) {
            tempAboutMeLength++;
            tempSelectedAboutMe?.push({ name, data });
          }
        };

        pushAboutMe(
          "personality_type",
          personalityType?.map((item) =>
            getAboutMeRemap("personality_type", item)
          )
        );
        pushAboutMe(
          "hobbies",
          hobbies?.map((item) => getAboutMeRemap("hobbies", item))
        );
        pushAboutMe("star_sign", getAboutMeRemap("star_sign", starSign));
        pushAboutMe(
          "fitness_level",
          getAboutMeRemap("fitness_level", fitnessLevel)
        );
        pushAboutMe(
          "dominant_or_submissive",
          getAboutMeRemap("dominant_or_submissive", dominantOrSubmissive)
        );
        pushAboutMe(
          "personality_traits",
          getAboutMeRemap("personality_traits", personalityTraits)
        );

        // If tempSelectedAboutMe has less than 3 items, add empty items
        if (tempSelectedAboutMe?.length < 3) {
          const emptyItems = 3 - tempSelectedAboutMe?.length;
          for (let i = 0; i < emptyItems; i++) {
            tempSelectedAboutMe.push({ name: null, data: null });
          }
        }

        dispatch(updateAboutMeList(tempAboutMeList));
        dispatch(updateAboutMeLength(tempAboutMeLength));
        dispatch(updateSelectedAboutMe(tempSelectedAboutMe));

        if (tempAboutMeLength > 0) {
          aboutMeExist = true;
        }

        let lookingForStateCounter = 0;
        // Looking For | Age Range
        if (
          restartJourneyData?.user_journey?.journey_data?.looking_for?.age_max
        ) {
          lookingForStateCounter++;

          dispatch(
            updateLookingForAgeRangeMax(
              restartJourneyData?.user_journey?.journey_data?.looking_for
                ?.age_max
            )
          );
          dispatch(
            updateLookingForAgeRangeMin(
              restartJourneyData?.user_journey?.journey_data?.looking_for
                ?.age_min
            )
          );
        }
        // Looking For | Locations
        if (
          restartJourneyData?.user_journey?.journey_data?.looking_for?.locations
            ?.length > 0
        ) {
          lookingForStateCounter++;

          dispatch(
            updateLookingForLocation(
              restartJourneyData?.user_journey?.journey_data?.looking_for
                ?.locations
            )
          );
        }
        // Looking For | Ethnicities
        if (
          restartJourneyData?.user_journey?.journey_data?.looking_for
            ?.ethnicities?.length > 0
        ) {
          lookingForStateCounter++;

          const getEthnicityData = restartJourneyData?.selections?.find(
            (item) => item?.category === "ethnicity"
          )?.selections;
          const getRaceData = restartJourneyData?.selections?.find(
            (item) => item?.category === "race"
          )?.selections;

          const organizedData =
            restartJourneyData?.user_journey?.journey_data?.looking_for?.ethnicities?.map(
              (ethnicityRace) => {
                const ethnicityId = ethnicityRace?.ethnicity;
                const raceId = ethnicityRace?.race;

                const ethnicity = getEthnicityData?.find(
                  (item) => item?.id === ethnicityId
                );
                const race = getRaceData?.find((item) => item?.id === raceId);

                return { ethnicity, race };
              }
            );

          dispatch(updateLookingForEthnicity(organizedData));
        }

        if (lookingForStateCounter === 3) {
          lookingForExist = true;
        }

        // Livestream
        dispatch(
          updateGender(restartJourneyData?.user_journey?.journey_data?.gender)
        );
        dispatch(
          updateWantToBe(
            restartJourneyData?.user_journey?.journey_data?.wanttobe
          )
        );
        if (
          restartJourneyData?.user_journey?.journey_data?.gender ===
          user.gender.MAN
        ) {
          if (
            restartJourneyData?.user_journey?.journey_data?.livestream
              ?.date_streamer
          ) {
            livestreamExist = true;

            const getDateStreamerData = restartJourneyData?.selections?.find(
              (item) => item?.category === "date_streamer"
            )?.selections;
            const joinLivestreamCommunityIds =
              restartJourneyData?.user_journey?.journey_data?.livestream
                ?.date_streamer;
            const joinLivestreamCommunity = getDateStreamerData?.find(
              (item) => item?.id === joinLivestreamCommunityIds
            );
            dispatch(updateJoinLivestreamCommunity(joinLivestreamCommunity));
          }
        } else if (
          restartJourneyData?.user_journey?.journey_data?.gender ===
          user.gender.WOMAN
        ) {
          if (
            restartJourneyData?.user_journey?.journey_data?.livestream
              ?.join_livestream
          ) {
            livestreamExist = true;

            const getJoinLivestreamData = restartJourneyData?.selections?.find(
              (item) => item?.category === "join_livestream"
            )?.selections;
            const joinLivestreamCommunityIds =
              restartJourneyData?.user_journey?.journey_data?.livestream
                ?.join_livestream;
            const joinLivestreamCommunity = getJoinLivestreamData?.find(
              (item) => item?.id === joinLivestreamCommunityIds
            );
            dispatch(updateJoinLivestreamCommunity(joinLivestreamCommunity));
          }
        }

        // Update Registration Navigation Data
        let temp = registrationNavigationData.map((item) => {
          if (item?.id === 1 && annualIncomeExist) {
            return {
              ...item,
              isVisible: true,
              isClickable: true,
            };
          } else if (item?.id === 2 && networthExist) {
            return {
              ...item,
              isVisible: true,
              isClickable: true,
            };
          } else if (item?.id === 3 && lifestyleExist) {
            return {
              ...item,
              isVisible: true,
              isClickable: true,
            };
          } else if (item?.id === 4 && relationshipStatusExist) {
            return {
              ...item,
              isVisible: true,
              isClickable: true,
            };
          } else if (item?.id === 5 && datingStylesExist) {
            return {
              ...item,
              isVisible: true,
              isClickable: true,
            };
          } else if (
            item?.id === 6 &&
            restartJourneyData?.user_journey?.journey_data?.gender ===
              user.gender.MAN &&
            positionExist &&
            industryExist
          ) {
            return {
              ...item,
              isVisible: true,
              isClickable: true,
            };
          } else if (
            item?.id === 6 &&
            restartJourneyData?.user_journey?.journey_data?.gender ===
              user.gender.WOMAN &&
            positionExist
          ) {
            return {
              ...item,
              isVisible: true,
              isClickable: true,
            };
          } else if (item?.id === 7 && ethnicityExist) {
            return {
              ...item,
              isVisible: true,
              isClickable: true,
            };
          } else if (item?.id === 8 && raceExist) {
            return {
              ...item,
              isVisible: true,
              isClickable: true,
            };
          } else if (item?.id === 9 && smokingExist) {
            return {
              ...item,
              isVisible: true,
              isClickable: true,
            };
          } else if (item?.id === 10 && drinkingExist) {
            return {
              ...item,
              isVisible: true,
              isClickable: true,
            };
          } else if (item?.id === 11 && alcoholPreferenceExist) {
            return {
              ...item,
              isVisible: true,
              isClickable: true,
            };
          } else if (item?.id === 12 && aboutMeExist) {
            return {
              ...item,
              isVisible: true,
              isClickable: true,
            };
          } else if (item?.id === 13 && lookingForExist) {
            return {
              ...item,
              isVisible: true,
              isClickable: true,
            };
          } else if (item?.id === 14 && livestreamExist) {
            return {
              ...item,
              isVisible: true,
              isClickable: true,
            };
          } else if (item?.id === 15) {
            return {
              ...item,
              isVisible: false,
              isClickable: false,
            };
          } else {
            return item;
          }
        });

        temp = temp?.map((item, i) => {
          return { ...item, skipped: false };
        });

        dispatch(updateRegistrationActiveTab(0));
        dispatch(updateRegistrationNavigationData(temp));

        let restartJourneyDataTemp = JSON.parse(
          JSON.stringify(restartJourneyData)
        );
        restartJourneyDataTemp.user_journey.journey_step = "0";
        dispatch(updateJourneyDataPassthrough(restartJourneyDataTemp));
        dispatch(updateJourneyNavigationPassthrough({}));

        dispatch(updateUserProfileDialog(false));
        onCloseDialog();
      }
    } else if (restartJourneyError) {
    }
  }, [
    restartJourneyFetching,
    restartJourneyLoading,
    restartJourneySuccess,
    restartJourneyError,
  ]);

  // Lifecycle | Check for update | restartJourneyCounter
  useEffect(() => {
    const lastShown = sessionService?.getRestartJourneyDialogLastShown();

    if (!lastShown || moment().diff(moment(lastShown), "days") > 0) {
      if (
        restartJourneyCounter >= 3 &&
        profileCompletionPercentage < profileRequirementPopup
      ) {
        TagManager.dataLayer({
          dataLayer: {
            event: "PWA-D38-38.2",
          },
        });

        dispatch(updateRestartRegistrationDialog(true));
      }
    }
  }, [restartJourneyCounter]);

  // Event Handlers | Button
  const onRestartJourney = () => {
    if (
      getProfileFetching ||
      getProfileLoading ||
      getJourneyQuestionsFetching ||
      getJourneyQuestionsLoading ||
      restartJourneyFetching ||
      restartJourneyLoading
    )
      return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-D38-38.1",
      },
    });

    getJourneyQuestions("restrartJourney", false);
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    sessionService?.setRestartJourneyDialogLastShown(moment().toISOString());

    dispatch(resetRestartJourneyCounter());
    dispatch(updateRestartRegistrationDialog(false));
  };

  // Utility Functions
  const getAboutMeRemap = (category, aboutMeId) => {
    const cat = getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === category
    )?.selections;

    const obj = cat?.find((data) => data?.id == aboutMeId);
    return obj;
  };

  return (
    <Dialog
      className={
        isMobile
          ? "custom-bottom-sheet-no-radius-fullscreen-dialog"
          : "custom-radius10-dialog"
      }
      fullScreen={isMobile ? true : false}
      // scroll={"body"}
      open={restartRegistrationDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      <div
        id="restart-journey-dialog"
        className={isMobile ? "" : "add-max-width"}
      >
        {/* <div className="dialog-header">
          <div className="left-container" onClick={onCloseDialog}>
            <ChevronLeftIcon className="back-icon" />
          </div>
          <div className="middle-container"></div>
          <div className="right-container"></div>
        </div> */}

        <div className="restart-journey-container">
          <div className="profile-photo-container">
            {profileCompletionPercentage < 100 && (
              <CircularProgress
                className="default-circular-progress-bar"
                variant="determinate"
                value={100}
              />
            )}
            {profileCompletionPercentage < 100 && (
              <CircularProgress
                className="circular-progress-bar"
                variant="determinate"
                value={profileCompletionPercentage || 0}
              />
            )}

            <CustomAvatar
              className={`profile-photo ${
                profileCompletionPercentage < 100
                  ? "profile-photo-absolute"
                  : ""
              }`}
              src={
                getProfileCompletionData?.data?.profile_photo?.photo_status !==
                "REJECTED"
                  ? getProfileCompletionErrorData?.data?.profile_photo
                      ?.original_photo
                  : null
              }
              role={getProfileCompletionData?.data?.role}
              profileBorder={
                getProfileCompletionData?.data?.profile_decorations
                  ?.profile_border_url
              }
            />

            {profileCompletionPercentage < 100 && (
              <div
                className="profile-completed-percentage-container"
                onClick={() => onNavigate(routeConst.profile.completion.path)}
              >
                {profileCompletionPercentage || 0}%
              </div>
            )}
          </div>

          <div className="restart-journey-title">
            <Trans
              i18nKey={"registration.restart.title"}
              components={{ br: <br /> }}
            />
          </div>

          <div className="restart-journey-description">
            {t("registration.restart.description")}
          </div>

          <div className="restart-journey-body-container">
            <div className="option-1-container">
              <div className="option-icon-container">
                {getIcon("seriousVibesIcon", "option-icon")}
              </div>

              <div className="option-label-container">
                <div className="option-title">
                  {t("registration.restart.option_1_title")}
                </div>
                <div className="option-description">
                  {t("registration.restart.option_1_description")}
                </div>
              </div>
            </div>

            <div className="option-2-container">
              <div className="option-icon-container">
                {getIcon("iceBreakerIcon", "option-icon")}
              </div>

              <div className="option-label-container">
                <div className="option-title">
                  {t("registration.restart.option_2_title")}
                </div>
                <div className="option-description">
                  {t("registration.restart.option_2_description")}
                </div>
              </div>
            </div>

            <div className="option-3-container">
              <div className="option-icon-container">
                {getIcon("standOutIcon", "option-icon")}
              </div>

              <div className="option-label-container">
                <div className="option-title">
                  {t("registration.restart.option_3_title")}
                </div>
                <div className="option-description">
                  {t("registration.restart.option_3_description")}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom-container">
          <div className="continue-button" onClick={onRestartJourney}>
            {getProfileFetching ||
            getProfileLoading ||
            getJourneyQuestionsFetching ||
            getJourneyQuestionsLoading ||
            restartJourneyFetching ||
            restartJourneyLoading ? (
              <Spinner size={19} isPadding={false} color={"white-spinner"} />
            ) : (
              t("registration.restart.complete_your_profile")
            )}
          </div>

          <div className="skip-button" onClick={onCloseDialog}>
            {t("registration.restart.skip_for_now")}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default RestartJourneyDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
