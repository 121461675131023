// General
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // Contest variables
  livestreamingContestId: null,
  livestreamingContestPkId: null,
  privateCallContestId: null,

  // Tabs variables
  leaderboardTab: 1,
  leaderboardTabValue: "private-call", // livestreaming // private-call
  livestreamingTab: 1,
  privateCallTab: 0,
  casinoTab: 0,
  casinoPeriodTab: 0,

  // Top Leaderboard variables
  leaderboardLists: [],
  leaderboardTitle: [],
  leaderboardPagination: null,

  // Livestreaming Leaderboard variables
  livestreamingTopStreamersTier1Event: [],
  livestreamingTopStreamersTier2Event: [],
  livestreamingTopStreamersTier3Event: [],
  livestreamingTopStreamersPkEvent: [],
  livestreamingTopStreamersDaily: [],
  livestreamingTopStreamersWeekly: [],
  livestreamingTopStreamersMonthly: [],
  livestreamingTopGiftersEvent: [],
  livestreamingTopGiftersPkEvent: [],
  livestreamingTopGiftersDaily: [],
  livestreamingTopGiftersWeekly: [],
  livestreamingTopGiftersMonthly: [],

  // Private Call Leaderboard variables
  privateCallTopStreamersEvent: [],
  privateCallTopStreamersWeekly: [],
  privateCallTopStreamersMonthly: [],
  privateCallTopGiftersEvent: [],
  privateCallTopGiftersWeekly: [],
  privateCallTopGiftersMonthly: [],

  // Casino Leaderboard variables
  casinoTopPlayersDaily: [],
  casinoTopPlayersWeekly: [],
  casinoTopPlayersMonthly: [],
  casinoTopWagersDaily: [],
  casinoTopWagersWeekly: [],
  casinoTopWagersMonthly: [],

  // Top Leaderboard List Info
  topLeaderboardListInfo: null,

  // Utility variables
  leaderboardPullToRefreshPassthrough: {},
  otherUserId: null,
  otherUserInfo: null,
};

export const leaderboardSlice = createSlice({
  name: "leaderboard",
  initialState,
  reducers: {
    // Contest Functions
    updateLivestreamingContestId: (state, action) => {
      state.livestreamingContestId = action.payload;
    },
    updateLivestreamingContestPkId: (state, action) => {
      state.livestreamingContestPkId = action.payload;
    },
    updatePrivateCallContestId: (state, action) => {
      state.privateCallContestId = action.payload;
    },
    clearLivestreamingContestId: (state) => {
      state.livestreamingContestId = null;
    },

    // Tabs Functions
    updateLeaderboardTab: (state, action) => {
      state.leaderboardTab = action.payload;
    },
    updateLeaderboardTabValue: (state, action) => {
      state.leaderboardTabValue = action.payload;
    },
    updateLivestreamingTab: (state, action) => {
      state.livestreamingTab = action.payload;
    },
    updatePrivateCallTab: (state, action) => {
      state.privateCallTab = action.payload;
    },
    updateCasinoTab: (state, action) => {
      state.casinoTab = action.payload;
    },
    updateCasinoPeriodTab: (state, action) => {
      state.casinoPeriodTab = action.payload;
    },

    // Top Leaderboard Functions
    updateLeaderboardLists: (state, action) => {
      const leaderboardListsData = [
        ...state.leaderboardLists,
        ...action.payload,
      ];

      state.leaderboardLists = leaderboardListsData;
    },
    resetLeaderboardLists: (state) => {
      state.leaderboardLists = [];
      state.leaderboardPagination = null;
    },
    updateLeaderboardTitle: (state, action) => {
      state.leaderboardTitle = action.payload;
    },
    updateLeaderboardPagination: (state, action) => {
      state.leaderboardPagination = action.payload;
    },

    // Livestreaming Leaderboard Functions
    updateLivestreamingTopStreamersTier1Event: (state, action) => {
      const livestreamingTopStreamersTier1EventData = [
        ...state.livestreamingTopStreamersTier1Event,
        ...action.payload,
      ];

      state.livestreamingTopStreamersTier1Event =
        livestreamingTopStreamersTier1EventData;
    },
    updateLivestreamingTopStreamersTier2Event: (state, action) => {
      const livestreamingTopStreamersTier2EventData = [
        ...state.livestreamingTopStreamersTier2Event,
        ...action.payload,
      ];

      state.livestreamingTopStreamersTier2Event =
        livestreamingTopStreamersTier2EventData;
    },
    updateLivestreamingTopStreamersTier3Event: (state, action) => {
      const livestreamingTopStreamersTier3EventData = [
        ...state.livestreamingTopStreamersTier3Event,
        ...action.payload,
      ];

      state.livestreamingTopStreamersTier3Event =
        livestreamingTopStreamersTier3EventData;
    },
    updateLivestreamingTopStreamersPkEvent: (state, action) => {
      const livestreamingTopStreamersPkEventData = [
        ...state.livestreamingTopStreamersPkEvent,
        ...action.payload,
      ];

      state.livestreamingTopStreamersPkEvent =
        livestreamingTopStreamersPkEventData;
    },
    updateLivestreamingTopStreamersDaily: (state, action) => {
      const livestreamingTopStreamersDailyData = [
        ...state.livestreamingTopStreamersDaily,
        ...action.payload,
      ];

      state.livestreamingTopStreamersDaily = livestreamingTopStreamersDailyData;
    },
    updateLivestreamingTopStreamersWeekly: (state, action) => {
      const livestreamingTopStreamersWeeklyData = [
        ...state.livestreamingTopStreamersWeekly,
        ...action.payload,
      ];

      state.livestreamingTopStreamersWeekly =
        livestreamingTopStreamersWeeklyData;
    },
    updateLivestreamingTopStreamersMonthly: (state, action) => {
      const livestreamingTopStreamersMonthlyData = [
        ...state.livestreamingTopStreamersMonthly,
        ...action.payload,
      ];

      state.livestreamingTopStreamersMonthly =
        livestreamingTopStreamersMonthlyData;
    },
    updateLivestreamingTopGiftersEvent: (state, action) => {
      const livestreamingTopGiftersEventData = [
        ...state.livestreamingTopGiftersEvent,
        ...action.payload,
      ];

      state.livestreamingTopGiftersEvent = livestreamingTopGiftersEventData;
    },
    updateLivestreamingTopGiftersPkEvent: (state, action) => {
      const livestreamingTopGiftersPkEventData = [
        ...state.livestreamingTopGiftersPkEvent,
        ...action.payload,
      ];

      state.livestreamingTopGiftersPkEvent = livestreamingTopGiftersPkEventData;
    },
    updateLivestreamingTopGiftersDaily: (state, action) => {
      const livestreamingTopGiftersDailyData = [
        ...state.livestreamingTopGiftersDaily,
        ...action.payload,
      ];

      state.livestreamingTopGiftersDaily = livestreamingTopGiftersDailyData;
    },
    updateLivestreamingTopGiftersWeekly: (state, action) => {
      const livestreamingTopGiftersWeeklyData = [
        ...state.livestreamingTopGiftersWeekly,
        ...action.payload,
      ];

      state.livestreamingTopGiftersWeekly = livestreamingTopGiftersWeeklyData;
    },
    updateLivestreamingTopGiftersMonthly: (state, action) => {
      const livestreamingTopGiftersMonthlyData = [
        ...state.livestreamingTopGiftersMonthly,
        ...action.payload,
      ];

      state.livestreamingTopGiftersMonthly = livestreamingTopGiftersMonthlyData;
    },
    resetLivestreamingTopStreamersTier1Event: (state) => {
      state.livestreamingTopStreamersTier1Event = [];
    },
    resetLivestreamingTopStreamersTier2Event: (state) => {
      state.livestreamingTopStreamersTier2Event = [];
    },
    resetLivestreamingTopStreamersTier3Event: (state) => {
      state.livestreamingTopStreamersTier3Event = [];
    },
    resetLivestreamingTopStreamersPkEvent: (state) => {
      state.livestreamingTopStreamersPkEvent = [];
    },
    resetLivestreamingTopStreamersDaily: (state) => {
      state.livestreamingTopStreamersDaily = [];
    },
    resetLivestreamingTopStreamersWeekly: (state) => {
      state.livestreamingTopStreamersWeekly = [];
    },
    resetLivestreamingTopStreamersMonthly: (state) => {
      state.livestreamingTopStreamersMonthly = [];
    },
    resetLivestreamingTopGiftersEvent: (state) => {
      state.livestreamingTopGiftersEvent = [];
    },
    resetLivestreamingTopGiftersPkEvent: (state) => {
      state.livestreamingTopGiftersPkEvent = [];
    },
    resetLivestreamingTopGiftersDaily: (state) => {
      state.livestreamingTopGiftersDaily = [];
    },
    resetLivestreamingTopGiftersWeekly: (state) => {
      state.livestreamingTopGiftersWeekly = [];
    },
    resetLivestreamingTopGiftersMonthly: (state) => {
      state.livestreamingTopGiftersMonthly = [];
    },

    // Private Call Leaderboard Functions
    updatePrivateCallTopStreamersEvent: (state, action) => {
      const privateCallTopStreamersEventData = [
        ...state.privateCallTopStreamersEvent,
        ...action.payload,
      ];

      state.privateCallTopStreamersEvent = privateCallTopStreamersEventData;
    },
    updatePrivateCallTopStreamersWeekly: (state, action) => {
      const privateCallTopStreamersWeeklyData = [
        ...state.privateCallTopStreamersWeekly,
        ...action.payload,
      ];

      state.privateCallTopStreamersWeekly = privateCallTopStreamersWeeklyData;
    },
    updatePrivateCallTopStreamersMonthly: (state, action) => {
      const privateCallTopStreamersMonthlyData = [
        ...state.privateCallTopStreamersMonthly,
        ...action.payload,
      ];

      state.privateCallTopStreamersMonthly = privateCallTopStreamersMonthlyData;
    },
    updatePrivateCallTopGiftersEvent: (state, action) => {
      const privateCallTopGiftersEventData = [
        ...state.privateCallTopGiftersEvent,
        ...action.payload,
      ];

      state.privateCallTopGiftersEvent = privateCallTopGiftersEventData;
    },
    updatePrivateCallTopGiftersWeekly: (state, action) => {
      const privateCallTopGiftersWeeklyData = [
        ...state.privateCallTopGiftersWeekly,
        ...action.payload,
      ];

      state.privateCallTopGiftersWeekly = privateCallTopGiftersWeeklyData;
    },
    updatePrivateCallTopGiftersMonthly: (state, action) => {
      const privateCallTopGiftersMonthlyData = [
        ...state.privateCallTopGiftersMonthly,
        ...action.payload,
      ];

      state.privateCallTopGiftersMonthly = privateCallTopGiftersMonthlyData;
    },
    resetPrivateCallTopStreamersEvent: (state) => {
      state.privateCallTopStreamersEvent = [];
    },
    resetPrivateCallTopStreamersWeekly: (state) => {
      state.privateCallTopStreamersWeekly = [];
    },
    resetPrivateCallTopStreamersMonthly: (state) => {
      state.privateCallTopStreamersMonthly = [];
    },
    resetPrivateCallTopGiftersEvent: (state) => {
      state.privateCallTopGiftersEvent = [];
    },
    resetPrivateCallTopGiftersWeekly: (state) => {
      state.privateCallTopGiftersWeekly = [];
    },
    resetPrivateCallTopGiftersMonthly: (state) => {
      state.privateCallTopGiftersMonthly = [];
    },

    // Casino Leaderboard Functions
    updateCasinoTopPlayersDaily: (state, action) => {
      const casinoTopPlayersDailyData = [
        ...state.casinoTopPlayersDaily,
        ...action.payload,
      ];

      state.casinoTopPlayersDaily = casinoTopPlayersDailyData;
    },
    updateCasinoTopPlayersWeekly: (state, action) => {
      const casinoTopPlayersWeeklyData = [
        ...state.casinoTopPlayersWeekly,
        ...action.payload,
      ];

      state.casinoTopPlayersWeekly = casinoTopPlayersWeeklyData;
    },
    updateCasinoTopPlayersMonthly: (state, action) => {
      const casinoTopPlayersMonthlyData = [
        ...state.casinoTopPlayersMonthly,
        ...action.payload,
      ];

      state.casinoTopPlayersMonthly = casinoTopPlayersMonthlyData;
    },
    updateCasinoTopWagersDaily: (state, action) => {
      const casinoTopWagersDailyData = [
        ...state.casinoTopWagersDaily,
        ...action.payload,
      ];

      state.casinoTopWagersDaily = casinoTopWagersDailyData;
    },
    updateCasinoTopWagersWeekly: (state, action) => {
      const casinoTopWagersWeeklyData = [
        ...state.casinoTopWagersWeekly,
        ...action.payload,
      ];

      state.casinoTopWagersWeekly = casinoTopWagersWeeklyData;
    },
    updateCasinoTopWagersMonthly: (state, action) => {
      const casinoTopWagersMonthlyData = [
        ...state.casinoTopWagersMonthly,
        ...action.payload,
      ];

      state.casinoTopWagersMonthly = casinoTopWagersMonthlyData;
    },
    resetCasinoTopPlayersDaily: (state) => {
      state.casinoTopPlayersDaily = [];
    },
    resetCasinoTopPlayersWeekly: (state) => {
      state.casinoTopPlayersWeekly = [];
    },
    resetCasinoTopPlayersMonthly: (state) => {
      state.casinoTopPlayersMonthly = [];
    },
    resetCasinoTopWagersDaily: (state) => {
      state.casinoTopWagersDaily = [];
    },
    resetCasinoTopWagersWeekly: (state) => {
      state.casinoTopWagersWeekly = [];
    },
    resetCasinoTopWagersMonthly: (state) => {
      state.casinoTopWagersMonthly = [];
    },

    // Top Leaderboard List Info Dialog
    updateTopLeaderboardListInfo: (state, action) => {
      state.topLeaderboardListInfo = action.payload;
    },

    // Utility Functions
    resetLeaderboardStore: () => {
      return { ...initialState };
    },
    updateLeaderboardPullToRefreshPassthrough: (state, action) => {
      state.leaderboardPullToRefreshPassthrough = action.payload;
    },
    updateOtherUserId: (state, action) => {
      state.otherUserId = action.payload;
    },
    updateOtherUserInfo: (state, action) => {
      state.otherUserInfo = action.payload;
    },
    updateFollowState: (state, action) => {
      const userId = action.payload.userId;
      const isFollowing = action.payload.isFollowing;

      let leaderboardListsArr = [...state.leaderboardLists];

      let livestreamingTopStreamersTier1EventArr = [
        ...state.livestreamingTopStreamersTier1Event,
      ];
      let livestreamingTopStreamersTier2EventArr = [
        ...state.livestreamingTopStreamersTier2Event,
      ];
      let livestreamingTopStreamersTier3EventArr = [
        ...state.livestreamingTopStreamersTier3Event,
      ];
      let livestreamingTopStreamersDailyArr = [
        ...state.livestreamingTopStreamersDaily,
      ];
      let livestreamingTopStreamersWeeklyArr = [
        ...state.livestreamingTopStreamersWeekly,
      ];
      let livestreamingTopStreamersMonthlyArr = [
        ...state.livestreamingTopStreamersMonthly,
      ];
      let livestreamingTopGiftersEventArr = [
        ...state.livestreamingTopGiftersEvent,
      ];
      let livestreamingTopGiftersDailyArr = [
        ...state.livestreamingTopGiftersDaily,
      ];
      let livestreamingTopGiftersWeeklyArr = [
        ...state.livestreamingTopGiftersWeekly,
      ];
      let livestreamingTopGiftersMonthlyArr = [
        ...state.livestreamingTopGiftersMonthly,
      ];
      let privateCallTopStreamersEventArr = [
        ...state.privateCallTopStreamersEvent,
      ];
      let privateCallTopStreamersWeeklyArr = [
        ...state.privateCallTopStreamersWeekly,
      ];
      let privateCallTopStreamersMonthlyArr = [
        ...state.privateCallTopStreamersMonthly,
      ];
      let privateCallTopGiftersEventArr = [...state.privateCallTopGiftersEvent];
      let privateCallTopGiftersWeeklyArr = [
        ...state.privateCallTopGiftersWeekly,
      ];
      let privateCallTopGiftersMonthlyArr = [
        ...state.privateCallTopGiftersMonthly,
      ];

      // Leaderboard Lists
      for (let i = 0; i < leaderboardListsArr.length; i++) {
        if (leaderboardListsArr[i].user.id === userId) {
          leaderboardListsArr[i].user.live_streaming_meta.is_following =
            isFollowing;
          break;
        }
      }

      // Livestreaming Top Streamers Tier 1 Event
      for (let i = 0; i < livestreamingTopStreamersTier1EventArr.length; i++) {
        if (livestreamingTopStreamersTier1EventArr[i].user.id === userId) {
          livestreamingTopStreamersTier1EventArr[
            i
          ].user.live_streaming_meta.is_following = isFollowing;
          break;
        }
      }

      // Livestreaming Top Streamers Tier 2 Event
      for (let i = 0; i < livestreamingTopStreamersTier2EventArr.length; i++) {
        if (livestreamingTopStreamersTier2EventArr[i].user.id === userId) {
          livestreamingTopStreamersTier2EventArr[
            i
          ].user.live_streaming_meta.is_following = isFollowing;
          break;
        }
      }

      // Livestreaming Top Streamers Tier 3 Event
      for (let i = 0; i < livestreamingTopStreamersTier3EventArr.length; i++) {
        if (livestreamingTopStreamersTier3EventArr[i].user.id === userId) {
          livestreamingTopStreamersTier3EventArr[
            i
          ].user.live_streaming_meta.is_following = isFollowing;
          break;
        }
      }

      // Livestreaming Top Streamers Daily
      for (let i = 0; i < livestreamingTopStreamersDailyArr.length; i++) {
        if (livestreamingTopStreamersDailyArr[i].user.id === userId) {
          livestreamingTopStreamersDailyArr[
            i
          ].user.live_streaming_meta.is_following = isFollowing;
          break;
        }
      }

      // Livestreaming Top Streamers Weekly
      for (let i = 0; i < livestreamingTopStreamersWeeklyArr.length; i++) {
        if (livestreamingTopStreamersWeeklyArr[i].user.id === userId) {
          livestreamingTopStreamersWeeklyArr[
            i
          ].user.live_streaming_meta.is_following = isFollowing;
          break;
        }
      }

      // Livestreaming Top Streamers Monthly
      for (let i = 0; i < livestreamingTopStreamersMonthlyArr.length; i++) {
        if (livestreamingTopStreamersMonthlyArr[i].user.id === userId) {
          livestreamingTopStreamersMonthlyArr[
            i
          ].user.live_streaming_meta.is_following = isFollowing;
          break;
        }
      }

      // Livestreaming Top Gifters Event
      for (let i = 0; i < livestreamingTopGiftersEventArr.length; i++) {
        if (livestreamingTopGiftersEventArr[i].user.id === userId) {
          livestreamingTopGiftersEventArr[
            i
          ].user.live_streaming_meta.is_following = isFollowing;
          break;
        }
      }

      // Livestreaming Top Gifters Daily
      for (let i = 0; i < livestreamingTopGiftersDailyArr.length; i++) {
        if (livestreamingTopGiftersDailyArr[i].user.id === userId) {
          livestreamingTopGiftersDailyArr[
            i
          ].user.live_streaming_meta.is_following = isFollowing;
          break;
        }
      }

      // Livestreaming Top Gifters Weekly
      for (let i = 0; i < livestreamingTopGiftersWeeklyArr.length; i++) {
        if (livestreamingTopGiftersWeeklyArr[i].user.id === userId) {
          livestreamingTopGiftersWeeklyArr[
            i
          ].user.live_streaming_meta.is_following = isFollowing;
          break;
        }
      }

      // Livestreaming Top Gifters Monthly
      for (let i = 0; i < livestreamingTopGiftersMonthlyArr.length; i++) {
        if (livestreamingTopGiftersMonthlyArr[i].user.id === userId) {
          livestreamingTopGiftersMonthlyArr[
            i
          ].user.live_streaming_meta.is_following = isFollowing;
          break;
        }
      }

      // Private Call Top Streamers Event
      for (let i = 0; i < privateCallTopStreamersEventArr.length; i++) {
        if (privateCallTopStreamersEventArr[i].user.id === userId) {
          privateCallTopStreamersEventArr[
            i
          ].user.live_streaming_meta.is_following = isFollowing;
          break;
        }
      }

      // Private Call Top Streamers Weekly
      for (let i = 0; i < privateCallTopStreamersWeeklyArr.length; i++) {
        if (privateCallTopStreamersWeeklyArr[i].user.id === userId) {
          privateCallTopStreamersWeeklyArr[
            i
          ].user.live_streaming_meta.is_following = isFollowing;
          break;
        }
      }

      // Private Call Top Streamers Monthly
      for (let i = 0; i < privateCallTopStreamersMonthlyArr.length; i++) {
        if (privateCallTopStreamersMonthlyArr[i].user.id === userId) {
          privateCallTopStreamersMonthlyArr[
            i
          ].user.live_streaming_meta.is_following = isFollowing;
          break;
        }
      }

      // Private Call Top Gifters Event
      for (let i = 0; i < privateCallTopGiftersEventArr.length; i++) {
        if (privateCallTopGiftersEventArr[i].user.id === userId) {
          privateCallTopGiftersEventArr[
            i
          ].user.live_streaming_meta.is_following = isFollowing;
          break;
        }
      }

      // Private Call Top Gifters Weekly
      for (let i = 0; i < privateCallTopGiftersWeeklyArr.length; i++) {
        if (privateCallTopGiftersWeeklyArr[i].user.id === userId) {
          privateCallTopGiftersWeeklyArr[
            i
          ].user.live_streaming_meta.is_following = isFollowing;
          break;
        }
      }

      // Private Call Top Gifters Monthly
      for (let i = 0; i < privateCallTopGiftersMonthlyArr.length; i++) {
        if (privateCallTopGiftersMonthlyArr[i].user.id === userId) {
          privateCallTopGiftersMonthlyArr[
            i
          ].user.live_streaming_meta.is_following = isFollowing;
          break;
        }
      }

      state.leaderboardLists = leaderboardListsArr;
      state.livestreamingTopStreamersTier1Event =
        livestreamingTopStreamersTier1EventArr;
      state.livestreamingTopStreamersTier2Event =
        livestreamingTopStreamersTier2EventArr;
      state.livestreamingTopStreamersTier3Event =
        livestreamingTopStreamersTier3EventArr;
      state.livestreamingTopStreamersDaily = livestreamingTopStreamersDailyArr;
      state.livestreamingTopStreamersWeekly =
        livestreamingTopStreamersWeeklyArr;
      state.livestreamingTopStreamersMonthly =
        livestreamingTopStreamersMonthlyArr;
      state.livestreamingTopGiftersEvent = livestreamingTopGiftersEventArr;
      state.livestreamingTopGiftersDaily = livestreamingTopGiftersDailyArr;
      state.livestreamingTopGiftersWeekly = livestreamingTopGiftersWeeklyArr;
      state.livestreamingTopGiftersMonthly = livestreamingTopGiftersMonthlyArr;
      state.privateCallTopStreamersEvent = privateCallTopStreamersEventArr;
      state.privateCallTopStreamersWeekly = privateCallTopStreamersWeeklyArr;
      state.privateCallTopStreamersMonthly = privateCallTopStreamersMonthlyArr;
      state.privateCallTopGiftersEvent = privateCallTopGiftersEventArr;
      state.privateCallTopGiftersWeekly = privateCallTopGiftersWeeklyArr;
      state.privateCallTopGiftersMonthly = privateCallTopGiftersMonthlyArr;
    },
  },
});

export const {
  // Contest Functions
  updateLivestreamingContestId,
  updateLivestreamingContestPkId,
  updatePrivateCallContestId,
  clearLivestreamingContestId,

  // Tabs Functions
  updateLeaderboardTab,
  updateLeaderboardTabValue,
  updateLivestreamingTab,
  updatePrivateCallTab,
  updateCasinoTab,
  updateCasinoPeriodTab,

  // Top Leaderboard Functions
  updateLeaderboardLists,
  resetLeaderboardLists,
  updateLeaderboardTitle,
  updateLeaderboardPagination,

  // Livestreaming Leaderboard Functions
  updateLivestreamingTopStreamersTier1Event,
  updateLivestreamingTopStreamersTier2Event,
  updateLivestreamingTopStreamersTier3Event,
  updateLivestreamingTopStreamersPkEvent,
  updateLivestreamingTopStreamersDaily,
  updateLivestreamingTopStreamersWeekly,
  updateLivestreamingTopStreamersMonthly,
  updateLivestreamingTopGiftersEvent,
  updateLivestreamingTopGiftersPkEvent,
  updateLivestreamingTopGiftersDaily,
  updateLivestreamingTopGiftersWeekly,
  updateLivestreamingTopGiftersMonthly,
  resetLivestreamingTopStreamersTier1Event,
  resetLivestreamingTopStreamersTier2Event,
  resetLivestreamingTopStreamersTier3Event,
  resetLivestreamingTopStreamersPkEvent,
  resetLivestreamingTopStreamersDaily,
  resetLivestreamingTopStreamersWeekly,
  resetLivestreamingTopStreamersMonthly,
  resetLivestreamingTopGiftersEvent,
  resetLivestreamingTopGiftersPkEvent,
  resetLivestreamingTopGiftersDaily,
  resetLivestreamingTopGiftersWeekly,
  resetLivestreamingTopGiftersMonthly,

  // Private Call Leaderboard Functions
  updatePrivateCallTopStreamersEvent,
  updatePrivateCallTopStreamersWeekly,
  updatePrivateCallTopStreamersMonthly,
  updatePrivateCallTopGiftersEvent,
  updatePrivateCallTopGiftersWeekly,
  updatePrivateCallTopGiftersMonthly,
  resetPrivateCallTopStreamersEvent,
  resetPrivateCallTopStreamersWeekly,
  resetPrivateCallTopStreamersMonthly,
  resetPrivateCallTopGiftersEvent,
  resetPrivateCallTopGiftersWeekly,
  resetPrivateCallTopGiftersMonthly,

  // Casino Leaderboard Functions
  updateCasinoTopPlayersDaily,
  updateCasinoTopPlayersWeekly,
  updateCasinoTopPlayersMonthly,
  updateCasinoTopWagersDaily,
  updateCasinoTopWagersWeekly,
  updateCasinoTopWagersMonthly,
  resetCasinoTopPlayersDaily,
  resetCasinoTopPlayersWeekly,
  resetCasinoTopPlayersMonthly,
  resetCasinoTopWagersDaily,
  resetCasinoTopWagersWeekly,
  resetCasinoTopWagersMonthly,

  // Top Leaderboard List Info
  updateTopLeaderboardListInfo,

  // Utility Functions
  resetLeaderboardStore,
  updateLeaderboardPullToRefreshPassthrough,
  updateOtherUserId,
  updateOtherUserInfo,
  updateFollowState,
} = leaderboardSlice.actions;

export default leaderboardSlice.reducer;
