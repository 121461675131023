// General
import "./tiers-info-dialog.scss";
import { useEffect, forwardRef } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateTierInfoDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide, Button } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

// TODO: Translation not done
const TiersInfoDialog = () => {
  // General variables
  let tiersList = [];

  // Redux variables
  const tierInfoDialog = useSelector((state) => state.dialog.tierInfoDialog);
  const contestList = useSelector((state) => state.livestreaming.contestList);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!tierInfoDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-TierInfo-Dialog",
      },
    });
  }, [tierInfoDialog]);

  // Event Handlers | Button
  const onCloseDialog = () => {
    dispatch(updateTierInfoDialog(false));
  };

  // Render Functions
  if (contestList?.results?.length > 0) {
    for (let i = 0; i < contestList?.results?.length; i++) {
      if (contestList?.results[i]?.tier_system === 3) {
        tiersList = contestList?.results[i]?.tiers;
      }
    }
  }

  return (
    <Dialog
      className="custom-bottom-sheet-dialog"
      fullScreen
      open={tierInfoDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      <div id="tiers-info-dialog">
        <div className="padding-container">
          <div className="header">What are Tiers?</div>

          <div className="text">
            A multi-tiered event offers a level playing field for every
            streamer. Now, you can compete with other streamers in the same
            category, with a better chance for you to win prizes.
          </div>

          {tiersList?.length > 0 &&
            tiersList?.map((tier, index) => (
              <div className="content-body" key={index}>
                <div className="badge-container">
                  {tier?.tier === 1
                    ? getIcon("tier1Badge", "badge")
                    : tier?.tier === 2
                    ? getIcon("tier2Badge", "badge")
                    : getIcon("tier3Badge", "badge")}
                </div>

                <div className="description-container">
                  <div className="subheader-container">
                    <div className="subheader">{tier?.name}</div>
                    {tier?.tier === 1 ? (
                      <div className="diamond-count-description">
                        ({tier?.amount_min.toLocaleString()}
                        <div className="diamond-container">
                          {getIcon("sbDiamondIcon", "diamond")}
                        </div>
                        and above)
                      </div>
                    ) : (
                      <div className="diamond-count-description">
                        ({tier?.amount_min?.toLocaleString()} -{" "}
                        {tier?.amount_max?.toLocaleString()}{" "}
                        <div className="diamond-container">
                          {getIcon("sbDiamondIcon", "diamond")}
                        </div>
                        )
                      </div>
                    )}
                  </div>

                  <div className="description">
                    Achieve a minimum of {tier?.amount_min?.toLocaleString()}
                    <span className="diamond-container">
                      {getIcon("sbDiamondIcon", "diamond")}
                    </span>
                    to qualify for the Tier {tier?.tier} price.
                  </div>
                </div>
              </div>
            ))}

          <Button
            className="close-button"
            variant="text"
            onClick={onCloseDialog}
          >
            {t("common.close")}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default TiersInfoDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
