// General
import "./edit-username.scss";
import { useState, useEffect } from "react";
// Services
import { useUpdateEditUserProfileMutation } from "../../../../../services/data.service";
import {
  noNumbersOrSpecialChars,
  removeHTMLEscape,
} from "../../../../../services/regex.service";
// Static Data
import userConst from "../../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateUsername,
  updateUsernameNewContent,
  updateUsernameStatus,
} from "../../../../../redux/store/editProfileStore";
import { updateUpdateProfileFullscreenDialog } from "../../../../../redux/store/dialogStore";
// Material UI
import { TextField } from "@mui/material";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Components
import Spinner from "../../../elements/spinner/spinner";

const EditUsername = () => {
  // API variables
  const [
    updateProfile,
    {
      data: updateProfileData,
      error: updateProfileErrorData,
      isLoading: updateProfileLoading,
      isSuccess: updateProfileSuccess,
      isError: updateProfileError,
    },
  ] = useUpdateEditUserProfileMutation();

  // General variables
  const [localUsername, setLocalUsername] = useState("");
  const [usernameErrorMessage, setUsernameErrorMessage] = useState("");
  const [usernameValid, setUsernameValid] = useState(false);
  const [usernameError, setUsernameError] = useState(false);

  // Redux variables
  const username = useSelector((state) => state.editProfile.username);
  const usernameNewContent = useSelector(
    (state) => state.editProfile.usernameNewContent
  );
  const usernameStatus = useSelector(
    (state) => state.editProfile.usernameStatus
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    setLocalUsername(returnUsernameValue());
  }, []);

  // Lifecycle | Check for update | updateProfile API Response
  useEffect(() => {
    if (updateProfileLoading) {
    } else if (updateProfileSuccess) {
      dispatch(updateUsername(updateProfileData?.data?.user?.username));
      dispatch(
        updateUsernameStatus(
          updateProfileData?.data?.user?.profile_details_approval?.username
            ?.approval
        )
      );
      dispatch(
        updateUsernameNewContent(
          updateProfileData?.data?.user?.profile_details_approval?.username
            ?.new_content
        )
      );
      dispatch(updateUpdateProfileFullscreenDialog(false));
    } else if (updateProfileError) {
    }
  }, [updateProfileLoading, updateProfileSuccess, updateProfileError]);

  // Event Handlers | Button
  const onSaveUsername = () => {
    if (!isFormValid()) return;

    const obj = {
      username: localUsername,
    };
    updateProfile(obj);
  };

  // Event Handlers | MUI Text Field
  const onInputChange = (state) => (event) => {
    switch (state) {
      case "username":
        setLocalUsername(event.target.value);
        break;
      default:
        break;
    }
  };

  // Utility Functions
  const isFormValid = () => {
    return localUsername && usernameValid && !updateProfileLoading;
  };
  const usernameValidation = () => {
    let valid = true;
    let error = false;

    if (localUsername === "") {
      setUsernameErrorMessage(t("signup.display_name_required"));
      valid = false;
      error = true;
    } else if (removeHTMLEscape.test(localUsername)) {
      setLocalUsername("");
      valid = false;
      error = true;
    } else {
      setUsernameErrorMessage("");
      valid = true;
      error = false;
    }

    setUsernameValid(valid);
    setUsernameError(error);
  };
  const returnUsernameValue = () => {
    if (
      usernameStatus === userConst.profileGeneralApprovalStatus.inReview ||
      usernameStatus === userConst.profileGeneralApprovalStatus.rejected
    ) {
      return usernameNewContent;
    } else {
      return username;
    }
  };

  // Check for email and password validation
  useEffect(() => {
    usernameValidation();
  }, [localUsername, usernameErrorMessage]);

  return (
    <div id="username-subcomponent">
      <div className="padding-container">
        <div className="top-container">
          <div className="username-title-container">
            <div className="username-title">
              {t("profile.edit_username_title")}
            </div>

            <div className="username-description">
              {t("profile.edit_username_desc")}
            </div>
          </div>

          <div className="form-container">
            <TextField
              sx={{ "& .MuiSelect-select": { marginTop: "6px" } }}
              className="form-field custom-text-field"
              value={localUsername || ""}
              error={usernameError}
              placeholder={t("profile.username")}
              onChange={onInputChange("username")}
              helperText={usernameErrorMessage}
              variant="standard"
              // InputProps={{ disableUnderline: true }}
              inputProps={{
                sx: {
                  "&::placeholder": {
                    opacity: "1",
                    font: "500 1em Lato",
                    color: "#CCCCCC",
                  },
                },
              }}
            ></TextField>
          </div>

          <div className="username-footer">
            <Trans
              i18nKey={"profile.edit_username_footer"}
              components={{ br: <br /> }}
            />
          </div>
        </div>
      </div>

      <div className="bottom-container">
        <div
          className={`save-button ${isFormValid() ? "" : "disabled-button"}`}
          onClick={onSaveUsername}
        >
          {updateProfileLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("common.save")
          )}
        </div>
      </div>
    </div>
  );
};

export default EditUsername;
