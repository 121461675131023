// General
import "./relationship-status-filter-dialog.scss";
import { useEffect, forwardRef } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateRelationshipStatusFilterDialog } from "../../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide } from "@mui/material";
// Components
import RelationshipStatusFilter from "../../../elements/filters/relationship-status-filter/relationship-status-filter";

const RelationshipStatusFilterDialog = () => {
  // Redux variables
  const relationshipStatusFilterDialog = useSelector(
    (state) => state.dialog.relationshipStatusFilterDialog
  );
  const dispatch = useDispatch();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!relationshipStatusFilterDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-RelationshipStatusFilter-Dialog",
      },
    });
  }, [relationshipStatusFilterDialog]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateRelationshipStatusFilterDialog(false));
  };

  return (
    <Dialog
      className="custom-bottom-sheet-dialog"
      fullScreen
      open={relationshipStatusFilterDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      <RelationshipStatusFilter />
    </Dialog>
  );
};

export default RelationshipStatusFilterDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
