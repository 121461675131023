// General
import "./update-profile-fullscreen-dialog.scss";
import { useState, useEffect, forwardRef } from "react";
// Static Data
import editProfileConst from "../../../../const/editProfileConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateUpdateProfileFullscreenTab,
  updateProfileCompletionState,
  updateIsEditAboutMeNext,
} from "../../../../redux/store/editProfileStore";
import {
  updateEditProfileUnsavedChangesDialog,
  updateUpdateProfileFullscreenDialog,
} from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// embla-carousel-react
import useEmblaCarousel from "embla-carousel-react";
// Material UI
import { Dialog, Slide, useMediaQuery } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Components
import EditPhotos from "../../profile/edit/edit-photos/edit-photos";
import EditUsername from "../../profile/edit/edit-username/edit-username";
import EditBirthdate from "../../profile/edit/edit-birthdate/edit-birthdate";
import EditTor from "../../profile/edit/edit-tor/edit-tor";
import EditDatingStyles from "../../profile/edit/edit-dating-styles/edit-dating-styles";
import EditLifestyle from "../../profile/edit/edit-lifestyle/edit-lifestyle";
import EditDatingBudget from "../../profile/edit/edit-dating-budget/edit-dating-budget";
import EditNetworth from "../../profile/edit/edit-net-worth/edit-net-worth";
import EditAnnualIncome from "../../profile/edit/edit-annual-income/edit-annual-income";
import EditHeight from "../../profile/edit/edit-height/edit-height";
import EditBodyType from "../../profile/edit/edit-body-type/edit-body-type";
import EditEthnicity from "../../profile/edit/edit-ethnicity/edit-ethnicity";
import EditRace from "../../profile/edit/edit-race/edit-race";
import EditEyeColor from "../../profile/edit/edit-eye-color/edit-eye-color";
import EditHairColor from "../../profile/edit/edit-hair-color/edit-hair-color";
import EditOccupation from "../../profile/edit/edit-occupation/edit-occupation";
import EditEducation from "../../profile/edit/edit-education/edit-education";
import EditRelationship from "../../profile/edit/edit-relationship/edit-relationship";
import EditChildren from "../../profile/edit/edit-children/edit-children";
import EditSmoking from "../../profile/edit/edit-smoking/edit-smoking";
import EditDrinking from "../../profile/edit/edit-drinking/edit-drinking";
import EditAlcoholPreference from "../../profile/edit/edit-alcohol-preference/edit-alcohol-preference";
import EditAboutMe from "../../profile/edit/edit-about-me/edit-about-me";
import EditLookingForAgeRange from "../../profile/edit/edit-looking-for-age-range/edit-looking-for-age-range";
import EditLookingForLocation from "../../profile/edit/edit-looking-for-location/edit-looking-for-location";
import EditLookingForEthnicity from "../../profile/edit/edit-looking-for-ethnicity/edit-looking-for-ethnicity";
import EditProfileUnsavedChangesDialog from "../edit-profile-unsaved-changes-dialog/edit-profile-unsaved-changes-dialog";

const UpdateProfileFullscreenDialog = () => {
  // General variables
  const [title, setTitle] = useState("");
  const [scrollSnaps, setScrollSnaps] = useState([]);

  // Redux variables
  const updateProfileFullscreenDialog = useSelector(
    (state) => state.dialog.updateProfileFullscreenDialog
  );
  const updateProfileDialogView = useSelector(
    (state) => state.editProfile.updateProfileDialogView
  );
  const profileCompletionState = useSelector(
    (state) => state.editProfile.profileCompletionState
  );
  const updateProfileFullscreenTab = useSelector(
    (state) => state.editProfile.updateProfileFullscreenTab
  );
  const isUnsavedChanges = useSelector(
    (state) => state.editProfile.isUnsavedChanges
  );
  const isEditAboutMeNext = useSelector(
    (state) => state.editProfile.isEditAboutMeNext
  );
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // Embla variables
  const [emblaRef, emblaApi] = useEmblaCarousel({
    duration: 15,
    watchDrag: false,
  });

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    if (updateProfileFullscreenDialog) {
      TagManager.dataLayer({
        dataLayer: {
          event: "PWA-D28",
        },
      });
    } else {
      dispatch(updateProfileCompletionState(null));
    }
  }, [updateProfileFullscreenDialog]);

  // Lifecycle | Mounted | emblaApi
  useEffect(() => {
    if (!emblaApi) return;
    emblaApi?.scrollTo(updateProfileFullscreenTab);

    emblaApi?.on("select", () => {
      dispatch(
        updateUpdateProfileFullscreenTab(emblaApi?.selectedScrollSnap())
      );
    });

    setScrollSnaps(emblaApi.scrollSnapList());
  }, [emblaApi]);

  // Lifecycle | Check for update | updateProfileFullscreenTab
  useEffect(() => {
    if (!emblaApi) return;

    emblaApi?.scrollTo(updateProfileFullscreenTab);
  }, [updateProfileFullscreenTab]);

  // Lifecycle | Check for update | updateProfileDialogView
  useEffect(() => {
    if (!updateProfileDialogView) return;
    switch (updateProfileDialogView) {
      case editProfileConst.profileDialogView.photos:
        setTitle(t("profile.edit_photos"));
        break;
      case editProfileConst.profileDialogView.username:
      case editProfileConst.profileDialogView.birthdate:
        setTitle(t("profile.basic_information"));
        break;
      case editProfileConst.profileDialogView.tor:
        setTitle(
          <Trans
            i18nKey={"profile.terms_of_relationship"}
            components={{ sup: <sup className="trade-mark-label" /> }}
          />
        );
        break;
      case editProfileConst.profileDialogView.datingStyle:
        setTitle(t("profile.dating_style"));
        break;
      case editProfileConst.profileDialogView.lifestyle:
      case editProfileConst.profileDialogView.datingBudget:
      case editProfileConst.profileDialogView.networth:
      case editProfileConst.profileDialogView.annualIncome:
        setTitle(t("profile.financial_information"));
        break;
      case editProfileConst.profileDialogView.height:
      case editProfileConst.profileDialogView.bodyType:
      case editProfileConst.profileDialogView.ethnicity:
      // case editProfileConst.profileDialogView.race:
      case editProfileConst.profileDialogView.eyeColor:
      case editProfileConst.profileDialogView.hairColor:
        setTitle(t("profile.appearance"));
        break;
      case editProfileConst.profileDialogView.occupation:
      case editProfileConst.profileDialogView.education:
      case editProfileConst.profileDialogView.relationship:
      case editProfileConst.profileDialogView.children:
      case editProfileConst.profileDialogView.smoking:
      case editProfileConst.profileDialogView.drinking:
      case editProfileConst.profileDialogView.alcoholPreference:
        setTitle(t("profile.personal_information"));
        break;
      case editProfileConst.profileDialogView.aboutMe:
        setTitle(t("profile.about_me"));
        break;
      case editProfileConst.profileDialogView.lookingForAgeRange:
      case editProfileConst.profileDialogView.lookingForLocation:
      case editProfileConst.profileDialogView.lookingForEthnicityRace:
        setTitle(t("profile.looking_for"));
        break;
      default:
        setTitle("");
        break;
    }
  }, [updateProfileDialogView]);

  useEffect(() => {
    if (!profileCompletionState) return;

    switch (profileCompletionState?.name) {
      case editProfileConst.groupProfileDialogView.photos:
        setTitle(t("profile.edit_photos"));
        break;
      case editProfileConst.groupProfileDialogView.datingStyle:
        setTitle(t("profile.dating_style"));
        break;
      case editProfileConst.groupProfileDialogView.financialInformation:
        setTitle(t("profile.financial_information"));
        break;
      case editProfileConst.groupProfileDialogView.appearance:
        setTitle(t("profile.appearance"));
        break;
      case editProfileConst.groupProfileDialogView.personalInformation:
      case editProfileConst.groupProfileDialogView.drinking:
        setTitle(t("profile.personal_information"));
        break;
      case editProfileConst.groupProfileDialogView.aboutMe:
        setTitle(t("profile.about_me"));
        break;
      case editProfileConst.groupProfileDialogView.lookingFor:
        setTitle(t("profile.looking_for"));
        break;
      default:
        setTitle("");
        break;
    }
  }, [profileCompletionState]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    if (
      (updateProfileDialogView === editProfileConst.profileDialogView.aboutMe ||
        profileCompletionState?.name ===
          editProfileConst.groupProfileDialogView.aboutMe) &&
      isEditAboutMeNext
    ) {
      dispatch(updateIsEditAboutMeNext(false));
    } else {
      if (isUnsavedChanges) {
        dispatch(updateEditProfileUnsavedChangesDialog(true));
      } else {
        dispatch(updateUpdateProfileFullscreenDialog(false));
      }
    }
  };

  // Render Functions
  const renderDialogView = () => {
    if (profileCompletionState) {
      switch (profileCompletionState?.name) {
        case editProfileConst.groupProfileDialogView.photos:
          return <EditPhotos />;
        case editProfileConst.groupProfileDialogView.datingStyle:
          return <EditDatingStyles />;
        case editProfileConst.groupProfileDialogView.financialInformation:
          return (
            <div ref={emblaRef} className="embla">
              <div className="embla__container">
                {!isDaddy && (
                  <div className="embla__slide">
                    {updateProfileFullscreenTab === 0 && (
                      <EditLifestyle addPaddingTop={true} />
                    )}
                  </div>
                )}

                {isDaddy && (
                  <div className="embla__slide">
                    {updateProfileFullscreenTab === 0 && (
                      <EditDatingBudget addPaddingTop={true} />
                    )}
                  </div>
                )}

                {isDaddy && (
                  <div className="embla__slide">
                    {updateProfileFullscreenTab === 1 && (
                      <EditNetworth addPaddingTop={true} />
                    )}
                  </div>
                )}

                {isDaddy && (
                  <div className="embla__slide">
                    {updateProfileFullscreenTab === 2 && (
                      <EditAnnualIncome addPaddingTop={true} />
                    )}
                  </div>
                )}
              </div>
            </div>
          );
        case editProfileConst.groupProfileDialogView.appearance:
          return (
            <div ref={emblaRef} className="embla">
              <div className="embla__container">
                <div className="embla__slide">
                  {updateProfileFullscreenTab === 0 && (
                    <EditHeight addPaddingTop={true} />
                  )}
                </div>

                <div className="embla__slide">
                  {updateProfileFullscreenTab === 1 && (
                    <EditBodyType addPaddingTop={true} />
                  )}
                </div>

                <div className="embla__slide">
                  {updateProfileFullscreenTab === 2 && (
                    <EditEthnicity addPaddingTop={true} />
                  )}
                </div>

                <div className="embla__slide">
                  {updateProfileFullscreenTab === 3 && (
                    <EditEyeColor addPaddingTop={true} />
                  )}
                </div>

                <div className="embla__slide">
                  {updateProfileFullscreenTab === 4 && (
                    <EditHairColor addPaddingTop={true} />
                  )}
                </div>
              </div>
            </div>
          );
        case editProfileConst.groupProfileDialogView.personalInformation:
          return (
            <div ref={emblaRef} className="embla">
              <div className="embla__container">
                <div className="embla__slide">
                  {updateProfileFullscreenTab === 0 && (
                    <EditOccupation addPaddingTop={true} />
                  )}
                </div>

                <div className="embla__slide">
                  {updateProfileFullscreenTab === 1 && (
                    <EditEducation addPaddingTop={true} />
                  )}
                </div>

                <div className="embla__slide">
                  {updateProfileFullscreenTab === 2 && (
                    <EditRelationship addPaddingTop={true} />
                  )}
                </div>

                <div className="embla__slide">
                  {updateProfileFullscreenTab === 3 && (
                    <EditChildren addPaddingTop={true} />
                  )}
                </div>

                <div className="embla__slide">
                  {updateProfileFullscreenTab === 4 && (
                    <EditSmoking addPaddingTop={true} />
                  )}
                </div>

                <div className="embla__slide">
                  {updateProfileFullscreenTab === 5 && (
                    <EditDrinking addPaddingTop={true} />
                  )}
                </div>

                <div className="embla__slide">
                  {updateProfileFullscreenTab === 6 && (
                    <EditAlcoholPreference addPaddingTop={true} />
                  )}
                </div>
              </div>
            </div>
          );
        case editProfileConst.groupProfileDialogView.aboutMe:
          return <EditAboutMe addPaddingTop={true} />;
        case editProfileConst.groupProfileDialogView.lookingFor:
          return (
            <div ref={emblaRef} className="embla">
              <div className="embla__container">
                <div className="embla__slide">
                  {updateProfileFullscreenTab === 0 && (
                    <EditLookingForAgeRange addPaddingTop={true} />
                  )}
                </div>

                <div className="embla__slide">
                  {updateProfileFullscreenTab === 1 && (
                    <EditLookingForLocation addPaddingTop={true} />
                  )}
                </div>

                <div className="embla__slide">
                  {updateProfileFullscreenTab === 2 && (
                    <EditLookingForEthnicity addPaddingTop={true} />
                  )}
                </div>
              </div>
            </div>
          );
        case editProfileConst.groupProfileDialogView.drinking:
          return (
            <div ref={emblaRef} className="embla">
              <div className="embla__container">
                <div className="embla__slide">
                  {updateProfileFullscreenTab === 0 && (
                    <EditDrinking addPaddingTop={true} />
                  )}
                </div>

                <div className="embla__slide">
                  {updateProfileFullscreenTab === 1 && (
                    <EditAlcoholPreference addPaddingTop={true} />
                  )}
                </div>
              </div>
            </div>
          );
      }
    } else {
      switch (updateProfileDialogView) {
        case editProfileConst.profileDialogView.photos:
          return <EditPhotos />;
        case editProfileConst.profileDialogView.username:
          return <EditUsername />;
        case editProfileConst.profileDialogView.tor:
          return <EditTor />;
        case editProfileConst.profileDialogView.birthdate:
          return <EditBirthdate />;
        case editProfileConst.profileDialogView.datingStyle:
          return <EditDatingStyles />;
        case editProfileConst.profileDialogView.lifestyle:
          return <EditLifestyle />;
        case editProfileConst.profileDialogView.datingBudget:
          return <EditDatingBudget />;
        case editProfileConst.profileDialogView.networth:
          return <EditNetworth />;
        case editProfileConst.profileDialogView.annualIncome:
          return <EditAnnualIncome />;
        case editProfileConst.profileDialogView.height:
          return <EditHeight />;
        case editProfileConst.profileDialogView.bodyType:
          return <EditBodyType />;
        case editProfileConst.profileDialogView.ethnicity:
          return <EditEthnicity />;
        // case editProfileConst.profileDialogView.race:
        //   return <EditRace />;
        case editProfileConst.profileDialogView.eyeColor:
          return <EditEyeColor />;
        case editProfileConst.profileDialogView.hairColor:
          return <EditHairColor />;
        case editProfileConst.profileDialogView.occupation:
          return <EditOccupation />;
        case editProfileConst.profileDialogView.education:
          return <EditEducation />;
        case editProfileConst.profileDialogView.relationship:
          return <EditRelationship />;
        case editProfileConst.profileDialogView.children:
          return <EditChildren />;
        case editProfileConst.profileDialogView.smoking:
          return <EditSmoking />;
        case editProfileConst.profileDialogView.drinking:
          return <EditDrinking />;
        case editProfileConst.profileDialogView.alcoholPreference:
          return <EditAlcoholPreference />;
        case editProfileConst.profileDialogView.aboutMe:
          return <EditAboutMe />;
        case editProfileConst.profileDialogView.lookingForAgeRange:
          return <EditLookingForAgeRange />;
        case editProfileConst.profileDialogView.lookingForLocation:
          return <EditLookingForLocation />;
        case editProfileConst.profileDialogView.lookingForEthnicityRace:
          return <EditLookingForEthnicity />;
        default:
          return null;
      }
    }
  };

  return (
    <Dialog
      className={
        isMobile
          ? "custom-bottom-sheet-no-radius-fullscreen-dialog"
          : "custom-radius10-dialog custom-dialog-height-inherit"
      }
      fullScreen={isMobile ? true : false}
      // scroll={"body"}
      open={updateProfileFullscreenDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      <div
        id="update-profile-fullscreen-dialog"
        className={isMobile ? "" : "apply-profile-fullscreen-min-width"}
      >
        <div className="dialog-header">
          <div className="left-container" onClick={onCloseDialog}>
            <ChevronLeftIcon className="back-icon" />
          </div>
          <div className="middle-container">{title}</div>
          <div className="right-container"></div>
        </div>

        <div className="select-view">
          {profileCompletionState?.slide > 0 && (
            <div className="embla-navigation">
              {Array.from(
                { length: profileCompletionState?.slide },
                (snap, index) => (
                  <div
                    className={`embla-dot-button ${
                      updateProfileFullscreenTab === index ? "active-embla" : ""
                    }`}
                    key={index}
                    onClick={() => emblaApi.scrollTo(index)}
                  ></div>
                )
              )}
            </div>
          )}

          {renderDialogView()}
        </div>
      </div>

      <EditProfileUnsavedChangesDialog />
    </Dialog>
  );
};

export default UpdateProfileFullscreenDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
