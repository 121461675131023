// General
import "./tor-filters-dialog.scss";
import { useEffect, forwardRef } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateTorFilterDialog } from "../../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide, useMediaQuery } from "@mui/material";
// Components
import TorFilter from "../../../elements/filters/tor-filter/tor-filter";
import searchConst from "../../../../../const/searchConst";

const TorFiltersDialog = () => {
  // Redux variables
  const torFilterDialog = useSelector((state) => state.dialog.torFilterDialog);
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // Lifecycle | Mounted
  useEffect(() => {
    if (!torFilterDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-TorFilter-Dialog",
      },
    });
  }, [torFilterDialog]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateTorFilterDialog(false));
  };

  return (
    <Dialog
      className={
        isMobile ? "custom-bottom-sheet-dialog" : "custom-radius10-dialog"
      }
      fullScreen={isMobile}
      open={torFilterDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      <TorFilter
        type={searchConst.searchFilterType.dialog}
        enableCloseButton={true}
        maxTag={50}
        enableSeeAllButton={false}
        enableTorDescription={true}
        enableDoneButton={true}
      />
    </Dialog>
  );
};

export default TorFiltersDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
