// General
import { useEffect } from "react";
// Services
import { sessionService } from "../../../../services/session.service";
// Static Data
import utilityConst from "../../../../const/utilityConst";
import { API_CONFIG_HEADERS } from "../../../../const/apiConst";
// Redux
import { useDispatch } from "react-redux";
import {
  updateManualLanguage,
  updateLanguage,
} from "../../../../redux/store/noResetStore";
// i18next
import { useTranslation } from "react-i18next";

const I18nMounted = () => {
  // Redux variables
  const dispatch = useDispatch();

  // i18next variables
  const { i18n } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    if (sessionService.getManualLanguage()) {
      // Get language from user defined settings
      // Set language config in Redux
      dispatch(updateManualLanguage(true));
      dispatch(updateLanguage(sessionService.getLanguage()));

      // Set language in i18next
      i18n.changeLanguage(sessionService.getLanguage());

      // Set language in API headers
      API_CONFIG_HEADERS.SB_API_HEADERS["device-language"] =
        sessionService.getLanguage();
      API_CONFIG_HEADERS.COM_HEADERS["device-language"] =
        sessionService.getLanguage();
      API_CONFIG_HEADERS.SPI_HEADERS["SB-Language"] =
        sessionService.getLanguage();
    } else {
      // Get language from browser settings
      const lng = navigator.language;
      let transformedLanguage = lng.toLowerCase();

      if (transformedLanguage.includes("ja")) {
        transformedLanguage = utilityConst.language.jaJp;
      } else if (transformedLanguage.includes("zh-tw")) {
        transformedLanguage = utilityConst.language.zhTw;
      } else {
        transformedLanguage = utilityConst.language.en;
      }

      // Set language in Redux
      dispatch(updateManualLanguage(false));
      dispatch(updateLanguage(utilityConst.language.system));

      // Set language in i18next
      i18n.changeLanguage(lng);

      // Set language in API headers
      API_CONFIG_HEADERS.SB_API_HEADERS["device-language"] =
        transformedLanguage;
      API_CONFIG_HEADERS.COM_HEADERS["device-language"] = transformedLanguage;
      API_CONFIG_HEADERS.SPI_HEADERS["SB-Language"] = transformedLanguage;
    }
  }, []);

  return <div id="i18n-mounted-shadow-component"></div>;
};

export default I18nMounted;
