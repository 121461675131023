// General
import "./gift-alert-element.scss";
// i18next
import { useTranslation } from "react-i18next";
// Components
import CustomAvatar from "../../../../shared/elements/custom-avatar/custom-avatar";

const GiftAlertElement = (props) => {
  const {
    userPhoto,
    profileDecorations,
    username,
    levellingBadge,
    giftName,
    giftAsset,

    // Style
    animation = "slideInAndOutLeft",
  } = props;

  // i18next variables
  const { t } = useTranslation();

  // Utility Functions
  const assignBackgroundGradient = () => {
    const randomNumber = Math.floor(Math.random() * 10) + 1;
    return `gift-element-gradient-${randomNumber}`;
  };

  return (
    <div
      id="gift-alert-element-subcomponent"
      className={`gift-element-container ${assignBackgroundGradient()}`}
      style={{
        WebkitAnimation: `${animation} 3s forwards`,
        MozAnimation: `${animation} 3s forwards`,
        animation: `${animation} 3s ease-in-out`,
      }}
    >
      <div className="profile-picture-container">
        <CustomAvatar
          className="profile-picture"
          src={userPhoto}
          role={null}
          profileBorder={profileDecorations?.profile_border_url}
        />
      </div>

      <div className="chat-info-container">
        <div className="user-info-container">
          <div className="username">{username || "-"}</div>
          {levellingBadge && (
            <div className="levelling-badge-container">
              <img
                className="levelling-badge"
                src={levellingBadge}
                alt="levelling-badge"
              />
            </div>
          )}
          <span className="sent-text">{t("pusher.sent")}</span>
        </div>

        <div className="gift-name">{giftName}</div>
      </div>

      <div className="gift-icon-container">
        <img className="gift-icon" src={giftAsset} alt="gift" />
      </div>
    </div>
  );
};

export default GiftAlertElement;
