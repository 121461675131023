// General
import "./about-settings.scss";
// Static Data
import routeConst from "../../../../const/routeConst";
// Material UI
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";

const AboutSettings = () => {
  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Event Handlers | Button
  const onNavigateToPrivacyPolicy = () => {
    onNavigate(routeConst.privacy.path);
  };
  const onNavigateToTerms = () => {
    onNavigate(routeConst.terms.path);
  };
  const onNavigateToSafetyTips = () => {
    onNavigate(routeConst.safetyTips.path);
  };

  return (
    <div id="settings-about-settings-subcomponent">
      <div className="padding-container">
        <div className="account-label">{t("settings.about")}</div>

        <div className="buttons-list-container">
          <div className="button-container" onClick={onNavigateToPrivacyPolicy}>
            <div className="button-label">{t("settings.privacy_policy")}</div>
            <div className="suffix">
              <NavigateNextIcon />
            </div>
          </div>

          <div className="button-container" onClick={onNavigateToTerms}>
            <div className="button-label">
              {t("settings.terms_and_conditions")}
            </div>
            <div className="suffix">
              <NavigateNextIcon />
            </div>
          </div>

          <div className="button-container" onClick={onNavigateToSafetyTips}>
            <div className="button-label">{t("settings.safety_tips")}</div>
            <div className="suffix">
              <NavigateNextIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSettings;
