// General
import "./redirect-from-old-app.scss";
import { useEffect } from "react";
// react-gtm-module
import TagManager from "react-gtm-module";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateRedirectFromOldAppDialog } from "../../../../redux/store/dialogStore";
// Material UI
import { Dialog } from "@mui/material";

const RedirectFromOldApp = () => {
  // Redux variables
  const redirectFromOldAppDialog = useSelector(
    (state) => state.dialog.redirectFromOldAppDialog
  );
  const dispatch = useDispatch();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!redirectFromOldAppDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-RedirectFromOldAp-Dialog",
      },
    });
  }, [redirectFromOldAppDialog]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateRedirectFromOldAppDialog(false));
  };

  return (
    <Dialog
      className="custom-radius25-dialog custom-dialog-always-top"
      open={redirectFromOldAppDialog}
      onClose={onCloseDialog}
    >
      <div id="redirect-from-old-app-dialog">
        <div className="padding-container">
          <div className="redirect-top-container">
            <div className="redirect-title">Say hello to..</div>

            <div className="redirect-subtitle">the new Sugarbook Web App!</div>
          </div>

          <div className="unordered-list-container">
            <div className="unordered-title">WHAT'S NEW?</div>

            <ul className="unordered-list">
              <li>Smooth like butter</li>
              <li>Filter by Terms of Relationships</li>
              <li>Improved search results</li>
              <li>Improved Livestreaming experience</li>
            </ul>
          </div>

          <div className="continue-button" onClick={onCloseDialog}>
            CONTINUE
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default RedirectFromOldApp;
