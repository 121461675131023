// General
import "./edit-height.scss";
import { useState, useEffect, useRef } from "react";
// Services
import { useUpdateEditUserProfileMutation } from "../../../../../services/data.service";
// Static Data
import userConst from "../../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateHeight,
  updateUpdateProfileFullscreenTab,
  updateIsUnsavedChanges,
} from "../../../../../redux/store/editProfileStore";
import {
  updateProfileCompletionPercentage,
  updateProfileRequirementPopup,
} from "../../../../../redux/store/profileStore";
import { updateUpdateProfileFullscreenDialog } from "../../../../../redux/store/dialogStore";
// Material UI
import { useMediaQuery } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../../elements/spinner/spinner";

const EditHeight = (props) => {
  const { addPaddingTop = false } = props;

  // API variables
  const [
    updateProfile,
    {
      data: updateProfileData,
      error: updateProfileErrorData,
      isLoading: updateProfileLoading,
      isSuccess: updateProfileSuccess,
      isError: updateProfileError,
    },
  ] = useUpdateEditUserProfileMutation();

  // General variables
  const [localHeight, setLocalHeight] = useState(null);
  const [heightData, setHeightData] = useState(null);
  const [heightIndex, setHeightIndex] = useState(null);
  const listRef = useRef(null);

  // Redux variables
  const height = useSelector((state) => state.editProfile.height);
  const profileCompletionState = useSelector(
    (state) => state.editProfile.profileCompletionState
  );
  const updateProfileFullscreenTab = useSelector(
    (state) => state.editProfile.updateProfileFullscreenTab
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    setHeightData(userConst.editHeight);

    return () => {
      listRef.current?.removeEventListener("scroll", onScroll);
    };
  }, []);

  // Lifecycle | Check for update | updateProfile API Response
  useEffect(() => {
    if (updateProfileLoading) {
    } else if (updateProfileSuccess) {
      dispatch(updateHeight(updateProfileData?.data?.user?.appearance?.height));
      dispatch(
        updateProfileCompletionPercentage(
          updateProfileData?.data?.user?.profile_progress?.summary?.progress
        )
      );
      dispatch(
        updateProfileRequirementPopup(
          updateProfileData?.data?.user?.profile_progress?.summary?.requirement
        )
      );
      dispatch(updateIsUnsavedChanges(false));

      if (profileCompletionState) {
        const height = updateProfileData?.data?.user?.appearance?.height;
        const bodyType = updateProfileData?.data?.user?.appearance?.body_type;
        const ethnicity = updateProfileData?.data?.user?.appearance?.ethnicity;
        const race = updateProfileData?.data?.user?.appearance?.race;
        const eyeColor = updateProfileData?.data?.user?.appearance?.eye_color;
        const hairColor = updateProfileData?.data?.user?.appearance?.hair_color;

        if (!height) {
          dispatch(updateUpdateProfileFullscreenTab(0));
        } else if (!bodyType) {
          dispatch(updateUpdateProfileFullscreenTab(1));
        } else if (!ethnicity) {
          dispatch(updateUpdateProfileFullscreenTab(2));
        } else if (!race) {
          dispatch(updateUpdateProfileFullscreenTab(2));
        } else if (!eyeColor) {
          dispatch(updateUpdateProfileFullscreenTab(3));
        } else if (!hairColor) {
          dispatch(updateUpdateProfileFullscreenTab(4));
        } else {
          dispatch(updateUpdateProfileFullscreenDialog(false));
        }
      } else {
        dispatch(updateUpdateProfileFullscreenDialog(false));
      }
    } else if (updateProfileError) {
    }
  }, [updateProfileLoading, updateProfileSuccess, updateProfileError]);

  // Lifecycle | Check for update | heightData
  useEffect(() => {
    if (!heightData || heightData?.length <= 0) return;

    listRef.current.addEventListener("scroll", onScroll);

    setHeightIndex(height ? heightData?.indexOf(height) : 2);
    setLocalHeight(height ? height : heightData[2]);

    // Scroll to the middle of the selected item
    const itemHeight = listRef.current.firstChild.offsetHeight;
    listRef.current.scrollTop =
      (height ? heightData.indexOf(height) : heightData[2]) * itemHeight -
      listRef.current.offsetHeight / 2 +
      itemHeight / 2;
  }, [heightData]);

  // Lifecycle | Check for update | heightIndex
  useEffect(() => {
    if (!heightIndex) return;

    setLocalHeight(heightData[heightIndex]);
  }, [heightIndex]);

  // Lifecycle | Check for update | localHeight
  useEffect(() => {
    if (localHeight !== height) {
      dispatch(updateIsUnsavedChanges(true));
    } else {
      dispatch(updateIsUnsavedChanges(false));
    }
  }, [localHeight]);

  // Event Handlers | Button
  const onSaveHeight = () => {
    if (!isFormValid()) return;

    const obj = {
      profile: {
        height: localHeight,
      },
    };
    updateProfile(obj);
  };
  const onChangeHeight = (direction) => {
    let newIndex = heightIndex;

    if (direction === "up") {
      if (heightIndex > 2) {
        newIndex = heightIndex - 1;
      }
    } else if (direction === "down") {
      if (heightIndex < heightData?.length - 2) {
        newIndex = heightIndex + 1;
      }
    }

    setHeightIndex(newIndex);

    // Scroll to the middle of the selected item
    const itemHeight = listRef.current.firstChild.offsetHeight;
    listRef.current.scrollTop =
      newIndex * itemHeight - listRef.current.offsetHeight / 2 + itemHeight / 2;
  };

  // Utility Functions
  const onScroll = () => {
    const middle = listRef.current.offsetHeight / 2;
    const itemHeight = listRef.current.firstChild.offsetHeight;
    const scrollTop = listRef.current.scrollTop;
    const closestIndex = Math.round((scrollTop + middle) / itemHeight);

    setHeightIndex(closestIndex - 1);
  };
  const isFormValid = () => {
    return heightIndex && !updateProfileLoading;
  };
  const getHeightData = () => {
    return heightData;
  };

  return (
    <div id="height-subcomponent">
      <div className="padding-container">
        <div
          className={`top-container ${
            addPaddingTop ? "add-edit-padding-top" : ""
          }`}
        >
          <div className="height-icon-container">
            {getIcon("heightIcon", "height-icon")}
          </div>

          <div className="height-title">{t("profile.whats_your_height")}</div>

          <div className="height-selector-container">
            {!isMobile && <div className="fake-arrow-container"></div>}

            <div className="spacer-container"></div>

            <div className="height-container" ref={listRef}>
              {getHeightData()?.map((height, index) => (
                <div
                  className={`height-item ${
                    index === heightIndex ? "selected" : ""
                  }`}
                  key={index}
                >
                  {height}
                  {height && "cm"}
                </div>
              ))}
            </div>

            <div className="spacer-container"></div>

            {!isMobile && (
              <div className="arrow-container">
                <ArrowDropUpIcon
                  className="arrow-icon"
                  onClick={() => onChangeHeight("up")}
                />
                <ArrowDropDownIcon
                  className="arrow-icon"
                  onClick={() => onChangeHeight("down")}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="bottom-container">
        <div
          className={`save-button ${isFormValid() ? "" : "disabled-button"}`}
          onClick={onSaveHeight}
        >
          {updateProfileLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("common.save")
          )}
        </div>
      </div>
    </div>
  );
};

export default EditHeight;
