// General
import "./charged-message-popup-dialog.scss";
import { useEffect } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateChargedMessagePopupDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog } from "@mui/material";
// i18next
import { Trans } from "react-i18next";
// Custom Hooks
import AssetManager from "../../../utility/manager/asset-manager/asset-manager";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const ChargedMessagePopupDialog = () => {
  // Redux variables
  const chargedMessagePopupDialog = useSelector(
    (state) => state.dialog.chargedMessagePopupDialog
  );
  const isChargedMessage = useSelector(
    (state) => state.privateCall.isChargedMessage
  );
  const chargedMessageCoinsReceived = useSelector(
    (state) => state.privateCall.chargedMessageCoinsReceived
  );
  const chargedMessageDiamondsReceived = useSelector(
    (state) => state.privateCall.chargedMessageDiamondsReceived
  );
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // Custom Hooks Functions
  const getAsset = AssetManager();
  const getIcon = IconManager();

  // Lifecycle | Check for update | chargedMessagePopupDialog
  useEffect(() => {
    if (!chargedMessagePopupDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-ChargedMessagePopup-Dialog",
      },
    });

    setTimeout(() => {
      onCloseDialog();
    }, 2000);
  }, [chargedMessagePopupDialog]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateChargedMessagePopupDialog(false));
  };

  return (
    <Dialog
      className="custom-dialog-transparent"
      open={chargedMessagePopupDialog}
      onClose={onCloseDialog}
    >
      <div id="charged-message-popup-dialog">
        {/* SB | Charged Message Enable */}
        {isChargedMessage && !isDaddy && (
          <img
            className="charged-message-enabled-background"
            src={getAsset("chargedMessageSbPopupEnable")}
          />
        )}

        {isChargedMessage && !isDaddy && (
          <div className="charged-message-sb-enable-description">
            <Trans
              className="sb-enable"
              i18nKey={"1on1.charged_message_sb_enable"}
              values={{ diamonds: chargedMessageDiamondsReceived }}
              components={{
                br: <br />,
                span: <span className="bold" />,
                diamondicon: getIcon("sbDiamondIcon", "diamond-icon"),
                diamond: <span className="diamond-label"></span>,
              }}
            />
          </div>
        )}

        {/* SB | Charged Message Disable */}
        {!isChargedMessage && !isDaddy && (
          <img
            className="charged-message-enabled-background"
            src={getAsset("chargedMessageSbPopupDisable")}
          />
        )}

        {!isChargedMessage && !isDaddy && (
          <div className="charged-message-sb-disable-description">
            <Trans
              classID="sb-disable"
              i18nKey={"1on1.charged_message_sb_disable"}
              components={{ br: <br />, span: <span className="bold" /> }}
            />
          </div>
        )}

        {/* SD | Charged Message */}
        {isChargedMessage && isDaddy && (
          <img
            className="charged-message-sd-background"
            src={getAsset("chargedMessageSdPopup")}
          />
        )}

        {isChargedMessage && isDaddy && (
          <div className="charged-message-sd-description">
            <Trans
              classID="sb-disable"
              i18nKey={"1on1.charged_message_sd_enable"}
              values={{ coins: chargedMessageCoinsReceived }}
              components={{
                br: <br />,
                span: <span className="bold" />,
                coinicon: getIcon("sbCoinIcon", "coin-icon"),
              }}
            />
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default ChargedMessagePopupDialog;
