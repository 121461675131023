// General
import "./empty-list.scss";
// Static Data
import routeConst from "../../../../const/routeConst";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const EmptyList = () => {
  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  return (
    <div
      id="viewed-me-tab-empty-list-subcomponent"
      className="empty-list-container"
    >
      <div className="message-icon-container">
        {getIcon("viewedMeOutlineIcon", "message-icon")}
      </div>
      <div className="no-message-text">
        <Trans
          i18nKey={"interest.empty_viewed_me"}
          components={{ br: <br /> }}
        />
      </div>

      <div
        className="browse-member-button"
        onClick={() => onNavigate(routeConst.search.path)}
      >
        {t("common.browse_members")}
      </div>
    </div>
  );
};

export default EmptyList;
