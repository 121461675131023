// General
import "./notification.scss";
import { useEffect, useRef } from "react";
// Services
import { useLazyGetNotificationsListQuery } from "../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  // Notification Functions
  updateNotificationList,
  updateShowNotificationBadge,
  updateNotificationPagination,
  resetNotificationList,
  updateNotificationFirstLoad,

  // Scroll Functions
  updateNotificationScrollYPosition,
} from "../../redux/store/notificationStore";
// React Infinite Scroller
import InfiniteScroll from "react-infinite-scroller";
// react-simple-pull-to-refresh
import PullToRefresh from "react-simple-pull-to-refresh";
// react-gtm-module
import TagManager from "react-gtm-module";
// i18next
import { useTranslation } from "react-i18next";
// Components
import EmptyList from "./empty-list/empty-list";
import Spinner from "../shared/elements/spinner/spinner";
import Banner from "../shared/elements/banner/banner";
import NotificationList from "./notification-list/notification-list";

const Notification = (props) => {
  const { showBanner = false, loadMoreThanOne = true } = props;

  // API variables
  const [
    getNotificationsList,
    {
      data: getNotificationsListData,
      error: getNotificationsListErrorData,
      isFetching: getNotificationsListFetching,
      isLoading: getNotificationsListLoading,
      isSuccess: getNotificationsListSuccess,
      isError: getNotificationsListError,
    },
  ] = useLazyGetNotificationsListQuery();

  // General variables
  const scrollContainerRef = useRef(null);

  // Render variables
  let notificationListView = <div></div>;

  // Redux variables
  const notificationList = useSelector(
    (state) => state.notification.notificationList
  );
  const notificationTodayList = useSelector(
    (state) => state.notification.notificationTodayList
  );
  const notificationYesterdayList = useSelector(
    (state) => state.notification.notificationYesterdayList
  );
  const notificationWeekList = useSelector(
    (state) => state.notification.notificationWeekList
  );
  const notificationMonthList = useSelector(
    (state) => state.notification.notificationMonthList
  );
  const notificationOlderList = useSelector(
    (state) => state.notification.notificationOlderList
  );
  const notificationPagination = useSelector(
    (state) => state.notification.notificationPagination
  );
  const notificationFirstLoad = useSelector(
    (state) => state.notification.notificationFirstLoad
  );
  const notificationScrollYPosition = useSelector(
    (state) => state.notification.notificationScrollYPosition
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-9-9.1",
      },
    });

    if (notificationFirstLoad) {
      getNotificationsList(null, true);
    }

    const scrollContainer = scrollContainerRef.current;

    const savedScrollPosition = notificationScrollYPosition;

    if (scrollContainer) {
      if (savedScrollPosition) {
        scrollContainer.scrollTop = parseInt(savedScrollPosition, 10);
      } else {
        scrollContainer.scrollTop = 0;
      }

      scrollContainer?.addEventListener("scroll", handleScroll);

      return () => {
        scrollContainer?.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  // Lifecycle | Check for update | Notifications List API Response
  useEffect(() => {
    if (getNotificationsListFetching || getNotificationsListLoading) {
    } else if (getNotificationsListSuccess) {
      dispatch(updateNotificationFirstLoad(false));

      // Pagination
      dispatch(
        updateNotificationPagination(
          getNotificationsListData?.data?.pagination?.next_cursor
        )
      );

      // Notification List
      if (getNotificationsListData?.data?.results?.length > 0) {
        dispatch(
          updateNotificationList(getNotificationsListData?.data?.results)
        );

        setTimeout(() => {
          dispatch(updateShowNotificationBadge());
        }, 1000);
      }
    } else if (getNotificationsListError) {
    }
  }, [
    getNotificationsListFetching,
    getNotificationsListLoading,
    getNotificationsListSuccess,
    getNotificationsListError,
  ]);

  // Infinite Scroller Functions
  const infiniteScrollFunc = () => {
    if (!notificationPagination) return;

    // Delay to allow User Meta to have a chance to load
    setTimeout(() => {
      getNotificationsList(`?pagination_token=${notificationPagination}`, true);
    }, 1000);
  };

  // Pull To Refresh Functions
  const pullToRefreshFunc = () => {
    dispatch(resetNotificationList());
    dispatch(updateNotificationFirstLoad(true));

    return new Promise((resolve) => {
      resolve(getNotificationsList(null, false));
    });
  };

  // Utility Functions
  const handleScroll = () => {
    const currentPosition = scrollContainerRef?.current?.scrollTop;

    if (currentPosition) {
      dispatch(updateNotificationScrollYPosition(currentPosition));
    }
  };

  // Render Functions | Notification List Data
  if (notificationList?.length > 0) {
    notificationListView = (
      <div className="infinite-scroller-container">
        {notificationTodayList?.length > 0 && (
          <NotificationList
            title={t("common.today")}
            notificationList={notificationTodayList}
          />
        )}

        {notificationYesterdayList?.length > 0 && (
          <NotificationList
            title={t("common.yesterday")}
            notificationList={notificationYesterdayList}
          />
        )}

        {notificationWeekList?.length > 0 && (
          <NotificationList
            title={t("common.this_week")}
            notificationList={notificationWeekList}
          />
        )}

        {notificationMonthList?.length > 0 && (
          <NotificationList
            title={t("common.this_month")}
            notificationList={notificationMonthList}
          />
        )}

        {notificationOlderList?.length > 0 && (
          <NotificationList
            title={t("common.older")}
            notificationList={notificationOlderList}
          />
        )}
      </div>
    );
  } else if (
    notificationFirstLoad &&
    (getNotificationsListFetching || getNotificationsListLoading)
  ) {
    notificationListView = <Spinner />;
  } else {
    notificationListView = <EmptyList />;
  }

  return (
    <div id="notifications-page">
      <PullToRefresh
        className="pull-to-refresh-container"
        onRefresh={pullToRefreshFunc}
        refreshingContent={t("common.release")}
        pullingContent={t("common.pull_to_refresh")}
        isPullable={
          !getNotificationsListFetching && !getNotificationsListLoading
        }
      >
        <div className="notification-list-container" ref={scrollContainerRef}>
          <InfiniteScroll
            pageStart={0}
            loadMore={infiniteScrollFunc}
            hasMore={notificationPagination && loadMoreThanOne ? true : false}
            loader={<Spinner key={0} />}
            useWindow={false}
          >
            {showBanner && (
              <div className="mobile-banner-container">
                <Banner type={"interests"} view={"carousel"} />
              </div>
            )}

            {notificationListView}
          </InfiniteScroll>
        </div>
      </PullToRefresh>
    </div>
  );
};

export default Notification;
