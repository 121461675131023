// Environment
import environment from "../environment/environment";

// App Key
export const DEVICE_TYPE = "pwa"; // Changing this to web will cause API response to return Invalid Device Type
export const DEVICE_LANGUAGE = "en";
export const APP_IDENTIFIER = "pwa.sugarbook.com"; // Changing this to candybook.com will cause API response to return Invalid App

export const CUSTOM_USER_AGENT = `Sugarbook PWA/${environment.appVersion} (${APP_IDENTIFIER})`;
export const FB_APP_ID = "143826786234468";
export const SP_PUBLIC_KEY = "pk_test_Cjd6sp4joFb5IKO8WNPsp9MN";

// Headers
export const API_CONFIG_HEADERS = {
  SB_API_HEADERS: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "display-mode": "browser",
    // "SB-Platform": `${DEVICE_TYPE}`,
    // "SB-App-Identifier": `${APP_IDENTIFIER}`,
    // "SB-App-Version": `${environment.appVersion}`,
    platform: `${DEVICE_TYPE}`,
    "api-access-token": process.env["REACT_APP_API_ACCESS_TOKEN"],
    "app-identifier": `${APP_IDENTIFIER}`,
    "app-version": `${environment.appVersion}`,
    "device-language": "en",
    "device-type": `${DEVICE_TYPE}`,
    Authorization: "",
    token: "",
  },
  COM_HEADERS: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "display-mode": "browser",
    // "SB-Platform": `${DEVICE_TYPE}`,
    // "SB-App-Identifier": `${APP_IDENTIFIER}`,
    // "SB-App-Version": `${env.appVersion}`,
    // "sb-development": 1,
    platform: `${DEVICE_TYPE}`,
    "api-access-token": process.env["REACT_APP_API_ACCESS_TOKEN"],
    "app-identifier": `${APP_IDENTIFIER}`,
    "app-version": `${environment.appVersion}`,
    "device-language": "en",
    "device-type": `${DEVICE_TYPE}`,
    token: "",
  },
  SPI_HEADERS: {
    // "Content-Type": "application/json",
    Accept: "application/json",
    "display-mode": "browser",
    "SB-Platform": `${DEVICE_TYPE}`,
    "SB-App-Identifier": `${APP_IDENTIFIER}`,
    "SB-App-Version": `${environment.appVersion}`,
    "SB-Language": `${DEVICE_LANGUAGE}`,
    platform: `${DEVICE_TYPE}`,
    Authorization: "",
  },

  // ALT
  SB_API_HEADERS_FORM_DATA: {
    Accept: "application/json",
    "display-mode": "browser",
    // "SB-Platform": `${DEVICE_TYPE}`,
    // "SB-App-Identifier": `${APP_IDENTIFIER}`,
    // "SB-App-Version": `${environment.appVersion}`,
    platform: `${DEVICE_TYPE}`,
    "api-access-token": process.env["REACT_APP_API_ACCESS_TOKEN"],
    "app-identifier": `${APP_IDENTIFIER}`,
    "app-version": `${environment.appVersion}`,
    "device-language": "en",
    "device-type": `${DEVICE_TYPE}`,
    Authorization: "",
    token: "",
  },
  COM_HEADERS_FORM_DATA: {
    Accept: "application/json",
    "display-mode": "browser",
    // "SB-Platform": `${DEVICE_TYPE}`,
    // "SB-App-Identifier": `${APP_IDENTIFIER}`,
    // "SB-App-Version": `${env.appVersion}`,
    // "sb-development": 1,
    platform: `${DEVICE_TYPE}`,
    "api-access-token": process.env["REACT_APP_API_ACCESS_TOKEN"],
    "app-identifier": `${APP_IDENTIFIER}`,
    "app-version": `${environment.appVersion}`,
    "device-language": "en",
    "device-type": `${DEVICE_TYPE}`,
    token: "",
  },
  REFRESH_TOKEN_HEADERS: {
    Accept: "application/json",
    "display-mode": "browser",
    "SB-Platform": `${DEVICE_TYPE}`,
    "SB-App-Identifier": `${APP_IDENTIFIER}`,
    "SB-App-Version": `${environment.appVersion}`,
    "SB-Language": `${DEVICE_LANGUAGE}`,
    platform: `${DEVICE_TYPE}`,
    Authorization: "",
    token: "",
  },
};
