// General
import "./edit-net-worth.scss";
import { useState, useEffect, useRef } from "react";
// Services
import {
  useLazyGetJourneyQuestionsQuery,
  useUpdateEditUserProfileMutation,
} from "../../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateNetworth,
  updateUpdateProfileFullscreenTab,
  updateIsUnsavedChanges,
} from "../../../../../redux/store/editProfileStore";
import {
  updateProfileCompletionPercentage,
  updateProfileRequirementPopup,
} from "../../../../../redux/store/profileStore";
import { updateUpdateProfileFullscreenDialog } from "../../../../../redux/store/dialogStore";
// Material UI
import { useMediaQuery } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../../elements/spinner/spinner";

const EditNetworth = (props) => {
  const { addPaddingTop = false } = props;

  // API variables
  const [
    getJourneyQuestions,
    {
      data: getJourneyQuestionsData,
      error: getJourneyQuestionsErrorData,
      isFetching: getJourneyQuestionsFetching,
      isLoading: getJourneyQuestionsLoading,
      isSuccess: getJourneyQuestionsSuccess,
      isError: getJourneyQuestionsError,
    },
  ] = useLazyGetJourneyQuestionsQuery();
  const [
    updateProfile,
    {
      data: updateProfileData,
      error: updateProfileErrorData,
      isLoading: updateProfileLoading,
      isSuccess: updateProfileSuccess,
      isError: updateProfileError,
    },
  ] = useUpdateEditUserProfileMutation();

  // General variables
  const [localNetworth, setLocalNetworth] = useState(null);
  const [networthData, setNetworthData] = useState(null);
  const [networthIndex, setNetworthIndex] = useState(null);
  const listRef = useRef(null);

  // Redux variables
  const networth = useSelector((state) => state.editProfile.networth);
  const profileCompletionState = useSelector(
    (state) => state.editProfile.profileCompletionState
  );
  const updateProfileFullscreenTab = useSelector(
    (state) => state.editProfile.updateProfileFullscreenTab
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    setLocalNetworth(networth);

    getJourneyQuestions(null, true);

    return () => {
      listRef.current?.removeEventListener("scroll", onScroll);
    };
  }, []);

  // Lifecycle | Check for update | getJourneyQuestions API Response
  useEffect(() => {
    if (getJourneyQuestionsFetching || getJourneyQuestionsLoading) {
    } else if (getJourneyQuestionsSuccess) {
      if (!networthData || networthData?.length <= 0) {
        let networthData = getJourneyQuestionsData?.selections?.find(
          (item) => item?.category === "networth"
        );

        let networthArrayTemp = JSON.parse(
          JSON.stringify(networthData?.selections)
        );
        // add empty items to the start and end of the array
        networthArrayTemp.unshift({ id: 0, display_locale: "", tag: "-" });
        networthArrayTemp.unshift({ id: 0, display_locale: "", tag: "-" });
        networthArrayTemp.push({ id: 0, display_locale: "", tag: "-" });
        networthArrayTemp.push({ id: 0, display_locale: "", tag: "-" });

        setNetworthData(networthArrayTemp);
        setNetworthIndex(
          networth
            ? networthArrayTemp?.findIndex((item) => item.id === networth?.id)
            : 2
        );
        setLocalNetworth(networth ? networth : networthArrayTemp[2]);
      }
    } else if (getJourneyQuestionsError) {
    }
  }, [
    getJourneyQuestionsFetching,
    getJourneyQuestionsLoading,
    getJourneyQuestionsSuccess,
    getJourneyQuestionsError,
  ]);

  // Lifecycle | Check for update | updateProfile API Response
  useEffect(() => {
    if (updateProfileLoading) {
    } else if (updateProfileSuccess) {
      dispatch(
        updateNetworth(updateProfileData?.data?.user?.occupation?.networth)
      );
      dispatch(
        updateProfileCompletionPercentage(
          updateProfileData?.data?.user?.profile_progress?.summary?.progress
        )
      );
      dispatch(
        updateProfileRequirementPopup(
          updateProfileData?.data?.user?.profile_progress?.summary?.requirement
        )
      );
      dispatch(updateIsUnsavedChanges(false));

      if (profileCompletionState) {
        const networth = updateProfileData?.data?.user?.occupation?.networth;
        const annualIncome =
          updateProfileData?.data?.user?.occupation?.annual_income;
        const datingBudget =
          updateProfileData?.data?.user?.relationship?.dating_budget;

        if (!datingBudget) {
          dispatch(updateUpdateProfileFullscreenTab(0));
        } else if (!networth) {
          dispatch(updateUpdateProfileFullscreenTab(1));
        } else if (!annualIncome) {
          dispatch(updateUpdateProfileFullscreenTab(2));
        } else {
          dispatch(updateUpdateProfileFullscreenDialog(false));
        }
      } else {
        dispatch(updateUpdateProfileFullscreenDialog(false));
      }
    } else if (updateProfileError) {
    }
  }, [updateProfileLoading, updateProfileSuccess, updateProfileError]);

  // Lifecycle | Check for update | networthData
  useEffect(() => {
    if (!networthData || networthData?.length <= 0) return;

    listRef.current.addEventListener("scroll", onScroll);

    setNetworthIndex(
      networth ? networthData?.findIndex((item) => item.id === networth?.id) : 2
    );
    setLocalNetworth(networth ? networth : networthData[2]);

    // Scroll to the middle of the selected item
    const itemHeight = listRef.current.firstChild.offsetHeight;
    listRef.current.scrollTop =
      (networth
        ? networthData?.findIndex((item) => item.id === networth?.id)
        : 2) *
        itemHeight -
      listRef.current.offsetHeight / 2 +
      itemHeight / 2;
  }, [networthData]);

  // Lifecycle | Check for update | networthIndex
  useEffect(() => {
    if (!networthIndex) return;

    // Scroll to the middle of the selected item
    setLocalNetworth(networthData[networthIndex]);
  }, [networthIndex]);

  // Lifecycle | Check for update | localNetworth
  useEffect(() => {
    if (localNetworth?.id !== networth?.id) {
      dispatch(updateIsUnsavedChanges(true));
    } else {
      dispatch(updateIsUnsavedChanges(false));
    }
  }, [localNetworth]);

  // Event Handlers | Button
  const onChangeNetworth = (direction) => {
    let newIndex = networthIndex;

    if (direction === "up") {
      if (networthIndex > 2) {
        newIndex = networthIndex - 1;
      }
    } else if (direction === "down") {
      if (networthIndex < networthData?.length - 2) {
        newIndex = networthIndex + 1;
      }
    }

    setNetworthIndex(newIndex);

    // Scroll to the middle of the selected item
    const itemHeight = listRef.current.firstChild.offsetHeight;
    listRef.current.scrollTop =
      newIndex * itemHeight - listRef.current.offsetHeight / 2 + itemHeight / 2;
  };
  const onSaveNetworth = () => {
    if (!isFormValid()) return;

    const obj = {
      profile: {
        networth: localNetworth?.id,
      },
    };
    updateProfile(obj);
  };

  // Utility Functions
  const onScroll = () => {
    const middle = listRef.current.offsetHeight / 2;
    const itemHeight = listRef.current.firstChild.offsetHeight;
    const scrollTop = listRef.current.scrollTop;
    const closestIndex = Math.round((scrollTop + middle) / itemHeight);

    setNetworthIndex(closestIndex - 1);
  };
  const isFormValid = () => {
    return localNetworth && !updateProfileLoading;
  };

  return (
    <div id="networth-subcomponent">
      <div className="padding-container">
        <div
          className={`top-container ${
            addPaddingTop ? "add-edit-padding-top" : ""
          }`}
        >
          <div className="networth-icon-container">
            {getIcon("networthIcon", "networth-icon")}
          </div>

          <div className="networth-title">
            {t("profile.whats_your_net_worth")}
          </div>

          {getJourneyQuestionsFetching || getJourneyQuestionsLoading ? (
            <Spinner />
          ) : (
            <div className="networth-selector-container">
              {!isMobile && <div className="fake-arrow-container"></div>}

              <div className="spacer-container"></div>

              <div className="networth-container" ref={listRef}>
                {networthData?.map((income, index) => (
                  <div
                    className={`networth-item ${
                      index === networthIndex ? "selected" : ""
                    }`}
                    key={index}
                  >
                    {income?.display_locale}
                  </div>
                ))}
              </div>

              <div className="spacer-container"></div>

              {!isMobile && (
                <div className="arrow-container">
                  <ArrowDropUpIcon
                    className="arrow-icon"
                    onClick={() => onChangeNetworth("up")}
                  />
                  <ArrowDropDownIcon
                    className="arrow-icon"
                    onClick={() => onChangeNetworth("down")}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="bottom-container">
        <div
          className={`save-button ${isFormValid() ? "" : "disabled-button"}`}
          onClick={onSaveNetworth}
        >
          {updateProfileLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("common.save")
          )}
        </div>
      </div>
    </div>
  );
};

export default EditNetworth;
