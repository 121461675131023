// General
import "./edit-profile.scss";
// Static Data
import utilityConst from "../../../../const/utilityConst";
// Components
import Photos from "../photos/photos";
import BasicInformation from "./basic-information/basic-information";
import TermsOfRelationship from "./terms-of-relationship/terms-of-relationship";
import DatingStyle from "./dating-style/dating-style";
import FinancialInformation from "./financial-information/financial-information";
import Location from "./location/location";
import Appearance from "./apperance/appearance";
import PersonalInformation from "./personal-information/personal-information";
import AlcoholPreference from "./alcohol-preference/alcohol-preference";
import AboutMe from "./about-me/about-me";
import LookingFor from "./looking-for/looking-for";

const EditProfile = () => {
  return (
    <div id="profile-edit-edit-profile-subcomponent">
      <Photos />

      <BasicInformation mode={utilityConst.editProfileMode.mobile} />

      <TermsOfRelationship mode={utilityConst.editProfileMode.mobile} />

      <DatingStyle mode={utilityConst.editProfileMode.mobile} />

      <FinancialInformation mode={utilityConst.editProfileMode.mobile} />

      <Location mode={utilityConst.editProfileMode.mobile} />

      <Appearance mode={utilityConst.editProfileMode.mobile} />

      <PersonalInformation mode={utilityConst.editProfileMode.mobile} />

      <AlcoholPreference />

      <AboutMe mode={utilityConst.editProfileMode.mobile} />

      <LookingFor mode={utilityConst.editProfileMode.mobile} />
    </div>
  );
};

export default EditProfile;
