// General
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // General variables
  profileLevellingTab: 0,
};

export const levellingSlice = createSlice({
  name: "levelling",
  initialState,
  reducers: {
    // General Functions
    updateProfileLevellingTab: (state, action) => {
      state.profileLevellingTab = action.payload;
    },

    // Utility Functions
    resetLevellingStore: () => {
      return { ...initialState };
    },
  },
});

export const {
  // General Functions
  updateProfileLevellingTab,

  // Utility Functions
  resetLevellingStore,
} = levellingSlice.actions;

export default levellingSlice.reducer;
