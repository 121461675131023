// General
import "./alcohol-preference.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetJourneyQuestionsQuery,
  useUpdateJourneyMutation,
} from "../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateAlcoholPreference,
  updateJourneyNavigationPassthrough,
  updateJourneyDataPassthrough,
  updateRegistrationNavigationData,
} from "../../../../redux/store/registrationStore";
import { updateErrorToast } from "../../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { useMediaQuery } from "@mui/material";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hook
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../../shared/elements/spinner/spinner";

const AlcoholPreferenceDetails = () => {
  // API variables
  const [
    getJourneyQuestions,
    {
      data: getJourneyQuestionsData,
      error: getJourneyQuestionsErrorData,
      isFetching: getJourneyQuestionsFetching,
      isLoading: getJourneyQuestionsLoading,
      isSuccess: getJourneyQuestionsSuccess,
      isError: getJourneyQuestionsError,
    },
  ] = useLazyGetJourneyQuestionsQuery();
  const [
    updateJourney,
    {
      data: updateJourneyData,
      error: updateJourneyErrorData,
      isLoading: updateJourneyLoading,
      isSuccess: updateJourneySuccess,
      isError: updateJourneyError,
    },
  ] = useUpdateJourneyMutation();
  const [
    skipJourney,
    {
      data: skipJourneyData,
      error: skipJourneyErrorData,
      isLoading: skipJourneyLoading,
      isSuccess: skipJourneySuccess,
      isError: skipJourneyError,
    },
  ] = useUpdateJourneyMutation();

  // Redux variables
  const alcoholPreference = useSelector(
    (state) => state.registration.alcoholPreference
  );
  const skippableJourney = useSelector(
    (state) => state.registration.skippableJourney
  );
  const registrationNavigationData = useSelector(
    (state) => state.registration.registrationNavigationData
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getJourneyQuestions(null, true);
  }, []);

  // Lifecycle | Check for update | updateJourney API Response
  useEffect(() => {
    if (updateJourneyLoading) {
    } else if (updateJourneySuccess) {
      dispatch(updateJourneyDataPassthrough(updateJourneyData));
      dispatch(updateJourneyNavigationPassthrough({}));
    } else if (updateJourneyError) {
      const warningToast = {
        message: updateJourneyErrorData?.data?.message,
        autoClose: 3000,
      };
      dispatch(updateErrorToast(warningToast));
    }
  }, [updateJourneyLoading, updateJourneySuccess, updateJourneyError]);

  // Lifecycle | Check for update | skipJourney API Response
  useEffect(() => {
    if (skipJourneyLoading) {
    } else if (skipJourneySuccess) {
      const updatedItems = registrationNavigationData?.map((item, i) => ({
        ...item,
        skipped: i === 11 ? true : i > 11 ? false : item.skipped,
      }));
      dispatch(updateRegistrationNavigationData(updatedItems));

      dispatch(updateJourneyDataPassthrough(updateJourneyData));
      dispatch(updateJourneyNavigationPassthrough({}));
    } else if (skipJourneyError) {
    }
  }, [skipJourneyLoading, skipJourneySuccess, skipJourneyError]);

  // Event Handlers | Button
  const onSelectAlcoholPreference = (alcohol) => {
    let alcoholPreferenceTemp = [...alcoholPreference];
    if (alcoholPreferenceTemp.includes(alcohol)) {
      alcoholPreferenceTemp = alcoholPreferenceTemp.filter(
        (item) => item?.id !== alcohol?.id
      );
    } else {
      if (alcoholPreferenceTemp?.length < returnMaxAlcoholPreference()) {
        alcoholPreferenceTemp.push(alcohol);
      }
    }
    dispatch(updateAlcoholPreference(alcoholPreferenceTemp));
  };
  const onNextPage = () => {
    if (!isFormValid()) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-62-62.12-62.12.1",
      },
    });

    const obj = {
      journey_step: "12",
      journey_data: {
        profile: {
          alcohol: alcoholPreference?.map((item) => item.id),
        },
      },
    };
    updateJourney(obj);
  };
  const onSkip = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-62-62.12-62.12.2",
      },
    });

    const obj = {
      journey_step: "12",
      journey_data: {
        looking_for: {},
      },
    };
    skipJourney(obj);
  };

  // Utility Functions
  const isFormValid = () => {
    return (
      alcoholPreference?.length >= returnMinAlcoholPreference() &&
      alcoholPreference?.length <= returnMaxAlcoholPreference() &&
      !updateJourneyLoading
    );
  };
  const getAlcoholPreferenceData = () => {
    return getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "alcohol"
    )?.selections;
  };
  const checkSelectedAlcoholPreference = (alcohol) => {
    return alcoholPreference?.find((item) => item?.id === alcohol?.id);
  };
  const getAlcoholIcon = (alcohol) => {
    switch (alcohol) {
      case "red-wine":
        return getIcon("redWineIcon", "alcohol-icon");
      case "white-wine":
        return getIcon("whiteWineIcon", "alcohol-icon");
      case "champagne":
        return getIcon("champagneIcon", "alcohol-icon");
      case "cognac":
        return getIcon("cognacIcon", "alcohol-icon");
      case "tequila":
        return getIcon("tequilaIcon", "alcohol-icon");
      case "beer":
        return getIcon("beerIcon", "alcohol-icon");
      case "cocktail":
        return getIcon("cocktailIcon", "alcohol-icon");
      case "vodka":
        return getIcon("vodkaIcon", "alcohol-icon");
      case "brandy":
        return getIcon("brandyIcon", "alcohol-icon");
      case "highball":
        return getIcon("highballIcon", "alcohol-icon");
      case "whiskey":
        return getIcon("whiskeyIcon", "whiskey-icon");
      case "liqueur":
        return getIcon("liqueurIcon", "alcohol-icon");
      case "absinthe":
        return getIcon("absintheIcon", "alcohol-icon");
      case "rum":
        return getIcon("rumIcon", "alcohol-icon");
      case "sangria":
        return getIcon("sangriaIcon", "alcohol-icon");
      case "gin":
        return getIcon("ginIcon", "alcohol-icon");
      case "sake":
        return getIcon("sakeIcon", "alcohol-icon");
      case "soju":
        return getIcon("sojuIcon", "alcohol-icon");
      case "martini":
        return getIcon("martiniIcon", "alcohol-icon");
      case "shots":
        return getIcon("shotsIcon", "alcohol-icon");
    }
  };
  const returnMinAlcoholPreference = () => {
    return (
      getJourneyQuestionsData?.questions?.find(
        (item) => item?.category === "alcohol"
      )?.min_selection || 1
    );
  };
  const returnMaxAlcoholPreference = () => {
    return (
      getJourneyQuestionsData?.questions?.find(
        (item) => item?.category === "alcohol"
      )?.max_selection || 5
    );
  };

  return (
    <div id="details-signup-alcohol-preference-details-subcomponent">
      <div className="top-container">
        <div className="alcohol-preference-details-label">
          {t("registration.alcohol.title")}
        </div>

        {getJourneyQuestionsFetching || getJourneyQuestionsLoading ? (
          <Spinner />
        ) : (
          <div className="alcohol-preference-body-container">
            {getAlcoholPreferenceData()?.map((item, index) => (
              <div
                className={`alcohol-preference-item ${
                  checkSelectedAlcoholPreference(item) ? "selected" : ""
                }`}
                onClick={() => onSelectAlcoholPreference(item)}
                key={index}
              >
                <div className="alcohol-icon-container">
                  {getAlcoholIcon(item?.tag)}
                </div>

                <div className="alcohol-item-label">{item?.display_locale}</div>

                {checkSelectedAlcoholPreference(item) && (
                  <div className="selected-check-mark-container">
                    <CheckCircleRoundedIcon className="selected-check-mark" />
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="bottom-container">
        <div
          className={`continue-button ${
            isMobile ? "full-width" : "fit-content"
          } ${isFormValid() ? "" : "disabled-button"}`}
          onClick={onNextPage}
        >
          {updateJourneyLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("profile.tor_selected", {
              n: alcoholPreference?.length,
              max: returnMaxAlcoholPreference(),
            })
          )}
        </div>

        <div className="skip-button" onClick={onSkip}>
          {skipJourneyLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("common.skip")
          )}
        </div>
      </div>
    </div>
  );
};

export default AlcoholPreferenceDetails;
