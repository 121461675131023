// General
import "./upgrade-promo-dialog.scss";
import { useState, useEffect } from "react";
// Services
import { useApplyPromoCodeMutation } from "../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateUpgradePromoDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, TextField } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Components
import Spinner from "../../elements/spinner/spinner";

const UpgradePromoDialog = () => {
  // API variables
  const [
    applyPromoCode,
    {
      data: applyPromoCodeData,
      error: applyPromoCodeErrorData,
      isLoading: applyPromoCodeLoading,
      isSuccess: applyPromoCodeSuccess,
      isError: applyPromoCodeError,
    },
  ] = useApplyPromoCodeMutation();

  // General variables
  const [promoCode, setPromoCode] = useState("");

  // Redux variables
  const upgradePromoDialog = useSelector(
    (state) => state.dialog.upgradePromoDialog
  );
  const utmSource = useSelector((state) => state.tracking.utmSource);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!upgradePromoDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-UpgradePromo-Dialog",
      },
    });
  }, [upgradePromoDialog]);

  // Lifecycle | Check for update | applyPromoCode API Response
  useEffect(() => {
    if (applyPromoCodeLoading) {
    } else if (applyPromoCodeSuccess) {
      switch (applyPromoCodeData?.status) {
        case -2:
          break;
        default:
          onCloseDialog();
          break;
      }
    } else if (applyPromoCodeError) {
    }
  }, [applyPromoCodeLoading, applyPromoCodeSuccess, applyPromoCodeError]);

  // Event Handlers | Button
  const onSubmit = () => {
    let obj = {
      promo_code: promoCode,
    };

    if (
      utmSource?.utm_source &&
      utmSource?.utm_medium &&
      utmSource?.utm_campaign
    ) {
      obj.utm_source = utmSource?.utm_source;
      obj.utm_medium = utmSource?.utm_medium;
      obj.utm_campaign = utmSource?.utm_campaign;
    }

    applyPromoCode(obj);
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateUpgradePromoDialog(false));
  };

  // Event Handlers | MUI TextField
  const onPromoCodeChange = (event) => {
    setPromoCode(event.target.value);
  };

  return (
    <Dialog
      className="custom-radius10-dialog"
      open={upgradePromoDialog}
      onClose={onCloseDialog}
    >
      <div id="upgrade-promo-dialog">
        <div className="padding-container">
          <div className="promo-text-field-container">
            <div className="enter-code-label">{t("upgrade.enter_code")}</div>

            <TextField
              className="promo-form-field custom-text-field"
              value={promoCode}
              onChange={onPromoCodeChange}
              placeholder={t("upgrade.enter_your_code")}
              helperText={applyPromoCodeData?.message ?? ""}
              variant="standard"
            />
          </div>

          <div className="buttons-container">
            <div className="submit-button" onClick={onSubmit}>
              {applyPromoCodeLoading ? (
                <Spinner size={20} isPadding={false} color={"white-spinner"} />
              ) : (
                t("common.submit")
              )}
            </div>
            <div className="cancel-button" onClick={onCloseDialog}>
              {t("common.cancel")}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default UpgradePromoDialog;
