// General
import "./livestream-stats.scss";
import { useEffect } from "react";
// Services
import { useLazyGetLastStreamResultQuery } from "../../../../../services/data.service";
// Material UI
import { Divider } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCompactNumbers from "../../../../utility/custom-hooks/useCompactNumbers-hook";
// Components
import Spinner from "../../../../shared/elements/spinner/spinner";

const LivestreamStats = () => {
  // API variables
  const [
    getLastStreamSummary,
    {
      data: getLastStreamSummaryData,
      error: getLastStreamSummaryErrorData,
      isFetching: getLastStreamSummaryFetching,
      isLoading: getLastStreamSummaryLoading,
      isSuccess: getLastStreamSummarySuccess,
      isError: getLastStreamSummaryError,
    },
  ] = useLazyGetLastStreamResultQuery();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getCompactNumbers = useCompactNumbers();

  // Lifecycle | Mounted
  useEffect(() => {
    getLastStreamSummary(null, true);
  }, []);

  if (getLastStreamSummaryFetching || getLastStreamSummaryLoading) {
    return <Spinner />;
  } else if (getLastStreamSummarySuccess) {
    if (getLastStreamSummaryData?.status === 1) {
      return (
        <div id="last-stream-summary-livestream-stats-subcomponent">
          <div className="viewers-container">
            <div className="viewers">
              {getLastStreamSummaryData?.data?.stats?.viewers_count || 0}
            </div>
            <div className="viewers-label">{t("live.viewers")}</div>
          </div>

          <Divider orientation="vertical" flexItem />

          <div className="following-container">
            <div className="following">
              {getLastStreamSummaryData?.data?.stats?.followers_count || 0}
            </div>
            <div className="following-label">{t("common.following")}</div>
          </div>

          <Divider orientation="vertical" flexItem />

          <div className="diamonds-container">
            <div className="diamonds">
              {getCompactNumbers(
                getLastStreamSummaryData?.data?.diamonds?.balance
              ) || 0}
            </div>
            <div className="diamonds-label">{t("live.diamonds")}</div>
          </div>
        </div>
      );
    }
  } else if (getLastStreamSummaryError) {
  }
};

export default LivestreamStats;
