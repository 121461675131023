// General
import "./geolocation-permission-denied-dialog.scss";
import { useState, useEffect } from "react";
// Services
import { useUpdateUserLocationMutation } from "../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateGeolocationPermissionDeniedDialog } from "../../../../redux/store/dialogStore";
// Material UI
import { Dialog } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const GeolocationPermissionDeniedDialog = () => {
  // General variables
  const [backdropCounter, setBackdropCounter] = useState(0);

  // Redux variables
  const geolocationPermissionDeniedDialog = useSelector(
    (state) => state.dialog.geolocationPermissionDeniedDialog
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (geolocationPermissionDeniedDialog) {
    } else {
    }
  }, [geolocationPermissionDeniedDialog]);

  // Lifecycle | Check for update | backdropCounter | Easter Egg to close dialog
  useEffect(() => {
    if (backdropCounter < 10) return;

    dispatch(updateGeolocationPermissionDeniedDialog(false));
  }, [backdropCounter]);

  // Event Handlers | Button
  const onVisitFaq = () => {
    onCloseDialog();
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = (event, reason) => {
    setBackdropCounter((prevBackdropCounter) => prevBackdropCounter + 1);

    if (reason === "backdropClick") return;

    dispatch(updateGeolocationPermissionDeniedDialog(false));
  };

  return (
    <Dialog
      className="custom-radius35-dialog"
      open={geolocationPermissionDeniedDialog}
      onClose={onCloseDialog}
    >
      <div id="geolocation-permission-denied-dialog">
        <div className="location-permission-denied-top-container">
          <div className="location-permission-denied-icon-container">
            {getIcon(
              "locationPermissionDeniedIcon",
              "location-permission-denied-icon"
            )}
          </div>
        </div>

        <div className="padding-container">
          <div className="geolocation-prompt-title">
            {t("location.location_permission_denied_title")}
          </div>
          <div className="geolocation-prompt-desc">
            {t("location.location_permission_denied_desc")}
          </div>

          <div className="buttons-container">
            <div className="faq-button" onClick={onVisitFaq}>
              {t("location.visit_faq")}
            </div>

            <div className="close-button" onClick={onCloseDialog}>
              {t("common.close")}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default GeolocationPermissionDeniedDialog;
