// General
import "./profile-financial-information.scss";
import { useEffect } from "react";
// Services
import { useLazyGetProfileQuery } from "../../../../../services/data.service";
// Static Data
import editProfileConst from "../../../../../const/editProfileConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateLookingForAgeRange,
  updateLookingForLocation,
  updateLookingForEthnicityRace,
  updateUpdateProfileDialogView,
  updateLifestyle,
  updateDatingBudget,
  updateAnnualIncome,
  updateNetworth,
} from "../../../../../redux/store/editProfileStore";
import { updateProfileCompletionPercentage } from "../../../../../redux/store/profileStore";
import {
  updateUpdateProfileFullscreenDialog,
  updateRestartRegistrationDialog,
} from "../../../../../redux/store/dialogStore";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";

const ProfileFinancialInformation = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // Redux variables
  const lifestyle = useSelector((state) => state.userCard.lifestyle);
  const datingBudget = useSelector((state) => state.userCard.datingBudget);
  const networth = useSelector((state) => state.userCard.networth);
  const annualIncome = useSelector((state) => state.userCard.annualIncome);
  const profileCompletionPercentage = useSelector(
    (state) => state.profile.profileCompletionPercentage
  );
  const profileRequirementPopup = useSelector(
    (state) => state.profile.profileRequirementPopup
  );
  const isSelf = useSelector((state) => state.userCard.isSelf);
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  // Lifecycle | Check for update | getProfileData
  useEffect(() => {
    if (getProfileFetching || getProfileLoading) {
    } else if (getProfileSuccess) {
      // dispatch(
      //   updateProfileCompletionPercentage(
      //     getProfileData?.data?.profile_progress?.summary?.progress
      //   )
      // );

      dispatch(
        updateLifestyle(getProfileData?.data?.occupation?.lifestyle_budget)
      );
      dispatch(
        updateDatingBudget(getProfileData?.data?.relationship?.dating_budget)
      );
      dispatch(
        updateAnnualIncome(getProfileData?.data?.occupation?.annual_income)
      );
      dispatch(updateNetworth(getProfileData?.data?.occupation?.networth));
    } else if (getProfileError) {
    }
  }, [
    getProfileFetching,
    getProfileLoading,
    getProfileSuccess,
    getProfileError,
  ]);

  // Event Handlers | Button
  const onEditSection = (section) => {
    if (isSelf) return;

    if (profileCompletionPercentage < profileRequirementPopup) {
      dispatch(updateRestartRegistrationDialog(true));
    } else {
      switch (section) {
        case editProfileConst.profileDialogView.lifestyle:
          if (isDaddy) {
            dispatch(
              updateUpdateProfileDialogView(
                editProfileConst.profileDialogView.datingBudget
              )
            );
          } else {
            dispatch(
              updateUpdateProfileDialogView(
                editProfileConst.profileDialogView.lifestyle
              )
            );
          }
          break;
        case editProfileConst.profileDialogView.datingBudget:
          if (isDaddy) {
            dispatch(
              updateUpdateProfileDialogView(
                editProfileConst.profileDialogView.datingBudget
              )
            );
          } else {
            dispatch(
              updateUpdateProfileDialogView(
                editProfileConst.profileDialogView.lifestyle
              )
            );
          }
          break;
        case editProfileConst.profileDialogView.annualIncome:
          if (isDaddy) {
            dispatch(
              updateUpdateProfileDialogView(
                editProfileConst.profileDialogView.annualIncome
              )
            );
          }
          break;
        case editProfileConst.profileDialogView.networth:
          if (isDaddy) {
            dispatch(
              updateUpdateProfileDialogView(
                editProfileConst.profileDialogView.networth
              )
            );
          }
          break;
        default:
          break;
      }

      dispatch(updateUpdateProfileFullscreenDialog(true));
    }
  };

  if (lifestyle || datingBudget || networth || annualIncome) {
    return (
      <div id="profile-financial-information-subcomponent">
        <div className="padding-container">
          <div className="financials-label">{t("profile.financials")}</div>

          <div className="financials-container">
            {lifestyle && (
              <div
                className={`financials-item-container ${
                  isSelf ? "non-clickable" : ""
                }`}
                onClick={() => onEditSection("lifestyle")}
              >
                {getIcon("lifestyleBudgetIcon", "lifestyle-budget-icon")}
                {t("profile.lifestyle")} {lifestyle?.locale}
              </div>
            )}

            {datingBudget && (
              <div
                className={`financials-item-container ${
                  isSelf ? "non-clickable" : ""
                }`}
                onClick={() => onEditSection("datingBudget")}
              >
                {getIcon("datingBudgetIcon", "dating-budget-icon")}
                {t("profile.dating_budget")} {datingBudget?.locale}
              </div>
            )}

            {annualIncome && (
              <div
                className={`financials-item-container ${
                  isSelf ? "non-clickable" : ""
                }`}
                onClick={() => onEditSection("annualIncome")}
              >
                {getIcon("annualIncomeIcon", "annual-income-icon")}
                {t("profile.income")} {annualIncome?.locale}
              </div>
            )}

            {networth && (
              <div
                className={`financials-item-container ${
                  isSelf ? "non-clickable" : ""
                }`}
                onClick={() => onEditSection("networth")}
              >
                {getIcon("networthIcon", "networth-icon")}
                {t("profile.networth")} {networth?.locale}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default ProfileFinancialInformation;
