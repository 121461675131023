// General
import { createSlice } from "@reduxjs/toolkit";
// Services
import { sessionService } from "../../services/session.service";
// Static Data
import utilityConst from "../../const/utilityConst";

const initialState = {
  // Hidden Settings variables
  showHiddenSettings: false,

  // Channel variables
  releaseChannel: utilityConst.releaseChannel.stable, // nightly, beta, stable

  // Mobile Navbar Theme variables
  mobileNavbarTheme: utilityConst.mobileNavbarTheme.default,

  // Icon Theme variables
  iconTheme: utilityConst.iconTheme.default,

  // Dark Mode variables
  isDarkMode: false,
  darkTheme: "oled", // oled, grey

  // Screen Orientation variables
  screenOrientation: utilityConst.screenOrientation.portrait, // portrait, landscape

  // Navbar Controller variables
  showVideoCall: false,

  // Background variables
  sugarbookTheme: utilityConst.sugarbookTheme.default,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    // Hidden Settings Functions
    updateShowHiddenSettings: (state, action) => {
      state.showHiddenSettings = action.payload;
      sessionService.setShowHiddenSettings(action.payload ?? false);
    },

    // Channel Functions
    updateReleaseChannel: (state, action) => {
      state.releaseChannel = action.payload;
      sessionService.setReleaseChannel(
        action.payload ?? utilityConst.releaseChannel.stable
      );
    },

    // Mobile Navbar Theme Functions
    updateMobileNavbarTheme: (state, action) => {
      state.mobileNavbarTheme = action.payload;
      sessionService.setMobileNavbarTheme(
        action.payload ?? utilityConst.mobileNavbarTheme.default
      );
    },

    // Icon Theme Functions
    updateIconTheme: (state, action) => {
      state.iconTheme = action.payload;
      sessionService.setIconTheme(
        action.payload ?? utilityConst.iconTheme.default
      );
    },

    // Dark Mode Functions
    updateIsDarkMode: (state, action) => {
      state.isDarkMode = action.payload;
      sessionService.setDarkMode(action.payload ?? false);
    },
    updateDarkTheme: (state, action) => {
      state.darkTheme = action.payload;
      sessionService.setDarkTheme(action.payload ?? "oled");
    },

    // Orientation Functions
    updateScreenOrientation: (state, action) => {
      state.screenOrientation = action.payload;
    },

    // Utility Functions
    resetAppStore: () => {
      return { ...initialState };
    },
  },
});

export const {
  // Hidden Settings Functions
  updateShowHiddenSettings,

  // Channel Functions
  updateReleaseChannel,

  // Mobile Navbar Theme Functions
  updateMobileNavbarTheme,

  // Icon Theme Functions
  updateIconTheme,

  // Dark Mode Functions
  updateIsDarkMode,
  updateDarkTheme,

  // Orientation Functions
  updateScreenOrientation,

  // Utility Functions
  resetAppStore,
} = appSlice.actions;

export default appSlice.reducer;
