// General
import "./onboarding-sugarbook-verified-dialog.scss";
import { useEffect, forwardRef } from "react";
// Services
import { useLazyGetProfileQuery } from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateOnboardingSugarbookVerifiedDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../../elements/custom-avatar/custom-avatar";

const OnboardingSugarbookVerifiedDialog = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // Redux variables
  const onboardingSugarbookVerifiedDialog = useSelector(
    (state) => state.dialog.onboardingSugarbookVerifiedDialog
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!onboardingSugarbookVerifiedDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-D36",
      },
    });

    getProfile(null, true);
  }, [onboardingSugarbookVerifiedDialog]);

  // Event Handlers | Button
  const onVerifyProfile = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-D36-36.1",
      },
    });

    onNavigate(routeConst.verify.sugarbookVerified.path);
    onCloseDialog();
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = (event, reason) => {
    if (
      reason === "backdropClick" ||
      reason === "clickaway" ||
      reason === "escapeKeyDown"
    )
      return;

    dispatch(updateOnboardingSugarbookVerifiedDialog(false));
  };

  return (
    <Dialog
      className={
        isMobile
          ? "custom-top-radius20-dialog custom-dialog-margin-top"
          : "custom-radius25-dialog"
      }
      fullScreen={isMobile ? true : false}
      open={onboardingSugarbookVerifiedDialog}
      TransitionComponent={Transition}
      maxWidth="xs"
      onClose={onCloseDialog}
    >
      <div id="onboarding-sugarbook-verified-dialog">
        <div className="dialog-header">
          <div className="close-button-container">
            <CloseIcon className="close-button" onClick={onCloseDialog} />
          </div>
        </div>

        <div className="padding-container">
          <div className="verified-profile-picture-container">
            <CustomAvatar
              className="own-profile-picture"
              src={
                getProfileData?.data?.profile_photo?.photo_status !== "REJECTED"
                  ? getProfileData?.data?.profile_photo?.original_photo
                  : null
              }
              role={getProfileData?.data?.role}
            />

            <div className="verified-profile-badge-container">
              {getIcon("verifiedProfileBadge", "verified-profile-badge")}
            </div>
          </div>

          <div className="verified-top-container">
            <div className="verified-title">
              {t("verification.build_trust_with")}
            </div>
            <div className="verified-title-alt">
              {t("verification.sugarbook_verified")}
            </div>
          </div>

          <div className="sugarbook-verified-perks-container">
            <div className="verified-badge-container">
              <div className="verified-icons-container">
                {getIcon(
                  "verifiedProfileOutlineBadge",
                  "verified-profile-outline-badge"
                )}
              </div>

              <div className="verified-content-container">
                <div className="verified-label">
                  {t("verification.verified_badge")}
                </div>
                <div className="verified-description">
                  {t("verification.verified_badge_desc")}
                </div>
              </div>
            </div>

            <div className="date-with-confidence">
              <div className="verified-icons-container">
                {getIcon("shieldCheckOutlineIcon", "shield-icon")}
              </div>

              <div className="verified-content-container">
                <div className="verified-label">
                  {t("verification.date_with_confidence")}
                </div>
                <div className="verified-description">
                  {t("verification.date_with_confidence_desc")}
                </div>
              </div>
            </div>

            <div className="safety-and-security">
              <div className="verified-icons-container">
                {getIcon("safetyOutlineIcon", "safety-icon")}
              </div>

              <div className="verified-content-container">
                <div className="verified-label">
                  {t("verification.safety_and_security")}
                </div>
                <div className="verified-description">
                  {t("verification.safety_and_security_desc")}
                </div>
              </div>
            </div>
          </div>

          <div className="get-verified-now-button" onClick={onVerifyProfile}>
            {t("verification.get_verified_now")}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default OnboardingSugarbookVerifiedDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
