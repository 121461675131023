// General
import "./masquerade-party-card.scss";
// Redux
import { useDispatch } from "react-redux";
import { updateSelectedCategoryTab } from "../../../../redux/store/searchStore";
// react-gtm-module
import TagManager from "react-gtm-module";

const MasqueradePartyCard = (props) => {
  const { backgroundImage } = props;

  // Redux variables
  const dispatch = useDispatch();

  // Event Handlers | Button
  const onRedirectToMasqueradeTab = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-S10-10.1-Button",
      },
    });

    dispatch(updateSelectedCategoryTab("masquerade_party"));

    const categoryTabElement = document.querySelector(".category-container");
    if (categoryTabElement) {
      categoryTabElement.scrollTo({
        left: categoryTabElement.scrollWidth,
        behavior: "smooth",
      });
    }
  };

  return (
    <div
      id="masquerade-party-card-shared-subcomponent"
      onClick={onRedirectToMasqueradeTab}
    >
      <div className="masquerade-party-card-container">
        <div className="masquerade-party-image-container">
          <img
            className="masquerade-party-image"
            src={backgroundImage}
            alt={"masquerade-party-background"}
          />
        </div>
      </div>
    </div>
  );
};

export default MasqueradePartyCard;
