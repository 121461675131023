// General
import "./update-email.scss";
import { useState, useEffect } from "react";
// Services
import { useUpdateAccountEmailMutation } from "../../../../services/data.service";
import {
  emailRegex,
  removeHTMLEscape,
} from "../../../../services/regex.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateSuccessToast,
  updateWarningToast,
} from "../../../../redux/store/toastStore";
import {
  updateOldEmail,
  updateNewEmail,
} from "../../../../redux/store/userStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Sentry
import * as Sentry from "@sentry/react";
// Material UI
import {
  Box,
  TextField,
  InputAdornment,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import UtilityDesktopNavbar from "../../../shared/elements/utility-desktop-navbar/utility-desktop-navbar";
import Spinner from "../../../shared/elements/spinner/spinner";

const UpdateEmail = () => {
  // API variables
  const [
    updateAccountEmail,
    {
      data: updateAccountEmailData,
      error: updateAccountEmailErrorData,
      isLoading: updateAccountEmailLoading,
      isSuccess: updateAccountEmailSuccess,
      isError: updateAccountEmailError,
    },
  ] = useUpdateAccountEmailMutation();

  // General variables
  const [oldEmailErrorMessage, setOldEmailErrorMessage] = useState("");
  const [oldEmailFocus, setOldEmailFocus] = useState(false);
  const [oldEmailValid, setOldEmailValid] = useState(false);
  const [oldEmailError, setOldEmailError] = useState(false);
  const [newEmailErrorMessage, setNewEmailErrorMessage] = useState("");
  const [newEmailFocus, setNewEmailFocus] = useState(false);
  const [newEmailValid, setNewEmailValid] = useState(false);
  const [newEmailError, setNewEmailError] = useState(false);
  const [confirmEmail, setConfirmEmail] = useState("");
  const [confirmEmailErrorMessage, setConfirmEmailErrorMessage] = useState("");
  const [confirmEmailFocus, setConfirmEmailFocus] = useState(false);
  const [confirmEmailValid, setConfirmEmailValid] = useState(false);
  const [confirmEmailError, setConfirmEmailError] = useState(false);

  // Redux variables
  const changeEmail = useSelector((state) => state.user.changeEmail);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-37",
      },
    });

    dispatch(updateOldEmail(""));
    dispatch(updateNewEmail(""));
  }, []);

  // Lifecycle | Check for update | Check for email validation
  useEffect(() => {
    emailValidation("old-email");
    emailValidation("new-email");
    emailValidation("confirm-email");
  }, [
    changeEmail.oldEmail,
    changeEmail.newEmail,
    confirmEmail,
    oldEmailFocus,
    newEmailFocus,
    confirmEmailFocus,
    oldEmailErrorMessage,
    newEmailErrorMessage,
    confirmEmailErrorMessage,
  ]);

  // Lifecycle | Check for update | Update Account Email API Response
  useEffect(() => {
    if (updateAccountEmailLoading) {
    } else if (updateAccountEmailSuccess) {
      switch (updateAccountEmailData?.status) {
        case 0:
        case 1:
          const successToastObj = {
            message: updateAccountEmailData?.message,
            autoClose: 3000,
          };
          dispatch(updateSuccessToast(successToastObj));

          onNavigate(routeConst.settings.updateEmail.verification.path);
          break;
        default:
          const warningToastObj = {
            message: updateAccountEmailData?.message,
            autoClose: 3000,
          };
          dispatch(updateWarningToast(warningToastObj));
          break;
      }
    } else if (updateAccountEmailError) {
    }
  }, [
    updateAccountEmailLoading,
    updateAccountEmailSuccess,
    updateAccountEmailError,
  ]);

  // Event Handlers | Button
  const onUpdateEmail = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-37-37.1-Button",
      },
    });

    const accountEmailObj = {
      current_email: changeEmail.oldEmail,
      new_email: changeEmail.newEmail,
      new_email_confirmation: changeEmail.newEmail,
    };

    updateAccountEmail(accountEmailObj);
  };

  // Event Handlers | MUI Text Fields
  const onEmailChange = (type) => (e) => {
    const value = e.target.value;

    switch (type) {
      case "old-email":
        if (removeHTMLEscape.test(value)) {
          dispatch(updateOldEmail(""));

          // Send to Sentry
          Sentry.captureMessage(
            "PWA-37-OldEmail-Input-HTML-Escape-Detected"
          );

          // Send to GTM
          TagManager.dataLayer({
            dataLayer: {
              event: "PWA-37-OldEmail-Input-HTML-Escape-Detected",
            },
          });
        } else {
          dispatch(updateOldEmail(value));
        }
        break;
      case "new-email":
        if (removeHTMLEscape.test(value)) {
          dispatch(updateNewEmail(""));

          // Send to Sentry
          Sentry.captureMessage(
            "PWA-37-NewEmail-Input-HTML-Escape-Detected"
          );

          // Send to GTM
          TagManager.dataLayer({
            dataLayer: {
              event: "PWA-37-NewEmail-Input-HTML-Escape-Detected",
            },
          });
        } else {
          dispatch(updateNewEmail(value));
        }
        break;
      case "confirm-email":
        if (removeHTMLEscape.test(value)) {
          setConfirmEmail("");

          // Send to Sentry
          Sentry.captureMessage(
            "PWA-37-ConfirmEmail-Input-HTML-Escape-Detected"
          );

          // Send to GTM
          TagManager.dataLayer({
            dataLayer: {
              event:
                "PWA-37-ConfirmEmail-Input-HTML-Escape-Detected",
            },
          });
        } else {
          setConfirmEmail(value);
        }
        break;
      default:
        break;
    }
  };
  const onEmailFocus = (type) => (e) => {
    switch (type) {
      case "old-email":
        setOldEmailFocus(true);
        break;
      case "new-email":
        setNewEmailFocus(true);
        break;
      case "confirm-email":
        setConfirmEmailFocus(true);
        break;
      default:
        break;
    }
  };
  const handleClickClearEmail = (type) => () => {
    switch (type) {
      case "old-email":
        dispatch(updateOldEmail(""));
        break;
      case "new-email":
        dispatch(updateNewEmail(""));
        break;
      case "confirm-email":
        setConfirmEmail("");
        break;
      default:
        break;
    }
  };
  const handleMouseDownEmail = (e) => {
    e.preventDefault();
  };

  // Utility Functions
  const isFormValid = () => {
    let valid = false;
    if (oldEmailValid && newEmailValid && confirmEmailValid) {
      valid = true;
    }

    return valid;
  };
  const emailValidation = (type) => {
    let valid = true;
    let error = false;
    const regex = emailRegex;

    switch (type) {
      case "old-email":
        if (!oldEmailFocus) {
          valid = false;
          error = false;
        } else if (oldEmailFocus && changeEmail.oldEmail === "") {
          setOldEmailErrorMessage("Email cannot be empty");
          valid = false;
          error = true;
        } else if (!regex.test(changeEmail.oldEmail)) {
          setOldEmailErrorMessage("Invalid email");
          valid = false;
          error = true;
        } else {
          setOldEmailErrorMessage("");
          valid = true;
          error = false;
        }

        setOldEmailValid(valid);
        setOldEmailError(error);
        break;
      case "new-email":
        if (!newEmailFocus) {
          valid = false;
          error = false;
        } else if (newEmailFocus && changeEmail.newEmail === "") {
          setNewEmailErrorMessage("Email cannot be empty");
          valid = false;
          error = true;
        } else if (!regex.test(changeEmail.newEmail)) {
          setNewEmailErrorMessage("Invalid email");
          valid = false;
          error = true;
        } else {
          setNewEmailErrorMessage("");
          valid = true;
          error = false;
        }

        setNewEmailValid(valid);
        setNewEmailError(error);
        break;
      case "confirm-email":
        if (!confirmEmailFocus) {
          valid = false;
          error = false;
        } else if (confirmEmailFocus && confirmEmail === "") {
          setConfirmEmailErrorMessage("Email cannot be empty");
          valid = false;
          error = true;
        } else if (changeEmail.newEmail !== confirmEmail) {
          setConfirmEmailErrorMessage("Email do not match");
          valid = false;
          error = true;
        } else if (!regex.test(confirmEmail)) {
          setConfirmEmailErrorMessage("Invalid email");
          valid = false;
          error = true;
        } else {
          setConfirmEmailErrorMessage("");
          valid = true;
          error = false;
        }

        setConfirmEmailValid(valid);
        setConfirmEmailError(error);
        break;
      default:
        break;
    }
  };

  return (
    <Box id="update-email-page" component="form" noValidate autoComplete="off">
      <div className="max-width-container">
        <UtilityDesktopNavbar leftButton={"back"} />

        <div className="padding-container">
          <div className="old-email-form-field-container">
            <TextField
              className="email-form-field custom-text-field"
              required
              value={changeEmail.oldEmail}
              error={oldEmailError}
              onChange={onEmailChange("old-email")}
              label={t("settings.old_email")}
              type="email"
              placeholder={t("settings.old_email")}
              helperText={oldEmailErrorMessage}
              variant="standard"
              onFocus={onEmailFocus("old-email")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {changeEmail.oldEmail && (
                      <IconButton
                        onClick={handleClickClearEmail("old-email")}
                        onMouseDown={handleMouseDownEmail}
                        edge="end"
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div className="new-email-form-field-container">
            <TextField
              className="email-form-field"
              sx={{
                "& .MuiInput-underline:after": { borderBottomColor: "#710D0D" },
                "& label.Mui-focused": { color: "rgba(0, 0, 0, 0.6)" },
              }}
              required
              value={changeEmail.newEmail}
              error={newEmailError}
              onChange={onEmailChange("new-email")}
              label={t("settings.new_email")}
              type="email"
              placeholder={t("settings.new_email")}
              helperText={newEmailErrorMessage}
              variant="standard"
              onFocus={onEmailFocus("new-email")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {changeEmail.newEmail && (
                      <IconButton
                        onClick={handleClickClearEmail("new-email")}
                        onMouseDown={handleMouseDownEmail}
                        edge="end"
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div className="confirm-email-form-field-container">
            <TextField
              className="email-form-field custom-text-field"
              required
              value={confirmEmail}
              error={confirmEmailError}
              onChange={onEmailChange("confirm-email")}
              label={t("settings.confirm_email")}
              type="email"
              placeholder={t("settings.confirm_email")}
              helperText={confirmEmailErrorMessage}
              variant="standard"
              onFocus={onEmailFocus("confirm-email")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {confirmEmail && (
                      <IconButton
                        onClick={handleClickClearEmail("confirm-email")}
                        onMouseDown={handleMouseDownEmail}
                        edge="end"
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <Button
            className={`update-email-button ${
              isFormValid() ? "" : "disabled-button"
            }`}
            variant="text"
            onClick={onUpdateEmail}
            disabled={!isFormValid()}
          >
            {updateAccountEmailLoading ? (
              <Spinner size={24} isPadding={false} color={"white-spinner"} />
            ) : (
              t("settings.update_email")
            )}
          </Button>

          <div className="bottom-text">
            {t("settings.email_verification_code")}
          </div>
        </div>
      </div>
    </Box>
  );
};

export default UpdateEmail;
