// General
import "./edit-ethnicity.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetJourneyQuestionsQuery,
  useUpdateEditUserProfileMutation,
} from "../../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateEthnicity,
  updateRace,
  updateUpdateProfileFullscreenTab,
  updateIsUnsavedChanges,
} from "../../../../../redux/store/editProfileStore";
import {
  updateProfileCompletionPercentage,
  updateProfileRequirementPopup,
} from "../../../../../redux/store/profileStore";
import { updateUpdateProfileFullscreenDialog } from "../../../../../redux/store/dialogStore";
// Material UI
import { MenuItem, TextField } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import IconManager from "../../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../../elements/spinner/spinner";

const EditEthnicity = (props) => {
  const { addPaddingTop = false } = props;

  // API variables
  const [
    getJourneyQuestions,
    {
      data: getJourneyQuestionsData,
      error: getJourneyQuestionsErrorData,
      isFetching: getJourneyQuestionsFetching,
      isLoading: getJourneyQuestionsLoading,
      isSuccess: getJourneyQuestionsSuccess,
      isError: getJourneyQuestionsError,
    },
  ] = useLazyGetJourneyQuestionsQuery();
  const [
    updateProfile,
    {
      data: updateProfileData,
      error: updateProfileErrorData,
      isLoading: updateProfileLoading,
      isSuccess: updateProfileSuccess,
      isError: updateProfileError,
    },
  ] = useUpdateEditUserProfileMutation();

  // General variables
  const [localEthnicityId, setLocalEthnicityId] = useState(null);
  const [localEthnicity, setLocalEthnicity] = useState(null);
  const [localRaceId, setLocalRaceId] = useState(null);
  const [localRace, setLocalRace] = useState(null);

  // Redux variables
  const ethnicity = useSelector((state) => state.editProfile.ethnicity);
  const race = useSelector((state) => state.editProfile.race);
  const profileCompletionState = useSelector(
    (state) => state.editProfile.profileCompletionState
  );
  const updateProfileFullscreenTab = useSelector(
    (state) => state.editProfile.updateProfileFullscreenTab
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    setLocalEthnicityId(ethnicity?.id);
    setLocalRaceId(race?.id);

    getJourneyQuestions(null, true);
  }, []);

  // Lifecycle | Check for update | getJourneyQuestions API Response
  useEffect(() => {
    if (getJourneyQuestionsSuccess) {
      const ethnicityData = getEthnicityData();
      const ethnicityObject = ethnicityData?.find(
        (item) => item?.id === ethnicity?.id
      );
      setLocalEthnicity(ethnicityObject);

      const raceData = getJourneyQuestionsData?.selections?.find(
        (item) => item?.category === "race"
      )?.selections;
      const raceObject = raceData?.find((item) => item?.id === race?.id);
      setLocalRace(raceObject);
    }
  }, [getJourneyQuestionsSuccess]);

  // Lifecycle | Check for update | updateProfile API Response
  useEffect(() => {
    if (updateProfileLoading) {
    } else if (updateProfileSuccess) {
      dispatch(
        updateEthnicity(updateProfileData?.data?.user?.appearance?.ethnicity)
      );
      dispatch(updateRace(updateProfileData?.data?.user?.appearance?.race));
      dispatch(
        updateProfileCompletionPercentage(
          updateProfileData?.data?.user?.profile_progress?.summary?.progress
        )
      );
      dispatch(
        updateProfileRequirementPopup(
          updateProfileData?.data?.user?.profile_progress?.summary?.requirement
        )
      );
      dispatch(updateIsUnsavedChanges(false));

      if (profileCompletionState) {
        const height = updateProfileData?.data?.user?.appearance?.height;
        const bodyType = updateProfileData?.data?.user?.appearance?.body_type;
        const ethnicity = updateProfileData?.data?.user?.appearance?.ethnicity;
        const race = updateProfileData?.data?.user?.appearance?.race;
        const eyeColor = updateProfileData?.data?.user?.appearance?.eye_color;
        const hairColor = updateProfileData?.data?.user?.appearance?.hair_color;

        if (!height) {
          dispatch(updateUpdateProfileFullscreenTab(0));
        } else if (!bodyType) {
          dispatch(updateUpdateProfileFullscreenTab(1));
        } else if (!ethnicity) {
          dispatch(updateUpdateProfileFullscreenTab(2));
        } else if (!race) {
          dispatch(updateUpdateProfileFullscreenTab(2));
        } else if (!eyeColor) {
          dispatch(updateUpdateProfileFullscreenTab(3));
        } else if (!hairColor) {
          dispatch(updateUpdateProfileFullscreenTab(4));
        } else {
          dispatch(updateUpdateProfileFullscreenDialog(false));
        }
      } else {
        dispatch(updateUpdateProfileFullscreenDialog(false));
      }
    } else if (updateProfileError) {
    }
  }, [updateProfileLoading, updateProfileSuccess, updateProfileError]);

  // Lifecycle | Check for update | localEthnicity
  useEffect(() => {
    if (localEthnicity?.id !== ethnicity?.id || localRace?.id !== race?.id) {
      dispatch(updateIsUnsavedChanges(true));
    } else {
      dispatch(updateIsUnsavedChanges(false));
    }
  }, [localEthnicity]);

  // Event Handlers | Button
  const onSaveEthnicity = () => {
    if (!isFormValid()) return;

    const obj = {
      profile: {
        ethnicity: localEthnicityId,
        race: localRaceId,
      },
    };
    updateProfile(obj);
  };

  // Event Handlers | MUI Select
  const handleSelectChange = (type) => (event) => {
    switch (type) {
      case "ethnicity":
        setLocalEthnicityId(event.target.value);

        const ethnicityData = getEthnicityData();
        const ethnicityObject = ethnicityData?.find(
          (item) => item?.id === event.target.value
        );
        setLocalEthnicity(ethnicityObject);
        setLocalRace(null);
        break;
      case "race":
        setLocalRaceId(event.target.value);

        const raceData = getJourneyQuestionsData?.selections?.find(
          (item) => item?.category === "race"
        )?.selections;
        const raceObject = raceData?.find(
          (item) => item?.id === event.target.value
        );

        setLocalRace(raceObject);
        break;
      default:
        break;
    }
  };

  // Utility Functions
  const isFormValid = () => {
    return localEthnicity && localRace && !updateProfileLoading;
  };
  const getEthnicityData = () => {
    return getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "ethnicity"
    )?.selections;
  };
  const getRaceData = () => {
    const raceData = getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "race"
    )?.selections;

    const filteredRaceData = raceData?.filter(
      (item) => item?.sub_category === localEthnicity?.tag
    );

    return filteredRaceData;
  };

  return (
    <div id="ethnicity-subcomponent">
      <div className="padding-container">
        <div
          className={`top-container ${
            addPaddingTop ? "add-edit-padding-top" : ""
          }`}
        >
          <div className="ethnicity-icon-container">
            {getIcon("ethnicityIcon", "ethnicity-icon")}
          </div>

          <div className="ethnicity-title">
            {t("profile.whats_your_ethnicity_race")}
          </div>

          {getJourneyQuestionsFetching || getJourneyQuestionsLoading ? (
            <Spinner />
          ) : (
            <div className="ethnicity-race-body-container">
              <div className="ethnicity-body-container">
                <div className="ethnicity-form-field-container">
                  <div className="ethnicity-select-form-field-container">
                    <TextField
                      className="ethnicity-select-form-field"
                      select
                      value={localEthnicityId || ""}
                      placeholder={t(
                        "registration.looking_for.select_ethnicity"
                      )}
                      label={t("registration.looking_for.select_ethnicity")}
                      onChange={handleSelectChange("ethnicity")}
                      variant="standard"
                    >
                      {getEthnicityData()?.map((ethnicity, index) => (
                        <MenuItem value={ethnicity?.id} key={index}>
                          {ethnicity?.display_locale || ethnicity?.locale}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>

                {localEthnicity?.id && (
                  <div className="selected-ethnicity">
                    {localEthnicity?.display_locale || localEthnicity?.locale}
                  </div>
                )}
              </div>

              <div className="race-body-container">
                <div className="race-form-field-container">
                  <div className="race-select-form-field-container">
                    <TextField
                      className="race-select-form-field"
                      select
                      value={localRaceId || ""}
                      placeholder={t("registration.looking_for.select_race")}
                      label={t("registration.looking_for.select_race")}
                      onChange={handleSelectChange("race")}
                      variant="standard"
                    >
                      {getRaceData()?.map((race, index) => (
                        <MenuItem value={race?.id} key={index}>
                          {race?.display_locale || race?.locale}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>

                {localRace?.id && (
                  <div className="selected-race">
                    {localRace?.display_locale || localRace?.locale}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="bottom-container">
        <div
          className={`save-button ${isFormValid() ? "" : "disabled-button"}`}
          onClick={onSaveEthnicity}
        >
          {updateProfileLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("common.save")
          )}
        </div>
      </div>
    </div>
  );
};

export default EditEthnicity;
