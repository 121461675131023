// General
import "./dating-styles.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetJourneyQuestionsQuery,
  useUpdateJourneyMutation,
} from "../../../../services/data.service";
// Static Data
import utilityConst from "../../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateDatingStyles,
  updateJourneyNavigationPassthrough,
  updateJourneyDataPassthrough,
  updateRegistrationNavigationData,
} from "../../../../redux/store/registrationStore";
import { updateErrorToast } from "../../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { useMediaQuery } from "@mui/material";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Components
import Spinner from "../../../shared/elements/spinner/spinner";

const DatingStylesDetails = () => {
  // API variables
  const [
    getJourneyQuestions,
    {
      data: getJourneyQuestionsData,
      error: getJourneyQuestionsErrorData,
      isFetching: getJourneyQuestionsFetching,
      isLoading: getJourneyQuestionsLoading,
      isSuccess: getJourneyQuestionsSuccess,
      isError: getJourneyQuestionsError,
    },
  ] = useLazyGetJourneyQuestionsQuery();
  const [
    updateJourney,
    {
      data: updateJourneyData,
      error: updateJourneyErrorData,
      isLoading: updateJourneyLoading,
      isSuccess: updateJourneySuccess,
      isError: updateJourneyError,
    },
  ] = useUpdateJourneyMutation();
  const [
    skipJourney,
    {
      data: skipJourneyData,
      error: skipJourneyErrorData,
      isLoading: skipJourneyLoading,
      isSuccess: skipJourneySuccess,
      isError: skipJourneyError,
    },
  ] = useUpdateJourneyMutation();

  // Redux variables
  const datingStyles = useSelector((state) => state.registration.datingStyles);
  const skippableJourney = useSelector(
    (state) => state.registration.skippableJourney
  );
  const registrationNavigationData = useSelector(
    (state) => state.registration.registrationNavigationData
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    getJourneyQuestions(null, true);
  }, []);

  // Lifecycle | Check for update | updateJourney API Response
  useEffect(() => {
    if (updateJourneyLoading) {
    } else if (updateJourneySuccess) {
      dispatch(updateJourneyDataPassthrough(updateJourneyData));
      dispatch(updateJourneyNavigationPassthrough({}));
    } else if (updateJourneyError) {
      const warningToast = {
        message: updateJourneyErrorData?.data?.message,
        autoClose: 3000,
      };
      dispatch(updateErrorToast(warningToast));
    }
  }, [updateJourneyLoading, updateJourneySuccess, updateJourneyError]);

  // Lifecycle | Check for update | skipJourney API Response
  useEffect(() => {
    if (skipJourneyLoading) {
    } else if (skipJourneySuccess) {
      const updatedItems = registrationNavigationData?.map((item, i) => ({
        ...item,
        skipped: i === 5 ? true : i > 5 ? false : item.skipped,
      }));
      dispatch(updateRegistrationNavigationData(updatedItems));

      dispatch(updateJourneyDataPassthrough(updateJourneyData));
      dispatch(updateJourneyNavigationPassthrough({}));
    } else if (skipJourneyError) {
    }
  }, [skipJourneyLoading, skipJourneySuccess, skipJourneyError]);

  // Event Handlers | Button
  const onSelectDatingStyle = (datingStyle) => {
    let datingStylesTemp = [...datingStyles];
    if (datingStylesTemp.includes(datingStyle)) {
      datingStylesTemp = datingStylesTemp.filter(
        (item) => item?.id !== datingStyle?.id
      );
    } else {
      if (datingStylesTemp?.length < returnMaxDatingStyles()) {
        datingStylesTemp.push(datingStyle);
      }
    }
    dispatch(updateDatingStyles(datingStylesTemp));
  };
  const onNextPage = () => {
    if (!isFormValid()) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-62-62.6-62.6.1",
      },
    });

    const obj = {
      journey_step: "6",
      journey_data: {
        profile: {
          dating_style: datingStyles?.map((item) => item?.id),
        },
      },
    };
    updateJourney(obj);
  };
  const onSkip = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-62-62.6-62.6.2",
      },
    });

    const obj = {
      journey_step: "6",
      journey_data: {
        looking_for: {},
      },
    };
    skipJourney(obj);
  };

  // Utility Functions
  const isFormValid = () => {
    return (
      datingStyles?.length >= returnMinDatingStyles() &&
      datingStyles?.length <= returnMaxDatingStyles() &&
      !updateJourneyLoading
    );
  };
  const getDatingStylesData = () => {
    return getJourneyQuestionsData?.selections?.find(
      (item) => item?.category === "dating_style"
    )?.selections;
  };
  const checkSelectedDatingStyles = (datingStyle) => {
    return datingStyles?.find((item) => item?.id === datingStyle?.id);
  };
  const returnMinDatingStyles = () => {
    return (
      getJourneyQuestionsData?.questions?.find(
        (item) => item?.category === "dating_style"
      )?.min_selection || 1
    );
  };
  const returnMaxDatingStyles = () => {
    return (
      getJourneyQuestionsData?.questions?.find(
        (item) => item?.category === "dating_style"
      )?.max_selection || 5
    );
  };

  return (
    <div id="details-signup-dating-style-details-subcomponent">
      <div className="padding-container">
        <div className="top-container">
          <div className="dating-style-details-label">
            <Trans
              i18nKey={"registration.dating_style.title"}
              components={{ br: <br /> }}
            />
          </div>

          <div className="dating-style-description-label">
            <Trans
              i18nKey={"registration.dating_style.description"}
              components={{ br: <br /> }}
            />
          </div>

          {getJourneyQuestionsFetching || getJourneyQuestionsLoading ? (
            <Spinner />
          ) : (
            <div
              className={`dating-style-body-container ${
                isMobile &&
                getDatingStylesData()?.length >
                  utilityConst.registrationItemFlexAmount
                  ? "flex-start"
                  : "justify-center"
              }`}
            >
              {getDatingStylesData()?.map((item, index) => (
                <div
                  className={`dating-style-item ${
                    isMobile &&
                    getDatingStylesData()?.length <=
                      utilityConst.registrationItemFlexAmount
                      ? "partial-width"
                      : isMobile &&
                        getDatingStylesData()?.length >
                          utilityConst.registrationItemFlexAmount
                      ? "fit-content"
                      : "fit-content"
                  } ${checkSelectedDatingStyles(item) ? "selected" : ""}`}
                  onClick={() => onSelectDatingStyle(item)}
                  key={index}
                >
                  <div className="dating-style-emoji-container">
                    {item?.emoji}
                  </div>

                  <div className="dating-style-label">
                    {item?.display_locale}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="bottom-container">
        <div
          className={`continue-button ${
            isMobile ? "full-width" : "fit-content"
          } ${isFormValid() ? "" : "disabled-button"}`}
          onClick={onNextPage}
        >
          {updateJourneyLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("profile.tor_selected", {
              n: datingStyles?.length,
              max: returnMaxDatingStyles(),
            })
          )}
        </div>

        <div className="skip-button" onClick={onSkip}>
          {skipJourneyLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("common.skip")
          )}
        </div>
      </div>
    </div>
  );
};

export default DatingStylesDetails;
