// General
import "./username-signup.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetJourneyQuery,
  useUpdateJourneyMutation,
} from "../../../services/data.service";
import {
  removeHTMLEscape,
  noNumbersOrSpecialChars,
} from "../../../services/regex.service";
// Static Data
import userConst from "../../../const/userConst";
import routeConst from "../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateUsername,
  updateBirthdate,
  updateJourneyDataPassthrough,
} from "../../../redux/store/registrationStore";
import { updateErrorToast } from "../../../redux/store/toastStore";
// react-date-picker
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
// Material UI
import { TextField, useMediaQuery } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Moment
import moment from "moment";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import Spinner from "../../shared/elements/spinner/spinner";

const UsernameSignup = () => {
  // API variables
  const [
    getJourney,
    {
      data: getJourneyData,
      error: getJourneyErrorData,
      isFetching: getJourneyFetching,
      isLoading: getJourneyLoading,
      isSuccess: getJourneySuccess,
      isError: getJourneyError,
    },
  ] = useLazyGetJourneyQuery();
  const [
    updateJourney,
    {
      data: updateJourneyData,
      error: updateJourneyErrorData,
      isLoading: updateJourneyLoading,
      isSuccess: updateJourneySuccess,
      isError: updateJourneyError,
    },
  ] = useUpdateJourneyMutation();

  // General variables
  const [usernameErrorMessage, setUsernameErrorMessage] = useState("");
  const [usernameFocus, setUsernameFocus] = useState(false);
  const [usernameValid, setUsernameValid] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [dobFocus, setDobFocus] = useState(false);
  const [dobValid, setDobValid] = useState(false);
  const [dobError, setDobError] = useState(false);
  const minDate = moment().subtract(userConst.userAgeRange.min, "years");
  const maxDate = moment().subtract(userConst.userAgeRange.max, "years");

  // Redux variables
  const username = useSelector((state) => state.registration.username);
  const birthdate = useSelector((state) => state.registration.birthdate);
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Event Handlers | Button
  const onNextPage = () => {
    if (!isFormValid()) return;

    const obj = {
      journey_data: {
        birth_date: birthdate,
        username,
      },
    };
    updateJourney(obj);
  };

  // Lifecycle | Mounted
  useEffect(() => {
    getJourney(null, false);
  }, []);

  // Lifecycle | Check for update | Journey API Response
  useEffect(() => {
    if (getJourneyFetching || getJourneyLoading) {
    } else if (getJourneySuccess) {
      if (getJourneyData?.user_journey?.journey_data?.username) {
        dispatch(
          updateUsername(getJourneyData?.user_journey?.journey_data?.username)
        );
      }

      if (getJourneyData?.user_journey?.journey_data?.date_of_birth) {
        dispatch(
          updateBirthdate(
            moment(
              getJourneyData?.user_journey?.journey_data?.date_of_birth
            )?.toDate()
          )
        );
      }
    } else if (getJourneyError) {
    }
  }, [
    getJourneyFetching,
    getJourneyLoading,
    getJourneySuccess,
    getJourneyError,
  ]);

  // Lifecycle | Check for update | updateJourney API Response
  useEffect(() => {
    if (updateJourneyLoading) {
    } else if (updateJourneySuccess) {
      dispatch(updateJourneyDataPassthrough(updateJourneyData));
      onNavigate(routeConst.usernameSignupComplete.path);
    } else if (updateJourneyError) {
      const warningToast = {
        message: updateJourneyErrorData?.data?.message,
        autoClose: 3000,
      };
      dispatch(updateErrorToast(warningToast));
    }
  }, [updateJourneyLoading, updateJourneySuccess, updateJourneyError]);

  // Event Handlers | MUI Text Field
  const onUsernameChange = (event) => {
    dispatch(updateUsername(event.target.value));
  };
  const onUsernameFocus = (event) => {
    setUsernameFocus(true);
  };

  // Event Handlers | MUI Date Picker
  const onDateChange = (event) => {
    if (!event) return;

    dispatch(updateBirthdate(moment(event).format("YYYY-MM-DD")));
  };

  // Utility Functions
  const isFormValid = () => {
    return username && usernameValid && birthdate && dobValid;
  };
  const usernameValidation = () => {
    let valid = true;
    let error = false;

    if (username === "") {
      setUsernameErrorMessage(t("signup.display_name_required"));
      valid = false;
      error = true;
    } else if (removeHTMLEscape.test(username)) {
      dispatch(updateUsername(""));
      valid = false;
      error = true;
    } else {
      setUsernameErrorMessage("");
      valid = true;
      error = false;
    }

    setUsernameValid(valid);
    setUsernameError(error);
  };
  const dobValidation = () => {
    let valid = true;
    let error = false;

    if (birthdate === "") {
      valid = false;
      error = true;
    } else {
      valid = true;
      error = false;
    }

    setDobValid(valid);
    setDobError(error);
  };
  const onCalendarOpen = () => {
    if (birthdate) return;

    // dispatch(updateBirthdate(maxDate.format("YYYY-MM-DD")));
  };

  // Check for email and password validation
  useEffect(() => {
    usernameValidation();
    dobValidation();
  }, [username, usernameFocus, usernameErrorMessage, birthdate, dobFocus]);

  return (
    <div id="username-signup-page">
      <div className="top-container">
        <div className="username-description-container">
          <div className="username-description-one">
            {t("signup.username_signup_title")}
          </div>

          <div className="username-description-two">
            {t("signup.username_signup_description")}
          </div>
        </div>

        <div
          className={`form-fields-container ${
            isMobile ? "full-width" : "partial-width"
          }`}
        >
          <div className="username-form-field-container">
            <TextField
              className={`username-form-field ${
                isMobile ? "full-width" : "partial-width"
              }`}
              required
              value={username}
              error={usernameError}
              onChange={onUsernameChange}
              placeholder={t("signup.display_name")}
              helperText={usernameErrorMessage}
              variant="standard"
              onFocus={onUsernameFocus}
            />
          </div>

          <div className="dob-form-field-container">
            <DatePicker
              name="dob"
              onChange={onDateChange}
              onCalendarOpen={onCalendarOpen}
              value={birthdate}
              defaultValue={maxDate.toDate()}
              minDate={minDate.toDate()}
              maxDate={maxDate.toDate()}
              clearIcon={null}
              format={"dd/MM/yyyy"}
            />

            {!dobValid && (
              <div className="dob-error-helper">{t("signup.dob_required")}</div>
            )}
          </div>
        </div>
      </div>

      <div className="bottom-container">
        <div
          className={`continue-button ${
            isMobile ? "full-width" : "fit-content"
          } ${isFormValid() ? "" : "disabled-button"}`}
          onClick={onNextPage}
        >
          {updateJourneyLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("common.continue")
          )}
        </div>
      </div>
    </div>
  );
};

export default UsernameSignup;
