// General
import "./gifts-section.scss";
import { useEffect } from "react";
// Services
import { useLazyGetProductGiftsQuery } from "../../../../services/data.service";
// Static Data
import routeConst from "../../../../const/routeConst";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import Spinner from "../spinner/spinner";
import Gifts from "./gifts/gifts";

const GiftsSection = (props) => {
  const { state } = props;

  // API variables
  const [
    getProductGifts,
    {
      data: getProductGiftsData,
      error: getProductGiftsErrorData,
      isFetching: getProductGiftsFetching,
      isLoading: getProductGiftsLoading,
      isSuccess: getProductGiftsSuccess,
      isError: getProductGiftsError,
    },
  ] = useLazyGetProductGiftsQuery();

  // Render variables
  let giftsListView;

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Mounted
  useEffect(() => {
    getProductGifts();
  }, []);

  // Render Functions
  if (getProductGiftsFetching || getProductGiftsLoading) {
    giftsListView = <Spinner />;
  } else if (getProductGiftsSuccess) {
    giftsListView = getProductGiftsData?.data?.map((gift, index) => {
      if (index < 6) {
        return (
          <Gifts
            state={state}
            productHashId={gift?.product_hash_id}
            giftImage={gift?.graphics?.png}
            giftAmount={gift?.amount.toLocaleString()}
            isEventBased={gift?.is_event_based}
            key={index}
          />
        );
      }
    });
  } else if (getProductGiftsError) {
    if (getProductGiftsErrorData?.status === 401) {
      onNavigate(routeConst.logout.path);
    }
  }

  return (
    <div
      id="interactable-overlay-gifts-section-subcomponent"
      className={state === "private-call" ? "add-padding" : ""}
    >
      {giftsListView}
    </div>
  );
};

export default GiftsSection;
