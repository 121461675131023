// General
import "./net-worth.scss";
import { useState, useEffect, useRef } from "react";
// Services
import {
  useLazyGetJourneyQuestionsQuery,
  useUpdateJourneyMutation,
} from "../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateNetworthData,
  resetNetworthData,
  updateNetworthIndex,
  updateNetworth,
  updateJourneyNavigationPassthrough,
  updateJourneyDataPassthrough,
  updateRegistrationNavigationData,
} from "../../../../redux/store/registrationStore";
import { updateErrorToast } from "../../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { useMediaQuery } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Components
import Spinner from "../../../shared/elements/spinner/spinner";

const NetWorthDetails = () => {
  // API variables
  const [
    getJourneyQuestions,
    {
      data: getJourneyQuestionsData,
      error: getJourneyQuestionsErrorData,
      isFetching: getJourneyQuestionsFetching,
      isLoading: getJourneyQuestionsLoading,
      isSuccess: getJourneyQuestionsSuccess,
      isError: getJourneyQuestionsError,
    },
  ] = useLazyGetJourneyQuestionsQuery();
  const [
    updateJourney,
    {
      data: updateJourneyData,
      error: updateJourneyErrorData,
      isLoading: updateJourneyLoading,
      isSuccess: updateJourneySuccess,
      isError: updateJourneyError,
    },
  ] = useUpdateJourneyMutation();
  const [
    skipJourney,
    {
      data: skipJourneyData,
      error: skipJourneyErrorData,
      isLoading: skipJourneyLoading,
      isSuccess: skipJourneySuccess,
      isError: skipJourneyError,
    },
  ] = useUpdateJourneyMutation();

  // General variables
  const [showArrow, setShowArrow] = useState(false);
  const listRef = useRef(null);

  // Redux variables
  const networthData = useSelector((state) => state.registration.networthData);
  const networthIndex = useSelector(
    (state) => state.registration.networthIndex
  );
  const networth = useSelector((state) => state.registration.networth);
  const skippableJourney = useSelector(
    (state) => state.registration.skippableJourney
  );
  const registrationNavigationData = useSelector(
    (state) => state.registration.registrationNavigationData
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    getJourneyQuestions(null, true);

    return () => {
      listRef.current?.removeEventListener("scroll", onScroll);
    };
  }, []);

  // Lifecycle | Check for update | getJourneyQuestions API Response
  useEffect(() => {
    if (getJourneyQuestionsFetching || getJourneyQuestionsLoading) {
    } else if (getJourneyQuestionsSuccess) {
      if (networthData?.length <= 0) {
        let networthData = getJourneyQuestionsData?.selections?.find(
          (item) => item?.category === "networth"
        );

        let networthArrayTemp = JSON.parse(
          JSON.stringify(networthData?.selections)
        );
        // add empty items to the start and end of the array
        networthArrayTemp.unshift({ id: 0, display_locale: "", tag: "-" });
        networthArrayTemp.unshift({ id: 0, display_locale: "", tag: "-" });
        networthArrayTemp.push({ id: 0, display_locale: "", tag: "-" });
        networthArrayTemp.push({ id: 0, display_locale: "", tag: "-" });

        dispatch(updateNetworthData(networthArrayTemp));
        dispatch(updateNetworthIndex(4));
        dispatch(updateNetworth(networthArrayTemp[4]?.value));
      }
    } else if (getJourneyQuestionsError) {
    }
  }, [
    getJourneyQuestionsFetching,
    getJourneyQuestionsLoading,
    getJourneyQuestionsSuccess,
    getJourneyQuestionsError,
  ]);

  // Lifecycle | Check for update | updateJourney API Response
  useEffect(() => {
    if (updateJourneyLoading) {
    } else if (updateJourneySuccess) {
      dispatch(updateJourneyDataPassthrough(updateJourneyData));
      dispatch(updateJourneyNavigationPassthrough({}));
    } else if (updateJourneyError) {
      const warningToast = {
        message: updateJourneyErrorData?.data?.message,
        autoClose: 3000,
      };
      dispatch(updateErrorToast(warningToast));

      dispatch(resetNetworthData());
      getJourneyQuestions(null, false);
      setShowArrow(true);
    }
  }, [updateJourneyLoading, updateJourneySuccess, updateJourneyError]);

  // Lifecycle | Check for update | skipJourney API Response
  useEffect(() => {
    if (skipJourneyLoading) {
    } else if (skipJourneySuccess) {
      const updatedItems = registrationNavigationData?.map((item, i) => ({
        ...item,
        skipped: i === 2 ? true : i > 2 ? false : item.skipped,
      }));
      dispatch(updateRegistrationNavigationData(updatedItems));

      dispatch(updateJourneyDataPassthrough(updateJourneyData));
      dispatch(updateJourneyNavigationPassthrough({}));
    } else if (skipJourneyError) {
    }
  }, [skipJourneyLoading, skipJourneySuccess, skipJourneyError]);

  // Lifecycle | Check for update | networthData
  useEffect(() => {
    if (!networthData || networthData?.length <= 0) return;

    console.log("beforeEventListener");
    setTimeout(() => {
      listRef.current.addEventListener("scroll", onScroll);
    }, 1000);
    console.log("afterEventListener");

    // Scroll to the middle of the selected item
    const itemHeight = listRef.current.firstChild.offsetHeight;
    listRef.current.scrollTop =
      networthIndex * itemHeight -
      listRef.current.offsetHeight / 2 +
      itemHeight / 2;
  }, [networthData]);

  // Lifecycle | Check for update | selectedNetworthIndex
  useEffect(() => {
    if (!networthIndex) return;

    if (networthIndex === NaN) {
      dispatch(updateNetworthIndex(4));
    } else {
      // Scroll to the middle of the selected item
      dispatch(updateNetworth(networthData[networthIndex]?.id));
    }
  }, [networthIndex]);

  // Lifecycle | Check for update | networthData & networthIndex
  useEffect(() => {
    if (
      networthData &&
      networthData?.length > 0 &&
      (!networthIndex || !networth)
    ) {
      if (listRef && listRef.current) {
        listRef.current.addEventListener("scroll", onScroll);
      }
    }
  }, [networthData, networthIndex, networth]);

  // Event Handlers | Button
  const onChangeNetworth = (direction) => {
    let newIndex = networthIndex;

    console.log("onChangeNetworth");
    if (typeof newIndex !== "number") return;

    if (direction === "up") {
      if (networthIndex > 2) {
        newIndex = networthIndex - 1;
      }
    } else if (direction === "down") {
      if (networthIndex < networthData?.length - 3) {
        newIndex = networthIndex + 1;
      }
    }

    dispatch(updateNetworthIndex(newIndex));

    // Scroll to the middle of the selected item
    const itemHeight = listRef.current.firstChild.offsetHeight;
    listRef.current.scrollTop =
      newIndex * itemHeight - listRef.current.offsetHeight / 2 + itemHeight / 2;
  };
  const onClickNetworth = (index) => {
    console.log("clickNetworth", index);
    dispatch(updateNetworth(index));

    // Scroll to the middle of the selected item
    const itemHeight = listRef.current.firstChild.offsetHeight;
    listRef.current.scrollTop =
      index * itemHeight - listRef.current.offsetHeight / 2 + itemHeight / 2;
  };
  const onNextPage = () => {
    if (!isFormValid()) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-62-62.3-62.3.1",
      },
    });

    const obj = {
      journey_step: "3",
      journey_data: {
        profile: {
          networth: networth,
        },
      },
    };
    updateJourney(obj);
  };
  const onSkip = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-62-62.3-62.3.2",
      },
    });

    const obj = {
      journey_step: "3",
      journey_data: {
        looking_for: {},
      },
    };
    skipJourney(obj);
  };

  // Utility Functions
  const onScroll = () => {
    const middle = listRef.current.offsetHeight / 2;
    const itemHeight = listRef.current.firstChild.offsetHeight;
    const scrollTop = listRef.current.scrollTop;
    const closestIndex = Math.round((scrollTop + middle) / itemHeight);

    if (typeof closestIndex === "number") {
      dispatch(updateNetworthIndex(closestIndex - 1));
    }
  };
  const isFormValid = () => {
    return networth && !updateJourneyLoading;
  };
  console.log("networthIndex", networthIndex);
  console.log("networth", networth);
  return (
    <div id="details-signup-net-worth-details-subcomponent">
      <div className="top-container">
        <div className="net-worth-details-label">
          <Trans
            i18nKey={"registration.networth.title"}
            components={{ br: <br /> }}
          />
        </div>

        {!networthData ? (
          <Spinner />
        ) : (
          <div className="net-worth-selector-container">
            {(!isMobile || showArrow) && (
              <div className="fake-arrow-container"></div>
            )}

            {!showArrow && <div className="spacer-container"></div>}
            <div
              className={`net-worth-container ${
                showArrow ? "reduce-width" : ""
              }`}
              ref={listRef}
            >
              {networthData?.map((income, index) => (
                <div
                  className={`net-worth-item ${
                    index === networthIndex ? "selected" : ""
                  }`}
                  onClick={() => onClickNetworth(index)}
                  key={index}
                >
                  {income?.display_locale}
                </div>
              ))}
            </div>

            {!showArrow && <div className="spacer-container"></div>}

            {(!isMobile || showArrow) && (
              <div className="arrow-container">
                <ArrowDropUpIcon
                  className="arrow-icon"
                  onClick={() => onChangeNetworth("up")}
                />
                <ArrowDropDownIcon
                  className="arrow-icon"
                  onClick={() => onChangeNetworth("down")}
                />
              </div>
            )}
          </div>
        )}
      </div>

      <div className="bottom-container">
        <div
          className={`continue-button ${
            isMobile ? "full-width" : "fit-content"
          } ${isFormValid() ? "" : "disabled-button"}`}
          onClick={onNextPage}
        >
          {updateJourneyLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("common.continue")
          )}
        </div>

        <div className="skip-button" onClick={onSkip}>
          {skipJourneyLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("common.skip")
          )}
        </div>
      </div>
    </div>
  );
};

export default NetWorthDetails;
