// General
import "./verification-status-dialog.scss";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateVerificationStatusDialog } from "../../../../redux/store/dialogStore";
// lottie-react
import Lottie from "lottie-react";
// Material UI
import { Dialog } from "@mui/material";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import AssetManager from "../../../utility/manager/asset-manager/asset-manager";

const VerificationStatusDialog = () => {
  // Redux variables
  const verificationStatusDialog = useSelector(
    (state) => state.dialog.verificationStatusDialog
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getAsset = AssetManager();

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateVerificationStatusDialog(false));
  };

  return (
    <Dialog
      className="custom-fullscreen-transparent-dialog"
      // className={"custom-radius25-dialog"}
      open={verificationStatusDialog}
      onClose={onCloseDialog}
    >
      <div id="verification-status-dialog">
        <div className="lottie-overlay-container">
          <Lottie
            className="general-success-lottie"
            animationData={getAsset("generalSuccessLottie")}
            autoPlay={true}
            loop={false}
          />

          <div className="verification-status-label">
            <Trans
              i18nKey={"verification.verification_successful"}
              components={{ br: <br /> }}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default VerificationStatusDialog;
