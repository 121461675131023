// General
import "./tor-signup.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetTorListQuery,
  useSaveTorListMutation,
} from "../../../services/data.service";
// Static Data
import routeConst from "../../../const/routeConst";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import useCustomNavigate from "../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../shared/elements/spinner/spinner";
import SignupStepper from "../../shared/elements/signup-stepper/signup-stepper";

const TorSignup = () => {
  // API variables
  const [
    getTorList,
    {
      data: getTorListData,
      error: getTorListErrorData,
      isFetching: getTorListFetching,
      isLoading: getTorListLoading,
      isSuccess: getTorListSuccess,
      isError: getTorListError,
    },
  ] = useLazyGetTorListQuery();
  const [
    saveTorList,
    {
      data: saveTorListData,
      error: saveTorListErrorData,
      isLoading: saveTorListLoading,
      isSuccess: saveTorListSuccess,
      isError: saveTorListError,
    },
  ] = useSaveTorListMutation();

  // General variables
  const maxTag = 5;
  const [view, setView] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);

  // Render variables
  const [tagDetails, setTagDetails] = useState(null);

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    getTorList();
  }, []);

  // Lifecycle | Check for update | Save Tor List API Response
  useEffect(() => {
    if (saveTorListLoading) {
    } else if (saveTorListSuccess) {
      switch (saveTorListData?.status) {
        case 0:
          onNavigate(routeConst.descriptionSignup.path);
          break;
        default:
          break;
      }
    } else if (saveTorListError) {
    }
  }, [saveTorListLoading, saveTorListSuccess, saveTorListError]);

  // Event Handlers | Button
  const onSelectTag = (tag) => {
    setTagDetails(tag.description);

    if (selectedTags.includes(tag.id)) {
      setSelectedTags((prev) => prev.filter((item) => item !== tag.id));
    } else {
      if (selectedTags.length < 5) {
        setSelectedTags((prev) => [...prev, tag.id]);
      }
    }
  };
  const onSave = () => {
    if (!isFormValid()) return;

    const obj = {
      tags: selectedTags,
    };
    saveTorList(obj);
  };

  // Utility Functions
  const isFormValid = () => {
    if (selectedTags?.length < 1) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div id="tor-signup-page">
      <div className="max-width-container">
        <div className="navbar-container">
          <div
            className="back-button"
            onClick={() => onNavigate(routeConst.infoSignup.path)}
          >
            {getIcon("signupBackIcon", "signup-back-icon")}
          </div>
        </div>

        <div className="overflow-container">
          <div className="stepper-container">
            <div className="stepper-max-width-container">
              <div className="stepper-max-width-inner-container">
                <SignupStepper current={2} total={5} />
              </div>
            </div>
          </div>

          <div className="title">
            <Trans
              i18nKey="profile.tor_dialog_select_title"
              components={{ sup: <sup className="smaller-font" /> }}
            />
          </div>

          <div
            className={`selected-label ${
              selectedTags.length === 5 ? "max-tags" : ""
            }`}
          >
            {t("profile.tor_selected", { n: selectedTags?.length, max: 5 })}
          </div>

          <div className="tags-container">
            {getTorListData?.data?.results?.map((tag, index) => (
              <div
                className={`tag-container ${
                  selectedTags.includes(tag?.id) ? "selected-tag" : ""
                }`}
                onClick={() => onSelectTag(tag)}
                key={index}
              >
                {tag?.locale}
              </div>
            ))}
          </div>
        </div>

        {tagDetails && (
          <div className="tag-details">
            <Trans i18nKey={tagDetails} components={{ b: <b /> }} />
          </div>
        )}

        <div className="bottom-container">
          <div
            className={`next-button ${!isFormValid() ? "disabled-button" : ""}`}
            onClick={onSave}
          >
            {saveTorListLoading ? (
              <Spinner size={21} isPadding={false} color={"white-spinner"} />
            ) : (
              t("common.save")
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TorSignup;
