// General
import "./edit-looking-for-age-range.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetJourneyQuestionsQuery,
  useUpdateEditUserProfileMutation,
} from "../../../../../services/data.service";
// Static Data
import searchConst from "../../../../../const/searchConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateLookingForAgeRange,
  updateUpdateProfileFullscreenTab,
  updateIsUnsavedChanges,
} from "../../../../../redux/store/editProfileStore";
import {
  updateProfileCompletionPercentage,
  updateProfileRequirementPopup,
} from "../../../../../redux/store/profileStore";
import { updateUpdateProfileFullscreenDialog } from "../../../../../redux/store/dialogStore";
// Material UI
import { Slider } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Components
import Spinner from "../../../elements/spinner/spinner";

const EditLookingForAgeRange = (props) => {
  const { addPaddingTop = false } = props;

  // API variables
  const [
    getJourneyQuestions,
    {
      data: getJourneyQuestionsData,
      error: getJourneyQuestionsErrorData,
      isFetching: getJourneyQuestionsFetching,
      isLoading: getJourneyQuestionsLoading,
      isSuccess: getJourneyQuestionsSuccess,
      isError: getJourneyQuestionsError,
    },
  ] = useLazyGetJourneyQuestionsQuery();
  const [
    updateProfile,
    {
      data: updateProfileData,
      error: updateProfileErrorData,
      isLoading: updateProfileLoading,
      isSuccess: updateProfileSuccess,
      isError: updateProfileError,
    },
  ] = useUpdateEditUserProfileMutation();

  // General variables
  const [localAgeRange, setLocalAgeRange] = useState({ min: null, max: null });

  // Redux variables
  const lookingForAgeRange = useSelector(
    (state) => state.editProfile.lookingForAgeRange
  );
  const profileCompletionState = useSelector(
    (state) => state.editProfile.profileCompletionState
  );
  const updateProfileFullscreenTab = useSelector(
    (state) => state.editProfile.updateProfileFullscreenTab
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    setLocalAgeRange(lookingForAgeRange);

    getJourneyQuestions(null, true);
  }, []);

  // Lifecycle | Check for update | updateProfile API Response
  useEffect(() => {
    if (updateProfileLoading) {
    } else if (updateProfileSuccess) {
      dispatch(
        updateLookingForAgeRange({
          min: updateProfileData?.data?.user?.looking_fors?.age?.min,
          max: updateProfileData?.data?.user?.looking_fors?.age?.max,
        })
      );
      dispatch(
        updateProfileCompletionPercentage(
          updateProfileData?.data?.user?.profile_progress?.summary?.progress
        )
      );
      dispatch(
        updateProfileRequirementPopup(
          updateProfileData?.data?.user?.profile_progress?.summary?.requirement
        )
      );
      dispatch(updateIsUnsavedChanges(false));

      if (profileCompletionState) {
        dispatch(
          updateUpdateProfileFullscreenTab(updateProfileFullscreenTab + 1)
        );
      } else {
        dispatch(updateUpdateProfileFullscreenDialog(false));
      }
    } else if (updateProfileError) {
    }
  }, [updateProfileLoading, updateProfileSuccess, updateProfileError]);

  // Lifecycle | Check for update | localAgeRange
  useEffect(() => {
    if (
      lookingForAgeRange?.min !== localAgeRange?.min ||
      lookingForAgeRange?.max !== localAgeRange?.max
    ) {
      dispatch(updateIsUnsavedChanges(true));
    } else {
      dispatch(updateIsUnsavedChanges(false));
    }
  }, [localAgeRange]);

  // Event Handlers | Button
  const onSaveAgeRange = () => {
    if (!isFormValid()) return;

    const obj = {
      looking_for: {
        age_min: localAgeRange?.min,
        age_max: localAgeRange?.max,
      },
    };
    updateProfile(obj);
  };

  // Event Handlers | Slider
  const onAgeRangeChange = (event, newValue) => {
    const ageRange = {
      min: newValue[0],
      max: newValue[1],
    };
    setLocalAgeRange(ageRange);
  };

  // Utility Functions
  const isFormValid = () => {
    return localAgeRange?.min && localAgeRange?.max && !updateProfileLoading;
  };

  return (
    <div id="looking-for-age-range-subcomponent">
      <div className="padding-container">
        <div
          className={`top-container ${
            addPaddingTop ? "add-edit-padding-top" : ""
          }`}
        >
          <div className="looking-for-age-range-title">
            {t("profile.select_age_range")}
          </div>

          {getJourneyQuestionsFetching || getJourneyQuestionsLoading ? (
            <Spinner />
          ) : (
            <div className="age-range-body-container">
              <div className="age-range-slider-container">
                <Slider
                  className="custom-slider-red my-slider"
                  value={[localAgeRange?.min, localAgeRange?.max]}
                  onChange={onAgeRangeChange}
                  valueLabelDisplay="off"
                  disableSwap
                  min={searchConst.filters.ageRange.min}
                  max={searchConst.filters.ageRange.max}
                />
              </div>

              <div className="selected-age-range-label">
                {localAgeRange?.min} - {localAgeRange?.max}{" "}
                {t("common.years_old")}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="bottom-container">
        <div
          className={`save-button ${isFormValid() ? "" : "disabled-button"}`}
          onClick={onSaveAgeRange}
        >
          {updateProfileLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("common.save")
          )}
        </div>
      </div>
    </div>
  );
};

export default EditLookingForAgeRange;
