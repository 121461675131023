// General
import "./viewer-info.scss";
import { useState, useEffect } from "react";
// react-gtm-module
import TagManager from "react-gtm-module";
// embla-carousel-react
import useEmblaCarousel from "embla-carousel-react";
// Material UI
import { Tabs, Tab, Box } from "@mui/material";
import PropTypes from "prop-types";
// i18next
import { useTranslation } from "react-i18next";
// Components
import LiveTab from "./live-tab/live-tab";
import TopFansTab from "./top-fans-tab/top-fans-tab";

const ViewerInfo = (props) => {
  const { state } = props;

  // MUI variables
  const [value, setValue] = useState(0);

  // Embla variables
  const [emblaRef, emblaApi] = useEmblaCarousel({
    duration: 15,
  });

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted | emblaApi
  useEffect(() => {
    if (!emblaApi) return;
    emblaApi?.scrollTo(value);

    emblaApi?.on("select", () => {
      setValue(emblaApi?.selectedScrollSnap());
    });
  }, [emblaApi]);

  // Lifecycle | Check for update | interestTab
  useEffect(() => {
    emblaApi?.scrollTo(value);
  }, [value]);

  // Event Handlers | MUI Tabs
  const onTabChange = (event, newValue) => {
    TagManager.dataLayer({
      dataLayer: {
        event: `PWA-D6-6.4-Button-${newValue === 0 ? "LiveTab" : "TopFansTab"}`,
      },
    });

    setValue(newValue);
  };
  const onTabChangeBySwipe = (index) => {
    TagManager.dataLayer({
      dataLayer: {
        event: `PWA-D6-6.5-Button-${index === 0 ? "LiveTab" : "TopFansTab"}`,
      },
    });

    setValue(index);
  };

  return (
    <div
      id="livestream-profile-view-dialog-viewer-info-subcomponent"
      className={state === "dialog" ? "screen-height" : "fix-height"}
    >
      <div className="tab-container">
        <Tabs
          className="custom-tabs"
          value={value}
          onChange={onTabChange}
          TabIndicatorProps={{ style: { backgroundColor: "#710d0d" } }}
          variant="fullWidth"
        >
          <Tab label={t("mini-profile-tabs.live")} {...a11yProps(0)} />
          <Tab label={t("mini-profile-tabs.top_fans")} {...a11yProps(1)} />
        </Tabs>

        <div ref={emblaRef} className="embla">
          <div className="embla__container">
            <div className="embla__slide">{value === 0 && <LiveTab />}</div>

            <div className="embla__slide">{value === 1 && <TopFansTab />}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewerInfo;

// Material UI | Tab Functions
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }} className="tab-box-container">
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
