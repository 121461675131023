// General
import "./buffering-overlay.scss";
// Redux
import { useSelector } from "react-redux";
// Material UI
import Spinner from "../../../shared/elements/spinner/spinner";

const LivestreamBufferingOverlay = () => {
  // Redux variables
  const livestreamBuffering = useSelector(
    (state) => state.livestreaming.livestreamBuffering
  );

  if (livestreamBuffering) {
    return (
      <div id="livestream-buffering-overlay-subcomponent">
        <Spinner type={"iOS"} isPadding={false} />
      </div>
    );
  }
};

export default LivestreamBufferingOverlay;
