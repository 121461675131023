const profileConst = {
  report: [
    {
      jsonTitle: "profile.block_user",
      title: "Block User",
      value: "block-user",
      output: "",
    },
    {
      jsonTitle: "profile.unblock_user",
      title: "Unblock User",
      value: "unblock-user",
      output: "",
    },
    {
      jsonTitle: "profile.request_private_photo",
      title: "Request Private Photo",
      value: "request-private-photo",
      output: "",
    },
    {
      jsonTitle: "profile.approve_private_photo",
      title: "Approve Private Photo Request",
      value: "approve-private-photo",
      output: "",
    },
    {
      jsonTitle: "profile.revoke_private_photo",
      title: "Revoke Private Photo",
      value: "revoke-private-photo",
      output: "",
    },
    {
      jsonTitle: "inbox.share_private_photos",
      title: "Share Private Photos",
      value: "share-private-photos",
      output: "",
    },
    {
      jsonTitle: "inbox.unshare_private_photos",
      title: "Unshare Private Photos",
      value: "unshare-private-photos",
      output: "",
    },
    {
      jsonTitle: "profile.report",
      title: "Report",
      value: "report-user",
      output: "",
    },
    {
      jsonTitle: "inbox.clear_messages",
      title: "Clear Messages",
      value: "clear-messages",
      output: "",
    },
  ],
  reportIssue: [
    {
      jsonTitle: "-",
      title: "Illegal Content",
      value: "illegal-content",
      output: "",
    },
    {
      jsonTitle: "-",
      title: "Soliciting / Scammers",
      value: "soliciting-scammers",
      output: "",
    },
    {
      jsonTitle: "-",
      title: "Inappropriate Photos",
      value: "inappropriate-photos",
      output: "",
    },
    {
      jsonTitle: "-",
      title: "Inappropriate Contents",
      value: "inappropriate-contents",
      output: "",
    },
    {
      jsonTitle: "-",
      title: "Inappropriate / Fraudulent Behavior",
      value: "fraudulent-behavior",
      output: "",
    },
    {
      jsonTitle: "-",
      title: "Underage",
      value: "undersage",
      output: "",
    },
    {
      jsonTitle: "-",
      title: "Impersonation",
      value: "impersonation",
      output: "",
    },
  ],
  reportConversation: [
    {
      jsonTitle: "profile.report",
      title: "Report",
      value: "report",
      output: "",
    },
    {
      jsonTitle: "inbox.clear_messages",
      title: "Clear Messages",
      value: "clear-messages",
      output: "",
    },
  ],
  reportProfile: [
    {
      jsonTitle: "profile.report",
      title: "Report",
      value: "report",
      output: "",
    },
  ],
  onboarding: {
    torTags: [
      {
        title: "tor.discreet",
      },
      {
        title: "tor.pay_per_meet",
      },
      {
        title: "tor.passport_ready",
      },
      {
        title: "tor.monthly_allowance",
      },
      {
        title: "tor.open_relationship",
      },
    ],
  },

  // Misc
  userProfileType: {
    dialog: "dialog",
    page: "page",
  },
  torTheme: {
    default: "default",
    cny: "cny_2024",
  },
};

export default profileConst;
