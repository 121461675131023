// Static Data
import userConst from "../../../const/userConst";
// Custom Hooks
import AssetManager from "../manager/asset-manager/asset-manager";

const useGetMembershipBadge = () => {
  // Custom Hooks Functions
  const getAsset = AssetManager();

  // Main Function
  const getMembershipBadge = (membershipType) => {
    switch (membershipType) {
      case userConst.membershipType.diamond:
        return getAsset("diamondBadge");
      case userConst.membershipType.freePremium:
        return getAsset("freePremiumBadge");
      case userConst.membershipType.premium:
        return getAsset("premiumBadge");
      case userConst.membershipType.admin:
        return getAsset("adminBadge");
      default:
        return;
    }
  };

  return getMembershipBadge;
};

export default useGetMembershipBadge;
