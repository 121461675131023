// General
import "./check-in.scss";
import { useEffect } from "react";
// Services
import { useLazyGetLevellingTasksQuery } from "../../../../services/data.service";
// Redux
import { useSelector } from "react-redux";
// i18next
import { useTranslation } from "react-i18next";
// Components
import Spinner from "../../../shared/elements/spinner/spinner";
import DailyCheckInReward from "../../../shared/daily-check-in-reward/daily-check-in-reward";

const CheckIn = () => {
  // API variables
  const [
    getLevellingTasks,
    {
      data: getLevellingTasksData,
      error: getLevellingTasksErrorData,
      isFetching: getLevellingTasksFetching,
      isLoading: getLevellingTasksLoading,
      isSuccess: getLevellingTasksSuccess,
      isError: getLevellingTasksError,
    },
  ] = useLazyGetLevellingTasksQuery();

  // Redux variables
  const dailyCheckInTimer = useSelector(
    (state) => state.public.dailyCheckInTimer
  );

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    getLevellingTasks();
  }, []);

  // Utility Functions
  const getFormattedTime = (seconds) => {
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds / 60) % 60);
    let secs = Math.floor(seconds % 60);

    let formattedHours = hours < 10 ? "0" + hours : hours;
    let formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    let formattedSeconds = secs < 10 ? "0" + secs : secs;

    return formattedHours + ":" + formattedMinutes + ":" + formattedSeconds;
  };

  if (getLevellingTasksFetching || getLevellingTasksLoading) {
    return <Spinner />;
  } else if (getLevellingTasksSuccess) {
    const levellingTasks = getLevellingTasksData?.data?.tasks;
    let showTimer = true;

    if (levellingTasks) {
      showTimer = levellingTasks.find(
        (task) => task.task_id === 3 && task.claimed
      );
    }

    return (
      <div id="levelling-check-in-subcomponent">
        <div className="padding-container">
          <div className="check-in-label">
            {t("profile.daily_checkin_rewards")}
          </div>

          <div className="check-in-description">
            {t("profile.daily_checkin_rewards_desc")}
          </div>

          <DailyCheckInReward />

          {showTimer && (
            <div className="time-left-label">
              {t("levelling.you_have")}{" "}
              <span className="time">
                ({getFormattedTime(dailyCheckInTimer)})
              </span>{" "}
              {t("levelling.until_next_checkin")}
            </div>
          )}
        </div>
      </div>
    );
  } else if (getLevellingTasksError) {
  }
};

export default CheckIn;
