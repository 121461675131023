// General
import "./terms-of-relationship.scss";
import { useState, useEffect } from "react";
// Services
import {
  useLazyGetTorListQuery,
  useLazyGetProfileQuery,
  useSaveTorListMutation,
  useUpdateJourneyMutation,
} from "../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateSelectedTags,
  updateRegistrationNavigationData,
  updateProfileDataPassthrough,
  updateJourneyNavigationPassthrough,
  updateJourneyDataPassthrough,
} from "../../../../redux/store/registrationStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { useMediaQuery } from "@mui/material";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Components
import Spinner from "../../../shared/elements/spinner/spinner";
import TorTag from "../../../shared/elements/tor-tag/tor-tag";
import user from "../../../../enums/user";

const TermsOfRelationshipDetails = () => {
  // API variables
  const [
    getTorList,
    {
      data: getTorListData,
      error: getTorListErrorData,
      isFetching: getTorListFetching,
      isLoading: getTorListLoading,
      isSuccess: getTorListSuccess,
      isError: getTorListError,
    },
  ] = useLazyGetTorListQuery();
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    saveTorList,
    {
      data: saveTorListData,
      error: saveTorListErrorData,
      isLoading: saveTorListLoading,
      isSuccess: saveTorListSuccess,
      isError: saveTorListError,
    },
  ] = useSaveTorListMutation();
  const [
    updateJourney,
    {
      data: updateJourneyData,
      error: updateJourneyErrorData,
      isLoading: updateJourneyLoading,
      isSuccess: updateJourneySuccess,
      isError: updateJourneyError,
    },
  ] = useUpdateJourneyMutation();

  // i18next variables
  const { t } = useTranslation();

  // General variables
  const [tagDetails, setTagDetails] = useState(
    t("profile.tor_tap_to_see_detail")
  );

  // Redux variables
  const selectedTags = useSelector((state) => state.registration.selectedTags);
  const registrationNavigationData = useSelector(
    (state) => state.registration.registrationNavigationData
  );
  const gender = useSelector((state) => state.signup.gender);
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // Lifecycle | Mounted
  useEffect(() => {
    getTorList(null, false);
  }, []);

  // Lifecycle | Check for update | Tor List API Response
  useEffect(() => {
    if (getTorListFetching || getTorListLoading) {
    } else if (getTorListSuccess) {
      getProfile("registration-journey-tor", false);
    } else if (getTorListError) {
    }
  }, [
    getTorListFetching,
    getTorListLoading,
    getTorListSuccess,
    getTorListError,
  ]);

  // Lifecycle | Check for update | Profile API Response
  useEffect(() => {
    if (getProfileFetching || getProfileLoading) {
    } else if (getProfileSuccess) {
      switch (getProfileData?.status) {
        case 0:
        case 1:
          dispatch(
            updateSelectedTags(
              getProfileData?.data?.tor_tags?.map((item) => item.id) || []
            )
          );
          break;
        default:
          break;
      }
    } else if (getProfileError) {
    }
  }, [
    getProfileFetching,
    getProfileLoading,
    getProfileSuccess,
    getProfileError,
  ]);

  // Lifecycle | Check for update | Save Tor List API Response
  useEffect(() => {
    if (saveTorListLoading) {
    } else if (saveTorListSuccess) {
      switch (saveTorListData?.status) {
        case 0:
          const obj = {
            journey_step: gender === user.gender.MAN ? "1" : "3",
            journey_data: {
              looking_for: {},
            },
          };
          updateJourney(obj);
          break;
        default:
          break;
      }
    } else if (saveTorListError) {
    }
  }, [saveTorListLoading, saveTorListSuccess, saveTorListError]);

  // Lifecycle | Check for update | Update Journey API Response
  useEffect(() => {
    if (updateJourneyLoading) {
    } else if (updateJourneySuccess) {
      // Update Registration Navigation Data
      let temp = registrationNavigationData.map((item) => {
        if (item?.id === 0) {
          return {
            ...item,
            isVisible: true,
            isClickable: true,
          };
        } else {
          return item;
        }
      });
      dispatch(updateRegistrationNavigationData(temp));

      // Determine Navigation path
      const obj = {
        data: {
          tor_tags: saveTorListData?.data?.results,
        },
      };
      dispatch(updateProfileDataPassthrough(obj));
      dispatch(updateJourneyDataPassthrough(updateJourneyData));
      dispatch(updateJourneyNavigationPassthrough({}));
    } else if (updateJourneyError) {
    }
  }, [updateJourneyLoading, updateJourneySuccess, updateJourneyError]);

  // Event Handlers | Button
  const onSelectTag = (tag) => {
    setTagDetails(tag.description);

    if (selectedTags?.includes(tag.id)) {
      dispatch(
        updateSelectedTags(selectedTags.filter((item) => item !== tag.id))
      );
      setTagDetails(null);
    } else {
      if (selectedTags?.length < 5) {
        dispatch(updateSelectedTags([...selectedTags, tag.id]));
      }
    }
  };
  const onNextPage = () => {
    if (!isFormValid()) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-62-62.1",
      },
    });

    const obj = {
      tags: selectedTags,
    };
    saveTorList(obj);
  };

  // Utility Functions
  const isFormValid = () => {
    return (
      selectedTags?.length > 2 && !saveTorListLoading && !updateJourneyLoading
    );
  };

  return (
    <div id="details-signup-terms-of-relationship-details-subcomponent">
      <div
        className={`top-container ${
          isMobile && tagDetails
            ? "tag-description-height"
            : "non-tag-description-height"
        }`}
      >
        <div className="terms-of-relationship-details-label">
          <Trans
            i18nKey={"registration.tor.what_are_your_tor"}
            components={{
              br: <br />,
              sup: <sup className="trade-mark-label" />,
            }}
          />
        </div>

        <div className="tor-description-label">
          <Trans
            i18nKey={"registration.tor.tor_description"}
            components={{ sup: <sup className="trade-mark-mini-label" /> }}
          />
        </div>

        {!isMobile && (
          <div className="tor-select-option-label">
            {t("registration.common.you_may_select_up_to_n", { n: 5 })}
          </div>
        )}

        {!isMobile && tagDetails && (
          <div
            className="tor-description-container"
            dangerouslySetInnerHTML={{ __html: tagDetails }}
          ></div>
        )}

        {getTorListFetching || getTorListLoading ? (
          <Spinner />
        ) : (
          <div
            className={`tor-list-container ${
              isMobile ? "flex-start" : "justify-center"
            }`}
          >
            {getTorListData?.data?.results?.map((tag, index) => (
              <div
                className="tag-container"
                onClick={() => onSelectTag(tag)}
                key={index}
              >
                <TorTag
                  torName={tag?.locale}
                  description={tag?.description}
                  blur={false}
                  match={selectedTags?.includes(tag?.id) ? true : false}
                  interactable={true}
                  readOnly={true}
                  showToolTip={false}
                  theme={tag?.theme}
                  unselectedBackgroundColor="transparent"
                  selectedBackgroundColor="white"
                  unselectedTextColor="white"
                  selectedTextColor="#710d0d"
                  unselectedBorderColor="rgba(255, 255, 255, 0.5)"
                  selectedBorderColor="white"
                />
              </div>
            ))}
          </div>
        )}
      </div>

      {isMobile && tagDetails && (
        <div
          className="tor-description-container"
          dangerouslySetInnerHTML={{ __html: tagDetails }}
        ></div>
      )}

      <div className="bottom-container">
        <div
          className={`continue-button ${
            isMobile ? "full-width" : "fit-content"
          } ${isFormValid() ? "" : "disabled-button"}`}
          onClick={onNextPage}
        >
          {saveTorListLoading || updateJourneyLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("profile.tor_selected", { n: selectedTags?.length, max: 5 })
          )}
        </div>
      </div>
    </div>
  );
};

export default TermsOfRelationshipDetails;
