// General
import "./logging-settings.scss";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateShowDebugInfo,
  updateShowLog,
} from "../../../../redux/store/debugStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Switch } from "@mui/material";

const LoggingSettings = () => {
  // Redux variables
  const showHiddenSettings = useSelector(
    (state) => state.app.showHiddenSettings
  );
  const showLog = useSelector((state) => state.debug.showLog);
  const dispatch = useDispatch();

  // Event Handlers | Button
  const testCoinsPurchaseTag = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-CoinPurchase-success", // Do not change this, it will affect GTM and Insider
        category: "Purchase",
        action: "Quick Purchase Coins Actions",
        label: "Quick Purchase Coins Payment State - success",
        value: {},
        currency: "Test RM",
        product_name: "Test Product Name",
        user_id: "Test User ID",
        username: "Test Username",
        amount: "100",
      },
    });
  };

  // Event Handlers | MUI Toggle Button
  const onToggleLoggingMode = (event) => {
    dispatch(updateShowLog(event.target.checked));
  };

  if (showHiddenSettings) {
    return (
      <div id="settings-logging-settings-subcomponent">
        <div className="padding-container">
          <div className="logging-mode-label">Show Log</div>

          <div className="logging-mode-switch-container">
            <Switch
              className="custom-switch"
              checked={showLog}
              onChange={onToggleLoggingMode}
            />
          </div>

          <div
            className="coins-purchase-tag-button"
            onClick={testCoinsPurchaseTag}
          >
            Coins Purchase Tag Button
          </div>
        </div>
      </div>
    );
  }
};

export default LoggingSettings;
