// General
import "./levels-description-dialog.scss";
import { useEffect, forwardRef } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateLevelsDescriptionDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog, Slide, useMediaQuery } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import AssetManager from "../../../utility/manager/asset-manager/asset-manager";

const LevelsDescriptionDialog = () => {
  // Redux variables
  const levelsDescriptionDialog = useSelector(
    (state) => state.dialog.levelsDescriptionDialog
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getAsset = AssetManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!levelsDescriptionDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-LevelsDescription-Dialog",
      },
    });
  }, [levelsDescriptionDialog]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateLevelsDescriptionDialog(false));
  };

  return (
    <Dialog
      className={isMobile ? "" : "custom-radius35-dialog"}
      fullScreen={isMobile ? true : false}
      open={levelsDescriptionDialog}
      TransitionComponent={Transition}
      onClose={onCloseDialog}
    >
      <div
        id="levels-description-dialog"
        className={isMobile ? "" : "max-width-container"}
      >
        <div className="padding-container">
          <div className="level-label">{t("levelling.levels")}</div>
          <div className="flex-label">{t("levelling.a_new_way_to_flex")}</div>

          <div className="level-background-container">
            <img
              className="level-background"
              src={getAsset("levelIntroMain")}
            />
          </div>

          <div className="what-are-levels-label">
            {t("levelling.intro_title")}
          </div>

          <div className="level-points-container">
            <div className="level-point-1-container">
              <div className="level-point-1-image-container">
                <img
                  className="level-point-1-image"
                  src={getAsset("levelIntroPoint1")}
                />
              </div>

              <div className="level-point-1">
                {t("levelling.intro_point_1")}
              </div>
            </div>

            <div className="level-point-2-container">
              <div className="level-point-2-image-container">
                <img
                  className="level-point-2-image"
                  src={getAsset("levelIntroPoint2")}
                />
              </div>

              <div className="level-point-2">
                {t("levelling.intro_point_2")}
              </div>
            </div>
          </div>

          <div className="got-it-button" onClick={onCloseDialog}>
            {t("common.got_it")}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default LevelsDescriptionDialog;

// Render Functions | MUI Dialog | Will not work if inside of the same render as the Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
