// Static Data
import userCardConst from "../../../../const/userCardConst";
// Moment
import moment from "moment";
// Components
import UserCard from "../../../shared/elements/user-card/user-card";
import GamingCard from "../../../shared/elements/gaming-card/gaming-card";
import MasqueradePartyCard from "../../../shared/elements/masquerade-party-card/masquerade-party-card";
import CoAnchorCard from "../../../shared/elements/co-anchor-card/co-anchor-card";

const UserCardManager = (props) => {
  const {
    type,

    // Settings
    cardView,
    // User View
    id,
    idInt,
    username,
    age,
    gender,
    location,
    membershipType,
    verifiedProfile,
    verifiedCaller,
    levellingBadge,
    livestreamingAchievementBadge,
    specialBadges,
    profilePhoto,
    profileDecorations,
    publicPhotos,
    privatePhotos,
    torTags,
    datingStyle,
    profileAchievementBadge,
    aboutMe,
    aboutMeReadStatus,
    lookingFor,
    tagline,
    annualIncome,
    networth,
    occupation,
    lifestyle,
    datingBudget,
    education,
    relationship,
    smoking,
    drinking,
    height,
    bodyType,
    ethnicity,
    eyeColor,
    hairColor,
    children,
    withinDistanceInKm,
    // About Me
    personalityTraits,
    personalityType,
    starSign,
    dominantOrSubmissive,
    fitnessLevel,
    hobbies,
    // User Utility
    profileStatus,
    privatePhotoAbleToRequest,
    privatePhotoRequested,
    privatePhotoApproval,
    requestedMyPrivatePhoto,
    approvedMyPrivatePhoto,
    isOnline,
    isBlocked,
    isSilentInteraction,
    isFavorited,
    isCallEnabled,
    isSugarbaby,
    role,
    interest,
    // Livestream
    livestreamData,
    channelId,
    livestreamerId,
    followerCount,
    viewerCount,
    diamondCount,
    isFollowing,
    // Card Utility
    cardStatus,

    // Gaming View
    backgroundImage,
  } = props;

  // Utility Functions
  const isPkActive = (pkData) => {
    const now = moment();

    if (pkData) {
      const pkStartTime = moment(pkData[pkData?.length - 1]?.start_at);
      const pkEndTime = moment(pkData[pkData?.length - 1]?.end_at);

      if (now.isBetween(pkStartTime, pkEndTime)) {
        return true;
      }

      return false;
    }

    return false;
  };

  switch (type) {
    case userCardConst.cardType.user:
    case userCardConst.cardType.default:
      return (
        <UserCard
          cardView={cardView}
          // User View
          id={id}
          idInt={idInt}
          username={username}
          age={age}
          gender={gender}
          location={location}
          membershipType={membershipType}
          verifiedProfile={verifiedProfile}
          verifiedCaller={verifiedCaller}
          levellingBadge={levellingBadge}
          livestreamingAchievementBadge={livestreamingAchievementBadge}
          specialBadges={specialBadges}
          profilePhoto={profilePhoto}
          profileDecorations={profileDecorations}
          publicPhotos={publicPhotos}
          privatePhotos={privatePhotos}
          torTags={torTags}
          datingStyle={datingStyle}
          profileAchievementBadge={profileAchievementBadge}
          aboutMe={aboutMe}
          aboutMeReadStatus={aboutMeReadStatus}
          lookingFor={lookingFor}
          tagline={tagline}
          annualIncome={annualIncome}
          networth={networth}
          occupation={occupation}
          lifestyle={lifestyle}
          datingBudget={datingBudget}
          education={education}
          relationship={relationship}
          smoking={smoking}
          drinking={drinking}
          height={height}
          bodyType={bodyType}
          ethnicity={ethnicity}
          eyeColor={eyeColor}
          hairColor={hairColor}
          children={children}
          withinDistanceInKm={withinDistanceInKm}
          // About Me
          personalityTraits={personalityTraits}
          personalityType={personalityType}
          starSign={starSign}
          dominantOrSubmissive={dominantOrSubmissive}
          fitnessLevel={fitnessLevel}
          hobbies={hobbies}
          // User Utility
          profileStatus={profileStatus}
          privatePhotoAbleToRequest={privatePhotoAbleToRequest}
          privatePhotoRequested={privatePhotoRequested}
          privatePhotoApproval={privatePhotoApproval}
          requestedMyPrivatePhoto={requestedMyPrivatePhoto}
          approvedMyPrivatePhoto={approvedMyPrivatePhoto}
          isOnline={isOnline}
          isBlocked={isBlocked}
          isSilentInteraction={isSilentInteraction}
          isFavorited={isFavorited}
          isCallEnabled={isCallEnabled}
          isSugarbaby={isSugarbaby}
          role={role}
          interest={interest}
          // Livestream
          livestreamData={livestreamData}
          channelId={channelId}
          livestreamerId={livestreamerId}
          followerCount={followerCount}
          viewerCount={viewerCount}
          diamondCount={diamondCount}
          isFollowing={isFollowing}
          // Card Utility
          cardStatus={cardStatus}
        />
      );
    case userCardConst.cardType.coAnchor:
      return (
        <CoAnchorCard
          cardView={cardView}
          // User View
          id={id}
          idInt={idInt}
          username={username}
          age={age}
          gender={gender}
          location={location}
          membershipType={membershipType}
          verifiedProfile={verifiedProfile}
          verifiedCaller={verifiedCaller}
          levellingBadge={levellingBadge}
          livestreamingAchievementBadge={livestreamingAchievementBadge}
          specialBadges={specialBadges}
          profilePhoto={profilePhoto}
          profileDecorations={profileDecorations}
          publicPhotos={publicPhotos}
          privatePhotos={privatePhotos}
          torTags={torTags}
          datingStyle={datingStyle}
          profileAchievementBadge={profileAchievementBadge}
          aboutMe={aboutMe}
          aboutMeReadStatus={aboutMeReadStatus}
          lookingFor={lookingFor}
          tagline={tagline}
          annualIncome={annualIncome}
          networth={networth}
          occupation={occupation}
          lifestyle={lifestyle}
          datingBudget={datingBudget}
          education={education}
          relationship={relationship}
          smoking={smoking}
          drinking={drinking}
          height={height}
          bodyType={bodyType}
          ethnicity={ethnicity}
          eyeColor={eyeColor}
          hairColor={hairColor}
          children={children}
          withinDistanceInKm={withinDistanceInKm}
          // About Me
          personalityTraits={personalityTraits}
          personalityType={personalityType}
          starSign={starSign}
          dominantOrSubmissive={dominantOrSubmissive}
          fitnessLevel={fitnessLevel}
          hobbies={hobbies}
          // User Utility
          profileStatus={profileStatus}
          privatePhotoAbleToRequest={privatePhotoAbleToRequest}
          privatePhotoRequested={privatePhotoRequested}
          privatePhotoApproval={privatePhotoApproval}
          requestedMyPrivatePhoto={requestedMyPrivatePhoto}
          approvedMyPrivatePhoto={approvedMyPrivatePhoto}
          isOnline={isOnline}
          isBlocked={isBlocked}
          isSilentInteraction={isSilentInteraction}
          isFavorited={isFavorited}
          isCallEnabled={isCallEnabled}
          isSugarbaby={isSugarbaby}
          role={role}
          interest={interest}
          // Livestream
          channelId={channelId}
          livestreamerId={livestreamerId}
          followerCount={followerCount}
          viewerCount={viewerCount}
          diamondCount={diamondCount}
          isFollowing={isFollowing}
          // Card Utility
          cardStatus={cardStatus}
          // Co Anchor Utility
          hostProfilePhoto={
            id === livestreamData?.co_anchor?.positions[0]?.user_id
              ? livestreamData?.co_anchor?.positions[0]?.profile_photo
              : livestreamData?.co_anchor?.positions[1]?.profile_photo
          }
          coHostProfilePhoto={
            id === livestreamData?.co_anchor?.positions[0]?.user_id
              ? livestreamData?.co_anchor?.positions[1]?.profile_photo
              : livestreamData?.co_anchor?.positions[0]?.profile_photo
          }
          hasPk={isPkActive(livestreamData?.co_anchor?.pk)}
        />
      );
    case userCardConst.cardType.gaming:
      return <GamingCard backgroundImage={backgroundImage} />;
    case userCardConst.cardType.masqueradeParty:
      return <MasqueradePartyCard backgroundImage={backgroundImage} />;
    default:
      return (
        <UserCard
          cardView={cardView}
          // User View
          id={id}
          idInt={idInt}
          username={username}
          age={age}
          gender={gender}
          location={location}
          membershipType={membershipType}
          verifiedProfile={verifiedProfile}
          verifiedCaller={verifiedCaller}
          levellingBadge={levellingBadge}
          livestreamingAchievementBadge={livestreamingAchievementBadge}
          specialBadges={specialBadges}
          profilePhoto={profilePhoto}
          profileDecorations={profileDecorations}
          publicPhotos={publicPhotos}
          privatePhotos={privatePhotos}
          torTags={torTags}
          datingStyle={datingStyle}
          profileAchievementBadge={profileAchievementBadge}
          aboutMe={aboutMe}
          aboutMeReadStatus={aboutMeReadStatus}
          lookingFor={lookingFor}
          tagline={tagline}
          annualIncome={annualIncome}
          networth={networth}
          occupation={occupation}
          lifestyle={lifestyle}
          datingBudget={datingBudget}
          education={education}
          relationship={relationship}
          smoking={smoking}
          drinking={drinking}
          height={height}
          bodyType={bodyType}
          ethnicity={ethnicity}
          eyeColor={eyeColor}
          hairColor={hairColor}
          children={children}
          withinDistanceInKm={withinDistanceInKm}
          // About Me
          personalityTraits={personalityTraits}
          personalityType={personalityType}
          starSign={starSign}
          dominantOrSubmissive={dominantOrSubmissive}
          fitnessLevel={fitnessLevel}
          hobbies={hobbies}
          // User Utility
          profileStatus={profileStatus}
          privatePhotoAbleToRequest={privatePhotoAbleToRequest}
          privatePhotoRequested={privatePhotoRequested}
          privatePhotoApproval={privatePhotoApproval}
          requestedMyPrivatePhoto={requestedMyPrivatePhoto}
          approvedMyPrivatePhoto={approvedMyPrivatePhoto}
          isOnline={isOnline}
          isBlocked={isBlocked}
          isSilentInteraction={isSilentInteraction}
          isFavorited={isFavorited}
          isCallEnabled={isCallEnabled}
          isSugarbaby={isSugarbaby}
          role={role}
          interest={interest}
          // Livestream
          livestreamData={livestreamData}
          channelId={channelId}
          livestreamerId={livestreamerId}
          followerCount={followerCount}
          viewerCount={viewerCount}
          diamondCount={diamondCount}
          isFollowing={isFollowing}
          // Card Utility
          cardStatus={cardStatus}
        />
      );
  }
};

export default UserCardManager;
