// General
import "./annual-income.scss";
import { useState, useEffect, useRef } from "react";
// Services
import {
  useLazyGetJourneyQuestionsQuery,
  useUpdateJourneyMutation,
} from "../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateAnnualIncomeData,
  resetAnnualIncomeData,
  updateAnnualIncomeIndex,
  updateAnnualIncome,
  updateJourneyNavigationPassthrough,
  updateJourneyDataPassthrough,
  updateRegistrationNavigationData,
} from "../../../../redux/store/registrationStore";
import { updateErrorToast } from "../../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { useMediaQuery } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Components
import Spinner from "../../../shared/elements/spinner/spinner";

const AnnualIncomeDetails = () => {
  // API variables
  const [
    getJourneyQuestions,
    {
      data: getJourneyQuestionsData,
      error: getJourneyQuestionsErrorData,
      isFetching: getJourneyQuestionsFetching,
      isLoading: getJourneyQuestionsLoading,
      isSuccess: getJourneyQuestionsSuccess,
      isError: getJourneyQuestionsError,
    },
  ] = useLazyGetJourneyQuestionsQuery();
  const [
    updateJourney,
    {
      data: updateJourneyData,
      error: updateJourneyErrorData,
      isLoading: updateJourneyLoading,
      isSuccess: updateJourneySuccess,
      isError: updateJourneyError,
    },
  ] = useUpdateJourneyMutation();
  const [
    skipJourney,
    {
      data: skipJourneyData,
      error: skipJourneyErrorData,
      isLoading: skipJourneyLoading,
      isSuccess: skipJourneySuccess,
      isError: skipJourneyError,
    },
  ] = useUpdateJourneyMutation();

  // General variables
  const [showArrow, setShowArrow] = useState(false);
  const listRef = useRef(null);

  // Redux variables
  const annualIncomeData = useSelector(
    (state) => state.registration.annualIncomeData
  );
  const annualIncomeIndex = useSelector(
    (state) => state.registration.annualIncomeIndex
  );
  const annualIncome = useSelector((state) => state.registration.annualIncome);
  const skippableJourney = useSelector(
    (state) => state.registration.skippableJourney
  );
  const registrationNavigationData = useSelector(
    (state) => state.registration.registrationNavigationData
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    getJourneyQuestions(null, true);

    return () => {
      listRef.current?.removeEventListener("scroll", onScroll);
    };
  }, []);

  // Lifecycle | Check for update | getJourneyQuestions API Response
  useEffect(() => {
    if (getJourneyQuestionsFetching || getJourneyQuestionsLoading) {
    } else if (getJourneyQuestionsSuccess) {
      if (annualIncomeData?.length <= 0) {
        let annualIncomeData = getJourneyQuestionsData?.selections?.find(
          (item) => item?.category === "annual_income"
        );

        let incomeArrayTemp = JSON.parse(
          JSON.stringify(annualIncomeData?.selections)
        );
        // add empty items to the start and end of the array
        incomeArrayTemp.unshift({ id: 0, display_locale: "", tag: "-" });
        incomeArrayTemp.unshift({ id: 0, display_locale: "", tag: "-" });
        incomeArrayTemp.push({ id: 0, display_locale: "", tag: "-" });
        incomeArrayTemp.push({ id: 0, display_locale: "", tag: "-" });

        dispatch(updateAnnualIncomeData(incomeArrayTemp));
        dispatch(updateAnnualIncomeIndex(4));
        dispatch(updateAnnualIncome(incomeArrayTemp[4]?.value));
      }
    } else if (getJourneyQuestionsError) {
    }
  }, [
    getJourneyQuestionsFetching,
    getJourneyQuestionsLoading,
    getJourneyQuestionsSuccess,
    getJourneyQuestionsError,
  ]);

  // Lifecycle | Check for update | updateJourney API Response
  useEffect(() => {
    if (updateJourneyLoading) {
    } else if (updateJourneySuccess) {
      dispatch(updateJourneyDataPassthrough(updateJourneyData));
      dispatch(updateJourneyNavigationPassthrough({}));
    } else if (updateJourneyError) {
      const warningToast = {
        message: updateJourneyErrorData?.data?.message,
        autoClose: 3000,
      };
      dispatch(updateErrorToast(warningToast));

      dispatch(resetAnnualIncomeData());
      dispatch(updateAnnualIncomeIndex(4));
      getJourneyQuestions(null, false);
      setShowArrow(true);
    }
  }, [updateJourneyLoading, updateJourneySuccess, updateJourneyError]);

  // Lifecycle | Check for update | skipJourney API Response
  useEffect(() => {
    if (skipJourneyLoading) {
    } else if (skipJourneySuccess) {
      const updatedItems = registrationNavigationData?.map((item, i) => ({
        ...item,
        skipped: i === 1 ? true : i > 1 ? false : item.skipped,
      }));
      dispatch(updateRegistrationNavigationData(updatedItems));

      dispatch(updateJourneyDataPassthrough(updateJourneyData));
      dispatch(updateJourneyNavigationPassthrough({}));
    } else if (skipJourneyError) {
    }
  }, [skipJourneyLoading, skipJourneySuccess, skipJourneyError]);

  // Lifecycle | Check for update | annualIncomeData
  useEffect(() => {
    if (!annualIncomeData || annualIncomeData?.length <= 0) return;

    console.log("beforeEventListener");
    setTimeout(() => {
      listRef.current.addEventListener("scroll", onScroll);
    }, 2000);
    console.log("afterEventListener");

    // Scroll to the middle of the selected item
    const itemHeight = listRef.current.firstChild.offsetHeight;
    listRef.current.scrollTop =
      annualIncomeIndex * itemHeight -
      listRef.current.offsetHeight / 2 +
      itemHeight / 2;
  }, [annualIncomeData]);

  // Lifecycle | Check for update | selectedAnnualIncomeIndex
  useEffect(() => {
    if (!annualIncomeIndex) return;

    if (annualIncomeIndex === NaN) {
      dispatch(updateAnnualIncomeIndex(4));
    } else {
      // Scroll to the middle of the selected item
      dispatch(updateAnnualIncome(annualIncomeData[annualIncomeIndex]?.id));
    }
  }, [annualIncomeIndex]);

  // Lifecycle | Check for update | annualIncomeData & annualIncomeIndex
  useEffect(() => {
    if (
      annualIncomeData &&
      annualIncomeData?.length > 0 &&
      (!annualIncomeIndex || !annualIncome)
    ) {
      if (listRef && listRef.current) {
        listRef.current.addEventListener("scroll", onScroll);
      }
    }
  }, [annualIncomeData, annualIncomeIndex, annualIncome]);

  // Event Handlers | Button
  const onChangeIncome = (direction) => {
    let newIndex = annualIncomeIndex;

    console.log("onChangeIncome", newIndex);
    if (typeof newIndex !== "number") return;

    if (direction === "up") {
      if (annualIncomeIndex > 2) {
        newIndex = annualIncomeIndex - 1;
      }
    } else if (direction === "down") {
      if (annualIncomeIndex < annualIncomeData?.length - 2) {
        newIndex = annualIncomeIndex + 1;
      }
    }

    dispatch(updateAnnualIncomeIndex(newIndex));

    // Scroll to the middle of the selected item
    const itemHeight = listRef.current.firstChild.offsetHeight;
    listRef.current.scrollTop =
      newIndex * itemHeight - listRef.current.offsetHeight / 2 + itemHeight / 2;
  };
  const onClickIncome = (index) => {
    console.log("clickIncome", index);
    dispatch(updateAnnualIncomeIndex(index));

    // Scroll to the middle of the selected item
    const itemHeight = listRef.current.firstChild.offsetHeight;
    listRef.current.scrollTop =
      index * itemHeight - listRef.current.offsetHeight / 2 + itemHeight / 2;
  };
  const onNextPage = () => {
    if (!isFormValid()) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-62-62.2-62.2.1",
      },
    });

    const obj = {
      journey_step: "2",
      journey_data: {
        profile: {
          annual_income: annualIncome,
        },
      },
    };
    updateJourney(obj);
  };
  const onSkip = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-62-62.2-62.2.2",
      },
    });

    const obj = {
      journey_step: "2",
      journey_data: {
        looking_for: {},
      },
    };
    skipJourney(obj);
  };

  // Utility Functions
  const onScroll = () => {
    const middle = listRef.current.offsetHeight / 2;
    const itemHeight = listRef.current.firstChild.offsetHeight;
    const scrollTop = listRef.current.scrollTop;
    const closestIndex = Math.round((scrollTop + middle) / itemHeight);

    if (typeof closestIndex === "number") {
      dispatch(updateAnnualIncomeIndex(closestIndex - 1));
    }
  };
  const isFormValid = () => {
    return annualIncome && !updateJourneyLoading;
  };
  console.log("annualIncomeIndex", annualIncomeIndex);
  console.log("annualIncome", annualIncome);
  return (
    <div id="details-signup-annual-income-details-subcomponent">
      <div className="top-container">
        <div className="annual-income-details-label">
          <Trans
            i18nKey={"registration.annual_income.title"}
            components={{ br: <br /> }}
          />
        </div>

        {!annualIncomeData ? (
          <Spinner />
        ) : (
          <div className="annual-income-selector-container">
            {(!isMobile || showArrow) && (
              <div className="fake-arrow-container"></div>
            )}

            {!showArrow && <div className="spacer-container"></div>}

            <div
              className={`annual-income-container ${
                showArrow ? "reduce-width" : ""
              }`}
              ref={listRef}
            >
              {annualIncomeData?.map((income, index) => (
                <div
                  className={`income-item ${
                    index === annualIncomeIndex ? "selected" : ""
                  }`}
                  onClick={() => onClickIncome(index)}
                  key={index}
                >
                  {income?.display_locale}
                </div>
              ))}
            </div>

            {!showArrow && <div className="spacer-container"></div>}

            {(!isMobile || showArrow) && (
              <div className="arrow-container">
                <ArrowDropUpIcon
                  className="arrow-icon"
                  onClick={() => onChangeIncome("up")}
                />
                <ArrowDropDownIcon
                  className="arrow-icon"
                  onClick={() => onChangeIncome("down")}
                />
              </div>
            )}
          </div>
        )}
      </div>

      <div className="bottom-container">
        <div
          className={`continue-button ${
            isMobile ? "full-width" : "fit-content"
          } ${isFormValid() ? "" : "disabled-button"}`}
          onClick={onNextPage}
        >
          {updateJourneyLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("common.continue")
          )}
        </div>

        <div className="skip-button" onClick={onSkip}>
          {skipJourneyLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("common.skip")
          )}
        </div>
      </div>
    </div>
  );
};

export default AnnualIncomeDetails;
