// General
import "./add-card-loading-dialog.scss";
import { useEffect } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateAddCardDialog } from "../../../../redux/store/dialogStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Dialog } from "@mui/material";
// Components
import Spinner from "../../elements/spinner/spinner";

const AddCardLoadingDialog = () => {
  // Redux variables
  const addCardDialog = useSelector((state) => state.dialog.addCardDialog);
  const dispatch = useDispatch();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!addCardDialog) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-AddCardLoading-Dialog",
      },
    });
  }, [addCardDialog]);

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateAddCardDialog(false));
  };

  return (
    <Dialog
      className="custom-radius35-dialog"
      open={addCardDialog}
      onClose={onCloseDialog}
    >
      <div id="add-card-loading-dialog">
        <Spinner type={"alt"} isPadding={false} />

        <div className="add-label">Adding Card</div>
      </div>
    </Dialog>
  );
};

export default AddCardLoadingDialog;
