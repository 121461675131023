// General
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // UTM variables
  utmSource: null,
};

export const trackingSlice = createSlice({
  name: "tracking",
  initialState,
  reducers: {
    // UTM Functions
    updateUtmSource: (state, action) => {
      state.utmSource = action.payload;
    },

    // Utility Functions
    resetTrackingStore: () => {
      return { ...initialState };
    },
  },
});

export const {
  // UTM Functions
  updateUtmSource,

  // Utility Functions
  resetTrackingStore,
} = trackingSlice.actions;

export default trackingSlice.reducer;
