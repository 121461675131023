// General
import "./about-me.scss";
import { useEffect } from "react";
// Static Data
import editProfileConst from "../../../../../const/editProfileConst";
import userConst from "../../../../../const/userConst";
import utilityConst from "../../../../../const/utilityConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateAboutMe,
  updateUpdateProfileDialogView,
} from "../../../../../redux/store/editProfileStore";
import {
  updateRestartRegistrationDialog,
  updateUpdateProfileFullscreenDialog,
} from "../../../../../redux/store/dialogStore";
import { updateSuccessToast } from "../../../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { Divider } from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// i18next
import { useTranslation } from "react-i18next";

const AboutMe = (props) => {
  const { mode = utilityConst.editProfileMode.mobile } = props;

  // Redux variables
  const aboutMeManualTextStatus = useSelector(
    (state) => state.editProfile.aboutMeManualTextStatus
  );
  const aboutMeManualText = useSelector(
    (state) => state.editProfile.aboutMeManualText
  );
  const aboutMeManualTextNewContent = useSelector(
    (state) => state.editProfile.aboutMeManualTextNewContent
  );
  const aboutMeList = useSelector((state) => state.editProfile.aboutMeList);
  const aboutMeLength = useSelector((state) => state.editProfile.aboutMeLength);
  const profileCompletionPercentage = useSelector(
    (state) => state.profile.profileCompletionPercentage
  );
  const profileRequirementPopup = useSelector(
    (state) => state.profile.profileRequirementPopup
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Event Handlers | Button
  const onEditSection = (section) => {
    TagManager.dataLayer({
      dataLayer: {
        event: `PWA-25-25.11-${section}`,
      },
    });

    if (profileCompletionPercentage < profileRequirementPopup) {
      dispatch(updateRestartRegistrationDialog(true));
    } else {
      if (section) {
        if (section === "about_me_manual_text") {
          dispatch(updateAboutMe({ name: section, data: aboutMeManualText }));
        } else {
          const obj = {
            name: section,
            data: aboutMeList?.find((item) => item?.name === section)?.data,
          };
          dispatch(updateAboutMe(obj));
        }
      }

      dispatch(
        updateUpdateProfileDialogView(
          editProfileConst.profileDialogView.aboutMe
        )
      );
      dispatch(updateUpdateProfileFullscreenDialog(true));
    }
  };

  // Utility Functions
  const getTranslatedStatus = (status) => {
    switch (status) {
      case userConst.profileGeneralApprovalStatus.inReview:
        return t("common.in_review");
      case userConst.profileGeneralApprovalStatus.rejected:
        return t("common.rejected");
      default:
        return "";
    }
  };
  const getAboutMe = () => {
    if (
      aboutMeManualTextStatus ===
        userConst.profileGeneralApprovalStatus.inReview ||
      aboutMeManualTextStatus ===
        userConst.profileGeneralApprovalStatus.rejected
    ) {
      return aboutMeManualTextNewContent;
    } else {
      return aboutMeManualText;
    }
  };

  if (mode === utilityConst.editProfileMode.mobile) {
    return (
      <div id="mobile-edit-profile-about-me-subcomponent">
        <div className="about-me-label-container">
          <div className="about-me-label">{t("profile.about_me")}</div>
        </div>

        <div className="about-me-description">
          {t("profile.about_me_description")}
        </div>

        <div className="about-me-topic-container">
          {/* About Me Manual Text */}
          {(aboutMeManualText || aboutMeManualTextNewContent) && (
            <div
              className="topic-container"
              onClick={() => onEditSection("about_me_manual_text")}
            >
              <div className="left-container">
                <div className="add-topic-label-container">
                  <div className="add-topic-label">
                    {t("profile.describe_yourself")}
                  </div>

                  {(aboutMeManualTextStatus ===
                    userConst.profileGeneralApprovalStatus.inReview ||
                    aboutMeManualTextStatus ===
                      userConst.profileGeneralApprovalStatus.rejected) && (
                    <div
                      className={`approval-status-container ${
                        aboutMeManualTextStatus ===
                        userConst.profileGeneralApprovalStatus.inReview
                          ? "in-review-status-container"
                          : aboutMeManualTextStatus ===
                            userConst.profileGeneralApprovalStatus.rejected
                          ? "rejected-status-container"
                          : ""
                      }`}
                    >
                      {getTranslatedStatus(aboutMeManualTextStatus)}
                    </div>
                  )}
                </div>

                <div className="about-me-manual-text">{getAboutMe()}</div>
              </div>

              <div className="right-container">
                <ChevronRightIcon className="right-icon" />
              </div>
            </div>
          )}

          {aboutMeList?.map((item, index) => {
            if (item?.data) {
              return (
                <div
                  className="topic-container"
                  onClick={() => onEditSection(item?.name)}
                  key={index}
                >
                  <div className="left-container">
                    <div className="add-topic-label">
                      {t(`registration.about_me.${item?.name}`)}
                    </div>

                    <div className="add-topic-wrap-container">
                      {item?.data?.length > 0 ? (
                        item?.data?.map((aboutMeAnswers, aboutMeIndex) => (
                          <div className="add-topic-answer" key={aboutMeIndex}>
                            {aboutMeAnswers?.locale}
                          </div>
                        ))
                      ) : (
                        <div className="add-topic-answer">
                          {item?.data?.locale}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="right-container">
                    {(
                      Array.isArray(item?.data)
                        ? item?.data?.length > 0
                        : item?.data
                    ) ? (
                      <ChevronRightIcon className="right-icon" />
                    ) : (
                      <AddRoundedIcon className="right-icon" />
                    )}
                  </div>
                </div>
              );
            }
          })}

          {aboutMeLength < aboutMeList?.length && (
            <div className="topic-container" onClick={() => onEditSection()}>
              <div className="left-container">
                <div className="add-topic-label">{t("profile.add_topic")}</div>

                <div className="add-topic-description">
                  {t("profile.choose_topic_best_describe_you")}
                </div>
              </div>

              <div className="right-container">
                <AddRoundedIcon className="right-icon" />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  } else if (mode === utilityConst.editProfileMode.desktop) {
    return (
      <div id="desktop-edit-profile-about-me-subcomponent">
        <div className="about-me-static-label-container">
          <div className="about-me-label">{t("profile.about_me")}</div>
          <Divider className="short-divider" />
        </div>

        <div className="about-me-description">
          {t("profile.about_me_description")}
        </div>

        <div className="about-me-topic-container">
          {/* About Me Manual Text */}
          {(aboutMeManualText || aboutMeManualTextNewContent) && (
            <div
              className="topic-container"
              onClick={() => onEditSection("about_me_manual_text")}
            >
              <div className="left-container">
                <div className="add-topic-label-container">
                  <div className="add-topic-label">
                    {t("profile.describe_yourself")}
                  </div>

                  {(aboutMeManualTextStatus ===
                    userConst.profileGeneralApprovalStatus.inReview ||
                    aboutMeManualTextStatus ===
                      userConst.profileGeneralApprovalStatus.rejected) && (
                    <div
                      className={`approval-status-container ${
                        aboutMeManualTextStatus ===
                        userConst.profileGeneralApprovalStatus.inReview
                          ? "in-review-status-container"
                          : aboutMeManualTextStatus ===
                            userConst.profileGeneralApprovalStatus.rejected
                          ? "rejected-status-container"
                          : ""
                      }`}
                    >
                      {getTranslatedStatus(aboutMeManualTextStatus)}
                    </div>
                  )}
                </div>

                <div className="about-me-manual-text">{getAboutMe()}</div>
              </div>

              <div className="right-container">
                <ChevronRightIcon className="right-icon" />
              </div>
            </div>
          )}

          {aboutMeList?.map((item, index) => {
            if (item?.data) {
              return (
                <div
                  className="topic-container"
                  onClick={() => onEditSection(item?.name)}
                  key={index}
                >
                  <div className="left-container">
                    <div className="add-topic-label">
                      {t(`registration.about_me.${item?.name}`)}
                    </div>

                    <div className="add-topic-wrap-container">
                      {item?.data?.length > 0 ? (
                        item?.data?.map((aboutMeAnswers, aboutMeIndex) => (
                          <div className="add-topic-answer" key={aboutMeIndex}>
                            {aboutMeAnswers?.locale}
                          </div>
                        ))
                      ) : (
                        <div className="add-topic-answer">
                          {item?.data?.locale}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="right-container">
                    {(
                      Array.isArray(item?.data)
                        ? item?.data?.length > 0
                        : item?.data
                    ) ? (
                      <ChevronRightIcon className="right-icon" />
                    ) : (
                      <AddRoundedIcon className="right-icon" />
                    )}
                  </div>
                </div>
              );
            }
          })}

          {aboutMeLength < aboutMeList?.length && (
            <div className="topic-container" onClick={() => onEditSection()}>
              <div className="left-container">
                <div className="add-topic-label">{t("profile.add_topic")}</div>

                <div className="add-topic-description">
                  {t("profile.choose_topic_best_describe_you")}
                </div>
              </div>

              <div className="right-container">
                <AddRoundedIcon className="right-icon" />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
};

export default AboutMe;
